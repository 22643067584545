import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useState, useEffect } from 'react';
import call from "../../service";

export default function PopUPEmailText({
  userId,
  setShowPopUpForShare,
  generatePDF,
  selectedCustomer,
  docName,
  shareType // 'email' or 'whatsapp'
}) {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');


  useEffect(() => {
    // Set initial values based on the share type
    if (shareType === 'email') {
      setEmail(selectedCustomer.email || ''); // Pre-fill email if available
      setSubject(`Sharing Document of ${selectedCustomer.docType} - ${selectedCustomer.docNumber}` || "");
      setMessage(`We are sharing ${docName} for your reference. Please find the attached document.`);
    } else if (shareType === 'whatsapp') {
      setPhoneNumber(selectedCustomer.phoneNumber || ''); // Pre-fill phone number if available
      setMessage(`We are sharing ${docName} for your reference.`);
    }
  }, [selectedCustomer, docName, shareType]);

  const handleSend = () => {
    if (shareType === 'email') {
      // Handle email sending
      generatePDF('invoiceDiv').then((pdf) => {
        const pdfBlob = pdf.output('blob');
        const formData = new FormData();
        formData.append('email', email);
        formData.append('message', message);
        formData.append('document', pdfBlob);
        formData.append('subject', subject);
        console.log(formData)
        // Send email through backend API
        call('POST', 'sendEmailWithPDF', formData).then(() => {
          console.log('Email sent successfully');
          setShowPopUpForShare(false);
        }).catch(e => console.log('Error sending email', e));
      });
    } else if (shareType === 'whatsapp') {
      // Handle WhatsApp sharing
      const whatsappMessage = `${message} (Document: ${docName})`;
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
      window.open(whatsappUrl, '_blank');
      setShowPopUpForShare(false);
    }
  };

  return (
    <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {shareType === 'email' ? 'Send Email' : 'Share on WhatsApp'}
            </h5>
            <button type="button" className="btn-close" onClick={() => setShowPopUpForShare(false)} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div>
              {shareType === 'email' ? (
                <>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Subject</label>
                    <input type="text" className="form-control" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" id="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input type="text" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" id="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>
                </>
              )}
              <button type="button" className="btn btn-primary" onClick={handleSend}>
                {shareType === 'email' ? 'Send Email' : 'Share on WhatsApp'}
              </button>
              {generatePDF && (
                <div className="mt-3">
                  <span><i className="fas fa-paperclip"></i> {docName}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
