import { ListItem } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { NewInput, NewSelect } from "../../../utils/newInput";
import SliderComponent from "../../viewProfile/components/SliderComponent";
import AddNewBuyerPopUp from "../../contractManagement/addnewBuyerPopUp";
import DatePicker from "react-datepicker";
import DropdownSearch from "../../tallyReports/Cards/dropdownWithSearch";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FilterGlobal = ({ filter, setFilter, refresh, setRefresh, count, showResultPerPage, showFilterBtn, filterData, setFilterData,
  showDownloadIcon, onDownloadClick, isAdditionalButton, children, filteredSearch, setFilteredSearch, selectedIndex, setSelectedIndex, resetPage, showSelectOption, setAssignType, setToggle, toggleLabel1, toggleLabel2, showToggleButton,
  showDraftCount, onShowDraftClick, draftModeActive, showApplyBtn, selectedlist, setselectedList, handleFilterChange, handleEmailChange, selectedValue, allsuppliers, handleAddressChange }) => {
  console.log(filterData, "fdddddddddd", filter, "this is filter----", setFilter, "setfilter it is",)
  const [showFilterPopup, toggleFilterPopup] = useState(false)
  const modalRef = useRef(null)
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      if (event.target.name != 'randomSelected') {
        setRefresh(refresh + 1)
        if (resetPage) {
          resetPage()
        }
      }
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, so close it
        toggleFilterPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === 'randomSelected') {
      if (event.target.value <= filter.resultPerPage) {
        setFilter({ ...filter, [event.target.name]: event.target.value })
        return
      } else {
        return
      }
    }
    setFilter({ ...filter, [event.target.name]: event.target.value })
    if (event.target.name === 'search' && resetPage) {
      resetPage()
    }

  }


  const removeFilter = (filterName) => {
    let temp = JSON.parse(JSON.stringify(filterData))
    // console.log("insideRemoveFilterrrrrrrrrrrrr", filterName, temp);
    if (filteredSearch?.length) {
      let temp2 = JSON.parse(JSON.stringify(filteredSearch))
      temp2[filterName]["isFilterActive"] = false
      temp2[filterName]["isAllChecked"] = false
      for (let index = 0; index < temp2[filterName]?.["data"]?.length; index++) {
        const element = temp2[filterName]?.["data"][index];
        element["isChecked"] = false
      }
      setFilteredSearch({ ...temp2 })
    }

    if (temp[filterName]["type"] === "minMaxDate") {
      temp[filterName]["value"] = ["", ""]
    }

    temp[filterName]["isFilterActive"] = false
    temp[filterName]["isAllChecked"] = false
    for (let index = 0; index < temp[filterName]?.["data"]?.length; index++) {
      const element = temp[filterName]?.["data"][index];
      element["isChecked"] = false
    }
    setFilterData({ ...temp })

  }

  const [comm, setcomm] = useState([])

  const [uniqueIndustryTypes, setUniqueIndustryTypes] = useState([]);

  useEffect(() => {
    // Extract the industry_type and remove duplicates
    const industryTypes = allsuppliers.map(ele => ele.industry_type);
    const uniqueTypes = [...new Set(industryTypes)];
    setUniqueIndustryTypes(uniqueTypes);
  }, [allsuppliers]);






  return (
    <>
      <div className={`modal fade ${showFilterPopup && "show"}`} style={showFilterPopup ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0" ref={modalRef}>
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Filters</label>
                <div className="modal-header border-0">
                  {showApplyBtn ?
                    <button type="button"
                      onClick={() => {
                        setRefresh(refresh + 1)
                        toggleFilterPopup(false)
                      }}
                      style={{ right: '5rem', top: '1.5rem' }}
                      className={` border-0 mb-2 text-white enableQuotebtn position-absolute`}>
                      {"Apply"}
                    </button> : null}
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleFilterPopup(false)}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4 filterbody">
              <div>

                <div className="d-flex flex-column gap-4">
                  {/* Active Filter Display */}


                  <div className="filter-row mb-3">
                    <label style={{ marginRight: "10px", fontWeight: "bold" }}>Commodity</label>
                    <NewSelect
                      label="Commodity"
                      optionValue="industry_type"
                      optionLabel="label"
                      selectData={uniqueIndustryTypes.map((com) => ({
                        value: com,
                        label: com,
                      }))}
                      onChange={handleFilterChange}
                      className="filter-select"
                    />
                  </div>
                  <div className="filter-row mb-3">
                    <label style={{ marginRight: "10px", fontWeight: "bold" }}>Email Address</label>
                    <NewSelect
                      label="Company Address"
                      optionValue="company"
                      optionLabel="label"
                      selectData={allsuppliers.map((com) => ({
                        value: com.email_id,
                        label: com.email_id,
                      }))}
                      value={selectedValue} // Bind selected value here
                      onChange={handleEmailChange} // Update selected value on change
                      className="filter-select"
                      placeholder="Select Company Address" // Default placeholder
                    />
                  </div>
                  <div className="filter-row mb-3">
                    <label style={{ marginRight: "10px", fontWeight: "bold" }}>Company Address</label>
                    <NewSelect
                      label="Company Address"
                      optionValue="user_address"
                      optionLabel="label"
                      selectData={Array.from(new Set(
                        allsuppliers
                          .filter(com => com.user_address && com.user_address.trim() !== '') // Filter out null/empty addresses
                          .map(com => com.user_address) // Extract the user_address values
                      )).map(user_address => ({ // Convert the unique addresses back to the selectData format
                        value: user_address,
                        label: user_address,
                      }))}
                      onChange={handleAddressChange}
                      className="filter-select"
                    />

                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="d-flex align-items-center ml-1 w-100">
        {showResultPerPage ? (
          <div className='row align-items-center'
          >
            <label className='col-auto pt-2'>Show</label>
            <input className='col-auto form-control pagination-input  my-4' type={"number"} name={"resultPerPage"} value={filter.resultPerPage}
              // onChange={(event) => setFilter({ ...filter, resultPerPage: event.target.value })}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <label className='col-auto pt-2'>{`of ${count || 0}`}</label>
          </div>
        ) : null}
        {showFilterBtn ? (
          <div className={showResultPerPage ? "ml-4" : ""}>
            <button
              onClick={() => toggleFilterPopup(!showFilterPopup)}
              type="button" className="btn btn-light bg-white px-4 border border-radius-12 text-start"><img src={"assets/images/filter.png"} alt="" /><span className="px-2">Filter</span></button>
          </div>
        ) : null}




        {Object.keys(filterData || {}).length ? Object.keys(filterData).map((l, m) => {
          if (l === "Grid Columns") {
            return null
          }
          if (filterData[l]["isFilterActive"]) {
            return (
              <div className={"ml-4"}>
                <button
                  onClick={() => {
                    removeFilter(l)
                    if (showApplyBtn) {
                      setRefresh(refresh + 1)
                    }
                  }}
                  type="button" className="btn btn-light px-4 border-radius-12 text-start bg-color1 border-color1-2px">
                  <span className="px-2 text-color1 font-wt-600 font-size-12 text-center">{l}</span>
                  <button type="button" className="btn-close text-color1 font-size-12" aria-label="Close" onClick={() => {
                    removeFilter(l)
                    if (showApplyBtn) {
                      setRefresh(refresh + 1)
                    }
                  }}></button>
                </button>
              </div>
            )
          }
        }) : null}

        {selectedIndex?.length > 0 &&
          <div className={"ml-4"}>
            <button
              onClick={() => setSelectedIndex([])}
              type="button" className="btn btn-light px-4 border-radius-12 text-start bg-color1 border-color1-2px">
              <span className="px-2 text-color1 font-wt-600 font-size-12 text-center">{`${selectedIndex.length} Selected`}</span>
              <button type="button" className="btn-close text-color1 font-size-12" aria-label="Close" onClick={() => setSelectedIndex([])}></button>
            </button>
          </div>
        }
        {showSelectOption &&
          <div >
            <ul className="ml-3 w-90 nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
              <li className="nav-item p-0 w-50" role="presentation">
                <button onClick={() => {
                  //setPriceKey("plan_amount_usd")
                  setAssignType("Random")
                }} className="nav-link active  roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">
                  <label className="font-size-13 mb-0">Random</label>
                  <input type="number" name='randomSelected' value={filter?.randomSelected}
                    onKeyDown={handleKeyDown} onChange={handleChange} maxLength={filter.resultPerPage}
                    className="w-25 ml-2 assignTaskInput" placeholder="" />
                </button>
              </li>
              <li className="nav-item p-0 w-50" role="presentation">
                <button onClick={(e) => {
                  setAssignType("Start To End")
                }} className="nav-link h-100 w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">

                  <label className="font-size-13 mb-0">Start To End</label>

                </button>
              </li>
            </ul>
          </div>
        }

        {showToggleButton &&
          <div >
            <ul className="ml-3 w-90 nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
              <li className="nav-item p-0 w-40" role="presentation">
                <button onClick={() => {
                  setToggle(toggleLabel1)
                }} className="nav-link active  roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">
                  <label className="font-size-13 mb-0">{toggleLabel1}</label>
                </button>
              </li>
              <li className="nav-item p-0 w-60" role="presentation">
                <button onClick={(e) => {
                  setToggle(toggleLabel2)
                }} className="nav-link h-100 w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">

                  <label className="font-size-13 mb-0">{toggleLabel2}</label>

                </button>
              </li>
            </ul>
          </div>
        }
      </div>

      <div className="d-flex align-items-center w-100 justify-content-end gap-2">


        {showDraftCount ? (
          <div
            style={{ background: '#fff', border: draftModeActive ? "2px solid #AED8FF " : '1px solid #ced4da', borderRadius: '10px' }}
            onClick={onShowDraftClick}
            className="cursor ml-3 px-3 py-1" >
            <label className="cursor" ><span className="font-size-14 font-wt-500 mr-1" >Draft</span><span className="font-size-14 font-wt-600">{showDraftCount}</span></label>
          </div>
        ) : null}
        {showDownloadIcon ? (
          <div
            onClick={onDownloadClick}
            className="cursor" >
            <img className="cursor" src={"/assets/images/download_text_icon.svg"} />
          </div>
        ) : null}
        {isAdditionalButton ? (
          <div>
            {children}
          </div>
        ) : null}
      </div>

    </>
  );
}
export default FilterGlobal;