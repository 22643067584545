import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { GetCache, getContactObject, getDocDetails, isEmpty, mapOrganizationTypeWithKarza, removeNextLine, SetCache, subAdminDataDownloadLimit, validateEmail } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import toastDisplay from '../../../utils/toastNotification';
import swal from 'sweetalert';
import MultipleSelect from '../../../utils/MultipleSelect';
import ChatBoxPopUp2 from '../../chatRoom/components/ChatBoxPopUp2';
import FinanceInvoiceModal from '../../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { InputWithSelect, NewInput, NewSelect } from '../../../utils/newInput';
import { companyTypes, industryData } from '../../registration/newRegistration';
import SubAdminPopup from '../SubAdminPopup';
// import Logo from "../../../../public/assets/images/logo_1.png";
import { decryptData, encryptData, multiSelectEventHandler } from "../../../utils/myFunctions"
import axios from 'axios';
import { platformBackendUrl } from '../../../urlConstants';
import CommodityAdd from '../../registration/commodityAdd';
import GlobalSignup from './globalSignup';
import Papa from 'papaparse';


const overallCounts = [
  { name: "Under Review", alt: 'underreview', class: ' text-F1C40F ' },
  { name: "Inprogress", alt: 'inprogress', class: ' text-F1C40F ' },
  { name: "Approved", alt: 'approved', class: ' text-48DA87 ' },
  { name: "Rejected", alt: 'rejected', class: ' colorFF7B6D ' },
  { name: "Expired", alt: 'expired', class: ' text-custom2 ' }
]

const FieldTypes = [
  {
    name: 'Company', type: "finTech", typeId: 19, techType: 2,
    soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true
  },
  // {
  //   name: 'Importer', type: "finTech", typeId: 3, techType: 2,
  //   soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true
  // },
  {
    name: 'Banks/Finance/NBFC', type: 'finTech', typeId: 8, techType: 2,
    soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true, disabled: true
  },
  {
    name: 'Channel Partner', type: "CP", typeId: 20, techType: null,
    individual: true, soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true
  },
  {
    name: 'Franchise Partner', type: "FP", typeId: 20, techType: null,
    individual: true, soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, disabled: true
  }
]

const formTitles = [
  { name: "Select your field" },
  { name: "Select your company type" },
  { name: "Enter your registration details" },
  { name: "Enter your company details" },
  { name: "Enter your personal details" }

]

const InvoiceLimit = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [showLoader2, setshowLoader2] = useState(false)



  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState(Object.keys(GetCache("invoiceLimitFilter")).length ? { ...GetCache("invoiceLimitFilter"), resultPerPage: 10 } : { resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState(GetCache("invoiceLimitFilterData"))
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [filterCount, setFilterCount] = useState({})
  const [filteredSearch, setFilteredSearch] = useState(GetCache("invoiceSearchFilter"))
  const [salesPerson, setSalesPerson] = useState([])
  const [data, setdata] = useState({ phone_code: "91", name_title: "Mr" })
  const [subadminPopup, togglesubadminPopup] = useState({ data: [], show: false, userId: '' })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [internalRemarks, setInternalRemarks] = useState([])
  const [internalRemarkPopup, setInternalRemarkPopup] = useState({
    show: false
  })
  const [addRemarkPopup, setAddRemarkPopup] = useState({
    show: false
  })
  const [selectSupplierPopup, setselectSupplierPopup] = useState(false)
  const [boolsignup, setboolsignup] = useState(false)
  const [suppliers, setsuppliersdata] = useState([])
  const [suppliersoverall, setsuppliersdataoverall] = useState([])
  const [supplierName, setSupplierName] = useState('')
  const [countrys, setCountrys] = useState([])
  const [errors, setErrors] = useState({})
  const [financiersData, setfinanciersData] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = (userPermissionsForSubAdmin?.mainAdmin || userPermissionsForSubAdmin?.[`Invoice Limit Complete`]) ? undefined : userId

  const handleValidation = () => {
    let err = {}
    const validationFields = ["supplierName", "contactPerson", "contact_number", "designation", "email_id"]
    for (let index = 0; index < validationFields.length; index++) {
      const element = validationFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      } else if (errors[element]) {
        err[element] = errors[element]
      }
    }
    let reqBodyFields = ["supplierName", "contactPerson", "name_title", "phone_code", "contact_number", "designation", "email_id", "industry_type", "organization_type", "user_address"]
    if (isEmpty(err)) {
      let obj = {
        'application_type': 'Invoice Limit'
      }
      reqBodyFields.forEach(k => {
        obj[k] = data[k]
      })
      let exporter = suppliersoverall.find(item => item.EXPORTER_NAME === data["supplierName"])
      console.log(exporter, "this is exporter here--->>>>")
      if (exporter) {
        obj["EXPORTER_CODE"] = exporter.EXPORTER_CODE
      }
      setshowLoader(true)
      call('POST', 'createdirectapplication', obj).then(result => {
        localStorage.setItem("UserDetails", JSON.stringify(result))
        window.open(`/applyForLimit`, "_blank")
        setshowLoader(false)
        setselectSupplierPopup(false)
        setdata({
          ...data,
          supplierName: "",
          contactPerson: "",
          contact_number: "",
          designation: "",
          email_id: "",
          user_address: "",
          organization_type: ''
        })
        setSupplierName('')
        setsuppliersdata([])
        setsuppliersdataoverall([])
      }).catch(e => {
        setshowLoader(false)
        toastDisplay("Failed To Create Application", "error")
      })
    } else {
      console.log('error in mandat', data);
      toastDisplay("Form Validation Error", "info")
    }
  }

  useEffect(() => {
    SetCache("invoiceSearchFilter", filteredSearch)

  }, [filteredSearch])
  const getCountrydata = () => {
    call('GET', 'getallCountry').then((result) => {
      console.log('running getallCountry api-->', result);
      setCountrys(result)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
    });
  }
  useEffect(() => {
    getCountrydata()
    getAllIndiaExporters()
  }, [])
  useEffect(() => {
    console.log("data here in invoice limit", data)
  }, [data])
  const getAllIndiaExporters = () => {
    setshowLoader(true)
    console.log(supplierName, "this is supplier naem-->>>>")
    call('POST', 'getAllIndiaExporters', { supplierName, userTypeId, userId }).then(result => {
      setsuppliersdata(result)
      setsuppliersdataoverall(result)
      setshowLoader(false)
    }).catch(e => {
      setsuppliersdata([{ id: "temp", "EXPORTER_NAME": "Add New Option", typedInput: supplierName }])
      // window.location = '/nwManagementExporter?action=addNewBuyer'
      setshowLoader(false)
    })
  }
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (supplierName) {
        getAllIndiaExporters()
      }
    }, 1000);
    return () => {
      clearTimeout(debounce)
    }
  }, [supplierName])
  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  const getAdminInvoiceLimit = () => {
    SetCache("invoiceLimitFilterData", filterData)
    SetCache("invoiceLimitFilter", filter)
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      atLimitStage: true,
      includeFobInr: data.onDownloadClick,
      ...filter
    }
    objectAPI["onlyShowForUserId"] = onlyShowForUserId

    console.log('filterdata', filterData);
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (data.subadmins && data.subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = data.subadmins
    }
    setshowLoader2(true)
    call('POST', 'getInvoiceDiscountingListForAdmin', objectAPI).then((result) => {
      console.log('running getInvoiceDiscountingListForAdmin api-->', result);
      setDbData(result.listData);
      setTableData(formatDataForTable(result.listData, financiersData))
      setCount(result.countData);
      setFilterCount(result.filterCount)
      setshowLoader(false)
      setshowLoader2(false)
      if (data.onDownloadClick) {
        downloadCSVFile(result.listData)
      }
    }).catch((e) => {
      // console.log('error in getInvoiceDiscountingListForAdmin', e);
      setshowLoader(false)
      setshowLoader2(false)
    });
  }

  useEffect(() => {
    call('POST', 'getFinanciersForInvoice', {}).then(res => {
      // console.log("getFinanciersForInvoiceresp", res);
      setfinanciersData([...res])
    })
  }, [])

  useEffect(() => {
    if (financiersData?.length) {
      getAdminInvoiceLimit()
    }
  }, [refresh, page, filterData, data.subadmins, salesPerson, financiersData])


  // useEffect(() => {
  //   downloadCSVFile(dbData)
  // }, [data.onDownloadClick])


  useEffect(() => {
    let isCacheExist = localStorage.getItem('invoiceLimitFilterData') != "{}"
    let isSearchCacheExist = localStorage.getItem('invoiceSearchFilter') != "{}"
    call('POST', 'getInvoiceDiscountingFiltersForAdmin', { userId, atLimitStage: true }).then(res => {
      console.log("getInvoiceDiscountingFiltersForAdmin then", res);
      if (!isCacheExist) {
        setFilterData(res)
      }
      if (!isSearchCacheExist) {
        setFilteredSearch(res)
      }
    }).catch(err => { })
  }, [])

  async function handleTransactionPopup(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.id, applicationId: itemData.applicationId
    })
    console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  function formatDataForTable(data, financiers) {
    try {
      let tableData = []
      let row = []
      for (let index = 0; index < data.length; index++) {
        const item = data[index];

        let allDeniedCount = 0
        let allApprovedCount = 0
        let buyersCredit = []

        if (item.buyers_credit) {
          try {
            buyersCredit = JSON.parse(item.buyers_credit)
          } catch (error) {
            console.log("errinnnnnnnnnnnnn", item);
          }
          for (let index = 0; index < buyersCredit.length; index++) {
            const j = buyersCredit[index];
            if (j.financierAction === "deny") {
              allDeniedCount += 1
            }
            else {
              allApprovedCount += 1
            }
          }
        }

        row[0] = moment(item.applicationUpdatedAt).format('DD/MM/YYYY')

        row[1] = <label className='cursor font-size-13 font-wt-600'
          onClick={() => {
            window.location = `/supplierDetails?id=${item.user_id}`
          }}
        >{item.supplierName}</label>
        row[2] = <label className='cursor font-size-13 font-wt-600'
          onClick={() => {
            window.location = `/buyerManagement?viewBuyerId=${item.id}&viewUserTypeId=19&viewUserId=${item.user_id}`
          }}
        >{`${item.buyerName} (${item.buyerCountryName})`}</label>
        row[3] = <div class="dropdown w-100" >
          <label class="font-wt-600 font-size-13 cursor" onClick={() => {
            togglesubadminPopup({
              show: true,
              data: [],
              userId: item.user_id
            })
          }}>
            {item.leadAssignToName || '-'}
          </label>

        </div>

        let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
        let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
        let selectedLenderSharedStatus = item.selectedLenderSharedStatus ? item.selectedLenderSharedStatus.split(",") : []


        let unsendFinList = []

        for (let index = 0; index < financiers.length; index++) {
          const element = financiers[index];
          let isAlreadySent = false
          for (let j = 0; j < selectedLenderId.length; j++) {
            if (element.id / 1 == selectedLenderId[j] / 1 && selectedLenderSharedStatus?.[j] / 1 == 1) {
              isAlreadySent = true
            }
          }
          if (!isAlreadySent) {
            unsendFinList.push(element)
          }
        }
        let chatRoomIds = item.chatRoomIds?.toString()?.split(",") || []
        let chatRoomUsers = item.chatRoomUsers?.toString()?.split(",") || []
        let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.toString()?.split(",") || []
        let lastMessageIds = item.lastMessageIds?.toString()?.split(",") || []

        row[4] = <div
          className=''
        >
          {selectedLenderName.length ? selectedLenderName.map((key, j) => {
            if (selectedLenderSharedStatus[j] / 1 != 1) { return null }
            let isApprovedByFinancier = buyersCredit?.filter(i => {
              if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "Approved") {
                return i
              }
            })?.[0]
            let isRejectedByFinancier = buyersCredit?.filter(i => {
              if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "deny") {
                return i
              }
            })?.[0]
            let isOnlyRemarkProvidedByFinancier = false
            if (!isApprovedByFinancier && !isRejectedByFinancier) {
              isOnlyRemarkProvidedByFinancier = item.buyersRemark?.[selectedLenderId[j] / 1]?.["isVisible"] ? true : false
            }
            let openChatRoomIndx = null
            chatRoomUsers.forEach((u, i) => {
              if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
                openChatRoomIndx = i
              }
            })
            // console.log("itembuyerNameeeeeeeeeeeeeeeee", selectedLenderId[j], item.buyerName, chatRoomIds, chatRoomUsers, chatRoomUnreadMsgCount, openChatRoomIndx);

            return (
              <div
                className={`position-relative cursor ${isOnlyRemarkProvidedByFinancier ? ' textOrange ' : '   '}
              ${isApprovedByFinancier ? " text2ECC71 " : ''} ${isRejectedByFinancier ? ' text-danger ' : '   '}`} >
                {/* <label className='font-wt-600 font-size-22 position-absolute cursor' style={{ top: "-0.8rem" }} >{`.`}</label> */}
                <img className='cursor'
                  onClick={async () => {
                    let reqObj = {
                      userTypeId,
                      senderId: userId,
                      sellerId: item.created_by,
                      receiverId: selectedLenderId[j],
                      textMessage: 'Hii',
                      chat_room_name: "CHAT" + new Date().getTime(),
                      includeAdmins: true,
                      invApplicationId: item.applicationId,
                      receiverParties: selectedLenderId[j],
                      dontSendInitialMsg: true
                    }
                    setshowLoader(true)
                    let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                    setshowLoader(false)
                    getChatDetails({
                      chat_room_id: apiResp.id,
                      loggedInUser: userId
                    })
                    setSelectedChat({
                      chatRoomId: apiResp.id,
                      receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                      invApplicationId: item.applicationId,
                      parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                      userId: userId,
                      isChatOpen: true,
                      receiverId: selectedLenderId[j],
                      sellerId: item.created_by
                    })
                  }}
                  src={lastMessageIds[openChatRoomIndx] && lastMessageIds[openChatRoomIndx] != "null" ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                <label
                  onClick={() => { openApplication(null, 2, item) }} className='ml-3 font-size-14  font-wt-600 cursor' >{`${key}`}
                  <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
              </div>
            )
          }) : "NA"}
          {unsendFinList.length ? unsendFinList.map((i, j) => {
            return (<div
              onClick={async () => {
                setshowLoader(true)
                let reason = await call('POST', 'getReasonWhyApplicationNotSent', { quoteId: item.applicationId, lenderId: i.id })
                setshowLoader(false)
                if (typeof (reason) != 'object') {
                  reason = [reason]
                }
                let reasonTxt = reason.length ? `
                Why application wasn't sent -
                  ${reason.map((i, j) => {
                  return (j + 1 + "." + i + "\n")
                })}
                ` : ''
                swal({
                  title: "Are you sure?",
                  text: `                  
                  Do you want to send [${item.supplierName} - ${item.buyerName}] application to ${i.name} 
                  ${reasonTxt}`,
                  icon: "warning",
                  buttons: ["No", "Yes"],
                  dangerMode: true,
                })
                  .then((yes) => {
                    if (yes) {
                      setshowLoader(true)
                      call('POST', 'sendApplicationToFinancierById', {
                        quoteId: item.applicationId, buyerId: item.id,
                        userId: item.user_id, lenderId: i.id
                      }).then((res) => {
                        setshowLoader(false)
                        swal(res, {
                          icon: "success",
                        });
                        setRefresh(refresh + 1)
                      }).catch(err => { setshowLoader(false); toastDisplay("Something went wrong", "error") })
                    }
                  });
              }}
              className={`position-relative cursor `}>
              <label className='font-wt-600 font-size-22 position-absolute cursor text-C0C0C0' style={{ top: "-0.8rem" }} >{`.`}</label>
              <label className='ml-3 font-size-14  font-wt-600 cursor text-C0C0C0' >{`${i.name}`}</label>
            </div>)
          }) : null}
        </div>

        row[5] = item.termsOfPayment


        row[6] =
          allDeniedCount / 1 == selectedLenderName.length / 1 ?
            <>
              <button type="button"
                onClick={() => { handleTransactionPopup(item) }}
                class={`rejected text-white border-0 `}>
                {"Rejected"}
              </button>
              <div class="ellipsis-container">
                <p
                  onClick={async () => {
                    setshowLoader(true)
                    let resp = await call('POST', 'getAdminRemarks', { invApplicationId: item.applicationId })
                    setshowLoader(false)
                    setInternalRemarks(resp)
                    if (resp.length) {
                      setInternalRemarkPopup({ show: true, invApplicationId: item.applicationId })
                    }
                    else {
                      toastDisplay('No remarks added yet', "info")
                    }
                  }}
                  className='fs-16 fw-800 cursor mt-2 ml-2 ellipsis-text' >{item.lastInternalRemark || "Remark"}</p>
              </div>
            </> :
            moment().diff(moment(item.applicationCreatedAt), "days") >= 60 &&
              (!item.termSheetSignedByExporter || !item.termSheetSignedByBank) ?
              <>
                <button type="button"
                  onClick={() => { handleTransactionPopup(item) }}
                  class={`expiredStatus text-white border-0 `}>
                  {"Expired"}
                </button>
                <div class="ellipsis-container">
                  <p
                    onClick={async () => {
                      setshowLoader(true)
                      let resp = await call('POST', 'getAdminRemarks', { invApplicationId: item.applicationId })
                      setshowLoader(false)
                      setInternalRemarks(resp)
                      if (resp.length) {
                        setInternalRemarkPopup({ show: true, invApplicationId: item.applicationId })
                      }
                      else {
                        toastDisplay('No remarks added yet', "info")
                      }
                    }}
                    className='fs-16 fw-800 cursor mt-2 ml-2 ellipsis-text' >{item.lastInternalRemark || "Remark"}</p>
                </div>
              </> :
              (item.termSheetSignedByExporter && item.termSheetSignedByBank) ?
                <>
                  <button type="button"
                    onClick={() => { handleTransactionPopup(item) }}
                    class={`approved text-white border-0 `}>
                    {"Approved"}
                  </button>
                  <div class="ellipsis-container">
                    <p
                      onClick={async () => {
                        setshowLoader(true)
                        let resp = await call('POST', 'getAdminRemarks', { invApplicationId: item.applicationId })
                        setshowLoader(false)
                        setInternalRemarks(resp)
                        if (resp.length) {
                          setInternalRemarkPopup({ show: true, invApplicationId: item.applicationId })
                        }
                        else {
                          toastDisplay('No remarks added yet', "info")
                        }
                      }}
                      className='fs-16 fw-800 cursor mt-2 ml-2 ellipsis-text' >{item.lastInternalRemark || "Remark"}</p>
                  </div>
                </> :
                <>
                  <button type="button" style={!item.adminReview ? { width: '8rem' } : {}}
                    onClick={() => { handleTransactionPopup(item) }}
                    class={`inprogress text-white border-0 `}>
                    {item.adminReview ? "Inprogress" : "Review Pending"}
                  </button>
                  <div class="ellipsis-container">
                    <p
                      onClick={async () => {
                        setshowLoader(true)
                        let resp = await call('POST', 'getAdminRemarks', { invApplicationId: item.applicationId })
                        setshowLoader(false)
                        setInternalRemarks(resp)
                        if (resp.length) {
                          setInternalRemarkPopup({ show: true, invApplicationId: item.applicationId })
                        }
                        else {
                          toastDisplay('No remarks added yet', "info")
                        }
                      }}
                      className='fs-16 fw-800 cursor mt-2 ml-2 ellipsis-text' >{item.lastInternalRemark || "Remark"}</p>
                  </div>
                </>
        if (!item.adminReview) {
          row[99] = <p className="font-size-12 text-color-value ml-3">
            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
              Application uploaded by supplier,</b> </span>
            <label
              onClick={() => {
                let tempUserTokenDetails = userTokenDetails
                tempUserTokenDetails["type_id"] = 19
                tempUserTokenDetails["user_id"] = item.user_id
                tempUserTokenDetails["email"] = item.supplierEmailId
                tempUserTokenDetails["userName"] = item.supplierName
                localStorage.setItem("UserDetails", JSON.stringify(tempUserTokenDetails))
                localStorage.setItem("appDetails", JSON.stringify(item))
                window.location = `/applyLimitV2?adminEdit=true`
              }}
              className="text-color1 text-decoration-underline cursor mr-2">Select financiers to send</label>
          </p>
        }
        else if (!item.buyers_credit) {
          row[99] = <p className="font-size-12 text-color-value ml-3">
            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
              Application sent by supplier, waiting for quote from financier</b> </span>
          </p>
        }

        if (item.termSheet && !item.termSheetSignedByExporter) {
          row[99] = <p className="font-size-12 text-color-value ml-3">
            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
              Term sheet sent by financier</b> </span>
          </p>
        }

        tableData.push(row)
        row = []

      }
      return tableData
    } catch (error) {
      console.log("errinformatDataForTable", error);
      return []
    }
  }

  function openApplication(indx, tabIndx, dbDataObj) {
    let item = dbDataObj || dbData[indx]
    window.location = `/seeQuotes?buyer=${item.id}`;
    localStorage.setItem("applicationId", item.applicationId)
    localStorage.setItem("invIfAppliedNo", item.invRefNo)
    localStorage.setItem("isAdmin", true)
    localStorage.setItem("defaultTabForAdmin", tabIndx)
    localStorage.setItem("selectedLenderName", item.selectedLenderName)
    localStorage.setItem("documentStatus", item.documentStatus)

    // setting manual user id & email for user
    localStorage.setItem("manualUserId", item.user_id)
    localStorage.setItem("manualUserEmail", item.supplierEmailId)
    localStorage.setItem("manualApplicantName", item.supplierName)
  }

  // async function downloadCSVFile(dbData) {
  //   try {
  //     if (!dbData.length) {
  //       return toastDisplay("Data not found to download", "info")
  //     }
  //     if (dbData.length > subAdminDataDownloadLimit) {
  //       let action = `Download ${dbData.length} datasets from Export Factoring > Limit`
  //       let checkReqApiResp = await call('POST', 'checkActionRequestFromAdmin', { userId, action })
  //       if (!checkReqApiResp.accessGranted) {
  //         swal({
  //           title: "Are you sure!",
  //           text: `In order to download ${dbData.length} datasets, you need approval from admin, do you want to raise approval request for the same?`,
  //           icon: "warning",
  //           buttons: ["No", "Yes"],
  //           dangerMode: true,
  //         })
  //           .then(async (yes) => {
  //             if (yes) {
  //               let raiseReqApiResp = await call('POST', 'raiseActionRequestToAdmin', { userId, action })
  //               if (raiseReqApiResp.requestRaised) {
  //                 toastDisplay('Request raised successfully', 'success')
  //               }
  //               else if (raiseReqApiResp.requestAlreadyExists) {
  //                 toastDisplay('Request already exists', 'success')
  //               }
  //             }
  //           });
  //         return
  //       }
  //     }
  //     const rows = [];
  //     rows.push(`"Date","Supplier","Organization Type","Total FOB Value(in INR)","Total Shipments","Supplier City","IEC No","PAN No","Industry","Buyer","Buyer HS Code","Buyer Product Details","Financer","Terms of Payment","Buyer Currency","Buyer Country Name","Buyer Duns No","Previous Year Annual Sale","Expected Annual Sale","Required Limit","Required Limit Currency","Expected Date of Shipment","Status","Stenn Remark Date","Stenn Limit","Stenn Action","Stenn Remark","Modifi Remark Date","Modifi Limit","Modifi Action","Modifi Remark","Drip Remark Date","Drip Limit","Drip Action","Drip Remark","Credlix Remark Date","Credlix Limit","Credlix Action","Credlix Remark","Tradewind Remark Date","Tradewind Limit","Tradewind Action","Tradewind Remark","INCOMLEND Remark Date","INCOMLEND Limit","INCOMLEND Action","INCOMLEND Remark","Internal Remarks"\r\n`)
  //     const chunkSize = 200;
  //     let currentPage = 0;
  //     let totalChunks = Math.ceil(dbData.length / chunkSize);
  //     let csvString = `"Date","Supplier","Organization Type","Total FOB Value(in INR)","Total Shipments","Supplier City","IEC No","PAN No","Industry","Buyer","Buyer HS Code","Buyer Product Details","Financer","Terms of Payment","Buyer Currency","Buyer Country Name","Buyer Duns No","Previous Year Annual Sale","Expected Annual Sale","Required Limit","Required Limit Currency","Expected Date of Shipment","Status","Stenn Remark Date","Stenn Limit","Stenn Action","Stenn Remark","Modifi Remark Date","Modifi Limit","Modifi Action","Modifi Remark","Drip Remark Date","Drip Limit","Drip Action","Drip Remark","Credlix Remark Date","Credlix Limit","Credlix Action","Credlix Remark","Tradewind Remark Date","Tradewind Limit","Tradewind Action","Tradewind Remark","INCOMLEND Remark Date","INCOMLEND Limit","INCOMLEND Action","INCOMLEND Remark","Internal Remarks"\r\n`
  //     while (currentPage < totalChunks) {
  //       const chunkData = dbData.slice(currentPage * chunkSize, (currentPage + 1) * chunkSize);
  //       for (let i = 0; i < chunkData.length; i++) {
  //         let element = chunkData[i]

  //         let allDeniedCount = 0
  //         if (element.buyers_credit) {
  //           for (let index = 0; index < JSON.parse(element.buyers_credit).length; index++) {
  //             const j = JSON.parse(element.buyers_credit)[index];
  //             if (j.financierAction === "deny") {
  //               allDeniedCount += 1
  //             }
  //           }
  //         }

  //         let rowString = `"${moment(element.applicationUpdatedAt).format('DD/MM/YYYY')}","${element.supplierName || 'NA'}","${element.organization_type || 'NA'}","${element.fobInrFromKarzaIec || 'NA'}","${element.totalShipments || 'NA'}","${element.supplierCompanyCity || 'NA'}","${element.supplierIecNo || 'NA'}","${element.supplierPanNo || 'NA'}","${element.supplierIndustryType || 'NA'}","${element.buyerName || 'NA'}","${element.buyerHsnCode || 'NA'}","${element.productDetails || 'NA'}","${element?.selectedLenderName?.length ? element?.selectedLenderName?.split(",").join("||") : 'NA'}","${element.termsOfPayment || "NA"}","${element?.buyerCurrency || "NA"}","${element.buyerCountryName || "NA"}","${element.buyerDUNSNo || "NA"}","${element.previousAnnualSale || "NA"}","${element.currentAnnualSale || "NA"}","${element.requiredLimit || "NA"}","${element.requiredLimitCurrency || "NA"}","${element.expShipmentDate || "NA"}",
  //       ${allDeniedCount / 1 == element?.selectedLenderName?.split(",")?.length / 1 ?
  //             "Rejected" :
  //             (moment().diff(moment(element.applicationCreatedAt), "days") >= 60 &&
  //               (!element.termSheetSignedByExporter || !element.termSheetSignedByBank)) ?
  //               "Expired" :
  //               (element.termSheetSignedByExporter && element.termSheetSignedByBank) ?
  //                 "Approved" : "Inprogress"}`
  //         let buyersCredit = element.buyers_credit ? JSON.parse(element.buyers_credit) : []
  //         let stennQuoteDetails = buyersCredit.filter((i, j) => {
  //           if (i.lender_id / 1 == 51) {
  //             return i
  //           }
  //         })?.[0] || {}
  //         let modifiQuoteDetails = buyersCredit.filter((i, j) => {
  //           if (i.lender_id / 1 == 748 || i.lender_id / 1 == 5035) {
  //             return i
  //           }
  //         })?.[0] || {}
  //         let dripQuoteDetails = buyersCredit.filter((i, j) => {
  //           if (i.lender_id / 1 == 972) {
  //             return i
  //           }
  //         })?.[0] || {}
  //         let tradewindQuoteDetails = buyersCredit.filter((i, j) => {
  //           if (i.lender_id / 1 == 1140) {
  //             return i
  //           }
  //         })?.[0] || {}
  //         let credlixQuoteDetails = buyersCredit.filter((i, j) => {
  //           if (i.lender_id / 1 == 73) {
  //             return i
  //           }
  //         })?.[0] || {}
  //         let incomlendQuoteDetails = buyersCredit.filter((i, j) => {
  //           if (i.lender_id / 1 == 1244) {
  //             return i
  //           }
  //         })?.[0] || {}
  //         if (stennQuoteDetails?.lender_id) {
  //           rowString += `,"${moment(stennQuoteDetails.assignDate).format('DD/MM/YYYY HH:MM')}","${stennQuoteDetails.financeLimit} ${stennQuoteDetails.financeLimitCurrency}","${stennQuoteDetails.financierAction}","${removeNextLine(stennQuoteDetails.assignRemark)}"`
  //         }
  //         else {
  //           rowString += `,,,,`
  //         }
  //         if (modifiQuoteDetails?.lender_id) {
  //           rowString += `,"${moment(modifiQuoteDetails.assignDate).format('DD/MM/YYYY HH:MM')}","${modifiQuoteDetails.financeLimit} ${modifiQuoteDetails.financeLimitCurrency}","${modifiQuoteDetails.financierAction}","${removeNextLine(modifiQuoteDetails.assignRemark)}"`
  //         }
  //         else {
  //           rowString += `,,,,`
  //         }
  //         if (dripQuoteDetails?.lender_id) {
  //           rowString += `,"${moment(dripQuoteDetails.assignDate).format('DD/MM/YYYY HH:MM')}","${dripQuoteDetails.financeLimit} ${dripQuoteDetails.financeLimitCurrency}","${dripQuoteDetails.financierAction}","${removeNextLine(dripQuoteDetails.assignRemark)}"`
  //         }
  //         else {
  //           rowString += `,,,,`
  //         }
  //         if (tradewindQuoteDetails?.lender_id) {
  //           rowString += `,"${moment(tradewindQuoteDetails.assignDate).format('DD/MM/YYYY HH:MM')}","${tradewindQuoteDetails.financeLimit} ${tradewindQuoteDetails.financeLimitCurrency}","${tradewindQuoteDetails.financierAction}","${removeNextLine(tradewindQuoteDetails.assignRemark)}"`
  //         }
  //         else {
  //           rowString += `,,,,`
  //         }
  //         if (credlixQuoteDetails?.lender_id) {
  //           rowString += `,"${moment(credlixQuoteDetails.assignDate).format('DD/MM/YYYY HH:MM')}","${credlixQuoteDetails.financeLimit} ${credlixQuoteDetails.financeLimitCurrency}","${credlixQuoteDetails.financierAction}","${removeNextLine(credlixQuoteDetails.assignRemark)}"`
  //         }
  //         else {
  //           rowString += `,,,,`
  //         }
  //         if (incomlendQuoteDetails?.lender_id) {
  //           rowString += `,"${moment(incomlendQuoteDetails.assignDate).format('DD/MM/YYYY HH:MM')}","${incomlendQuoteDetails.financeLimit} ${incomlendQuoteDetails.financeLimitCurrency}","${incomlendQuoteDetails.financierAction}","${removeNextLine(incomlendQuoteDetails.assignRemark)}"`
  //         }
  //         else {
  //           rowString += `,,,,`
  //         }

  //         // Add internal remarks start
  //         let adminRemarks = await call('POST', 'getAdminRemarks', { invApplicationId: element.applicationId, showUpto3: true })
  //         // let adminRemarks = await call('POST', 'getAdminRemarks', { invApplicationId: dbData.map(d => d.applicationId), showUpto3: true });

  //         for (let index = 0; index < adminRemarks.length; index++) {
  //           const element = adminRemarks[index];
  //           rowString += `${element.contact_person}(${element.remark})(${moment(element.createdAt).format("YYYY-MM-DD hh:mm a")})

  //         `
  //         }
  //         // Add internal remarks end
  //         rows.push(rowString)
  //         // rowString += `\r\n`
  //         // rowString = rowString.replace(/(\n)/gm, "")
  //         // csvString += rowString
  //       }
  //       currentPage++;
  //       // console.log("csvString====================>", csvString);
  //       // let link = document.createElement('a');
  //       // link.style.display = 'none';
  //       // link.setAttribute('target', '_blank');
  //       // link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
  //       // link.setAttribute('download', `InvoiceLimitList.csv`);
  //       if (currentPage === totalChunks) {
  //         // const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  //         // const link = document.createElement('a');
  //         // link.href = URL.createObjectURL(blob);
  //         // link.setAttribute('download', 'InvoiceLimitList.csv');
  //         // link.click();
  //         // document.body.appendChild(link);
  //         // link.click();
  //         // document.body.removeChild(link);

  //         const csvString = rows.join("\r\n");
  //         const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  //         const link = document.createElement('a');
  //         link.href = URL.createObjectURL(blob);
  //         link.setAttribute('download', 'InvoiceLimitList.csv');
  //         link.click();
  //       }
  //     }


  //   } catch (error) {
  //     console.log("error in downloadCSVFile", error);
  //   }
  // }




  //---------------***********-------------------



  // async function downloadCSVFile(dbData) {
  //   try {
  //     if (!dbData.length) {
  //       return toastDisplay("Data not found to download", "info");
  //     }

  //     if (dbData.length > subAdminDataDownloadLimit) {
  //       let action = `Download ${dbData.length} datasets from Export Factoring > Limit`;
  //       let checkReqApiResp = await call('POST', 'checkActionRequestFromAdmin', { userId, action });
  //       if (!checkReqApiResp.accessGranted) {
  //         swal({
  //           title: "Are you sure!",
  //           text: `In order to download ${dbData.length} datasets, you need approval from admin. Do you want to raise an approval request for the same?`,
  //           icon: "warning",
  //           buttons: ["No", "Yes"],
  //           dangerMode: true,
  //         }).then(async (yes) => {
  //           if (yes) {
  //             let raiseReqApiResp = await call('POST', 'raiseActionRequestToAdmin', { userId, action });
  //             if (raiseReqApiResp.requestRaised) {
  //               toastDisplay('Request raised successfully', 'success');
  //             } else if (raiseReqApiResp.requestAlreadyExists) {
  //               toastDisplay('Request already exists', 'success');
  //             }
  //           }
  //         });
  //         return;
  //       }
  //     }

  //     const chunkSize = 500; // Larger chunks for better efficiency
  //     let currentPage = 0;
  //     const totalChunks = Math.ceil(dbData.length / chunkSize);
  //     const rows = [];

  //     while (currentPage < totalChunks) {
  //       const chunkData = dbData.slice(currentPage * chunkSize, (currentPage + 1) * chunkSize);

  //       for (const element of chunkData) {
  //         let allDeniedCount = 0;
  //         let buyersCredit = element.buyers_credit ? JSON.parse(element.buyers_credit) : [];

  //         if (buyersCredit.length) {
  //           allDeniedCount = buyersCredit.filter((j) => j.financierAction === "deny").length;
  //         }

  //         const rowData = [
  //           moment(element.applicationUpdatedAt).format('DD/MM/YYYY'),
  //           element.supplierName || 'NA',
  //           element.organization_type || 'NA',
  //           element.fobInrFromKarzaIec || 'NA',
  //           element.totalShipments || 'NA',
  //           element.supplierCompanyCity || 'NA',
  //           element.supplierIecNo || 'NA',
  //           element.supplierPanNo || 'NA',
  //           element.supplierIndustryType || 'NA',
  //           element.buyerName || 'NA',
  //           element.buyerHsnCode || 'NA',
  //           element.productDetails || 'NA',
  //           element.selectedLenderName ? element.selectedLenderName.split(",").join("||") : 'NA',
  //           element.termsOfPayment || 'NA',
  //           element.buyerCurrency || 'NA',
  //           element.buyerCountryName || 'NA',
  //           element.buyerDUNSNo || 'NA',
  //           element.previousAnnualSale || 'NA',
  //           element.currentAnnualSale || 'NA',
  //           element.requiredLimit || 'NA',
  //           element.requiredLimitCurrency || 'NA',
  //           element.expShipmentDate || 'NA',
  //           allDeniedCount / 1 === buyersCredit.length / 1
  //             ? "Rejected"
  //             : moment().diff(moment(element.applicationCreatedAt), "days") >= 60 &&
  //               (!element.termSheetSignedByExporter || !element.termSheetSignedByBank)
  //               ? "Expired"
  //               : element.termSheetSignedByExporter && element.termSheetSignedByBank
  //                 ? "Approved"
  //                 : "Inprogress"
  //         ];

  //         // Add lender details
  //         const lenders = [
  //           { id: 51, data: buyersCredit },
  //           { id: 748, data: buyersCredit },
  //           { id: 972, data: buyersCredit },
  //         ];
  //         lenders.forEach((lender) => {
  //           const lenderDetails = lender.data.find((j) => j.lender_id === lender.id) || {};
  //           rowData.push(
  //             lenderDetails.assignDate
  //               ? moment(lenderDetails.assignDate).format('DD/MM/YYYY HH:mm')
  //               : 'NA',
  //             lenderDetails.financeLimit || 'NA',
  //             lenderDetails.financeLimitCurrency || 'NA',
  //             lenderDetails.financierAction || 'NA'
  //           );
  //         });

  //         // Handle remarks
  //         let adminRemarks = await call('POST', 'getAdminRemarks', {
  //           invApplicationId: element.applicationId,
  //           showUpto3: true,
  //         });
  //         const remarksString = adminRemarks
  //           .map(
  //             (remark) =>
  //               `${remark.contact_person}(${remark.remark})(${moment(remark.createdAt).format(
  //                 "YYYY-MM-DD hh:mm a"
  //               )})`
  //           )
  //           .join(" || ");
  //         rowData.push(remarksString || 'NA');

  //         rows.push(rowData.join(","));
  //       }

  //       currentPage++;
  //     }

  //     // Create Blob
  //     const csvHeader =
  //             `"Date","Supplier","Organization Type","Total FOB Value(in INR)","Total Shipments","Supplier City","IEC No","PAN No","Industry","Buyer","Buyer HS Code","Buyer Product Details","Financer","Terms of Payment","Buyer Currency","Buyer Country Name","Buyer Duns No","Previous Year Annual Sale","Expected Annual Sale","Required Limit","Required Limit Currency","Expected Date of Shipment","Status","Stenn Remark Date","Stenn Limit","Stenn Action","Stenn Remark","Modifi Remark Date","Modifi Limit","Modifi Action","Modifi Remark","Drip Remark Date","Drip Limit","Drip Action","Drip Remark","Credlix Remark Date","Credlix Limit","Credlix Action","Credlix Remark","Tradewind Remark Date","Tradewind Limit","Tradewind Action","Tradewind Remark","INCOMLEND Remark Date","INCOMLEND Limit","INCOMLEND Action","INCOMLEND Remark","Internal Remarks"\r\n`



  //     const blob = new Blob([csvHeader + rows.join("\r\n")], { type: 'text/csv;charset=utf-8;' });

  //     // Download Blob
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.setAttribute('download', 'InvoiceLimitList.csv');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.log("Error in downloadCSVFile", error);
  //   }
  // }


  // async function downloadCSVFile() {
  //   try {
  //     const response = await fetch('http://localhost:3212/generate-csv', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // Include token in Authorization header
  //       },
  //       body: JSON.stringify({ dbData }), // Wrap dbData in an object
  //     });

  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const link = document.createElement('a');
  //       link.href = URL.createObjectURL(blob);
  //       link.setAttribute('download', 'InvoiceLimitList.csv');
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else {
  //       console.error("Failed to download CSV:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error in handleDownload:", error);
  //   }
  // }





  async function downloadCSVFile(dbData) {
    try {
      if (!dbData.length) {
        return toastDisplay("Data not found to download", "info");
      }

      if (dbData.length > subAdminDataDownloadLimit) {
        const action = `Download ${dbData.length} datasets from Export Factoring > Limit`;
        const checkReqApiResp = await call('POST', 'checkActionRequestFromAdmin', { userId, action });
        if (!checkReqApiResp.accessGranted) {
          const userConfirmed = await swal({
            title: "Are you sure!",
            text: `In order to download ${dbData.length} datasets, you need approval from admin. Do you want to raise an approval request for the same?`,
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
          });

          if (userConfirmed) {
            const raiseReqApiResp = await call('POST', 'raiseActionRequestToAdmin', { userId, action });
            if (raiseReqApiResp.requestRaised) {
              toastDisplay('Request raised successfully', 'success');
            } else if (raiseReqApiResp.requestAlreadyExists) {
              toastDisplay('Request already exists', 'success');
            }
          }
          return;
        }
      }

      // Transform data into CSV rows
      const rows = dbData.map((element) => {
        const allDeniedCount = (element.buyers_credit ? JSON.parse(element.buyers_credit) : []).filter(
          (j) => j.financierAction === "deny"
        ).length;

        const status =
          allDeniedCount === element.buyers_credit?.length
            ? "Rejected"
            : moment().diff(moment(element.applicationCreatedAt), "days") >= 60 &&
              (!element.termSheetSignedByExporter || !element.termSheetSignedByBank)
              ? "Expired"
              : element.termSheetSignedByExporter && element.termSheetSignedByBank
                ? "Approved"
                : "Inprogress";

        const buyersCredit = element.buyers_credit ? JSON.parse(element.buyers_credit) : [];
        const lenderDetails = (lenderId) => {
          const lender = buyersCredit.find((j) => j.lender_id === lenderId) || {};
          return [
            lender.assignDate ? moment(lender.assignDate).format('DD/MM/YYYY HH:mm') : 'NA',
            lender.financeLimit || 'NA',
            lender.financeLimitCurrency || 'NA',
            lender.financierAction || 'NA',
          ];
        };

        const lenderIds = [51, 748, 972];
        const lenderColumns = lenderIds.flatMap((id) => lenderDetails(id));

        return [
          moment(element.applicationUpdatedAt).format('DD/MM/YYYY'),
          element.supplierName || 'NA',
          element.organization_type || 'NA',
          element.fobInrFromKarzaIec || 'NA',
          element.totalShipments || 'NA',
          element.supplierCompanyCity || 'NA',
          element.supplierIecNo || 'NA',
          element.supplierPanNo || 'NA',
          element.supplierIndustryType || 'NA',
          element.buyerName || 'NA',
          element.buyerHsnCode || 'NA',
          element.productDetails || 'NA',
          element.selectedLenderName?.split(",").join("||") || 'NA',
          element.termsOfPayment || 'NA',
          element.buyerCurrency || 'NA',
          element.buyerCountryName || 'NA',
          element.buyerDUNSNo || 'NA',
          element.previousAnnualSale || 'NA',
          element.currentAnnualSale || 'NA',
          element.requiredLimit || 'NA',
          element.requiredLimitCurrency || 'NA',
          element.expShipmentDate || 'NA',
          status,
          ...lenderColumns,
        ];
      });

      // Fetch remarks in parallel
      // const remarksPromises = dbData.map((element) =>
      //   call('POST', 'getAdminRemarks', { invApplicationId: element.applicationId, showUpto3: true })
      // );
      // const remarksResults = await Promise.all(remarksPromises);

      // // Append remarks to rows
      // remarksResults.forEach((adminRemarks, index) => {
      //   const remarksString = adminRemarks
      //     .map(
      //       (remark) =>
      //         `${remark.contact_person}(${remark.remark})(${moment(remark.createdAt).format(
      //           "YYYY-MM-DD hh:mm a"
      //         )})`
      //     )
      //     .join(" || ");
      //   rows[index].push(remarksString || 'NA');
      // });

      // Generate and download CSV
      const csv = Papa.unparse(rows, {
        header: true,
        columns: [
          "Date",
          "Supplier",
          "Organization Type",
          "FOB Value (INR)",
          "Total Shipments",
          "City",
          "IEC No",
          "PAN No",
          "Industry Type",
          "Buyer",
          "HSN Code",
          "Product Details",
          "Lenders",
          "Payment Terms",
          "Currency",
          "Country",
          "DUNS No",
          "Previous Annual Sale",
          "Current Annual Sale",
          "Required Limit",
          "Limit Currency",
          "Expected Shipment Date",
          "Status",
          "Lender Details",
          "Remarks",
        ],
      });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'InvoiceLimitList.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error in downloadCSVFile", error);
    }
  }


  const handleMultiSelectchange = async (e, name, val, singleSelect) => {
    if (singleSelect) {
      if (e?.[0]?.id === "temp") {
        console.log('1111111111111111111111111', { ...e[0], [val]: e[0]["typedInput"], id: e[0]["typedInput"] });
        let allsuppliers = suppliersoverall
        let EXPORTER_NAME = e[0]["typedInput"]
        allsuppliers.push({ ...e[0], ["EXPORTER_NAME"]: e[0]["typedInput"], id: e[0]["typedInput"] })
        setsuppliersdata(allsuppliers)
        setsuppliersdataoverall(allsuppliers)
        setdata({
          ...data,
          [name]: EXPORTER_NAME,
          organization_type: (EXPORTER_NAME?.toLowerCase().includes("pvt ltd") || EXPORTER_NAME?.toLowerCase().includes("ltd")) ? 'pvtPubLtd' : EXPORTER_NAME?.toLowerCase().includes("llp") ? 'llp' : ''

        })
      }
      else {
        const exporter = suppliersoverall.find(item => item.EXPORTER_NAME == e?.[0]?.["EXPORTER_NAME"])
        let contact = getContactObject(exporter?.EXTRA_DETAILS || [])
        // const result = contact ? await call('POST', 'checkduplicateEmailId', { email_id: contact["Email ID"] }) : ''

        setdata({
          ...data,
          [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
          contactPerson: contact ? contact["Contact Person"] : "",
          contact_number: contact ? contact["Contact Number"] : "",
          designation: contact ? contact["Designation"] : "",
          email_id: contact ? contact["Email ID"] : "",
          user_address: exporter?.EXPORTER_ADDRESS || "",
          organization_type: (exporter?.EXPORTER_NAME?.toLowerCase().includes("pvt ltd") || exporter?.EXPORTER_NAME?.toLowerCase().includes("ltd")) ? 'pvtPubLtd' : exporter?.EXPORTER_NAME?.toLowerCase().includes("llp") ? 'llp' : ''
        })
        setErrors({
          email_id: ""
        })
      }

    }
    else {
      setdata({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getAdminInvoiceLimit()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  useEffect(() => {
    console.log('Email id', data.email_id)
  }, [data.email_id])
  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === 'email_id') {
      let emailTovalidate = event.target.value
      setdata({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
      if (validateEmail(emailTovalidate)) {
        // const result = await call('POST', 'checkduplicateEmailId', { email_id: event.target.value })
        setdata({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
      } else {
        setdata({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
      }

    }
    // else {
    //   setdata({ ...data, [event.target.name]: event.target.value })
    //   setErrors({ ...errors, [event.target.name]: "" })
    // }





    if (event.target.name === "country" && event.target.value) {
      let tempSelectedCountry = countryData.filter((i) => {
        if (i.sortname === event.target.value) {
          return i
        }
      })?.[0] || {}
      setNewData({ ...newData, [event.target.name]: event.target.value, phoneCode: tempSelectedCountry.phonecode })
      setErrors({ ...errors, [event.target.name]: "" })
      return null
    }
    if (event.target.name === 'selectedCompanyName' && event.target.value) {
      let selectedCompany = searchedCompanys.filter((i) => {
        if (i.entityId === event.target.value) {
          return true
        }
      })?.[0] || {}
      setNewData({ ...newData, [event.target.name]: event.target.value, companyName: selectedCompany.primaryName })
      setErrors({ ...errors, [event.target.name]: "" })
      toggleMoreKYC(false)
      return null
    }
    if (event.target.name === "commodity" && event.target.value) {

      let tempSelectedCommodity = commodityDropDown.filter((i) => {
        if (i.id == event.target.value) {
          return i
        }
      })?.[0] || {}
      console.log("temp", tempSelectedCommodity, event.target.value)
      // setSelectedCommodity(tempSelectedCommodity)
      setNewData({ ...newData, [event.target.name]: event.target.value, id: tempSelectedCommodity.id })
      setErrors({ ...errors, [event.target.name]: "" })
      // console.log("....", e.target.name, "....", e.target.value, '......', tempSelectedCommodity.id)
      return null
    }
    if (event.target.name === "gstDocument" && FieldTypes[newData.workField]?.["typeId"] / 1 != 20) {
      if (!(newData.organizationType && companyTypes[newData.organizationType]["alt"] === "foreign")) {
        toggleMoreKYC(false)
      }
    }
    if (event.target.name === "contactNo") {
      toggleIsMobVerified(false)
    }
    if (event.target.name === "email") {
      toggleIsEmailVerified(false)
    }


    if (event.target.name === "contactPersonDropDown") {
      if (event.target.value === "Other") {
        setNewData({ ...newData, "contactPerson": "", [event.target.name]: event.target.value })
      }
      else {
        setNewData({ ...newData, "contactPerson": [event.target.value], [event.target.name]: event.target.value })
      }
    }
    else {
      setNewData({ ...newData, [event.target.name]: event.target.value })
      setdata({ ...newData, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }

  }

  const [outsideIndiaOrg, setoutSideIndiaOrg] = useState("no")
  const [newData, setNewData] = useState({
    nameTitle: "Mr", phoneCode: "91", tcAccept: false,
    designation: "Director"
  })
  const [stepperProgress, updateStepperProgress] = useState(0);
  const [searchedCompanys, setSearchedCompanys] = useState([])

  const [showMoreKYC, toggleMoreKYC] = useState(false)
  const [commodityDropDown, setCommodityDropDown] = useState([])
  const [countryData, setcountryData] = useState([]);
  const [isEmailVerified, toggleIsEmailVerified] = useState(false);
  const [isMobVerified, toggleIsMobVerified] = useState(false);
  const [panArr, setPanArr] = useState([])

  const handleFilterOptions = async (typedInput, name) => {
    // console.log("typedInput", typedInput);
    if (name === "selectedCompanyName" && typedInput) {
      let entitySearchApiResp = await call('POST', 'searchEntity', { supplierName: typedInput })
      if (entitySearchApiResp?.length) {
        setSearchedCompanys([...entitySearchApiResp])
      }
    }
  }

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
    // axios.get(commodityDropDownUrl + "/getcommoditycategory").then((result) => {
    //   if (result.data.message && result.data.message.length) {
    //     // const values = [...new Set(result.data.message.map(item => item.category))];
    //     setCommodityDropDown(result.data.message);
    //   }
    // });
    call('GET', 'getcommoditycategory')
      .then(result => {
        if (result && result.length) {
          setCommodityDropDown(result);
        }
      })
      .catch(error => {
        console.error("Error in fetching commodity categories", error);

      });
  }, []);

  const handleStepperProgress = (type) => {
    type === "inc" ? updateStepperProgress(stepperProgress + 1) : updateStepperProgress(stepperProgress - 1)
  }
  let userIdToOnboard = queryParams.get("user")

  const onRegister = () => {
    let reqObject = {
      "typeId": FieldTypes[newData.workField]["typeId"],
      "cinDocumentName": newData.cinDocument || null,
      "gstDocumentName": newData.gstDocument || null,
      "iecDocumentName": newData.iecDocument || null,
      "panDocumentName": newData.panDocument || null,
      "aadharDocumentName": newData.aadharDocument || null,
      "organizationType": companyTypes[newData.organizationType]["alt"],
      "companyName": newData.companyName,
      "contactPerson": newData.contactPerson,
      "companyAddress": newData.companyAddress,
      "email": newData.email,
      "contactNo": newData.contactNo,
      "gst_company_detail": newData.gst_company_detail || null,
      "iec_company_detail": newData.iec_company_detail || null,
      "cin_company_detail": newData.cin_company_detail || null,
      "type": FieldTypes[newData.workField]["type"],
      "referralCode": newData.referalCode,
      "password": newData.password,
      "passwordConfirm": newData.repassword,
      "termsAndCondition": true,
      "country": newData.country,
      "industryType": newData.industryType,
      "techType": FieldTypes[newData.workField]["techType"],
      "companyCity": newData.companyCity,
      "companyPostalCode": newData.companyPostalCode,
      "phoneCode": newData.phoneCode,
      "nameTitle": newData.nameTitle,
      "companyState": newData.companyState,
      "designation": newData.designation
    };

    if (userIdToOnboard) {
      reqObject["userIdToOnboard"] = decodeURIComponent(decryptData(userIdToOnboard));
    }
    if (FieldTypes[newData.workField]["typeId"] === 20) {
      reqObject["adminProfile"] = false;
      reqObject["finTechType"] = true;
      reqObject["role"] = FieldTypes[newData.workField]["type"];
      reqObject["status"] = 1;
      if (newData.country != "IN") {
        reqObject["setKycTrue"] = true;
      }
      setshowLoader(true);
      console.log("req obj", reqObject)
      call('POST', 'registration', reqObject).then((result) => {
        setshowLoader(false)
        toastDisplay("Operation success, contact admin for next process", "success")

      }).catch(err => {
        setshowLoader(false)
        toastDisplay("Something went wrong", "error");
      })
    }
    else {
      let formData = new FormData()
      Object.keys(reqObject).forEach(item => {
        formData.append(item, reqObject[item])
      })
      if (commodityList.length) {
        formData.append('commodityList', JSON.stringify(commodityList))
      }
      setshowLoader(true)
      call('POST', 'registration', formData).then((result) => {
        setshowLoader(false)
        toastDisplay("User registered successfully", "success")
        setTimeout(() => {
          window.location = `login?attr1=${encodeURIComponent(encryptData(newData.email))}&attr2=${encodeURIComponent(encryptData(data.password))}`
        }, 1500);
      }).catch(err => {
        setshowLoader(false)
        toastDisplay(err.message || "Something went wrong", "error");
      })
    }
  }


  const handleNext = async () => {
    let err = {}
    if (stepperProgress === 0 && !FieldTypes[data.workField]) {
      err["msg"] = "Select work field"
    }
    else if (stepperProgress === 0) {
      if (outsideIndiaOrg === "yes") {
        return updateStepperProgress(3)
      }
      else {
        return updateStepperProgress(2)
      }
    }
    // if (stepperProgress === 1 && !companyTypes[data.organizationType]) {
    //   err["msg"] = "Select company type"
    // }
    if (stepperProgress === 2) {
      if (showMoreKYC) {
        let validateFields = []
        let isChannelPartner = FieldTypes[newData.workField]?.["typeId"] / 1 == 20
        // console.log("organizationTypeeeeeeeeeeeeeeeeeee", data.organizationType);
        if (!newData?.organizationType?.toString()?.length) {
          return setErrors({ ...errors, organizationType: "Mandatory Field" })
        }
        else {
          if (companyTypes[newData.organizationType]["alt"] === "individual") {
            validateFields = ["aadharDocument", "panDocument"]
          }
          if (companyTypes[newData.organizationType]["alt"] === "soleProprietorship" && !isChannelPartner) {
            // validateFields = ["iecDocument"]
          }
          if (companyTypes[newData.organizationType]["alt"] === "partnership" && !isChannelPartner) {
            // validateFields = ["iecDocument"]
          }
          if (companyTypes[newData.organizationType]["alt"] === "pvtPubLtd" && !isChannelPartner) {
            validateFields = ["cinDocument"]
          }
          if (companyTypes[newData.organizationType]["alt"] === "llp" && !isChannelPartner) {
            // validateFields = ["iecDocument"]
          }
        }
        for (let index = 0; index < validateFields.length; index++) {
          const element = validateFields[index];
          if (!newData[element]) {
            err[element] = "Mandatory field"
          }
        }
      }
      else {
        let isIndividualCompany = companyTypes?.[newData.organizationType]?.["alt"] === "individual"
        let isChannelPartner = FieldTypes[newData.workField]?.["typeId"] / 1 == 20
        if (!isIndividualCompany && !newData.gstDocument && !isChannelPartner && !newData.selectedCompanyName) {
          err["gstDocument"] = "Enter GST Number"
        }
        else if (isChannelPartner && !newData.panDocument) {
          err["panDocument"] = "Enter PAN Number"
        }
        else {
          setshowLoader(true)
          try {
            let apiResp = await call('POST', 'getAndVerifyKYCV2', isChannelPartner ?
              { "pan": newData.panDocument, typeId: FieldTypes[newData.workField]?.["typeId"] / 1 } :
              {
                "gst": newData.gstDocument || undefined, typeId: FieldTypes[newData.workField]?.["typeId"] / 1,
                "entityId": newData.gstDocument ? undefined : newData.selectedCompanyName
              })
            setshowLoader(false)
            if (apiResp) {
              let addressComponents = {}
              setshowLoader(true)
              if (apiResp?.company_address?.length) {
                addressComponents = await call('POST', 'getAddressComponents', { address: apiResp.company_address })
              }
              setshowLoader(false)
              let tempMultPanArr = []
              for (let index = 0; index < apiResp.multiplePans?.length; index++) {
                tempMultPanArr.push({ name: apiResp.multiplePans[index] })
              }
              setPanArr(tempMultPanArr)
              // setNewData({
              //   ...newData,
              //   gstDocument: data.gstDocument || apiResp?.gst,
              //   iecArr: apiResp.iecArr,
              //   organizationType: mapOrganizationTypeWithKarza(apiResp.organizationType),
              //   iecDocument: apiResp.iecArr?.[0]?.["iec"] || null,
              //   panDocument: isChannelPartner ? data.panDocument : apiResp.pan, cinDocument: apiResp.cin || null,
              //   "companyName": apiResp.company_name,
              //   "contactPerson": apiResp.company_name,
              //   "companyAddress": apiResp.company_address,
              //   "companyCity": addressComponents.city,
              //   "companyPostalCode": addressComponents.postalCode,
              //   "country": addressComponents.countrySortName,
              //   "companyState": addressComponents.state,
              //   "gst_company_detail": null,
              //   "iec_company_detail": null,
              //   "cin_company_detail": null,
              //   "type": "finTech",
              //   categoryOfExporters: apiResp?.categoryOfExporters
              // })
              toggleMoreKYC(true)
              return null
            }
          } catch (error) {
            console.log("e", error);
            toggleMoreKYC(true)
            // setShowLoader(false)
            toastDisplay(error, "error")
            return null
          }
        }
      }
    }
    if (stepperProgress === 3) {
      let validateFields = ["companyName", "country", "companyAddress", "companyCity", "companyPostalCode"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!newData[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    // #2
    // if (stepperProgress === 4) {
    //   let validateFields = ["contactPersonDropDown", "contactPerson", "nameTitle", "email", "contactNo", "designation"]
    //   if (!typeId) {
    //     if (!isEmailVerified) {
    //       err["email"] = "Kindly verify email id to proceed"
    //       sendCombinedOTP()
    //     }
    //     else if (!isMobVerified) {
    //       err["contactNo"] = "Kindly verify mobile number to proceed"
    //       sendCombinedOTP()
    //     }
    //   }

    //   for (let index = 0; index < validateFields.length; index++) {
    //     const element = validateFields[index];
    //     if (!data[element]) {
    //       err[element] = "Mandatory field"
    //     }
    //   }
    // }
    // if (stepperProgress === 5) {
    //   let validateFields = ["password", "repassword"]
    //   if (newData.password && newData.password.length < 6) {
    //     err["password"] = "Password should be atleast 6 characters long"
    //   }
    //   else if (newData.password != newData.repassword) {
    //     err["repassword"] = "Password mismatch"
    //   }
    //   for (let index = 0; index < validateFields.length; index++) {
    //     const element = validateFields[index];
    //     if (!newData[element]) {
    //       err[element] = "Mandatory field"
    //     }
    //   }
    // }
    if (!Object.keys(err).length) {
      if (stepperProgress == 4) {
        onRegister()
      }
      // else if (stepperProgress == 2) {
      //   let req = {
      //     "organizationType": companyTypes[data.organizationType]["alt"],
      //     "cinDocumentName": data["cinDocument"],
      //     "gstDocumentName": data["gstDocument"],
      //     "iecDocumentName": data["iecDocument"]
      //   }
      //   if (req.organizationType != "foreign" && req.organizationType != "individual") {
      //     setShowLoader(true)
      //     call('POST', 'getKYCDetail', req).then((result) => {
      //       // console.log("getKYCDetail==>", result);
      //       if (result.company_name != '') {
      //         setData({
      //           ...data,
      //           "companyName": result.company_name,
      //           "contactPerson": result.company_name,
      //           "companyAddress": result.company_address,
      //           "email": result.company_email,
      //           "contactNo": result.company_mobile,
      //           "gst_company_detail": result.gst_company_detail ? result.gst_company_detail : null,
      //           "iec_company_detail": result.iec_company_detail ? result.iec_company_detail : null,
      //           "cin_company_detail": result.cin_company_detail ? result.cin_company_detail : null,
      //           "type": "finTech"
      //         });
      //         toastDisplay("KYC verified successfully", "success");
      //         handleStepperProgress("inc")
      //       }
      //       else {
      //         toastDisplay("Your KYC is Not-Verified", "error");
      //       }
      //       setShowLoader(false)
      //     }).catch((e) => {
      //       setShowLoader(false)
      //       toastDisplay("Something went wrong", "error");
      //     })
      //   }
      //   else {
      //     handleStepperProgress("inc")
      //   }
      // }
      else {
        handleStepperProgress("inc")
      }
    }
    else {
      if (stepperProgress != 3) {
        toastDisplay(err["msg"] || "Form validation error", "error")
      }
    }
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", err);
    setErrors(err)
  }


  const handleFieldChange = (index, type) => {
    if (type === "workField") {
      setNewData({ ...newData, [type]: index, organizationType: outsideIndiaOrg === "yes" ? 5 : null })
    }
    else {
      setNewData({ ...newData, [type]: index })
    }
  }
  const [additionalCommodity, setAdditionalCommodity] = useState([]);
  const [addMorecommodity, setAddMoreCommodity] = useState(false)
  const [commodityList, setCommodityList] = useState([])
  const onCancel = (selectedCommodityId, commodityName) => {

    const temp = commodityDropDown.find(item => parseInt(item.id) === parseInt(selectedCommodityId));
    const filteredItems = []
    for (let i of commodityList) {
      if (i.commdCategory !== temp.category && i.commodityName !== commodityName) {
        filteredItems.push(i)
      }
    }
    console.log("filteredItems....", filteredItems)
    setCommodityList(filteredItems)

    // const filteredItems = commodityList.filter(item => item.commdCategory !== temp.category && item.commodityName !== commodityName);
    console.log(filteredItems)
    setCommodityList(filteredItems)


  }


  const onPlus = (id, name) => {
    if (id && name) {

      const temp = commodityDropDown.find(item => parseInt(item.id) === parseInt(id));
      console.log("temp item in add", temp)

      const obj = { category_id: id, commodity_name: name, commodity_pretty_name: name.toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) }
      console.log("object added", obj)
      setCommodityList(prev => [...prev, obj])

      setAddMoreCommodity(true)
    }
    else {
      return
    }
  }
  useEffect(() => { console.log("in use Efeect", commodityList) }, [commodityList])

  const handleAddMore = () => {
    setAdditionalCommodity(prev => [...prev, <CommodityAdd commodityDropDown={commodityDropDown} onPlus={onPlus} setAddMoreCommodity={setAddMoreCommodity} onCancel={onCancel} />])
  }





  return (
    <>
      {addRemarkPopup.show && <div className={`modal fade ${addRemarkPopup.show && "show"}`} style={{ display: "block" }}>
        <div className="modal-dialog modal-md">
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setAddRemarkPopup({ show: false })}></button>
            </div>
            <div className="modal-body text-center">
              <p>Add Remark</p>
              <div className="col-md-10 move-p">
                <textarea rows="4" cols="50" className={"form-control"} placeholder="Write a remark for internal purpose." name="addRemark" value={data.addRemark}
                  onChange={handleChange}
                />
              </div>
              <button type="button"
                onClick={() => {
                  if (!data.addRemark) {
                    return toastDisplay('Kindly add remark to continue', "info")
                  }
                  setshowLoader(true)
                  call('POST', 'addAdminRemark', {
                    userId, remark: data.addRemark, invApplicationId: addRemarkPopup.invApplicationId
                  }).then(async (result) => {
                    toastDisplay("Remark added", "success")
                    setRefresh(refresh + 1)
                    setshowLoader(false)
                    setAddRemarkPopup({ show: false })
                  }).catch((error) => {
                    toastDisplay(error, "error")
                    setshowLoader(false)
                    setAddRemarkPopup({ show: false })
                  })
                }}
                className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                {"Send Remark"}
              </button>
            </div>
          </div>
        </div>
      </div>}
      <SubAdminPopup togglesubadminpopup={togglesubadminPopup} subadminpopup={subadminPopup} setShowLoader={setshowLoader} refreshtable={getAdminInvoiceLimit} />

      <div className={`modal fade ${internalRemarkPopup.show && "show"}`} style={internalRemarkPopup.show ? { display: "block", "zIndex": '1000001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="">
                <i onClick={() => { setInternalRemarkPopup({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 icon-color cursor"></i>
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Internal Remarks</label>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className='d-flex row'>
                {internalRemarks.map((i, j) => {
                  return (
                    <div className='card-layout p-3 mb-4'>
                      <div>
                        <label className='col-7 font-size-14 font-wt-600'>{i.contact_person || "NA"}</label>
                        <label className='col-5 font-size-14 font-wt-600'>{moment(i.createdAt).format("DD/MM/YYYY hh:mm a") || "NA"}</label>
                      </div>
                      <p
                        style={{ whiteSpace: 'pre-wrap' }}
                        className='font-size-15 font-wt-500 mx-3'>{i.remark}</p>
                    </div>
                  )
                })}
              </div>
              <button style={{ height: '2rem', borderRadius: 0, fontSize: 18, background: '#5CB8D3' }} type="button"
                onClick={() => { setdata({ ...data, addRemark: "" }); setInternalRemarkPopup({ show: false }); setAddRemarkPopup({ show: true, invApplicationId: internalRemarkPopup.invApplicationId }) }}
                class={`text-white border-0 w-100 `}>
                {"Add Remark"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showLoader2 && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      {selectSupplierPopup &&
        <FinanceInvoiceModal modalSize={"md"} isCentered={true} limitinvoice={selectSupplierPopup} closeSuccess={() => {
          setselectSupplierPopup(false)
          setdata({
            ...data,
            supplierName: "",
            contactPerson: "",
            contact_number: "",
            designation: "",
            email_id: "",
            user_address: "",
            organization_type: ''
          })
          setSupplierName('')
          setsuppliersdata([])
          setsuppliersdataoverall([])
        }}>
          <div>
            <div className='col-md-12'>


              <MultipleSelect
                isMulti
                Id="Supplier Name"
                Label="Supplier Name"
                selectedvalue="Supplier Name"
                filterOption={() => true}
                onInputChange={(e) => {
                  setSupplierName(e)
                }}
                optiondata={[
                  { EXPORTER_NAME: "Add New Option", id: "addNew" },
                  ...suppliers,
                  // Add "Add New Option" dynamically
                ]}
                onChange={(e) => {
                  const addNewOption = e.find((option) => option.EXPORTER_NAME === "Add New Option");
                  if (addNewOption) {
                    setselectSupplierPopup(false)
                    setboolsignup((prev) => !prev)
                    // Open the URL in a new tab

                  } else {
                    handleMultiSelectchange(e, "supplierName", "EXPORTER_NAME", true);
                  }
                }}
                value={data.supplierName ? [data.supplierName] : []}
                name="supplierName"
                labelKey={"EXPORTER_NAME"}
                valKey={"EXPORTER_NAME"}
                customStyles={{
                  backgroundColor: '#DEF7FF',
                  borderRadius: '10px'
                }}
              />

            </div>
            <div className="col py-2 ">
              <div className="col-md-12 px-0">
                <InputWithSelect isAstrix={true} type={"text"} label={"Contact Person"}
                  selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                  selectName={"name_title"} selectValue={data.name_title}
                  optionLabel={"name"} optionValue={'name'}
                  name={'contactPerson'} value={data.contactPerson} error={errors.contactPerson}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2 ">
              <div className="col-md-12 px-0">

                <InputWithSelect isAstrix={true} type={"text"} label={"Contact Number"}
                  selectData={countrys}
                  selectName={"phone_code"} selectValue={data.phone_code}
                  optionLabel={"phonecode"} optionValue={'phonecode'}
                  name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={true} type={"text"} label={"Designation"}
                  name={"designation"} value={data.designation} error={errors.designation}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={true} type={"text"} label={"Email ID"}
                  name={"email_id"} value={data.email_id} error={errors.email_id}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect
                  isAstrix={true}
                  selectData={companyTypes}
                  optionLabel={'name'}
                  optionValue={'alt'}
                  name={"organization_type"}
                  label={'Organization Type'}
                  value={data.organization_type || ""}
                  onChange={handleChange}
                  error={errors.organization_type} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect
                  isAstrix={true}
                  selectData={industryData}
                  optionLabel={'name'}
                  optionValue={'value'}
                  name={"industry_type"}
                  label={'Industry Type'}
                  value={data.industry_type || ""}
                  onChange={handleChange}
                  error={errors.industry_type} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={false} type={"text"} label={"Address"}
                  name={"user_address"} value={data.user_address} error={errors.user_address}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="d-flex gap-4  px-3 ">
              <button className={`mt-4 new-btn w-40 py-2 px-2 text-white cursor`} onClick={handleValidation}>Create Application</button>
            </div>

          </div>
        </FinanceInvoiceModal>
      }

      {boolsignup && <GlobalSignup

        stepperProgress={stepperProgress}

        formTitles={formTitles}
        FieldTypes={FieldTypes}
        handleFieldChange={handleFieldChange}
        handleStepperProgress={handleStepperProgress}
        updateStepperProgress={updateStepperProgress}
        outsideIndiaOrg={outsideIndiaOrg}
        setoutSideIndiaOrg={setoutSideIndiaOrg}
        newData={newData}
        setNewData={setNewData}
        errors={errors}
        countryData={countryData}
        industryData={industryData}
        additionalCommodity={additionalCommodity}
        addMorecommodity={addMorecommodity}
        handleAddMore={handleAddMore}
        onPlus={onPlus}
        onCancel={onCancel}
        boolsignup={boolsignup}
        setboolsignup={setboolsignup}
        handleChange={handleChange}
        companyTypes={companyTypes}
        searchedCompanys={searchedCompanys}
        handleFilterOptions={handleFilterOptions}
        showMoreKYC={showMoreKYC}
        panArr={panArr}
        commodityDropDown={commodityDropDown}
        isEmailVerified={isEmailVerified}
        handleNext={handleNext}
        setsuppliersdataoverall={setsuppliersdataoverall}
        setsuppliersdata={setsuppliersdata}
        setSupplierName={setSupplierName}

      />}

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoiceLimit" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discounting > Limit"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              <div className='d-flex justify-content-end p-0 '>
                <div className="col-md-3">
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      Id="Select User"
                      Label="Select User"
                      selectedvalue="Select Requirement"
                      optiondata={salesPerson}
                      onChange={(e) => handleMultiSelectchange(e, "subadmins", "id")}
                      value={data.subadmins ? data.subadmins : []}
                      name="subadmins"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                      isCheckableList={true}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                {overallCounts.map((i, j) => {
                  return (
                    <div className='w-15 cursor'
                      onClick={() => {
                        let temp = filterData
                        for (let index = 0; index < temp["Status"]["data"].length; index++) {
                          const element = temp["Status"]["data"][index];
                          if (index / 1 == j / 1) {
                            element["isChecked"] = true
                          }
                          else {
                            element["isChecked"] = false
                          }
                        }
                        temp["Status"]["isFilterActive"] = true
                        setFilterData({ ...temp })
                      }}>
                      <div className='card h-100 dashboard-card shadow-sm align-items-center cursor'>
                        <label className={`cursor value font-wt-600 ${i.class}`}>{filterCount[i.alt] || "NA"}</label>
                        <label className={'cursor font-size-14 font-wt-600 text-color-label'}>{i.name}</label>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className='filter-div'>
                <Filter
                  showDownloadIcon={true} onDownloadClick={() => {
                    setdata({ ...data, onDownloadClick: true })
                  }}
                  filterData={filterData} setFilterData={setFilterData}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} isAdditionalButton={true}>

                  <div className="d-flex gap-4">
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setselectSupplierPopup(true)}>Create New Application</button>
                  </div>

                </Filter>
              </div>

              <div
                style={{}}>
                <NewTable disableAction={false}
                  columns={[{
                    name: "Date", filter: true,
                    filterDataKey: "Date",
                    sort: [
                      { name: "Latest First", selected: filter.sortDateBy === "DESC", onActionClick: () => { setFilter({ ...filter, sortDateBy: 'DESC', sortBuyerName: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Oldest First", selected: filter.sortDateBy === "ASC", onActionClick: () => { setFilter({ ...filter, sortDateBy: "ASC", sortBuyerName: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Supplier", filter: true,
                    filterDataKey: "Exporter Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortExpName === "ASC", onActionClick: () => { setFilter({ ...filter, sortExpName: 'ASC', sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortExpName === "DESC", onActionClick: () => { setFilter({ ...filter, sortExpName: "DESC", sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Buyer", filter: true,
                    filterDataKey: "Buyer Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Admin", filter: true,
                    filterDataKey: "Admins"
                  },
                  {
                    name: "Financer", filter: true, filterDataKey: "Financier Selected"
                  }, {
                    name: "Terms of Payment", filter: false
                  },
                  {
                    name: "Status", filter: true, filterDataKey: "Status", sort: []

                  }
                  ]}


                  data={tableData}
                  options={[
                    { name: "Application", onClick: (indx) => openApplication(indx, 0) },
                    { name: "Documents", onClick: (indx) => openApplication(indx, 1) },
                    { name: "Quote", onClick: (indx) => openApplication(indx, 2) },
                    // { name: "Chat with Supplier", onClick: () => null },
                    // { name: "Chat with Buyer", onClick: () => null },
                    {
                      name: "Add Remark", onClick: (indx) => {
                        setdata({ ...data, addRemark: '' })
                        setAddRemarkPopup({ show: true, invApplicationId: dbData[indx]["applicationId"] })
                      }
                    }
                  ]}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                />
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(InvoiceLimit)