import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const SCFQuote = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("scfQuote");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");




  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getSCFQuoteList', objectAPI).then((result) => {
      console.log('running getWCQuoteList api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBGQuoteList', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getSCFQuoteListFilter', { userId }).then(res => {
      console.log("getWCQuoteListFilterthen", res);
      setFilterData(res)
    }).catch(err => { })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('scfApplicationId', selectedChat.scfApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }


  const [tablecol, setTablecol] = useState(wcQuotesColumn)
  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    // Sync `updatedData` with `tablecol` only when modal is opened for the first time or `tablecol` changes
    useEffect(() => {
      if (showModal) {
        setUpdatedData([...tablecol]);
      }
    }, [showModal]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      // Toggle the checked state of the column
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setUpdatedData(items); // Update reordered columns
    };

    const handleSave = () => {
      // Persist `updatedData` to `tablecol`
      setTablecol([...updatedData]);
      setShowModal(false); // Close modal
    };

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
            width: "20rem",
            paddingTop: "9px",
            paddingBottom: "9px"
          }}
        >
          Customize Columns
        </button>

        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {updatedData.map((ele, index) => (
                            <Draggable key={ele.subColumns} draggableId={ele.subColumns} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="mb-1"
                                    style={{
                                      border: "2px solid #84d4ef",
                                      padding: "8px",
                                      borderRadius: "0.5rem",
                                      backgroundColor: snapshot.isDragging
                                        ? "#d25de9"
                                        : "white",
                                      color: snapshot.isDragging ? "white" : "black",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <img
                                      onClick={() => handleCheck(index)}
                                      className="cursor ml-8"
                                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                                        }.png`}
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                      alt={ele.isChecked ? "Checked" : "Unchecked"}
                                    />
                                    <label style={{ marginLeft: "0.5rem" }}>{ele.subColumns}</label>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="otherFinSCFQuote" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Supply Chain Finance > Quotes"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                <ColumnSelector tablecol={wcQuotesColumn} setTablecol={setTablecol} />
                {Object.values(filterData || {})?.filter(i => {
                  if (i.isFilterActive) { return true }
                })?.length ? null : <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                  {finHighlights.map((i, j) => {
                    return (<div className='d-flex align-items-center mx-2' >
                      <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                      <label className='font-size-13 font-wt-500 mt-2 mx-2' >{i.name}</label>
                    </div>)
                  })}
                </div>}

              </div>

              <div>
                <NewTablev2 columns={tablecol.filter(col => col.isChecked)} >
                  {dbData.map((item, j) => {
                    let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
                    let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
                    let buyersCredit = item.financierQuotes ? JSON.parse(item.financierQuotes) : []
                    let chatRoomIds = item.chatRoomIds?.split(",") || []
                    let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                    let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

                    let allDeniedCount = 0
                    let apporvedCount = 0

                    if (item.financierQuotes) {
                      for (let index = 0; index < JSON.parse(item.financierQuotes).length; index++) {
                        const j = JSON.parse(item.financierQuotes)[index];
                        if (j.financierAction === "deny") {
                          allDeniedCount += 1
                        }
                        else {
                          apporvedCount += 1
                        }
                      }
                    }

                    let supplierNames = []
                    let supplierLimits = []
                    let supplierCount = 0
                    for (let index = 0; index < 11; index++) {
                      if (item.details[`supplierName${index}`]) {
                        supplierCount += 1
                        supplierNames.push(item.details[`supplierName${index}`])
                        supplierLimits.push(item.details[`supplierCreditLimitRequired${index}`])
                      }

                    }

                    return (
                      <tr key={j}>  {tablecol.map((col) => {
                        if (col.isChecked) {
                          return (
                            <td key={col.subColumns}>
                              {col.subColumns === "Date" && (
                                <label className='font-size-13 font-wt-400 text-break'>{moment(item.applicationUpdatedAt).format("DD/MM/YYYY")}</label>)}
                              {col.subColumns === "Buyer Name" && (
                                <label>
                                  <ul className='py-0 pl-3 cursor'>
                                    {supplierNames.map(item => {
                                      return <li >
                                        <div>
                                          <label className='font-size-13 font-wt-400 text-break'>{item}</label>
                                        </div>
                                      </li>
                                    })}
                                  </ul>
                                </label>)}
                              {col.subColumns === "Services selected & limit requested" && (
                                <label>
                                  <ul className='py-0 pl-3 cursor'>
                                    {supplierLimits.map(item => {
                                      return <li >
                                        <div >
                                          <label className='font-size-13 font-wt-400 text-break'>{"$ " + item}</label>
                                        </div>
                                      </li>
                                    })}
                                  </ul>
                                </label>)}
                              {col.subColumns === "Financers Selected" && (
                                <label>
                                  <div
                                    className=''
                                  >
                                    {selectedLenderName.length ? selectedLenderName.map((key, j) => {
                                      let isApprovedByFinancier = buyersCredit?.filter(i => {
                                        if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "Approved") {
                                          return i
                                        }
                                      })?.[0]
                                      let isRejectedByFinancier = buyersCredit?.filter(i => {
                                        if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "deny") {
                                          return i
                                        }
                                      })?.[0]
                                      let openChatRoomIndx = null
                                      chatRoomUsers.forEach((u, i) => {
                                        if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
                                          openChatRoomIndx = i
                                        }
                                      })
                                      return (
                                        <div className='position-relative' >
                                          <img className='cursor'
                                            onClick={async () => {
                                              let reqObj = {
                                                senderId: userId,
                                                receiverId: selectedLenderId[j],
                                                textMessage: 'Hii',
                                                chat_room_name: "CHAT" + new Date().getTime(),
                                                includeAdmins: true,
                                                scf: item.applicationId,
                                                receiverParties: selectedLenderId[j],
                                                dontSendInitialMsg: true
                                              }
                                              setshowLoader(true)
                                              let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                              setshowLoader(false)
                                              getChatDetails({
                                                chat_room_id: apiResp.id,
                                                loggedInUser: userId
                                              })
                                              setSelectedChat({
                                                chatRoomId: apiResp.id,
                                                receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                                scfApplicationId: item.applicationId,
                                                parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                                userId: userId,
                                                isChatOpen: true,
                                                receiverId: selectedLenderId[j]
                                              })
                                            }}
                                            src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                                          <label className={`ml-3 font-size-13 text-color-label font-wt-400 ${selectedLenderId[j] / 1 == item.selectedFinancier / 1 ? " text5CB8D3 " : isApprovedByFinancier ? " text2ECC71 " : " "}  ${isRejectedByFinancier ? ' text-danger ' : '   '} `} >{`${key} `}
                                            <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                                        </div>
                                      )
                                    }) : "NA"}
                                  </div>
                                </label>)}
                              {col.subColumns === "Status" && (
                                <label>
                                  <div className=''>
                                    {apporvedCount ?
                                      <button type="button"
                                        class={`approved text-white border-0 `}>
                                        {"Approved"}
                                      </button> :
                                      (allDeniedCount / 1 == selectedLenderName?.length / 1) ?
                                        <button type="button"
                                          class={`rejected text-white border-0 `}>
                                          {"Rejected"}
                                        </button>
                                        :
                                        <button type="button"
                                          class={`inprogress text-white border-0 `}>
                                          {"Inprogress"}
                                        </button>}
                                  </div>
                                </label>)}
                              {col.subColumns === "Action" && (
                                <label>
                                  <div className='' >
                                    <a className='mt-2'>
                                      {(item.termSheet || item.termSheetLink) ?
                                        <button type="button"
                                          onClick={() => {
                                            window.location = `/scfSignTermsheet`;
                                            localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                          }}
                                          disabled={item.termSheetSignedByExporter}
                                          className={` border-0 mb-2 ${item.termSheetSignedByExporter ? "disabledQuotebtn text-dark" : "text-white enableQuotebtn"}`}>
                                          {item.termSheetSignedByExporter ? "Termsheet Signed" : "Sign Termsheet"}
                                        </button>
                                        :
                                        <button type="button"
                                          onClick={() => {
                                            window.location = `/scfQuoteDetails`;
                                            localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                          }}
                                          disabled={!item.financierQuotes || allDeniedCount / 1 == selectedLenderName?.length / 1 || item.selectedFinancier}
                                          className={` border-0 mb-2 ${(!item.financierQuotes || allDeniedCount / 1 == selectedLenderName?.length / 1 || item.selectedFinancier) ? "disabledQuotebtn text-dark" : "text-white enableQuotebtn"}`}>
                                          {"See Quotes"}
                                        </button>}
                                      {item.selectedFinancier && !item.termSheet && !item.termSheetLink ?
                                        <p className="font-size-12 text-color-value ml-3">
                                          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Quote selected, waiting for Termsheet.</span>
                                        </p>
                                        : item.termSheet || item.termSheetLink ?
                                          <p className="font-size-12 text-color-value ml-3">
                                            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Termsheet sent by financier</span>
                                          </p>
                                          : allDeniedCount / 1 == selectedLenderName?.length / 1 ?
                                            <p className="font-size-12 text-color-value ml-3">
                                              <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 text-danger">Application rejected by all financiers.</span>
                                            </p>
                                            : apporvedCount && !item.selectedFinancier ?
                                              <p className="font-size-12 text-color-value ml-3">
                                                <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Quote received.</span>
                                              </p>
                                              : null}
                                    </a>
                                  </div >
                                </label>)}
                              {col.subColumns === "" && (
                                <label
                                  // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                  className='position-relative'>
                                  <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                    onClick={() => setAction({ show: true, index: j })}
                                    aria-hidden="true"></i>
                                  {action.show && action.index === j ? (
                                    <Action
                                      id={j}
                                      onDismiss={() => setAction({ show: false, index: j })}
                                      options={[
                                        {
                                          name: "Application", icon: "edit.png", onClick: () => {
                                            window.location = `/scfQuoteDetails`;
                                            item["disableFinSelection"] = true
                                            localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                          }
                                        }
                                      ]} />
                                  ) : null}
                                </label>)}
                            </td>)
                        }
                        return null
                      })}
                      </tr>
                    )
                  })}
                </NewTablev2>
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

            </div>
          </main>
        </div>
      </div>

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SCFQuote)