import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../../../partial/sideBarV2";
import HeaderV2 from "../../../partial/headerV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, dataURItoBlobURL, extractNoFromTxt, finLogoByUserId, GetCache, getDocDetails, LCPurposeObject, LCTypesObject, most_used_currencies, multiSelectEventHandler, replaceFileBase64WithFileObj, SetCache, toolTip } from "../../../../utils/myFunctions";
import call from "../../../../service";
import config from "../../../../config.json";
import { FileInput } from "../../../../utils/FileInput";
import AdavancePaymentTab from "../../../InvoiceDiscounting/contract/components/adavancePaymentTab";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, NewTextArea, SelectForTable } from "../../../../utils/newInput";
import LcDetails from "./lcDetails";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { ToastContainer } from "react-toastify";
import { drawEllipsePath } from "pdf-lib";
import axios from "axios";
import { aiServerBackendUrl, environment, platformURL } from "../../../../urlConstants";
import { formatMs, useEventCallback } from "@material-ui/core";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import moment from "moment";
import TutorialPopup from "../../../tutorialPopup";
import avatarUrl from "../../../../utils/makeAvatarUrl";
import LcDetailsV3 from "./lcDetailsV3";
import { documentTable, documentTable3, lcDocumentTable, multipleBuyerTable, multipleLCTable } from "../../../Dashboard/TableJson";
import NewTablev2 from "../../../../utils/newTablev2";
import MultipleSelect from "../../../../utils/MultipleSelect";
import { DocumentViewer } from "../../../../utils/documentViewer";
import { DocumentDownloader } from "../../../../utils/DocumentDownloader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import MultipleSelectForTable from "../../../../utils/MultipleSelectForTable";
import ChatBoxPopUp from "../../../chatRoom/components/ChatBoxPopUp";
import ReactCountryFlag from "react-country-flag";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const reviewLabels = [
  { name: 'Total Finance Application', color: "#1B94B7", alt: "totalFinanceApplied" },
  { name: 'Application Rejected', color: '#FE4141', alt: "applicationRejected" },
  { name: 'Application Approved', color: '#00B74D', alt: "applicationApproved" },
]

const tutorialPopupCSS = {
  "0": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Enter required limit for you & click on Continue.</span></label>
  },
  "1": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Select Your LC Type.</span></label>
  },
  "2": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Upload LC & Manually enter LC details which are failed to fetch from document, review & click on continue button.</span></label>
  },
  "3": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Sign documents & click on continue button.</span></label>
  },
  "4": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Kindly select financier with whom you want to share LC.</span></label>
  },
  "5": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Review all form details, click on check box & click on Submit button.</span></label>
  }
}


const addBuyerTabs = [
  { name: "Buyer details" },
  { name: "LC details" },
  { name: "Documents" },
  // { name: "Select financer" },
  { name: "Review" },
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName0" },
  { "name": "Previous year annual sales", val: "previousAnnualSale0", unit: "buyerCurrency0" },
  { "name": "Contact Number:", val: "buyerPhone0", unit: "buyerPhoneCode0" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale0", unit: "buyerCurrency0" },

  { "name": "Email Id", val: "buyerEmail0" },
  { "name": "Inco Terms", val: "incoTerms0" },
  { "name": "Website", val: "buyerWebsite0" },
  { "name": "Terms of Payment", val: "termsOfPayment0" },

  { "name": "Address", val: "buyerAddress0" },
  { "name": "Product Details", val: "productDetails0" },

  { "name": "DUNS No", val: "buyerDUNSNo0" },
  { "name": "HSN Code", val: "buyerHsnCode0" },

]

const buyerReviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

export const Lcdetailsdata = [
  { name: "Sight LC", value: "sight_lc" },
  { name: "Usance LC", value: "usance_lc" },
  { name: "Back to back LC", value: "b2bank_lc" },
  { name: "Green Clause LC", value: "green_clause_lc" },
  { name: "Red Clause LC", value: "red_clause_lc" },
  { name: "Transferable LC", value: "transferable_lc" },
];

const reviewForm3 = [
  { "name": "LC type", val: "lctype" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  { "name": "LC tenor", val: "lcTenor" },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]

export const lcTenorDD = [
  { name: "30 Days", ocrValue: "30" },
  { name: "45 Days", ocrValue: "45" },
  { name: "60 Days", ocrValue: "60" },
  { name: "90 Days", ocrValue: "90" },
  { name: "120 Days", ocrValue: "120" }
]


const LcDiscountingcardV2 = ({ userTokenDetails, navToggleState, hideSideBarAndHeader, userShipmentDetails, lcFields }) => {

  console.log(hideSideBarAndHeader, userShipmentDetails, lcFields, "this is from bulk-->>>>>")

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  let isBuyerUndefined = buyer === "undefined" || buyer === "null"
  const lcPurpose = queryParams.get("purpose")
  const onlyView = queryParams.get("view") ? true : false
  const adminView = queryParams.get("adminView") ? true : false
  const adminLCApply = queryParams.get("adminLCApply") ? true : false
  const uploadReqLetter = queryParams.get("uploadReqLetter") ? true : false
  const quoteId = queryParams.get("id")
  const amendmentId = queryParams.get("amendmentId")
  const sendLCForReview = true
  const history = useHistory();

  const [tab, setTab] = useState(0)
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);

  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ currentDocuments0: true, "currency0:0:0": "USD", collectiveSelection: true })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showLoader2, setshowLoader2] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [ocrData, setOCRData] = useState([])
  const [ocrTxtFormat, setOcrTxtFormat] = useState([])

  const [countrys, setCountrys] = useState([])
  const [ports, setPorts] = useState([])
  const [swiftData, setSwiftData] = useState([])
  const [userBanks, setUserBanks] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [lcFinanciers, setLCFinanciers] = useState([])

  const [lcCodes, setLCCodes] = useState([])
  const [inputdisplay, setInputdisplay] = useState(onlyView);

  const [amendReq, setAmendReq] = useState({});

  const [textarea, setTextarea] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const [askForConfirmingBank, setAskForConfirmingBank] = useState(0)
  const [tab1, setTab1] = useState(false);
  const [confirmbanking, setConfirmbanking] = useState(false);
  const [ammendPopup, toggleAmmendPopup] = useState({ show: false, key: null })
  const [ammendPopup2, toggleAmmendPopup2] = useState({ show: false, key: null })
  const [ammendFields, setAmmendFields] = useState({})
  const [allPorts, setAllPorts] = useState([])
  const [allSwiftData, setAllSwiftData] = useState([])

  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 10)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [finList, setFinList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})
  const [draftLCBlob, setDraftLCBlob] = useState(null)
  const [ocrFieldMapped, toggleOcrFieldMapped] = useState(false)

  const [multipleLCForm, setMultipleLCForm] = useState(true)
  const [lcRows, setLCRows] = useState([null])
  const [accordion, setAccordion] = useState(1);
  const [accordion1, setAccordion1] = useState(1);
  const [accordion2, setAccordion2] = useState(1);
  const [dbData, setDbData] = useState([])
  const [dbDataFilter, setDbDataFilter] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [ocrScreen, setOcrScreen] = useState({})


  const [totalDocsCount, setTotalDocsCount] = useState({})
  const [multInvPo, setMultInvPo] = useState([{ purchase_orders: [null], invoices: [null] }])
  const formContinue = queryParams.get("formContinue")

  const [shouldSaveCache, toggleShouldSaveCache] = useState(formContinue ? false : true)

  const [cacheId, setCacheId] = useState(formContinue || null)
  const [finActionTab, setFinancierActionTab] = useState(0)
  const [draftData, setDraftData] = useState([])


  const [refresh, setRefresh] = useState(0)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false
  })
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')
  const [contractList, setContractList] = useState([])

  let jj = -1

  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1 || userTokenDetails.type_id / 1 == 20) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }


  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = localStorage.getItem("manualEmailId") || (userToken.email ? userToken.email : null)
  const userId = localStorage.getItem("manualUserId") || (userToken.user_id ? userToken.user_id : null)
  const userName = localStorage.getItem("manualUserName") || (userToken.userName ? userToken.userName : null)
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const subUserName = userToken?.sub_user_name
  const mainUserName = userToken?.main_user_name
  const user_avatar = userToken.userAvatar ? userToken.userAvatar : null
  console.log('Usernameeeeeeeeee', subUserName, mainUserName, userData)


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {

    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("LcDiscountingV2");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is LC  discounting--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    call('POST', 'getAllInvoiceLimitDrafts', { userId, forLC: true }).then((res) => setDraftData(res))
  }, [refresh])

  useEffect(() => {
    getContractList()
    if (cacheId) {
      getCache()
    }
  }, [])

  async function getContractList() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getContracts', {
      "currentPage": 1,
      "resultPerPage": 1000,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    })
    // console.log("apiResppppppppppppppppppppppppppppppppp", apiResp);
    if (apiResp?.contractdata?.length) {
      setContractList(apiResp?.contractdata)
    }
    setshowLoader(false)
  }

  async function getCache() {
    let cacheDetails = await call('POST', 'getInvoiceLimitCacheById', { cacheId, forLC: true })
    if (cacheDetails?.details) {
      setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
      setTab(cacheDetails.details.tab)
      // setLCRows(cacheDetails.details.lcRows)
      setMultInvPo(cacheDetails.details.multInvPo)
      setMultipleLCForm(cacheDetails.details.multipleLCForm)
      setFinList(cacheDetails.details.finList)
      setOCRData(cacheDetails.details.ocrData)
      toggleShouldSaveCache(true)
    }
  }

  useEffect(() => {
    if (!onlyView && !adminView) {
      if (shouldSaveCache) {
        saveCache()
      }
    }
  }, [data, tab, lcRows, multInvPo, multipleLCForm, finList, ocrData])

  async function saveCache() {
    let reqBody = { userId, forLC: true }
    reqBody["details"] = { data, tab, lcRows, multInvPo, multipleLCForm, finList, ocrData, percentageComplete: (tab) * 20 }
    if (cacheId) {
      reqBody["cacheId"] = cacheId
    }
    let tempCacheId = await call('POST', 'saveInvoiceLimitCache', reqBody)
    setCacheId(tempCacheId)
  }

  useEffect(() => {
    updateDocsCount()
  }, [data])

  async function updateDocsCount() {
    let tempObj = {}
    for (let index = 0; index < lcRows.length; index++) {
      if (lcRows[index] === null) {
        tempObj[index] = 0
        for (let j = 0; j < multInvPo.length; j++) {
          for (let k = 0; k < 1; k++) {
            if (data?.[`invoiceDocument${index}:${j}:${k}`]?.name) {
              tempObj[index] += 1
            }
          }
          for (let k = 0; k < 1; k++) {
            if (data?.[`poDocument${index}:${j}:${k}`]?.name) {
              tempObj[index] += 1
            }
          }
        }
        if (data[`draftLCDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`invReportDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`logisticDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`grnDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`salesContractDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`shippingBillDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`packingListDocument${index}`]?.name) {
          tempObj[index] += 1
        }
      }
    }
    setTotalDocsCount(tempObj)
  }

  useEffect(() => {
    if (lcTutorial) {
      if (tab == 0) {
        setTutorialStage(0)
      }
      if (tab == 1) {
        setTutorialStage(1)
      }
      if (tab == 1 && inputdisplay) {
        setTutorialStage(2)
      }
      if (tab == 2) {
        setTutorialStage(3)
      }
      if (tab == 3) {
        setTutorialStage(4)
      }
      if (tab == 4) {
        setTutorialStage(5)
      }
    }
  }, [tab, inputdisplay])

  useEffect(() => {
    if (data?.draftLCDocument?.filebase64) {
      let blob = null
      blob = dataURItoBlobURL(data.draftLCDocument.filebase64);
      setDraftLCBlob(blob)
    }
  }, [data.draftLCDocument])

  useEffect(() => {
    getBuyerDetails()
  }, [refresh])

  const getBuyerDetails = () => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      // setDbData([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData]);
      let tempResultBuyerData = result.buyerData
      for (let index = 0; index < tempResultBuyerData.length; index++) {
        tempResultBuyerData[index][`buyerNameHtml`] = <div>
          <label className="font-size-12 font-wt-500" >{`${tempResultBuyerData[index]["buyerName"]}`}
            <ReactCountryFlag
              countryCode={tempResultBuyerData[index].buyerCountry}
              style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
          </label>
        </div>
      }
      setDbData([...tempResultBuyerData]);
      setDbDataFilter([...tempResultBuyerData]);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)

    });
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }


  const confirmBank = () => {
    setConfirmbanking(true);
    setAskForConfirmingBank(askForConfirmingBank + 1)
  }

  async function reloadUserBankDetails() {
    setshowLoader(true)
    call("POST", "getUserBanks", { "email": userEmail, "inProfile": true })
      .then((result) => {
        setshowLoader(false)
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        setshowLoader(false)
        // console.log("Error while querying getUserBanks:", e);
      });
  }



  async function getAmendmentDetailsById() {
    if (amendmentId) {
      let amendmentData = {}
      let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, amendmentId })
      if (amendmentDataRes && amendmentDataRes.length) {
        amendmentData["fieldLabels"] = Object.keys(amendmentDataRes[0].details) || []
        amendmentData["fieldComments"] = amendmentDataRes[0].details || {}
        // amendmentData["changedDetails"] = amendmentDataRes[0].changedDetails || {}
        amendmentData["changedDetails"] = amendmentDataRes[0].beforeDetails || {}
        setAmendReq(amendmentData)
      }
      else {
        toastDisplay("Invalid Link", "info", () => {
          history.go(-1)
        })
      }
    }
  }

  // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);

  const handleMultiSelect = async (e, name, val, index) => {
    console.log("handleMultiSelect", e, name, val);
    if (e?.[0]?.id.toString().includes("addNewBank")) {
      return window.open(`${platformURL}/viewprofile?action=addNewBank`, "_blank")
    }
    if (e?.[0]?.id.toString().includes("temp")) {
      setshowLoader(true)
      if (name.includes("lcIssuingBank")) {
        let swiftId = await call('POST', 'insertSwiftBankInMaster', { bankName: e?.[0]?.typedInput })
        setshowLoader(false)
        call('POST', 'getSwiftMaster').then((result) => {
          setSwiftData(result)
          setAllSwiftData(result)
          setData({
            ...data,
            [name]: swiftId,
            [`lcIssuingBankName${index}`]: e?.[0]?.typedInput, [`lcIssuingBankAddress${index}`]: null,
            [`lcIssuingBankSwiftCode${index}`]: null
          });
        }).catch((e) => {
          console.log('error in getSwiftMaster', e);
        })
      }
      else {
        let portId = await call('POST', 'insertPortInMaster', { portName: e?.[0]?.typedInput })
        setshowLoader(false)
        call('POST', 'getOldPortMaster').then((result) => {
          setPorts(result)
          setAllPorts(result)
          setData({
            ...data,
            [name]: portId
          });
        }).catch((e) => {
          // console.log('error in getOldPortMaster', e);
        })
      }
    }
    else {
      if (name?.includes("lcIssuingBank")) {
        let selectedValue = e?.[0]?.id ? e.reverse()?.[0] : {}
        setData({
          ...data,
          [name]: selectedValue.id || null,
          [`lcIssuingBankName${index}`]: selectedValue.bankName || "", [`lcIssuingBankAddress${index}`]: selectedValue.branchName || "",
          [`lcIssuingBankSwiftCode${index}`]: selectedValue.swiftCode || ""
        })
        // console.log("selectedLCIssuingBankkkkkkkkkkkkkkkkkkk", selectedValue);
      }
      else if (name?.includes("beneficiaryBank")) {
        let selectedEntry = e?.[0]?.id && e.reverse()?.[0]?.id ? e.reverse()?.[0] : {}
        setData({
          ...data,
          [name]: selectedEntry.id || null,
          [`beneficiaryBankName${index}`]: selectedEntry.beneficiaryBankName || "", [`beneficiaryAccountNo${index}`]: selectedEntry.beneficiaryAccountNo || "",
          [`beneficiaryIfscCode${index}`]: selectedEntry.beneficiaryIfscCode || "", [`beneficiarySwiftCode${index}`]: selectedEntry.beneficiarySwiftCode || ""
        });
      }
      else {
        setData({
          ...data,
          [name]: e?.[0]?.id ? e.reverse()?.[0]?.id : null
        })
      }
    }
  };

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let tempPort = []
    let filtered = []
    let filteredBuyers = []
    if (name.includes("buyerName")) {
      if (typedInput) {
        filteredBuyers = dbData.filter((i) => {
          if (i.buyerName.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBuyers = dbData
      }
      setDbDataFilter([...filteredBuyers])
    }
    if (name.includes("ports")) {
      tempPort = [{ id: "temp", "port_name": "Add New Option", typedInput }]
      filtered = allPorts.filter((i) => {
        if (i.port_name && i.port_name.toLowerCase().includes(typedInput.toLowerCase())) {
          return i
        }
      })
    }
    if (name.includes("swiftData")) {
      tempPort = [{ id: "temp", "bankName": "Add New Option", typedInput }]
      filtered = allSwiftData.filter((i) => {
        if (i.bankName && i.bankName.toLowerCase().includes(typedInput.toLowerCase())) {
          return i
        }
      })
    }
    if (!filtered.length) {
      filtered = tempPort
    }
    if (name.includes("ports")) {
      setPorts(filtered)
    }
    if (name.includes("swiftData")) {
      setSwiftData(filtered)
    }
  };


  useEffect(() => {
    getFinanciersForLC()
  }, [lcRows])

  useEffect(() => {
    getAmendmentDetailsById()
    // Get master data start
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    call('POST', 'getOldPortMaster').then((result) => {
      setPorts(result)
      setAllPorts(result)
    }).catch((e) => {
      console.log('error in getOldPortMaster', e);
    })
    call('POST', 'getSwiftMaster').then((result) => {
      setSwiftData(result)
      setAllSwiftData(result)
    }).catch((e) => {
      console.log('error in getSwiftMaster', e);
    })

    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      setLCCodes(result.mandatoryFields.concat(result.optionalFields))
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    call("POST", "getUserBanks", { "email": userEmail, "inProfile": true })
      .then((result) => {
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        // console.log("Error while querying getUserBanks:", e);
      });
    // Get master data end
    console.log('bbsdadsa', isBuyerUndefined, amendmentId, onlyView, adminView);
    if (!isBuyerUndefined && !amendmentId && !onlyView && !adminView && !adminLCApply) {
      // setOverlay(true);
    }
  }, [])

  useEffect(() => {
    // Will fetch buyer data & then do OCR
    fetchBuyerData()
  }, [data])


  async function fetchBuyerData() {
    setshowLoader(true)
    let temp = {}
    for (let index = 0; index < lcRows.length; index++) {
      if (lcRows[index] === null) {
        if (data[`selectedBuyer${index}`] && !data[`fetchForBuyer${index}`]) {
          let buyerAPIResp = await call('POST', 'getBuyersDetailWithDoc', {
            buyersId: data[`selectedBuyer${index}`].toString()
          })
          buyerAPIResp = buyerAPIResp?.[0]
          // console.log("buyerAPIRespppppppppppppppppppppp", buyerAPIResp);
          temp[`id${index}`] = buyerAPIResp?.id
          temp[`buyerName${index}`] = buyerAPIResp?.buyerName
          temp[`buyerEmail${index}`] = buyerAPIResp?.buyerEmail
          temp[`buyerPhone${index}`] = buyerAPIResp?.buyerPhone
          temp[`buyerAddress${index}`] = buyerAPIResp?.buyerAddress
          temp[`buyerWebsite${index}`] = buyerAPIResp?.buyerWebsite
          temp[`buyerCurrency${index}`] = buyerAPIResp?.buyerCurrency?.split(":")?.[1]
          temp[`buyerDUNSNo${index}`] = buyerAPIResp?.buyerDUNSNo
          temp[`previousAnnualSale${index}`] = buyerAPIResp?.previousAnnualSale
          temp[`currentAnnualSale${index}`] = buyerAPIResp?.currentAnnualSale
          temp[`buyerCountry${index}`] = buyerAPIResp?.buyerCountry
          temp[`incoTerms${index}`] = buyerAPIResp?.incoTerms
          temp[`productDetails${index}`] = buyerAPIResp?.productDetails
          temp[`termsOfPayment${index}`] = buyerAPIResp?.termsOfPayment
          temp[`buyerPhoneCode${index}`] = buyerAPIResp?.buyerPhoneCode
          temp[`buyerHsnCode${index}`] = buyerAPIResp?.buyerHsnCode
          temp[`annualTurnOver${index}`] = buyerAPIResp?.annualTurnOver
          temp[`fetchForBuyer${index}`] = true
        }
      }
    }
    setshowLoader(false)
    if (Object.keys(temp)?.length) {
      getDetailsByOCR(temp)
    }
    else {
      getDetailsByOCR({})
    }
    console.log("tempppppppppppppppppppppppppppppp", temp);
  }


  async function mapOCRDataToFields(ocrData, index) {
    return new Promise(async (resolve) => {
      if (Object.keys(ocrData).length) {
        let processedData = {}
        if (ocrData["buyer_name"]) {
          let buyerId = null
          buyerId = dbData?.filter((i) => {
            if (i.buyerName?.toLowerCase() === ocrData?.["buyer_name"]?.toLowerCase()) {
              return true
            }
          })?.[0]?.id
          if (!buyerId) {
            let buyerAddApiResp = await call('POST', 'addBuyerWithMinimalDetails', { userId, buyerName: ocrData["buyer_name"] })
            buyerId = buyerAddApiResp?.buyerInsertId
            setRefresh(refresh + 1)
          }
          if (buyerId) {
            processedData[`selectedBuyer${index}`] = buyerId
            processedData[`fetchForBuyer${index}`] = false
          }
        }
        if (ocrData["lc_issuing_bank_name"]) {
          setshowLoader(true)
          let swiftId = await call('POST', 'insertSwiftBankInMaster', {
            [`bankName`]: ocrData["lc_issuing_bank_name"],
            [`bankAddress`]: ocrData["lc_issuing_bank_address"], swiftCode: ocrData["lc_issuing_bank_swift_code"]
          })
          let apiResult = await call('POST', 'getSwiftMaster')
          setshowLoader(false)
          setSwiftData(apiResult)
          setAllSwiftData(apiResult)
          processedData[`lcIssuingBank${index}`] = swiftId
          processedData[`lcIssuingBankName${index}`] = ocrData["lc_issuing_bank_name"]
          processedData[`lcIssuingBankAddress${index}`] = ocrData["lc_issuing_bank_address"]
          processedData[`lcIssuingBankSwiftCode${index}`] = ocrData["lc_issuing_bank_swift_code"]
        }
        if (ocrData["20"]) {
          processedData[`lcNo${index}`] = ocrData["20"]
        }
        if (ocrData["44E"]) {
          let matchWord = ocrData["44E"].split(" ")[0].toUpperCase()
          let temp = ports.filter((i) => {
            if (i.port_name.toUpperCase().includes(matchWord)) {
              return i
            }
          })
          if (temp.length) {
            processedData[`portOfLoading${index}`] = temp[0].id
            processedData[`portOfLoadingName${index}`] = temp[0].port_name
          }
        }
        if (ocrData["44F"]) {
          let matchWord = ocrData["44F"].split(" ")[0].toUpperCase()
          let temp = ports.filter((i) => {
            if (i.port_name.toUpperCase().includes(matchWord)) {
              return i
            }
          })
          if (temp.length) {
            processedData[`portOfDischarge${index}`] = temp[0].id
            processedData[`portOfDischargeName${index}`] = temp[0].port_name
          }
        }
        if (ocrData["45A"]) {
          processedData[`commodity${index}`] = ocrData["45A"]
        }
        if (ocrData["44C"]) {
          let temp = moment(ocrData["44C"]).isValid()
          if (temp) {
            processedData[`expectedDateOfShipment${index}`] = moment(ocrData["44C"]).format("YYYY-MM-DD")
          }
        }
        if (ocrData["48"]) {
          processedData[`lcTenor${index}`] = extractNoFromTxt(ocrData["48"]) || ""
        }
        resolve(processedData)
        // toggleOcrFieldMapped(true)
      }
    })
  }

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(result => {
      setChatList(result)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }



  const getFinanciersForLC = async () => {
    setshowLoader(true)
    let tempFinList = []
    for (let j = 0; j < lcRows.length; j++) {
      if (lcRows[j] === null) {
        let res = await call('POST', "getFinanciersForLCV2", {})
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          element["isChecked"] = true
          element["isAvailable"] = true
          element["logo"] = element.user_avatar || null
        }
        tempFinList[j] = res
      }
    }
    setFinList([...tempFinList])
    setshowLoader(false)
  }

  async function convertPdfToTxt(additionalDataToSave) {
    setshowLoader(true)
    let tempOCRTxtFormat = ocrTxtFormat
    for (let index = 0; index <= lcRows.length; index++) {
      if (lcRows[index] === null) {
        if (data[`draftLCDocument${index}`]?.name && !data[`textConvDone${index}`]) {
          let formData = new FormData()
          formData.append("doc", data[`draftLCDocument${index}`])
          let res = {}
          try {
            res = await axios.post(`${aiServerBackendUrl}/pdf-to-text`, formData, {
            })
            tempOCRTxtFormat[index] = res?.data?.["pages"] || []
          } catch (error) {
            tempOCRTxtFormat[index] = []
          }
          additionalDataToSave[`textConvDone${index}`] = true
        }
      }
    }
    setshowLoader(false)
    setOcrTxtFormat(tempOCRTxtFormat)
    if (Object.keys(additionalDataToSave).length) {
      setData({ ...data, ...additionalDataToSave })
    }
  }

  async function getDetailsByOCR(additionalDataToSave) {
    // setshowLoader(true)
    // console.log("1111111111111111111111111111111111111111111", data, lcRows);
    for (let index = 0; index < lcRows.length; index++) {
      if (lcRows[index] === null) {
        if (data[`draftLCDocument${index}`]?.name && !data[`ocrDone${index}`]) {
          let formData = new FormData()
          formData.append("doc", data[`draftLCDocument${index}`])
          let res = {}

          try {
            toastDisplay("Analyzing document kindly wait", "info")
            setshowLoader2(true)
            res = await axios.post(`${aiServerBackendUrl}/text-analyse-v2`, formData, {})
            setshowLoader2(false)
          } catch (error) {
            toastDisplay(`OCR failed for document ${index + 1}`, "error")
          }
          // console.log("respppppppppppppppp", res);
          // if (multipleLCForm) {
          //   setLCRows(lcRows.concat([null]))
          // }
          additionalDataToSave[`ocrDone${index}`] = true
          let tempOcrData = ocrData
          if (res?.data?.length) {
            tempOcrData[index] = {
              ...res.data[0], '32B1': 'USD', readerResp: res.data[0],
              '32B2': extractNoFromTxt(res.data[0]["32B"]) || ""
            }
            setOCRData([...tempOcrData])
            let processedData = await mapOCRDataToFields({
              ...res.data[0], '32B1': 'USD', [`readerResp${index}`]: res.data[0]
            }, index)
            additionalDataToSave = {
              ...additionalDataToSave, ...processedData
            }
          }
        }
      }
    }
    // console.log("222222222222222222222222222222222222222222222222222", additionalDataToSave)
    convertPdfToTxt(additionalDataToSave)
    setshowLoader(false)
  }

  const handleOCRFieldsChange = async (event, index) => {
    let tempOcrData = ocrData
    if (event.target.name.includes("32B2") || event.target.name.includes("32B1")) {
      tempOcrData[index] = {
        ...tempOcrData[index],
        '32B': tempOcrData?.[index]?.[`32B1`] + " " + tempOcrData?.[index]?.[`32B2`], [event.target.name]: event.target.value
      }
      setOCRData([...tempOcrData])
    }
    else {
      tempOcrData[index] = {
        ...tempOcrData[index],
        [event.target.name]: event.target.value
      }
      setOCRData([...tempOcrData])
    }
  }

  const handleChange = async (event, index) => {
    event.persist()
    if (event.target.name.includes("portOfLoading") && event.target.value) {
      let selectedPort = ports.filter((i) => {
        if (i.id == event.target.value) {
          return i
        }
      })
      setData({
        ...data, [event.target.name]: event.target.value, [`portOfLoadingName${index}`]: selectedPort[0].port_name
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name.includes("portOfDischarge") && event.target.value) {
      let selectedPort = ports.filter((i) => {
        if (i.id == event.target.value) {
          return i
        }
      })
      setData({
        ...data, [event.target.name]: event.target.value, [`portOfDischargeName${index}`]: selectedPort[0].port_name
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name.includes("beneficiaryBank")) {
      let selectedBank = userBanks.filter(i => {
        if (i.id == event.target.value) {
          return i
        }
      })[0]
      setData({
        ...data, [event.target.name]: event.target.value, [`beneficiaryBankName${index}`]: selectedBank.beneficiaryBankName, [`beneficiaryAccountNo${index}`]: selectedBank.beneficiaryAccountNo,
        [`beneficiaryIfscCode${index}`]: selectedBank.beneficiaryIfscCode, [`beneficiarySwiftCode${index}`]: selectedBank.beneficiarySwiftCode
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else {
      if (event.target.name?.includes("selectedBuyer")) {
        setData({ ...data, [event.target.name]: event.target.value, [`fetchForBuyer${index}`]: false })
        setErrors({ ...errors, [event.target.name]: "" })
      }
      else {
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
      }
    }
  }

  const closeFn = () => {
    setLimitinvoice(true);
  }

  const closeExpFn = () => {
    setExperience(false);
  }

  // console.log("dddddddddddddddddddddddddddddddddddddddddddd", data);

  const handleFile = (event, index) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        let tempData = { ...data, [event.target.name]: fileObj }
        if (event.target.name.includes("draftLC")) {
          tempData[`ocrDone${index}`] = false
          tempData[`textConvDone${index}`] = false
        }
        setData(tempData)
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleFileWithAsyncAwait = async (event) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setshowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setshowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          console.log("insidehandleFileeeeeeeeeeeeeeee", tempData);
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }


  const handleFun = () => {
    if (tab === 1 && !inputdisplay) {
      if (!data.lctype0) {
        return toastDisplay("Please select LC type to continue", "info")
      }
      else if (!data.draftLCDocument0?.name) {
        setErrors({ ...errors, ["draftLCDocument0"]: "Please upload draft LC document to continue" })
      }
      else {
        setInputdisplay(true);
      }
    }
    else {
      handleValidation(undefined)
    }
  }


  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }
    let validateFields = []
    let err = {}
    if (tab === 0) {
      if (multipleLCForm) {
        for (let index = 0; index < lcRows.length; index++) {
          if (lcRows[index] === null) {
            if (data[`draftLCDocument${index}`]?.name && index > 0) {
              if (!data[`lcPurpose${index}`]) {
                err[`lcPurpose${index}`] = 'Mandatory field'
              }
              // if (!data[`lctype${index}`]) {
              //   err[`lctype${index}`] = 'Mandatory field'
              // }
              if (!data[`lcNo${index}`]) {
                err[`lcNo${index}`] = 'Mandatory field'
              }
              if (!data[`countryOfOrigin${index}`]) {
                err[`countryOfOrigin${index}`] = 'Mandatory field'
              }
              if (!data[`lcIssuingBank${index}`]) {
                err[`lcIssuingBank${index}`] = 'Mandatory field'
              }
              // if (!(ocrData?.[index]?.[`32B1`] && ocrData?.[index]?.[`32B2`])) {
              //   err[`32B2${index}`] = "Mandatory Field"
              // }
              if (!data[`lcTenor${index}`]) {
                err[`lcTenor${index}`] = "Mandatory Field"
              }
              else if (!(0 < data[`lcTenor${index}`] / 1 && data[`lcTenor${index}`] / 1 < 366)) {
                err[`lcTenor${index}`] = "LC Tenor should be from 1 to 365 days only"
              }
            }
            else if (index === 0) {
              if (!data[`draftLCDocument${index}`]?.name) {
                err[`draftLCDocument${index}`] = 'Mandatory field'
              }
              if (!data[`lcPurpose${index}`]) {
                err[`lcPurpose${index}`] = 'Mandatory field'
              }
              // if (!data[`lctype${index}`]) {
              //   err[`lctype${index}`] = 'Mandatory field'
              // }
              if (!data[`lcNo${index}`]) {
                err[`lcNo${index}`] = 'Mandatory field'
              }
              if (!data[`countryOfOrigin${index}`]) {
                err[`countryOfOrigin${index}`] = 'Mandatory field'
              }
              if (!data[`lcIssuingBank${index}`]) {
                err[`lcIssuingBank${index}`] = 'Mandatory field'
              }
              // if (!(ocrData?.[index]?.[`32B1`] && ocrData?.[index]?.[`32B2`])) {
              //   err[`32B2${index}`] = "Mandatory Field"
              // }
              if (!data[`lcTenor${index}`]) {
                err[`lcTenor${index}`] = "Mandatory Field"
              }
              else if (!(0 < data[`lcTenor${index}`] / 1 && data[`lcTenor${index}`] / 1 < 366)) {
                err[`lcTenor${index}`] = "LC Tenor should be from 1 to 365 days only"
              }
            }
          }
        }
      }
      else {
        if (!data['lcPurpose0']) {
          err[`lcPurpose0`] = "Mandatory Field"
        }
      }
    }
    if (tab === 1) {
      validateFields = ["draftLCDocument0", "lcNo0"]
      if (!(ocrData?.[0]?.["32B1"] && ocrData?.[0]?.["32B2"])) {
        err["32B20"] = "Mandatory Field"
      }
    }
    if (tab === 2) {
      for (let index = 0; index < lcRows.length; index++) {
        if (lcRows[index] === null) {
          // if (!data[`salesContractDocument${index}`]?.name && !data[`salesContractNotAvailable${index}`]) {
          //   err[`salesContractDocument${index}`] = "Mandatory"
          //   err[`accordion${index}`] = true
          // }
        }
      }
    }
    if (tab === 3) {
      for (let j = 0; j < lcRows.length; j++) {
        if (lcRows[j] === null) {
          let isFinSelected = false
          for (let index = 0; index < finList[j].length; index++) {
            const element = finList[j][index];
            if (element.isChecked) {
              isFinSelected = true
            }
          }
          if (!isFinSelected) {
            err[`accordion1${j}`] = true
            toastDisplay("Kindly select atleast 1 financer", "info")
          }
        }
      }
    }
    if (!Object.keys(err).length) {
      if (tab === 0) {
        // delete unnecessary lcRows
        if (tab === 0) {
          let tempLcRows = lcRows
          for (let index = 0; index < lcRows.length; index++) {
            if (!data[`draftLCDocument${index}`]?.name && multipleLCForm) {
              tempLcRows[index] = undefined
            }
          }
          // setLCRows([...tempLcRows])
        }

        if (multipleLCForm) {
          setTab(3)
        }
        else {
          setTab(1)
        }
      }
      else if (tab === 3) {
        submitDetails()
      }
      else {
        setTab(tab + 1)
      }
    }
    else {
      toastDisplay("Please fill all the mandatory details in the form", "error")
    }
    setErrors(err)
  }


  async function submitDetails() {
    setshowLoader(true)
    let errorOccuredFor = []
    let tempDataVar = {}

    let mergedApplicationId = null

    if (lcRows.length > 1) {
      mergedApplicationId = "MERGED" + new Date().getTime()
    }

    for (let j = 0; j < lcRows.length; j++) {
      if (lcRows[j] === null) {
        if (!data[`dontTryAgain${j}`]) {
          let formData = new FormData()
          if (mergedApplicationId) {
            formData.append("mergedApplicationId", mergedApplicationId)
          }
          formData.append("userName", userName)
          Object.keys(data).forEach(key => {
            if (!key.includes("Document") && !key.includes("commodityContract")) {
              if (key.endsWith(j.toString())) {
                formData.append(key.replace(j.toString(), ""), data[key])
              }
              else {
                formData.append(key, data[key])
              }
            }
          })
          formData.append("userId", userId)
          formData.append("ocrData", JSON.stringify(ocrData[j]))
          formData.append("lcTypeTemplateName", LCTypesObject[data[`lctype${j}`]])
          formData.append("lcPurposeTemplateName", LCPurposeObject[data[`lcPurpose${j}`]])
          formData.append("ActionBy", subUserName ? subUserName : mainUserName)
          if (userShipmentDetails) {
            formData.append("contractNo", userShipmentDetails.contract_number)
          }
          // formData.append('to', "+917310004469");
          // formData.append('templateName', "letter_of_credit");
          // formData.append('mediaUrl', "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
          // formData.append('filename', "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
          // formData.append('placeholders', JSON.stringify([userTokenDetails.contactPerson]));

          if (data[`commodityContract${j}`]) {
            formData.append('commodityContract', data[`commodityContract${j}`])
          }
          if (sendLCForReview) {
            formData.append("sendLCForReview", sendLCForReview)
          }
          if (finList[j].length) {
            formData.append("bankList", JSON.stringify(finList[j]))
          }

          // all other docs

          for (let index = 0; index < multInvPo.length; index++) {
            const element = multInvPo[index];
            for (let l = 0; l < element.invoices.length; l++) {
              if (data[`invoiceDocument${j}:${index}:${l}`]?.name) {
                formData.append("invoiceDocument" + index + ":" + l, data[`invoiceDocument${j}:${index}:${l}`])
                if (data[`invoiceDocument${j}:${index}:${l}`]?.signatureId) {
                  formData.append("invoiceRefrenceNo" + index + ":" + l + "SignId", data[`invoiceDocument${j}:${index}:${l}`].signatureId)
                }
                formData.append("invoiceRefrenceNo" + index + ":" + l, data["invoiceRefrenceNo" + j + ":" + index + ":" + l])
                formData.append("currency" + index + ":" + l, data["currency" + j + ":" + index + ":" + l])
                formData.append("contractAmount" + index + ":" + l, data["contractAmount" + j + ":" + index + ":" + l])
              }
            }
            for (let l = 0; l < element.purchase_orders.length; l++) {
              if (data[`poDocument${j}:${index}:${l}`]?.name) {
                formData.append("poDocument" + index + ":" + l, data[`poDocument${j}:${index}:${l}`])
                if (data[`poDocument${j}:${index}:${l}`]?.signatureId) {
                  formData.append("poDocument" + index + ":" + l + "SignId", data[`poDocument${j}:${index}:${l}`].signatureId)
                }
                formData.append("poRefrenceNo" + index + ":" + l, data["poRefrenceNo" + j + ":" + index + ":" + l])
                formData.append("poAmount" + index + ":" + l, data["poAmount" + j + ":" + index + ":" + l])
              }
            }
          }
          if (data[`draftLCDocument${j}`].name) {
            formData.append("draftLCDocument", data[`draftLCDocument${j}`])
          }
          if (data[`draftLCDocument${j}`].signatureId) {
            formData.append("draftLCDocSignId", data[`draftLCDocument${j}`].signatureId)
          }
          if (data?.[`logisticDocument${j}`]?.name && !data?.[`logisticDocument${j}`]?.fromDb) {
            formData.append("logisticDocument", data[`logisticDocument${j}`])
          }
          if (data?.[`invReportDocument${j}`]?.name && !data?.[`invReportDocument${j}`]?.fromDb) {
            formData.append("invReportDocument", data[`invReportDocument${j}`])
          }
          if (data?.[`grnDocument${j}`]?.name && !data?.[`grnDocument${j}`]?.fromDb) {
            formData.append("grnDocument", data[`grnDocument${j}`])
          }
          if (data?.[`packingListDocument${j}`]?.name && !data?.[`packingListDocument${j}`]?.fromDb) {
            formData.append("packingListDocument", data[`packingListDocument${j}`])
          }
          if (data?.[`shippingBillDocument${j}`]?.name && !data?.[`shippingBillDocument${j}`]?.fromDb) {
            formData.append("shippingBillDocument", data[`shippingBillDocument${j}`])
          }
          if (data?.[`salesContractDocument${j}`]?.name && !data?.[`salesContractDocument${j}`]?.fromDb) {
            formData.append("salesContractDocument", data[`salesContractDocument${j}`])
          }
          if (data?.[`invoiceDocument${j}`]?.signatureId) {
            formData.append("invoiceDocSignId", data[`invoiceDocument${j}`].signatureId)
          }
          if (data?.[`poDocument${j}`]?.signatureId) {
            formData.append("poDocSignId", data[`poDocument${j}`].signatureId)
          }
          if (data?.[`logisticDocument${j}`]?.signatureId) {
            formData.append("logisticDocumentSignId", data[`logisticDocument${j}`].signatureId)
          }
          if (data?.[`invReportDocument${j}`]?.signatureId) {
            formData.append("invReportDocumentSignId", data[`invReportDocument${j}`].signatureId)
          }
          if (data?.[`grnDocument${j}`]?.signatureId) {
            formData.append("grnDocumentSignId", data[`grnDocument${j}`].signatureId)
          }
          if (data?.[`packingListDocument${j}`]?.signatureId) {
            formData.append("packingListDocumentSignId", data[`packingListDocument${j}`].signatureId)
          }
          if (data?.[`shippingBillDocument${j}`]?.signatureId) {
            formData.append("shippingBillDocumentSignId", data[`shippingBillDocument${j}`].signatureId)
          }
          if (data?.[`salesContractDocument${j}`]?.signatureId) {
            formData.append("salesContractDocumentSignId", data[`salesContractDocument${j}`].signatureId)
          }
          if (data[`currentDocuments${j}`]) {
            formData.append("documentStatus", "currentDocuments")
          }
          else if (data[`pastDocuments${j}`]) {
            formData.append("documentStatus", "pastDocuments")
          }

          try {
            await call('POST', 'applyForLCQuote', formData)
            // const whatsappResponse = await call('POST', "sendWhatsAppMessage", {
            //   to: "+917310004469",
            //   templateName: "letter_of_credit",
            //   mediaUrl: "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png",
            //   filename: "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png",
            //   placeholders: ["nikita", "https://saurav.catalog.to"],
            //   authorizationKey: "key_ER0Eyu3LGA"
            // });
            tempDataVar[`dontTryAgain${j}`] = true
          } catch (error) {
            // console.log("errorwhileapplyinglclimit");
            errorOccuredFor.push(j.toString())
          }
        }
      }
    }
    setshowLoader(false)
    if (errorOccuredFor.length) {
      toastDisplay(`Failed to submit applications - ${errorOccuredFor.map((m, n) => `${data[`lcNo${n}`]}, `)}`, "error")
      setData({ ...data, ...tempDataVar })
    }
    else {
      if (cacheId) {
        await call("POST", 'clearInvoiceLimitCacheById', { cacheId, forLC: true })
      }
      toastDisplay("Application submitted successfully", "success", () => {
        window.location = adminLCApply ? "lcLimit" : "LcQuote"
      })
    }
  }

  async function sendMessage() {
    const requestData = {
      to: "+917310004469",
      templateName: "letter_of_credit",
      mediaUrl: "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png",
      filename: "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png",
      placeholders: ["nikita", "https://saurav.catalog.to"]
    };

    try {
      const response = await fetch('http://localhost:3212/watsappMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ' key_ER0Eyu3LGA'
        },
        body: JSON.stringify(requestData)
      });

      const data = await response.json();
      console.log('Message sent:', data);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }


  function handleFinSelection(j, index, selectAll, lastAction) {
    // console.log("finListtttttttttttttttttttttttt", finList, j, index);
    if (selectAll) {
      let temp = finList
      for (let indexx = 0; indexx < temp.length; indexx++) {
        let item = temp[indexx]
        for (let j = 0; j < item.length; j++) {
          let element = item[j];
          if (j / 1 == index / 1) {
            element["isChecked"] = !lastAction
          }
        }
      }
      setFinList([...temp])
    }
    else {
      let temp = finList
      temp[j][index]["isChecked"] = !temp[j][index]["isChecked"]
      setFinList([...temp])
    }
  }

  async function adminUpdateLCDetails() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("userName", userName)
    formData.append("userId", userId)
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    formData.append("ocrData", JSON.stringify(ocrData))
    if (finList.length) {
      formData.append("bankList", JSON.stringify(finList))
    }
    call('POST', 'adminUpdateLCQuote', formData).then((result) => {
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      // console.log('error in adminUpdateLCQuote', e);
      toastDisplay(e, "error")
      setshowLoader(false)
    })
  }

  useEffect(() => {
    if (userShipmentDetails) {
      setData({
        ...data,
        [`lcNo0`]: lcFields[0].lcno,
        [`countryOfOrigin0`]: lcFields[0]?.country || "",
      })
    }
  }, [userShipmentDetails])

  console.log(data, "this is data")

  return (
    <>
      {selectedChat.isChatOpen &&
        <div className="chatboxDiv">
          <ChatBoxPopUp
            chatList={chatList}
            user_avatar={user_avatar}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            onChatSend={() => sendChatMsg()}
            message={message}
            setTextMsg={setTextMsg}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false
              })
            }}
          />
        </div>
      }
      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ width: '4rem', height: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div>

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <TutorialPopup show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"]
        }} showSkip={true} userId={userId}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showLoader2 && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid" >
        <div className={`${overlay && 'overlay_in'}`}></div>
        <div className={`row`}>
          <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
          <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />

          {!hideSideBarAndHeader && (<SideBarV2 state="LcDiscounting" userTokenDetails={userToken} />)}
          <main role="main" className={`ml-sm-auto ${navToggleState.status ? "expanded-right" : ""} ${hideSideBarAndHeader ? "col-lg-12" : "col-lg-10"}`} id="app-main-div">
            {!hideSideBarAndHeader && (<HeaderV2
              title={"Apply for finance limit"}
              userTokenDetails={userToken} />)}
            <a
              onClick={() => history.go(-1)}
              className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1"
                style={(lcTutorial) ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem 0rem 1rem 0rem",
                  "borderRadius": "1rem",
                  "height": "auto",
                  "maxHeight": "40rem",
                  "overflow": "auto"
                } : {}}
              >

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'4.5rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='ulFormsContainer'>

                    <div className='mb-3 d-flex align-items-center'>
                      <label className={!multipleLCForm ? "font-wt-500 font-size-16" :
                        "font-wt-700 font-size-16 color-2ECC71"} for="currentDocuments">Multiple LC</label>
                      <img
                        className="cursor mx-3"
                        onClick={() => setMultipleLCForm(!multipleLCForm)}
                        src={multipleLCForm ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                      />
                      <label className={multipleLCForm ? "font-wt-500 font-size-16" :
                        "font-wt-700 font-size-16 color-2ECC71"} for="pastDocuments">Single LC</label>
                      {multipleLCForm ? null :
                        <label className="font-size-14 font-wt-600 mx-3" >(You can skip this step & continue without selecting any buyer)</label>}
                    </div>

                    {multipleLCForm ? <div>
                      {!ocrScreen?.show ? (
                        <>
                          <NewTablev2 showSideBorders={true} columns={multipleLCTable}>
                            {lcRows.map((i, j) => {
                              if (i === null) {
                                jj = jj + 1
                                return (
                                  <tr>
                                    <td>
                                      <MultipleSelectForTable
                                        Label={''}
                                        Id={`commodityContract${j}`}
                                        optiondata={contractList}
                                        onChange={(e) => {
                                          handleChange(multiSelectEventHandler(e, `commodityContract${j}`, "contract_number"), j)
                                        }}
                                        value={data[`commodityContract${j}`] ? [data[`commodityContract${j}`]] : []}
                                        name={`commodityContract${j}`}
                                        labelKey={"contract_name"}
                                        valKey={"contract_number"}
                                        error={errors[`commodityContract${j}`]}
                                      />
                                    </td>
                                    <td>
                                      <FileInput name={`draftLCDocument${j}`} value={data[`draftLCDocument${j}`]} error={errors[`draftLCDocument${j}`]}
                                        onChange={(e) => handleFile(e, j)} isEditable={adminView ? false : true}
                                        onUploadCancel={() => setData({ ...data, [`draftLCDocument${j}`]: null })} />
                                      {errors[`draftLCDocument${j}`] ? <div class="text-danger mt-2 font-size-12">
                                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                        <b>{errors[`draftLCDocument${j}`]}</b></div> : ''}
                                    </td>
                                    <td>
                                      <SelectForTable selectData={[{ name: 'LC Confirmation (International)', val: 'lc_confirmation' }, { name: 'LC Discounting (International)', val: 'lc_discounting' },
                                      { name: 'LC Confirmation & Discounting (International)', val: 'lc_confirmation_discounting' },
                                      { name: 'LC Confirmation (Domestic)', val: 'lc_confirmation_domestic' }, { name: 'LC Discounting (Domestic)', val: 'lc_discounting_domestic' },
                                      { name: 'LC Confirmation & Discounting (Domestic)', val: 'lc_confirmation_discounting_domestic' }]}
                                        name={`lcPurpose${j}`} label={""} optionLabel={"name"} optionValue={'val'} value={data[`lcPurpose${j}`]} error={errors[`lcPurpose${j}`]} onChange={handleChange}
                                      />
                                    </td>
                                    <td>
                                      {/* <MultipleSelectForTable
                                        filterOption={() => true}
                                        onInputChange={(e) => {
                                          handleFilterOptions(e, "buyerName")
                                        }}
                                        Label={""}
                                        Id={`selectedBuyer${j}`}
                                        optiondata={dbDataFilter}
                                        onChange={(e) => {
                                          handleChange(multiSelectEventHandler(e, `selectedBuyer${j}`, "id"), j)
                                        }}
                                        value={data[`selectedBuyer${j}`] ? [data[`selectedBuyer${j}`]] : []}
                                        name={`selectedBuyer${j}`}
                                        labelKey={"buyerNameHtml"}
                                        valKey={"id"}
                                        error={errors[`selectedBuyer${j}`]}
                                      /> */}
                                      <SelectForTable selectData={Lcdetailsdata}
                                        name={`lctype${j}`} label={""} optionLabel={"name"} optionValue={'value'} value={data[`lctype${j}`]} error={errors[`lctype${j}`]} onChange={handleChange}
                                      />
                                    </td>
                                    <td>
                                      <InputForTable isAstrix={false} type={"text"} label={""}
                                        name={`lcNo${j}`} value={data[`lcNo${j}`]} error={errors[`lcNo${j}`]}
                                        onChange={(e) => { handleChange(e, j) }} />
                                    </td>
                                    <td>
                                      <InputForTable isAstrix={false} label={""}
                                        type={"number"} name={`lcTenor${j}`}
                                        value={data[`lcTenor${j}`]}
                                        onChange={(e) => { handleChange(e, j) }} error={errors[`lcTenor${j}`]} />
                                    </td>
                                    <td>
                                      <MultipleSelectForTable
                                        Label={""}
                                        Id={`countryOfOrigin${j}`}
                                        optiondata={countrys}
                                        onChange={(e) => {
                                          handleChange(multiSelectEventHandler(e, `countryOfOrigin${j}`, "sortname"), j)
                                        }}
                                        value={data[`countryOfOrigin${j}`] ? [data[`countryOfOrigin${j}`]] : []}
                                        name={`countryOfOrigin${j}`}
                                        labelKey={"name"}
                                        valKey={"sortname"}
                                        error={errors[`countryOfOrigin${j}`]}
                                      />
                                    </td>
                                    <td>
                                      <MultipleSelectForTable
                                        Id="LC issuing bank name"
                                        Label=""
                                        filterOption={() => true}
                                        onInputChange={(e) => {
                                          handleFilterOptions(e, "swiftData")
                                        }}
                                        optiondata={swiftData}
                                        onChange={(e) => handleMultiSelect(e, `lcIssuingBank${j}`, "id", j)}
                                        value={data[`lcIssuingBank${j}`] ? [data[`lcIssuingBank${j}`]] : []}
                                        name={`lcIssuingBank${j}`}
                                        labelKey={"bankName"}
                                        valKey={"id"}
                                        error={errors[`lcIssuingBank${j}`]}
                                      />
                                    </td>

                                    <td>
                                      <div className="d-flex w-100 justify-content-between" >
                                        <div className="w-50" >
                                          {(lcRows.length - lcRows.filter(i => {
                                            if (i === undefined) {
                                              return true
                                            }
                                          }).length) - 1 === jj ?
                                            <img
                                              onClick={async () => {
                                                let temp = lcRows
                                                temp.push(null)
                                                setLCRows([...temp])
                                              }}
                                              className="cursor" src="assets/images/add_black_icon.png" /> :
                                            <img
                                              onClick={() => {
                                                let temp = lcRows
                                                temp[j] = undefined
                                                setLCRows([...temp])
                                              }}
                                              className="cursor" src="assets/images/delete.png" />}
                                        </div>
                                        <div className="w-50">
                                          <img
                                            onClick={() => setOcrScreen({ show: true, index: j })}
                                            src="assets/images/scann.png" className="cursor" style={{ width: 20, height: 20 }} />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>)
                              }
                            })}
                          </NewTablev2>
                          {/* <div
                            onClick={() => {
                              window.open(`/buyerManagement?action=addNewBuyer`, "_blank");
                              localStorage.setItem('afterUpdateCloseTab', true)
                            }}
                            className="cursor" >
                            <label className="font-size-14 text-color1 font-wt-600 mx-1 cursor">{"Add New Buyer"}</label>
                            <img
                              className="cursor text-color1" src={'assets/images/right-arrow.png'} />
                          </div> */}
                        </>
                      ) : null}
                      {ocrScreen.show ?
                        <div className="mt-3">
                          <div
                            className="mb-4"
                            onClick={() => setOcrScreen({})}
                          >
                            <a
                              className="cursor font-wt-600"><img className="mx-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} />Back to form</a>
                          </div>
                          <LcDetailsV3
                            indx={ocrScreen.index}
                            ocrTxtFormat={ocrTxtFormat?.[ocrScreen.index] || []}
                            adminView={adminView}
                            reloadUserBankDetails={reloadUserBankDetails}
                            handleMultiSelect={(e, name, val) => handleMultiSelect(e, name, val, ocrScreen.index)}
                            handleFilterOptions={handleFilterOptions}
                            amendmentId={amendmentId}
                            fieldLabels={amendReq.fieldLabels || []} fieldComments={amendReq.fieldComments || {}}
                            fieldChanged={amendReq.changedDetails || {}}
                            ammendPopup={ammendPopup} toggleAmmendPopup={toggleAmmendPopup}
                            ammendPopup2={ammendPopup2} toggleAmmendPopup2={toggleAmmendPopup2}
                            ammendFields={ammendFields} setAmmendFields={setAmmendFields}
                            onlyView={onlyView} lcCodes={lcCodes} ocrData={ocrData?.[ocrScreen?.index] || {}} handleOCRFieldsChange={(e) => handleOCRFieldsChange(e, ocrScreen.index)}
                            data={data} setData={setData} handleChange={(e) => handleChange(e, ocrScreen.index)} errors={errors} handleFile={(e) => handleFile(e, ocrScreen.index)} countrys={countrys} currencyData={currencyData}
                            ports={ports} userBanks={userBanks} swiftData={swiftData}
                            tab1={tab1} setTab1={setTab1} confirmbanking={confirmbanking} setConfirmbanking={setConfirmbanking} confirmBank={confirmBank} />
                        </div>
                        : null}
                    </div> :
                      <div className="row mt-3">
                        <div className='w-100 d-flex row'>
                          <div className="col-3 p-0 my-3 mr-4" style={{ position: "relative", zIndex: "9" }}>
                            <MultipleSelect singleSelection
                              Label={'Select Contract'}
                              Id={`commodityContract0`}
                              optiondata={contractList}
                              onChange={(e) => {
                                handleChange(multiSelectEventHandler(e, `commodityContract0`, "contract_number"), 0)
                              }}
                              value={data[`commodityContract0`] ? [data[`commodityContract0`]] : []}
                              name={`commodityContract0`}
                              labelKey={"contract_name"}
                              valKey={"contract_number"}
                              error={errors[`commodityContract0`]}
                            />
                          </div>
                          <div className="col-3 p-0 my-3  mr-4" style={{ position: "relative", zIndex: "9" }} >
                            <NewSelect isAstrix={false} label={"Select Buyer"}
                              selectData={dbData} name={`selectedBuyer0`} error={errors[`selectedBuyer0`]}
                              value={data[`selectedBuyer0`]} optionLabel={"buyerName"} optionValue={'id'}
                              onChange={(e) => handleChange(e, 0)} />
                          </div>
                          <div className="col-3 m-0 p-0 my-3" style={{ position: "relative", zIndex: "9" }} >
                            <NewSelect isAstrix={false} label={"Select LC Service"}
                              selectData={[{ name: 'LC Confirmation (International)', val: 'lc_confirmation' }, { name: 'LC Discounting (International)', val: 'lc_discounting' },
                              { name: 'LC Confirmation & Discounting (International)', val: 'lc_confirmation_discounting' },
                              { name: 'LC Confirmation (Domestic)', val: 'lc_confirmation_domestic' }, { name: 'LC Discounting (Domestic)', val: 'lc_discounting_domestic' },
                              { name: 'LC Confirmation & Discounting (Domestic)', val: 'lc_confirmation_discounting_domestic' }]} name={`lcPurpose0`} error={errors[`lcPurpose0`]}
                              value={data[`lcPurpose0`]} optionLabel={"name"} optionValue={'val'}
                              onChange={(e) => handleChange(e, 0)} />
                          </div>
                        </div>

                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                          {data.selectedBuyer0 ?
                            <img
                              onClick={() => { window.location = `/buyerManagement?editBuyer=${data.selectedBuyer0}` }}
                              className="ms-3 cursor"
                              src={"assets/images/edit.png"} alt="edit" /> : null}
                        </label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>}

                    {/* textarea starts here */}
                    {/* <div class="input-group mb-3 col-md-4 px-0 border borderRadius input-group-lg textareablur" id="hamburger">
                      <textarea contenteditable="true" class="form-control border-0 w-90" aria-label="With textarea" placeholder="23 - Reference to pre-advice23" aria-describedby="inputGroup-sizing-lg"></textarea>

                      <span onClick={() => setTextarea(true)} id="inputGroup-sizing-lg" class=" cursor h-100 input-group-text bg-transparent border-0 position-absolute mt-2" style={{ right: "12px " }}><img className="genericImgIcon" src={"assets/images/chat.png"} alt="" /></span>
                    </div> */}

                    {/* {textarea && <div class={`modal fade ${textarea && "show"}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
                      <div class="modal-dialog modal-md modal-dialog-centered justify-content-center">
                        <div class="modal-content borderRadius submitmodal TextareaModal">
                          <div class="modal-body">
                            <div className="text-end mb-2">
                              <img onClick={() => setTextarea(false)} src={"/assets/images/cancel-icon.png"} alt="" className="cursor" />
                            </div>
                            <div>
                              <textarea contenteditable="true" class="form-control border w-90" aria-label="With textarea" placeholder="23 - Reference to pre-advice23" aria-describedby="inputGroup-sizing-lg" disabled></textarea>
                              <div className="text-center mt-3">
                                <NewTextArea
                                  rows={3}
                                  type={"text"} label={`Comment`} name={ammendPopup["lcNo"]}
                                  value={ammendFields[ammendPopup["lcNo"]]}
                                  onChange={(e) => {
                                    setAmmendFields({ ...ammendFields, ["lcNo"]: e.target.value || undefined })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="text-end">
                              <button className="new-btn py-2 px-3 text-white font-size-12">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>} */}


                    {/* textarea ends here */}

                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='ulFormsContainer'>
                    {!inputdisplay && <div className="row">
                      <label className='font-wt-500 font-size-16 mb-3'><u>Select LC type</u></label>
                      {Lcdetailsdata.map((key) => {
                        return (
                          <div className={`col-md-3 mt-4 cursor `} key={key.value}>
                            <div className={`card border-0 text-center borderRadius`}>
                              <a onClick={() => {
                                setData({ ...data, lctype0: key.value })
                              }
                              } className={`font-size-13 mb-0 p-2 borderRadius  ${data.lctype0 === key.value ? "activebox " : " border"}`}> {key.name}</a>
                            </div>
                          </div>
                        );
                      })}
                      <div className="mt-5">
                        <label className='font-wt-500 font-size-16 mb-3'><u>{`Attach ${amendmentId ? "Amended LC" : 'Draft LC'} `}</u></label>
                        <div className="col-md-5">
                          <div className="col-md-11 px-0">
                            {/* <label className="font-size-14">{amendmentId ? "Amended LC" : "Draft LC"}</label> */}
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument0"} value={data[`draftLCDocument0`]} error={errors[`draftLCDocument0`]}
                                  onChange={handleFile} isEditable={adminView ? false : true}
                                  onUploadCancel={() => setData({ ...data, [`draftLCDocument0`]: null })} />
                                {errors[`draftLCDocument0`] ? <div class="text-danger mt-2 font-size-12">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors[`draftLCDocument0`]}</b></div> : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>}
                    {inputdisplay &&
                      <div className="mt-3">
                        <LcDetailsV3
                          indx={0}
                          ocrTxtFormat={ocrTxtFormat?.[0] || []}
                          adminView={adminView}
                          reloadUserBankDetails={reloadUserBankDetails}
                          handleMultiSelect={(e, name, val) => handleMultiSelect(e, name, val, 0)}
                          handleFilterOptions={handleFilterOptions}
                          amendmentId={amendmentId}
                          fieldLabels={amendReq.fieldLabels || []} fieldComments={amendReq.fieldComments || {}}
                          fieldChanged={amendReq.changedDetails || {}}
                          ammendPopup={ammendPopup} toggleAmmendPopup={toggleAmmendPopup}
                          ammendPopup2={ammendPopup2} toggleAmmendPopup2={toggleAmmendPopup2}
                          ammendFields={ammendFields} setAmmendFields={setAmmendFields}
                          onlyView={onlyView} lcCodes={lcCodes} ocrData={ocrData?.[0] || {}} handleOCRFieldsChange={(e) => handleOCRFieldsChange(e, 0)}
                          data={data} setData={setData} handleChange={(e) => handleChange(e, 0)} errors={errors} handleFile={(e) => handleFile(e, 0)} countrys={countrys} currencyData={currencyData}
                          ports={ports} userBanks={userBanks} swiftData={swiftData}
                          tab1={tab1} setTab1={setTab1} confirmbanking={confirmbanking} setConfirmbanking={setConfirmbanking} confirmBank={confirmBank} />
                      </div>}
                  </div>
                ) : null}
                {tab === 2 ? (

                  <div className='ulFormsContainer'>

                    <div className="accordionWrapper m-0 p-0">
                      {lcRows.map((key, index) => {
                        if (key === null) {
                          return (
                            <div className="col-md-12 row m-0 p-0">
                              <div
                                style={errors[`accordion${index}`] ? { border: '2px solid red' } : { border: accordion === (index + 1) ? '2px solid rgb(92, 184, 211)' : '0' }}
                                className={"accordionItem col-md-12" + (accordion === (index + 1) ? " open" : " closed")}>
                                <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion(accordion === index + 1 ? null : (index + 1)) }}>
                                  <label className="font-size-16 font-wt-600 col-3 pt-3" >{data[`lcNo${index}`]}</label>
                                  <div className='mb-3 d-flex pl-2 pt-2 align-items-center col-auto'>
                                    <label className={!data[`currentDocuments${index}`] ? "font-wt-500 font-size-16" :
                                      "font-wt-800 font-size-16 color-2ECC71"} for={`currentDocuments${index}`}>Current Trade Documents</label>
                                    <img
                                      className="cursor mx-3"
                                      onClick={() => setData({ ...data, [`currentDocuments${index}`]: !data[`currentDocuments${index}`], [`pastDocuments${index}`]: !data[`pastDocuments${index}`] })}
                                      src={data[`currentDocuments${index}`] ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                                    />
                                    <label className={!data[`pastDocuments${index}`] ? "font-wt-500 font-size-16" :
                                      "font-wt-800 font-size-16 color-2ECC71"} for={`pastDocuments${index}`}>Past Trade Documents</label>
                                    <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2 mb-2" />
                                    <div style={{ top: '3rem' }} className="otherChargePopup p-3">
                                      <div>
                                        <label className="font-size-13 font-wt-600" ><u>Current Trade Documents:</u></label>
                                        <label className="font-size-12 font-wt-500">Current trade documents are documents related to ongoing or future trade transactions</label>
                                        <label className="font-size-13 font-wt-600" ><u>Past Trade Documents:</u></label>
                                        <label className="font-size-12 font-wt-500">Past trade documents are records of completed trade transactions</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{
                                    // height: '2rem',
                                    width: '6rem'
                                  }} className="" >
                                    <CircularProgressbar
                                      styles={buildStyles({
                                        pathColor: '#2ECC71',
                                        textColor: '#000000'
                                      })}
                                      value={(totalDocsCount[index] / 9) * 100} text={`${totalDocsCount[index]}/9`} />
                                  </div>
                                </div>
                                <div className="accordionItemContent">
                                  <div
                                    className={"mx-1 px-2"}
                                  >
                                    <label
                                      className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                                    ><u>{`Invoice & Purchase Order`}</u></label>
                                    <NewTablev2 columns={documentTable}>
                                      {multInvPo.map((i, j) => {
                                        return <>
                                          {i.invoices?.map((o, p) => {
                                            return (
                                              <tr>
                                                <td>{"Invoice"}</td>
                                                <td><div>
                                                  <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                                    onMultipleDragDrop={async (e) => {
                                                      let tempErr = errors
                                                      let tempData = data
                                                      for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                                                        let element = e.target.files[indexx];
                                                        let customEvent = {
                                                          target: { name: "invoiceDocument" + index + ":" + j + ":" + (p + indexx), files: [element] },
                                                          persist: () => console.log("...")
                                                        }
                                                        let resp = await handleFileWithAsyncAwait(customEvent, j, p + indexx, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                                        if (resp.status / 1 == 0) {
                                                          tempErr = { ...tempErr, ...resp.msg }
                                                        }
                                                        if (resp.status / 1 == 1) {
                                                          tempData = { ...tempData, ...resp.msg }
                                                        }
                                                      }
                                                      setData(tempData)
                                                      setErrors(tempErr)
                                                      let temp = multInvPo
                                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                                        temp[j].invoices.push(null)
                                                      }
                                                      setMultInvPo([...temp])
                                                    }}
                                                    name={`invoiceDocument${index}:${j}:${p}`} value={data[`invoiceDocument${index}:${j}:${p}`]} error={errors[`invoiceDocument${index}:${j}:${p}`]}
                                                    isEditable={true} onChange={handleFile}
                                                    onUploadCancel={() => setData({ ...data, [`invoiceDocument${index}:${j}:${p}`]: null })}
                                                  />
                                                  {errors[`invoiceDocument${index}:${j}:${p}`] ? <div class="text-danger mt-2 font-size-12">
                                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                    <b>{errors[`invoiceDocument${index}:${j}:${p}`]}</b></div> : ''}
                                                </div></td>
                                                <td><div className="position-relative">
                                                  <InputForTable isAstrix={false} type={"text"} label={""}
                                                    name={`invoiceRefrenceNo${index}:${j}:${p}`} value={data[`invoiceRefrenceNo${index}:${j}:${p}`]} error={errors[`invoiceRefrenceNo${index}:${j}:${p}`]}
                                                    onChange={handleChange} />
                                                </div></td>
                                                <td><div className="position-relative">
                                                  <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                                    selectData={currencyData}
                                                    selectName={`currency${index}:${j}:${p}`} selectValue={data[`currency${index}:${j}:${p}`]}
                                                    optionLabel={"code"} optionValue={'code'}
                                                    name={`contractAmount${index}:${j}:${p}`} value={data[`contractAmount${index}:${j}:${p}`]} error={errors[`contractAmount${index}:${j}:${p}`] || errors[`currency${index}:${j}:${p}`]}
                                                    onChange={handleChange} />
                                                </div></td>
                                                <td>
                                                  {!data[`invoiceDocument${index}:${j}:${p}`]?.signatureId ?
                                                    <img className="cursor"
                                                      onClick={() => { setSigndoc(data[`invoiceDocument${index}:${j}:${p}`]?.name); setData({ ...data, docToSign: data[`invoiceDocument${index}:${j}:${p}`], docSignName: `invoiceDocument${index}:${j}:${p}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                                    :
                                                    <img style={{ width: '20px' }} className=""
                                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                                  }
                                                </td>
                                                <td><div
                                                  className="d-flex"
                                                >
                                                  {data[`invoiceDocument${index}:${j}:${p}`]?.name ? (
                                                    <>
                                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invoiceDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invoiceDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                    </>
                                                  ) : null}
                                                  {(i.invoices.length - 1 > p) ?
                                                    <img
                                                      onClick={() => {
                                                        let temp = multInvPo
                                                        let tempUpdatedPo = []
                                                        for (let m = 0; m < temp[j].invoices.length; m++) {
                                                          if (m != p) {
                                                            tempUpdatedPo.push(null)
                                                          }
                                                        }
                                                        temp[j].invoices = tempUpdatedPo
                                                        setMultInvPo([...temp])
                                                      }}
                                                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                                    /> : <img
                                                      onClick={() => {
                                                        let temp = multInvPo
                                                        temp[j].invoices.push(null)
                                                        setMultInvPo([...temp])
                                                      }}
                                                      style={{ width: '22px', height: '22px' }}
                                                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                                </div></td>
                                              </tr>
                                            )
                                          })}
                                          {
                                            i.purchase_orders?.map((o, p) => {
                                              return (
                                                <tr>
                                                  <td>{"Purchase Order"}</td>
                                                  <td>
                                                    <div>
                                                      <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                                        onMultipleDragDrop={async (e) => {
                                                          let tempErr = errors
                                                          let tempData = data
                                                          for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                                                            let element = e.target.files[indexx];
                                                            let customEvent = {
                                                              target: { name: "poDocument" + index + ":" + j + ":" + (p + indexx), files: [element] },
                                                              persist: () => console.log("...")
                                                            }
                                                            let resp = await handleFileWithAsyncAwait(customEvent, j, p + index, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                                            if (resp.status / 1 == 0) {
                                                              tempErr = { ...tempErr, ...resp.msg }
                                                            }
                                                            if (resp.status / 1 == 1) {
                                                              tempData = { ...tempData, ...resp.msg }
                                                            }
                                                            // console.log("customEventtttttttttttttttt", customEvent, resp);
                                                          }
                                                          setData(tempData)
                                                          setErrors(tempErr)
                                                          let temp = multInvPo
                                                          for (let index = 0; index < e.target.files.length - 1; index++) {
                                                            temp[j].purchase_orders.push(null)
                                                          }
                                                          setMultInvPo([...temp])
                                                        }}
                                                        name={`poDocument${index}:${j}:${p}`} value={data[`poDocument${index}:${j}:${p}`]} error={errors[`poDocument${index}:${j}:${p}`]}
                                                        isEditable={true}
                                                        onUploadCancel={() => setData({ ...data, [`poDocument${index}:${j}:${p}`]: null })}
                                                      />
                                                      {errors[`poDocument${index}:${j}:${p}`] ? <div class="text-danger mt-2 font-size-12">
                                                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                        <b>{errors[`poDocument${index}:${j}:${p}`]}</b></div> : ''}
                                                    </div>
                                                  </td>
                                                  <td><div className="position-relative">
                                                    <InputForTable isAstrix={false} type={"text"} label={""}
                                                      name={`poRefrenceNo${index}:${j}:${p}`} value={data[`poRefrenceNo${index}:${j}:${p}`]} error={errors[`poRefrenceNo${index}:${j}:${p}`]}
                                                      onChange={(e) => handleChange(e, j, p)} />
                                                  </div></td>
                                                  <td><div className="position-relative">
                                                    <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                                      selectData={currencyData}
                                                      selectName={`currency${index}:${j}:${p}`} selectValue={data[`currency${index}:${j}:${p}`]}
                                                      optionLabel={"code"} optionValue={'code'}
                                                      name={`poAmount${index}:${j}:${p}`} value={data[`poAmount${index}:${j}:${p}`]} error={errors[`poAmount${index}:${j}:${p}`] || errors[`currency${index}:${j}:${p}`]}
                                                      onChange={handleChange} />
                                                  </div></td>
                                                  <td>
                                                    {!data[`poDocument${index}:${j}:${p}`]?.signatureId ?
                                                      <img className="cursor"
                                                        onClick={() => { setSigndoc(data[`poDocument${index}:${j}:${p}`]?.name); setData({ ...data, docToSign: data[`poDocument${index}:${j}:${p}`], docSignName: `poDocument${index}:${j}:${p}` }) }}
                                                        src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                                      :
                                                      <img className=""
                                                        style={{ width: '20px' }}
                                                        src={"assets/images/doc_sign_filled.svg"} alt="" />
                                                    }
                                                  </td>
                                                  <td><div
                                                    className="d-flex"
                                                  >
                                                    {data[`poDocument${index}:${j}:${p}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`poDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`poDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                    {i.purchase_orders.length - 1 > p ?
                                                      <img
                                                        onClick={() => {
                                                          let temp = multInvPo
                                                          let tempUpdatedPo = []
                                                          for (let m = 0; m < temp[j].purchase_orders.length; m++) {
                                                            if (m != p) {
                                                              tempUpdatedPo.push(null)
                                                            }
                                                          }
                                                          temp[j].purchase_orders = tempUpdatedPo
                                                          setMultInvPo([...temp])
                                                        }}
                                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                                      /> :
                                                      <img
                                                        onClick={() => {
                                                          let temp = multInvPo
                                                          temp[j].purchase_orders.push(null)
                                                          setMultInvPo([...temp])
                                                        }}
                                                        style={{ width: '22px', height: '22px' }}
                                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />
                                                    }
                                                  </div></td>
                                                </tr>
                                              )
                                            })}
                                        </>
                                      })}

                                    </NewTablev2>
                                  </div>

                                  <div
                                    className={"mx-1 px-2"}
                                  >
                                    <label
                                      className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                                    ><u>{`Other Documents`}</u></label>
                                    <NewTablev2 columns={lcDocumentTable}
                                    >
                                      <tr>
                                        <td>{"Draft LC"}</td>
                                        <td><div className="position-relative">
                                          <FileInput isEditable={false} hideViewDowdBtns={true}
                                            name={`draftLCDocument${index}`} value={data[`draftLCDocument${index}`]} error={errors[`draftLCDocument${index}`]}
                                          />
                                        </div></td>
                                        <td>{data[`lcNo${index}`]}</td>
                                        <td>
                                          {!data[`draftLCDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`draftLCDocument${index}`]?.name); setData({ ...data, docToSign: data[`draftLCDocument${index}`], docSignName: `draftLCDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`draftLCDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`draftLCDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`draftLCDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Investigation Report</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`invReportDocument${index}`} value={data[`invReportDocument${index}`]} error={errors[`invReportDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`invReportDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {!data[`invReportDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`invReportDocument${index}`]?.name); setData({ ...data, docToSign: data[`invReportDocument${index}`], docSignName: `invReportDocument${index}` }) }}
                                              src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`invReportDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Bill of Lading / Airway Bill</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`logisticDocument${index}`} value={data[`logisticDocument${index}`]} error={errors[`logisticDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`logisticDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {!data[`logisticDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`logisticDocument${index}`]?.name); setData({ ...data, docToSign: data[`logisticDocument${index}`], docSignName: `logisticDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`logisticDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Certificate of Origin</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`grnDocument${index}`} value={data[`grnDocument${index}`]} error={errors[`grnDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`grnDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {!data[`grnDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`grnDocument${index}`]?.name); setData({ ...data, docToSign: data[`grnDocument${index}`], docSignName: `grnDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`grnDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td><div>
                                          <label>Sales Contract</label>
                                          <div className="align-self-center">
                                            <img
                                              onClick={() => {
                                                setData({ ...data, [`salesContractNotAvailable${index}`]: !data[`salesContractNotAvailable${index}`] })
                                              }}
                                              className='cursor mr-3' src={`assets/images/${data[`salesContractNotAvailable${index}`] ? 'checked-green' : 'empty-check'}.png`} />
                                            <label>Not Available</label>
                                          </div>
                                        </div></td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`salesContractDocument${index}`} value={data[`salesContractDocument${index}`]} error={errors[`salesContractDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`salesContractDocument${index}`]: null })}
                                          />
                                          {errors[`salesContractDocument${index}`] ? <div class="text-danger mt-2 font-size-12">
                                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            <b>{errors[`salesContractDocument${index}`]}</b></div> : ''}
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {!data[`salesContractDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`salesContractDocument${index}`]?.name); setData({ ...data, docToSign: data[`salesContractDocument${index}`], docSignName: `salesContractDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`salesContractDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Shipping Bill</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`shippingBillDocument${index}`} value={data[`shippingBillDocument${index}`]} error={errors[`shippingBillDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`shippingBillDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {!data[`shippingBillDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`shippingBillDocument${index}`]?.name); setData({ ...data, docToSign: data[`shippingBillDocument${index}`], docSignName: `shippingBillDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`shippingBillDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Packing List</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`packingListDocument${index}`} value={data[`packingListDocument${index}`]} error={errors[`packingListDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`packingListDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {!data[`packingListDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`packingListDocument${index}`]?.name); setData({ ...data, docToSign: data[`packingListDocument${index}`], docSignName: `packingListDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`packingListDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>
                                    </NewTablev2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>

                ) : null}
                {/* 
                {tab === 3 ? (
                  <div className='ulFormsContainer'>
                    {multipleLCForm ?
                      <div className='mb-3 d-flex pl-2 pt-2 align-items-center col-auto'>
                        <label className={!data[`collectiveSelection`] ? "font-wt-500 font-size-16" :
                          "font-wt-800 font-size-16 color-2ECC71"} for={`collectiveSelection`}>Collective Selection</label>
                        <img
                          className="cursor mx-3"
                          onClick={() => setData({ ...data, [`collectiveSelection`]: !data[`collectiveSelection`], [`individualSelection`]: !data[`individualSelection`] })}
                          src={data[`collectiveSelection`] ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                        />
                        <label className={!data[`individualSelection`] ? "font-wt-500 font-size-16" :
                          "font-wt-800 font-size-16 color-2ECC71"} for={`individualSelection`}>Individual Selection</label>
                        <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2 mb-2" />
                        <div style={{ top: '3rem', left: '15rem' }} className="otherChargePopup p-3">
                          <div>
                            <label className="font-size-13 font-wt-600" ><u>Collective Selection:</u></label>
                            <label className="font-size-12 font-wt-500">Select same financiers for all LC application</label>
                            <label className="font-size-13 font-wt-600" ><u>Individual Selection:</u></label>
                            <label className="font-size-12 font-wt-500">Select different financiers for individual LC application</label>
                          </div>
                        </div>
                      </div> : null}

                    {data.individualSelection ? <div className="accordionWrapper p-0 m-0">
                      {lcRows.map((i, j) => {
                        if (i === null) {
                          let finListWithJ = finList?.[j] || []
                          return (
                            <div className="col-md-12 row p-0 m-0">
                              <div
                                style={errors[`accordion1${j}`] ? { border: '2px solid red' } : { border: accordion1 === (j + 1) ? '2px solid rgb(92, 184, 211)' : '0' }}
                                className={"accordionItem col-md-12" + (accordion1 === (j + 1) ? " open" : " closed")}>
                                <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion1(accordion1 === j + 1 ? null : (j + 1)) }}>
                                  <label className="font-size-16 font-wt-600 col-3 pt-3" >{data[`lcNo${j}`]}</label>
                                </div>
                                <div className="accordionItemContent">
                                  <label className='font-wt-500 font-size-16'>Select financer</label>
                                  <div style={{ marginBottom: '2.5rem' }} className='row justify-content-between '>
                                    {
                                      finListWithJ.map((item, index) => {
                                        return <div style={{ width: '22%', marginTop: '2.2rem' }} className={item.isAvailable ? ` ${item.isRecommened ? ' financierCardNewRecommended ' : ' financierCardNew '}  ` : '  financierCardNewDisabled '}>
                                          {item.isRecommened ? <img src="assets/images/recommended_ribbon.svg" className={'recommendRibbon2'} /> : null}
                                          <div className='d-flex w-100 gap-4 align-items-center justify-content-between'>
                                            <div className='d-flex gap-4 align-items-center'>
                                              <img style={{ maxWidth: '120px', maxHeight: '40px' }} src={avatarUrl(item.logo)} />
                                            </div>
                                            <div className="" >
                                              {!item.isAvailable ?
                                                null :
                                                <img
                                                  onClick={() => {
                                                    if (item.isAvailable || environment != "prod") {
                                                      handleFinSelection(j, index)
                                                    } else {
                                                      toastDisplay("Action not availble", "info")
                                                    }
                                                  }}
                                                  className={'cursor mr-3'} src={`assets/images/${finListWithJ?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                                              }
                                            </div>
                                          </div>
                                          <div className="d-flex px-2 pt-3 w-100">
                                            <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14 font-wt-500">{item.avgRatings.toFixed(2)}</p>
                                              <span>
                                                <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                              </span>
                                            </div>
                                            <div
                                              onClick={async () => {
                                                // Get getUserTradeInfoWithFinancier
                                                let temp = item
                                                temp["show"] = true
                                                toggleReviewScrollIndex(0)
                                                setshowLoader(true)
                                                temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: item.id, sellerId: userId }) }
                                                setshowLoader(false)
                                                setSeeReview(temp)
                                              }}
                                              className="col-6 px-0 cursor"><p className="mb-1 font-size-14 font-wt-500">{item.reviewsCount}</p><p className="font10 font-size-14 font-wt-500">See reviews</p></div>
                                          </div>
                                          <p
                                            onClick={() => {
                                              setSelectedChat({
                                                receiverName: item.name,
                                                receiverId: item.id,
                                                userId: userId,
                                                isChatOpen: true
                                              })
                                            }}
                                            className="cursor"
                                          ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2 font-wt-500">Chat with financer</span></p>
                                        </div>
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>)
                        }
                      })}
                    </div> :
                      <div className="accordionWrapper p-0 m-0">
                        {lcRows.map((i, j) => {
                          if (j / 1 == 0) {
                            let finListWithJ = finList?.[j] || []
                            return (
                              <div className="col-md-12 row p-0 m-0">
                                <div className="accordionItemContent">
                                  <label className='font-wt-500 font-size-16'>Select financer</label>
                                  <div style={{ marginBottom: '2.5rem' }} className='row justify-content-between '>
                                    {
                                      finListWithJ.map((item, index) => {
                                        return <div style={{ width: '22%', marginTop: '2.2rem' }} className={item.isAvailable ? ` ${item.isRecommened ? ' financierCardNewRecommended ' : ' financierCardNew '}  ` : '  financierCardNewDisabled '}>
                                          {item.isRecommened ? <img src="assets/images/recommended_ribbon.svg" className={'recommendRibbon2'} /> : null}
                                          <div className='d-flex w-100 gap-4 align-items-center justify-content-between'>
                                            <div className='d-flex gap-4 align-items-center'>
                                              <img style={{ maxWidth: '120px', maxHeight: '40px' }} src={avatarUrl(item.logo)} />
                                            </div>
                                            <div className="" >
                                              {!item.isAvailable ?
                                                null :
                                                <img
                                                  onClick={() => {
                                                    if (item.isAvailable || environment != "prod") {
                                                      handleFinSelection(j, index, true, finListWithJ?.[index]["isChecked"])
                                                    } else {
                                                      toastDisplay("Action not availble", "info")
                                                    }
                                                  }}
                                                  className={'cursor mr-3'} src={`assets/images/${finListWithJ?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                                              }
                                            </div>
                                          </div>
                                          <div className="d-flex px-2 pt-3 w-100">
                                            <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14 font-wt-500">{item.avgRatings.toFixed(2)}</p>
                                              <span>
                                                <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                              </span>
                                            </div>
                                            <div
                                              onClick={async () => {
                                                // Get getUserTradeInfoWithFinancier
                                                let temp = item
                                                temp["show"] = true
                                                toggleReviewScrollIndex(0)
                                                setshowLoader(true)
                                                temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: item.id, sellerId: userId }) }
                                                setshowLoader(false)
                                                setSeeReview(temp)
                                              }}
                                              className="col-6 px-0 cursor"><p className="mb-1 font-size-14 font-wt-500">{item.reviewsCount}</p><p className="font10 font-size-14 font-wt-500">See reviews</p></div>
                                          </div>
                                          <p
                                            onClick={() => {
                                              setSelectedChat({
                                                receiverName: item.name,
                                                receiverId: item.id,
                                                userId: userId,
                                                isChatOpen: true
                                              })
                                            }}
                                            className="cursor"
                                          ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2 font-wt-500">Chat with financer</span></p>
                                        </div>
                                      })
                                    }
                                  </div>
                                </div>

                              </div>)
                          }
                        })}
                      </div>}
                  </div>
                ) : null} */}

                {tab === 3 ? (
                  <div className='ulFormsContainer'>
                    <div className="accordionWrapper p-0 m-0">
                      {lcRows.map((key, index) => {
                        if (key === null) {
                          return (<div className="col-md-12 row p-0 m-0">
                            <div
                              style={errors[`accordion2${index}`] ? { border: '2px solid red' } : { border: accordion2 === (index + 1) ? '2px solid rgb(92, 184, 211)' : '0' }}
                              className={"accordionItem col-md-12" + (accordion2 === (index + 1) ? " open" : " closed")}>
                              <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion2(accordion2 === index + 1 ? null : (index + 1)) }}>
                                <label className="font-size-16 font-wt-600 col-3 pt-3" >{data[`lcNo${index}`]}</label>
                              </div>
                              <div className="accordionItemContent">
                                {data[`selectedBuyer${index}`] ? (
                                  <div className='w-100'>
                                    <label className='font-wt-500 font-size-16'><u>Buyer Details</u> </label>
                                    <div className='row'>
                                      {buyerReviewForm.map((item) => {
                                        return (
                                          <div className="col-md-6 pe-5">
                                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span>
                                              <span className="ellipsis-container" >
                                                <span className="ellipsis-text2 BuyerdetailsDesc" > {data[item.val + index] ? (item.unit ? `${data[item.unit + index]} ` : "") + (data[item.val + index]) : "NA"}</span>
                                              </span>
                                            </p>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                ) : null}
                                <div className={`w-100 ${!data[`selectedBuyer${index}`] ? 'mt-3' : ''}`}>
                                  <label className='font-wt-500 font-size-16'><u>LC Details</u> </label>
                                  <div className='row'>
                                    {reviewForm3.map((item) => {
                                      return (
                                        <div className="col-md-6 pe-5">
                                          <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span>
                                            <span className="ellipsis-container" >
                                              <span className="ellipsis-text2 BuyerdetailsDesc" > {data[item.val + index] ? (item.unit ? `${data[item.unit + index]} ` : "") + (data[item.val + index]) : "NA"}</span>
                                            </span>
                                          </p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                                <div className='w-100 mt-3'>
                                  <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                                  <div className="">
                                    <NewTablev2 columns={documentTable3}>
                                      {multInvPo.map((i, j) => {
                                        return <>
                                          {i.invoices?.map((o, p) => {
                                            if (data["invoiceDocument" + index + ":" + j + ":" + p]?.name) {
                                              return (<tr>
                                                <td>{`Invoice - ${data["invoiceRefrenceNo" + index + ":" + j + ":" + p] || ""}`}</td>
                                                <td>{`${data["contractAmount" + index + ":" + j + ":" + p] || ""} ${data["currency" + index + ":" + j + ":" + p] || ""}`}</td>
                                                <td>
                                                  <FileInput value={data["invoiceDocument" + index + ":" + j + ":" + p]} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data["invoiceDocument" + index + ":" + j + ":" + p]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data["invoiceDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data["invoiceDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr>
                                              )
                                            }
                                          })}
                                          {i.purchase_orders?.map((o, p) => {
                                            if (data["poDocument" + index + ":" + j + ":" + p]?.name) {
                                              return (<tr>
                                                <td>{`Purchase Order - ${data["poRefrenceNo" + index + ":" + j + ":" + p] || ""}`}</td>
                                                <td>{`${data["poAmount" + index + ":" + j + ":" + p] || ""} ${data["currency" + index + ":" + j + ":" + p] || ""}`}</td>
                                                <td>
                                                  <FileInput value={data["poDocument" + index + ":" + j + ":" + p]} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data["poDocument" + index + ":" + j + ":" + p]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data["poDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data["poDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr>
                                              )
                                            }
                                          })}
                                        </>
                                      })}
                                      {data[`draftLCDocument${index}`]?.name ?
                                        <tr>
                                          <td>Draft LC</td>
                                          <td>{data[`lcNo${index}`]}</td>
                                          <td>
                                            <FileInput name={`draftLCDocument${index}`} value={data[`draftLCDocument${index}`]} error={errors[`draftLCDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`draftLCDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`draftLCDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`draftLCDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                      {data[`invReportDocument${index}`]?.name ?
                                        <tr>
                                          <td>Investigation Report</td>
                                          <td>-</td>
                                          <td>
                                            <FileInput name={`invReportDocument${index}`} value={data[`invReportDocument${index}`]} error={errors[`invReportDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`invReportDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                      {data[`logisticDocument${index}`]?.name ?
                                        <tr>
                                          <td>Bill of Lading / Airway Bill</td>
                                          <td>-</td>
                                          <td>
                                            <FileInput name={`logisticDocument${index}`} value={data[`logisticDocument${index}`]} error={errors[`logisticDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`logisticDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                      {data[`grnDocument${index}`]?.name ?
                                        <tr>
                                          <td>Certificate of Origin</td>
                                          <td>-</td>
                                          <td>
                                            <FileInput name={`grnDocument${index}`} value={data[`grnDocument${index}`]} error={errors[`grnDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`grnDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                      {data[`salesContractDocument${index}`]?.name ?
                                        <tr>
                                          <td>Sales Contract</td>
                                          <td>-</td>
                                          <td>
                                            <FileInput name={`salesContractDocument${index}`} value={data[`salesContractDocument${index}`]} error={errors[`salesContractDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`salesContractDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                      {data[`shippingBillDocument${index}`]?.name ?
                                        <tr>
                                          <td>Shipping Bill</td>
                                          <td>-</td>
                                          <td>
                                            <FileInput name={`shippingBillDocument${index}`} value={data[`shippingBillDocument${index}`]} error={errors[`shippingBillDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`shippingBillDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                      {data[`packingListDocument${index}`]?.name ?
                                        <tr>
                                          <td>Packing List</td>
                                          <td>-</td>
                                          <td>
                                            <FileInput name={`packingListDocument${index}`} value={data[`packingListDocument${index}`]} error={errors[`packingListDocument${index}`]}
                                              onChange={handleFile} isEditable={false}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className="d-flex"
                                            >
                                              {data[`packingListDocument${index}`]?.name ? (
                                                <>
                                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                </>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr> : null}
                                    </NewTablev2>
                                  </div>
                                </div>
                                <div className='w-100 mt-5'>
                                  <label className='font-wt-500 font-size-16'><u>Financiers Selected</u></label>
                                  <div className="d-flex gap-4 my-3">
                                    {finList[index].map((key) => {
                                      if (key.isChecked) {
                                        return (
                                          <label key={key.name}
                                            className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                            {key.name}
                                          </label>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div> </div>)
                        }
                      })}
                    </div>


                    <div className='mt-3'>
                      <img
                        onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                        className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                      <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                    </div>

                  </div>

                ) : null}


                {onlyView || adminView ?
                  <div className="px-5 pb-5">
                    <button type="button"
                      disabled={(!data.tcAccepted && tab === 3)}
                      onClick={() => handleFun()}
                      className={`new-btn w-20 py-2 px-2 text-white enablesigncontract text-white `}>
                      {tab === 3 ? `${(onlyView || adminView) ? "Update" : " Submit "}` : "Continue"}
                    </button>
                  </div> :
                  <div
                    className="d-flex finActionTabContainer m-4" >
                    {tab / 1 != 3 ? <div
                      onClick={() => {
                        handleFun()
                      }}
                      style={{ borderRight: '2px solid #5CB8D3' }}
                      className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                      <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                    </div> : <div
                      onClick={() => {
                        setFinancierActionTab(0)
                        handleFun()
                      }}
                      style={{ borderRight: '2px solid #5CB8D3' }}
                      className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                      <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                    </div>}
                    <div
                      onClick={() => {
                        if (tab > 0) {
                          setTab(tab - 1)
                        }
                      }}
                      style={{ borderRight: '2px solid #5CB8D3' }}
                      className={`position-relative w-25 text-center py-0 px-2 cursor ${tab === 0 ? ' disable-bg ' : " "}`} >
                      <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Previous</label>
                    </div>
                    <div
                      style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                      <label
                        onClick={() => { toastDisplay('Application saved as draft', "success"); setRefresh(refresh + 1) }}
                        className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Save as Draft</label>
                    </div>
                    <div
                      onClick={() => {
                        setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                      }}
                      className={`position-relative w-25 text-center py-0 px-2 cursor`} >
                      {finActionTab == 2 && draftData.length ? <div
                        style={{
                          width: '14rem',
                          top: `${draftData.length * -2.5}rem`,
                          borderRadius: "10px"
                        }}
                        className="position-absolute " >
                        {draftData.map((i, j) => {
                          return (
                            <div
                              onClick={() => { window.location = `/LcDiscountingV2?formContinue=${i.id}` }}
                              style={{ height: '2.5rem' }} className="d-flex bg-white border-bottom-solid-dee2e6 align-items-center" >
                              <label
                                className="font-size-14 font-wt-500 col-8 cursor truncate" >{i.details?.data?.lcNo0 || "NA"}</label>
                              <div className="col-4 d-flex align-items-center" >
                                <label
                                  style={{
                                    background: "#5CB8D3",
                                    color: "#fff",
                                    borderRadius: "100%"
                                  }}
                                  className="ml-3 px-1 py-2 m-0" >{i.details.percentageComplete + " %"}</label>
                              </div>
                            </div>
                          )
                        })}
                      </div> : null}
                      <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Draft
                        <span
                          style={{
                            background: "#5CB8D3",
                            color: "#fff",
                            borderRadius: "100%"
                          }}
                          className="ml-3 px-2 py-1" >{draftData.length}</span>
                      </label>
                    </div>
                  </div>}
              </div>
            </div>
          </main>
        </div >
      </div >

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      {
        limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
          <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
          <p>Your Application for Finance limit has been submitted successfully !</p>
        </FinanceInvoiceModal>
      }

      {/* {experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
        <img src={"/assets/images/lc/coins.png"} alt="" className="mb-3" />
        <p>100 coins debited from your wallet for finance limit application. </p>
        <p>Available balance : <img src={"/assets/images/sidebarV2/coin.png"} alt="" /> <span>10,000</span></p>
      </FinanceInvoiceModal >} */}

      {
        askForConfirmingBank == 1 && <FinanceInvoiceModal limitinvoice={askForConfirmingBank == 1}
          closeSuccess={() => setAskForConfirmingBank(askForConfirmingBank + 1)}>
          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
          <p className="font-size-13 font-wt-600">Do you have any other confirming bank?</p>
          <button className="bg-white px-3 py-2 btnsmall font-size-13" onClick={() => confirmBank()}>Yes</button>
          <button className="bg-white px-3 py-2 btnsmall font-size-13 ms-3" onClick={() => setAskForConfirmingBank(askForConfirmingBank + 1)}>No</button>
        </FinanceInvoiceModal>
      }

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(LcDiscountingcardV2)