import React, { useRef, useState } from "react";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import StandardCard from "../wallet/components/standardCard";
import toastDisplay from "../../utils/toastNotification";
import call from "../../service";
import { useEffect } from "react";
import BalanceCard from "../wallet/components/BalanceCard";
import moment from "moment";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { CustomizedTable } from "../wallet/components/CustomizedTable";
import { NewInput, SelectForTable } from "../../utils/newInput";
import { ToastContainer } from "react-toastify";

const otherIncludes = [
  "Daily Market Updates",
  "Dedicated RM",
  "MIS Reports",
  "E-Invoice Generator",
];
const tablerows = [
  { label: "LC nos.", currentKey: "lc_nos", actualKey: "total_lc_nos" },
  {
    label: "Buyer Details",
    currentKey: "buyer_nos",
    actualKey: "total_buyer_nos",
  },
  {
    label: "Document Vault Limit",
    currentKey: "transactions_nos",
    actualKey: "total_transactions_nos",
  },
  { label: "Points", currentKey: "points_nos", actualKey: "points_nos" },
  {
    label: "Invoice nos.",
    currentKey: "invoice_nos",
    actualKey: "total_inv_nos",
  },
];
const Settings = ({ userTokenDetails, navToggleState }) => {
  const [showLoader, setshowLoader] = useState(false);
  const [planBalance, setPlanBalance] = useState({});
  const [walletData, setWalletData] = useState({});
  const [showmodalTopup, setShowModalTopup] = useState(false);
  const [showmodalCurrentDetails, setShowModalCurrentDetails] = useState(false);
  const [topupdata, settopupdata] = useState([]);
  const [plandata, setplandata] = useState([]);
  const [tableExpand, setTableExpand] = useState("");
  const [data, setdata] = useState({ theme: "Default", language: "English" });
  const [isSetPasswordPopup, setIsSetPasswordPopup] = useState(false);
  const [showauthModal, setshowauthModel] = useState(false);
  const [boolauthentication, setboolauthentication] = useState(false);
  const [isNavbarEditable, setIsNavbarEditable] = useState(false);
  const [errors, setErrors] = useState({});
  const [resetWithOtp, toggleResetWithOtp] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null;
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;
  const twoauth = userTokenDetails.twofactorstate ? userTokenDetails.twofactorstate : 0;
  console.log(userEmail, "maillll iddd");
  useEffect(() => {
    getPlanBalance();
    getWalletBalance();
    getTopupPlans();
    getUserCurrentPlan();
  }, []);
  const getTopupPlans = () => {
    setshowLoader(true);
    call("POST", "getTopupPlans", {})
      .then((result) => {
        settopupdata(formatDataForTopup(result));
        setshowLoader(false);
      })
      .catch((e) => {
        console.log("error in getTopupPlans", e);
        setshowLoader(false);
      });
  };
  const getUserCurrentPlan = () => {
    setshowLoader(true);
    call("POST", "getUserCurrentPlan", { userId })
      .then((result) => {
        console.log("success in getUserCurrentPlan", result);
        setplandata(formatDataForCurrentPlan(tablerows, result));
        setshowLoader(false);
      })
      .catch((e) => {
        console.log("error in getUserCurrentPlan", e);
        setshowLoader(false);
      });
  };

  const closeFn = () => {
    setShowModalTopup(false);
    setShowModalCurrentDetails(false);
  };
  const getWalletBalance = () => {
    call("POST", "getWalletBalance", { userId })
      .then((result) => {
        console.log("API result getWalletBalance", result);
        setshowLoader(false);
        if (result && result.length) {
          setWalletData(result[0]);
        }
      })
      .catch((e) => {
        console.log("Error while getWalletBalance", e);
        setshowLoader(false);
      });
  };
  const getPlanBalance = () => {
    setshowLoader(true);
    call("POST", "getPlanBalance", { userId })
      .then((result) => {
        console.log("success in getPlanBalance", result);
        let expiryDate = moment(result.createdAt)
          .add(
            result.plan_validity_unit?.toLowerCase(),
            result.plan_validity_value
          )
          .format("MM/DD/YYYY");
        setPlanBalance({
          ...result,
          expiryDate: expiryDate,
        });
        setshowLoader(false);
      })
      .catch((e) => {
        console.log("error in getPlanBalance", e);
        setshowLoader(false);
      });
  };
  function formatDataForTopup(data) {
    let tableData = [];
    let row = [];
    data.forEach((item, index) => {
      console.log(tableExpand, item);

      row[0] = item.topup_type;
      row[1] = `$ ${item.topup_value_usd}`;
      row[2] = item.topup_nos;
      row[3] = (
        <label
          className="font-size-14 text-color1 font-wt-600 cursor"
          onClick={() => {
            onPurchasePlan(
              item.id,
              "Topup" + " - " + item.topup_type,
              item.topup_value_usd
            );
          }}
        >
          Buy now
        </label>
      );
      tableData.push(row);
      row = [];
    });
    return tableData;
  }
  function formatDataForCurrentPlan(data, Obj) {
    let tableData = [];
    let row = [];
    data.forEach((item, index) => {
      console.log(tableExpand, item);

      row[0] = (
        <>
          <b>{item.label}</b>
        </>
      );
      row[1] = Obj[item.actualKey];
      row[2] = Obj[item.currentKey];
      tableData.push(row);
      row = [];
    });
    return tableData;
  }
  const onPurchasePlan = async (plan_id, plan_type, plan_amount_usd) => {
    try {
      let reqObj = {
        plan_id,
        plan_type,
        plan_amount_usd,
        userId,
        currency: "$",
      };
      const result = await call("POST", "createPaymentlink", reqObj);
      window.location = result;
    } catch (e) {
      console.log("error in createPaymentlink", e);
    }
  };
  const changePassword = (verifiedByOtp) => {
    setshowLoader(true);
    let reqObj = {
      currentPassword: data.currentPasswd,
      newPassword: data.newPasswd,
      confirmPassword: data.confNewPasswd,
      userId: userId,
      userEmail: userEmail,
      attr1: localStorage.getItem("attr1"),
      attr2: localStorage.getItem("attr2"),
      verifiedByOtp,
    };
    call("POST", "changepassword", reqObj)
      .then((res) => {
        console.log("sucess changepassword response", res);
        setshowLoader(false);
        toastDisplay("Password Updated", "success");
        setIsSetPasswordPopup(false);
      })
      .catch((e) => {
        console.log("Error", e);
        setshowLoader(false);
        toastDisplay("Failed to update password", "error");
      });
  };
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist();
    }

    setdata({ ...data, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" });
  };

  async function verifyCombinedOTP() {
    setshowLoader(true);
    let req1 = {
      userId,
      verifyEmail: true,
      otp: data.currentPasswd,
    };
    let req2 = {
      userId,
      verifyMob: true,
      otp: data.currentPasswd,
    };
    let mobileOTPRes, emailOTPRes;
    try {
      emailOTPRes = await call("POST", "sendVerifySameOtp", req1);
    } catch (err) {
      setshowLoader(false);
      toastDisplay(err, "error");
    }
    try {
      mobileOTPRes = await call("POST", "sendVerifySameOtp", req2);
    } catch (err) {
      setshowLoader(false);
      toastDisplay(err, "error");
    }
    setshowLoader(false);
    if (emailOTPRes || mobileOTPRes) {
      toastDisplay("OTP Verification Succesful.", "success");
      changePassword(true);
    } else {
      toastDisplay("OTP Verification Failed", "error");
    }
  }

  async function sendCombinedOTP() {
    setshowLoader(true);
    let reqObj = {
      userId,
      sendOnMob: true,
      sendOnEmail: true,
    };
    call("POST", "sendVerifySameOtp", reqObj)
      .then((result) => {
        setshowLoader(false);
        if (result) {
          toastDisplay(
            "OPT has been sent to your registered email & mobile number",
            "success"
          );
        }
      })
      .catch((err) => {
        setshowLoader(false);
        toastDisplay(err, "error");
      });
  }

  const [secrettoken, setsecretToken] = useState({});
  const [nextStep, setnextStep] = useState(false);
  const isFirstRender = useRef(true);
  const qrCodeContainerRef = useRef(null);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    call("POST", "gettwofactorAuthenticationState", { email_id: userEmail })
      .then((res) => {
        // Check if the response is 1 (true) or not
        const twofactorstate = res === 1 ? true : false;
        setboolauthentication(twofactorstate);

      })
      .catch((error) => {
        console.error("Error fetching two-factor authentication state:", error);
        // Handle the error appropriately, e.g., display an error message to the user.
      });
  }, [userEmail]);

  const setAuthenticationStatus = (status) => {
    console.log(status, "status");
    setboolauthentication(status);
    setshowauthModel(status);

    call("POST", "setTwofactorAuthentication", {
      email_id: userEmail,
      stateauth: boolauthentication,
    });
  };

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //     return;
  //   }

  //   if (boolauthentication) {
  //     call("POST", "enable2fa", { email_id: userEmail }).then((res) => {
  //       setsecretToken(res || {}); 
  //       toastDisplay("Two Factor Authentication is enabled", "success");
  //     });
  //   }
  // }, [boolauthentication, userEmail]); 

  useEffect(() => {
    // Ensure secretToken is not null or undefined before updating the QR code
    if (secrettoken && qrCodeContainerRef.current) {
      const qrCodeImage = document.createElement("img");
      qrCodeImage.src = secrettoken.qrcode || ""; // Ensure accessing qrcode property safely

      // Clear previous content
      qrCodeContainerRef.current.innerHTML = "";
      qrCodeContainerRef.current.appendChild(qrCodeImage);
    }
  }, [secrettoken, qrCodeContainerRef]);

  console.log(boolauthentication, "tokenqr", secrettoken, "token");

  const verifyOTP = () => {
    if (otp.trim() === "") {
      return;
    }

    // Call the API to verify OTP
    call("POST", "verify2fa", { userToken: otp, email_id: userEmail })
      .then((res) => {
        setsecretToken(res);

        toastDisplay("Two Factor Authentication is enabled", "success");

        console.log(res, "hello resss");
      })
      .catch((error) => {
        console.error("Error during 2FA verification:", error);
        // Handle the error appropriately, e.g., display an error message to the user.
        toastDisplay(
          "An error occurred during two-factor authentication verification",
          "error"
        );
      });
  };

  const getOTP = () => {
    setnextStep(true);
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}

      {showmodalTopup && (
        <FinanceInvoiceModal
          limitinvoice={showmodalTopup}
          setLimitinvoice={setShowModalTopup}
          closeSuccess={closeFn}
        >
          <p className="text-center font-size-16 font-wt-600">Top - Up</p>
          <div className="d-flex justify-content-center">
            <div className="col-10 px-0">
              <CustomizedTable
                disableAction={true}
                columns={[
                  { name: "Top-up", filter: false },
                  { name: "Price", filter: false },
                  { name: "Nos", filter: false },
                  { name: "Action", filter: false },
                ]}
                data={topupdata}
              />
            </div>
          </div>
        </FinanceInvoiceModal>
      )}
      {showmodalCurrentDetails && (
        <FinanceInvoiceModal
          limitinvoice={showmodalCurrentDetails}
          setLimitinvoice={setShowModalCurrentDetails}
          closeSuccess={closeFn}
        >
          <p className="text-center font-size-16 font-wt-600">
            Current plan details
          </p>
          <div className="ml-5">
            <p className="leftText font-size-14 font-wt-600 text-color1">
              {planBalance.plan_name}
              <label className="leftText font-size-14 font-wt-500 text-color-0C0C0C">
                {" "}
                (valid till {planBalance.expiryDate}){" "}
              </label>
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-10 px-0">
              <CustomizedTable
                disableAction={true}
                columns={[
                  {
                    name: "Plan Includes",
                    filter: false,
                    columnStyles: "text-color1 font-wt-600 font-size-14",
                  },
                  { name: "Plan", filter: false },
                  { name: "Left", filter: false },
                ]}
                data={plandata}
              />
            </div>
          </div>
          {(planBalance.plan_name === "Pro" ||
            planBalance.plan_name === "Pro Plus") && (
              <>
                <div className="pt-2 ml-5">
                  <p className="leftText font-size-14 font-wt-500">
                    Other Includes:
                  </p>
                </div>
                <div className="d-flex justify-content-start pt-0">
                  <div className="col-6 ml-3">
                    <ul>
                      {otherIncludes.map((item) => {
                        return (
                          <li className="leftText font-size-14 font-wt-500">
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
        </FinanceInvoiceModal>
      )}
      {isSetPasswordPopup && (
        <FinanceInvoiceModal
          headerTitle={""}
          limitinvoice={isSetPasswordPopup}
          setLimitinvoice={() => setIsSetPasswordPopup(false)}
          closeSuccess={() => {
            setIsSetPasswordPopup(false);
          }}
        >
          <div className="d-flex justify-content-center flex-column">
            <label className="w-100 text-center mb-4 font-size-14 font-wt-600 text-dark">
              Change Password
            </label>
            <div className="col-md-12 mb-0">
              <NewInput
                isAstrix={true}
                type={"password"}
                label={resetWithOtp ? "Enter OTP" : "Enter current password"}
                name={"currentPasswd"}
                value={data.currentPasswd}
                error=""
                onChange={handleChange}
              />
            </div>
            <label
              onClick={() => {
                toggleResetWithOtp(!resetWithOtp);
                if (!resetWithOtp) {
                  sendCombinedOTP();
                }
              }}
              className="font-size-12 font-wt-600 cursor text-color1 mx-4 mb-3 text-right"
            >
              {resetWithOtp ? `Reset with Current Password` : `Reset with OTP`}
            </label>
            <div className="col-md-12 mb-2">
              <NewInput
                isAstrix={true}
                type={"password"}
                label={"Enter new password"}
                name={"newPasswd"}
                value={data.newPasswd}
                error=""
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12 mb-2">
              <NewInput
                isAstrix={true}
                type={"password"}
                label={"Confirm new password"}
                name={"confNewPasswd"}
                value={data.confNewPasswd}
                error=""
                onChange={handleChange}
              />
            </div>
            <div className="mt-2 mx-3 text-center ">
              <button
                type="button"
                className={`new-btn w-60  py-2 px-2 text-white cursor`}
                onClick={() => {
                  if (resetWithOtp) {
                    verifyCombinedOTP();
                  } else {
                    changePassword();
                  }
                }}
              >
                Save
              </button>
              <br />
              {/* <p onClick={() => setLimitinvoice(true)} className='forgetpassword cursor mt-3'>Forget Password?</p> */}
            </div>
          </div>
        </FinanceInvoiceModal>
      )}

      {showauthModal && (
        <FinanceInvoiceModal
          headerTitle={""}
          limitinvoice={showauthModal}
          setLimitinvoice={() => setshowauthModel(false)}
          closeSuccess={() => {
            setshowauthModel(false);
            setnextStep(false);
          }}
        >
          {boolauthentication && nextStep !== true && (
            <div className="d-flex justify-content-center flex-column text-break">
              <label className="w-100 text-center mb-4 font-size-14 font-wt-600 text-dark">
                Two Factor Authentication
              </label>

              <div className="col-md-12 mb-2">
                <div>
                  Scan this QRCode on your google authenticator app to set up
                  google authentication
                </div>
                <div
                  style={{ marginLeft: "8rem" }}
                  ref={qrCodeContainerRef}
                ></div>
              </div>
              <div className="col-md-12 mb-2">
                <div>
                  You can also enter the given text manually in google
                  authenticator app
                </div>
                <div>{secrettoken.secretKey}</div>
              </div>
              <div className="mt-2 mx-3 text-center ">
                <button
                  type="button"
                  className={`new-btn w-60  py-2 px-2 text-white cursor`}
                  onClick={getOTP}
                >
                  Next
                </button>
                <br />
              </div>
            </div>
          )}
          {boolauthentication && nextStep && (
            <div className="d-flex justify-content-center flex-column">
              <label className="w-100 text-center mb-4 font-size-14 font-wt-600 text-dark">
                Verfication
              </label>

              <div className="col-md-12 mb-2">
                <div>Enter OTP shown on goggle authenticator app</div>
                <div className="col-md-12 mb-0" style={{ marginTop: "2rem" }}>
                  <NewInput
                    isAstrix={true}
                    type={"token"}
                    label={"Enter token key"}
                    name={"token"}
                    value={otp}
                    error=""
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ marginTop: "2rem" }}
                  />
                </div>
              </div>

              <div className="mt-2 mx-3 text-center ">
                <button
                  type="button"
                  className={`new-btn w-60  py-2 px-2 text-white cursor`}
                  style={{ marginTop: "2rem" }}
                  onClick={verifyOTP}
                >
                  Verify
                </button>
                <br />
              </div>
            </div>
          )}
        </FinanceInvoiceModal>
      )}

      <div className="container-fluid">
        <div className="row">
          <SideBarV2
            state="settings"
            userTokenDetails={userTokenDetails}
            isEditable={isNavbarEditable}
            setisEditable={setIsNavbarEditable}
          />
          <main
            role="main"
            className={
              "ml-sm-auto col-lg-10 " +
              (navToggleState.status ? " expanded-right" : "")
            }
            id="app-main-div"
            style={{ height: "85vh" }}
          >
            <HeaderV2 title={"Settings"} userTokenDetails={userTokenDetails} />

            <div
              className="col-md-12 h-100 mx-auto mt-0 "
              style={isNavbarEditable ? { zIndex: -1 } : {}}
            >
              <div className="h-100 w-100 newCardStyle d-flex flex-row gap-4 p-4">
                <div className="w-70 newCardStyle p-3">
                  <div className="topupDiv cursor border-bottom d-flex flex-row justify-content-between align-items-center p-3">
                    <label className="mb-0 font-size-14">Theme</label>
                    <SelectForTable
                      selectData={[{ name: "Default" }]}
                      name={`theme`}
                      optionLabel={"name"}
                      optionValue={"name"}
                      value={data.theme}
                      onChange={() => { }}
                    />
                  </div>
                  <div className="topupDiv border-bottom cursor d-flex flex-row justify-content-between align-items-center p-3">
                    <label className="mb-0 font-size-14">Language</label>
                    <SelectForTable
                      selectData={[{ name: "English" }]}
                      name={`language`}
                      optionLabel={"name"}
                      optionValue={"name"}
                      value={data.language}
                      onChange={() => { }}
                    />
                  </div>
                  <div
                    className="topupDiv cursor border-bottom d-flex flex-row justify-content-between align-items-center p-3"
                    onClick={() => setIsNavbarEditable(!isNavbarEditable)}
                  >
                    <label className="mb-0 cursor">
                      Customize Navigation Bar
                    </label>
                    <img src="assets/images/ArrowBack.png" />
                  </div>
                  <div
                    className="topupDiv cursor border-bottom d-flex flex-row justify-content-between align-items-center p-3"
                    onClick={() => setIsSetPasswordPopup(true)}
                  >
                    <label className="mb-0 cursor">Change Password</label>
                    <img src="assets/images/ArrowBack.png" />
                  </div>

                  {/* <label className="mb-0 cursor">
                      Two Factor Authentication
                    </label> */}
                  {/* <div>
                      <div onClick={() => setAuthenticationStatus(true)}>
                        <input
                          type="radio"
                          id="authenticationToggleYes"
                          name="authenticationType"
                          value="true"
                          checked={boolauthentication === true}
                        />
                        <label
                          htmlFor="authenticationToggleYes"
                          style={{ paddingLeft: "0.5rem" }}
                        >
                          Yes
                        </label>
                      </div>
                      <div onClick={() => setAuthenticationStatus(false)}>
                        <input
                          type="radio"
                          id="authenticationToggleNo"
                          name="authenticationType"
                          value="false"
                          checked={boolauthentication === false}
                        />
                        <label
                          htmlFor="authenticationToggleNo"
                          style={{ paddingLeft: "0.5rem" }}
                        >
                          No
                        </label>
                      </div>
                    </div> */}

                </div>
                <div className="w-30 newCardStyle">
                  <div className="p-3 d-flex justify-content-center">
                    <BalanceCard data={planBalance} coindata={walletData} />
                  </div>
                  <div className="p-3 d-flex justify-content-center">
                    <div
                      className="card Month borderRadius px-3 justify-content-evenly"
                      style={{
                        width: "240px",
                        height: "95px",
                      }}
                    >
                      <div
                        className="topupDiv cursor border-bottom d-flex flex-row justify-content-between align-items-center"
                        onClick={() => setShowModalCurrentDetails(true)}
                      >
                        <label className="mb-0">Current Plan Details</label>
                        <img src="assets/images/ArrowBack.png" />
                      </div>
                      <div
                        className="cursor d-flex flex-row justify-content-between align-items-center"
                        style={{ padding: 2 }}
                        onClick={() => setShowModalTopup(true)}
                      >
                        <label className="mb-0">Top-up</label>
                        <img src="assets/images/ArrowBack.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    navToggleState: state.navToggleState,
  };
};

export default connect(mapStateToProps, null)(Settings);
