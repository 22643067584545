import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getTotalCharges, getTotalOtherCharges, LCPurposeObject, LCPurposeObjectV2, LCTypesObject, most_used_currencies } from "../../../../utils/myFunctions";
import call from '../../../../service';
import config from "../../../../config.json";
import { FileInput } from "../../../../utils/FileInput";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import SubmitModal from "../../../InvoiceDiscounting/components/submitModal";
import { ToastContainer } from "react-toastify";
import TooltipModal from "../../../InvoiceDiscounting/components/Tooltipmodal";
import quotes from "../quotes";
import { jssPreset } from "@material-ui/core";
import ChatBoxPopUp from "../../../chatRoom/components/ChatBoxPopUp";
import { useHistory } from "react-router";
import { NewInput, NewTextArea } from "../../../../utils/newInput";
import AmmendLC from "../../applyforFinance/components/ammendLC";
import AmendmentDetails from "../../../InvoiceDiscounting/applyForFinance/components/amendmentDetails";
import { useRef } from "react";
import ChatBoxPopUp2 from "../../../chatRoom/components/ChatBoxPopUp2";
import NewTablev2 from "../../../../utils/newTablev2";
import { quoteViewColumns, quoteViewColumns2 } from "../../../Dashboard/TableJson";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";




const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]


const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  // { "name": "LC tenor", val:  },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]


const LcSeequotes = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const quoteId = queryParams.get("id")
  const onlyView = queryParams.get("view")
  const amendmentId = queryParams.get("amendmentId")
  const reqLetterRequired = queryParams.get("reqLetterRequired") ? true : false

  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"))
  let defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()

  const addBuyerTabs = isAdmin ? [
    { name: "Application" },
    { name: "Quotes" },
    { name: "Documents" },
    { name: "Amendment Request" },
    { name: "Amendment LC" },
    // { name: "Original LC" },
  ] : [
    { name: "Buyer details" },
    { name: "Select financer" },
    { name: "Documents" },
    { name: "Review" }
  ]

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(isAdmin ? (defaultTabForAdmin / 1) : (amendmentId ? 0 : 1))
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [lcFinanciers, setLCFinanciers] = useState([])
  const [showOtherCharges, toggleOtherCharges] = useState(false)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])

  const [lcMoreDetails, setLCMoreDetails] = useState(false);
  const [lcCodes, setLCCodes] = useState({})



  const [limitinvoice, setLimitinvoice] = useState(false);
  const [multInvPo, setMultInvPo] = useState([])

  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = localStorage.getItem("manualUserId") ? localStorage.getItem("manualUserId") : (userTokenDetails.user_id ? userTokenDetails.user_id : null)
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null
  // const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [slice, setSlice] = useState(1);

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const Next = () => {
    setFirstindex(firstindex + 4);
    setLastindex(lastindex + 4);
    setSlice(slice + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 4);
    setLastindex(lastindex - 4);
    setSlice(slice - 1);
  };


  useEffect(() => {
    // master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    call('POST', 'getFinanciersForLC').then((result) => {
      setLCFinanciers(result)
    }).catch((e) => {
      console.log('error in getFinanciersForLC', e);
    })
    call('POST', 'getRecommendedFinanciers').then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    //master data end
    //Get quote data start
    setshowLoader(true)
    call('POST', 'getLCQuoteApplicationById', { quoteId }).then(async (result) => {
      console.log('running getLCQuoteApplicationById api-->', result);
      if (result.length) {
        let reqLetterDocument = {}
        let additionalDetails = {}
        if (onlyView) {
          reqLetterDocument = await getDocDetails(result[0].reqLetterOfConfirmation)
          additionalDetails = {
            selectedFinancier: result[0].selectedFinancier,
            selectedFinancierName: JSON.parse(result[0]["selectedQuote"])["lender_name"],
            sameFinancierForDiscounting: result[0].sameBankForLCDiscounting ? true : false
          }
        }
        let draftLCDocument = await getDocDetails(result[0].draftLC)

        // load all limit docs start
        let tempLimitDocsDetails = {}
        let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: result[0].id, fetchLCDocs: true })
        let totalInvoiceDetails = limitDocs.maxIndx
        let maxInvPoDocs = limitDocs.maxSubIndx
        for (let index = 0; index < limitDocs.data.length; index++) {
          const element = limitDocs.data[index];
          tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
          if (element.attributes) {
            tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
          }
        }
        setshowLoader(false)
        setMultInvPo([...new Array(totalInvoiceDetails).fill({ invoices: new Array(maxInvPoDocs).fill(null), purchase_orders: new Array(maxInvPoDocs).fill(null) })])
        // load all limit docs end

        let financierQuotes = result[0]["financierQuotes"] ? JSON.parse(result[0]["financierQuotes"]) : []

        for (let index = 0; index < financierQuotes.length; index++) {
          if (financierQuotes[index]["lender_name"].toLowerCase().includes("stenn")) {
            financierQuotes[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
          }
          else if (financierQuotes[index]["lender_name"].toLowerCase().includes("modifi")) {
            financierQuotes[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
          }
        }

        setData({
          ...result[0], buyerCurrency: result[0].buyerCurrency ? result[0].buyerCurrency.split(":")[1] : "", draftLCDocument, reqLetterDocument, ...additionalDetails,
          limitRequired: result[0]["requiredLimit"], lcType: LCTypesObject[result[0]["lcType"]],
          limitRequiredCurrency: result[0]["requiredLimitCurrency"], financierQuotes, tcAccepted: onlyView ? true : false,
          ...tempLimitDocsDetails
        })
        // setAddMoreDoc(moreDocArr)
      }
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      setshowLoader(false)
    });
    //Get quote data end
  }, [])

  // useEffect(() => {
  //   if (buyer && dbData.length) {
  //     let event = {
  //       target: {
  //         name: "selectedBuyer",
  //         value: buyer
  //       }
  //     }
  //     handleChange(event)
  //   }
  // }, [buyer, dbData])


  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData[0]
      let buyers_credit = JSON.parse(selectedBuyer.buyers_credit)

      for (let index = 0; index < buyers_credit.length; index++) {
        const element = buyers_credit[index];
        if (element.lender_name.toLowerCase().includes("stenn")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (element.lender_name.toLowerCase().includes("modifi")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }

      let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
      let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])
      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        invoiceDocument, poDocument, buyers_credit
      })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }



  const closeSuccess = () => {
    setLimitinvoice(false);
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  const closeFn = () => {
    setModal(true);
  }

  async function handleValidation(tabIndex) {

    if (tab == 1 && reqLetterRequired) {
      setTab(tab + 1)
      return askForDiscountingBank()
    }

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (tab === 2) {
      if (reqLetterRequired) {
        if (!(data.reqLetterDocument && data.reqLetterDocument.filebase64)) {
          err["reqLetterDocument"] = "Mandatory Field"
        }
        if (data.reqLetterDocument && !data.reqLetterDocument.signatureId) {
          err["reqLetterDocument"] = "Signature is required"
        }
      }
    }

    if (!isAdmin && tab === 3) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("onlyView", onlyView)
      formData.append("lcNo", data.lcNo)
      formData.append("buyerId", data.buyerId)
      // if (!data.invoiceDocument.fromDb) {
      formData.append("invoiceDocument", data.invoiceDocument)
      // }
      // if (!data.poDocument.fromDb) {
      formData.append("poDocument", data.poDocument)
      // }
      formData.append("buyerOtherDocs", data.buyerOtherDocs)
      formData.append("reqLetterDocument", data.reqLetterDocument)
      formData.append("reqLetterDocSignId", data.reqLetterDocument.signatureId)
      formData.append('lcPurpose', data.lcPurpose)

      if (reqLetterRequired) {
        formData.append("reqLetterRequired", reqLetterRequired)
      }

      formData.append("buyersDocId", data.buyersDocId)
      formData.append("userId", userId)
      formData.append("selectedFinancier", data.selectedFinancier)
      formData.append("selectedQuote", JSON.stringify(data.selectedQuote))
      formData.append("applicationId", data.id)
      formData.append("sameFinancierForDiscounting", data.sameFinancierForDiscounting)
      if (data.invoiceDocument?.signatureId) {
        formData.append("invoiceDocSignId", data.invoiceDocument.signatureId)
      }
      if (data.poDocument?.signatureId) {
        formData.append("poDocSignId", data.poDocument.signatureId)
      }
      addMoreDoc.forEach((k, index) => {
        if (data["otherDoc" + index]) {
          formData.append("otherDoc" + index, data["otherDoc" + index])
          if (data["otherDoc" + index]["signatureId"]) {
            formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
          }
        }
      })
      call('POST', 'exporterSubmitLCQuote', formData).then((result) => {
        // console.log('running exporterSubmitLCQuote api-->', result);
        toastDisplay("Quotation updated successfully", "success", () => {
          window.location = "/LcQuote"
        })
        setshowLoader(false)
      }).catch((e) => {
        // console.log('error in exporterSubmitLCQuote', e);
        toastDisplay("Something went wrong", "error")
        setshowLoader(false)
      });
      return null
    }
    if (!Object.keys(err).length) {
      setTab(tab + 1)
    }
    setErrors(err)
  }

  async function askForDiscountingBank() {
    // if (data.lcPurpose === "lc_confirmation") {
    //   setLimitinvoice(true)
    // }
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', data.id)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="/LcSeequotes" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`${LCPurposeObject[data.lcPurpose]} quote`}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (isAdmin) {
                            setTab(index)
                          }
                          else {
                            handleValidation(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => {
                      if (isAdmin) {
                        setTab(index)
                      }
                      else {
                        handleValidation(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      {data.buyerId ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16 p-0'><u>Buyer Details</u>
                            {isAdmin ? null : (
                              <img
                                onClick={() => { window.location = `/buyerManagement?editBuyer=${data.buyerId}` }}
                                className="ms-3 cursor"
                                src={"assets/images/edit.png"} alt="edit" />
                            )}
                          </label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                            <div className="col-md-6 pe-5">
                              <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                {data["limitRequiredCurrency"] + " " + data["limitRequired"]}
                              </span> </p>
                            </div>
                          </div>
                        </div >
                      ) : null}


                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''}`}>
                        {lcMoreDetails ? null : (
                          <>
                            <label className='font-wt-500 font-size-16'><u>LC Details</u>
                              {/* <img className="ms-3"
                            onClick={() => { window.location = `/LcDiscounting?buyer=${data.buyerId}&purpose=${data.lcPurpose}&id=${data.id}&view=true` }}
                            src={"assets/images/edit.png"} alt="edit" /> */}
                            </label>
                            <div className='row'>
                              {reviewForm3.map((item) => {
                                return (
                                  <div className="col-md-6 pe-5">
                                    <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        )}

                        {lcMoreDetails ? (
                          <>
                            <img className="mb-2 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} />
                            <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                            <div className='row'>
                              {reviewForm3.map((item) => {
                                return (
                                  <div className="col-md-4 pe-5">
                                    <NewInput type={"text"} isDisabled={true} label={item.name}
                                      value={data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                            <div className='row'>
                              <label className='font-wt-500 font-size-16 mb-4'><u>More LC Details</u></label>
                              {lcCodes && Object.keys(lcCodes).map((item) => {
                                return (
                                  <div className="col-md-4 pe-5">
                                    <NewTextArea isDisabled={true} label={item + "-" + lcCodes[item]}
                                      value={data?.["ocrFields"]?.[item] || " "}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        ) : null}

                        {lcMoreDetails ? null : (
                          <p className="mt-3 text-blue cursor" onClick={() => setLCMoreDetails(true)}>View more LC details
                            <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /> </p>
                        )}
                      </div>

                      <div className='w-100 mt-5'>
                        {!isAdmin ? <>
                          <label className='font-wt-500 font-size-16'><u>Documents</u>
                          </label>
                          <div className='row mt-2'>
                            {!onlyView ? null : (
                              <div className="col-md-4">
                                <label className="font-size-14">Request Letter of Confirmation</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4">
                              <label className="font-size-14">Draft LC</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"draftLCDocument"} value={data.draftLCDocument} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                            {data?.invoiceDocument?.name ? (
                              <div className="col-md-4">
                                <label className="font-size-14">Invoice</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"invoiceDocument"} value={data.invoiceDocument} isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.poDocument?.name ? (
                              <div className="col-md-4">
                                <label className="font-size-14">Purchase Order</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"poDocument"} value={data.poDocument} isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                              if (data["otherDoc" + index]) {
                                return (
                                  <div className="col-md-4">
                                    <label className="font-size-14">Add More</label>
                                    <div className="row form-group">
                                      <div className="col-md-12">
                                        <FileInput value={data["otherDoc" + index]} isEditable={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            }) : null}
                          </div>
                        </> : null}

                        {onlyView ? null : (
                          <div className='w-100 mt-3'>
                            <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                            <div className="d-flex gap-4 my-3">
                              {lcFinanciers.map((key) => {
                                return (
                                  <label key={key.name}
                                    className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                    {key.name}
                                  </label>
                                );
                              })}
                            </div>
                          </div>
                        )}

                        <div className='w-100 mt-4'>
                          <label className='font-wt-500 font-size-16'><u>Service Selected</u></label>
                          <div className="d-flex gap-4 my-3">
                            <label
                              className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                              {LCPurposeObjectV2[data["lcPurpose"]]}
                            </label>
                            {data["sameBankForLCDiscounting"] ? <label
                              className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                              {"Discounting"}
                            </label> : null}
                          </div>
                        </div>
                      </div>

                    </div >
                  </div >
                ) : null}
                {
                  tab === 1 ? (
                    <div className='px-5 pt-4 pb-4'>

                      <div
                        className="cursor"
                        onClick={() => {
                          setQuoteView(quoteView === "card" ? "table" : 'card')
                        }}
                        style={{ position: 'absolute', right: '5.5rem' }}
                      >
                        <img src={quoteView != "card" ? "assets/images/card_icon.svg" : "assets/images/table_icon.svg"} />
                      </div>
                      {/* <div
                        className="cursor"
                        onClick={() => {
                          // toggleSeeRateCalculator(true)
                        }}
                        style={{ position: 'absolute', right: '2rem' }}
                      >
                        <img src={"assets/images/calculator_updated_icon.svg"} />
                      </div> */}

                      <div className="text-center lineheight19">
                        <label className='font-wt-500 font-size-16 mb-0 letter-spacing05'>Select financer</label><br />
                        <small className="selectfinacne-subheading">Quotes for {LCPurposeObject[data.lcPurpose]}</small>
                      </div>
                      {quoteView === "card" ? (
                        <div id={"scrollContainerId"} style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className="row align-items-center d-flex w-100 my-4">
                          {data.financierQuotes ? data.financierQuotes.map((key, index) => {
                            return (
                              <div ref={(el) => quoteCardRef.current[index] = el} className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                                <div className={`text-center card py-4 pb-0 px-3
                              ${key.status === "denied" ? "limitdeniedcontract" : "selectfinancercardrec"} `}>
                                  {!reqLetterRequired && recommendedFinanciers.includes(key.lender_id / 1) && key.status != "denied" ? (
                                    <div style={{ right: '-1.4rem' }} className="position-absolute" >
                                      <img src="assets/images/recommended_label_updated.svg" />
                                    </div>
                                  ) : null}
                                  {reqLetterRequired && data.selectedFinancier / 1 == key.lender_id / 1 ? (
                                    <div class="containercustom " data-ribbon="Selected"></div>
                                  ) : null}
                                  <>
                                    <p style={{ marginTop: '2.5rem' }} className="cursor mb-1"><img className="mr-2"
                                      src={key.logo} alt="" />
                                      <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                                      <img
                                        onClick={() => { setTooltip(true); setData({ ...data, showRemark: key.assignRemark, showLenderName: key.lender_name }) }}
                                        src={"assets/images/warning.png"} alt="" />
                                      {/* <a href="/docsRequested" >
                                      <img src={"assets/images/warning.png"} alt="" />
                                    </a> */}
                                    </p>
                                  </>

                                  {key.status != "denied" ? (
                                    <>
                                      {/* <p className="font-size-12 mb-1 font-wt-600 text-color1">
                                      {`Discounting Percentage : ${key.discountingPercentage} % of invoice`}</p> */}
                                      <p className="font-size-12 mb-0">
                                        {`Offer Validity : ${key.offerValidity}`}</p>
                                      <p style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                                    </>
                                  ) : null}

                                  {key.status === "denied" ? (
                                    <>
                                      <div className="limitDeniedCard">
                                        <div className="mb-2">
                                          <img src={"assets/images/block.png"} height={25} width={25} />
                                        </div>
                                        <div className="font-size-14 font-wt-600">
                                          Limit Denied
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {key.confirmationFees ?
                                        <div className="mt-2">
                                          <p className="mb-0  font-size-13 font-wt-500">Confirmation Fees</p>
                                          {key.giveConfirmationFees ?
                                            <>
                                              <span className="mb-0 text-secondary  font-size-13 font-wt-500" style={{ fontSize: "12px" }}>{"(" + key.confirmationFeesValidity?.toUpperCase().split("_").join(" ") + ")"}</span>
                                              <p className="font-size-16">{key.giveConfirmationFees ? key.confirmationFees ? (key.confirmationFees + " %") : "" : ""}</p>
                                            </> : null}
                                        </div> : null}
                                      {key.discountingRate ?
                                        <div className="mt-2">
                                          <p className="mb-0  font-size-13 font-wt-500">Discounting Rate</p>
                                          {key.giveDiscountingRate ?
                                            <>
                                              <span className="mb-0 text-secondary  font-size-13 font-wt-500" style={{ fontSize: "12px" }}>{"(Inclusive TERM for " + key.termSofr + ")"}</span>
                                              <p className="font-size-16">{key.giveDiscountingRate ? key.discountingRate ? (key.discountingRate + " %") : "" : ""}</p>
                                            </> : null}
                                        </div> : null}
                                      {key.setupFees ?
                                        <div className="mt-0 lineheight19">
                                          <p className="mb-0 font-size-13 font-wt-500">One time set-up fees</p>
                                          <p className="font-size-16">{key.setupFees + " " + key.setupFeesCurrency}</p>
                                        </div> : null}
                                      {key.handlingCharges ?
                                        <div className="mt-0 lineheight19">
                                          <p className="mb-0 font-wt-500 font-size-13">Handling Charges</p>
                                          <p className="font-size-16">{key.handlingCharges + " " + key.handlingChargeCurrency}</p>
                                        </div> : null}
                                      {key.courierCharges ?
                                        <div className="mt-0 lineheight19">
                                          <p className="mb-0 font-wt-500 font-size-13">Courier Charges</p>
                                          <p className="font-size-16">{key.courierCharges + " " + key.CourierChargeCurrency}</p>
                                        </div> : null}
                                      {getTotalOtherCharges(key.otherCharges).toFixed(2) / 1 > 0 ? <div className="position-relative lineheight19">
                                        <p
                                          className="mb-0 font-wt-500 font-size-13">Other Charges
                                        </p>
                                        <p className="font-size-16 totalChargesLabel">{getTotalOtherCharges(key.otherCharges).toFixed(2) + " " + key.setupFeesCurrency}</p>
                                        <div className="otherChargePopup">
                                          {key.otherCharges.map((j) => (
                                            <>
                                              <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                                <label className="text-color-label">{j.name}</label>
                                                <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      </div> : null}
                                      <div className="mt-2">
                                        <p className="mb-0 text-secondary font-size-13 font-wt-500">Total Charges</p>
                                        <span className="mb-0 text-secondary font-size-13 font-wt-500" style={{ fontSize: "12px" }}>($ 50,0000/30 days)</span>
                                        <p className="font-size-16 font-wt-600 text-color1">{getTotalCharges(key).toFixed(2) + " " + key.setupFeesCurrency}</p>
                                      </div>
                                    </>
                                  )}
                                  {isAdmin ? null : (
                                    <>
                                      {reqLetterRequired ? null : (
                                        <div>
                                          {key.status != "denied" ? (
                                            <button
                                              onClick={() => {
                                                setData({ ...data, selectedFinancier: key.lender_id, selectedFinancierName: key.lender_name, selectedQuote: key }); setTab(tab + 1); askForDiscountingBank()
                                              }}
                                              className={`mx-2 new-btn w-75 py-2 px-2 text-white`}>Select Quote</button>
                                          ) : null}
                                        </div>
                                      )}
                                    </>
                                  )}
                                  <p className="mt-1"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-13 font-wt-500 color434343 letter-spacing05 ms-2 cursor"
                                    onClick={async () => {
                                      let reqObj = {
                                        senderId: userId,
                                        receiverId: key.lender_id / 1,
                                        textMessage: 'Hii',
                                        chat_room_name: "CHAT" + new Date().getTime(),
                                        includeAdmins: true,
                                        lcApplicationId: data.id,
                                        receiverParties: key.lender_id / 1,
                                        dontSendInitialMsg: true
                                      }
                                      setshowLoader(true)
                                      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                      setshowLoader(false)
                                      getChatDetails({
                                        chat_room_id: apiResp.id,
                                        loggedInUser: userId
                                      })
                                      setSelectedChat({
                                        chatRoomId: apiResp.id,
                                        receiverName: `${key.lender_name} - ${apiResp.id}`,
                                        lcApplicationId: data.id,
                                        parties: apiResp.parties,
                                        userId: userId,
                                        isChatOpen: true,
                                        receiverId: key.lender_id / 1
                                      })
                                    }}
                                  >Chat with financer</span></p>
                                </div>
                              </div>
                            );
                          }) : null}
                        </div>
                      ) :
                        <div className="my-4" >
                          <NewTablev2 columns={quoteViewColumns2} >
                            {data.financierQuotes?.length ? data.financierQuotes.map((key, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div>
                                      <img className="mr-2"
                                        src={key.logo} alt="" />
                                      <span className="font-size-14 font-wt-600 text-dark mx-1">{key.lender_name}</span>
                                      {!reqLetterRequired && recommendedFinanciers.includes(key.lender_id / 1) && key.status != "denied" ? (
                                        <img src="assets/images/recommended_circle_icon.svg" />
                                      ) : null
                                      }
                                    </div></td>
                                  <td>{`${key.confirmationFees} % ` + "(" + key.confirmationFeesValidity?.toUpperCase().split("_").join(" ") + ")"}</td>
                                  <td>{`${key.discountingRate} % ` + "(" + key.termSofr + ")"}</td>
                                  <td>{key.setupFees + " " + key.setupFeesCurrency}</td>
                                  <td>{key.handlingCharges + " " + key.handlingChargeCurrency}</td>
                                  <td>{key.courierCharges + " " + key.CourierChargeCurrency}</td>
                                  <td>{getTotalOtherCharges(key.otherCharges).toFixed(2) + " " + key.setupFeesCurrency}</td>
                                  <td>{getTotalCharges(key).toFixed(2) + " " + key.setupFeesCurrency}</td>
                                  <td>{key.offerValidity}</td>
                                  <td><img
                                    onClick={async () => {
                                      let reqObj = {
                                        senderId: userId,
                                        receiverId: key.lender_id / 1,
                                        textMessage: 'Hii',
                                        chat_room_name: "CHAT" + new Date().getTime(),
                                        includeAdmins: true,
                                        lcApplicationId: data.id,
                                        receiverParties: key.lender_id / 1,
                                        dontSendInitialMsg: true
                                      }
                                      setshowLoader(true)
                                      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                      setshowLoader(false)
                                      getChatDetails({
                                        chat_room_id: apiResp.id,
                                        loggedInUser: userId
                                      })
                                      setSelectedChat({
                                        chatRoomId: apiResp.id,
                                        receiverName: `${key.lender_name} - ${apiResp.id}`,
                                        lcApplicationId: data.id,
                                        parties: apiResp.parties,
                                        userId: userId,
                                        isChatOpen: true,
                                        receiverId: key.lender_id / 1
                                      })
                                    }}
                                    className="cursor" src={"assets/images/supplier-images/chat.png"} /></td>
                                  <td>
                                    {key.status === "approved" && !reqLetterRequired ? (
                                      <button
                                        onClick={() => {
                                          setData({ ...data, selectedFinancier: key.lender_id, selectedFinancierName: key.lender_name, selectedQuote: key }); setTab(tab + 1); askForDiscountingBank()
                                        }}
                                        className={`mx-2 w-75 py-2 px-2 new-btn text-white`}>Select Quote</button>
                                    ) : null}
                                  </td>
                                </tr>
                              )
                            }) : null}
                          </NewTablev2>
                        </div>}


                      {data.financierQuotes?.length > 4 && quoteView != 'table' ? (
                        <div className="col-12 mx-auto text-end">
                          <img onClick={() => {
                            scrollLeft()
                          }}
                            className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                          <img onClick={() =>
                            scrollRight()
                          }
                            className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                        </div>
                      ) : null}

                      {selectedChat.isChatOpen &&
                        <div className="chatboxDivFixed">
                          <ChatBoxPopUp2
                            chatList={chatList}
                            user_avatar={selectedChat.logo}
                            userId={selectedChat.userId}
                            receiverName={selectedChat.receiverName}
                            parties={selectedChat.parties}
                            userTokenDetails={userTokenDetails}
                            onChatSend={(file, parties) => sendChatMsg(file, parties)}
                            message={message}
                            setTextMsg={setTextMsg}
                            reloadChatList={() => getChatDetails({
                              chat_room_id: selectedChat.chatRoomId,
                              loggedInUser: userId
                            })}
                            onPopupClose={() => {
                              setSelectedChat({
                                receiverName: '',
                                receiverId: '',
                                userId: userId,
                                isChatOpen: false,
                                logo: ''
                              })
                            }}
                          />
                        </div>
                      }

                    </div>
                  ) : null}
                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>

                    <div className='w-100 mt-2'>
                      {reqLetterRequired ? (
                        <div className="col-md-8 mt-3 mb-5 p-0">
                          <label className="font-size-14">Request Letter of Confirmation</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} error={errors.reqLetterDocument}
                                onChange={handleFile} isEditable={onlyView ? false : true}
                                onUploadCancel={() => setData({ ...data, "reqLetterDocument": null })}
                              />
                              {errors.reqLetterDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.reqLetterDocument}</b></div> : ''}
                            </div>
                            {data.reqLetterDocument && data.reqLetterDocument.name && !data.reqLetterDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.reqLetterDocument, docSignName: "reqLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.reqLetterDocument && data.reqLetterDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      <label className='font-wt-500 font-size-16'><u>Invoice & PO Details</u></label>
                      <div className='row mt-2'>
                        <div className="my-4">
                          <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                          ]}>
                            {multInvPo.map((i, j) => {
                              return <>
                                {i.invoices?.map((o, p) => {
                                  return (
                                    <tr>
                                      <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                      <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                      <td><div>
                                        <FileInput hideViewDowdBtns={false} isEditable={false}
                                          name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                          onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                        />
                                        {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                          <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                      </div></td>
                                    </tr>
                                  )
                                })}
                                {
                                  i.purchase_orders?.map((o, p) => {
                                    return (
                                      <tr>
                                        <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                        <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                        <td>
                                          <div>
                                            <FileInput hideViewDowdBtns={false}
                                              name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                              isEditable={false}
                                              onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                            />
                                            {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                              <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </>
                            })}
                          </NewTablev2>
                        </div>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        {data.draftLCDocument && data.draftLCDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument} handleValidation
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>}
                        {data.logisticDocument && data.logisticDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Bill of Lading/Airway Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument} handleValidation
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>}
                        {data.invReportDocument && data.invReportDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Investigation Report</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument} handleValidation
                                  isEditable={false}
                                />

                              </div>
                            </div>
                          </div>}
                        {data.grnDocument && data.grnDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Certificate of Origin</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument} handleValidation
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>}
                        {data.shippingBillDocument && data.shippingBillDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Shipping Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument} handleValidation
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>}
                        {data.packingListDocument && data.packingListDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Packing List</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument} handleValidation
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>}
                        {data.salesContractDocument && data.salesContractDocument.name &&
                          <div className="col-md-4">
                            <label className="font-size-14">Sales Contract</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument} handleValidation
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>}
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 3 && !isAdmin ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      {data.buyerId ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16 p-0'><u>Buyer Details</u>
                          </label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                            <div className="col-md-6 pe-5">
                              <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                {data["limitRequiredCurrency"] + " " + data["limitRequired"]}
                              </span> </p>
                            </div>
                          </div>
                        </div >
                      ) : null}
                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''}`}>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u> </label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                        </label>
                        <div className='row mt-2'>

                          <div className='w-100 mt-2'>
                            <label className='font-wt-500 font-size-16'><u>Invoice & PO Details</u></label>
                            <div className='row mt-2'>
                              <div className="my-4">
                                <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                                ]}>
                                  {multInvPo.map((i, j) => {
                                    return <>
                                      {i.invoices?.map((o, p) => {
                                        return (
                                          <tr>
                                            <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                            <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                            <td><div>
                                              <FileInput hideViewDowdBtns={false} isEditable={false}
                                                name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                                onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                              />
                                              {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                            </div></td>
                                          </tr>
                                        )
                                      })}
                                      {
                                        i.purchase_orders?.map((o, p) => {
                                          return (
                                            <tr>
                                              <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                              <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                              <td>
                                                <div>
                                                  <FileInput hideViewDowdBtns={false}
                                                    name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                                    isEditable={false}
                                                    onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                                  />
                                                  {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                    <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        })}
                                    </>
                                  })}
                                </NewTablev2>
                              </div>
                              <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                              {(reqLetterRequired || isAdmin) ? (
                                <div className="col-md-4">
                                  <label className="font-size-14">Request letter of confirmation</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {data.draftLCDocument && data.draftLCDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Draft LC</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument} handleValidation
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>}
                              {data.logisticDocument && data.logisticDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Bill of Lading/Airway Bill</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument} handleValidation
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>}
                              {data.invReportDocument && data.invReportDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Investigation Report</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument} handleValidation
                                        isEditable={false}
                                      />

                                    </div>
                                  </div>
                                </div>}
                              {data.grnDocument && data.grnDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Certificate of Origin</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument} handleValidation
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>}
                              {data.shippingBillDocument && data.shippingBillDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Shipping Bill</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument} handleValidation
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>}
                              {data.packingListDocument && data.packingListDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Packing List</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument} handleValidation
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>}
                              {data.salesContractDocument && data.salesContractDocument.name &&
                                <div className="col-md-4">
                                  <label className="font-size-14">Sales Contract</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument} handleValidation
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>}
                            </div>
                          </div>

                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>

                          <div className="d-flex">
                            <div className="my-3 col-2">
                              <label className="font11">{LCPurposeObject[data.lcPurpose]}</label>
                              <p
                                className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                {data.selectedFinancierName}
                              </p>
                            </div>

                            {data.lcPurpose === "lc_confirmation" && data.sameFinancierForDiscounting ? (
                              <div className="my-3 col-2">
                                <label className="font11">LC Discounting</label>
                                <p
                                  className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                  {data.selectedFinancierName}
                                </p>
                              </div>
                            ) : null}

                          </div>
                        </div>
                      </div>
                      <div className='mt-3'>
                        <img
                          onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                          className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                        <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                      </div>
                    </div >
                  </div >
                ) : null}

                {tab === 3 && isAdmin ? (
                  <>
                    <AmmendLC params={{
                      id: quoteId,
                      buyerEmail: data?.buyerEmail,
                      financierQuotes: data?.financierQuotes,
                      selectedFinancier: data?.selectedFinancier,
                      isAdmin: true
                    }} />
                  </>
                ) : null}

                {tab === 4 && isAdmin ? (
                  <>
                    <AmendmentDetails params={{
                      amendmentId: null,
                      quoteId,
                      isAdmin: true
                    }} />
                  </>
                ) : null}

                {/* {!isAdmin ? <>
                  {((tab != 1) || onlyView || reqLetterRequired) ? (
                    <div className="pb-5 px-5">
                      <button type="button"
                        disabled={(!data.tcAccepted && tab === 3)}
                        onClick={() => { handleValidation(undefined) }}
                        className={`new-btn w-20 py-2 px-2 text-white cursor ${(!data.tcAccepted && tab === 3) ? " disablesigncontract" : ""}`}>
                        {tab === 3 ? `${onlyView ? "Update" : "Submit"}` : "Continue"}
                      </button>
                    </div>

                  ) : null}
                </> : <div className="pb-5 px-5">
                  <button type="button"
                    disabled={(!data.tcAccepted && tab === 3)}
                    onClick={() => { handleValidation(undefined) }}
                    className={`new-btn w-20 py-2 px-2 text-white cursor ${(!data.tcAccepted && tab === 3) ? " disablesigncontract" : ""}`}>
                    {tab === 3 ? `${onlyView ? "Update" : "Submit"}` : "Continue"}
                  </button>
                </div>} */}

                {/* <div className="pb-5 px-5">
                  <button
                    type="button"
                    disabled={!data.tcAccepted && tab === 3}
                    onClick={() => handleValidation(undefined)}
                    className={`new-btn w-20 py-2 px-2 text-white cursor ${(!data.tcAccepted && tab === 3) ? "disablesigncontract" : ""}`}>
                    {tab === 3 ? (onlyView ? "Update" : "Submit") : "Continue"}
                  </button>
                </div> */}

                <div className="pb-5 px-5">
                  <button
                    type="button"
                    disabled={(!data.tcAccepted && ((isAdmin && tab === 4) || (!isAdmin && tab === 3)))}
                    onClick={() => handleValidation(undefined)}
                    className={`new-btn w-20 py-2 px-2 text-white ${(!data.tcAccepted && ((isAdmin && tab === 4) || (!isAdmin && tab === 3)))
                      ? "disablesigncontract"
                      : ""
                      }`}
                  >
                    {isAdmin
                      ? tab === 4
                        ? onlyView
                          ? "Update"
                          : "Submit"
                        : "Continue"
                      : tab === 3
                        ? onlyView
                          ? "Update"
                          : "Submit"
                        : "Continue"}
                  </button>
                </div>




              </div>
            </div>
          </main>
        </div>
      </div>

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      {modal && <SubmitModal modal={modal} setModal={setModal} />}
      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}
      {
        limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
          <p className="font-size-13 font-wt-600">Do you want to apply for LC discounting with the same bank?</p>
          <button className="bg-white px-3 py-2 btnsmall font-size-13" onClick={() => {
            setLimitinvoice(false);
            setData({ ...data, sameFinancierForDiscounting: true })
          }}>Yes</button>
          <button className="bg-white px-3 py-2 btnsmall font-size-13 ms-3" onClick={() => { setLimitinvoice(false) }}>No</button>
        </FinanceInvoiceModal>
      }
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(LcSeequotes)