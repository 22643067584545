export default function validateAddShareholder(data) {
  let errors = {};

  if (!data.name) {
    errors.name = 'Name is Mandatory';
  }
  if (!data.phone_number) {
    errors.phone_number = 'Phone Number is Mandatory'
  }
  if (!data.shareholderEmail) {
    errors.shareholderEmail = 'Email is Mandatory';
  }
  if (!data.dob) {
    errors.dob = 'Correspondent Account Number is Mandatory';
  }
  if (!data.add_1) {
    errors.add_1 = 'Address line 1 is Mandatory';
  }
  if (!data.add_2) {
    errors.add_2 = 'Address line 2 is Mandatory'
  }
  // if (!data.city) {
  //   errors.city = 'City is Mandatory'
  // }
  if (!data.state) {
    errors.state = 'State is Mandatory'
  }
  if (!data.nationality) {
    errors.nationality = 'Nationality is Mandatory'
  }
  if (!data.postalCode) {
    errors.postalCode = 'Postal Code is Mandatory'
  }
  if (!data.shareholderCountry) {
    errors.shareholderCountry = 'Country is Mandatory'
  }
  if (!data.sharePercent) {
    errors.sharePercent = 'Share Percent is Mandatory'
  }
  if (!data.din) {
    errors.din = 'DIN No is Mandatory'
  }
  if (!data.authSigntature) {
    errors.authSigntature = 'Authorized signatory is Mandatory'
  }
  if (!data.itr) {
    errors.itr = 'ITR is Mandatory'
  }
  if (!data.panDoc) {
    errors.panDoc = 'Pan Card Document is Mandatory'
  }
  if (!data.aadharDoc) {
    errors.aadharDoc = 'Aadhaar Card Document is Mandatory'
  }
  console.log(errors);
  return errors;
}
