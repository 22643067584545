import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import { setContractDetails, setContractState, setContractDocList } from '../../store/actions/action';


import { getSocket } from '../../socket'
import NewTablev2 from '../../utils/newTablev2';
import { formatDate_Application } from '../../utils/dateFormaters';
import { getStatusDisplayName } from '../dataTablesColumsMap/contractListColoums';
import { Action } from '../myCounterPartComp/action';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import SalesAndPurchaseTab from './SalesAndPurchaseTab';

const finHighlights = [
  { name: 'In-Pilot', color: 'rgba(255, 193, 7, 0.9)' },
  { name: 'In-Progress', color: 'rgba(248, 148, 6, 0.9)' },
  { name: 'Completed', color: 'rgba(63, 195, 128, 0.9)' }
]


const ContainerContractManagement = ({ userTokenDetails, cDetailsState, setContractDetails, contractState, setContractState, clientType, setContractDocList, navToggleState, dispatch }) => {
  console.log(userTokenDetails, "tokennnnnnnn")
  const queryParams = new URLSearchParams(window.location.search)


  var Socket = getSocket()
  const [contractCount, setcontractCount] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [filterData, setFilterData] = useState({})
  const [statusFilter, setstatusFilter] = useState(0)
  const [contractLog, setcontractLog] = useState({ modal: false, contract_no: "" })
  const [showQuotRaiseModal, setshowQuotRaiseModal] = useState({})
  const [showInvestigationRemark, setshowInvestigationRemark] = useState({})
  const [showInsuranceRemark, setshowInsuranceRemark] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [contDeleteData, setcontDeleteData] = useState({});
  const [inspectionContractModal, setInspectionContractModal] = useState({ show: false, data: {} })
  const [inspectionWorkorderDetails, setInspectionWorkorderDetails] = useState({ show: false, data: {}, offer_data: {} })

  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [action, setAction] = useState({ show: false, index: null })
  const [filter, setFilter] = useState({ resultPerPage: 10, search: queryParams.get("search") || "" })
  const [page, setPage] = useState(1)
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })


  // transact dev
  const [tab, setTab] = useState('All')
  const [hideButtons, setHideButtons] = useState(true)
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const parentId = userTokenDetails.parentId !== undefined ? userTokenDetails.parentId : null

  console.log("aclMapppppppppppppppppppppppppppppppp", aclMap);





  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];
    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("containerContractManagement");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    // Create filter data & set it 
    let filterData = {}
    filterData["Date"] = {
      "accordianId": 'dateRangeFilter',
      type: "minMaxDate",
      value: []
    }
    filterData["Status"] = {
      "accordianId": 'status',
      type: "checkbox",
      data: [{ name: "In-Pilot" }, { name: "In-Progress" }, { name: 'Completed' }],
      labelName: "name"
    }
    setFilterData(filterData)
  }, [])

  useEffect(() => {
    fetchTableData({ userId: userId, type_id: userTypeId })
  }, [refresh, clientType.type, contractState.info.refresh, filter, filterData])

  function fetchTableData(inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": filter.resultPerPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      // "activeType": clientType.type,
      "userParentId": parentId,
      "gridOnly": true,
      search: filter.search
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    objectAPI["fromDate"] = objectAPI?.["dateRangeFilter"]?.[0]
    objectAPI["toDate"] = objectAPI?.["dateRangeFilter"]?.[1]
    if (objectAPI?.status?.includes("In-Pilot")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("In-Progress")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("Completed")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true
      }
    }
    call('POST', 'getContracts', objectAPI).then(async (result) => {
      console.log('running getcontract api-->', result);
      let drafts = await call('POST', 'getAllInvoiceLimitDrafts', { userId, forContract: true })
      drafts = drafts.length ? drafts : []
      drafts = drafts.concat(result.contractdata || [])
      settableData(drafts)
      setTotalRows(result.countdata.totalcount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  }

  // useEffect(()=>{
  //   getDifferentContractCount()
  // },[])


  // async function getDifferentContractCount(){
  //   if(tableData.length>0){
  //     for(let i = 0 ;i < tableData.length ;i++){

  //     }
  //   }
  // }


  async function handleTransactionPopup(itemData) {
    console.log(itemData, "itemmmmmm");
    setshowLoader(true)
    let tempData = []

    let exportFactoringLogs = { recent: [], others: [] }
    let lcLogs = { recent: [], others: [] }
    let logisticLogs = { recent: [], others: [] }
    let contractLogs = { recent: [], others: [] }

    let sortedRecents = []

    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      applicationId: itemData.invLimitAppId, buyerId: itemData.invLimitBuyerId
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Export Factoring" })
      tempData = tempData.concat(apiResp)
    }
    apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.lcLimitBuyerId, applicationId: itemData.lcLimitAppId
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Letter of Credit" })
      tempData = tempData.concat(apiResp)
    }
    apiResp = await call('POST', 'getTransactionsHistoryForShipmentBooking', {
      contractNo: itemData.contract_number
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Logistic" })
      tempData = tempData.concat(apiResp)
    }
    apiResp = await call('POST', 'getcontractlogs', {
      contract_number: itemData.contract_number
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Contract Management" })
      tempData = tempData.concat(apiResp)
    }
    tempData.sort(function (a, b) {
      return new Date(a.dateTime || a.created_at) - new Date(b.dateTime || b.created_at);
    })
    tempData = tempData.reverse()
    // console.log("tempDataaaaaaaaaaaaaaaaaaaaaaaa", tempData);
    let logTypes = [{ name: "Export Factoring", variable: exportFactoringLogs }, { name: "Letter of Credit", variable: lcLogs },
    { name: "Logistic", variable: logisticLogs }, { name: "Contract Management", variable: contractLogs }]
    for (let index = 0; index < tempData.length; index++) {
      const element = tempData[index];
      for (let j = 0; j < logTypes.length; j++) {
        if (element.logType === logTypes[j]["name"]) {
          if (!logTypes[j]["variable"]["recent"].length) {
            logTypes[j]["variable"]["recent"] = [element]
            sortedRecents.push(element)
          }
          else {
            logTypes[j]["variable"]["others"].push(element)
          }
        }
      }
    }
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", sortedRecents, exportFactoringLogs, lcLogs, logisticLogs, contractLogs);
    setshowLoader(false)
    toggleTransactionPopup({
      show: true, data: tempData, sortedRecents, exportFactoringLogs, lcLogs, logisticLogs, contractLogs,
      contract_number: itemData.contract_number
    })
  }

  const handleNew = () => {

    if (booladd || userPermissions == null) {
      window.location = '/contractManagementAdd';
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  return (
    <>

      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>
            <div className="modal-body px-4">
              {transactionPopup.sortedRecents?.length ? transactionPopup.sortedRecents.map((item, index) => {
                let logTypes = {
                  "Export Factoring": { alt: "exportFactoringLogs", redirect: `/Quotes?search=${transactionPopup?.contract_number}` },
                  "Letter of Credit": { alt: "lcLogs", redirect: `/LcQuote?search=${transactionPopup?.contract_number}` },
                  "Logistic": { alt: "logisticLogs", redirect: `/ShipmentBookingQuotes?search=${transactionPopup?.contract_number}` },
                  "Contract Management": { alt: "contractLogs" }
                }
                return (
                  <>
                    <div className='d-flex flex-row ml-3'>
                      <div className="progressBarContainer">
                        <div className="progressBarInnerCircle">
                        </div>
                      </div>
                      <div className='pl-4 pt-3 d-flex row'>
                        <div className='row pt-2' >
                          <p
                            onClick={() => {
                              toggleTransactionPopup({ ...transactionPopup, [item.logType]: !transactionPopup[item.logType] })
                            }}
                            className='font-size-14 text-color1 font-wt-500 mb-0 col-6 cursor'>{item.logType}
                            {transactionPopup?.[logTypes[item.logType]["alt"]]?.["others"]?.length ?
                              <img
                                style={transactionPopup[item.logType] ? { transform: 'rotate(180deg)' } : {}}
                                className='cursor' src='assets/images/arrowdown.png' /> : null}
                          </p>
                          <p className='font-size-14 text-color-label font-wt-500 mb-0 col-6 text-right'>{item.date || moment(item.created_at).format("DD MMM, YYYY")} {"|"} {item.time || moment(item.created_at).format("hh:mm a")}</p>
                        </div>
                        <p
                          onClick={() => {
                            if (logTypes[item.logType]["redirect"]) {
                              window.location = `${logTypes[item.logType]["redirect"]}`
                            }
                          }}
                          className='font-size-14 text-color-label font-wt-500 mb-0 cursor'>{item.action || item.message}</p>
                      </div>
                    </div>
                    {
                      transactionPopup[item.logType] ?
                        transactionPopup?.[logTypes[item.logType]["alt"]]?.["others"].map((j, k) => {
                          return (<div className='d-flex flex-row ml-5'>
                            <div className="progressBarContainer">
                              <div className="progressBarInnerCircle">
                              </div>
                            </div>
                            <div className='pl-4 pt-3 d-flex row'>
                              <div className='row pt-2' >
                                <p className='font-size-14 text-color-label font-wt-500 mb-0 col-6'>{j.action || j.message}</p>
                                <p className='font-size-14 text-color-label font-wt-500 mb-0 col-6 text-right'>{j.date || moment(j.created_at).format("DD MMM, YYYY")} {"|"} {j.time || moment(j.created_at).format("hh:mm a")}</p>
                              </div>
                            </div>
                          </div>
                          )
                        })
                        : null
                    }
                  </>
                )
              })
                : null}
            </div>
          </div>
        </div>
      </div>
      {loading && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="containerContractManagement" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Contract Management"}
              userTokenDetails={userTokenDetails}
            />
            <nav className="position-relative">
              {hideButtons && <div className="nav nav-tabs signdoctabs gap-4 border-0 w-100 d-flex flex-nowrap" id="signdoctabs" role="tablist" style={{ overflowX: 'hidden', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                {["All", "Sales", "Purchase"].map(tabName => (
                  <button
                    key={tabName}
                    className={`nav-link bg-transparent ${tab === tabName && 'active'} paymenttab mb-2`}
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    style={{ width: "fit-content" }}
                    aria-controls={`nav-${tabName.toLowerCase().replace(" ", "-")}`}
                    aria-selected={tab === tabName}
                    onClick={() => setTab(tabName)}
                  >
                    {tabName}
                  </button>
                ))}

              </div>}
            </nav>



            <SalesAndPurchaseTab userTokenDetails={userTokenDetails} setHideButtons={setHideButtons} navToggleState={navToggleState} tab={tab} />
          </main>
        </div>
      </div>

    </>
  )

}

const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setContractDocList: (id, data) => { dispatch(setContractDocList({ modal: true, contractId: id, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerContractManagement)