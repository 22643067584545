import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, extractFloatFromText, GetCache, getDocDetails, handleFileCommonFunction, handleFileWithAsyncAwait, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputForTable, InputWithSelect, NewInput, NewSelect, NewTextArea, SelectForTable } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import MultipleSelectForTable from '../../utils/MultipleSelectForTable';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const TallyTransactionsBankStatement = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ totalAmtCurrency: 'USD' })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: false })
  const [voucherUploadPopup, setVoucherUploadPopup] = useState({ show: false })
  const [statementUploadPopup, setStatementUploadPopup] = useState({ show: false })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [ledgers, setLedgers] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [items, setItems] = useState([])
  const [particulars, setParticulars] = useState([null])
  const [salesPurchaseVouchers, setSalesPurchaseVouchers] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  let jj = -1

  const [tablecol, setTablecol] = useState([
    { subColumns: 'Upload Date', isChecked: true },
    { subColumns: 'Bank Ledger', isChecked: true },
    { subColumns: 'Bank Name', isChecked: true },
    { subColumns: 'Statement From', isChecked: true },
    { subColumns: 'Statement To', isChecked: true },
    { subColumns: 'Status', isChecked: true },
    { subColumns: 'Action', isChecked: true }
  ])


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("tallyTxnsBankStatements");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  useEffect(() => {
    call('POST', 'getTransactionVoucher', { userId, voucherType: ["Sales", "Purchase"] }).then(res => {
      let temp = []
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        if ((element.details?.voucherType === "Sales" || element.details?.voucherType === "Purchase") && element.details.invoiceNo) {
          temp.push({ name: element.details.invoiceNo })
        }
      }
      setSalesPurchaseVouchers([...temp])
    })
  }, [])

  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    // for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
    //   let filterName = Object.keys(filterData)[index]
    //   const element = filterData[filterName];
    //   if (element.isFilterActive) {
    //     if (element.type === "checkbox") {
    //       objectAPI[element.accordianId] = []
    //       element["data"].forEach((i) => {
    //         if (i.isChecked) {
    //           objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
    //         }
    //       })
    //     }
    //     else if (element.type === "minMaxDate") {
    //       objectAPI[element.accordianId] = element["value"]
    //     }
    //   }
    // }
    call('POST', 'getUploadedBankStatements', objectAPI).then((result) => {
      console.log('running getUploadedBankStatements api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getUploadedBankStatements', e);
      setshowLoader(false)
    });
    SetCache("voucherTxnsFilterData", filterData)
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getAccountingLedgers', { userId }).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        res.data[index]["name"] = element.details.name
      }
      let tempLedgers = [{ name: 'Add New' }]
      tempLedgers = tempLedgers.concat(res?.data?.length ? res.data : [])
      setLedgers([...tempLedgers])
    })
    call('POST', 'getusercommoditylist', { userId }).then(res => {
      // for (let index = 0; index < res.data.length; index++) {
      //   const element = res.data[index];
      //   res.data[index]["name"] = element.details.name
      // }
      let tempItems = [{ commodity_pretty_name: 'Add New' }]
      tempItems = tempItems.concat(res?.length ? res : [])
      setItems([...tempItems])
    })
  }, [refresh])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', selectedChat.wcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation() {
    let validateFields = ["voucherType"]
    let err = {}
    if (data.voucherType === 'Sales' || data.voucherType === "Purchase") {
      validateFields = ["voucherType", "date", "partyName", "selectedLedger", "placeOfSupply", "totalAmount", "totalAmtCurrency"]
    }
    if (data.voucherType === 'Payment' || data.voucherType === "Receipt") {
      validateFields = ["voucherType", "date", "selectedLedger", "totalAmount", "totalAmtCurrency"]
      let isParticularAdded = false
      for (let index = 0; index < particulars.length; index++) {
        if (particulars[index] === null && data[`particularName${index}`] && data[`particularAmount${index}`]) {
          isParticularAdded = true
        }
      }
      if (!isParticularAdded) {
        err["particular"] = 'Mandatory'
        toastDisplay('Enter particulars to save', "info")
      }
    }
    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = 'Mandatory field'
      }
    }
    if (!Object.keys(err).length) {
      setshowLoader(true)
      if ((data.voucherType === "Sales" || data.voucherType === "Purchase") && !data.id) {
        for (let index = 0; index < invItems.length; index++) {
          if (invItems[index] === null && data[`itemName${index}`]) {
            await call("POST", 'adjustQuantityTrail', {
              commodityPrettyName: data[`itemName${index}`], userId,
              rate: data[`itemRate${index}`], usedFor: data.invoiceNo || "NA", date: data.date,
              quantity: data.voucherType === "Sales" ? data[`itemQuantity${index}`] * -1 : data[`itemQuantity${index}`]
            })
          }
        }
      }
      call('POST', 'saveTransactionVoucher', { ...data, userId, invItems, particulars }).then(res => {
        toastDisplay(data.id ? "Voucher Updated" : "Voucher Created", "success", () => {
          setshowLoader(false)
          window.location.reload()
          // setData({})
          // setRefresh(refresh + 1)
        })
      })
    }
    setErrors({ ...err })
  }

  async function uploadBankStatement() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("bankStatementFile", data.bankStatementFile)
    formData.append("userId", userId)
    formData.append("selectedBankLedger", data.selectedBankLedger)
    call('POST', 'uploadBankStatement', formData).then(res => {
      setshowLoader(false);
      toastDisplay("Document uploaded, pls wait till it process", "success")
      setStatementUploadPopup({ show: false })
      setRefresh(refresh + 1)
    }).catch(err => { setshowLoader(false); toastDisplay("Something went wrong", "error") })

  }

  async function invoiceOCRInit(pdfFileObj) {
    if (pdfFileObj?.name) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("document", pdfFileObj)
      let resp = await call('POST', 'analyzeInvoiceByOCR', formData)
      // console.log("invoiceOCRResp==========>", resp);
      let counterPartyName = data?.voucherType === "Sales" ? resp?.customer_name : resp?.supplier_name
      setData({
        ...data, invoiceNo: resp?.complete_response?.invoice_no, totalAmtCurrency: resp?.invoice_currency_code,
        totalAmount: extractFloatFromText(resp?.invoice_amount || "0"),
        partyName: counterPartyName
      })
      if (counterPartyName) {
        let isLedgerExists = ledgers.filter(i => {
          if (i.name === counterPartyName) { return true }
        })?.[0]
        if (!isLedgerExists) {
          call('POST', 'saveAccountingMastersLedger', {
            name: counterPartyName, alias: counterPartyName, parent: 'Primary', userId
          })
          setRefresh(refresh + 1)
        }
      }
      setshowLoader(false)
    }
  }



  const handleCreateNew = () => {
    if (!booladd) {
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    } else {
      setStatementUploadPopup({ show: true })
    }
  }
  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    // Sync `updatedData` with `tablecol` only when modal is opened for the first time or `tablecol` changes
    useEffect(() => {
      if (showModal) {
        setUpdatedData([...tablecol]);
      }
    }, [showModal]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      // Toggle the checked state of the column
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setUpdatedData(items); // Update reordered columns
    };

    const handleSave = () => {
      // Persist `updatedData` to `tablecol`
      setTablecol([...updatedData]);
      setShowModal(false); // Close modal
    };

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
            width: "13rem",
            paddingTop: "9px",
            paddingBottom: "9px"
          }}
        >
          Customize Columns
        </button>

        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {updatedData.map((ele, index) => (
                            <Draggable key={ele.subColumns} draggableId={ele.subColumns} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="mb-1"
                                    style={{
                                      border: "2px solid #84d4ef",
                                      padding: "8px",
                                      borderRadius: "0.5rem",
                                      backgroundColor: snapshot.isDragging
                                        ? "#d25de9"
                                        : "white",
                                      color: snapshot.isDragging ? "white" : "black",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <img
                                      onClick={() => handleCheck(index)}
                                      className="cursor ml-8"
                                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                                        }.png`}
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                      alt={ele.isChecked ? "Checked" : "Unchecked"}
                                    />
                                    <label style={{ marginLeft: "0.5rem" }}>{ele.subColumns}</label>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyTxnsBankStatements" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Banking > Bank Statements`}
              userTokenDetails={userTokenDetails} />

            {statementUploadPopup.show && (
              <FinanceInvoiceModal
                limitinvoice={statementUploadPopup.show}
                setLimitinvoice={() => { setStatementUploadPopup({ show: false }) }}
                closeSuccess={() => { setStatementUploadPopup({ show: false }) }}
              >
                <label className="text-center font-size-16 font-wt-600 w-100">Upload Bank Statement</label>
                <label className="text-center font-size-13 font-wt-600 w-100 mb-4">Please upload the bank statement without password in pdf format.</label>
                <div className="d-flex row justify-content-center">
                  <div className='col-10'>
                    <MultipleSelect singleSelection
                      Label={'Select Bank Ledger'}
                      Id={`selectedBankLedger`}
                      onClick={() => { setRefresh(refresh + 1) }}
                      optiondata={ledgers}
                      onChange={(e) => {
                        if (e?.[e?.length - 1]?.name === "Add New") {
                          window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                        }
                        else {
                          handleChange(multiSelectEventHandler(e, `selectedBankLedger`, "name"))
                        }
                      }}
                      value={data[`selectedBankLedger`] ? [data[`selectedBankLedger`]] : []}
                      name={`selectedBankLedger`}
                      labelKey={"name"}
                      valKey={"name"}
                      error={errors[`selectedBankLedger`]}
                    />
                  </div>
                  <div className='col-10'>
                    <FileInput isEditable={true} name={"bankStatementFile"} value={data.bankStatementFile}
                      onChange={e => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                      onUploadCancel={() => setData({ ...data, [`bankStatementFile`]: null })}
                    />
                    {errors[`bankStatementFile`] ? <div class="text-danger mt-2 font-size-12">
                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                      <b>{errors[`bankStatementFile`]}</b></div> : ''}
                    <div className='d-flex justify-content-center mt-4'>
                      <button disabled={!(data.bankStatementFile?.name && data.selectedBankLedger)} className={`new-btn w-60 py-2 px-2 text-white cursor`}
                        onClick={uploadBankStatement}
                      >Submit</button>
                    </div>
                  </div>
                </div>
              </FinanceInvoiceModal>
            )}

            <div className='mt-4' style={{}} >
              {createGroupView.show ? null : <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                  isAdditionalButton>
                  <div className="d-flex gap-3">
                    <ColumnSelector tablecol={tablecol} setTablecol={setTablecol} />
                    {/* <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setVoucherUploadPopup({ show: true })}>Import From Tally</button>
                    </div> */}
                    <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={handleCreateNew}>Upload New</button>
                    </div></div>
                </Filter>

              </div>}

              {createGroupView.show ?
                <>
                  <i
                    onClick={() => {
                      window.location.reload()
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                  <div className="bg-white pb-2">
                    <div className="d-flex justify-content-center pt-5 mb-1" >
                      <FormProgressBar
                        tabs={
                          [{ name: "Voucher Details" }]} activeTab={tab} label={"name"}
                        separationWidth={'1rem'}
                      />
                    </div>
                    {!data.id && (data.voucherType === "Sales" || data.voucherType === "Purchase") ?
                      <div className='d-flex row mt-5 mb-4 mx-0 col-8'>
                        <label className='font-size-14 font-wt-500' >{`Upload ${data.voucherType} invoice & our system will fetch details from document automatically`}</label>
                        <div className='col-8'>
                          <FileInput
                            name={`invoiceDocForOCR`} value={data[`invoiceDocForOCR`]} error={errors[`invoiceDocForOCR`]}
                            isEditable={true} onChange={async (e) => {
                              setshowLoader(true)
                              let fileData = await handleFileWithAsyncAwait(e)
                              // console.log("fileDataaaaaaaaaaaaaaa", fileData);
                              if (fileData.status) {
                                setErrors({ ...errors, invoiceDocForOCR: "" })
                                setData({ ...data, invoiceDocForOCR: fileData.msg?.invoiceDocForOCR })
                                invoiceOCRInit(fileData.msg?.invoiceDocForOCR)
                              }
                              else {
                                setErrors({ ...errors, invoiceDocForOCR: fileData.msg?.invoiceDocForOCR })
                                setshowLoader(false)
                              }
                            }}
                            onUploadCancel={() => setData({ ...data, [`invoiceDocForOCR`]: null })}
                          />
                          {errors[`invoiceDocForOCR`] ? <div class="text-danger mt-2 font-size-12">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors[`invoiceDocForOCR`]}</b></div> : ''}
                        </div>
                      </div> : null}
                    <div className="row d-flex p-4">
                      <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Voucher Type'}
                          Id={`voucherType`}
                          isAstrix
                          optiondata={[{ name: 'Purchase' }, { name: 'Sales' }, { name: 'Payment' }, { name: 'Receipt' }]}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `voucherType`, "name"))
                          }}
                          value={data[`voucherType`] ? [data[`voucherType`]] : []}
                          name={`voucherType`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`voucherType`]}
                        />
                      </div>
                      <div className="col-md-3">
                        <NewInput label={"Invoice/Ref No"}
                          name={"invoiceNo"} value={data.invoiceNo} error={errors.invoiceNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput type={"date"} label={"Date"} isAstrix
                          name={"date"} value={data.date} error={errors.date}
                          onChange={handleChange} />
                      </div>
                      {(data.voucherType === "Sales" || data.voucherType === "Purchase") ? <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Select Party'}
                          Id={`partyName`}
                          isAstrix
                          onClick={() => { setRefresh(refresh + 1) }}
                          optiondata={ledgers}
                          onChange={(e) => {
                            if (e?.[e?.length - 1]?.name === "Add New") {
                              window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                            }
                            else {
                              handleChange(multiSelectEventHandler(e, `partyName`, "name"))
                            }
                          }}
                          value={data[`partyName`] ? [data[`partyName`]] : []}
                          name={`partyName`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`partyName`]}
                        />
                      </div> : null}

                      {data.voucherType ?
                        <div className="col-md-3">
                          <MultipleSelect singleSelection
                            Label={data.voucherType === "Purchase" ? 'Purchase Ledger' :
                              data.voucherType === "Sales" ? "Sales Ledger" :
                                data.voucherType === "Payment" ? "Select Account" : data.voucherType === "Receipt" ? "Select Account" : ""}
                            Id={`selectedLedger`}
                            isAstrix
                            optiondata={ledgers}
                            onClick={() => { setRefresh(refresh + 1) }}
                            onChange={(e) => {
                              if (e?.[e?.length - 1]?.name === "Add New") {
                                window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                              }
                              else {
                                handleChange(multiSelectEventHandler(e, `selectedLedger`, "name"))
                              }
                            }}
                            value={data[`selectedLedger`] ? [data[`selectedLedger`]] : []}
                            name={`selectedLedger`}
                            labelKey={"name"}
                            valKey={"name"}
                            error={errors[`selectedLedger`]}
                          />
                        </div> : null}
                      {(data.voucherType === "Purchase" || data.voucherType === "Sales") ? <div className="col-md-3">
                        <NewInput label={"Place of Supply"}
                          name={"placeOfSupply"} value={data.placeOfSupply} error={errors.placeOfSupply}
                          onChange={handleChange} />
                      </div> : <>
                        <div className="col-md-3">
                          <NewInput label={"Transaction Date"} type={"date"}
                            name={"transactionDate"} value={data.transactionDate} error={errors.transactionDate}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-3">
                          <NewSelect label={"Transaction Type"} selectData={[{ name: 'Cash' }, { name: 'Bank Transfer' }]}
                            name={"transactionType"} value={data.transactionType} error={errors.transactionType} optionLabel={"name"}
                            optionValue={"name"}
                            onChange={handleChange} />
                        </div>
                        {data.transactionType === "Bank Transfer" ? <>
                          <div className="col-md-3">
                            <NewSelect label={"Transfer Mode"} selectData={[{ name: 'Cheque' }, { name: 'DD' }, { name: 'NEFT' }, { name: 'RTGS' }, { name: 'IMPS' }, { name: 'UPI' },
                            { name: 'OTHER' }
                            ]} optionLabel={"name"} optionValue={"name"}
                              name={"transferMode"} value={data.transferMode} error={errors.transferMode}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-3">
                            <NewInput label={"Transaction No"} name={"transactionNo"} value={data.transactionNo}
                              error={errors.transactionNo} onChange={handleChange} />
                          </div>
                        </> : null}
                      </>}
                    </div>




                    <div className="row d-flex px-4 pt-4">
                      <div className="col-md-9">
                        <div className='col-6 m-0 p-0'>
                          <NewTextArea label={"Narration"}
                            name={"narration"} value={data.narration} error={errors.narration}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className='col-12 m-0 p-0'>
                          <InputWithSelect type={"number"} label={"Total Amount"}
                            selectData={currencyData}
                            selectName={"totalAmtCurrency"} selectValue={data.totalAmtCurrency}
                            optionLabel={"code"} optionValue={'code'}
                            name={'totalAmount'} value={data.totalAmount} error={errors.totalAmount}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-center'>
                      <button type="button"
                        onClick={() => {
                          handleValidation()
                        }}
                        className={` new-btn w-15 py-2 px-2 text-white mt-5`}>
                        {createGroupView.viewUpdate ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </> :
                <>
                  <div>
                    <NewTablev2 filterData={filterData} columns={tablecol.filter(col => col.isChecked)} >
                      {dbData.map((item, j) => {
                        return (
                          <tr key={j}>
                            {tablecol.map((col) => {
                              if (col.isChecked) {
                                return (
                                  <td key={col.subColumns}>
                                    {col.subColumns === " Upload Date" && (<label className="font-size-13 font-wt-400 text-break" >{moment(item.createdAt).format("YYYY/MM/DD")}</label>)}
                                    {col.subColumns === "Bank Ledger" && (<label className="font-size-13 font-wt-400 text-break" >{item.ledgerName || "-"}</label>)}
                                    {col.subColumns === "Bank Name" && (<label className="font-size-13 font-wt-400 text-break" >{item.bankName || "-"}</label>)}
                                    {col.subColumns === "Statement From" && (<label className="font-size-13 font-wt-400 text-break" >{item.statementFromDate ? moment(item.statementFromDate).format("YYYY/MM/DD") : "NA"}</label>)}
                                    {col.subColumns === "Statement To" && (<label className="font-size-13 font-wt-400 text-break" >{item.statementToDate ? moment(item.statementToDate).format("YYYY/MM/DD") : 'NA'}</label>)}
                                    {col.subColumns === "Status" && (<label>{
                                      item.status / 1 == 1 ?
                                        <button type="button"
                                          class={`approved text-white border-0 `}>
                                          {"Processed"}
                                        </button> : <button type="button"
                                          class={`inprogress text-white border-0 `}>
                                          {"Processing"}
                                        </button>}</label>)}
                                    {col.subColumns === "Action" && (<label
                                      // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', display: showGridCol(filterData, wcQuotesColumn[6]["subColumns"]) }}
                                      className='position-relative'>
                                      <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                        onClick={() => setAction({ show: true, index: j })}
                                        aria-hidden="true"></i>
                                      {action.show && action.index === j ? (
                                        <Action
                                          id={j}
                                          onDismiss={() => setAction({ show: false, index: j })}
                                          options={[
                                            {
                                              name: "View", onClick: () => {
                                                localStorage.setItem("bankStatementDetails", JSON.stringify(item))
                                                window.location = '/tallyTransactionsBankStatementDetails'
                                              }
                                            }, {
                                              name: "Delete", onClick: async () => {
                                                setshowLoader(true)
                                                await call("POST", 'deleteBankStatementById', { id: item.id })
                                                setshowLoader(false)
                                                toastDisplay('Statement Deleted', "success", () => {
                                                  setRefresh(refresh + 1)
                                                })
                                              }
                                            }
                                          ]} />
                                      ) : null}
                                    </label>)}
                                  </td>)
                              }
                              return null
                            })}
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                </>}

            </div>
          </main>
        </div >
      </div >

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(TallyTransactionsBankStatement)