import React, { useRef, useState } from "react";
import { NewInput } from "./newInput";
import toastDisplay from "./toastNotification";

const NewTablev2 = ({
  columns,
  children,
  tableSubColumnWith,
  tableFixed,
  thwidth,
  showSideBorders,
  stickTableHeaderToTop,
  hideHeading,
  addTableClass,
  filterData,
  setFilterData,
  filteredSearch,
  setFilteredSearch,
  filter,
  setFilter,
  filterKey,
  refresh,
  setRefresh,
  freezeFirstColumn
  , tableStyles, boolscroll
}) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [sortedcols, setsortedcols] = useState(columns);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const box2 = useRef();
  const [filterPopup, toggleFilterPopup] = useState({});
  const setFilterDataOnChange = (filterName, filterDataIndex, item) => {
    let temp = JSON.parse(JSON.stringify(filterData));
    let temp2 = JSON.parse(JSON.stringify(filteredSearch));
    tableStyles = tableStyles || {}
    let indexTemp1;
    temp[filterName]["data"].forEach((val, index) => {
      if (val.name === item.name) {
        indexTemp1 = index;
      }
    });

    temp[filterName]["data"][indexTemp1]["isChecked"] =
      !temp[filterName]["data"][indexTemp1]["isChecked"];
    temp2[filterName]["data"][filterDataIndex]["isChecked"] =
      !temp2[filterName]["data"][filterDataIndex]["isChecked"];
    temp2[filterName]["isAllChecked"] = false;
    temp[filterName]["isAllChecked"] = false;
    console.log(
      "temppppp",
      temp,
      temp2,
      temp2[filterName]["data"][filterDataIndex]
    );
    let isFilterActive = false;
    for (let index = 0; index < temp[filterName]["data"].length; index++) {
      const element = temp[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive = true;
      }
    }
    let isFilterActive2 = false;

    for (let index = 0; index < temp2[filterName]["data"].length; index++) {
      const element = temp2[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive2 = true;
      }
    }
    temp[filterName]["isFilterActive"] = isFilterActive;
    temp2[filterName]["isFilterActive"] = isFilterActive2;
    setFilterData({ ...temp });
    setFilteredSearch({ ...temp2 });
  };

  const handleAllFilterStatus = (filterName) => {
    let temp = filterData;
    let temp2 = filteredSearch;
    let isFilterActive = false;

    for (let index = 0; index < temp2[filterName].data.length; index++) {
      const element = temp2[filterName]["data"][index];
      element["isChecked"] = !temp2[filterName]["isAllChecked"];
      if (element["isChecked"]) {
        isFilterActive = true;
      }
    }

    temp[filterName]["isFilterActive"] = isFilterActive;
    temp[filterName]["isAllChecked"] = !temp2[filterName]["isAllChecked"];

    temp2[filterName]["isFilterActive"] = isFilterActive;
    temp2[filterName]["isAllChecked"] = !temp2[filterName]["isAllChecked"];
    setFilterData({ ...temp });
    setFilteredSearch({ ...temp2 });
  };

  const handleSort = () => {
    let newColumns = [...sortedcols];
    const draggedItemContent = newColumns[dragItem.current];
    newColumns.splice(dragItem.current, 1);
    newColumns.splice(dragOverItem.current, 0, draggedItemContent);

    setsortedcols([...newColumns]);
  };

  const handleDragStart = (index) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const handleDragEnd = () => {
    handleSort();
    dragItem.current = null;
    dragOverItem.current = null;
  };
  tableStyles = tableStyles || {}
  return (
    <>
      <div style={{ overflowX: boolscroll ? 'auto' : 'unset' }}>
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: 0,
            border: "1px solid #D5D5D5",
            borderRadius: "10px",
            ...tableStyles
          }}
          class={`table Tablenew ${tableFixed && " tableLayoutfixed "
            } ${addTableClass} bordered-table `}
        >
          {hideHeading ? null : (
            <thead
              className={stickTableHeaderToTop ? "stickTableHeaderToTop" : ""}
            >
              <tr>
                {columns.map((item, index) => {
                  if (filterData?.["Grid Columns"]?.data?.length) {
                    if (
                      !filterData["Grid Columns"].data.filter(
                        (i) => i.name === item.subColumns && i.isChecked
                      )?.[0]
                    ) {
                      return null;
                    }
                  }
                  return (
                    <th
                      key={index}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragEnter={() => handleDragEnter(index)}
                      onDragEnd={handleDragEnd}
                      onClick={() => {
                        if (item.subColumnsOnClick) {
                          item.subColumnsOnClick();
                        }
                      }}
                      style={{
                        width: item.width || 'auto',
                        ...item.subColumnStyle,
                        position: freezeFirstColumn && index === 0 ? 'sticky' : 'static',
                        left: freezeFirstColumn && index === 0 ? 0 : 'auto',
                        backgroundColor: freezeFirstColumn && index === 0 ? 'white' : 'transparent',
                        zIndex: freezeFirstColumn && index === 0 ? 2 : 1
                      }} scope="col" className={`font-size-14 font-wt-500 ${item.subColumnsOnClick ? ' cursor ' : " "} ${item.thwidth ? item.thwidth : ''} ${showSideBorders ? ' tableNewTh ' : ' '}`}> <div className="mb-0 d-flex ">
                        <div className={item.subColumnClass}> {item.subColumns}</div>
                        {item.colAdditional}
                        {/* <div style={{ paddingLeft: "5px" }}><i class="arrow down"></i></div> */}

                        {filterPopup[item.filterDataKey] ? (
                          <div
                            ref={box2}
                            className={"position-absolute bg-white sortPopup px-3"}
                            style={{
                              width: "20rem",
                              zIndex: 10,
                              // top: 0,
                            }}
                          >
                            <div className="py-2 border-bottom-eeeeee d-flex flex-row gap-2 justify-content-between">
                              <div>
                                {item.name}
                                <img
                                  style={{ transform: "rotate(180deg)" }}
                                  onClick={() => {
                                    toggleFilterPopup({
                                      [item.filterDataKey]: false,
                                    });
                                  }}
                                  className="mx-2 cursor"
                                  src={"assets/images/header/down_arrow.png"}
                                />
                              </div>

                              {filteredSearch?.[item?.filterDataKey]?.data
                                ?.length ? (
                                <div>
                                  <img
                                    onClick={() =>
                                      handleAllFilterStatus(item.filterDataKey)
                                    }
                                    className="cursor"
                                    src={`assets/images/${filteredSearch[item.filterDataKey][
                                      "isAllChecked"
                                    ]
                                      ? "checked-green"
                                      : "empty-check"
                                      }.png`}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div>
                              {item?.sort?.length
                                ? item.sort.map((m, n) => (
                                  <div
                                    key={n}
                                    onClick={() => {
                                      m.onActionClick();
                                      toggleFilterPopup({
                                        [item.filterDataKey]: false,
                                      });
                                    }}
                                    className="pt-1 border-bottom-eeeeee cursor"
                                  >
                                    <label
                                      className={`${m.selected
                                        ? "text-color1 font-wt-600 cursor"
                                        : "text-color-label cursor"
                                        }`}
                                    >
                                      {m.name}
                                    </label>
                                  </div>
                                ))
                                : null}
                              {filterData?.[item?.filterDataKey]?.data?.length ? (
                                <div>
                                  <NewInput
                                    name={"search"}
                                    label={"Search"}
                                    onChange={(e) => {
                                      console.log(
                                        filterData.item.filterDataKey,
                                        "data key---------->>>>"
                                      );
                                      setTimeout(() => {
                                        if (e.target.value) {
                                          console.log(filteredSearch);
                                          if (
                                            filteredSearch?.[item?.filterDataKey][
                                            "accordianId"
                                            ] === filterKey
                                          ) {
                                            setFilter({
                                              ...filter,
                                              [`${filterKey}Search`]:
                                                e.target.value,
                                            });
                                            setRefresh(refresh + 1);
                                          }
                                          let key =
                                            filteredSearch[item.filterDataKey][
                                            "labelName"
                                            ];
                                          const filtereddata = filterData?.[
                                            item?.filterDataKey
                                          ]?.data.filter((item) =>
                                            item[key]
                                              ?.toLowerCase()
                                              .includes(
                                                e.target.value?.toLowerCase()
                                              )
                                          );
                                          setFilteredSearch({
                                            ...filteredSearch,
                                            [item?.filterDataKey]: {
                                              ...filteredSearch[
                                              item?.filterDataKey
                                              ],
                                              data: filtereddata,
                                            },
                                          });
                                        } else {
                                          setFilteredSearch(filterData);
                                        }
                                      }, 500);
                                    }}
                                  />
                                </div>
                              ) : null}
                              <div
                                style={{
                                  overflow: "auto",
                                  height: "auto",
                                  maxHeight: "15rem",
                                }}
                              >
                                {filteredSearch?.[item?.filterDataKey]?.data?.length
                                  ? filteredSearch?.[item?.filterDataKey]?.data.map(
                                    (i, j) => {
                                      console.log(
                                        filteredSearch.item,
                                        "this is item----"
                                      );

                                      console.log(i, "this is i ---");
                                      return (
                                        <div className="mt-2" key={j}>
                                          <img
                                            onClick={() =>
                                              setFilterDataOnChange(
                                                item.filterDataKey,
                                                j,
                                                i
                                              )
                                            }
                                            className="cursor"
                                            src={`assets/images/${i.isChecked
                                              ? "checked-green"
                                              : "empty-check"
                                              }.png`}
                                          />
                                          <label className="ml-2">
                                            {
                                              i[
                                              filteredSearch[
                                              item.filterDataKey
                                              ]["labelName"]
                                              ]
                                            }
                                          </label>
                                        </div>
                                      );
                                    }
                                  )
                                  : null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {item.filter ? (
                          <img
                            onClick={() => {
                              console.log(
                                "FilteredSearch",
                                filteredSearch,
                                item.filterDataKey
                              );
                              if (
                                filteredSearch?.[item?.filterDataKey]?.data
                                  ?.length ||
                                item?.sort?.length ||
                                filterKey ===
                                filteredSearch?.[item?.filterDataKey]?.[
                                "accordianId"
                                ]
                              ) {
                                toggleFilterPopup({
                                  [item.filterDataKey]:
                                    !filterPopup[item.filterDataKey],
                                });
                              } else {
                                toastDisplay(
                                  "Data not available for filter",
                                  "info"
                                );
                              }
                            }}
                            className="mx-2 cursor"
                            src={"assets/images/header/down_arrow.png"}
                          />
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody
            className={`tablenewtbody ${showSideBorders ? " tablenewtbod2 " : ""
              } `}
          >
            {children}
          </tbody>
        </table>
      </div>
    </>
  );
};
export const showGridCol = (filterData, name) => {
  filterData = filterData?.["Grid Columns"]?.data || [];
  if (!filterData.length) {
    return undefined;
  }
  return filterData?.filter((i) => i.name === name && i.isChecked)?.[0]
    ? undefined
    : "none";
};
export default NewTablev2;
