import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const AccountingVouchers = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ activateVoucherType: true, allowNarration: true })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: false })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [parent, setParent] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [tablecol, setTablecol] = useState([
    { subColumns: 'Date', isChecked: true },
    { subColumns: 'Name', isChecked: true },
    { subColumns: 'Alias', isChecked: true },
    { subColumns: 'Type of Voucher', isChecked: true },
    { subColumns: 'Abbreviation', isChecked: true },
    { subColumns: 'Action', isChecked: true }
  ])



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("tallyMastersAccountingVoucherType");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getAccountingVoucherTypes', objectAPI).then((result) => {
      console.log('running getAccountingVoucherTypes api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getAccountingVoucherTypes', e);
      setshowLoader(false)
    });
    SetCache("accountingVoucherTypesFilterData", filterData)
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getAccountingMastersParentVoucherTypes', { userId }).then(res => {
      setParent(res)
    })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', selectedChat.wcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation() {
    let validateFields = ['name', "typeOfVoucher"]
    let err = {}
    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = 'Mandatory field'
      }
    }
    if (!Object.keys(err).length) {
      setshowLoader(true)
      call('POST', 'saveAccountingMastersVoucherType', { ...data, userId }).then(res => {
        toastDisplay("Voucher Type Created", "success", () => {
          setshowLoader(false)
          toggleCreateGroupView({ show: false })
          setData({})
          setRefresh(refresh + 1)
        })
      })
    }
    setErrors({ ...err })
  }


  const handleCreateNew = () => {

    if (booladd || userPermissions == null) {
      toggleCreateGroupView({ show: true })
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }


  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    // Sync `updatedData` with `tablecol` only when modal is opened for the first time or `tablecol` changes
    useEffect(() => {
      if (showModal) {
        setUpdatedData([...tablecol]);
      }
    }, [showModal]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      // Toggle the checked state of the column
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setUpdatedData(items); // Update reordered columns
    };

    const handleSave = () => {
      // Persist `updatedData` to `tablecol`
      setTablecol([...updatedData]);
      setShowModal(false); // Close modal
    };

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
            width: "13rem",
            paddingTop: "9px",
            paddingBottom: "9px"
          }}
        >
          Customize Columns
        </button>

        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {updatedData.map((ele, index) => (
                            <Draggable key={ele.subColumns} draggableId={ele.subColumns} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="mb-1"
                                    style={{
                                      border: "2px solid #84d4ef",
                                      padding: "8px",
                                      borderRadius: "0.5rem",
                                      backgroundColor: snapshot.isDragging
                                        ? "#d25de9"
                                        : "white",
                                      color: snapshot.isDragging ? "white" : "black",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <img
                                      onClick={() => handleCheck(index)}
                                      className="cursor ml-8"
                                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                                        }.png`}
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                      alt={ele.isChecked ? "Checked" : "Unchecked"}
                                    />
                                    <label style={{ marginLeft: "0.5rem" }}>{ele.subColumns}</label>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyMastersAccountingVoucherType" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Tally > Accounting Master > Voucher Type${createGroupView.show ? `${createGroupView.viewUpdate ? ' > View/Update' : ' > Create New'}` : ''}`}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              {createGroupView.show ? null : <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                  isAdditionalButton>
                  <div className="d-flex gap-4">
                    <ColumnSelector tablecol={tablecol} setTablecol={setTablecol} />
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={handleCreateNew}>Create New</button>
                  </div>
                </Filter>
              </div>}

              {createGroupView.show ?
                <>
                  <i
                    onClick={() => {
                      toggleCreateGroupView({ show: false })
                      setData({})
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                  <div className="bg-white pb-2">
                    <div className="d-flex justify-content-center pt-5 mb-1" >
                      <FormProgressBar
                        tabs={
                          [{ name: "Voucher Type Details" }]} activeTab={tab} label={"name"}
                        separationWidth={'1rem'}
                      />
                    </div>
                    <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput isAstrix type={"text"} label={"Name"}
                          name={"name"} value={data.name} error={errors.name}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput type={"text"} label={"Alias"}
                          name={"alias"} value={data.alias} error={errors.alias}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Type of Voucher'}
                          Id={`typeOfVoucher`}
                          isAstrix
                          optiondata={parent}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `typeOfVoucher`, "type"))
                          }}
                          value={data[`typeOfVoucher`] ? [data[`typeOfVoucher`]] : []}
                          name={`typeOfVoucher`}
                          labelKey={"type"}
                          valKey={"type"}
                          error={errors[`typeOfVoucher`]}
                        />
                      </div>
                      <div className="col-md-3">
                        <NewInput type={"text"} label={"Abbreviation"}
                          name={"abbreviation"} value={data.abbreviation} error={errors.abbreviation}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3 pt-1" >
                        <label className="font-size-14 font-wt-500 w-80" >Activate this Voucher Type</label>
                        <img
                          onClick={() => {
                            setData({ ...data, activateVoucherType: !data.activateVoucherType })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.activateVoucherType ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >Use effective dates for Vouchers</label>
                        <img
                          onClick={() => {
                            setData({ ...data, useEffectiveDates: !data.useEffectiveDates })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.useEffectiveDates ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >Allow zero-valued Transactions</label>
                        <img
                          onClick={() => {
                            setData({ ...data, allowZeroValuedTransactions: !data.allowZeroValuedTransactions })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.allowZeroValuedTransactions ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >Make this Voucher type as "optional" by default</label>
                        <img
                          onClick={() => {
                            setData({ ...data, voucherOptionalDefault: !data.voucherOptionalDefault })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.voucherOptionalDefault ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >Allow narration in Voucher</label>
                        <img
                          onClick={() => {
                            setData({ ...data, allowNarration: !data.allowNarration })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.allowNarration ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >Provide narrations for each ledger in Voucher</label>
                        <img
                          onClick={() => {
                            setData({ ...data, provideNarrationForEachLedger: !data.provideNarrationForEachLedger })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.provideNarrationForEachLedger ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >Track additional Costs for Purchases</label>
                        <img
                          onClick={() => {
                            setData({ ...data, additionalCostsForPurchases: !data.additionalCostsForPurchases })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.additionalCostsForPurchases ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className="col-md-3 mt-4" >
                        <label className="font-size-14 font-wt-500 w-80" >WhatsApp Voucher after saving</label>
                        <img
                          onClick={() => {
                            setData({ ...data, wpVoucherAfterSaving: !data.wpVoucherAfterSaving })
                          }}
                          className={'cursor w-20'} src={`assets/images/${data.wpVoucherAfterSaving ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                      </div>
                      <div className='d-flex justify-content-center'>
                        <button type="button"
                          onClick={() => {
                            handleValidation()
                          }}
                          className={` new-btn w-15 py-2 px-2 text-white mt-5`}>
                          {createGroupView.viewUpdate ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div></> :
                <>
                  <div>
                    <NewTablev2 filterData={filterData} columns={tablecol.filter(col => col.isChecked)} >
                      {dbData.map((item, j) => {
                        return (
                          <tr key={j}>
                            {tablecol.map((col) => {
                              if (col.isChecked) {
                                return (
                                  <td key={col.subColumns}>
                                    {col.subColumns === "Date" && (<label className="font-size-13 font-wt-500 text-break" >{moment(item.createdAt).format("DD/MM/YYYY")}</label>)}
                                    {col.subColumns === "Name" && (<label className="font-size-13 font-wt-500 text-break" >{item.details?.name || "-"}</label>)}
                                    {col.subColumns === "Alias" && (<label className="font-size-13 font-wt-500 text-break" >{item.details?.alias || "-"}</label>)}
                                    {col.subColumns === "Type of Voucher" && (<label className="font-size-13 font-wt-500 text-break" >{item.details?.typeOfVoucher || "-"}</label>)}
                                    {col.subColumns === "Abbreviation" && (<label className="font-size-13 font-wt-500 text-break" >{item.details?.abbreviation || "-"}</label>)}
                                    {col.subColumns === "Action" && (<label
                                      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', display: showGridCol(filterData, wcQuotesColumn[6]["subColumns"]) }}
                                      className='position-relative'>
                                      <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                        onClick={() => setAction({ show: true, index: j })}
                                        aria-hidden="true"></i>
                                      {action.show && action.index === j ? (
                                        <Action
                                          id={j}
                                          onDismiss={() => setAction({ show: false, index: j })}
                                          options={[
                                            {
                                              name: "View/Update", onClick: () => {
                                                setData({ ...item, ...item.details })
                                                toggleCreateGroupView({ show: true, viewUpdate: true })
                                              }
                                            }, {
                                              name: "Delete", onClick: async () => {
                                                setshowLoader(true)
                                                await call("POST", 'deleteAccountingVoucherType', { id: item.id })
                                                setshowLoader(false)
                                                toastDisplay('Voucher Type deleted', "success", () => {
                                                  setRefresh(refresh + 1)
                                                })
                                              }
                                            }
                                          ]} />
                                      ) : null}
                                    </label>)}

                                  </td>
                                )
                              }
                              return null
                            })}
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                </>}

            </div>
          </main>
        </div >
      </div >

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AccountingVouchers)