import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import CommercialInvoice from "./CommercialInvoice";
import ProformaInvoice from "./ProformaInvoice";
import call from "../../service";
import moment from "moment";
import { Action } from "../myCounterPartComp/action";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import {
  getDocDetails,
  multiSelectEventHandler,
} from "../../utils/myFunctions";
import toastDisplay from "../../utils/toastNotification";
import PurchaseOrder from "./PurchaseOrder";
import DraftLC from "./DraftLC";
import MultipleSelect from "../../utils/MultipleSelect";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { NewInput, NewSelect, NewTextArea } from "../../utils/newInput";
import { getBulkBreakMailTemplateforLC } from "../commodityFormandMaster/BulkBreakTemplate";
import { platformURL } from "../../urlConstants";

export let poTemplateTypes = [
  { name: "Draft LC", src: "/assets/images/po_template.PNG" },
];

const ELC = ({ userTokenDetails, navToggleState, userShipmentDetails, hideSideBarAndHeader, setbooldraftLC }) => {
  const queryParams = new URLSearchParams(window.location.search);
  let serarchParam = queryParams.get("search");

  const params = new URLSearchParams(window.location.search);
  const contractNoFromUrl = params.get('draftlc');

  console.log(contractNoFromUrl, "conract  o from url-->>>>")

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null;
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null;
  const subUserId = userTokenDetails.sub_user_id
    ? userTokenDetails.sub_user_id
    : null;
  const parentData = userTokenDetails.parent_data
    ? userTokenDetails.parent_data
    : null;

  const [showLoader, setshowLoader] = useState(false);
  const [filter, setFilter] = useState({
    resultPerPage: 10,
    search: serarchParam ? serarchParam : "",
  });
  const [filterData, setFilterData] = useState({});
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({
    show: false,
    data: { type: "" },
  });
  const [dbData, setDbData] = useState([]);
  const [page, setPage] = useState(1);
  const [action, setAction] = useState({ show: false, index: null });
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} });
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} });
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({});
  const [recentTemplates, setRecentTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalBuyers, settotalBuyers] = useState([]);
  const [showHistoryModal, setshowHistoryModal] = useState(false);
  const [transactionPopup, toggleTransactionPopup] = useState({
    show: false,
    data: [],
  });
  const [LCdocid, setLCdocid] = useState();
  const [lcid, setlcid] = useState();
  const [lcToSend, setlcToSend] = useState();
  const [searchUserForListing, setSearchUserForListing] = useState("");
  const [traderOnboardForm, toggleTraderOnboardForm] = useState({
    show: false,
    data: {},
  });
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [selectedBuyersData, setSelectedBuyersData] = useState([]);
  const [otherBuyers, setOtherBuyers] = useState([]);
  const [autoSelectUserByEmail, setAutoSelectUserByEmail] = useState(null);
  const [refreshSupplier, setrefreshSupplier] = useState(0);
  let isCompactContract =
    templateSelectionForm.data.type === "Compact Contract";
  const [errorData, seterrorData] = useState({});
  const recepients = [
    { name: "Buyer", value: "Buyer" },
    { name: "Supplier", value: "Supplier" },
    { name: "Financier", value: "Financier" },
  ];
  let contractState = {
    modal: true,
    info: {
      modalMode: queryParams.get("type") || "create",
      contractNo: queryParams.get("contractNo"),
    },
  };

  const [contractdata, setcontractdata] = useState([]);

  const [existingData, setExistingData] = useState([]);
  const [contractType, setcontractype] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [toggleemail, settogglemmail] = useState(false);

  const [data, setData] = useState({});

  const [selectedContract, setSelectedContract] = useState("");

  let [mailDetails, setMailDetails] = useState({});

  const [cols, setcols] = useState([]);

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");
  let communicationSettings
  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];
    communicationSettings = userPermissions.find(role => role.communicationSettings)?.communicationSettings || [];

    console.log(communicationSettings, "arrrr")
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("edocsLC");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");




  useEffect(() => {
    const fetchExistingData = async () => {
      try {
        if (selectedInvoiceData?.id) {
          call("POST", "getDocWithLCId", {
            lcId: selectedInvoiceData.id,
          }).then((res) => setExistingData(res));
        }
      } catch (error) {
        console.error("Error fetching existing data:", error);
      }
    };

    fetchExistingData();
  }, [selectedInvoiceData]);

  useEffect(() => {
    call("POST", "getRecentlyUsedEdocs", { userId, onlyLC: true }).then(
      (res) => {
        setRecentTemplates(res);
      }
    );
    setFilterData({
      "Template Type": {
        accordianId: "invoiceType",
        type: "checkbox",
        data: poTemplateTypes,
        labelName: "name",
      },
    });
  }, []);

  useEffect(() => {
    setshowLoader(true);
    let objectAPI = {
      userId: userId,
      userEmail: userEmail,
      userTypeId: userTypeId,
      currentPage: page,
      onlyLC: true,
      ...filter,
    };
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index];
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = [];
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(
                element.accordianId === "invoiceTypee"
                  ? i[element["labelName"]]
                  : `'${i[element["labelName"]]}'`
              );
            }
          });
        }
      }
    }
    call("POST", "getEdocs", objectAPI)
      .then(async (result) => {
        console.log("running getEdocs api-->", result);
        setDbData(result.data);
        setCount(result.count);
        setshowLoader(false);
      })
      .catch((e) => {
        // console.log('error in getEdocs', e);
        setshowLoader(false);
      });
  }, [refresh, page]);

  useEffect(() => {
    if (userTypeId === 8) {
      call("POST", "lcforlender", { userId })
        .then((result) => {
          setDbData(result);
          // setCount(result.count);
          setshowLoader(false);
        })
        .catch((e) => {
          // console.log('error in getEdocs', e);
          setshowLoader(false);
        });
    }
  }, []);

  const getAllBuyers = () => {
    call("POST", "getAllBuyers", { userId }).then((res) => {
      let tempResult = [
        {
          html: <label className="font-size-13 font-wt-400">Add New</label>,
          user_name: "Add New",
          id: "Add New",
        },
      ];
      tempResult = tempResult.concat(res || []);

      // Set the state with the modified response
      settotalBuyers(tempResult);
    });
  };

  useEffect(() => {
    getAllBuyers();
  }, []);

  const toggleSend = () => {
    // const selectedbanks = totalBuyers.filter((ele)=>ele.isChecked === true)
    // setshowLoader(true)
    const selectedbanks = totalBuyers
      .filter((ele) => ele.isChecked === true)
      .map((ele) => ele.company_name);

    let objectAPI = {
      userId: userId,
      userEmail: userEmail,
      userTypeId: userTypeId,
      username: userName,
      bank_name: selectedbanks,
      sendLC: true,
      docId: LCdocid,
    };
    call("POST", "getEdocs", objectAPI)
      .then(async (result) => {
        console.log("running getEdocs api-->", result);

        setshowLoader(false);
        const updatedTotalBuyers = totalBuyers.map((ele) => ({
          ...ele,
          isChecked: false,
        }));
        settotalBuyers(updatedTotalBuyers);
      })
      .catch((e) => {
        // console.log('error in getEdocs', e);
        setshowLoader(false);
      });
    setShowModal(!showModal);
  };

  async function signUpUser(companyName, contactPerson, companyAddress, email) {
    return new Promise((resolve) => {
      let reqObject = {
        typeId: 19,
        cinDocumentName: null,
        gstDocumentName: null,
        iecDocumentName: null,
        panDocumentName: null,
        aadharDocumentName: null,
        iecDocumentName: null,
        panDocumentName: null,
        aadharDocumentName: null,
        organizationType: "foreign",
        companyName,
        contactPerson,
        companyAddress,
        email,
        contactNo: "",
        gst_company_detail: null,
        iec_company_detail: null,
        cin_company_detail: null,
        type: "finTech",
        // referralCode: userData.refercode,
        password: "Pa$$w0rd!",
        passwordConfirm: "Pa$$w0rd!",
        termsAndCondition: true,
        country: "",
        industryType: "",
        techType: 1,
        companyCity: "",
        companyPostalCode: "",
        phoneCode: "",
        nameTitle: "",
        companyState: "",
        designation: "",
        accountStatus: "inactive",
      };
      setshowLoader(true);
      call("POST", "registration", reqObject)
        .then((result) => {
          setshowLoader(false);
          toastDisplay("Details saved", "info");
          setrefreshSupplier(refreshSupplier + 1);
          resolve(true);
        })
        .catch((err) => {
          setshowLoader(false);
          toastDisplay(err || "Something went wrong", "error");
          resolve(false);
        });
    });
  }

  const handleChange = (event) => {
    event.persist();

    setData((data) => ({
      ...data,
      [event.target.name]: event.target.value,
    }));
    seterrorData((errorData) => ({
      ...errorData,
      [event.target.name]: "",
    }));
  };

  const [selectedlist, setselectedlist] = useState([]);

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    setselectedlist(e);
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
      });
    } else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : [],
      });
    }
  };

  async function handleSelectAllBuyers(element) {
    let tempSelectedBuyers = [];
    let tempSelectedBuyersData = [];

    tempSelectedBuyers.push(element.id);
    tempSelectedBuyersData.push(element);

    setSelectedBuyers([...tempSelectedBuyers]);
    setSelectedBuyersData([...tempSelectedBuyersData]);
  }

  const handleValidation = () => {
    let tempMailDetails = {};
    let entriesValidated = true;

    for (let index = 0; index < selectedlist.length; index++) {
      if (communicationSettings.includes("Limit Order Creation")) {
        tempMailDetails[selectedlist[index].id] = {
          to: [selectedlist[index].login_id || "NA"],
          cc: [],
          bcc: [userTokenDetails.userEmail],
          subject: `Letter Of Credit`,
          html: getBulkBreakMailTemplateforLC({
            ...selectedlist[index],

            link: "www.google.com",
            userName,
            link: `${platformURL}/edocsLC`,
            currency: data.currency,
          }),
          attachments: [null],
        };
      } else {
        tempMailDetails[selectedlist[index].id] = {
          to: [selectedlist[index].login_id || "NA"],
          cc: [],
          bcc: [],
          subject: `Letter Of Credit`,
          html: getBulkBreakMailTemplateforLC({
            ...selectedlist[index],

            link: "www.google.com",
            userName,
            link: `${platformURL}/edocsLC`,
            currency: data.currency,
          }),
          attachments: [null],
        };
      }


    }
    setMailDetails({ ...tempMailDetails });
    if (!entriesValidated) {
      errorData["entriesValidated"] = "Please fill all details to continue";
      toastDisplay(errorData["entriesValidated"], "error");
    } else {
      setData({ ...data, selectedBuyerId: selectedlist[0]["id"] });
    }
  };

  const sendmailToSelectedLender = (lenderid) => {
    call("POST", "getlender", { docid: lcid, lenderid })
      .then(() => toastDisplay("Sent Successfully", "success"))
      .catch(() => toastDisplay("Try Again", "error"));
  };

  useEffect(() => {
    let objectAPI = {
      currentPage: page,
      resultPerPage: filter.resultPerPage,
      userId: userId,
      type_id: userTypeId,
      // "activeType": clientType.type,
      // userParentId: parentId,
      gridOnly: true,
      search: filter.search,
    };
    call("POST", "getContracts", objectAPI)
      .then((result) => {
        console.log("running getcontract api-->", result);
        setcontractdata(result.contractdata || []);

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error in getContracts", e);
        // setoading(false);
      });
  }, []);

  const [showContractModal, setshowcontractmodal] = useState(false);
  const [contractChosen, setcontractChosen] = useState({});
  const [showdraftlc, setdraftlc] = useState(false);
  const [selectedLender, setSelectedLender] = useState(null);
  const handleToggleModal = () => {
    setshowcontractmodal(!showContractModal);
  };

  const handleContractTypeChange = (event) => {
    const val = event.target.value;
    const getcontract = contractdata.filter((ele) => ele.contract_name == val);

    setcontractChosen(getcontract);
    setdraftlc(true);
  };


  const handleCreateNew = () => {
    if (booladd || userPermissions === null) {
      toggleTemplateSelectionForm({
        show: true,
        data: { type: "" },
      });
    } else {
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  useEffect(() => {
    if (userShipmentDetails) {
      toggleTemplateSelectionForm(({
        show: true,
        data: { type: "Draft LC" },
      }))

    }
  }, [])



  return (
    <>
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      <DocumentViewer
        show={viewDoc.show}
        value={viewDoc.doc}
        dismiss={() => toggleViewDoc({ show: false, doc: {} })}
      />
      <DocumentDownloader
        show={dowdDoc.show}
        value={dowdDoc.doc}
        dismiss={() => toggleDowdDoc({ show: false, doc: {} })}
      />

      <div
        className={`modal fade ${transactionPopup.show && "show"}`}
        style={
          transactionPopup.show ? { display: "block", zIndex: "100001" } : {}
        }
      >
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label className="font-size-16 font-wt-600 text-color-value mx-3">
                  LC History
                </label>
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() =>
                      toggleTransactionPopup({ show: false, data: [] })
                    }
                  ></button>
                </div>
              </div>
            </div>
            <div className="modal-body px-4">
              {Array.isArray(existingData) && existingData.length > 0 ? (
                existingData?.map((entry, index) => {
                  // Extracting relevant information from each data entry
                  let logType = Object.keys(entry.new_doc_data.data)[0]; // Assuming each entry has a single logType
                  console.log(logType, "this is logtypoe--->>>>");
                  return (
                    <div key={index} className="d-flex flex-row ml-3">
                      <div className="progressBarContainer">
                        <div className="progressBarInnerCircle"></div>
                      </div>
                      <div className="pl-4 pt-3 d-flex row">
                        <div className="row pt-2">
                          <p
                            onClick={() => {
                              toggleTransactionPopup({
                                ...transactionPopup,
                                [logType]: !transactionPopup[logType],
                              });
                            }}
                            className="font-size-14 text-color1 font-wt-500 mb-0 col-6 cursor"
                          >
                            {logType}
                            {transactionPopup?.[logType]?.others?.length ? (
                              <img
                                style={
                                  transactionPopup[logType]
                                    ? { transform: "rotate(180deg)" }
                                    : {}
                                }
                                className="cursor"
                                src="assets/images/arrowdown.png"
                                alt="expand-icon"
                              />
                            ) : null}
                          </p>
                          <p className="font-size-14 text-color-label font-wt-500 mb-0 col-6 text-right">
                            {moment(entry.timestamp).format("DD MMM, YYYY")} |{" "}
                            {moment(entry.timestamp).format("hh:mm a")}|
                            {entry.userId}
                          </p>
                        </div>
                        <p className="font-size-14 text-color-label font-wt-500 mb-0 cursor">
                          {entry.new_doc_data.data[logType]}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Not Available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          {!hideSideBarAndHeader && (<SideBarV2 state="edocsLC" userTokenDetails={userTokenDetails} />)}
          <main role="main" className={`ml-sm-auto ${navToggleState.status ? "expanded-right" : ""} ${hideSideBarAndHeader ? "col-lg-12" : "col-lg-10"}`} id="app-main-div">
            {!hideSideBarAndHeader && (<HeaderV2
              title={
                templateSelectionForm.show
                  ? `E Docs > Letter of Credit > Create Letter of Credit (${templateSelectionForm.data?.type})`
                  : `E Docs > Letter of Credit`
              }
              userTokenDetails={userTokenDetails}
            />)}

            {traderOnboardForm.show ? (
              <FinanceInvoiceModal
                limitinvoice={traderOnboardForm.show}
                closeSuccess={() => {
                  toggleTraderOnboardForm({ show: false });
                }}
              >
                <div className="d-flex row justify-content-center">
                  <label className="font-size-16 font-wt-600 text-center w-100 col-12 pb-3">{`Enter Details`}</label>
                  <div className="col-8 justify-self-center">
                    <NewInput
                      label={"Company Name"}
                      onChange={handleChange}
                      name={"traderOnboardCompanyName"}
                      value={data.traderOnboardCompanyName}
                    />
                  </div>
                  <div className="col-8">
                    <NewInput
                      label={"Email Id"}
                      onChange={handleChange}
                      name={"traderOnboardEmailId"}
                      value={data.traderOnboardEmailId}
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <button
                      type="button"
                      onClick={async () => {
                        if (
                          data.traderOnboardCompanyName &&
                          data.traderOnboardEmailId
                        ) {
                          // onboard user start
                          let userOnboardResp = await signUpUser(
                            data.traderOnboardCompanyName,
                            data.traderOnboardCompanyName,
                            null,
                            data.traderOnboardEmailId
                          );
                          if (userOnboardResp) {
                            setAutoSelectUserByEmail(data.traderOnboardEmailId);
                            setrefreshSupplier(refreshSupplier + 1);
                            setData({
                              ...data,
                              traderOnboardCompanyName: "",
                              traderOnboardEmailId: "",
                            });
                            toggleTraderOnboardForm({ show: false });
                          }
                        } else {
                          toastDisplay("Fill details to continue", "info");
                        }
                      }}
                      className={` new-btn w-100 py-2 px-2  text-white`}
                    >
                      {"Submit"}
                    </button>
                  </div>
                </div>
              </FinanceInvoiceModal>
            ) : null}

            {templateSelectionForm.data.type ? (
              <>
                {templateSelectionForm.data.type === "Draft LC" ? (
                  <>
                    <a
                      onClick={() => {
                        handleToggleModal();
                        toggleTemplateSelectionForm({
                          show: true,
                          data: { type: "" },
                        });
                        setSelectedInvoiceData({});
                      }}
                      className="cursor"
                    >
                      <img
                        className="cursor mb-3"
                        src={"assets/images/back.png"}
                        alt="Back"
                        height={15}
                        width={15}
                      />
                    </a>

                    <DraftLC
                      templateSelectionForm={templateSelectionForm}
                      toggleTemplateSelectionForm={toggleTemplateSelectionForm}
                      contractdata={contractdata}
                      showContractModal={showContractModal}
                      setshowcontractmodal={setshowcontractmodal}
                      handleContractTypeChange={handleContractTypeChange}
                      contractChosen={contractChosen}
                      selectedInvoiceData={selectedInvoiceData}
                      userTokenDetails={userTokenDetails}
                      existingData={existingData}
                      contractNoFromUrl={contractNoFromUrl}
                      userShipmentDetails={userShipmentDetails}
                      setbooldraftLC={setbooldraftLC}
                    />
                  </>
                ) : null}
              </>
            ) : (
              <>
                {templateSelectionForm.show ? (
                  <>
                    <a
                      onClick={() => {
                        toggleTemplateSelectionForm({
                          show: false,
                          data: { type: "" },
                        });
                        setSelectedInvoiceData({});
                      }}
                      className="cursor"
                    >
                      <img
                        className="cursor mb-3"
                        src={"assets/images/back.png"}
                        alt="Back"
                        height={15}
                        width={15}
                      />
                    </a>
                    <div className="card mt-1 px-2">
                      <label className="font-size-16 font-wt-600 w-100 text-center pt-4">
                        Select Template
                      </label>
                      <div className="d-flex gap-4 p-4 flex-wrap">
                        {poTemplateTypes.map((i, j) => {
                          return (
                            <div
                              onClick={() => {
                                toggleTemplateSelectionForm({
                                  show: true,
                                  data: { type: i.name },
                                });
                              }}
                              className="w-20 cursor"
                            >
                              <img
                                className="contractTemplateImg2"
                                style={{}}
                                src={i.src}
                              />
                              <label className="font-size-14 font-wt-600 p-2 cursor">
                                {i.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {recentTemplates?.length ? (
                        <>
                          <hr />
                          <label className="font-size-16 font-wt-600 w-100 pt-4 px-4 m-0">
                            Recently Used Templates
                          </label>
                          <div className="d-flex gap-4 p-4 flex-wrap">
                            {poTemplateTypes.map((i, j) => {
                              let isRecentlyUsed = recentTemplates.find((m) => {
                                if (m.template === i.name) {
                                  return true;
                                }
                              })?.["template"];
                              if (isRecentlyUsed) {
                                return (
                                  <div
                                    onClick={() => {
                                      toggleTemplateSelectionForm({
                                        show: true,
                                        data: { type: i.name },
                                      });
                                    }}
                                    className="w-20 cursor"
                                  >
                                    <img
                                      className="contractTemplateImg2"
                                      style={{}}
                                      src={i.src}
                                    />
                                    <label className="font-size-14 font-wt-600 p-2 cursor">
                                      {i.name}
                                    </label>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="filter-div position-relative">
                      <Filter
                        isAdditionalButton
                        filterData={filterData}
                        setFilterData={setFilterData}
                        showFilterBtn={true}
                        showResultPerPage={true}
                        count={count}
                        filter={filter}
                        setFilter={setFilter}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      >
                        {userTypeId === 19 && (
                          <div className="d-flex gap-4">
                            <button
                              className={`new-btn  py-2 px-2 text-white cursor`}
                              // onClick={() => {
                              //   toggleTemplateSelectionForm({
                              //     show: true,
                              //     data: { type: "" },
                              //   });
                              // }}
                              onClick={handleCreateNew}
                            >
                              Create New
                            </button>
                          </div>
                        )}
                      </Filter>
                    </div>

                    {toggleemail ? (
                      <FinanceInvoiceModal
                        limitinvoice={toggleemail}
                        closeSuccess={() => {
                          settogglemmail(!toggleemail);
                        }}
                        modalSize="lg"
                      >
                        <div className="d-flex row px-5 mb-0 pb-4 pt-4">
                          <div className="col-12">
                            {/* <label className='text-left font-wt-600 text-color1 font-size-16 w-100 text-center'>Send Mail</label> */}
                            <label className="text-left font-wt-600 text-color1 font-size-14  w-100 text-center mb-3">
                              (Mail template for your counterparty so they can
                              also work on this contract with you
                              collaboratively)
                            </label>
                            <div className="position-relative">
                              <MultipleSelect
                                // filterOption={() => true}
                                // onInputChange={(e) => {
                                //   handleFilterOptions(e, "buyerName")
                                // }}
                                Label={"Select Buyer"}
                                Id={`selectedBuyerId`}
                                optiondata={selectedlist}
                                onChange={(e) => {
                                  handleChange(
                                    multiSelectEventHandler(
                                      e,
                                      `selectedBuyerId`,
                                      "id"
                                    )
                                  );
                                }}
                                value={
                                  data[`selectedBuyerId`]
                                    ? [data[`selectedBuyerId`]]
                                    : []
                                }
                                name={`selectedBuyerId`}
                                labelKey={"user_name"}
                                valKey={"id"}
                              />
                            </div>
                            {data.selectedBuyerId ? (
                              <>
                                <label className="text-left font-wt-600 font-size-14 mb-2 w-100 ">
                                  To (add multiple email ids separared by comma)
                                </label>
                                <NewTextArea
                                  label={""}
                                  value={
                                    mailDetails[data.selectedBuyerId]["to"]
                                  }
                                  onChange={(e) => {
                                    let emailIds = e.target.value?.trim();
                                    let tempMailDetails = mailDetails;
                                    tempMailDetails[data.selectedBuyerId][
                                      "to"
                                    ] = emailIds ? emailIds.split(",") : [];
                                    setMailDetails({ ...tempMailDetails });
                                  }}
                                />
                                <label className="text-left font-wt-600 font-size-14 mb-2 w-100 ">
                                  CC (add multiple email ids separared by comma)
                                </label>
                                <NewTextArea
                                  label={""}
                                  value={
                                    mailDetails[data.selectedBuyerId]["cc"]
                                  }
                                  onChange={(e) => {
                                    let emailIds = e.target.value?.trim();
                                    let tempMailDetails = mailDetails;
                                    tempMailDetails[data.selectedBuyerId][
                                      "cc"
                                    ] = emailIds ? emailIds.split(",") : [];
                                    setMailDetails({ ...tempMailDetails });
                                  }}
                                />
                                <label className="text-left font-wt-600 font-size-14 mb-2 w-100 ">
                                  BCC (add multiple email ids separared by
                                  comma)
                                </label>
                                <NewTextArea
                                  label={""}
                                  value={
                                    mailDetails[data.selectedBuyerId]["bcc"]
                                  }
                                  onChange={(e) => {
                                    let emailIds = e.target.value?.trim();
                                    let tempMailDetails = mailDetails;
                                    tempMailDetails[data.selectedBuyerId][
                                      "bcc"
                                    ] = emailIds ? emailIds.split(",") : [];
                                    setMailDetails({ ...tempMailDetails });
                                  }}
                                />
                                <label className="text-left font-wt-600 font-size-14 mb-2 w-100 ">
                                  Subject
                                </label>
                                <NewTextArea
                                  label={""}
                                  value={
                                    mailDetails[data.selectedBuyerId]["subject"]
                                  }
                                  onChange={(e) => {
                                    let tempMailDetails = mailDetails;
                                    tempMailDetails[data.selectedBuyerId][
                                      "subject"
                                    ] = e.target.value;
                                    setMailDetails({ ...tempMailDetails });
                                  }}
                                />
                                <label className="text-left font-wt-600 font-size-14 mb-2 w-100 ">
                                  Message
                                </label>
                                <NewTextArea
                                  rows={10}
                                  label={""}
                                  value={
                                    mailDetails[data.selectedBuyerId]["html"]
                                  }
                                  onChange={(e) => {
                                    let tempMailDetails = mailDetails;
                                    tempMailDetails[data.selectedBuyerId][
                                      "html"
                                    ] = e.target.value;
                                    setMailDetails({ ...tempMailDetails });
                                  }}
                                />

                                <button
                                  className={`new-btn py-2 px-2 text-white cursor ml-2 mb-3`}
                                  onClick={() => {
                                    sendmailToSelectedLender(
                                      data.selectedBuyerId
                                    );
                                  }}
                                >
                                  Send
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </FinanceInvoiceModal>
                    ) : null}
                    {showModal ? (
                      <FinanceInvoiceModal
                        limitinvoice={showModal}
                        closeSuccess={() => {
                          setShowModal(!showModal);
                        }}
                      >
                        <div className="text-center">
                          <div className="col-12">
                            <label className="text-left font-wt-600 text-color1 font-size-14 w-100 text-center mb-3">
                              {`Select Lenders for LC ${lcToSend}`}
                            </label>
                            <div className="d-flex flex-column align-items-center">
                              <div
                                style={{
                                  width: "100%",
                                  maxWidth: "300px",
                                  marginBottom: "15px",
                                }}
                              >
                                <MultipleSelect
                                  Label={"Select Lenders"}
                                  optiondata={totalBuyers}
                                  Id={`lender_name`}
                                  name={"lender_name"}
                                  labelKey={"user_name"}
                                  valKey={"id"}
                                  value={
                                    data["lender_name"]
                                      ? data["lender_name"]
                                      : []
                                  }
                                  onChange={async (e) => {
                                    if (e?.[0]?.id === "Add New") {
                                      toggleTraderOnboardForm({ show: true });
                                    } else {
                                      handleMultiSelectchange(
                                        e,
                                        "lender_name",
                                        "id"
                                      );
                                    }
                                  }}
                                  error={errorData[`lender_name`]}
                                />
                              </div>
                              <button
                                className={`new-btn py-2 px-3 text-white cursor mb-3`}
                                onClick={() => {
                                  handleValidation();
                                  setShowModal(false);
                                  settogglemmail(true);
                                }}
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </FinanceInvoiceModal>
                    ) : null}

                    {/* {showModal && (
                      <div className="col-md-4 d-flex align-items-end">
                        <div style={{ flex: 1 }}>
                          <MultipleSelect
                            Label={"Select Lenders"}
                            optiondata={totalBuyers}
                            Id={`lender_name`}
                            name={"lender_name"}
                            labelKey={"user_name"}
                            valKey={"id"}
                            value={
                              data["lender_name"] ? data["lender_name"] : []
                            }
                            onChange={async (e) => {
                              if (e?.[0]?.id === "Add New") {
                                toggleTraderOnboardForm({ show: true });
                              } else {
                                handleMultiSelectchange(e, "lender_name", "id");
                              }
                            }}
                            error={errorData[`lender_name`]}
                          />
                        </div>
                        <button
                          className={`new-btn py-2 px-2 text-white cursor ml-2 mb-3`}
                          onClick={() => {
                            handleValidation();
                            setShowModal(false);
                            settogglemmail(true);
                          }}
                        >
                          Send
                        </button>
                      </div>
                    )} */}

                    <div>
                      <NewTablev2
                        columns={[
                          {
                            subColumns: "Date",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "LC No",
                            subColumnStyle: { width: "15%" },
                          },
                          {
                            subColumns: "Template Type",
                            subColumnStyle: { width: "15%" },
                          },
                          {
                            subColumns: "Total Amount",
                            subColumnStyle: { width: "15%" },
                          },
                          {
                            subColumns: "Applicant",
                            subColumnStyle: { width: "15%" },
                          },
                          {
                            subColumns: "Beneficiary",
                            subColumnStyle: { width: "15%" },
                          },
                          {
                            subColumns: "Action",
                            subColumnStyle: { width: "5%" },
                          },
                        ]}
                      // columns={cols}
                      >
                        {dbData.length > 0 ? dbData.map((i, j) => {
                          console.log(i, "heu this is iiii---->>>>");
                          return (
                            <tr>
                              {/* {userTypeId === 19 && (
                                <td>
                                  <div>
                                    <img
                                      className="cursor"
                                      onClick={() => {
                                        let tempSelectedBuyers = selectedBuyers;
                                        let tempSelectedBuyersData =
                                          selectedBuyersData;
                                        if (tempSelectedBuyers.includes(i.id)) {
                                          tempSelectedBuyers =
                                            tempSelectedBuyers.filter(
                                              (i) => i != i.id
                                            );
                                          tempSelectedBuyersData =
                                            tempSelectedBuyersData.filter(
                                              (i) => i.id != i.id
                                            );
                                          setSelectedBuyers([
                                            ...tempSelectedBuyers,
                                          ]);
                                          setSelectedBuyersData([
                                            ...tempSelectedBuyersData,
                                          ]);
                                        } else {
                                          tempSelectedBuyers.push(i.id);
                                          tempSelectedBuyersData.push(i);
                                          setSelectedBuyers([
                                            ...tempSelectedBuyers,
                                          ]);
                                          setSelectedBuyersData([
                                            ...tempSelectedBuyersData,
                                          ]);
                                        }
                                      }}
                                      src={`assets/images/${
                                        selectedBuyers.includes(i.id)
                                          ? "checked-green"
                                          : "empty-check"
                                      }.png`}
                                    />
                                    
                                  </div>
                                </td>
                              )} */}
                              <td>
                                <label className="font-size-13 font-wt-500">
                                  {moment(i.createdAt).format("YYYY-MM-DD")}
                                </label>
                              </td>
                              <td>
                                <label className="font-size-13 font-wt-500">
                                  {i.lcid || "-"}
                                </label>
                              </td>
                              <td>
                                <label className="font-size-13 font-wt-500">
                                  {i.template}
                                </label>
                              </td>
                              <td>
                                <label className="font-size-13 font-wt-500">
                                  {/* {i.docData?.data?.["32B"] ||  "-"} */}
                                  {i.docData?.data?.['Invoice Value'] || "-"}
                                </label>
                              </td>
                              <td>
                                <label className="font-size-13 font-wt-500">
                                  {/* {i.docData?.data?.["50"] || "-"} */}
                                  {i.docData?.data?.["Applicant"] || "-"}
                                </label>
                              </td>
                              <td>
                                <label className="font-size-13 font-wt-500">
                                  {i.docData?.data?.["Beneficiary"] || "-"}
                                </label>
                              </td>
                              <td
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                className="position-relative"
                              >
                                <i
                                  className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                  onClick={() =>
                                    setAction({ show: true, index: j })
                                  }
                                  aria-hidden="true"
                                ></i>
                                {action.show && action.index === j ? (
                                  <Action
                                    id={j}
                                    onDismiss={() =>
                                      setAction({ show: false, index: j })
                                    }
                                    options={[
                                      {
                                        name: "View",
                                        onClick: async () => {
                                          handleSelectAllBuyers(i);
                                          let docDetails = await getDocDetails(
                                            i.docId
                                          );
                                          if (Object.keys(docDetails)?.length) {
                                            toggleViewDoc({
                                              show: true,
                                              doc: docDetails,
                                            });
                                          } else {
                                            toastDisplay(
                                              "No document found",
                                              "error"
                                            );
                                          }
                                        },
                                      },
                                      {
                                        name: "Edit",
                                        onClick: async () => {
                                          handleSelectAllBuyers(i);
                                          setshowcontractmodal(false);
                                          let docDetails = await getDocDetails(
                                            i.docId
                                          );
                                          if (Object.keys(docDetails)?.length) {
                                            setSelectedInvoiceData(i);
                                            toggleTemplateSelectionForm({
                                              show: true,
                                              data: { type: i.template },
                                            });
                                          } else {
                                            toastDisplay(
                                              "No document found",
                                              "error"
                                            );
                                          }
                                        },
                                      },
                                      {
                                        name: "Download",
                                        onClick: async () => {
                                          handleSelectAllBuyers(i);
                                          let docDetails = await getDocDetails(
                                            i.docId
                                          );
                                          if (Object.keys(docDetails)?.length) {
                                            toggleDowdDoc({
                                              show: true,
                                              doc: docDetails,
                                            });
                                          } else {
                                            toastDisplay(
                                              "No document found",
                                              "error"
                                            );
                                          }
                                        },
                                      },
                                      {
                                        name: "Send To",
                                        onClick: async () => {
                                          handleSelectAllBuyers(i);
                                          setLCdocid(i.docId);
                                          setlcid(i.id);
                                          setlcToSend(i.lcid);
                                          setShowModal(!showModal);
                                        },
                                      },
                                      {
                                        name: "History",
                                        onClick: async () => {
                                          handleSelectAllBuyers(i);
                                          setSelectedInvoiceData(i);
                                          toggleTransactionPopup({
                                            show: true,
                                          });
                                          // handleTransactionPopup(i);
                                        },
                                      },
                                    ]}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          )
                        }) : (
                          <tr
                            style={{
                              height: "2.5rem",
                              borderBottom: "1px solid #EEEEEE",
                              left: "45%",
                            }}
                            className="position-relative"
                          >
                            <div style={{ zIndex: 10 }} className="position-absolute">
                              <label className="font-size-14 font-wt-500">No Data Found</label>
                            </div>
                          </tr>
                        )
                        }
                      </NewTablev2>
                      <Pagination
                        perPage={filter.resultPerPage || 10}
                        page={page}
                        totalCount={count}
                        onPageChange={(p) => setPage(p)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    navToggleState: state.navToggleState,
  };
};

export default connect(mapStateToProps, null)(ELC);
