import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';

const creditInsMenu = [
  {
    Icon: "assets/images/whole_turnover.svg",
    Heading: "Whole Turnover",
    Link: "/tciWholeTO",
    value: "whole_turnover"
  },
  {
    Icon: "assets/images/key_accounts.svg",
    Heading: "Key accounts",
    Link: "/tciKeyAccounts",
    value: "key_accounts"
  },
  {
    Icon: "assets/images/single_buyer.svg",
    Heading: "Single Buyer",
    Link: "/tciSingleBuyer",
    value: "single_buyer"
  },
  {
    Icon: "assets/images/transactional.svg",
    Heading: "Transactional",
    Link: "/tciTransactional",
    value: "transactional"
  }
];


const TradeCreditInsuarance = ({ userTokenDetails, navToggleState }) => {

  // console.log(
  //   userTokenDetails.UserAccessPermission,
  //   JSON.parse(userTokenDetails.UserAccessPermission),
  //   "this is exportermanagement--->>>>>"
  // );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("nwManagementExporter");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

        <SideBarV2 state="other_services_credit_insurance" userTokenDetails={userTokenDetails} />
        <main role="main" className={"container-bg ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            // title={`Buyer Management ${showAddBuyerForm ? ` > ${data.isEditing ? 'Edit Buyer' : 'Add Buyer'}` : ""}`}
            title={`Trade Credit Insuarance`}
            userTokenDetails={userTokenDetails} />
          <div className="col-md-11 mx-auto mt-4 d-flex justify-content-center">
            <div className="p-4 card border-0 rounded-3 ">
              <div className="text-center">
                <p className="mb-0 font-size-16 mt-3 font-wt-600" >Trade Credit Insuarance</p>
                <small className="text-secondary font-size-12 font-wt-400 ">Select Insurance type</small>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='col-md-8 col-centered'>
                  <div className='row mt-4 mb-5 pb-5'>
                    {creditInsMenu.map((item, index) => {
                      return <>
                        <div className='col-md-6 mt-4 text-center' onClick={() => {
                          if (item.Link == "/") {
                            toastDisplay("Coming Soon", "info")
                          } else {
                            if (userTokenDetails?.type_id == 19) {
                              window.location = item.Link + `?exporterId=${userTokenDetails?.user_id}`
                            } else {
                              window.location = item.Link
                            }
                          }
                        }}>
                          <div className='col-md-12'>
                            <div className="card p-3 tcitypeCard cursor">
                              <div className="d-flex gap-3 align-items-center">
                                <img src={item.Icon} alt="" />
                                <div className="row">
                                  <p className="font-size-14 mb-1">{item.Heading}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    })}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </main>
      </div>
    </>
  )
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}
export default connect(mapStateToProps, null)(TradeCreditInsuarance) 