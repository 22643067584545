import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const VendorPayments = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ activateVoucherType: true, allowNarration: true })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: false })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [parent, setParent] = useState([])
  const [voucherPopup, setVoucherPopup] = useState({})
  const [bankAccounts, setBankAccounts] = useState([])
  const [bankAccounts2, setBankAccounts2] = useState([])
  const [tableColumns, setTableColumns] = useState([
    { subColumns: 'Invoice Date', isChecked: true },
    { subColumns: 'Invoice No', isChecked: true },
    { subColumns: 'Purchase Ledger', isChecked: true },
    { subColumns: 'Vendor', isChecked: true },
    { subColumns: 'Amount', isChecked: true },
    { subColumns: 'Status', isChecked: true },
    { subColumns: 'Action', isChecked: true },
  ])
  const [selecter, toggleSelecter] = useState({ show: false, data: {} })
  const [selectedEntriesForBulkPayout, setSelectedEntriesForBulkPayout] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      console.log('Message received:', event.data);
      if (event.data === "RefreshData") {
        setVoucherPopup({})
        loadData()
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    loadData()
  }, [refresh, filter, filterData, page])

  async function loadData() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getVendorPayments', { userId, currentPage: page, ...filter })
    setshowLoader(false)
    setDbData(apiResp?.data)
    setCount(apiResp?.count)
    let bankAccsApiResp = await call('POST', 'getAccountingLedgers', {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
    })
    let bankAccs = []
    let bankAccs2 = []
    for (let index = 0; index < bankAccsApiResp.data?.length; index++) {
      const element = bankAccsApiResp.data?.[index];
      if (element?.details?.parent?.includes("Bank") && element?.details?.razorpayKeyId && element?.details?.razorpayKeySecret) {
        bankAccs.push({ ...element, name: element.details.name })
      }
      if (element?.details?.parent?.includes("Bank") && element?.details?.openMoneyKeyId && element?.details?.openMoneyKeySecret) {
        bankAccs2.push({ ...element, name: element.details.name })
      }
    }
    setBankAccounts([...bankAccs])
    setBankAccounts2([...bankAccs2])
  }

  async function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: "" })
  }

  useEffect(() => {
    let tempSelecter = [
      { subColumns: 'Invoice Date', isChecked: true },
      { subColumns: 'Invoice No', isChecked: true },
      { subColumns: 'Purchase Ledger', isChecked: true },
      { subColumns: 'Vendor', isChecked: true },
      { subColumns: 'Amount', isChecked: true },
      { subColumns: 'Status', isChecked: true },
      { subColumns: 'Action', isChecked: true },
    ]
    if (selecter.show) {
      setTableColumns([{
        subColumns: !selecter.selectAll ? 'Select All' : "Unselect All", isChecked: true,
        subColumnsOnClick: () => handleSelectAllForBulkPayouts()
      }, ...tempSelecter])
    }
    else {
      setTableColumns([...tempSelecter])
    }
  }, [selecter.show, selectedEntriesForBulkPayout])


  async function handleSelectAllForBulkPayouts() {
    let tempSelectedUsers = []
    if (selecter.selectAll) {
      setSelectedEntriesForBulkPayout([])
    }
    else {
      for (let index = 0; index < dbData.length; index++) {
        const element = dbData[index];
        if (element.paymentDetails?.status != "Paid") {
          tempSelectedUsers.push(element.id)
        }
      }
    }
    setSelectedEntriesForBulkPayout([...tempSelectedUsers])
    toggleSelecter({ show: true, selectAll: !selecter.selectAll })
  }

  console.log("voucherPopupppppppppppppp", voucherPopup);

  async function cancelOperation() {
    setSelectedEntriesForBulkPayout([])
    toggleSelecter({ show: false, data: {} })
  }

  async function processBulkPayouts() {
    let err = {}
    setshowLoader(true)
    for (let index = 0; index < selectedEntriesForBulkPayout.length; index++) {
      let element = selectedEntriesForBulkPayout[index];
      let selectedDbData = dbData.find(i => { if (i.id / 1 == element / 1) { return true } })
      let payoutBankDetails = bankAccounts.find(i => { if (i.id / 1 == data.bankAccForPayment / 1) { return true } })
      if (!payoutBankDetails) {
        payoutBankDetails = bankAccounts2.find(i => { if (i.id / 1 == data.bankAccForPayment / 1) { return true } })
      }
      let vendorData = await call('POST', 'getAccountingLedgers', {
        userId,
        userEmail,
        userTypeId,
        "type": "all",
        "currentPage": 1,
        "resultPerPage": 1,
        "search": selectedDbData.details?.partyName
      })
      vendorData = vendorData?.data?.[0] || {}
      try {
        await call('POST', 'initializeVendorPayment', {
          bankAccForPaymentType: data.bankAccForPaymentType,
          userId, keyId: data.bankAccForPaymentType === "razorpayx" ? payoutBankDetails.details.razorpayKeyId : payoutBankDetails.details.openMoneyKeyId,
          keySecret: data.bankAccForPaymentType === "razorpayx" ? payoutBankDetails.details.razorpayKeySecret : payoutBankDetails.details.openMoneyKeySecret,
          vendorData: vendorData?.details,
          accountNo: payoutBankDetails.details.bankACNo, amount: selectedDbData?.details?.totalAmount,
          currency: selectedDbData?.details?.totalAmtCurrency, modeOfPayment: data.modeOfPayment,
          voucherId: selectedDbData?.id, vendorId: vendorData?.id, bankLedgerId: data.bankAccForPayment
        })
      } catch (error) {
        console.log("errorIninitializeVendorPayment", error);
        err[element] = error
      }
    }
    setshowLoader(false)
    setErrors({ ...errors, ...err })
    cancelOperation()
    if (!Object.keys(err).length) {
      toastDisplay('Request processed successfully', "success")
    }
  }

  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    // Sync `updatedData` with `tablecol` only when modal is opened for the first time or `tablecol` changes
    useEffect(() => {
      if (showModal) {
        setUpdatedData([...tablecol]);
      }
    }, [showModal]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      // Toggle the checked state of the column
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setUpdatedData(items); // Update reordered columns
    };

    const handleSave = () => {
      // Persist `updatedData` to `tablecol`
      setTablecol([...updatedData]);
      setShowModal(false); // Close modal
    };



    const getUserBankList = () => {
      let objectAPI = {
        email: userEmail,
        inProfile: true,
        search: data.search_text,
        resultPerPage: data.pageCount,
        userId,
        // currentPage: 1
      }
      call('POST', 'getUserBanks', objectAPI).then((result) => {
        console.log('running getUserBanks api-->', result);


        // } else {
        //   setData({
        //     ...data,
        //     pageCount: 10
        //   })
        // }
      }).catch((e) => {
        console.log('error in getBuyersDetail', e);
      });
    }
    useEffect(() => {
      getUserBankList();
    }, []);

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
            width: "13rem",
            paddingTop: "9px",
            paddingBottom: "9px"
          }}
        >
          Customize Columns
        </button>

        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {updatedData.map((ele, index) => (
                            <Draggable key={ele.subColumns} draggableId={ele.subColumns} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="mb-1"
                                    style={{
                                      border: "2px solid #84d4ef",
                                      padding: "8px",
                                      borderRadius: "0.5rem",
                                      backgroundColor: snapshot.isDragging
                                        ? "#d25de9"
                                        : "white",
                                      color: snapshot.isDragging ? "white" : "black",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <img
                                      onClick={() => handleCheck(index)}
                                      className="cursor ml-8"
                                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                                        }.png`}
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                      alt={ele.isChecked ? "Checked" : "Unchecked"}
                                    />
                                    <label style={{ marginLeft: "0.5rem" }}>{ele.subColumns}</label>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (<>
    {selecter.showBankSelection ?
      <FinanceInvoiceModal modalSize={'md'} limitinvoice={selecter.showBankSelection}
        setLimitinvoice={() => { cancelOperation() }} closeSuccess={() => { cancelOperation() }} >
        <div className="col-12">
          <label className='text-left font-wt-600 font-size-16 w-100 text-center'>Select Bank</label>
          <label className='font-size-16 font-wt-600 text-color1 mt-4 mb-4'><u>RazorpayX Bank Account</u></label>
          <div className='d-flex row'>
            <div className="col-md-5 mx-0 px-0 position-relative">
              <NewSelect label={"Bank Account"} name={"bankAccForPayment"} value={data.bankAccForPayment}
                onChange={e => { setData({ ...data, [e.target.name]: e.target.value, bankAccForPaymentType: 'razorpayx' }) }}
                selectData={bankAccounts} optionLabel={"name"} optionValue={"id"} />
            </div>
            <div className="col-md-5 mx-4 px-0 position-relative">
              <NewSelect label={"Mode of Payment"} name={"modeOfPayment"} value={data.modeOfPayment} onChange={handleChange}
                selectData={[{ name: 'IMPS' }, { name: 'NEFT' }, { name: 'RTGS' }]} optionLabel={"name"} optionValue={"id"} />
            </div>
          </div>
          <label className='font-size-16 font-wt-600 mt-2 mb-0 w-100 text-center' >OR</label>
          <label className='font-size-16 font-wt-600 text-color1 mt-3 mb-4'><u>OpenMoney Bank Account</u></label>
          <div className='d-flex row'>
            <div className="col-md-5 mx-0 px-0 position-relative">
              <NewSelect label={"Bank Account"} name={"bankAccForPayment"} value={data.bankAccForPayment}
                onChange={e => { setData({ ...data, [e.target.name]: e.target.value, bankAccForPaymentType: 'openmoney' }) }}
                selectData={bankAccounts2} optionLabel={"name"} optionValue={"id"} />
            </div>
            <div className="col-md-5 mx-4 px-0 position-relative">
              <NewSelect label={"Mode of Payment"} name={"modeOfPayment"} value={data.modeOfPayment} onChange={handleChange}
                selectData={[{ name: 'IMPS' }, { name: 'NEFT' }, { name: 'RTGS' }]} optionLabel={"name"} optionValue={"id"} />
            </div>
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <button type='button'
              onClick={() => {
                if (data.bankAccForPayment && data.modeOfPayment) {
                  processBulkPayouts()
                }
                else {
                  toastDisplay("Select bank first", "info")
                }
              }}
              class={`new-btn text-white border-0 w-40 py-2`}>
              {"Pay Now"}
            </button>
          </div>
        </div>
      </FinanceInvoiceModal> : null
    }
    <div className={`modal fade ${voucherPopup.show && "show"}`} style={voucherPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
      <div className="modal-dialog modal-md mr-0 my-0">
        <div className="modal-content submitmodal pb-4"
        >
          <div className="modal-header border-0">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <label
                className="font-size-16 font-wt-600 text-color-value mx-3"
              >Voucher Details</label>
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close"
                  onClick={() => setVoucherPopup({ show: false })}></button>
              </div>
            </div>
          </div>
          <div className="modal-body px-4 mx-2">
            <label className='font-size-16 font-wt-600 text-color1 mb-3'><u>Vendor Mailing Details</u>
              <img onClick={() => {
                window.open(`tallyMastersAccountingLedger?search=${encodeURIComponent(voucherPopup.vendorData?.details?.name)}`, "_blank")
              }}
                className='mx-3 cursor' src='assets/images/edit.png' /></label>
            <div className='d-flex row'>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Name: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.name || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Address: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.mailingAddress || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`State: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.mailingState || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Country: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.mailingCountry || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Pincode: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.mailingPinCode || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Email Id: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.mailingEmailId || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Contact No: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.mailingContactNo || "NA"}</label></label>
            </div>
            <label className='font-size-16 font-wt-600 text-color1 mt-4 mb-3'><u>Vendor Bank Details</u>
              <img onClick={() => {
                window.open(`tallyMastersAccountingLedger?search=${encodeURIComponent(voucherPopup.vendorData?.details?.name)}`, "_blank")
              }}
                className='mx-3 cursor' src='assets/images/edit.png' /></label>
            <div className='d-flex row'>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Account Name: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.bankACHolderName || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Bank Name: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.bankName || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Account No: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.bankACNo || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`IFSC Code: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.vendorData?.details?.bankIFSC || "NA"}</label></label>
            </div>
            <label className='font-size-16 font-wt-600 text-color1 mt-4 mb-2'><u>Transaction Details</u>
              <img onClick={() => {
                window.open(`tallyTxnsVoucher?search=${encodeURIComponent(voucherPopup.voucherData?.id)}`, "_blank")
              }}
                className='mx-3 cursor' src='assets/images/edit.png' /></label>
            <div className='d-flex row'>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Invoice No: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.voucherData?.details?.invoiceNo || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Purchase Ledger: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{voucherPopup.voucherData?.details?.selectedLedger || "NA"}</label></label>
              <label className='font-size-14 font-wt-600 text-color-value col-6'>{`Total Amount: `}
                <label className='font-size-14 font-wt-500 text-color-label'>{`${voucherPopup.voucherData?.details?.totalAmount} ${voucherPopup.voucherData?.details?.totalAmtCurrency}`}</label></label>
            </div>
            <label className='font-size-16 font-wt-600 text-color1 mt-4 mb-4'><u>RazorpayX Bank Account</u></label>
            <div className='d-flex row'>
              <div className="col-md-5 mx-0 px-0 position-relative">
                <NewSelect label={"Bank Account"} name={"bankAccForPayment"} value={data.bankAccForPayment}
                  onChange={e => { setData({ ...data, [e.target.name]: e.target.value, bankAccForPaymentType: 'razorpayx' }) }}
                  selectData={bankAccounts} optionLabel={"name"} optionValue={"id"} />
              </div>
              <div className="col-md-5 mx-4 px-0 position-relative">
                <NewSelect label={"Mode of Payment"} name={"modeOfPayment"} value={data.modeOfPayment} onChange={handleChange}
                  selectData={[{ name: 'IMPS' }, { name: 'NEFT' }, { name: 'RTGS' }]} optionLabel={"name"} optionValue={"id"} />
              </div>
            </div>
            <label className='font-size-16 font-wt-600 mt-3 mb-0 w-100 text-center' >OR</label>
            <label className='font-size-16 font-wt-600 text-color1 mt-4 mb-4'><u>OpenMoney Bank Account</u></label>
            <div className='d-flex row'>
              <div className="col-md-5 mx-0 px-0 position-relative">
                <NewSelect label={"Bank Account"} name={"bankAccForPayment"} value={data.bankAccForPayment}
                  onChange={e => { setData({ ...data, [e.target.name]: e.target.value, bankAccForPaymentType: 'openmoney' }) }}
                  selectData={bankAccounts2} optionLabel={"name"} optionValue={"id"} />
              </div>
              <div className="col-md-5 mx-4 px-0 position-relative">
                <NewSelect label={"Mode of Payment"} name={"modeOfPayment"} value={data.modeOfPayment} onChange={handleChange}
                  selectData={[{ name: 'IMPS' }, { name: 'NEFT' }, { name: 'RTGS' }]} optionLabel={"name"} optionValue={"id"} />
              </div>
            </div>
            <div className='mt-4 d-flex row'>
              {voucherPopup.voucherData?.paymentDetails?.status === "Paid" ?
                <button type="button"
                  class={`new-btn text-white border-0 w-40 py-2 bg-2ECC71`}>
                  {"Already Paid"}
                </button> :
                <>
                  {errors.initializeVendorPayment ? <label className='font-size-15 font-wt-600 text-danger'>{errors.initializeVendorPayment}</label> : null}
                  <button type="button"
                    onClick={async () => {
                      if (!(data.bankAccForPayment && data.modeOfPayment)) {
                        setErrors({ ...errors, "initializeVendorPayment": "Select Payout Bank Details" })
                      }
                      else {
                        let payoutBankDetails = bankAccounts.find(i => { if (i.id / 1 == data.bankAccForPayment / 1) { return true } })
                        if (!payoutBankDetails) {
                          payoutBankDetails = bankAccounts2.find(i => { if (i.id / 1 == data.bankAccForPayment / 1) { return true } })
                        }
                        setshowLoader(true)
                        call('POST', 'initializeVendorPayment', {
                          bankAccForPaymentType: data.bankAccForPaymentType,
                          userId, keyId: data.bankAccForPaymentType === "razorpayx" ? payoutBankDetails.details.razorpayKeyId : payoutBankDetails.details.openMoneyKeyId,
                          keySecret: data.bankAccForPaymentType === "razorpayx" ? payoutBankDetails.details.razorpayKeySecret : payoutBankDetails.details.openMoneyKeySecret,
                          vendorData: voucherPopup.vendorData?.details,
                          accountNo: payoutBankDetails.details.bankACNo, amount: voucherPopup.voucherData?.details?.totalAmount,
                          currency: voucherPopup.voucherData?.details?.totalAmtCurrency, modeOfPayment: data.modeOfPayment,
                          voucherId: voucherPopup.voucherData?.id, vendorId: voucherPopup.vendorData?.id, bankLedgerId: data.bankAccForPayment
                        }).then(res => {
                          // console.log("apiRespppppppppppppppppp", res);
                          setErrors({ ...errors, "initializeVendorPayment": "" })
                          setshowLoader(false)
                          setVoucherPopup({})
                          toastDisplay(res, "success")
                        }).catch(err => {
                          // console.log("errorIninitializeVendorPayment", err);
                          setErrors({ ...errors, "initializeVendorPayment": err })
                          setshowLoader(false)
                        })
                      }
                    }}
                    class={`new-btn text-white border-0 w-40 py-2`}>
                    {"Pay Now"}
                  </button>
                </>}
            </div>
          </div>
        </div>
      </div>
    </div>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
    <div className="container-fluid">
      <div className="row">
        <SideBarV2 state="vendorBankPayments" userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={`Banking > Vendor Payments`}
            userTokenDetails={userTokenDetails} />

          <div className='mt-4' style={{}} >
            <div className='filter-div position-relative'>
              <Filter
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                isAdditionalButton={true}
              >
                <div className="d-flex gap-12">
                  <ColumnSelector tablecol={tableColumns} setTablecol={setTableColumns} />
                  {selecter.show ?
                    <button className={`new-btn2  py-2 px-2 text-color1 cursor`} onClick={cancelOperation}>Cancel Operation</button> : null}
                  <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => {
                    if (selecter.show && selectedEntriesForBulkPayout.length) {
                      toggleSelecter({ ...selecter, showBankSelection: true })
                    }
                    else {
                      toggleSelecter({ show: true, data: {} })
                    }
                  }}>
                    {selecter.show && selectedEntriesForBulkPayout.length ? `Select Bank` : `Bulk Payout`}</button>
                </div>
              </Filter>
            </div>
            <div>
              <NewTablev2 filterData={filterData} columns={tableColumns.filter(col => col.isChecked)} >
                {dbData.map((item, j) => {
                  return <tr key={j}>
                    {tableColumns.map((col) => {
                      if (col.isChecked) {
                        return (
                          <td key={col.subColumns}>
                            {(col.subColumns === "Select All" || col.subColumns === "Unselect All") && selecter.show ?
                              <div>
                                <img className='cursor' onClick={() => {
                                  if (item.paymentDetails?.status === "Paid") { return toastDisplay("Already Paid", "info") }
                                  let tempSelectedEntriesForBulkPayout = selectedEntriesForBulkPayout
                                  if (tempSelectedEntriesForBulkPayout.includes(item.id)) {
                                    tempSelectedEntriesForBulkPayout = tempSelectedEntriesForBulkPayout.filter(i => i != item.id)
                                    setSelectedEntriesForBulkPayout([...tempSelectedEntriesForBulkPayout])
                                  }
                                  else {
                                    tempSelectedEntriesForBulkPayout.push(item.id)
                                    setSelectedEntriesForBulkPayout([...tempSelectedEntriesForBulkPayout])
                                  }
                                }} src={
                                  `assets/images/${selectedEntriesForBulkPayout.includes(item.id) ? 'checked-green' : 'empty-check'
                                  }.png`
                                } />
                              </div>
                              : null}
                            {col.subColumns === "Invoice Date" && (<label className='font-size-13 font-wt-400 text-break'>{item.details?.date || "NA"}</label>)}
                            {col.subColumns === "Invoice No" && (<label className='font-size-13 font-wt-400 text-break'>{item.details?.invoiceNo || "NA"}</label>)}
                            {col.subColumns === "Purchase Ledger" && (<label className='font-size-13 font-wt-400 text-break'>{item.details?.selectedLedger || "NA"}</label>)}
                            {col.subColumns === "Vendor" && (<label className='font-size-13 font-wt-400 text-break'>{item.details?.partyName || "NA"}</label>)}
                            {col.subColumns === "Amount" && (<label className='font-size-13 font-wt-400 text-break'>{item.details?.totalAmount ?
                              `${item.details?.totalAmount} ${item.details?.totalAmtCurrency}` : "NA"}</label>)}
                            {col.subColumns === "Status" && (<label style={{ width: '9rem' }}>
                              <div
                                className='cursor'>
                                {item.paymentDetails?.status === "Paid" ?
                                  <button type="button"
                                    class={`approved text-white border-0 `}>
                                    {"Paid"}
                                  </button> :
                                  <button type="button"
                                    class={`inprogress text-white border-0 `}>
                                    {item.paymentDetails?.status}
                                  </button>}
                                {errors[item.id] ? <label className='font-size-12 font-wt-600 text-danger'>{errors[item.id]}</label> : null}
                              </div>
                            </label>)}
                            {col.subColumns === "Action" && (<label className='position-relative'>
                              <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                onClick={() => setAction({ show: true, index: j })}
                                aria-hidden="true"></i>
                              {action.show && action.index === j ? (
                                <Action
                                  id={j}
                                  onDismiss={() => setAction({ show: false, index: j })}
                                  options={[
                                    {
                                      name: "View Details", onClick: async () => {
                                        setshowLoader(true)
                                        let vendorData = await call('POST', 'getAccountingLedgers', {
                                          userId,
                                          userEmail,
                                          userTypeId,
                                          "type": "all",
                                          "currentPage": 1,
                                          "resultPerPage": 1,
                                          "search": item.details?.partyName
                                        })
                                        setshowLoader(false)
                                        vendorData = vendorData?.data?.[0] || {}
                                        setVoucherPopup({ show: true, voucherData: item, vendorData: vendorData })
                                      }
                                    }
                                  ]} />
                              ) : null}
                            </label>)}
                          </td>)
                      }
                      return null
                    })}
                  </tr>
                })}
              </NewTablev2>
            </div>
          </div>

        </main>
      </div>
    </div>
  </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(VendorPayments)