import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { financialYearMonths, handleFileCommonFunction, handleMultipleFile, mergeMultiplePdfs, most_used_currencies, multiSelectEventHandler } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import NewRegistration, { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import Papa from 'papaparse';
import MultipleSelect from "../../utils/MultipleSelect";
import { getTraderType, setTraderType } from "../../utils/cookieHelper";
import { IncoTerms } from "../UserOnboard/BuyersTab";
import ReactCountryFlag from "react-country-flag";

const tabsArr = [
  { name: "Shipment Details" },
  { name: "Select Shipper" },
  { name: "Review" }
]

export let DeliveryMode = [{ name: 'Sea' }, { name: 'Air' }, { name: 'Land' }, { name: 'Auto' }]

export let unitSystem = [
  { name: 'International (SI)', unit: 'mt' },
  { name: 'Imperial (US)', unit: 'lbs' },
  { name: 'Custom (mt+ft^3)', unit: 'mt' },
  { name: 'Custom (lbs+m^3)', unit: 'lbs' }
]

export let cargoType = [
  { name: 'Hazardous Cargo', img: 'assets/images/hazardous.svg' },
  { name: 'Perishable Cargo', img: 'assets/images/perishable.svg' },
  { name: 'Oversized Cargo', img: 'assets/images/oversized.svg' },
  { name: 'Liquid Cargo', img: 'assets/images/liquid.svg' }
]

export let seaTransportation = [{ name: 'Full Container Load (FCL)' }, { name: 'Less Container Load (LCL)' }, { name: 'Bulk' }]
export let landTransportation = [{ name: 'Road Full Container Load (FCL)' }, { name: 'Road Full Truck Load (FTL)' }, { name: 'Road Less Truck Load (LTL)' },
{ name: 'Rail Full Wagon Load (FWL)' }]
export let airTransportation = [{ name: 'Standard Cargo' }, { name: 'ULD Container' }]
export let shipContainerType = [{ name: '20’ Standard' }, { name: '40’ Standard' }, { name: '40’ High Cube' }, { name: '45’ High Cube' }
  , { name: '20’ Refrigerated' }, { name: '40’ Refrigerated' }]
export let shipType = [{ name: 'Asphalt Carrier' }, { name: 'Bulk Carrier' }, { name: 'Chemical Tanker' }, { name: 'Containership' },
{ name: 'Crude Container' }, { name: 'Gas Carrier' }, { name: 'General Cargo Vessel' }, { name: 'Heavy Lift' }, { name: 'Livestock' },
{ name: 'Product Tanker' }, { name: 'Refrigerated' }, { name: 'Ro/Ro' }, { name: 'Wood Chip' }]
export let truckType = [{ name: '2T-14M^3' }, { name: '3.5T-35M^3' }, { name: '3.75T-50M^3' }, { name: 'Car Carrier (Autocart)' },
{ name: 'Cattle Truck' }, { name: 'Coil Truck' }, { name: 'Conestoga 48\'' }, { name: 'Conestoga 53\'' }, { name: 'Drop-side Platform' },
{ name: 'Dry Van 28\'' }, { name: 'Dry Van 45\'' }, { name: 'Dry Van 48\'' }, { name: 'Dry Van 53\'' }, { name: 'Drump Truck' }]
export let wagonType = [{ name: 'Closed Wagon' }, { name: 'Container Platform' }, { name: 'Cover' }, { name: 'Covered Wagon' }, { name: 'Flat Wagon' },
{ name: 'Freight Semi-Wagon' }, { name: 'Freight Wagon' }]
export let uldType = [{ name: 'Demi' }, { name: 'HMA Stall' }, { name: 'LD-1' }, { name: 'LD-11' }, { name: 'LD-2' }, { name: 'LD-26' },
{ name: 'LD-29' }, { name: 'LD-29 reefer' }, { name: 'LD-3' }, { name: 'LD-3 reefer' }, { name: 'LD-39' }, { name: 'LD-4' }, { name: 'LD-6' },
{ name: 'LD-7' }]


const ApplyForBooking = ({ userTokenDetails, navToggleState, hideSideBarAndHeader, userShipmentDetails, contractNo }) => {
  console.log(userShipmentDetails, "userShipmentDetails--", contractNo, "contract no is it----")
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
    unitSystem: 'International (SI)',
    weightUnit: 'mt',
    volumeUnit: 'm^3',
    commodityPriceUnit: 'USD',
    traderActiveType: getTraderType()
  })
  const [errors, setErrors] = useState({})
  const [contractList, setContractList] = useState([])
  const [ports, setPorts] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [countryData, setcountryData] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [ShipperList, setShipperList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})

  const [userData, setUserData] = useState({});
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })
  const [shipperOnboardPopup, toggleShipperOnboardPopup] = useState({ show: false, data: {} })

  const [commList, setCommList] = useState([])
  const [commFilterList, setCommFilterList] = useState([])


  let traderActiveType = getTraderType()

  let reviewForm = [
    { "name": "Trader Role", val: getTraderType() },
    { "name": "Commodity Contract", val: data.commodityContract },
    {
      "name": "Unit System", val: data.unitSystem
    },
    {
      "name": "Commodity", val: data.commodity
    },
    {
      "name": "Cargo Type", val: data.cargoType
    },
    {
      "name": "Delivery Mode", val: data.deliveryMode
    },
    {
      "name": "Transportation By", val: data.transportationBy
    },
    {
      "name": "Ship Type", val: data.shipType
    },
    { "name": "Loading Rate", val: data.loadingRate, unit: `${data.weightUnit}/day` },
    { "name": "Discharging Rate", val: data.dischargeRate, unit: `${data.weightUnit}/day` },
    { "name": "Container Type", val: data.containerTruckWagonUldType, unit: `${data.containerTruckWagonUldTypeQuantity} X ` },
    {
      "name": "Weight", val: data.weight, unit: data.weightUnit
    },
    { "name": "Volume", val: data.volume, unit: data.volumeUnit },
    {
      "name": "Loading Country", val: data.loadingCountry
    },
    {
      "name": "Loading Port", val: data.loadingPort
    },
    {
      "name": "Unloading Country", val: data.unloadingCountry
    },
    {
      "name": "Unloading Port", val: data.unloadingPort
    },
    {
      "name": "Inco Terms", val: data.incoTerms
    },
    {
      "name": "Ready To Load", val: data.readyToLoad
    },

    { "name": "Total Shipment Value", val: data.commodityPrice, unit: data.commodityPriceUnit }

  ]


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  // const [userPermissions, setUserPermissions] = useState([]);

  // useEffect(() => {
  //   if (userTokenDetails.UserAccessPermission) {
  //     try {
  //       const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
  //       setUserPermissions(parsedPermissions);
  //     } catch (error) {
  //       console.error("Failed to parse user permissions:", error);
  //     }
  //   }
  // }, [userTokenDetails.UserAccessPermission]);

  // console.log(userPermissions, "Parsed user permissions");

  // const getSubPermissions = (componentPath) => {
  //   for (const role of userPermissions) {
  //     for (const perm of role.permissions) {
  //       if (perm.item === componentPath) {
  //         return perm.selected;
  //       }
  //     }
  //   }
  //   return [];
  // };

  // const subPermissions = getSubPermissions("ShipmentBookingApply");
  // console.log(subPermissions, "this is subpermission--//////");

  // // Boolean states for permissions
  // const [booladd, setbooladd] = useState(false);
  // const [boolview, setboolview] = useState(false);

  // useEffect(() => {
  //   const hasAddPermission = subPermissions.includes("create");
  //   const hasViewPermission = subPermissions.includes("view");
  //   console.log(hasAddPermission, "this is add permission--->>>>>");

  //   // Update state only if there is a change
  //   if (booladd !== hasAddPermission) {
  //     setbooladd(hasAddPermission);
  //   }
  //   if (boolview !== hasViewPermission) {
  //     setboolview(hasViewPermission);
  //   }
  // }, [subPermissions, booladd, boolview]);

  // console.log(subPermissions, "this is buyer management--->>>>>");
  // console.log(booladd, "this is bool add--->>>>");





  useEffect(() => {
    call('POST', 'getuserprofiledata', { 'email': userEmail, 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      setUserData(result)
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    })
    getPorts()
  }, []);

  async function getPorts() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getOldPortMaster', {})
    console.log("aaaaaaaaaaaaaaaaaaaaaaaa", apiResp);
    let tempPorts = apiResp
    for (let index = 0; index < tempPorts.length; index++) {
      tempPorts[index][`html`] = <div>
        <ReactCountryFlag
          countryCode={tempPorts[index].sortname}
          style={{ width: '20px', height: '20px', marginRight: '1rem' }} svg />
        <label className="font-size-12 font-wt-500" >{`${tempPorts[index]["port_name"]} ${tempPorts[index]["name"] ? `(${tempPorts[index]["name"]})` : ''}`}
        </label>
      </div>
    }
    setshowLoader(false)
    console.log(tempPorts, "temp ports---->>>")
    setPorts([...tempPorts])
    setPortLoading([...tempPorts])
    // if (hideSideBarAndHeader) {
    //   const val = tempPorts.filter((ele) => ele.includes(userShipmentDetails.loading_port))
    //   console.log(val, "this is valllll here")
    // }
    setPortUnloading([...tempPorts])
  }

  function processHSCodesFilter(result) {
    for (let index = 0; index < result.length; index++) {
      result[index]["labelHtml"] = <div>
        <label className="font-size-13 font-wt-500" >{`${result[index]["HS_CODE"]} - ${result[index]["Description"]}`}</label>
      </div>
    }
    return result
  }


  useEffect(() => {
    call('POST', 'getAllHsCodes', {}).then(res => {
      res = processHSCodesFilter(res)
      setCommList([...res])
      setCommFilterList([...res])
    })
    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    getContractList()
    call('post', 'getusercommoditycategory', { userId: userId }).then((result) => {
      console.log("API result getusercommoditycategory in comm modal:-->", result);
      setCommCategory(result);
    }).catch((e) => {
      console.log("Error while querying getusercommoditycategory:", e);
    })
    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

  }, [])

  useEffect(() => {
    getShippers()
  }, [])

  const getShippers = async () => {
    setshowLoader(true)
    let shipperApiResp = await call('POST', "getShippers", {})
    for (let index = 0; index < shipperApiResp.length; index++) {
      const element = shipperApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
    }
    setshowLoader(false)
    setShipperList([null].concat(shipperApiResp))
  }
  const [contractcom, setcontractcom] = useState([])

  async function getContractList() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getContracts', {
      "currentPage": 1,
      "resultPerPage": 1000,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    })
    // console.log("apiResppppppppppppppppppppppppppppppppp", apiResp);
    if (apiResp?.contractdata?.length) {
      // if (userShipmentDetails) {
      //   const filterval = apiResp?.contractdata.filter((ele) => ele.contract_number === userShipmentDetails.contract_number)
      //   console.log(filterval, "filterval--->>>>")
      //   setContractList(filterval)

      // } else {


      setContractList(apiResp?.contractdata)

    }
    setshowLoader(false)
  }


  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === "commodityContract" && event.target.value) {
      setshowLoader(true)
      let selectedContract = await call('POST', 'getcontractdetails', { "contract_number": event.target.value, "clientType": traderActiveType })
      selectedContract = selectedContract[0]
      // console.log("selectedContracttttttttttttttttttttttttt", selectedContract);
      setshowLoader(false)
      let loadingPort = await call('POST', 'getCountryPort', { portCkey: selectedContract.loading_port })
      let unloadingPort = await call('POST', 'getCountryPort', { portCkey: selectedContract.unloading_port })
      setData({
        ...data, [event.target.name]: event.target.value, loadingCountry: countryData.find((i) => {
          if (i.id == selectedContract.load_country.split(":")[1]) {
            return true
          }
        })["name"],
        unloadingCountry: countryData.find((i) => {
          if (i.id == selectedContract.unload_country.split(":")[1]) {
            return true
          }
        })["name"],
        loadingPort: loadingPort?.[0]?.["port_name"],
        unloadingPort: unloadingPort?.[0]?.["port_name"],
        readyToLoad: selectedContract.laycan_start ? moment(selectedContract.laycan_start).format("YYYY-MM-DD") : null,
        commodityPrice: selectedContract.price, commodityPriceUnit: selectedContract.currency?.split(":")[2], incoTerms: selectedContract.contract_type
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name === "deliveryMode") {
      setData({ ...data, [event.target.name]: event.target.value, transportationBy: null })
      setErrors({ ...errors, [event.target.name]: "", transportationBy: "" })
    }
    else if (event.target.name === "unitSystem" && event.target.value) {
      setData({
        ...data, [event.target.name]: event.target.value, weightUnit: (event.target.value.includes('Imperial') ||
          event.target.value.includes('lbs')) ? 'lbs' : 'mt',
        volumeUnit: (event.target.value.includes('ft') ||
          event.target.value.includes('Imperial')) ? 'ft^3' : 'mt^3'
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name === "transportationBy") {
      setData({ ...data, [event.target.name]: event.target.value, containerTruckWagonUldType: null })
      setErrors({ ...errors, [event.target.name]: "", containerTruckWagonUldType: "" })
    }
    else if (event.target.name === "loadingPort" && event.target.value) {
      let selectedPort = portLoading.find((i) => { if (i.port_name === event.target.value) { return true } })
      setData({ ...data, [event.target.name]: event.target.value, loadingCountry: selectedPort.name })
      setErrors({ ...errors, [event.target.name]: "", loadingCountry: "" })
    }
    else if (event.target.name === "unloadingPort" && event.target.value) {
      let selectedPort = portUnloading.find((i) => { if (i.port_name === event.target.value) { return true } })
      setData({ ...data, [event.target.name]: event.target.value, unloadingCountry: selectedPort.name })
      setErrors({ ...errors, [event.target.name]: "", unloadingCountry: "" })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }


  function getSupplierCommodities(event, supplierId) {
    event.persist();
    setData(data => ({ ...data, "commodity": "" }));
    if (!event.target.value) {
      setsupplierCommodityList([])
      return null
    }
    call('post', 'getusercommoditylist', { "userId": supplierId, "commCatId": event.target.value === "All" ? "" : event.target.value }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      setsupplierCommodityList(result)
    }).catch((e) => {
      console.log('error in getusercommoditylist', e);
    })
  }



  function handleValidation() {
    let validationArr = []
    let err = {}
    if (tab === 0) {
      validationArr = ["unitSystem", "commodity", "cargoType", "deliveryMode", "weight", "loadingPort", "unloadingCountry", "unloadingPort", "incoTerms", "readyToLoad", 'commodityPrice', 'commodityPriceUnit']
      if (data.deliveryMode != "Auto") {
        validationArr.push("transportationBy")
      }
      if (data.transportationBy && (data.transportationBy.includes('FCL') || data.transportationBy.includes('FTL') ||
        data.transportationBy.includes('FWL') || data.transportationBy.includes('ULD'))) {
        validationArr.push("containerTruckWagonUldType")
        validationArr.push("containerTruckWagonUldTypeQuantity")
      }
      else if (data.transportationBy && data.transportationBy.includes('Bulk')) {
        validationArr.push("shipType")
        validationArr.push("loadingRate")
        validationArr.push("dischargeRate")
      }
      else {
        validationArr.push("volume")
      }
    }
    if (tab === 1) {
      let isFinSelected = false
      for (let index = 0; index < ShipperList.length; index++) {
        if (index != 0) {
          const element = ShipperList[index];
          if (element.isChecked) {
            isFinSelected = true
          }
        }
      }
      if (!isFinSelected) {
        toastDisplay("Kindly select atleast 1 shipper", "info")
        err["selectFinancier"] = "Kindly select atleast 1 shipper"
      }
    }
    for (let index = 0; index < validationArr.length; index++) {
      const element = validationArr[index];
      if (!data[element]) {
        err[element] = "Mandatory field"
      }
    }

    setErrors(err)
    if (!Object.keys(err).length) {
      if (tab === 2) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("userId", userId)
        formData.append("userName", userName)
        Object.keys(data).forEach(key => {
          if (!key.includes("Document") && data[key]) {
            formData.append(key, data[key])
          }
        })
        formData.append("shippersList", JSON.stringify(ShipperList))
        formData.append("traderType", traderActiveType)
        if (contractNo) {
          formData.append("contractNo", contractNo)
        }
        call('POST', 'applyForShipmentBooking', formData, "shipment").then(res => {
          setshowLoader(false);
          toggleApplicationSubmitPopup({ show: true, data: {} })
        }).catch(err => { setshowLoader(false); toastDisplay(err || "Something went wrong", "error") })
      }
      else {
        setTab(tab + 1)
      }
    }
  }

  function switchTrader(traderType) {
    setTraderType(traderType);
    window.location.reload()
    setData({ ...data, traderActiveType: traderType })
  }

  function handleShipperSelection(j) {
    let temp = ShipperList
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setShipperList([...temp])
  }

  const handleCommFilterOptions = async (typedInput) => {
    // console.log("typedInput", typedInput);
    let filteredComm = []
    if (typedInput) {
      filteredComm = await call('POST', 'getAllHSCodes', { search: typedInput })
      filteredComm = processHSCodesFilter(filteredComm)
    }
    if (filteredComm.length) {
      setCommFilterList([...filteredComm])
    }
  }

  // console.log("shipperListtttttttttttttttttttttttt", commFilterList);

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredPorts = []
    if (name.includes("Port")) {
      if (typedInput) {
        filteredPorts = ports.filter((i) => {
          if (i.port_name.toLowerCase().includes(typedInput.toLowerCase()) || i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredPorts = ports
      }
    }
    if (name === "loadingPort") {
      setPortLoading([...filteredPorts])
    }
    if (name === "unloadingPort") {
      setPortUnloading([...filteredPorts])
    }
  }

  useEffect(() => {
    if (userShipmentDetails) {
      const matchedContract = contractList.find(
        (contract) => contract.commodity_name === userShipmentDetails.commodity_name
      );
      setData({
        ...data,
        "incoTerms": userShipmentDetails.incoTerm,
        "commodityPrice": userShipmentDetails.price,
        "weight": userShipmentDetails.quantity,
        "loadingPort": userShipmentDetails.loading_port,
        "unloadingPort": userShipmentDetails.unloading_port,
        commodityContract: matchedContract ? matchedContract.commodity_name : null,

      })
    }
  }, [userShipmentDetails, contractList])

  console.log(data, "this is data forinco", contractList)

  return (
    <div>
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup.show} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = '/ShipmentBookingQuotes'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >Shipment Booking </span> has been submitted successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      {shipperOnboardPopup.show ? (
        <FinanceInvoiceModal limitinvoice={shipperOnboardPopup.show} closeSuccess={() => {
          toggleShipperOnboardPopup({ show: false })
        }}>
          <div className="d-flex row justify-content-center" >
            <label className="font-size-16 font-wt-600 text-center w-100 col-12 pb-3" >Enter Shipowner Details</label>
            <div className="col-8 justify-self-center" >
              <NewInput label={"Company Name"} onChange={handleChange} name={"shipperCompanyName"} value={data.shipperCompanyName}
              />
            </div>
            <div className="col-8" >
              <NewInput label={"Email Id"} onChange={handleChange} name={"shipperEmailId"} value={data.shipperEmailId}
              />
            </div>
            <div className="w-100 text-center" >
              <label class="form-check-label text-center font-size-13 font-wt-600" for="sendInvite">
                <input class="form-check-input new-check-box" type="checkbox" name="sendInvite" id="sendInvite"
                  value={!data.sendInvite}
                  onChange={handleChange}
                  checked={data.sendInvite}
                />
                Also add user in my Network</label>
            </div>
            <div className="col-6 mt-3" >
              <button type="button"
                onClick={() => {
                  if (data.shipperCompanyName && data.shipperEmailId) {
                    // onboard user start
                    let reqObject = {
                      "typeId": 5,
                      "cinDocumentName": null,
                      "gstDocumentName": null,
                      "iecDocumentName": null,
                      "panDocumentName": null,
                      "aadharDocumentName": null,
                      "iecDocumentName": null,
                      "panDocumentName": null,
                      "aadharDocumentName": null,
                      "organizationType": "foreign",
                      "companyName": data.shipperCompanyName,
                      "contactPerson": data.shipperCompanyName,
                      "companyAddress": "",
                      "email": data.shipperEmailId,
                      "contactNo": "",
                      "gst_company_detail": null,
                      "iec_company_detail": null,
                      "cin_company_detail": null,
                      "type": "finTech",
                      "referralCode": data.sendInvite ? userData.refercode : "",
                      "password": "Pa$$w0rd!",
                      "passwordConfirm": "Pa$$w0rd!",
                      "termsAndCondition": true,
                      "country": "",
                      "industryType": "",
                      "techType": 3,
                      "companyCity": "",
                      "companyPostalCode": "",
                      "phoneCode": "",
                      "nameTitle": "",
                      "companyState": "",
                      "designation": "",
                      "accountStatus": "inactive"
                    }
                    setshowLoader(true)
                    call('POST', 'registration', reqObject, "user").then((result) => {
                      setshowLoader(false)
                      let tempUpdatedShipperList = ShipperList
                      tempUpdatedShipperList.splice(1, 0, {
                        "name": data.shipperCompanyName,
                        "email": data.shipperEmailId,
                        "id": result.userId,
                        "avgRatings": 0,
                        "reviews": [],
                        "reviewsCount": 0,
                        "logo": "default.png",
                        isAvailable: true,
                        isChecked: true
                      })
                      setShipperList([...tempUpdatedShipperList])
                      toastDisplay("Details saved", "info")
                      toggleShipperOnboardPopup({ show: false })
                      setData({ ...data, shipperCompanyName: "", shipperEmailId: '', sendInvite: false })
                    }).catch(err => {
                      setshowLoader(false)
                      toastDisplay(err || "Something went wrong", "error");
                    })
                  }
                  else {
                    toastDisplay('Fill details to continue', 'info')
                  }
                }}
                className={` new-btn w-100 py-2 px-2  text-white`}>
                {"Submit"}
              </button>
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        <div className="row">
          {!hideSideBarAndHeader && (<SideBarV2 state="ShipmentBookingApply" userTokenDetails={userTokenDetails} />)}
          <main role="main" className={`ml-sm-auto ${navToggleState.status ? "expanded-right" : ""} ${hideSideBarAndHeader ? "col-lg-12" : "col-lg-10"}`}
            id="app-main-div">
            {!hideSideBarAndHeader && (<HeaderV2
              title={"Logistic > Request Quote"}
              userTokenDetails={userTokenDetails} />)}

            <div>
              {!hideSideBarAndHeader && <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabsArr.map((item, index) => {
                  return (
                    <li
                      onClick={() => {
                        if (index < tab) {
                          setTab(index)
                        }
                      }}
                    >
                      <a
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>}

              <div className="card mt-1"
              >
                {tab === 0 ?
                  <div className="d-flex row p-4" >
                    <div className="w-40" >
                      <div className="d-flex row align-items-center mb-4" >
                        <div className="col-4 pt-1" >
                          <label className="font-size-14 font-wt-500 ">Your Role</label>
                        </div>
                        <div className="col-8 row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >
                          <div className='w-auto' onClick={() => {
                            switchTrader("buyer")
                          }}>
                            <input className="form-check-input" type="radio" value={"buyer"} checked={traderActiveType === 'buyer'} />
                            <label className="form-check-label p-0 m-0" >
                              As Importer
                            </label>
                          </div>
                          <div className='w-auto' onClick={() => {
                            switchTrader('seller')
                          }}>
                            <input className="form-check-input" type="radio" value={"seller"} checked={traderActiveType === 'seller'} />
                            <label className="form-check-label p-0 m-0" >
                              As Exporter
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-30 position-relative" >
                      <MultipleSelect singleSelection
                        Label={'Commodity Contract (Optional)'}
                        Id={`commodityContract`}
                        optiondata={contractList}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `commodityContract`, "contract_number"))
                        }}
                        value={
                          data[`commodityContract`]
                            ? contractList.filter(
                              (contract) => contract.commodity_name === data[`commodityContract`]
                            )
                            : []
                        }

                        name={`commodityContract`}
                        labelKey={"contract_name"}
                        valKey={"contract_number"}
                        error={errors[`commodityContract`]}
                      />
                    </div>
                    <div className="w-30 position-relative" >
                      <MultipleSelect singleSelection
                        Label={'Unit System'}
                        Id={`unitSystem`}
                        optiondata={unitSystem}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `unitSystem`, "name"))
                        }}
                        value={data[`unitSystem`] ? [data[`unitSystem`]] : []}
                        name={`unitSystem`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`unitSystem`]}
                      />
                    </div>
                    <label className="font-size-16 font-wt-500 mb-4" >Cargo Details</label>
                    <div className="col-md-6" >
                      <MultipleSelect singleSelection
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleCommFilterOptions(e)
                        }}
                        Label={'Select Commodity'}
                        Id={`commodity`}
                        optiondata={commFilterList}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `commodity`, "Description"))
                        }}
                        value={data[`commodity`] ? [data[`commodity`]] : []}
                        name={`commodity`}
                        labelKey={"labelHtml"}
                        valKey={"Description"}
                        error={errors[`commodity`]}
                      />
                    </div>

                    <label className="font-size-16 font-wt-500 my-3" >Cargo Type</label>
                    <div className="d-flex row mb-3" >
                      {cargoType.map((i, j) => {
                        return (
                          <div
                            onClick={() => {
                              setData({ ...data, cargoType: i.name })
                            }}
                            style={i.name === data.cargoType ? { border: "3px solid #B9EFFF", borderRadius: '20px' } :
                              {
                                boxShadow: "0px 1px 6px #ccc",
                                borderRadius: "20px"
                              }}
                            className="w-20 mx-2 d-flex align-items-center justify-content-center cursor" >
                            <img src={i.img} className="mr-2 cursor" />
                            <label className="font-size-13 font-wt-500 text-dark pt-2 cursor" >{i.name}</label>
                          </div>
                        )
                      })}
                      {errors.cargoType ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{"Select Cargo Type"}</b></div> : ''}
                    </div>

                    <label className="font-size-16 font-wt-500 mb-4 mt-3" >Delivery Details</label>

                    <div className="col-md-4" >
                      <MultipleSelect singleSelection

                        Label={'Delivery Mode'}
                        Id={`deliveryMode`}
                        optiondata={DeliveryMode}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `deliveryMode`, "name"))
                        }}
                        value={data[`deliveryMode`] ? [data[`deliveryMode`]] : []}
                        name={`deliveryMode`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`deliveryMode`]}
                      />
                    </div>
                    {data.deliveryMode != 'Auto' ? <div className="col-md-4" >
                      <MultipleSelect singleSelection
                        Label={'Transportation By'}
                        Id={`transportationBy`}
                        optiondata={data.deliveryMode === "Sea" ? seaTransportation :
                          data.deliveryMode === "Air" ? airTransportation : data.deliveryMode === "Land" ? landTransportation : []}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `transportationBy`, "name"))
                        }}
                        value={data[`transportationBy`] ? [data[`transportationBy`]] : []}
                        name={`transportationBy`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`transportationBy`]}
                      />
                    </div> : null}

                    {data.transportationBy && data.transportationBy.includes('Bulk') ?
                      <>
                        <div className="col-md-4" >
                          <MultipleSelect singleSelection
                            Label={'Ship Type'}
                            Id={`shipType`}
                            optiondata={shipType}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `shipType`, "name"))
                            }}
                            value={data[`shipType`] ? [data[`shipType`]] : []}
                            name={`shipType`}
                            labelKey={"name"}
                            valKey={"name"}
                            error={errors[`shipType`]}
                          />
                        </div>
                        <div className="col-md-4" >
                          <NewInput type={"number"}
                            label={`Loading Rate ${data.weightUnit}/day`} name={'loadingRate'}
                            value={data.loadingRate} error={errors.loadingRate} onChange={handleChange} />
                        </div>
                        <div className="col-md-4" >
                          <NewInput type={"number"}
                            label={`Discharging Rate ${data.weightUnit}/day`} name={'dischargeRate'}
                            value={data.dischargeRate} error={errors.dischargeRate} onChange={handleChange} />
                        </div>
                      </> : null}

                    {data.transportationBy && (data.transportationBy.includes('FCL') || data.transportationBy.includes('FTL') ||
                      data.transportationBy.includes('FWL') || data.transportationBy.includes('ULD')) ?
                      <>
                        <div className="col-md-4" >
                          <MultipleSelect singleSelection
                            Label={data.transportationBy.includes('FCL') ? 'Container Type' :
                              data.transportationBy.includes('FTL') ? 'Truck Type' : data.transportationBy.includes('FWL') ? 'Wagon Type' : 'ULD Container Type'}
                            Id={`containerTruckWagonUldType`}
                            optiondata={data.transportationBy.includes('FCL') ? shipContainerType : data.transportationBy.includes('FTL') ? truckType :
                              data.transportationBy.includes('FWL') ? wagonType : data.transportationBy.includes('ULD') ? uldType : []}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `containerTruckWagonUldType`, "name"))
                            }}
                            value={data[`containerTruckWagonUldType`] ? [data[`containerTruckWagonUldType`]] : []}
                            name={`containerTruckWagonUldType`}
                            labelKey={"name"}
                            valKey={"name"}
                            error={errors[`containerTruckWagonUldType`]}
                          />
                        </div>
                        <div className="col-md-4" >
                          <NewInput type={"number"}
                            label={"Quantity of" + (data.transportationBy.includes("FCL") ? ' Container' :
                              data.transportationBy.includes('FTL') ? ' Truck' : data.transportationBy.includes('FWL') ? ' Wagon' : ' ULD')} name={'containerTruckWagonUldTypeQuantity'}
                            value={data.containerTruckWagonUldTypeQuantity} error={errors.containerTruckWagonUldTypeQuantity} onChange={handleChange} />
                        </div>
                      </> : null}

                    {hideSideBarAndHeader ? <div className="col-md-4" >
                      <NewInput label={`Weight in ${data.weightUnit}`} type={"number"} name={'weight'}
                        value={userShipmentDetails.quantity} error={errors.weight} onChange={handleChange} />
                    </div>
                      : <div className="col-md-4" >
                        <NewInput label={`Weight in ${data.weightUnit}`} type={"number"} name={'weight'}
                          value={data.weight} error={errors.weight} onChange={handleChange} />
                      </div>
                    }
                    {(data.transportationBy && !data.transportationBy.includes('FCL') &&
                      !data.transportationBy.includes('FTL') && !data.transportationBy.includes('FWL') && !data.transportationBy.includes('ULD')
                      && !data.transportationBy.includes('Bulk')) || data.deliveryMode === 'Auto' ?
                      <div className="col-md-4" >
                        <NewInput label={`Volume in ${data.volumeUnit}`} type={"number"} name={'volume'}
                          value={data.volume} error={errors.volume} onChange={handleChange} />
                      </div> : null}

                    {/* <div className="col-md-4" >
                      <MultipleSelect singleSelection
                        Label={'Loading Country'}
                        Id={`loadingCountry`}
                        optiondata={countryData}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `loadingCountry`, "name"))
                          loadPort(multiSelectEventHandler(e, `loadingCountry`, "id"));
                        }}
                        value={data[`loadingCountry`] ? [data[`loadingCountry`]] : []}
                        name={`loadingCountry`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`loadingCountry`]}
                      />
                    </div>
                    <div className="col-md-4" >
                      <MultipleSelect singleSelection
                        Label={'Loading Port'}
                        Id={`loadingPort`}
                        optiondata={portLoading}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `loadingPort`, "port_name"))
                        }}
                        value={data[`loadingPort`] ? [data[`loadingPort`]] : []}
                        name={`loadingPort`}
                        labelKey={"port_name"}
                        valKey={"port_name"}
                        error={errors[`loadingPort`]}
                      />
                    </div> */}

                    {/* <div className="col-md-4" >
                      <MultipleSelect
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "loadingPort")
                        }}
                        Label={"Loading Port"}
                        Id={`loadingPort`}
                        optiondata={portLoading}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `loadingPort`, "port_name"))
                        }}
                        value={data[`loadingPort`] ? [data[`loadingPort`]] : []}
                        name={`loadingPort`}
                        labelKey={"html"}
                        valKey={"port_name"}
                        error={errors[`loadingPort`]}
                      />
                    </div> */}


                    {hideSideBarAndHeader ? (<div className="col-md-4">
                      {console.log("Selected Port:", userShipmentDetails.loading_port)}

                      <MultipleSelect
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "loadingPort");
                        }}
                        Label={"Loading Port"}
                        Id={`loadingPort`}
                        optiondata={ports}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `loadingPort`, "port_name"));
                        }}
                        value={
                          // Check if userShipmentDetails.loading_port exists before trying to use it
                          userShipmentDetails.loading_port && portLoading.find(port =>
                            userShipmentDetails.loading_port?.toLowerCase().includes(port.port_name.toLowerCase())
                          )
                            ? [ports.find(port =>
                              userShipmentDetails.loading_port?.toLowerCase().includes(port.port_name.toLowerCase())
                            ).port_name] // Set matched port_name as value
                            : []
                        }
                        name={`loadingPort`}
                        labelKey={"port_name"} // Ensure the label is using the correct key for display
                        valKey={"port_name"}   // Ensure the value is mapped to port_name as well
                        error={errors[`loadingPort`]}
                      />

                    </div>)
                      :
                      <div className="col-md-4">
                        <MultipleSelect
                          filterOption={() => true}
                          onInputChange={(e) => {
                            handleFilterOptions(e, "loadingPort")
                          }}
                          Label={"Loading Port"}
                          Id={`loadingPort`}
                          optiondata={portLoading}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `loadingPort`, "port_name"))
                          }}
                          value={data[`loadingPort`] ? [data[`loadingPort`]] : []}
                          name={`loadingPort`}
                          labelKey={"html"}
                          valKey={"port_name"}
                          error={errors[`loadingPort`]}
                        />
                      </div>
                    }
                    {hideSideBarAndHeader ? (<div className="col-md-4">
                      <MultipleSelect
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "unloadingPort")
                        }}
                        Label={"Unloading Port"}
                        Id={`unloadingPort`}
                        optiondata={portUnloading}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `unloadingPort`, "port_name"))
                        }}
                        value={
                          // Use includes to find a matching port name
                          userShipmentDetails.loading_port && portLoading.find(port => userShipmentDetails.unloading_port.toLowerCase().includes(port.port_name.toLowerCase()))
                            ? [portLoading.find(port => userShipmentDetails.unloading_port.toLowerCase().includes(port.port_name.toLowerCase())).port_name] // Set matched port_name as value
                            : []
                        }
                        name={`unloadingPort`}
                        labelKey={"html"}
                        valKey={"port_name"}
                        error={errors[`unloadingPort`]}
                      />
                    </div>) :
                      <div className="col-md-4">
                        <MultipleSelect
                          filterOption={() => true}
                          onInputChange={(e) => {
                            handleFilterOptions(e, "unloadingPort")
                          }}
                          Label={"Unloading Port"}
                          Id={`unloadingPort`}
                          optiondata={portUnloading}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `unloadingPort`, "port_name"))
                          }}
                          value={data[`unloadingPort`] ? [data[`unloadingPort`]] : []}
                          name={`unloadingPort`}
                          labelKey={"html"}
                          valKey={"port_name"}
                          error={errors[`unloadingPort`]}
                        />
                      </div>

                    }

                    {/* <div className="col-md-4" >
                      <MultipleSelect singleSelection

                        Label={'Unloading Country'}
                        Id={`unloadingCountry`}
                        optiondata={countryData}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `unloadingCountry`, "name"))
                          unloadPort(multiSelectEventHandler(e, `unloadingCountry`, "id"))
                        }}
                        value={data[`unloadingCountry`] ? [data[`unloadingCountry`]] : []}
                        name={`unloadingCountry`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`unloadingCountry`]}
                      />
                    </div>
                    <div className="col-md-4" >
                      <MultipleSelect singleSelection

                        Label={'Unloading Port'}
                        Id={`unloadingPort`}
                        optiondata={portUnloading}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `unloadingPort`, "port_name"))
                        }}
                        value={data[`unloadingPort`] ? [data[`unloadingPort`]] : []}
                        name={`unloadingPort`}
                        labelKey={"port_name"}
                        valKey={"port_name"}
                        error={errors[`unloadingPort`]}
                      />
                    </div> */}


                    {hideSideBarAndHeader ? <div className="col-md-4" >
                      <MultipleSelect singleSelection

                        Label={'Inco Terms'}
                        Id={`incoTerms`}
                        optiondata={IncoTerms}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `incoTerms`, "name"))
                        }}
                        value={userShipmentDetails.incoTerm ? userShipmentDetails.incoTerm : []}
                        name={`incoTerms`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`incoTerms`]}
                      />
                    </div> :
                      <div className="col-md-4" >
                        <MultipleSelect singleSelection

                          Label={'Inco Terms'}
                          Id={`incoTerms`}
                          optiondata={IncoTerms}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `incoTerms`, "name"))
                          }}
                          value={data[`incoTerms`] ? [data[`incoTerms`]] : []}
                          name={`incoTerms`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`incoTerms`]}
                        />
                      </div>}
                    <div className="col-md-4">
                      <NewInput

                        type={"date"}
                        label={'Ready to Load'}
                        onChange={handleChange}
                        value={data['readyToLoad']}
                        name={`readyToLoad`}
                        error={errors[`readyToLoad`]}
                      />
                    </div>

                    {hideSideBarAndHeader ? <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Total Shipment Value"}
                        selectData={currencyData}
                        selectName={"commodityPriceUnit"} selectValue={data.commodityPriceUnit}
                        optionLabel={"code"} optionValue={'code'}
                        name={'commodityPrice'} value={data.commodityPrice}
                        error={errors.commodityPrice || errors.commodityPriceUnit}
                        onChange={handleChange} />
                    </div> : <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Total Shipment Value"}
                        selectData={currencyData}
                        selectName={"commodityPriceUnit"} selectValue={data.commodityPriceUnit}
                        optionLabel={"code"} optionValue={'code'}
                        name={'commodityPrice'} value={data.commodityPrice}
                        error={errors.commodityPrice || errors.commodityPriceUnit}
                        onChange={handleChange} />
                    </div>}

                  </div> : null}

                {tab === 1 ? (
                  <div className="d-flex row px-4 pt-4" >
                    <label className='font-wt-500 font-size-16'>{`Select any ${ShipperList.length - 1} shippers`}</label>
                    <div className="p-0 m-0 d-flex mx-3 mt-2">
                      <div>
                        <img
                          onClick={() => { setData({ ...data, requestfromOtherMarketplace: !data.requestfromOtherMarketplace }) }}
                          className='cursor'
                          src={`assets/images/${data.requestfromOtherMarketplace ? 'checked-green' : 'empty-check'}.png`} />
                      </div>
                      <label className='font-wt-600 font-size-16 text-color1 w-100 mx-3'>Request quote from other marketplace</label>
                    </div>
                    {/* <label className="font-wt-400 font-size-14 " >{'If you have your own shipper, please provide their details by '}
                      <label
                        onClick={() => { window.open(window.location.origin + "/registration?referralCode=" + userData.refercode, "_blank") }}
                        className="font-wt-600 font-size-14 text-color1 cursor">{' clicking here'}</label>, and we will send your application to them as well.</label> */}
                    <div className="row mb-3">
                      {
                        ShipperList.map((key, index) => {
                          if (!key) {
                            return (
                              <div
                                onClick={() => {
                                  toggleShipperOnboardPopup({ show: true })
                                }}
                                className="col-4 my-3 cursor">
                                <div className={`card p-3 col-12 selectfinancercard h-100 justify-content-center cursor`}>
                                  <img className="cursor align-self-center mb-4" width={40} src="/assets/images/add more.png" />
                                  <label className="cursor font-size-15 font-wt-600 text-center text-color1" >{"Send application to your shipper"}</label>
                                </div>
                              </div>
                            );
                          }
                          else {
                            return (
                              <div className="col-4 my-3">
                                <div className="d-flex ">
                                  <div class="custom-control ps-0 custom-checkbox form-check">
                                    <label class="form-check-label" for={"flexCheckChecked" + index}></label>
                                  </div>
                                  <div className={`card p-3 col-12 ${key.isAvailable ? "selectfinancercard" : 'disabledFinCard'}`}>
                                    {key.isRecommened ? (
                                      <div style={{ right: '-1.4rem' }} className="position-absolute" >
                                        <img src="assets/images/recommended_label_updated.svg" />
                                      </div>
                                    ) : null}

                                    <div className="d-flex row mt-3 ">
                                      <div className="col-2" >
                                        {key.logo ? (<img
                                          style={{ maxWidth: '120px', maxHeight: '40px' }}
                                          className="" src={avatarUrl(key.logo)} alt="" />) : null}
                                      </div>
                                      <div className="col-8" >
                                        <label
                                          // style={{ paddingRight: '4rem' }} 
                                          className="font-size-14 font-wt-600 pl-2" >{key.name}</label>
                                      </div>
                                      <div className="col-2 p-0 m-0">
                                        <img
                                          onClick={
                                            () => {
                                              if (key.isAvailable || environment === "dev") {
                                                handleShipperSelection(index)
                                              } else {
                                                toastDisplay("Action not availble", "info")
                                              }
                                            }
                                          }
                                          className='cursor'
                                          src={`assets/images/${ShipperList?.[index]["isChecked"] ? 'checked-green' : 'empty-check'}.png`} />
                                      </div>
                                    </div>

                                    <div className="d-flex px-2 pt-4 pb-1">
                                      <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{key.avgRatings.toFixed(2)}</p>
                                        <span>
                                          <img className={`${key.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                          <img className={`${key.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                          <img className={`${key.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                          <img className={`${key.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                          <img className={`${key.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        </span>
                                      </div>
                                      <div
                                        onClick={async () => {
                                          // Get getUserTradeInfoWithFinancier
                                          let temp = key
                                          temp["show"] = true
                                          toggleReviewScrollIndex(0)
                                          setshowLoader(true)
                                          temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: key.id, sellerId: userId }) }
                                          setshowLoader(false)
                                          setSeeReview(temp)
                                        }}
                                        className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{key.reviewsCount}</p><p className="font10 font-size-14 font-wt-500">See reviews</p></div>
                                    </div>
                                    {/* <p
                                    onClick={() => {
                                      setSelectedChat({
                                        receiverName: key.name,
                                        receiverId: key.id,
                                        userId: userId,
                                        isChatOpen: true
                                      })
                                    }}
                                     className="cursor"
                                  ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 font-wt-500 text-dark ms-2">Chat with financer</span></p> */}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      }
                    </div>
                  </div>
                ) : null}

                {tab === 2 ?
                  <div className='p-4'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Application Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            if (item.val) {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {item.val ? (item.unit ? `${item.unit} ` : "") + (item.val) : "NA"}</span> </p>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Shipper selected</u></label>
                        <div className="d-flex gap-4 my-3 flex-wrap">
                          {ShipperList.map((key) => {
                            if (key?.isChecked) {
                              return (
                                <label key={key.name}
                                  className="browse-div cursor text-center m-0 w-auto border-finance text-dark" >
                                  {key.name}
                                </label>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  : null}

                <div className="pb-4 px-4">
                  <button type="button"
                    onClick={() => handleValidation()}
                    className={` new-btn w-17 py-2 px-2  text-white`}>
                    {tab === 2 ? "Submit" : "Continue"}
                  </button>
                </div>

              </div>
            </div>
          </main>

        </div>
      </div>
    </div>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyForBooking)