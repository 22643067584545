import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import CommercialInvoice from "./CommercialInvoice";
import ProformaInvoice from "./ProformaInvoice";
import call from "../../service";
import moment from "moment";
import { Action } from "../myCounterPartComp/action";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import { getDocDetails } from "../../utils/myFunctions";
import toastDisplay from "../../utils/toastNotification";
import PurchaseOrder from "./PurchaseOrder";
import Po from "../contractManagement/po";
import TransactionDetails from "../contractManagement/transactionView";
import TimelineCard from "../contractManagement/cardForTimeline";
import TransactionTimeline from "../contractManagement/transactionTimeline";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export let poTemplateTypes = [
  { name: 'Purchase Order', src: '/assets/images/po_template.PNG' }
]

const EPO = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const [selectedSeller, setSelectedSeller] = useState("")
  const [showTransactionDetails, setShowTransactionDetails] = useState(false)
  const [clickedDocForTD, setClickedDocForTD] = useState({})
  const [editDocument, setEditDocument] = useState({})
  const [showTimeline, setShowTimeline] = useState(false)

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({ show: false, data: { type: '' } })
  const [dbData, setDbData] = useState([])
  const [page, setPage] = useState(1)
  const [action, setAction] = useState({ show: false, index: null })
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({})
  const [recentTemplates, setRecentTemplates] = useState([])


  const [tablecol, setTablecol] = useState([
    { subColumns: "Document No", isChecked: true },
    { subColumns: "Document Type", isChecked: true },
    { subColumns: "Creation Date", isChecked: true },
    { subColumns: "Deal Owner", isChecked: true },
    { subColumns: "Company", isChecked: true },
    { subColumns: "Total Amount", isChecked: true },
    { subColumns: "Delivery Date", isChecked: true },
    { subColumns: "Origin Address", subColumnStyle: { width: '14%' }, isChecked: true },
    { subColumns: "Destination Address", subColumnStyle: { width: '13%' }, isChecked: true },
    { subColumns: "Actions", subColumnStyle: { width: '5%' }, isChecked: true }
  ])

  useEffect(() => {
    call("POST", 'getRecentlyUsedEdocs', { userId, onlyPO: true }).then(res => {
      setRecentTemplates(res)
    })
    setFilterData({
      "Template Type": {
        "accordianId": 'invoiceType',
        type: "checkbox",
        data: poTemplateTypes,
        labelName: "name"
      }
    })
  }, [])



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("edocsPOGenerator");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      currentPage: page,
      onlyPO: true,
      byDocName: ['Purchase Order'],
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "invoiceTypee") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
      }
    }
    // call('POST', 'getEdocs', objectAPI).then(async (result) => {
    call('POST', 'getSalesPurchaseQuotation', objectAPI).then(async (result) => {
      console.log('running getEdocs api-->', result);
      setDbData(result.data);
      setCount(result.data.length);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getEdocs', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  const handleCreateNew = () => {


    if (booladd || userPermissions == null) {
      setSelectedSeller({})
      toggleTemplateSelectionForm({ show: true, data: { type: "" } })
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }
  const handleRedirect = (item) => {
    setEditDocument({ ...item.details, docType: item.docType, idFromDB: item.id, sellerId: item.sellerId, buyerId: item.buyerId, itemStatus: item.status, transaction_timeline: item.transaction_timeline });
    setClickedDocForTD(item)
    setShowTransactionDetails(true)
  };




  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    // Sync `updatedData` with `tablecol` only when modal is opened for the first time or `tablecol` changes
    useEffect(() => {
      if (showModal) {
        setUpdatedData([...tablecol]);
      }
    }, [showModal]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      // Toggle the checked state of the column
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setUpdatedData(items); // Update reordered columns
    };

    const handleSave = () => {
      // Persist `updatedData` to `tablecol`
      setTablecol([...updatedData]);
      setShowModal(false); // Close modal
    };

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
            width: "13rem",
            paddingTop: "9px",
            paddingBottom: "9px"
          }}
        >
          Customize Columns
        </button>

        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {updatedData.map((ele, index) => (
                            <Draggable key={ele.subColumns} draggableId={ele.subColumns} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="mb-1"
                                    style={{
                                      border: "2px solid #84d4ef",
                                      padding: "8px",
                                      borderRadius: "0.5rem",
                                      backgroundColor: snapshot.isDragging
                                        ? "#d25de9"
                                        : "white",
                                      color: snapshot.isDragging ? "white" : "black",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <img
                                      onClick={() => handleCheck(index)}
                                      className="cursor ml-8"
                                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                                        }.png`}
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                      alt={ele.isChecked ? "Checked" : "Unchecked"}
                                    />
                                    <label style={{ marginLeft: "0.5rem" }}>{ele.subColumns}</label>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };





  return (
    <>


      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="edocsPOGenerator" userTokenDetails={userTokenDetails} />

          <main role="main" className={" ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={templateSelectionForm.show ? `E Docs > PO Generator > Create PO (${templateSelectionForm.data?.type})` : `E Docs > PO Generator`}
              userTokenDetails={userTokenDetails} />

            {showTransactionDetails || showTimeline ?
              <>
                {showTransactionDetails && !showTimeline && <TransactionDetails setShowTimeline={setShowTimeline} showTimelineBtn={false} handleGoBack={setShowTransactionDetails} editDocument={editDocument} />}
                {
                  showTimeline && !showTransactionDetails && <TransactionTimeline userId={userId} setShowTimeline={setShowTimeline} handleGoBack={setShowTimeline} editDocument={editDocument} />
                }
              </>
              :
              <div>
                {templateSelectionForm.data.type ? <>
                  {templateSelectionForm.data.type === 'Purchase Order' ? <>
                    <a
                      onClick={() => {
                        toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                        setSelectedInvoiceData({})
                      }}
                      className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                    {/* <PurchaseOrder selectedInvoiceData={selectedInvoiceData} userTokenDetails={userTokenDetails} userPermissions={userPermissions} /> </> : */}

                    <Po
                      // setSelectedDocument={setSelectedDocument}
                      docType={"Purchase Order"}
                      isPofromEDocs={true}
                      editDocument={false}
                      setrefresh={setRefresh}
                      setSelectedBuyer={setSelectedSeller}
                      handleGoBack={() => {
                        toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                        setSelectedInvoiceData({})
                      }}
                      buyerDetails={{
                        companyName: userTokenDetails.userName || "",
                        address: "",
                        companyWebsite: userTokenDetails.companyWebsite || "",
                        companyEmailId: userTokenDetails.email || "",
                        contactName: userTokenDetails.contactPerson,
                        companyContactNo: `+${userTokenDetails.phone_code} ${userTokenDetails.contact_number}` || ""
                      }}
                      sellerDetails={{
                        companyId: selectedSeller?.id,
                        companyName: selectedSeller?.company_name || "",
                        address: selectedSeller?.user_address || "",
                        companyWebsite: selectedSeller?.companyWebsite || "",
                        companyEmailId: selectedSeller?.user_name || "",
                        contactName: selectedSeller?.contact_person,
                        companyContactNo: selectedSeller["contact_number"] ? `+${selectedSeller?.phone_code} ${selectedSeller?.contact_number}` : ""
                      }}
                      selectedInvoiceData={{}} userTokenDetails={userTokenDetails}

                    /> </> : null


                  }
                </> : <>
                  {templateSelectionForm.show ?
                    <>
                      <a
                        onClick={() => {
                          toggleTemplateSelectionForm({ show: false, data: { type: "" } })
                          setSelectedInvoiceData({})
                        }}
                        className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                      <div className='card mt-1 px-2' >
                        <label className='font-size-16 font-wt-600 w-100 text-center pt-4' >Select Template</label>
                        <div className='d-flex gap-4 p-4 flex-wrap' >
                          {poTemplateTypes.map((i, j) => {
                            return (
                              <div
                                onClick={() => {
                                  toggleTemplateSelectionForm({ show: true, data: { type: i.name } })
                                }}
                                className='w-20 cursor' >
                                <img className='contractTemplateImg2' style={{}} src={i.src} />
                                <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                              </div>
                            )
                          })}
                        </div>
                        {recentTemplates?.length ? <>
                          <hr />
                          <label className='font-size-16 font-wt-600 w-100 pt-4 px-4 m-0' >Recently Used Templates</label>
                          <div className='d-flex gap-4 p-4 flex-wrap' >
                            {poTemplateTypes.map((i, j) => {
                              let isRecentlyUsed = recentTemplates?.find(m => {
                                if (m.template === i.name) {
                                  return true
                                }
                              })?.["template"]
                              if (isRecentlyUsed) {
                                return (
                                  <div
                                    onClick={() => {
                                      toggleTemplateSelectionForm({ show: true, data: { type: i.name } })
                                    }}
                                    className='w-20 cursor' >
                                    <img className='contractTemplateImg2' style={{}} src={i.src} />
                                    <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                                  </div>
                                )
                              }
                            })}
                          </div></> : null}
                      </div>
                    </> : <>
                      <div className='filter-div position-relative'>
                        <Filter isAdditionalButton
                          filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                          showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}>
                          <div className="d-flex gap-4">
                            <ColumnSelector tablecol={tablecol} setTablecol={setTablecol} />
                            <button className={`new-btn  py-2 px-2 text-white cursor`}
                              // onClick={() => {
                              //   toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                              // }}
                              onClick={handleCreateNew}
                            >Create New</button>
                          </div>
                        </Filter>
                      </div>
                      <div>
                        <NewTablev2 columns={tablecol.filter(col => col.isChecked)}>
                          {dbData.map((item, j) => {
                            {/* return (
                              <tr>
                                
                                <td style={{ width: "10%" }}><label className='font-size-13 qwertyu font-wt-400 text-break' >{item.docId}  </label></td>
                                <td><label className='font-size-13 font-wt-400 text-break' >{item.docType || "-"}</label></td>
                                <td><label className='font-size-13 font-wt-400 text-break' >{moment(item.createdAt).format('YYYY-MM-DD')}</label></td>

                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.clientContactName || "-"}</label></td>

                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.shipToCompanyName || "-"}</label></td>
                               
                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.invTotalAmount ? item.details?.invTotalAmount + " " + item.details?.invCurrency : "NA"}</label></td>

                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details.deliveryDate ? moment(item.details.deliveryDate).format('YYYY-MM-DD') : "-"}</label></td>
                                <td><label className="font-size-13 font-wt-400" >{item.details?.clientAddress || "-"}</label></td>
                                <td><label className="font-size-13 font-wt-400" >{item.details?.finalDestination || "-"}</label></td>
                                <td
                                  
                                  className='position-relative'>
                                  <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                    onClick={() => setAction({ show: true, index: j })}
                                    aria-hidden="true"></i>
                                  {action.show && action.index === j ? (
                                    <Action
                                      id={j}
                                      onDismiss={() => setAction({ show: false, index: j })}
                                      options={[
                                        {
                                          name: "View", onClick: async () => {
                                            handleRedirect(item)
                                          }
                                        },
                                        // {
                                        //   name: "Edit", onClick: async () => {
                                        //     let docDetails = await getDocDetails(item.docId)
                                        //     if (Object.keys(docDetails)?.length) {
                                        //       setSelectedInvoiceData(item)
                                        //       toggleTemplateSelectionForm({ show: true, data: { type: item.template } })
                                        //     }
                                        //     else {
                                        //       toastDisplay("No document found", "error")
                                        //     }
                                        //   }
                                        // },
                                        // {
                                        //   name: "Download", onClick: async () => {
                                        //     let docDetails = await getDocDetails(item.docId)
                                        //     if (Object.keys(docDetails)?.length) {
                                        //       toggleDowdDoc({ show: true, doc: docDetails })
                                        //     }
                                        //     else {
                                        //       toastDisplay("No document found", "error")
                                        //     }
                                        //   }
                                        // }
                                      ]} />
                                  ) : null}
                                </td>
                              </tr>
                            ) */}

                            return (
                              <tr key={j}>
                                {tablecol.map((col) => {
                                  if (col.isChecked) {
                                    return (
                                      <td key={col.subColumns}>
                                        {col.subColumns === "Document No" && (<label className='font-size-13 qwertyu font-wt-400 text-break' >{item.docId}</label>)}
                                        {col.subColumns === "Document Type" && (<label className='font-size-13 font-wt-400 text-break' >{item.docType || "-"}</label>)}
                                        {col.subColumns === "Creation Date" && (<label className='font-size-13 font-wt-400 text-break' >{moment(item.createdAt).format('YYYY-MM-DD')}</label>)}

                                        {col.subColumns === "Deal Owner" && (<label className='font-size-13 font-wt-400 text-break' >{item.details?.clientContactName || "-"}</label>)}

                                        {col.subColumns === "Company" && (<label className='font-size-13 font-wt-400 text-break' >{item.details?.shipToCompanyName || "-"}</label>)}
                                        {/* <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.enquiryNo || "-"}</label></td> */}
                                        {col.subColumns === "Total Amount" && (<label className='font-size-13 font-wt-400 text-break' >{item.details?.invTotalAmount ? item.details?.invTotalAmount + " " + item.details?.invCurrency : "NA"}</label>)}

                                        {col.subColumns === "Delivery Date" && (<label className='font-size-13 font-wt-400 text-break' >{item.details.deliveryDate ? moment(item.details.deliveryDate).format('YYYY-MM-DD') : "-"}</label>)}
                                        {col.subColumns === "Origin Address" && (<label className="font-size-13 font-wt-400" >{item.details?.clientAddress || "-"}</label>)}
                                        {col.subColumns === "Destination Address" && (<label className="font-size-13 font-wt-400" >{item.details?.finalDestination || "-"}</label>)}
                                        {col.subColumns === "Deal Status" && (

                                          <div>
                                            {item.status / 1 == 0 || item.status / 1 == 1 ?
                                              <button type="button"
                                                class={`${item.status / 1 == 0 ? "inprogress" : "docDraft"} text-white border-0 `}>
                                                {item.status / 1 == 1 ? "Draft" : "Created"}
                                              </button> : item.status / 1 == 4 ? <button type="button"
                                                class={`expiredStatus text-white border-0 `}>
                                                {"Cancelled"}

                                              </button> : null}</div>

                                        )}
                                        {col.subColumns === "Action" && (

                                          <label

                                            className='position-relative'>
                                            <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                              onClick={() => setAction({ show: true, index: j })}
                                              aria-hidden="true"></i>
                                            {action.show && action.index === j ? (
                                              <Action
                                                id={j}
                                                onDismiss={() => setAction({ show: false, index: j })}
                                                options={[
                                                  {
                                                    name: "View", onClick: async () => {
                                                      handleRedirect(item)
                                                    }
                                                  },
                                                  // {
                                                  //   name: "Edit", onClick: async () => {
                                                  //     let docDetails = await getDocDetails(item.docId)
                                                  //     if (Object.keys(docDetails)?.length) {
                                                  //       setSelectedInvoiceData(item)
                                                  //       toggleTemplateSelectionForm({ show: true, data: { type: item.template } })
                                                  //     }
                                                  //     else {
                                                  //       toastDisplay("No document found", "error")
                                                  //     }
                                                  //   }
                                                  // },
                                                  // {
                                                  //   name: "Download", onClick: async () => {
                                                  //     let docDetails = await getDocDetails(item.docId)
                                                  //     if (Object.keys(docDetails)?.length) {
                                                  //       toggleDowdDoc({ show: true, doc: docDetails })
                                                  //     }
                                                  //     else {
                                                  //       toastDisplay("No document found", "error")
                                                  //     }
                                                  //   }
                                                  // }
                                                ]} />
                                            ) : null}
                                          </label>)}
                                      </td>
                                    )
                                  }
                                  return null
                                })}
                              </tr>
                            )

                          })}
                        </NewTablev2>
                        <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
                      </div>
                    </>}
                </>}
              </div>}
          </main>

        </div>
      </div>

    </>
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(EPO)