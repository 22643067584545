import { useState, useEffect } from "react"
import PurchaseOrder from "./po";
import SalesQuotaion from "./salesQuotaion";
import SalesEnquiry from './se'
import Oc from "./oc";
import requestForQuo from "./requestForQuo";
import Invoice from "./invoice";
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import { ToastContainer } from 'react-toastify';
import RequestForQuo from "./requestForQuo";
import DeliveryChallan from "../Edocs/DeliveryChallan";
import ChallanOrder from "../Edocs/ChallanOrder";
import Inward from "./inward";

export default function AddDocument({ navToggleState, setEditDocument, setSelectedDocument, setSelectedSeller, setrefresh, editDocument = {}, type, userTokenDetails, setSelectedBuyer, setAddNewDocument, buyer = {}, seller = {} }) {
  const [hideButtons, setHideButtons] = useState(true)
  const [searchParams, setSearchParams] = useState({})
  console.log("in add doc", editDocument)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setSearchParams({ docType: params.get('docname'), docID: params.get('docid') });
    console.log("aaa searchParam  ", params.get('doctype'), params.get('docid'))

  }, []);



  const handleGoBack = () => {
    setAddNewDocument(false)
    setHideButtons(true)
  }
  const commonPropsSales = {
    setEditDocument,
    setSelectedDocument,
    editDocument,
    setrefresh,
    handleGoBack,
    setSelectedBuyer,
    buyerDetails: !editDocument && {
      companyId: buyer?.id,
      companyName: buyer?.company_name || "",
      address: buyer?.user_address || "",
      companyWebsite: buyer?.companyWebsite || "",
      companyEmailId: buyer?.user_name || "",
      contactName: buyer?.contact_person,
      companyContactNo: buyer["contact_number"]
        ? `+${buyer?.phone_code} ${buyer?.contact_number}`
        : "",
    },
    sellerDetails: !editDocument && {
      companyName: userTokenDetails.userName || "",
      address: seller.user_address || "",
      companyWebsite: userTokenDetails.companyWebsite || "",
      companyEmailId: userTokenDetails.email || "",
      contactName: userTokenDetails.contactPerson,
      companyContactNo: `+${userTokenDetails.phone_code} ${userTokenDetails.contact_number}` || "",
    },
    selectedInvoiceData: {},
    userTokenDetails,
  };
  return (
    <>
      {searchParams.docType && searchParams.docID ?
        (<div>
          <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
          <div className="container-fluid">
            <div className="row">
              <SideBarV2 state="contractManagement" userTokenDetails={userTokenDetails} />
              <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
                <HeaderV2
                  title={"Contract Management"}
                  userTokenDetails={userTokenDetails}
                />
                {/* <h1>Hello</h1> */}
                <SalesQuotaion docType="Sales Quotation" {...commonPropsSales} />

              </main>
            </div>
          </div>
        </div>


        )

        :
        (<div>
          {type === "Sales Quotation" && <SalesQuotaion docType="Sales Quotation" {...commonPropsSales} />}
          {type === "Invoice" && <Invoice docType={"Invoice"} {...commonPropsSales} />}
          {type === "Delivery Challan" && <ChallanOrder docType={"Delivery Challan"} fromSP={true} {...commonPropsSales} />}

          {type === "Order Confirmation" && <Oc docType={"Order Confirmation"} {...commonPropsSales} />}
          {type === "Sales Enquiry" && <SalesEnquiry docType={"Sales Enquiry"} {...commonPropsSales} />}
          {type === "Request Quotation" && <RequestForQuo docType={"Request Quotation"} setSelectedDocument={setSelectedDocument}

            editDocument={editDocument}
            setrefresh={setrefresh}
            setSelectedBuyer={setSelectedSeller}
            handleGoBack={handleGoBack}
            buyerDetails={!editDocument && {
              companyName: userTokenDetails.userName || "",
              address: seller.user_address || "",
              companyWebsite: userTokenDetails.companyWebsite || "",
              companyEmailId: userTokenDetails.email || "",
              contactName: userTokenDetails.contactPerson,
              companyContactNo: `+${userTokenDetails.phone_code} ${userTokenDetails.contact_number}` || ""
            }}
            sellerDetails={!editDocument && {
              companyId: seller?.id,
              companyName: seller?.company_name || "",
              address: seller?.user_address || "",
              companyWebsite: seller?.companyWebsite || "",
              companyEmailId: seller?.user_name || "",
              contactName: seller?.contact_person,
              companyContactNo: seller["contact_number"] ? `+${seller?.phone_code} ${seller?.contact_number}` : ""
            }}
            selectedInvoiceData={{}} userTokenDetails={userTokenDetails} />}

          {
            type === "Purchase Order" && <PurchaseOrder
              setSelectedDocument={setSelectedDocument}
              docType={"Purchase Order"}
              editDocument={editDocument}
              setrefresh={setrefresh}
              setSelectedBuyer={setSelectedSeller}
              handleGoBack={handleGoBack}
              buyerDetails={!editDocument && {
                companyName: userTokenDetails.userName || "",
                address: seller.user_address || "",
                companyWebsite: userTokenDetails.companyWebsite || "",
                companyEmailId: userTokenDetails.email || "",
                contactName: userTokenDetails.contactPerson,
                companyContactNo: `+${userTokenDetails.phone_code} ${userTokenDetails.contact_number}` || ""
              }}
              sellerDetails={!editDocument && {
                companyId: seller?.id,
                companyName: seller?.company_name || "",
                address: seller?.user_address || "",
                companyWebsite: seller?.companyWebsite || "",
                companyEmailId: seller?.user_name || "",
                contactName: seller?.contact_person,
                companyContactNo: seller["contact_number"] ? `+${seller?.phone_code} ${seller?.contact_number}` : ""
              }}
              selectedInvoiceData={{}} userTokenDetails={userTokenDetails}
            />
          }
          {
            type === "Purchase Tax Invoice" && <Invoice
              setSelectedDocument={setSelectedDocument}
              docType={"Purchase Tax Invoice"}
              editDocument={editDocument}
              setrefresh={setrefresh}
              setSelectedBuyer={setSelectedSeller}
              handleGoBack={handleGoBack}
              buyerDetails={!editDocument && {
                companyName: userTokenDetails.userName || "",
                address: seller.user_address || "",
                companyWebsite: userTokenDetails.companyWebsite || "",
                companyEmailId: userTokenDetails.email || "",
                contactName: userTokenDetails.contactPerson,
                companyContactNo: `+${userTokenDetails.phone_code} ${userTokenDetails.contact_number}` || ""
              }}
              sellerDetails={!editDocument && {
                companyId: seller?.id,
                companyName: seller?.company_name || "",
                address: seller?.user_address || "",
                companyWebsite: seller?.companyWebsite || "",
                companyEmailId: seller?.user_name || "",
                contactName: seller?.contact_person,
                companyContactNo: seller["contact_number"] ? `+${seller?.phone_code} ${seller?.contact_number}` : ""
              }}
              selectedInvoiceData={{}} userTokenDetails={userTokenDetails}
            />
          }

          {
            type === "Inward Document" && <Inward
              setSelectedDocument={setSelectedDocument}
              docType={"Inward Document"}
              editDocument={editDocument}
              setrefresh={setrefresh}
              setSelectedBuyer={setSelectedSeller}
              handleGoBack={handleGoBack}
              buyerDetails={!editDocument && {
                companyName: userTokenDetails.userName || "",
                address: seller.user_address || "",
                companyWebsite: userTokenDetails.companyWebsite || "",
                companyEmailId: userTokenDetails.email || "",
                contactName: userTokenDetails.contactPerson,
                companyContactNo: `+${userTokenDetails.phone_code} ${userTokenDetails.contact_number}` || ""
              }}
              sellerDetails={!editDocument && {
                companyId: seller?.id,
                companyName: seller?.company_name || "",
                address: seller?.user_address || "",
                companyWebsite: seller?.companyWebsite || "",
                companyEmailId: seller?.user_name || "",
                contactName: seller?.contact_person,
                companyContactNo: seller["contact_number"] ? `+${seller?.phone_code} ${seller?.contact_number}` : ""
              }}
              selectedInvoiceData={{}} userTokenDetails={userTokenDetails}
            />
          }
        </div>)
      }
    </>
  )
}


