import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import { InputForTable, InputWithSelectForTable, NewInput, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import { handleFileCommonFunction, most_used_currencies, printDiv } from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FileInput } from "../../utils/FileInput";
import SignDocument, { signFonts } from "../InvoiceDiscounting/components/SignDocument";
import DropdownSearch from "../tallyReports/Cards/dropdownWithSearch";
import AddNewBuyerPopUp from "./addnewBuyerPopUp";
import PopUPEmailText from "../tallyReports/Cards/paymentView/popUpEmailText";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PopUpforEmail from './popUpforEmail'

// let invItemsTable = [{ subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
// { subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '20%' } },
// { subColumns: "HSN NO.", subColumnStyle: { width: '10%' } },
// { subColumns: "QUANTITY", subColumnStyle: { width: '10%' } },
// { subColumns: "UNIT PRICE", subColumnStyle: { width: '12%' } },
// { subColumns: "TAX %", subColumnStyle: { width: '12%' } },
// { subColumns: "TAX AMOUNT", subColumnStyle: { width: '12%' } },
// { subColumns: "TOTAL PRICE", subColumnStyle: { width: '15%' } }
// ]


let chargesTable = [{ subColumns: "CHARGE TITLE", subColumnStyle: { width: '42.5%' } }, { subColumns: "AMOUNT", subColumnStyle: { width: '45%' } }]

const TransactionDetails = ({ showTimelineBtn, setShowTimeline, setHideButtons, editDocument = {}, userTokenDetails, buyerDetails, sellerDetails, handleGoBack, setSelectedDocument }) => {
  console.log("aaa =doc doc", editDocument)
  // console.log("aaa edit doc ", editDocument)
  let invItemsTable = [
    { subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
    { subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '20%' } },
    { subColumns: "HSN CODE", subColumnStyle: { width: '10%' } },
    { subColumns: "QUANTITY", subColumnStyle: { width: '15%' } },
  ];
  console.log("aaa edit doc ", `${editDocument.docType} - ${editDocument.docNumber}.pdf`)

  // Conditionally include columns for Unit Price, Amount, and Tax if docType is not "Request Quotation"
  if (editDocument?.docType !== "Request Quotation") {
    invItemsTable = invItemsTable.concat([
      { subColumns: "UNIT PRICE", subColumnStyle: { width: '15%' } },
      { subColumns: "TAX %", subColumnStyle: { width: '10%' } },
      { subColumns: "TAX AMOUNT", subColumnStyle: { width: '12%' } },
      { subColumns: "TOTAL AMOUNT", subColumnStyle: { width: '15%' } },
    ]);
  }

  const userTypeId = userTokenDetails?.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails?.email ? userTokenDetails.email : null
  const userId = userTokenDetails?.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails?.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails?.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails?.parent_data ? userTokenDetails.parent_data : null;
  const [showLoader, setshowLoader] = useState(false)
  const [clickedBtn, setClickedBtn] = useState('')

  const [showPopUpForShare, setShowPopUpForShare] = useState(false);

  // #3
  const [data, setData] = useState({
    invCurrency: 'USD', invChargeTitle0: "SUBTOTAL", invChargeTitle1: 'HANDLING', nviChargeTitle2: 'FREIGHT',
    invChargeTitle3: 'MISC.', ...editDocument,
  })
  const [error, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [invOther, setInvOther] = useState([null, null, null, null])
  const [preview, setPreview] = useState({ show: true })
  const [showPopup, togglePopup] = useState(false)
  const [signdoc, setSigndoc] = useState(false);
  const [docNumber, setDocNumber] = useState(editDocument.docNumber || '')
  const [mstCommList, setMstCommList] = useState([])
  const [termsConditions, setTermsConditions] = useState([{ id: Date.now(), value: '' }]);
  const [editSeller, setEditingSeller] = useState(true)
  const [editBuyer, setEditingBuyer] = useState(true)
  const [editingDeliveryLocation, setEditingDeliveryLocation] = useState(true)
  const [editingPos, setEditingPos] = useState(true)
  const [removeDeliveryLocation, setRemoveDeliveryLocation] = useState(false)
  const [removePos, setRemovePos] = useState(false)
  const [changeBuyer, setChangeBuyer] = useState(false)
  const [editingTandC, setEditingTandC] = useState(true)
  const [editingRemarks, setEditingRemarks] = useState(true)
  const [editingInternalNotes, setEditingInternalNotes] = useState(true)
  const [editingClinetNotes, setEditingClinetNotes] = useState(true)
  const [removeRemarks, setRemoveRemarks] = useState(editDocument.remarks ? false : true)
  const [removeTandC, setRemoveTandC] = useState(editDocument.tandc1 ? false : true)
  const [removeInternalNotes, setRemoveInternalNotes] = useState(editDocument.internalNotes ? false : true)
  const [removeClinetNotes, setRemoveClinetNotes] = useState(editDocument.clinetNotes ? false : true)
  const tableColumnsForInward = [{ subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
  { subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '20%' } },
  { subColumns: "HSN NO.", subColumnStyle: { width: '10%' } },
  { subColumns: "QUANTITY", subColumnStyle: { width: '10%' } },
  // { subColumns: "UNIT PRICE", subColumnStyle: { width: '12%' } },
  { subColumns: "DELIVERY DATE", subColumnStyle: { width: '12%' } },
  { subColumns: "DELIVERED", subColumnStyle: { width: '12%' } },
  { subColumns: "DELIVERED NOW", subColumnStyle: { width: '12%' } },
  { subColumns: "BALANCE", subColumnStyle: { width: '12%' } },
  { subColumns: "COMMENTS", subColumnStyle: { width: '12%' } },

  ]

  let jj = -1
  let kk = -1


  // useEffect(() => {
  //   if (buyerDetails && sellerDetails) {
  //     setData(prevData => ({
  //       ...prevData,
  //       companyContactNo: buyerDetails?.companyContactNo,
  //       companyEmailId: buyerDetails?.companyEmailId,
  //       companyWebsite: buyerDetails?.companyWebsite,
  //       originAddress: buyerDetails?.address,
  //       companyName: buyerDetails?.companyName,
  //       finalDestination: sellerDetails?.address,
  //       shipToContactNo: buyerDetails?.companyContactNo,
  //       shipToEmailId: buyerDetails?.companyEmailId,
  //       shipToCompanyName: buyerDetails?.companyName,
  //       shipToContactName: buyerDetails?.contactName,
  //       clientContactName: sellerDetails?.contactName,
  //       clientCompanyName: sellerDetails?.companyName,
  //       clientEmailId: sellerDetails?.companyEmailId,
  //       clientContactNo: sellerDetails?.companyContactNo,
  //       clientAddress: sellerDetails?.address,

  //     }))
  //   };
  // }, [buyerDetails, sellerDetails]);
  useEffect(() => {
    if (editDocument) {
      // setDisableSaveandDraft(false)
      const tandcEntries = Object.entries(editDocument)
        .filter(([key]) => /^tandc\d+$/.test(key)) // Regex to match "tandc1", "tandc2", etc.
        .map(([key, value]) => ({ key, value }));

      setTermsConditions(tandcEntries);
      const itemDescCount = Object.keys(editDocument).filter(key => /^itemDesc\d+$/.test(key)).length;

      setInvItems(itemDescCount ? Array(itemDescCount).fill(null) : [null]);

    }
  }, [editDocument]);



  useEffect(() => {
    const bootstrap = require('bootstrap');
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 100, hide: 10 },
        customClass: 'custom-tooltip',
        placement: "top",
        trigger: "hover"
      });
    });
  }, []);



  const generatePDF = () => {
    const input = document.getElementById("invoiceDiv");

    const pdf = new jsPDF('p', 'mm', 'a4');
    const margin = 5;
    return html2canvas(input, {
      scale: 2, // Higher scale improves the clarity of the PDF
      useCORS: true, // Ensures cross-origin images load correctly
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 0.8); // Compress image to reduce size
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin; // Width with margins
      const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * margin; // Height with margins

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Calculate aspect ratio to fit image within the PDF while maintaining proportions
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgFinalWidth = imgWidth * ratio;
      const imgFinalHeight = imgHeight * ratio;

      // Center the image in the PDF
      const x = (pdf.internal.pageSize.getWidth() - imgFinalWidth) / 2;
      const y = margin; // Top margin

      pdf.addImage(imgData, 'JPEG', x, y, imgFinalWidth, imgFinalHeight);



      // Return the final PDF
      return pdf;
    });
  };
  const handleSendWhatsApp = (type) => {
    setClickedBtn(type)
    setShowPopUpForShare(true);

  };

  const handleSendMail = (type) => {
    setClickedBtn(type)
    setShowPopUpForShare(true);
    // PopUPEmailText would confirm email and message
  };



  const handleDownload = (editDocument) => {
    console.log(editDocument.docType, editDocument.docId)
    if (!editDocument || !editDocument.docType || !editDocument.docId) {
      console.error("Document type or number is missing.");
      return;
    }
    generatePDF().then((pdf) => {
      const pdfBlob = pdf.output('blob');
      console.log(`${editDocument.docType} - ${editDocument.docId}.pdf`)
      const pdfName = `${editDocument.docType} - ${editDocument.docId}.pdf`;
      pdf.save(pdfName);

    });
  };
  return (
    <>
      {
        showPopUpForShare &&
        <PopUpforEmail
          userId={userId}
          setShowPopUpForShare={setShowPopUpForShare}
          selectedCustomer={editDocument}
          docName={`${editDocument.docType} - ${editDocument.docNumber}`}
          shareType={clickedBtn}
          generatePDF={generatePDF}
        />

      }
      {showPopup && <FinanceInvoiceModal limitinvoice={showPopup} setLimitinvoice={togglePopup} closeSuccess={() => togglePopup(false)} >
        <div className="col-md-10 mb-2 ml-5">
          <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>Upload Company Logo</label>
          <FileInput
            onUploadCancel={() => { setData({ ...data, companyLogo: {} }) }} name={"companyLogo"} value={data["companyLogo"]}
            onChange={(event) => {
              let file_type = event.target.files?.[0]?.["type"]?.toLowerCase()
              if (file_type && (file_type.includes("png") || file_type.includes("jpeg"))) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = async (e) => {
                  let fileObj = event.target.files[0]
                  let fileDataUrl = e.target.result
                  fileObj["filebase64"] = fileDataUrl
                  setData({ ...data, [event.target.name]: fileObj })
                  togglePopup(false)
                }
              }
              else {
                setErrors({ ...error, companyLogo: 'Only png & jpeg images are supported' })
              }
            }}
            error={error.companyLogo} isEditable={true} />
          {error.companyLogo ? <div class="text-danger mt-2 font-size-12">
            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
            <b>{error.companyLogo}</b></div> : ''}
        </div>
      </FinanceInvoiceModal>}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='card mt-1  pt-2' >
        <div className=" row m-0 mb-2 p-0 justify-content-end"     >
          <div className="d-flex justify-content-between align-items-center" >
            <div className="ms-3">
              <img onClick={() => {
                handleGoBack(false);
                if (setHideButtons) setHideButtons(true)
              }} class="bg-transparent d-block cursor-pointer me-2" src="assets/images/back.png" alt="Back" height="15" width="20"></img>

            </div>

            <div className=" rounded p-1 w-25 ms-3 d-flex justify-content-end">
              {<button onClick={() => { handleGoBack(false); setShowTimeline(true) }} className="rounded mr-1 pb- ms-3" style={{ border: "0.5px solid grey", backgroundColor: "#5CB8D3" }}>Go To Transaction</button>
              }
              {/* <button data-toggle="tooltip" title="Send Mail" onClick={() => { handleSendMail("email") }} className="bg-white rounded mr-1 pb-1  ms-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src='assets/images/mail.png' style={{ width: "15px", height: "15px" }} /></button>
              <button data-toggle="tooltip" title="Text Message" onClick={() => { }} className="bg-white rounded mr-1 pb-1 ms-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src='assets/images/messages.png' style={{ width: "15px", height: "15px" }} /></button>
              <button data-toggle="tooltip" title="Share On Whatsapp" onClick={() => handleSendWhatsApp("whatsapp")} className="bg-white rounded mr-1 pb-1 ms-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src='assets/images/whatsapp.png' style={{ width: "15px", height: "15px" }} /></button> */}
              <button data-toggle="tooltip" title="Download" onClick={() => handleDownload(editDocument)} className="bg-white rounded mr-1 pb-1 ms-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src="assets/images/new_dowd_icon.svg" style={{ width: "15px", height: "15px" }} /></button>


            </div>
          </div>

          <div className={`${!preview.show ? ' w-20 ' : ' w-50 '} ${editDocument?.itemStatus === 0 || editDocument?.itemStatus === 2 || editDocument?.itemStatus === 4 ? 'd-none' : 'd-flex'} justify-content-between`} >

          </div>
        </div>
        <div id={"invoiceDiv"} className="p-4 pt-1"   >
          <div className="border border-dark p-3">
            <div

              className="d-flex justify-content-center align-items-center py-2 border-bottom border-dark" >
              <label className="font-size-18 font-wt-600" >{editDocument.docType} - {editDocument.docId}</label>
            </div>
            <div className="row m-0 mb-2 d-flex border-dark border-bottom" >
              <div className="col-8 p-0">

                <div className="p-3  d-flex justify-content-between"
                >
                  <div className="ps-2 w-50 me-2 position-relative border-right border-dark" style={{ backgroundColor: !editBuyer ? "#ebf7f4" : "" }}>
                    <div className=" position-relative w-100 mb-2 mt-2 " >
                      <div className="d-flex justify-content-start align-items-center mb-1 ">
                        <label className="font-size-15 font-wt-500 m-0" ><u>BUYER DETAILS</u></label>
                        {!preview.show && <img
                          className="cursor p-1  ms-2 mb-1 border border-secondary rounded"
                          src="assets/images/sidebarV2/buyermanagement.svg"
                          onClick={() => { setChangeBuyer(true) }}
                          data-toggle="tooltip"
                          title="Change Buyer"
                        />}
                      </div>
                      <InputForTable
                        fontClass={" font-size-15 font-wt-500 "}
                        isDisabled={preview.show || editBuyer} placeholder={(!editDocument.companyName && !preview.show) ? "<COMPANY NAME>" : ""} name={"companyName"} value={editDocument.companyName} />

                    </div>
                    <div className="position-relative w-70 mb-2" >
                      <NewTextAreaForTable isDisabled={preview.show || editBuyer} placeholder={(!editDocument.originAddress && !preview.show) ? "Enter Address" : ""} name={"originAddress"} value={editDocument.originAddress} />
                    </div>
                    <div className="position-relative w-70 mb-2" >
                      <InputForTable isDisabled={preview.show || editBuyer} placeholder={(!editDocument.companyWebsite && !preview.show) ? "Enter Website" : ""} name={"companyWebsite"} value={editDocument.companyWebsite} />
                    </div>
                    <div className="position-relative w-70 mb-2" >
                      <InputForTable isDisabled={preview.show || editBuyer} placeholder={(!editDocument.companyEmailId && !preview.show) ? "Enter Email Id" : ""} name={"companyEmailId"} value={editDocument.companyEmailId} />
                    </div>
                    <div className="position-relative w-70 mb-2" >
                      <InputForTable isDisabled={preview.show || editBuyer} placeholder={(!editDocument.companyContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"companyContactNo"} value={editDocument.companyContactNo} />
                    </div>
                    <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                      <div className="d-flex justify-content-end">
                        <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editBuyer ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                        {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/sidebarV2/buyermanagement.svg" data-toggle="tooltip" onClick={() => { setChangeBuyer(true) }} /> */}

                      </div>
                    </div>
                  </div>
                  {editDocument.docType === "Delivery Challan" ? <div className={`w-50 border-dark ps-2 pt-1 position-relative ${!removeDeliveryLocation ? '' : ''}`} style={{ backgroundColor: !editingDeliveryLocation ? "#ebf7f4" : "" }}>

                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DISPATCH DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable type="date" isDisabled={preview.show} placeholder={(!data.dispatchDate && !preview.show) ? "Enter Dispatch Date" : ""} name={"dispatchDate"} value={data.dispatchDate} />
                      </div>
                    </div>
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>ORDER DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable type="date" isDisabled={preview.show} placeholder={(!data.orderDate && !preview.show) ? "Enter Order Date" : ""} name={"orderDate"} value={data.orderDate} />
                      </div>
                    </div>
                  </div>
                    : <div className={`w-50 border-right border-dark ps-2 pt-1 position-relative ${!removeDeliveryLocation ? '' : ''}`} style={{ backgroundColor: !editingDeliveryLocation ? "#ebf7f4" : "" }}>
                      {!removeDeliveryLocation &&
                        <div className="p-"
                        >
                          <label className="font-size-15 font-wt-500" ><u>DELIVERY LOCATION</u></label>
                          <div className="position-relative w-70 mb-2 mt-2" >
                            <InputForTable
                              isDisabled={preview.show || editingDeliveryLocation} placeholder={(!editDocument.shipToContactName && !preview.show) ? "Enter Contact Name" : ""} name={"shipToContactName"} value={editDocument.shipToContactName} />
                          </div>
                          <div className="position-relative w-70 mb-2" >
                            <InputForTable isDisabled={preview.show || editingDeliveryLocation} placeholder={(!editDocument.shipToCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"shipToCompanyName"} value={editDocument.shipToCompanyName} />
                          </div>
                          {/* <div className="position-relative w-70 mb-2" >
                          <InputForTable isDisabled={preview.show || editingDeliveryLocation} placeholder={(!editDocument.shipToEmailId && !preview.show) ? "Enter Email Id" : ""} name={"shipToEmailId"} value={editDocument.shipToEmailId} />
                        </div> */}
                          <div className="position-relative w-70 mb-2" >
                            <InputForTable isDisabled={preview.show || editingDeliveryLocation} placeholder={(!editDocument.shipToContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"shipToContactNo"} value={editDocument.shipToContactNo} />
                          </div>
                          <div className="position-relative w-70 mb-2" >
                            <NewTextAreaForTable isDisabled={preview.show || editingDeliveryLocation} placeholder={(!editDocument.finalDestination && !preview.show) ? "Enter Address" : ""} name={"finalDestination"} value={editDocument.finalDestination} />
                          </div>
                          <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                            <div className="d-flex justify-content-end">
                              <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingDeliveryLocation ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                              <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" />
                            </div>
                          </div>
                        </div>}
                    </div>}
                </div>

              </div>
              {editDocument.docType === "Sales Enquiry" && <div className="col-4 p-0 m-0" >
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.docNumber && !preview.show) ? "Enter No" : ""} name={"docNumber"} value={docNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={editDocument.invoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>ENQUIRY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.orderDate && !preview.show) ? "Select Date" : ""} name={"orderDate"} value={editDocument.orderDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT TERM</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.paymentTerm && !preview.show) ? "Enter Payment Term" : ""} name={"paymentTerm"} value={editDocument.paymentTerm} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-100 p-0" >
                    <div className="p-3"
                      >
                      <label className="font-size-15 font-wt-500" ><u>KIND ATTENTION</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.attention && !preview.show) ? "Enter..." : ""} name={"attention"} value={editDocument.attention} />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>}
              {editDocument.docType === "Sales Quotation" && <div className="col-4 p-0 m-0">
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.docNumber && !preview.show) ? "Enter No" : ""} name={"docNumber"} value={editDocument.docNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={editDocument.invoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>ENQUIRY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.orderDate && !preview.show) ? "Select Date" : ""} name={"orderDate"} value={editDocument.orderDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT TERM</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.paymentTerm && !preview.show) ? "Enter Payment Term" : ""} name={"paymentTerm"} value={editDocument.paymentTerm} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-100 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>KIND ATTENTION</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.attention && !preview.show) ? "Enter..." : ""} name={"attention"} value={editDocument.attention} />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>}
              {editDocument.docType === "Invoice" && <div className="col-4 p-0 m-0" >
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>INVOICE NO</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.taxInvoiceNum && !preview.show) ? "Enter No" : ""} name={"taxInvoiceNum"} value={editDocument.taxInvoiceNum} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>INVOICE DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.taxInvoiceDate && !preview.show) ? "Select Date" : ""} name={"taxInvoiceDate"} value={editDocument.taxInvoiceDate} />
                      </div>
                    </div>
                  </div>

                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT TERM</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.paymentTerm && !preview.show) ? "Enter Payment Term" : ""} name={"paymentTerm"} value={editDocument.paymentTerm} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.paymentDate && !preview.show) ? "Enter Payment Date" : ""} name={"paymentDate"} value={editDocument.paymentDate} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="w-100 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>KIND ATTENTION</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.attention && !preview.show) ? "Enter..." : ""} name={"attention"} value={editDocument.attention} />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              }
              {editDocument.docType === "Order Confirmation" && <div className="col-4 p-0 m-0">
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>OC NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.OCNum && !preview.show) ? "Enter No" : ""} name={"OCNum"} value={editDocument.OCNum} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>OC DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.OCInvoiceDate && !preview.show) ? "Select Date" : ""} name={"OCInvoiceDate"} value={editDocument.OCInvoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>ENQUIRY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.SEinvoiceDate && !preview.show) ? "Select Date" : ""} name={"orderData"} value={editDocument.SEinvoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT TERM</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.paymentTerm && !preview.show) ? "Enter Payment Term" : ""} name={"paymentTerm"} value={editDocument.paymentTerm} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-100 p-0" >
                      <div className="p-3"
                        style={{ border: '1px solid #000' }}>
                        <label className="font-size-15 font-wt-500" ><u>KIND ATTENTION</u></label>
                        <div className="position-relative" >
                          <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.attention && !preview.show) ? "Enter..." : ""} name={"attention"} value={editDocument.attention} />
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>}
              {editDocument.docType === "Purchase Tax Invoice" && <div className="col-4 p-0 m-0" style={{ borderRight: '1.5px solid #000', }}>
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>INVOICE NO</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.taxInvoiceNum && !preview.show) ? "Enter No" : ""} name={"taxInvoiceNum"} value={editDocument.taxInvoiceNum} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>INVOICE DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.taxInvoiceDate && !preview.show) ? "Select Date" : ""} name={"taxInvoiceDate"} value={editDocument.taxInvoiceDate} />
                      </div>
                    </div>
                  </div>

                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT TERM</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.paymentTerm && !preview.show) ? "Enter Payment Term" : ""} name={"paymentTerm"} value={editDocument.paymentTerm} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.paymentDate && !preview.show) ? "Enter Payment Date" : ""} name={"paymentDate"} value={editDocument.paymentDate} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="w-100 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>KIND ATTENTION</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.attention && !preview.show) ? "Enter..." : ""} name={"attention"} value={editDocument.attention} />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              }
              {editDocument.docType === "Purchase Order" && <div className="col-4 p-0 m-0" >
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.docNumber && !preview.show) ? "Enter No" : ""} name={"docNumber"} value={docNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={editDocument.invoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>ENQUIRY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.orderData && !preview.show) ? "Select Date" : ""} name={"orderData"} value={editDocument.orderData} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PAYMENT TERM</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"text"} placeholder={(!editDocument.paymentTerm && !preview.show) ? "Enter Payment Term" : ""} name={"paymentTerm"} value={editDocument.paymentTerm} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.store && !preview.show) ? "Enter store" : ""} name={"store"} value={editDocument.store} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>}
              {editDocument.docType === "Inward Document" && <div className="col-4 p-0 m-0 ">
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} placeholder={(!data.docNumber && !preview.show) ? "Enter No" : ""} name={"docNumber"} value={docNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DOCUMENT DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={data.invoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>ENQUIRY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.orderData && !preview.show) ? "Select Date" : ""} name={"orderData"} value={data.orderData} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>DELIVERY DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={data.deliveryDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PO NO</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.poNumber && !preview.show) ? "Enter Payment Term" : ""} name={"poNumber"} value={data.poNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>PO DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable type={'date'} isDisabled={preview.show} placeholder={(!data.poDate && !preview.show) ? "Enter store" : ""} name={"poDate"} value={data.poDate} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>}

              {editDocument.docType === "Request Quotation" && <div className="col-4 p-0 m-0">
                <div className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>RFQ NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.docNumber && !preview.show) ? "Enter No" : ""} name={"docNumber"} value={editDocument.docNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>RFQ DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={editDocument.invoiceDate} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>BIDDING START DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.orderDate && !preview.show) ? "Select Date" : ""} name={"orderDate"} value={editDocument.orderDate || '-'} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                    >
                      <label className="font-size-15 font-wt-500" ><u>BIDDING END DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={true} type={"date"} placeholder={(!editDocument.deliveryDate && !preview.show) ? "Select Date" : ""} name={"deliveryDate"} value={editDocument.deliveryDate || '-'} />
                      </div>
                    </div>
                  </div>



                </div>
              </div>}

            </div>


            {editDocument.docType === "Inward Document" ?
              <div className=" m-0 d-flex "  >
                <div className="col-8 p-0" >
                  <div className="p-3 d-flex justify-content-between"
                  >
                    <div className="w-50 me-2 pt-1 ps-2 position-relative border-right border-dark" style={{ backgroundColor: !editSeller ? "#ebf7f4" : "" }}>
                      <label className="font-size-15 font-wt-500" ><u>GOODS SENT BY</u></label>
                      {!preview.show && <img
                        className="cursor p-1  ms-2 mb-1 rounded"
                        src="assets/images/sidebarV2/buyermanagement.svg"
                        onClick={() => { setChangeBuyer(true) }}
                        data-toggle="tooltip"
                        title="Change Seller"
                      />}
                      <div className="position-relative w-70 mb-2 mt-2" >
                        <InputForTable // #3
                          isDisabled={preview.show || editSeller} placeholder={(!data.clientContactName && !preview.show) ? "Enter Contact Name" : ""} name={"clientContactName"} value={data.clientContactName} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={preview.show || editSeller} placeholder={(!data.clientCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"clientCompanyName"} value={data.clientCompanyName} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={preview.show || editSeller} placeholder={(!data.clientEmailId && !preview.show) ? "Enter Email Id" : ""} name={"clientEmailId"} value={data.clientEmailId} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={preview.show || editSeller} placeholder={(!data.clientContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"clientContactNo"} value={data.clientContactNo} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <NewTextAreaForTable isDisabled={preview.show || editSeller} placeholder={(!data.clientAddress && !preview.show) ? "Enter Address" : ""} name={"clientAddress"} value={data.clientAddress} />
                      </div>
                      <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                        <div className="d-flex justify-content-end">
                          <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editSeller ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                          {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={delete}/> */}
                        </div>
                      </div>
                    </div>
                    <div className="w-50  m-0 p-0 border-right border-dark">
                      <div className=" w-100 p-0" >
                        <div className="p-3 "
                        >
                          <label className="font-size-15 font-wt-500" ><u>TRANSPORTER NAME</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.transporterName && !preview.show) ? "Enter No" : ""} name={"transporterName"} value={data.transporterName} />
                          </div>
                        </div>
                      </div>

                      <div className=" w-100 p-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>DELIVERY CHALLAN NO</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.clnNum && !preview.show) ? "Enter No" : ""} name={"clnNum"} value={data.clnNum} />
                          </div>
                        </div>
                      </div>

                    </div>
                    {/* <div className={`w-50 ps-2 pt-1 position-relative ${!removePos ? 'border border-secondary rounded' : ''}`} style={{ backgroundColor: !editingPos ? "#ebf7f4" : "" }}>
                    {!removePos && <div className="p-"
                    >
                      <label className="font-size-15 font-wt-500" ><u>SUPPLIER LOCATION</u></label>
                        <div className="position-relative w-70 mb-2" >
                        <NewTextAreaForTable isDisabled={preview.show || editingPos} placeholder={(!data.placeOfSupply && !preview.show) ? "Enter Address" : ""} name={"placeOfSupply"} value={data.placeOfSupply}  />
                      </div>
                      <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                        <div className="d-flex justify-content-end">
                          <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingPos ? "#c7d5d6" : "" }} src="assets/images/edit.png" onClick={() => enableEditingDiv("pos")} />
                          <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={() => deleteDiv("pos")} />
                        </div>
                      </div>
                    </div>}
                  </div> */}


                  </div>

                </div>
                <div className="col-4  m-0 w-100 p-0">
                  <div className="d-flex m-0 p-0">
                    <div className=" w-100 p-0 m-0" >
                      <div className="p-3"
                      >
                        <label className="font-size-15 font-wt-500" ><u>INVOICE NO</u></label>
                        <div className="position-relative" >
                          <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.invNum && !preview.show) ? "Enter No" : ""} name={"invNum"} value={data.invNum} />
                        </div>
                      </div>
                    </div>
                    <div className=" w-100 p-0" >
                      <div className="p-3"
                      >
                        <label className="font-size-15 font-wt-500" ><u>INVOICE DATE</u></label>
                        <div className="position-relative" >
                          <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.invDate && !preview.show) ? "Select Date" : ""} name={"invDate"} value={data.invDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex m-0 p-0">
                    <div className=" w-100 p-0" >
                      <div className="p-3"
                      >
                        <label className="font-size-15 font-wt-500" ><u>STORE</u></label>
                        <div className="position-relative" >
                          <InputForTable isDisabled={preview.show} placeholder={(!data.store && !preview.show) ? "Enter store" : ""} name={"store"} value={data.store} />
                        </div>
                      </div>
                    </div>
                    <div className=" w-100 p-0" >
                      <div className="p-3"
                      >
                        <label className="font-size-15 font-wt-500" ><u>VEHICLE NUMBER</u></label>
                        <div className="position-relative" >
                          <InputForTable isDisabled={preview.show} placeholder={(!data.vehicleNumber && !preview.show) ? "Enter store" : ""} name={"vehicleNumber"} value={data.vehicleNumber} />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div> :
              editDocument.docType !== "Request Quotation" && <div className=" m-0 d-flex "  >
                <div className="col-8 p-0" >
                  <div className="p-3 pt-0 d-flex justify-content-between"
                  >
                    <div className="w-50 me-2 pt-1 ps-2 position-relative border-right border-dark" style={{ backgroundColor: !editSeller ? "#ebf7f4" : "" }}>
                      <label className="font-size-15 font-wt-500" ><u>SUPPLIER DETAILS</u></label>
                      <div className="position-relative w-70 mb-2 mt-2" >
                        <InputForTable // #3
                          isDisabled={preview.show || editSeller} placeholder={(!editDocument.clientContactName && !preview.show) ? "Enter Contact Name" : ""} name={"clientContactName"} value={editDocument.clientContactName} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={preview.show || editSeller} placeholder={(!editDocument.clientCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"clientCompanyName"} value={editDocument.clientCompanyName} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={preview.show || editSeller} placeholder={(!editDocument.clientEmailId && !preview.show) ? "Enter Email Id" : ""} name={"clientEmailId"} value={editDocument.clientEmailId} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={preview.show || editSeller} placeholder={(!editDocument.clientContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"clientContactNo"} value={editDocument.clientContactNo} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <NewTextAreaForTable isDisabled={preview.show || editSeller} placeholder={(!editDocument.clientAddress && !preview.show) ? "Enter Address" : ""} name={"clientAddress"} value={editDocument.clientAddress} />
                      </div>
                      <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                        <div className="d-flex justify-content-end">
                          <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editSeller ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                          {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={delete}/> */}
                        </div>
                      </div>
                    </div>
                    {editDocument.docType === "Delivery Challan" ? <div className={`w-50 border-dark ps-2 pt-1 position-relative ${!removeDeliveryLocation ? '' : ''}`} style={{ backgroundColor: !editingDeliveryLocation ? "#ebf7f4" : "" }}>

                      <div className="p-3"
                      >
                        <label className="font-size-15 font-wt-500" ><u>SHIPPING TERMS</u></label>
                        <div className="position-relative" >
                          <InputForTable isDisabled={preview.show} placeholder={(!data.shippingTerms && !preview.show) ? "Enter Shipping Terms" : ""} name={"shippingTerms"} value={data.shippingTerms} />
                        </div>
                      </div>

                    </div> :
                      <div className={`w-50 border-right border-dark ps-2 pt-1 position-relative }`} style={{ backgroundColor: !editingPos ? "#ebf7f4" : "" }}>
                        {editDocument.docType === "Inward Document" ? <>
                          <div className=" w-100 p-0" >
                            <div className="p-3 pb-2">
                              <label className="font-size-15 font-wt-500" ><u>TRANSPORTER NAME</u></label>
                              <div className="position-relative" >
                                <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.transporterName && !preview.show) ? "Enter No" : ""} name={"transporterName"} value={data.transporterName} />
                              </div>
                            </div>
                          </div>

                          <div className=" w-100 p-0" >
                            <div className="p-3 pb-0  "
                            >
                              <label className="font-size-15 font-wt-500" ><u>DELIVERY CHALLAN NO</u></label>
                              <div className="position-relative" >
                                <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.clnNum && !preview.show) ? "Enter No" : ""} name={"clnNum"} value={data.clnNum} />
                              </div>
                            </div>
                          </div>
                        </>
                          : <div className="p-"
                          >
                            <label className="font-size-15 font-wt-500" ><u>SUPPLIER LOCATION</u></label>
                            {/* <div className="position-relative w-70 mb-2 mt-2" >
                        <InputForTable
                          isDisabled={true} placeholder={(!editDocument.shipToContactName && !preview.show) ? "Enter Contact Name" : ""} name={"shipToContactName"} value={editDocument.shipToContactName} />
                      </div>
                      <div className="position-relative w-70 mb-2" >
                        <InputForTable isDisabled={true} placeholder={(!editDocument.shipToCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"shipToCompanyName"} value={editDocument.shipToCompanyName} />
                      </div> */}
                            <div className="position-relative w-70 mb-2" >
                              <NewTextAreaForTable isDisabled={preview.show || editingPos} placeholder={(!editDocument.placeOfSupply && !preview.show) ? "Enter Address" : ""} name={"placeOfSupply"} value={editDocument.placeOfSupply} />
                            </div>
                            <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                              <div className="d-flex justify-content-end">
                                <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingPos ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                                <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" />
                              </div>
                            </div>
                          </div>}
                      </div>}


                  </div>
                </div>
                {
                  editDocument.docType === "Order Confirmation" &&
                  <div className="col-4  m-0 w-100 p-0">
                    <div className="d-flex m-0 p-0" >
                      <div className="col-6 w-100 p-0 m-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>QUOTATION NO</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={true} type={"text"} placeholder={(!data.docNumber && !preview.show) ? "Enter No" : ""} name={"docNumber"} value={data.docNumber} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 w-100 p-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>QUOTATION DATE</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={true} type={"date"} placeholder={(!data.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={data.invoiceDate} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                }
                {
                  (editDocument.docType === "Sales Quotation" && editDocument.SEdocNumber && editDocument.SEinvoiceDate) &&
                  <div className="col-4  m-0 w-100 p-0">
                    <div className="d-flex m-0 p-0" >
                      <div className="col-6 w-100 p-0 m-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>SE NO</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={true} type={"text"} placeholder={(!data.SEdocNumber && !preview.show) ? "Enter No" : ""} name={"SEdocNumber"} value={data.SEdocNumber} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 w-100 p-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>SE DATE</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={true} type={"date"} placeholder={(!data.SEinvoiceDate && !preview.show) ? "Select Date" : ""} name={"SEinvoiceDate"} value={data.SEinvoiceDate} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                }
                {
                  (editDocument.docType === "Invoice" || editDocument.docType === "Purchase Tax Invoice") &&
                  <div className="col-4  m-0 w-100 p-0">
                    <div className="d-flex m-0 p-0">
                      {editDocument.docType === "Invoice" ? <>
                        <div className="col-6 w-100 p-0 m-0" >
                          <div className="p-3"
                          >
                            <label className="font-size-15 font-wt-500" ><u>OC NO</u></label>
                            <div className="position-relative" >
                              <InputForTable isDisabled={true} type={"text"} placeholder={(!data.OCNumber && !preview.show) ? "Enter No" : ""} name={"OCNumber"} value={data.OCNumber} />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 w-100 p-0" >
                          <div className="p-3"
                          >
                            <label className="font-size-15 font-wt-500" ><u>OC DATE</u></label>
                            <div className="position-relative" >
                              <InputForTable isDisabled={true} type={"date"} placeholder={(!data.OCDate && !preview.show) ? "Select Date" : ""} name={"OCDate"} value={data.OCDate} />
                            </div>
                          </div>
                        </div> </> :
                        <>
                          <div className="col-6 w-100 p-0 m-0" >
                            <div className="p-3"
                            >
                              <label className="font-size-15 font-wt-500" ><u>INWARD NO</u></label>
                              <div className="position-relative" >
                                <InputForTable type={"text"} placeholder={(!data.INWNumber && !preview.show) ? "Enter No" : ""} name={"INWNumber"} value={data.INWNumber} />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 w-100 p-0" >
                            <div className="p-3"
                            >
                              <label className="font-size-15 font-wt-500" ><u>INWARD DATE</u></label>
                              <div className="position-relative" >
                                <InputForTable type={"date"} placeholder={(!data.INWDate && !preview.show) ? "Select Date" : ""} name={"INWDate"} value={data.INWDate} />
                              </div>
                            </div>
                          </div>
                        </>}
                    </div>
                    <div className="d-flex m-0 p-0" >
                      <div className="col-6 w-100 p-0 m-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>PO NO</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={true} type={"text"} placeholder={(!data.poNumber && !preview.show) ? "Enter No" : ""} name={"poNumber"} value={data.poNumber} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 w-100 p-0" >
                        <div className="p-3"
                        >
                          <label className="font-size-15 font-wt-500" ><u>PO DATE</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={true} type={"date"} placeholder={(!data.poDate && !preview.show) ? "Select Date" : ""} name={"poDate"} value={data.poDate} />
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                }

              </div>}

            <div className="p-3 pt-0"
            >
              {editDocument.docType === "Inward Document" ?
                <>
                  <NewTablev2 addTableClass={`m-0 invBorder`}
                    columns={tableColumnsForInward}
                  >
                    {invItems.map((i, j) => {
                      if (i === null) {
                        jj = jj + 1
                        if (preview.show && !data[`itemDesc${j}`]) {
                          return null
                        }
                        return (
                          <tr>
                            <td><label className="font-size-16 font-wt-500" >{jj + 1}</label></td>
                            <td>

                              <div className="position-relative w-100">
                                {/* <DropdownSearch placeholder={"Select Commodity*"} isDisabled={preview.show} defaultItem={editDocument && data[`itemDesc${j}`]} items={mstCommList.map(item => item.commodity_pretty_name)} customStyles={{ "index": j }} /> */}
                                <p>{data[`itemDesc${j}`]}</p>
                                <div className="mt-3 mb-2 ms-2">
                                  <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data[`itemAddOn${j}`] && !preview.show) ? "Enter Item Description" : ""} name={"itemAddOn"} value={data[`itemAddOn${j}`]} />
                                </div>

                                {/*{inputValue && filteredOptions.length > 0 && (
                              <select
                                className="w-100"
                                value={inputValue}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                  handleCommodityChange(e.target.value, j);
                                }}
                              >
                                <option value="" disabled>Select Commodity</option>
                                {filteredOptions.map((comm, i) => (
                                  <option key={i} value={comm.commodity_pretty_name}>
                                    {comm.commodity_pretty_name}
                                  </option>
                                ))}
                              </select>
                            )}*/}
                              </div>
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`itemHSN${j}`] && !preview.show) ? "Enter HSN" : ""} name={`itemHSN${j}`} value={data[`itemHSN${j}`]} />


                              </div>
                            </td>
                            <td>
                              <div className="position-relative d-flex " style={{ gap: "5px" }} >

                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`itemQuantity${j}`] && !preview.show) ? "Quantity" : ""} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]} />
                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`itemQuantityUnits${j}`] && !preview.show) ? "Units" : ""} name={`itemQuantityUnits${j}`} value={data[`itemQuantityUnits${j}`]} />

                              </div>
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputForTable isDisabled={preview.show} type={"date"}
                                  placeholder={(!data[`deliveryDate${j}`] && !preview.show) ? "Enter Date" : ""} name={`deliveryDate${j}`} value={data[`deliveryDate${j}`]} />
                              </div>
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`delivered${j}`] && !preview.show) ? "Enter Delivered" : ""} name={`delivered${j}`} value={data[`delivered${j}`]} />
                              </div>
                              {/* <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemTax%${j}`] && !preview.show) ? "Enter Tax %" : ""} name={`itemTax%${j}`} value={`${data[`itemTax%${j}`]}` || "1"}  />
                          </div> */}
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`deliveredNow${j}`] && !preview.show) ? "Enter Delivered Now" : ""} name={`deliveredNow${j}`} value={data[`deliveredNow${j}`]} />
                              </div>
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`balance${j}`] && !preview.show) ? "Enter Balance" : ""} name={`balance${j}`} value={data[`balance${j}`]} />
                              </div>
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputForTable isDisabled={preview.show}
                                  placeholder={(!data[`comments${j}`] && !preview.show) ? "Enter Comments" : ""} name={`comments${j}`} value={data[`comments${j}`]} />
                              </div>
                            </td>
                            {!preview.show ?
                              <td>
                                <div className="d-flex w-100 justify-content-between" >
                                  <div className="" >
                                    {(invItems.length - invItems.filter(i => {
                                      if (i === undefined) {
                                        return true
                                      }
                                    }).length) - 1 === jj ?
                                      <img
                                        onClick={async () => {
                                          let temp = invItems
                                          temp.push(null)
                                          setInvItems([...temp])
                                        }}
                                        className="cursor" src="assets/images/add_black_icon.png" /> :
                                      <img
                                        onClick={() => {
                                          let temp = invItems
                                          temp[j] = undefined
                                          setInvItems([...temp])
                                        }}
                                        className="cursor" src="assets/images/delete.png" />}
                                  </div>
                                </div>
                              </td> : null}
                          </tr>
                        )
                      }
                    })}
                  </NewTablev2>
                </> :

                <><NewTablev2 addTableClass={`m-0 invBorder`}
                  columns={preview.show ? invItemsTable : invItemsTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '5%' } }])}
                >
                  {invItems.map((i, j) => {
                    if (i === null) {
                      jj = jj + 1
                      if (preview.show && !data[`itemDesc${j}`]) {
                        return null
                      }
                      return (
                        <tr>
                          <td><label className="font-size-16 font-wt-500" >{jj + 1}</label></td>
                          <td>

                            <div className="position-relative w-100">
                              {/* <DropdownSearch placeholder={"Select Commodity*"} isDisabled={true} defaultItem={editDocument && data[`itemDesc${j}`]} items={mstCommList.map(item => item.commodity_pretty_name)} /> */}
                              <p>{data[`itemDesc${j}`]}</p>
                              <div className="mt-3 mb-2 ms-2">
                                <InputForTable isDisabled={true} type={"text"} placeholder={(!data[`itemAddOn${j}`] && !preview.show) ? "Enter Item Description" : ""} name={"itemAddOn"} value={data[`itemAddOn${j}`]} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="position-relative " >
                              <InputForTable isDisabled={true}
                                placeholder={(!data[`itemHSN${j}`] && !preview.show) ? "Enter HSN" : ""} name={`itemHSN${j}`} value={data[`itemHSN${j}`]} />


                            </div>
                          </td>
                          <td>
                            <div className="position-relative d-flex " style={{ gap: "5px" }} >

                              <InputForTable isDisabled={true}
                                placeholder={(!data[`itemQuantity${j}`] && !preview.show) ? "Quantity" : ""} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]} />
                              <InputForTable isDisabled={true}
                                placeholder={(!data[`itemQuantityUnits${j}`] && !preview.show) ? "Units" : ""} name={`itemQuantityUnits${j}`} value={data[`itemQuantityUnits${j}`]} />

                            </div>
                          </td>
                          {editDocument.docType !== "Request Quotation" && <td>
                            <div className="position-relative " >
                              <InputWithSelectForTable type={"number"} isDisabled={true}
                                selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                optionLabel={"code"} optionValue={"code"}
                                name={`itemUnitPrice${j}`} value={data[`itemUnitPrice${j}`]} />
                            </div>
                          </td>}
                          {editDocument.docType !== "Request Quotation" && <td>
                            <div className="position-relative " >
                              <InputForTable isDisabled={true}
                                placeholder={(!data[`itemTax%${j}`] && !preview.show) ? "Enter Tax %" : ""} name={`itemTax%${j}`} value={data[`itemTax%${j}`]} />


                            </div>
                            {/* <div className="position-relative " >
                            <InputForTable isDisabled={true}
                              placeholder={(!data[`itemTax%${j}`] && !preview.show) ? "Enter Tax %" : ""} name={`itemTax%${j}`} value={`${data[`itemTax%${j}`]}` || "1"} />
                          </div> */}
                          </td>}
                          {editDocument.docType !== "Request Quotation" && <td>
                            <div className="position-relative " >
                              <InputWithSelectForTable type={"number"} isDisabled={true}
                                selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                optionLabel={"code"} optionValue={"code"}
                                name={`itemTax${j}`} value={data[`itemTax${j}`]} />
                            </div>
                          </td>}
                          {editDocument.docType !== "Request Quotation" && <td>
                            <div className="position-relative " >
                              <InputWithSelectForTable type={"number"} isDisabled={true}
                                selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                optionLabel={"code"} optionValue={"code"}
                                name={`itemTotalAmount${j}`} value={data[`itemTotalAmount${j}`]} />
                            </div>
                          </td>}
                          {!preview.show ?
                            <td>
                              <div className="d-flex w-100 justify-content-between" >
                                <div className="" >
                                  {(invItems.length - invItems.filter(i => {
                                    if (i === undefined) {
                                      return true
                                    }
                                  }).length) - 1 === jj ?
                                    <img
                                      onClick={async () => {
                                        let temp = invItems
                                        temp.push(null)
                                        setInvItems([...temp])
                                      }}
                                      className="cursor" src="assets/images/add_black_icon.png" /> :
                                    <img
                                      onClick={() => {
                                        let temp = invItems
                                        temp[j] = undefined
                                        setInvItems([...temp])
                                      }}
                                      className="cursor" src="assets/images/delete.png" />}
                                </div>
                              </div>
                            </td> : null}
                        </tr>
                      )
                    }
                  })}
                </NewTablev2>

                  <div className="d-flex justify-content-end"
                  >
                    <div className="w-33 mt-2" >
                      <NewTablev2 hideHeading={preview.show} addTableClass={"m-0 invBorder"}
                        columns={preview.show ? chargesTable : chargesTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '10%' } }])}>
                        {invOther.map((i, j) => {
                          if (i === null) {
                            kk = kk + 1
                            if (preview.show && !data[`invChargeTitle${j}`]) {
                              return null
                            }
                            return (
                              <tr>
                                <td>
                                  <label className="font-size-16 font-wt-500" >
                                    <InputForTable isDisabled={j === 0 || preview.show} placeholder={(!data[`invChargeTitle${j}`] && !preview.show) ? "Enter Title" : ""} name={`invChargeTitle${j}`}
                                      value={data[`invChargeTitle${j}`]} />
                                  </label>
                                </td>
                                <td>
                                  <div className="position-relative " >
                                    <InputWithSelectForTable type={"number"} isDisabled={true}
                                      selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                      optionLabel={"code"} optionValue={"code"}
                                      name={`invSubTotalAmount${j}`} value={data[`invSubTotalAmount${j}`]} />
                                  </div>
                                </td>
                                {!preview.show ?
                                  <td>
                                    {j === 0 ? null :
                                      <div className="d-flex w-100 justify-content-between" >
                                        <div className="" >
                                          {(invOther.length - invOther.filter(i => {
                                            if (i === undefined) {
                                              return true
                                            }
                                          }).length) - 1 === kk ?
                                            <img
                                              onClick={async () => {
                                                let temp = invOther
                                                temp.push(null)
                                                setInvOther([...temp])
                                              }}
                                              className="cursor" src="assets/images/add_black_icon.png" /> :
                                            <img
                                              onClick={() => {
                                                let temp = invOther
                                                temp[j] = undefined
                                                setInvOther([...temp])
                                              }}
                                              className="cursor" src="assets/images/delete.png" />}
                                        </div>
                                      </div>}
                                  </td> : null}
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                  {editDocument.docType != "Request Quotation" && <div className="d-flex  justify-content-end">
                    <div style={{ border: '1px solid #000', width: "33%", borderTop: "0px" }} >
                      <div className="d-flex row align-items-center m-0 p-0 mt-2"
                      >
                        <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL (before)</label>
                        <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                          <InputWithSelectForTable isDisabled={true} type={"number"}
                            selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                            optionLabel={"code"} optionValue={"code"}
                            name={`invTotalAmountBeforeTax`} value={data[`invTotalAmountBeforeTax`]} />

                        </div>

                      </div>
                      <div className="d-flex row align-items-center m-0 p-0 "
                      >
                        <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL TAX</label>
                        <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                          <InputWithSelectForTable isDisabled={true} type={"number"}
                            selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                            optionLabel={"code"} optionValue={"code"}
                            name={`invTotalTax`} value={data[`invTotalTax`]} />
                        </div>
                      </div>
                      <div className="d-flex row align-items-center m-0 p-0"
                      >
                        <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL  (After tax + Extra Charges)</label>
                        <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                          <InputWithSelectForTable isDisabled={true} type={"number"}
                            selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                            optionLabel={"code"} optionValue={"code"}
                            name={`invTotalAmount`} value={data[`invTotalAmount`]} />
                        </div>
                      </div>


                    </div>
                  </div>}
                  {editDocument.docType !== "Request Quotation" && editDocument.docType !== "Delivery Challan" && <div className="d-flex justify-content-end m-0 mt-2 p-0">
                    <div className="w-33 d-flex row align-items-center m-0 p-0 py-2 " style={{ border: '1px solid #000' }}
                    >
                      <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >ADVANCE TO PAY</label>
                      <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                        <InputWithSelectForTable isDisabled={true} type={"number"}
                          selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                          optionLabel={"code"} optionValue={"code"}
                          name={`invAdvToPay`} value={data[`invAdvToPay`]} />
                      </div>
                    </div>

                  </div>}
                  {editDocument.docType !== "Request Quotation" && editDocument.docType !== "Delivery Challan" && <div className="d-flex justify-content-end m-0 mt-2 p-0">
                    <div className="w-33 d-flex row align-items-center m-0 p-0 py-2 " style={{ border: '1px solid #000' }}
                    >
                      <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >BALANCE TO PAY</label>
                      <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                        <InputWithSelectForTable isDisabled={true} type={"number"}
                          selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                          optionLabel={"code"} optionValue={"code"}
                          name={`invBalanceToPay`} value={data[`invBalanceToPay`]} />
                      </div>
                    </div>

                  </div>}
                </>}








            </div>



            {editDocument.docType === "Request Quotation" && (
              <>
                <div style={{ border: "1px solid black" }}>
                  <div style={{ margin: "1rem", fontWeight: "bold", textAlign: "center", marginBottom: "2rem" }}>
                    RFQ Summary
                  </div>
                  <div className="table-responsive custom-table">
                    <table className="table">
                      <thead >
                        <tr>
                          <th style={{ padding: "10px" }}>Parameter</th>

                          {Object.keys(editDocument)
                            .filter((key) => key.startsWith("clientCompanyName")) // Supplier Names as columns
                            .map((key, index) => (
                              <th key={index}>
                                <InputForTable
                                  isDisabled={preview.show || editSeller}
                                  placeholder={!editDocument[key] && !preview.show ? "Enter Supplier Name" : ""}
                                  name={key}
                                  value={editDocument[key]}
                                />
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* Quoted Quantity Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Quoted Quantity</td>
                          {Object.keys(editDocument)
                            .filter((key) => key.startsWith("clientCompanyName")) // Dynamically pull values for each supplier
                            .map((key, index) => (
                              <td key={index}>
                                <InputForTable
                                  isDisabled={preview.show || editSeller}
                                  placeholder="Enter Quantity"
                                  name={`quotedQuantity${index + 1}`}
                                  value={editDocument[`quotedQuantity${index + 1}`]} // Dynamically pull quantity per supplier
                                />
                              </td>
                            ))}
                        </tr>

                        {/* Price Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Price Per Unit</td>
                          {Object.keys(editDocument)
                            .filter((key) => key.startsWith("clientCompanyName"))
                            .map((key, index) => (
                              <td key={index}>
                                <InputForTable
                                  isDisabled={preview.show || editSeller}
                                  placeholder="Enter Price"
                                  name={`price${index + 1}`}
                                  value={editDocument[`price${index + 1}`]} // Dynamically pull price per supplier
                                />
                              </td>
                            ))}
                        </tr>
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Tax %</td>
                          {Object.keys(editDocument)
                            .filter((key) => key.startsWith("clientCompanyName"))
                            .map((key, index) => (
                              <td key={index}>
                                <InputForTable
                                  isDisabled={preview.show || editSeller}
                                  placeholder="Enter Tax %"
                                  name={`tax${index + 1}`}
                                  value={editDocument[`tax${index + 1}`]} // Dynamically pull tax per supplier
                                />
                              </td>
                            ))}
                        </tr>
                        {/* Amount Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Total Amount</td>
                          {Object.keys(editDocument)
                            .filter((key) => key.startsWith("clientCompanyName"))
                            .map((key, index) => (
                              <td key={index}>
                                <InputForTable
                                  isDisabled={preview.show || editSeller}
                                  placeholder="Enter Amount"
                                  name={`amount${index + 1}`}
                                  value={editDocument[`amount${index + 1}`]} // Dynamically pull amount per supplier
                                />
                              </td>
                            ))}
                        </tr>

                        {/* Tax % Row */}

                      </tbody>
                    </table>
                  </div>
                </div>

              </>
            )}



            {!removeTandC &&
              <div className='p-3 pt-2 position-relative border-top border-dark' >
                <label className="font-size-15 font-wt-500" ><u>TERMS AND CONDITIONS</u></label>

                {termsConditions.map((tc, index) => (
                  <div className="position-relative w-100 m-1" key={tc.id}>
                    <InputForTable
                      isDisabled={preview.show || editingTandC}
                      placeholder={(!tc.value && !preview.show) ? "Enter Terms and Conditions" : ""}
                      name={"tandc"}
                      value={tc.value}

                    />
                  </div>
                ))}

                <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                  <div className="d-flex justify-content-end">
                    <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingDeliveryLocation ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                    <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" />
                  </div>
                </div>
              </div>}
            {!removeRemarks && <div className="w-100 p-0  position-relative" >
              <div className="p-3 pt-2 border-top border-dark"
              >
                <label className="font-size-15 font-wt-500" ><u>REMARKS</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={preview.show || editingRemarks} type={"text"} placeholder={(!editDocument.remarks && !preview.show) ? "Enter Remarks" : ""} name={"remarks"} value={editDocument.remarks} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingRemarks ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" />
                </div>
              </div>
            </div>}
            {!removeClinetNotes && <div className="w-100 p-0 mt-2 position-relative" >
              <div className="p-3 pt-2 border-top border-dark"
              >
                <label className="font-size-15 font-wt-500" ><u>CLIENT NOTES</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={preview.show || editingClinetNotes} type={"text"} placeholder={(!editDocument.clinetNotes && !preview.show) ? "Enter Clinet Notes" : ""} name={"clinetNotes"} value={editDocument.clinetNotes} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingClinetNotes ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" />
                </div>
              </div>
            </div>}
            {!removeInternalNotes && <div className="w-100 p-0 mt-2 position-relative" >
              <div className="p-3  pt-2 border-top border-dark"
              >
                <label className="font-size-15 font-wt-500" ><u>INTERNAL NOTES</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={preview.show || editingInternalNotes} type={"text"} placeholder={(!editDocument.internalNotes && !preview.show) ? "Enter Internal Notes" : ""} name={"internalNotes"} value={editDocument.internalNotes} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !editingInternalNotes ? "#c7d5d6" : "" }} src="assets/images/edit.png" />
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" />
                </div>
              </div>
            </div>}
            {editDocument.receivedBy && <div className="w-100 p-0 mt-2 position-relative border-top border-dark" >
              <div className="p-3">
                <label className="font-size-15 font-wt-500" ><u>Received By</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={true} type={"text"} placeholder={(!data.receivedBy && !preview.show) ? "Enter Received By" : ""} name={"receivedBy"} value={data.receivedBy} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !removeReceivedBy ? "#c7d5d6" : "" }} src="assets/images/edit.png" onClick={() => enableEditingDiv("receivedBy")} /> */}
                  {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={() => deleteDiv("receivedBy")} /> */}
                </div>
              </div>
            </div>}
            {editDocument.deliveredBy && <div className="w-100 p-0 mt-2 position-relative border-top border-dark" >
              <div className="p-3">
                <label className="font-size-15 font-wt-500" ><u>Delivered By</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={true} type={"text"} placeholder={(!data.deliveredBy && !preview.show) ? "Enter Received By" : ""} name={"deliveredBy"} value={data.deliveredBy} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !removeReceivedBy ? "#c7d5d6" : "" }} src="assets/images/edit.png" onClick={() => enableEditingDiv("receivedBy")} /> */}
                  {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={() => deleteDiv("deliveredBy")} /> */}
                </div>
              </div>
            </div>}

            <div className="px-3 py-5 pt-2 border-top border-dark  mt-2"
            >
              <div className="d-flex m-0 p-0 align-items-center" >
                <label className="font-size-14 font-wt-500 w-45" >"WE HEREBY CERTIFY THIS INVOICE TO BE TRUE AND CORRECT."</label>
                <div className="w-55  d-flex" >
                  <div className="position-relative w-50" >
                    <label className="font-size-14 font-wt-500" ><u>Name of Authorized Signatory</u></label>
                    <InputForTable isDisabled={true}
                      placeholder={(!editDocument.authorizedSignatoryName && !preview.show) ? "Enter Name" : ""} name={"authorizedSignatoryName"} value={editDocument.authorizedSignatoryName} />
                  </div>
                  <div className="position-relative w-50" >
                    <label
                      onClick={() => {
                        setSigndoc(true);
                      }}
                      className="font-size-14 font-wt-500 cursor" ><u>Signature</u>
                      {preview.show ? null :
                        <img src="assets/images/edit.png" className="cursor" />}</label>
                    <div>
                      {data?.invSign?.typedSign ?
                        <label style={{
                          fontFamily: signFonts.find((i, j) => {
                            if (j === editDocument.invSign.typedSignStyle) {
                              return true
                            }
                          })['font']
                        }} className="font-size-16" >{editDocument.invSign.typedSign}</label>
                        : data?.invSign?.signBase64 ?
                          <img src={data?.invSign?.signBase64} />
                          : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mt-3 border-secondary border-top pt-3" style={{
            display: preview.show ? "none" : "block"
          }}>
            <h6>ADD ON'S:</h6>
            <div className=" d-flex justify-content-start ">
              {removeDeliveryLocation && <button className="me-2 btn border border-secondary rounded" onClick={() => { setRemoveDeliveryLocation(false) }} style={{ backgroundColor: !removeDeliveryLocation ? "#5CB8D3" : "" }}>Add Delivery Location</button>}
              {removePos && <button className="me-2 btn  border border-secondary rounded" onClick={() => { setRemovePos(false) }} style={{ backgroundColor: !removePos ? "#5CB8D3" : "" }}>Add Supplier Location</button>}
              {removeRemarks && <button className="me-2 btn  border border-secondary rounded" onClick={() => { setRemoveRemarks(false) }} style={{ backgroundColor: !removeRemarks ? "#5CB8D3" : "" }}>Add Remarks</button>}
              {removeTandC && <button className="me-2 btn  border border-secondary rounded" onClick={() => { setRemoveTandC(false) }} style={{ backgroundColor: !removeTandC ? "#5CB8D3" : "" }}>Add Terms and Conditions</button>}
              {removeInternalNotes && <button className="me-2 btn  border border-secondary rounded" onClick={() => { setRemoveInternalNotes(false) }} style={{ backgroundColor: !removeInternalNotes ? "#5CB8D3" : "" }}>Add Internal Notes</button>}
              {removeClinetNotes && <button className="me-2 btn  border border-secondary rounded" onClick={() => { setRemoveClinetNotes(false) }} style={{ backgroundColor: !removeClinetNotes ? "#5CB8D3" : "" }}>Add Clinet Notes</button>}

            </div>
          </div>
        </div>
      </div>
    </>
  )

}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(TransactionDetails)

