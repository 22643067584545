import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import TutorialPopup, { TutorialVideoPopup } from "../tutorialPopup";
import UserReportsTab from "./components/UserReportsTab";
import BuyerReportsTab from "./components/BuyerReportsTab";
import CRMReportsTab from "./components/CRMReportsTab";
import { CustomSelect, NewSelect } from "../../utils/newInput";
import Calendar from "react-calendar";
import { useRef } from "react";
import moment from "moment";
import call from "../../service";
import MultipleSelect from "../../utils/MultipleSelect";
import ApplicationReportsTab from "./components/ApplicationReportsTab";

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "18rem",
      "right": "6rem"
    },
    arrow: {
      "top": "28rem",
      "zIndex": 10,
      transform: "rotate(110deg)",
      left: "15.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>You can check your "Payment, Finance & Shipment report" by clicking on the respective tab under Reports.</span></label>
  }
}

const AdminReports = ({ userTokenDetails, navToggleState }) => {

  const [tab, setTab] = useState("");
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 3)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [selectedDateFilter, setselectedDateFilter] = useState('Today')
  const [showCalendar, setshowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState()
  const [tokenDetails, setTokenDetails] = useState(userTokenDetails)
  const [selectedUser, setSelectedUser] = useState(null)
  const box = useRef(null)
  const [salesPerson, setSalesPerson] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [data, setdata] = useState({})
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  useEffect(() => {
    const tabName = localStorage.getItem('admin_report_name');
    setTab(tabName)
  }, [])
  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  useEffect(() => {
    let startDate
    let endDate
    const today = new Date();
    if (selectedDateFilter === 'Today') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, -1);
    } else if (selectedDateFilter === 'Previous Week') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedDateFilter === 'Previous Month') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedDateFilter === 'Current Week') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedDateFilter === 'Current Month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedDateFilter === 'Custom') {
      setshowCalendar(true)
      return
    } else if (selectedDateFilter === 'Overall') {
      startDate = new Date(2021, 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }
    setDateRange({
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    })

  }, [selectedDateFilter])
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [])
  const handleOutsideClick = (event) => {
    let eventTargetStr = event?.target?.outerHTML
    console.log("handleOutsideClick", box.current, event?.target?.outerHTML);
    if (box && box.current && !box.current.contains(event.target) && !eventTargetStr?.includes("<select class=")) {
      //setshowCalendar(false)
    }
  }
  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setdata({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setdata({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="AdminReports" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Reports"}
              userTokenDetails={userTokenDetails} />
            <div className='d-flex justify-content-between w-100'>

              <nav>
                <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                  <button className={`nav-link  bg-transparent ${tab === "Users" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("Users")}>Users</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Application" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("Application")}>Application</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Buyers" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("Buyers")}>Buyers</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "CRM" && 'active'} paymenttab`} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("CRM")}>CRM</button>

                </div>
              </nav>
              <div className='d-flex gap-3 col-md-5'>
                <div className="col-md-6">
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      Id="Select User"
                      Label="Select User"
                      selectedvalue="Select Requirement"
                      optiondata={salesPerson}
                      onChange={(e) => handleMultiSelectchange(e, "subadmins", "id")}
                      // value={data.subadmins ? data.subadmins : []}
                      value={data.subadmins && data.subadmins.length > 0
                        ? data.subadmins
                        : salesPerson.length > 0
                          ? [salesPerson[0].id]
                          : []}
                      name="subadmins"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                      isCheckableList={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <CustomSelect
                    selectData={[{ name: 'Overall' }, { name: 'Previous Month' }, { name: 'Previous Week' }, { name: 'Yesterday' }, { name: 'Today' }, { name: 'Current Week' }, { name: 'Current Month' }, { name: 'Custom' }]}
                    optionLabel={'name'} optionValue={'name'}
                    onItemCllick={(e) => {
                      if (e === 'Custom') {
                        setshowCalendar(true)
                        setselectedDateFilter(e)
                      } else {
                        setselectedDateFilter(e)
                        setshowCalendar(false)
                      }

                    }}
                    removeMb={true}
                    value={selectedDateFilter}
                  />
                  {showCalendar &&
                    <div className='position-absolute dropdownZindex' ref={box} style={{ right: "0px" }}>
                      <Calendar onChange={(val) => {
                        setDateRange({
                          from: moment(val[0]).format('YYYY-MM-DD'),
                          to: moment(val[1]).format('YYYY-MM-DD')
                        })
                        setshowCalendar(false)
                      }}
                        //value={[new Date(filterData["Date"]["value"][0]), new Date(filterData["Date"]["value"][1])]}
                        className="borderRadius border-0 calenderBorder col-md-12"
                        next2Label={null}
                        rev2Label={null}
                        selectRange={true}
                        calendarType={"US"} />
                    </div>


                  }
                </div>
              </div>
            </div>
            <div className="tab-content d-block" id="nav-tabContent" style={lcTutorial ? { position: 'relative', zIndex: -1 } : {}}>
              {tab === 'Users' &&
                <UserReportsTab userTokenDetails={userTokenDetails} dateRange={dateRange} userIds={data.subadmins} />
              }
              {tab === 'Application' &&
                <ApplicationReportsTab userTokenDetails={userTokenDetails} dateRange={dateRange} userIds={data.subadmins} />
              }
              {tab === 'Buyers' &&
                <BuyerReportsTab userTokenDetails={userTokenDetails} dateRange={dateRange} userIds={data.subadmins} />
              }
              {tab === 'CRM' &&
                <CRMReportsTab userTokenDetails={userTokenDetails} dateRange={dateRange} userIds={data.subadmins} />
              }
            </div>
          </main>
        </div>
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AdminReports)