import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import { setContractDetails, setContractState, setContractDocList } from '../../store/actions/action';


import { getSocket } from '../../socket'
import NewTablev2 from '../../utils/newTablev2';
import { formatDate_Application } from '../../utils/dateFormaters';
import { getStatusDisplayName } from '../dataTablesColumsMap/contractListColoums';
import { Action } from '../myCounterPartComp/action';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import SalesAndPurchaseTab from './SalesAndPurchaseTab';

const finHighlights = [
  { name: 'In-Pilot', color: 'rgba(255, 193, 7, 0.9)' },
  { name: 'In-Progress', color: 'rgba(248, 148, 6, 0.9)' },
  { name: 'Completed', color: 'rgba(63, 195, 128, 0.9)' }
]


const WorkOrderContract = ({ userTokenDetails, cDetailsState, setContractDetails, contractState, setContractState, clientType, setContractDocList, navToggleState, dispatch }) => {
  console.log(userTokenDetails, "tokennnnnnnn")
  const queryParams = new URLSearchParams(window.location.search)


  var Socket = getSocket()
  const [contractCount, setcontractCount] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [filterData, setFilterData] = useState({})
  const [statusFilter, setstatusFilter] = useState(0)
  const [contractLog, setcontractLog] = useState({ modal: false, contract_no: "" })
  const [showQuotRaiseModal, setshowQuotRaiseModal] = useState({})
  const [showInvestigationRemark, setshowInvestigationRemark] = useState({})
  const [showInsuranceRemark, setshowInsuranceRemark] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [contDeleteData, setcontDeleteData] = useState({});
  const [inspectionContractModal, setInspectionContractModal] = useState({ show: false, data: {} })
  const [inspectionWorkorderDetails, setInspectionWorkorderDetails] = useState({ show: false, data: {}, offer_data: {} })

  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [action, setAction] = useState({ show: false, index: null })
  const [filter, setFilter] = useState({ resultPerPage: 10, search: queryParams.get("search") || "" })
  const [page, setPage] = useState(1)
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })


  // transact dev
  const [tab, setTab] = useState('Container')
  const [hideButtons, setHideButtons] = useState(true)
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const parentId = userTokenDetails.parentId !== undefined ? userTokenDetails.parentId : null

  console.log("aclMapppppppppppppppppppppppppppppppp", aclMap);





  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];
    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("contractManagementList");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    // Create filter data & set it 
    let filterData = {}
    filterData["Date"] = {
      "accordianId": 'dateRangeFilter',
      type: "minMaxDate",
      value: []
    }
    filterData["Status"] = {
      "accordianId": 'status',
      type: "checkbox",
      data: [{ name: "In-Pilot" }, { name: "In-Progress" }, { name: 'Completed' }],
      labelName: "name"
    }
    setFilterData(filterData)
  }, [])

  useEffect(() => {
    fetchTableData({ userId: userId, type_id: userTypeId })
  }, [refresh, clientType.type, contractState.info.refresh, filter, filterData])
  function mergeSortedArrays(drafts, contractdata) {
    // Ensure drafts and contractdata are arrays, default to empty arrays if undefined
    drafts = drafts || [];
    contractdata = contractdata || [];

    let i = 0, j = 0;
    const mergedArray = [];

    // Merge both arrays in descending order based on their creation dates
    while (i < drafts.length && j < contractdata.length) {
      const draftDate = new Date(drafts[i].createdAt);
      const contractDate = new Date(contractdata[j].created_at);

      if (contractDate > draftDate) {
        mergedArray.push(contractdata[j]);
        j++;
      } else {
        mergedArray.push(drafts[i]);
        i++;
      }
    }

    // Add remaining elements from either array (if any)
    while (i < drafts.length) {
      mergedArray.push(drafts[i]);
      i++;
    }

    while (j < contractdata.length) {
      mergedArray.push(contractdata[j]);
      j++;
    }

    return mergedArray;
  }

  function fetchTableData(inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": filter.resultPerPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      // "activeType": clientType.type,
      "userParentId": parentId,
      "gridOnly": true,
      search: filter.search
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    objectAPI["fromDate"] = objectAPI?.["dateRangeFilter"]?.[0]
    objectAPI["toDate"] = objectAPI?.["dateRangeFilter"]?.[1]
    if (objectAPI?.status?.includes("In-Pilot")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("In-Progress")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("Completed")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true
      }
    }
    call('POST', 'getContracts', objectAPI).then(async (result) => {
      console.log('running getcontract api-->', result);
      let drafts = await call('POST', 'getAllInvoiceLimitDrafts', { userId, forContract: true })

      drafts = drafts.length ? drafts : []
      console.log("drafts here", drafts)
      drafts = drafts.concat(result.contractdata || [])
      const sortedData = mergeSortedArrays(drafts, result.contractdata);
      settableData(sortedData)
      setTotalRows(result.countdata.totalcount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  }

  // useEffect(()=>{
  //   getDifferentContractCount()
  // },[])


  // async function getDifferentContractCount(){
  //   if(tableData.length>0){
  //     for(let i = 0 ;i < tableData.length ;i++){

  //     }
  //   }
  // }


  async function handleTransactionPopup(itemData) {
    console.log(itemData, "itemmmmmm");
    setshowLoader(true)
    let tempData = []

    let exportFactoringLogs = { recent: [], others: [] }
    let lcLogs = { recent: [], others: [] }
    let logisticLogs = { recent: [], others: [] }
    let contractLogs = { recent: [], others: [] }

    let sortedRecents = []

    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      applicationId: itemData.invLimitAppId, buyerId: itemData.invLimitBuyerId
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Export Factoring" })
      tempData = tempData.concat(apiResp)
    }
    apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.lcLimitBuyerId, applicationId: itemData.lcLimitAppId
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Letter of Credit" })
      tempData = tempData.concat(apiResp)
    }
    apiResp = await call('POST', 'getTransactionsHistoryForShipmentBooking', {
      contractNo: itemData.contract_number
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Logistic" })
      tempData = tempData.concat(apiResp)
    }
    apiResp = await call('POST', 'getcontractlogs', {
      contract_number: itemData.contract_number
    })
    if (apiResp.length) {
      apiResp.forEach(i => { i["logType"] = "Contract Management" })
      tempData = tempData.concat(apiResp)
    }
    tempData.sort(function (a, b) {
      return new Date(a.dateTime || a.created_at) - new Date(b.dateTime || b.created_at);
    })
    tempData = tempData.reverse()
    // console.log("tempDataaaaaaaaaaaaaaaaaaaaaaaa", tempData);
    let logTypes = [{ name: "Export Factoring", variable: exportFactoringLogs }, { name: "Letter of Credit", variable: lcLogs },
    { name: "Logistic", variable: logisticLogs }, { name: "Contract Management", variable: contractLogs }]
    for (let index = 0; index < tempData.length; index++) {
      const element = tempData[index];
      for (let j = 0; j < logTypes.length; j++) {
        if (element.logType === logTypes[j]["name"]) {
          if (!logTypes[j]["variable"]["recent"].length) {
            logTypes[j]["variable"]["recent"] = [element]
            sortedRecents.push(element)
          }
          else {
            logTypes[j]["variable"]["others"].push(element)
          }
        }
      }
    }
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", sortedRecents, exportFactoringLogs, lcLogs, logisticLogs, contractLogs);
    setshowLoader(false)
    toggleTransactionPopup({
      show: true, data: tempData, sortedRecents, exportFactoringLogs, lcLogs, logisticLogs, contractLogs,
      contract_number: itemData.contract_number
    })
  }

  const handleNew = () => {

    if (booladd || userPermissions == null) {
      window.location = '/contractManagementAdd';
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  return (
    <>

      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>
            <div className="modal-body px-4">
              {transactionPopup.sortedRecents?.length ? transactionPopup.sortedRecents.map((item, index) => {
                let logTypes = {
                  "Export Factoring": { alt: "exportFactoringLogs", redirect: `/Quotes?search=${transactionPopup?.contract_number}` },
                  "Letter of Credit": { alt: "lcLogs", redirect: `/LcQuote?search=${transactionPopup?.contract_number}` },
                  "Logistic": { alt: "logisticLogs", redirect: `/ShipmentBookingQuotes?search=${transactionPopup?.contract_number}` },
                  "Contract Management": { alt: "contractLogs" }
                }
                return (
                  <>
                    <div className='d-flex flex-row ml-3'>
                      <div className="progressBarContainer">
                        <div className="progressBarInnerCircle">
                        </div>
                      </div>
                      <div className='pl-4 pt-3 d-flex row'>
                        <div className='row pt-2' >
                          <p
                            onClick={() => {
                              toggleTransactionPopup({ ...transactionPopup, [item.logType]: !transactionPopup[item.logType] })
                            }}
                            className='font-size-14 text-color1 font-wt-500 mb-0 col-6 cursor'>{item.logType}
                            {transactionPopup?.[logTypes[item.logType]["alt"]]?.["others"]?.length ?
                              <img
                                style={transactionPopup[item.logType] ? { transform: 'rotate(180deg)' } : {}}
                                className='cursor' src='assets/images/arrowdown.png' /> : null}
                          </p>
                          <p className='font-size-14 text-color-label font-wt-500 mb-0 col-6 text-right'>{item.date || moment(item.created_at).format("DD MMM, YYYY")} {"|"} {item.time || moment(item.created_at).format("hh:mm a")}</p>
                        </div>
                        <p
                          onClick={() => {
                            if (logTypes[item.logType]["redirect"]) {
                              window.location = `${logTypes[item.logType]["redirect"]}`
                            }
                          }}
                          className='font-size-14 text-color-label font-wt-500 mb-0 cursor'>{item.action || item.message}</p>
                      </div>
                    </div>
                    {
                      transactionPopup[item.logType] ?
                        transactionPopup?.[logTypes[item.logType]["alt"]]?.["others"].map((j, k) => {
                          return (<div className='d-flex flex-row ml-5'>
                            <div className="progressBarContainer">
                              <div className="progressBarInnerCircle">
                              </div>
                            </div>
                            <div className='pl-4 pt-3 d-flex row'>
                              <div className='row pt-2' >
                                <p className='font-size-14 text-color-label font-wt-500 mb-0 col-6'>{j.action || j.message}</p>
                                <p className='font-size-14 text-color-label font-wt-500 mb-0 col-6 text-right'>{j.date || moment(j.created_at).format("DD MMM, YYYY")} {"|"} {j.time || moment(j.created_at).format("hh:mm a")}</p>
                              </div>
                            </div>
                          </div>
                          )
                        })
                        : null
                    }
                  </>
                )
              })
                : null}
            </div>
          </div>
        </div>
      </div>
      {loading && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="contractManagement" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Contract Management"}
              userTokenDetails={userTokenDetails}
            />
            <nav className="position-relative">
              {hideButtons && <div className="nav nav-tabs signdoctabs gap-4 border-0 w-100 d-flex flex-nowrap" id="signdoctabs" role="tablist" style={{ overflowX: 'hidden', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                {["Container", "Bulk"].map(tabName => (
                  <button
                    key={tabName}
                    className={`nav-link bg-transparent ${tab === tabName && 'active'} paymenttab mb-2`}
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    style={{ width: "fit-content" }}
                    aria-controls={`nav-${tabName.toLowerCase().replace(" ", "-")}`}
                    aria-selected={tab === tabName}
                    onClick={() => setTab(tabName)}
                  >
                    {tabName}
                  </button>
                ))}

              </div>}
            </nav>


            {tab === "Bulk" && <><div style={{ display: 'flex', gap: '2rem' }}>
              <div
                onClick={() => {
                  let tempFilterData = filterData
                  let doesFilterActivated = false
                  for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
                    let element = tempFilterData["Status"]["data"][index];
                    if (element.name === "In-Pilot") {
                      element["isChecked"] = !element["isChecked"]
                      if (element["isChecked"]) {
                        doesFilterActivated = true
                      }
                    }
                    else {
                      element["isChecked"] = false
                    }
                  }
                  tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
                  setFilterData({ ...filterData })
                }}
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' style={{ 'color': 'rgba(255, 193, 7, 0.9)' }} >{contractCount.pilotcount || 0}</p>
                  <p className='font-size-16 font-wt-400 colorFFC107 p-0 m-0 mt-3 mb-4'>In - Pilot</p>
                </div>
              </div>

              <div
                onClick={() => {
                  let tempFilterData = filterData
                  let doesFilterActivated = false
                  for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
                    let element = tempFilterData["Status"]["data"][index];
                    if (element.name === "In-Progress") {
                      element["isChecked"] = !element["isChecked"]
                      if (element["isChecked"]) {
                        doesFilterActivated = true
                      }
                    }
                    else {
                      element["isChecked"] = false
                    }
                  }
                  tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
                  setFilterData({ ...filterData })
                }}
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' style={{ 'color': 'rgba(242, 195, 55, 0.9)' }} >{contractCount.progresscount || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3 mb-4'>In - Progress</p>

                </div>
              </div>

              <div
                onClick={() => {
                  let tempFilterData = filterData
                  let doesFilterActivated = false
                  for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
                    let element = tempFilterData["Status"]["data"][index];
                    if (element.name === "Completed") {
                      element["isChecked"] = !element["isChecked"]
                      if (element["isChecked"]) {
                        doesFilterActivated = true
                      }
                    }
                    else {
                      element["isChecked"] = false
                    }
                  }
                  tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
                  setFilterData({ ...filterData })
                }}
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{contractCount.completecount || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3 mb-4'>Completed</p>
                </div>
              </div>



              <div className='card p-4 border1Blue' style={{ width: '43.22%' }} >

                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0 ml-2 mb-2' >{contractCount.LCData || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Letter Of Credit</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0 ml-2 mb-2' >{contractCount.exportFactoring || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Export Factoring</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0 ml-2 mb-2' >{contractCount.shipmentData || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Logistics</p>
                  </div>
                </div>
              </div>


            </div>
              <div className='filter-div position-relative'>
                {/* <Filter
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={totalRows} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh}
                isAdditionalButton
              >
               {userTokenDetails.main_user_name !== "Admin FOB" (<div className="d-flex gap-4">
                  <button className={`new-btn  py-2 px-2 text-white cursor`}
                    onClick={() => {
                      window.location = '/contractManagementAdd'
                    }}
                  >Add New</button>
                </div>)}
              </Filter> */}
                <Filter
                  filterData={filterData}
                  setFilterData={setFilterData}
                  showFilterBtn={true}
                  showResultPerPage={true}
                  count={totalRows}
                  filter={filter}
                  setFilter={setFilter}
                  refresh={refresh}
                  setRefresh={setrefresh}
                  isAdditionalButton
                >
                  {userTokenDetails.main_user_name !== "Admin FOB" && (
                    <div className="d-flex gap-4">
                      <button
                        className={`new-btn py-2 px-2 text-white cursor`}
                        // onClick={() => {
                        //   window.location = '/contractManagementAdd';

                        // }}
                        onClick={handleNew}

                      >
                        Add New
                      </button>
                    </div>
                  )}
                </Filter>

                {Object.values(filterData || {})?.filter(i => {
                  if (i.isFilterActive) { return true }
                })?.length ? null : <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                  {finHighlights.map((i, j) => {
                    return (<div className='d-flex align-items-center mx-2' >
                      <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                      <label className='font-size-13 font-wt-500 mt-2 mx-2' >{i.name}</label>
                    </div>)
                  })}
                </div>}
              </div>
              {/* {!userPlanQuotaFetch()?.["trades"]?.limitLeft ?
              <label className='text-danger font-size-13 font-wt-600' >{'You have already used 100% of your commodity contracts quota. Please go to your plan and make a purchase in order to be able to create a new contract.'}</label>
              : null} */}
              <NewTablev2
                columns={[{ subColumns: "Contract Name", subColumnStyle: { width: '10%' } },
                { subColumns: "Buyer", subColumnStyle: { width: '10%' } },
                {
                  subColumns: "Seller", filterDataKey: "Buyer Name",
                  subColumnStyle: { width: '10%' }
                },
                { subColumns: "Commodity", subColumnStyle: { width: '8%' }, },
                { subColumns: "Quantity", subColumnStyle: { width: '8%' } },
                { subColumns: "Price", subColumnStyle: { width: '5%' } },
                { subColumns: "Created On", subColumnStyle: { width: '8%' } },
                { subColumns: "Laycan Start", subColumnStyle: { width: '8%' } },
                { subColumns: "Laycan End", subColumnStyle: { width: '8%' } },
                { subColumns: "Status", subColumnStyle: { width: '10%' } }, { subColumns: "", subColumnStyle: { width: '5%' } }
                ]}
              >
                {tableData.map((h, i) => {
                  try {

                    let isDraftEntry = tableData[i].details?.data
                    let isMktPlaceContract = tableData[i].booking_id ? true : false
                    let offerType = inspectionTypeIds.includes(userTypeId) ? "offers_sent" : "offers_received"
                    let tempObj = {}

                    tempObj.isMktPlaceContract = isMktPlaceContract
                    tempObj.contract_name = isDraftEntry ? tableData[i].details?.data?.contractAlias : tableData[i].contract_name;
                    tempObj.buyername = isDraftEntry ? tableData[i].details?.data?.buyerName : tableData[i].buyername;
                    tempObj.suppliername = isDraftEntry ? tableData[i].details?.data?.supplierName : tableData[i].suppliername;
                    tempObj.commodity_name = isDraftEntry ? tableData[i].details?.data?.commodity_pretty_name0 : tableData[i].commodity_name;

                    tempObj.investigation_status = tableData[i].investigation_status;
                    tempObj.insurance_status = tableData[i].insurance_status;


                    tempObj.quantity = isDraftEntry ? (tableData[i].details?.data?.quantity0) : (tableData[i].quantity + " " + tableData[i].commodityUnit);
                    tempObj.price = isDraftEntry ? "-" : ([3, 4, 8, 19].includes(userTypeId / 1)) ? (tableData[i].currency.split(':')[1] + tableData[i].price) : "CONFI";

                    tempObj.created_at = isDraftEntry ? formatDate_Application(tableData[i]?.createdAt) : tableData[i].created_at ? formatDate_Application(tableData[i].created_at) : "NA";

                    if (aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) {
                      tempObj.category = ((userId === tableData[i].buyer_bank) ? "Buyer Bank" :
                        (userId === tableData[i].supplier_bank) ? "Seller Bank" :
                          (userId !== tableData[i].supplier_bank && userId !== tableData[i].buyer_bank && parentId === 0) ? "Bank Master" : "NA")
                      tempObj.lc = tableData[i].lc ? tableData[i].lc : 'N/A';
                      tempObj.status = tableData[i].lc_status;
                    }
                    else {
                      tempObj.status = {
                        "status_buyer": tableData[i].status_buyer,
                        "status_seller": tableData[i].status_seller,
                        "edit_request": tableData[i].edit_request,
                        "payment_status": tableData[i].payment_status,
                        "userId": userId,
                        "display_status": tableData[i].display_status
                      }
                      console.log(tempObj.status["display_status"], "displayyyyyy")
                      if (isMktPlaceContract) {
                        tempObj.laycan_start = formatDate_Application(tableData[i][offerType][0]["offer_details_obj"]["laycan_start_date"]);
                        tempObj.laycan_end = formatDate_Application(tableData[i][offerType][0]["offer_details_obj"]["laycan_end_date"]);
                      }
                      else {
                        tempObj.laycan_start = tableData[i].laycan_start ? formatDate_Application(tableData[i].laycan_start) : "NA";
                        tempObj.laycan_end = tableData[i].laycan_end ? formatDate_Application(tableData[i].laycan_end) : "NA";
                      }

                    }

                    tempObj.actions = {
                      "status_buyer": tableData[i].status_buyer,
                      "status_seller": tableData[i].status_seller,
                      "contract_number": isMktPlaceContract ? tableData[i].booking_id : tableData[i].contract_number,
                      "contract_id": tableData[i].id,
                      "exporter_id": tableData[i].exporter_id,
                      "importer_id": tableData[i].importer_id,
                      "supplier_bank": tableData[i].supplier_bank,
                      "lc_status": tableData[i].lc_status,
                      "contract_type": tableData[i].contract_type,
                      "quotation_status": tableData[i].quotation_status,
                      "contAddress": tableData[i].address,
                    }
                    let temp = getStatusDisplayName(tempObj)
                    let statusDisplayName = isDraftEntry ? "Draft" : temp["label"]
                    let statusStyle = isDraftEntry ? { backgroundColor: 'rgba(255, 193, 7, 0.9)' } : temp["style"]

                    let actionOptions = []
                    if (!isDraftEntry) {
                      if ((tempObj.actions.status_buyer === 0 || tempObj.actions.status_seller === 0) && tableData[i].contractTemplate != 'Compact Contract') {
                        actionOptions.push({
                          name: "Contract Form", onClick: () => {
                            window.location = `/contractManagementAdd?contractNo=${encodeURIComponent(tempObj.actions.contract_number)}&type=edit`
                          }
                        })
                      }
                      actionOptions.push({
                        name: "Contract Details", onClick: () => {
                          window.location = `/commodityContractDetails?contractNo=${tempObj.actions.contract_number}`
                        }
                      })
                      actionOptions.push({
                        name: "Bulk Break", onClick: async () => {
                          setshowLoader(true)
                          let commDetails = await call('POST', 'getusercommoditylist', {
                            userId,
                            userTypeId,
                            "page": 1,
                            "pageLimit": 10,
                            "grid": true,
                            "searchKey": tempObj.commodity_name?.split(",")?.[0]
                          })
                          if (commDetails?.data?.length) {
                            localStorage.setItem("commDetails", JSON.stringify({
                              ...commDetails?.data?.[0], quantityToBulkBreak: tableData[i]?.quantity?.split(",")?.[0] / 1,
                              perUnitPriceFromContract: tableData[i]?.commodityUnitPrice?.split(",")?.[0] / 1
                            }))
                            window.location = `/bulkBreak`
                          }
                          else {
                            toastDisplay("Something went wrong", "error")
                          }
                        }
                      })
                    }
                    else {
                      actionOptions.push({
                        name: "Contract Form", onClick: () => {
                          window.location = `/contractManagementAdd?formContinue=${encodeURIComponent(tableData[i].id)}`
                        }
                      })
                    }
                    let procuredQuantity = 0
                    for (let index = 0; index < tableData[i]?.procured?.length; index++) {
                      let element = tableData[i]?.procured?.[index];
                      if (element.state === "Procured") {
                        procuredQuantity += element.quantity / 1 || 0
                      }
                    }
                    let availableQuantity = procuredQuantity - (tableData[i].commQuantityUtilizedInContract ? tableData[i].commQuantityUtilizedInContract / 1 : 0) - (tableData[i].quantity?.split(",")?.[0] / 1)

                    return (
                      <tr>
                        <td><label className='font-size-13 font-wt-400 text-break' >{tempObj.contract_name || "-"}</label></td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.buyername || "-"}</label></td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.suppliername || "-"}</label></td>
                        <td className='position-relative'><label className='font-size-12 font-wt-500 text-break'>{tempObj.commodity_name || "-"}</label>
                          {availableQuantity / 1 < 0 && tableData[i].exporter_id / 1 == userId / 1 ?
                            <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2" /> : null}
                          <label
                            onClick={() => {
                              window.open(`/manageCommodities?search=${encodeURIComponent(tempObj.commodity_name?.split(",")?.[0])}`, "_blank")
                            }}
                            style={{ top: '2rem', left: '0.5rem', width: '45rem' }} className='otherChargePopup px-3 py-2 cursor font-size-11 font-wt-800 text-danger position-absolute'>Your commodity quantity may not be sufficient for this order. Kindly update the quantities by clicking here.</label>
                        </td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.quantity || "-"}</label></td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.price || "-"}</label></td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.created_at || "-"}</label></td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.laycan_start || "-"}</label></td>
                        <td><label className='font-size-13 font-wt-400 text-break'>{tempObj.laycan_end || "-"}</label></td>
                        <td><label
                          onClick={() => {
                            if (!isDraftEntry) {
                              handleTransactionPopup(tableData[i])
                            }
                          }}
                          style={{ ...statusStyle, borderRadius: 5 }}
                          className='font-size-13 font-wt-400 text-break text-dark w-100 p-1 text-center' >{statusDisplayName}</label></td>
                        <td
                          // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          className='position-relative'>
                          <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                            onClick={() => {
                              setAction({ show: true, index: i })
                            }}
                            aria-hidden="true"></i>
                          {action.show && action.index === i ? (
                            <Action
                              id={i}
                              onDismiss={() => setAction({ show: false, index: i })}
                              options={actionOptions} />
                          ) : null}
                        </td>
                      </tr>
                    )
                  } catch (error) {
                    console.log("errorIntableData", error);
                  }
                })}
              </NewTablev2>
              <Pagination page={page} totalCount={totalRows} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} />
            </>}
            {tab === "Container" && <SalesAndPurchaseTab userTokenDetails={userTokenDetails} setHideButtons={setHideButtons} navToggleState={navToggleState} />}
          </main>
        </div>
      </div>

    </>
  )

}

const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setContractDocList: (id, data) => { dispatch(setContractDocList({ modal: true, contractId: id, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderContract)