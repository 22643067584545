import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { handleMultipleFile, mergeMultiplePdfs, most_used_currencies, replaceFileBase64WithFileObj } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputWithSelect, NewInput, NewSelect } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

const tabsArr = [
  { name: "Documents" },
  { name: "Business Details" },
  { name: "BG Details" },
  { name: "Other Details" },
  { name: "Select Financier" },
  { name: "Review" }
]

export const reviewBusiness = [
  { "name": "Business Name", val: "businessName" },
  { "name": "Start Date of Business", val: "startDateOfBusiness" },
  { "name": "Contact Person", val: "contactPerson" },
  { "name": "Product Details", val: "productDescription" },
  { "name": "Contact Number", val: "contactNo", unit: "phoneCode" },
  { "name": "Address", val: "addressLine1", after: "addressLine2" },
  { "name": "Email Id", val: "emailId" },
  { "name": "", val: "" },
  { "name": "Industry Type", val: "industry" },
  { "name": "", val: "" },
  { "name": "Aadhar No", val: "aadharNo" },
  { "name": "", val: "" },
  { "name": "PAN No", val: "panNo" }
]

export const reviewBG = [
  { "name": "Claim period months/days", val: "claimPeriod", after: "claimPeriodType" },
  { "name": "Request Date", val: "requestDate" },
  { "name": "Claim expiry date", val: "claimExpiryDate" },
  { "name": "Effective date", val: "effectiveDate" },
  { "name": "Place of lodgement claim", val: "placeOfLodgementClaim" },
  { "name": "Category", val: "category" },
  { "name": "Beneficiary name", val: "beneficiaryName" },
  { "name": "Amount", val: "amountValue", unit: "amountCurrency" },
  { "name": "Address", val: "addressLine1", after: "addressLine2" },
  { "name": "Expiry date", val: "expiryDate" },
  { "name": "", val: "" },
  { "name": "BG tenor in days", val: "bgTenorInDays" }
]

export const reviewOther = [
  { "name": "Issuing branch state", val: "issuingBranchState" },
  { "name": "Delivery branch city", val: "deliveryBranchCity" },
  { "name": "Issuing branch city", val: "issuingBranchCity" },
  { "name": "Delivery branch", val: "deliveryBranch" },
  { "name": "Issuing branch", val: "issuingBranch" },
  { "name": "Delivery branch IFSC code", val: "deliveryIFSCCode" },
  { "name": "Issuing branch IFSC code", val: "issuingIFSCCode" },
  { "name": "Delivery mode", val: "deliveryMode" },
  { "name": "Delivery branch state", val: "deliveryBranchState" },
  { "name": "Amount", val: "amountValue", unit: "amountCurrency" }
]

const BGGetConfirmation = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const formContinue = queryParams.get("formContinue")

  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }

  console.log("usertokendetailssssssssssssssss", userToken);

  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const user_avatar = userToken.userAvatar ? userToken.userAvatar : null

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [signdoc, setSigndoc] = useState(false);

  const [countryData, setCountryData] = useState([])
  const [currencyData, setCurrencyData] = useState(most_used_currencies)
  const [finList, setFinList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false
  })
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')
  const [states, setstatedata] = useState([])
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [finActionTab, setFinancierActionTab] = useState(0)
  const [draftData, setDraftData] = useState([])
  const [shouldSaveCache, toggleShouldSaveCache] = useState(formContinue ? false : true)
  const [cacheId, setCacheId] = useState(formContinue || null)

  useEffect(() => {
    if (shouldSaveCache) {
      saveCache()
    }
  }, [data, tab])

  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (userPermissions.length > 0) {

      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("bgGetConfirmation");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  async function saveCache() {
    let reqBody = { userId, forBG: true }
    reqBody["details"] = { data, tab, percentageComplete: (tab) * 16.66 }
    if (cacheId) {
      reqBody["cacheId"] = cacheId
    }
    let tempCacheId = await call('POST', 'saveInvoiceLimitCache', reqBody)
    if (tempCacheId) {
      setCacheId(tempCacheId)
    }
  }

  useEffect(() => {
    if (cacheId) {
      getCache()
    }
  }, [])

  async function getCache() {
    let cacheDetails = await call('POST', 'getInvoiceLimitCacheById', { cacheId, forBG: true })
    if (cacheDetails?.details) {
      setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
      setTab(cacheDetails.details.tab)
      toggleShouldSaveCache(true)
    }
  }

  useEffect(() => {
    call('POST', 'getAllInvoiceLimitDrafts', { userId, forBG: true }).then((res) => setDraftData(res))
  }, [])


  useEffect(() => {
    getFinancers()
    fetchUserDetails()
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountryData(result.data.message);
      }
    });
    getStateByCountry(101)
  }, [])

  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }

  async function fetchUserDetails() {
    let resp = await call("POST", "getUserDetailsById", { contactUserId: userId })
    let userResp = resp?.[0]
    let userIECResp = await call("POST", "getUserDetailsFromIEC", { userId })
    // console.log("userIECResppppppppppppp", userIECResp);
    setData({
      ...data, businessName: userResp.company_name, panNo: userResp.pan_no, aadharNo: userResp.aadhar_no,
      contactPerson: userResp.contact_person, contactNo: userResp.contact_number, phoneCode: userResp.phone_code, emailId: userResp.email_id,
      addressLine1: userResp.user_address, city: userResp.company_city, state: userResp.company_state, country: userResp.country_code,
      postalCode: userResp.company_postal_code, industry: industryData?.filter(i => {
        if (i.value === userResp.industry_type) {
          return true
        }
      })?.[0]?.["name"], startDateOfBusiness: userIECResp?.dateOfIncorporation ? moment(userIECResp.dateOfIncorporation).format("YYYY-MM-DD") : null,
      domesticBG: true, claimPeriodType: 'Month', amountCurrency: 'USD', beneficiaryName: userResp.contact_person
    })
  }

  function handleFinSelection(j) {
    let temp = finList
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setFinList([...temp])
  }

  const getFinancers = async () => {
    setshowLoader(true)
    let finApiResp = await call('POST', "getFinanciersForBG", {})
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
    }
    setshowLoader(false)
    setFinList(finApiResp)
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation() {
    let err = {}
    if (tab === 4) {
      let isFinSelected = false
      for (let index = 0; index < finList.length; index++) {
        const element = finList[index];
        if (element.isChecked) {
          isFinSelected = true
        }
      }
      if (!isFinSelected) {
        toastDisplay("Kindly select atleast 1 financer", "info")
      }
      else {
        setTab(tab + 1)
      }
    }
    else if (tab === 5) {
      if (!data.tcAccepted) {
        err["tcAccepted"] = 'kindly accept declaration, to continue'
        toastDisplay('kindly accept declaration, to continue', "info")
      }
    }
    else {
      setTab(tab + 1)
    }
    setErrors(err)
    if (!Object.keys(err).length && tab === 5) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("userId", userId)
      formData.append("userName", userName)
      Object.keys(data).forEach(key => {
        if (!key.includes("Document")) {
          formData.append(key, data[key])
        }
      })
      if (data.bgTextDocument?.name) {
        formData.append("bgTextDocument", data.bgTextDocument)
        if (data[`bgTextDocument`].signatureId) {
          formData.append("bgTextDocumentSignId", data[`bgTextDocument`].signatureId)
        }
      }
      if (data.contractCopyDocument?.name) {
        formData.append("contractCopyDocument", data.contractCopyDocument)
        if (data[`contractCopyDocument`].signatureId) {
          formData.append("contractCopyDocumentSignId", data[`contractCopyDocument`].signatureId)
        }
      }
      if (data.declarationDocument?.name) {
        formData.append("declarationDocument", data.declarationDocument)
        if (data[`declarationDocument`].signatureId) {
          formData.append("declarationDocumentSignId", data[`declarationDocument`].signatureId)
        }
      }
      if (data.boardResolutionDocument?.name) {
        formData.append("boardResolutionDocument", data.boardResolutionDocument)
        if (data[`boardResolutionDocument`].signatureId) {
          formData.append("boardResolutionDocumentSignId", data[`boardResolutionDocument`].signatureId)
        }
      }
      formData.append("finList", JSON.stringify(finList))
      // formData.append('to', "+917310004469");
      // formData.append('templateName', "bg_getconfirmation");
      // formData.append('mediaUrl', "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
      // formData.append('filename', "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
      // formData.append('placeholders', JSON.stringify(["6786898"]));
      call('POST', 'applyForBGConfirmation', formData).then(async res => {
        setshowLoader(false);
        toggleApplicationSubmitPopup({ show: true, data: {} })
        if (cacheId) {
          await call("POST", 'clearInvoiceLimitCacheById', { cacheId, forBG: true })
        }
        if (userTokenDetails.type_id / 1 == 1) {
          localStorage.clear("UserDetails")
          window.close()
        }
        toastDisplay("Confirmation Successful", "success")
        window.relocate = '/bgQuote'

      }).catch(err => { setshowLoader(false); toastDisplay("Something went wrong") })
    }
  }

  // console.log("dattaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(result => {
      setChatList(result)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              {/* <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div> */}

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {selectedChat.isChatOpen &&
        <div className="chatboxDiv">
          <ChatBoxPopUp
            chatList={chatList}
            user_avatar={user_avatar}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            onChatSend={() => sendChatMsg()}
            message={message}
            setTextMsg={setTextMsg}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false
              })
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup(false)
          window.location = 'bgQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >Bank Guarantee Confirmation </span> has been submitted successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="bgGetConfirmation" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Bank Guarantee > Get Confirmation"}
              userTokenDetails={userToken} />
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabsArr.map((item, index) => {
                  return (
                    <li
                      onClick={() => {
                        if (index < tab) {
                          setTab(index)
                        }
                      }}
                    >
                      <a
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className="card mt-1"
              >

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabsArr} activeTab={tab} label={"name"}
                    separationWidth={'2rem'} handleClick={(i, index) => {
                      if (index < tab) {
                        setTab(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row"
                    >
                      <NewTablev2 columns={bgGetConfirmationDocs}>
                        <tr>
                          <td>BG Text</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "bgTextDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData["bgTextDocument"] = await mergeMultiplePdfs(tempFilesArr, "combinedbgTextDocument")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data["bgTextDocument"]} error={errors["bgTextDocument"]}
                              isEditable={true}
                              onUploadCancel={() => setData({ ...data, ["bgTextDocument"]: null })}
                            />
                            {errors["bgTextDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["bgTextDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`bgTextDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`bgTextDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>

                        <tr>
                          <td>Contract Copy</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "contractCopyDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData["contractCopyDocument"] = await mergeMultiplePdfs(tempFilesArr, "combinedcontractCopyDocument")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data["contractCopyDocument"]} error={errors["contractCopyDocument"]}
                              isEditable={true}
                              onUploadCancel={() => setData({ ...data, ["contractCopyDocument"]: null })}
                            />
                            {errors["contractCopyDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["contractCopyDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`contractCopyDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`contractCopyDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>

                        <tr>
                          <td>Declaration</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "declarationDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData["declarationDocument"] = await mergeMultiplePdfs(tempFilesArr, "combineddeclarationDocument")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data["declarationDocument"]} error={errors["declarationDocument"]}
                              isEditable={true}
                              onUploadCancel={() => setData({ ...data, ["declarationDocument"]: null })}
                            />
                            {errors["declarationDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["declarationDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`declarationDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`declarationDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>

                        <tr>
                          <td>Board Resolution</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "boardResolutionDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData["boardResolutionDocument"] = await mergeMultiplePdfs(tempFilesArr, "combinedboardResolutionDocument")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data["boardResolutionDocument"]} error={errors["boardResolutionDocument"]}
                              isEditable={true}
                              onUploadCancel={() => setData({ ...data, ["boardResolutionDocument"]: null })}
                            />
                            {errors["boardResolutionDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["boardResolutionDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`boardResolutionDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`boardResolutionDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>

                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className="d-flex row px-5 pb-4 pt-5" >
                    <div className="col-md-4" >
                      <NewInput
                        label={"Business Name"} value={data.businessName} error={errors.businessName}
                        onChange={handleChange} name={"businessName"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"PAN No"} value={data.panNo} error={errors.panNo}
                        onChange={handleChange} name={"panNo"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Aadhar No"} value={data.aadharNo} error={errors.aadharNo}
                        onChange={handleChange} name={"aadharNo"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Contact Person"} value={data.contactPerson} error={errors.contactPerson}
                        onChange={handleChange} name={"contactPerson"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Contact No"}
                        selectData={countryData}
                        selectName={`phoneCode`} selectValue={data[`phoneCode`]}
                        optionLabel={"phonecode"} optionValue={'phonecode'}
                        name={`contactNo`} value={data[`contactNo`]} error={errors[`phoneCode`] || errors[`contactNo`]}
                        onChange={handleChange} />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Email Id"} value={data.emailId} error={errors.emailId}
                        onChange={handleChange} name={"emailId"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Address Line 1"} value={data.addressLine1} error={errors.addressLine1}
                        onChange={handleChange} name={"addressLine1"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Address Line 2"} value={data.addressLine2} error={errors.addressLine2}
                        onChange={handleChange} name={"addressLine2"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"City"} value={data.city} error={errors.city} name={"city"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"State"} value={data.state} error={errors.state} name={"state"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewSelect
                        selectData={countryData} optionLabel={"name"} optionValue={"sortname"} onChange={handleChange}
                        label={"Country"} name={"country"} value={data.country} error={errors.country}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Postal Code"} value={data.postalCode} error={errors.postalCode} name={"postalCode"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Product Description"} value={data.productDescription} error={errors.productDescription} name={"productDescription"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Industry"} value={data.industry} error={errors.postalCode} name={"industry"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput type={"date"}
                        label={"Start Date of Business"} value={data.startDateOfBusiness} error={errors.startDateOfBusiness} name={"startDateOfBusiness"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                ) : null}

                {tab === 2 ? (
                  <div className="d-flex row px-5 py-4" >
                    <div className='mb-3 d-flex m-2'>
                      <label className="font-wt-600 font-size-16 pt-1 mr-4" >BG Type</label>
                      <input type="radio" id="domesticBG" name="domesticBG"
                        checked={data.domesticBG ? true : false}
                        onClick={() => {
                          setData({ ...data, ["domesticBG"]: !data.domesticBG, foreignBG: false })
                        }}
                      />
                      <label className="font-wt-500 font-size-14 mr-4" for="domesticBG">Domestic BG</label>
                      <input className="mr-3" type="radio" id="foreignBG" name="foreignBG"
                        checked={data.foreignBG ? true : false}
                        onClick={() => {
                          setData({ ...data, ["foreignBG"]: !data.foreignBG, domesticBG: false })
                        }} />
                      <label className="font-wt-500 font-size-14 mr-4" for="foreignBG">Foreign BG</label>
                    </div>


                    <div className="col-md-4" >
                      <NewInput type={"date"}
                        label={"Request Date"} value={data.requestDate} error={errors.requestDate}
                        onChange={handleChange} name={"requestDate"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput type={"date"}
                        label={"Effective date"} value={data.effectiveDate} error={errors.effectiveDate}
                        onChange={handleChange} name={"effectiveDate"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Category"} value={data.category} error={errors.category}
                        onChange={handleChange} name={"category"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Amount"}
                        selectData={currencyData}
                        selectName={`amountCurrency`} selectValue={data[`amountCurrency`]}
                        optionLabel={"code"} optionValue={'code'}
                        name={`amountValue`} value={data[`amountValue`]} error={errors[`amountCurrency`] || errors[`amountValue`]}
                        onChange={handleChange} />
                    </div>
                    <div className="col-md-4" >
                      <NewInput type={"date"}
                        label={"Expiry Date"} value={data.expiryDate} error={errors.expiryDate}
                        onChange={handleChange} name={"expiryDate"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput type={"number"}
                        label={"BG tenor in days"} value={data.bgTenorInDays} error={errors.bgTenorInDays}
                        onChange={handleChange} name={"bgTenorInDays"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Claim period month / days"}
                        selectData={[{ title: 'Month' }, { title: 'Days' }]}
                        selectName={`claimPeriodType`} selectValue={data[`claimPeriodType`]}
                        optionLabel={"title"} optionValue={'title'}
                        name={`claimPeriod`} value={data[`claimPeriod`]} error={errors[`claimPeriodType`] || errors[`claimPeriod`]}
                        onChange={handleChange} />
                    </div>
                    <div className="col-md-4" >
                      <NewInput type={"date"}
                        label={"Claim Expiry Date"} value={data.claimExpiryDate} error={errors.claimExpiryDate}
                        onChange={handleChange} name={"claimExpiryDate"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Place of Lodgement Claim"} value={data.placeOfLodgementClaim} error={errors.placeOfLodgementClaim}
                        onChange={handleChange} name={"placeOfLodgementClaim"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Beneficiary Name"} value={data.beneficiaryName} error={errors.beneficiaryName}
                        onChange={handleChange} name={"beneficiaryName"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Address Line 1"} value={data.addressLine1} error={errors.requestDate}
                        onChange={handleChange} name={"addressLine1"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Address Line 2"} value={data.addressLine2} error={errors.addressLine2}
                        onChange={handleChange} name={"addressLine2"}
                      />
                    </div>

                  </div>
                ) : null}

                {tab === 3 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className="col-md-4" >
                      <NewSelect isAstrix={true} label={"Issuing Branch State"}
                        selectData={states} name={"issuingBranchState"}
                        value={data.issuingBranchState} optionLabel={"name"} optionValue={'sortname'}
                        onChange={handleChange} error={errors.issuingBranchState} />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Issuing branch City"} value={data.issuingBranchCity} error={errors.issuingBranchCity} name={"issuingBranchCity"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Issuing Branch"} value={data.issuingBranch} error={errors.issuingBranch} name={"issuingBranch"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Issuing IFSC Code"} value={data.issuingIFSCCode} error={errors.issuingIFSCCode} name={"issuingIFSCCode"}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4" >
                      <NewSelect isAstrix={true} label={"Delivery Branch State"}
                        selectData={states} name={"deliveryBranchState"}
                        value={data.deliveryBranchState} optionLabel={"name"} optionValue={'sortname'}
                        onChange={handleChange} error={errors.deliveryBranchState} />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Delivery Branch City"} value={data.deliveryBranchCity} error={errors.deliveryBranchCity} name={"deliveryBranchCity"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Delivery Branch"} value={data.deliveryBranch} error={errors.deliveryBranch} name={"deliveryBranch"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Delivery IFSC code"} value={data.deliveryIFSCCode} error={errors.deliveryIFSCCode} name={"deliveryIFSCCode"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Delivery mode"} value={data.deliveryMode} error={errors.deliveryMode} name={"deliveryMode"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Amount"}
                        selectData={currencyData}
                        selectName={`amountCurrency`} selectValue={data[`amountCurrency`]}
                        optionLabel={"code"} optionValue={'code'}
                        name={`amountValue`} value={data[`amountValue`]} error={errors[`amountCurrency`] || errors[`amountValue`]}
                        onChange={handleChange} />
                    </div>
                  </div>) : null}

                {tab === 4 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <label className='font-wt-500 font-size-16'>{`Select any ${finList.length} financiers`}</label>
                    <div className="row my-4">
                      {
                        finList.map((key, index) => {
                          return (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-3">
                              <div className="d-flex ">
                                <div class="custom-control ps-0 custom-checkbox form-check">
                                  <label class="form-check-label" for={"flexCheckChecked" + index}></label>
                                </div>
                                <div className={`card p-3 col-10 ${key.isAvailable ? "selectfinancercard" : 'disabledFinCard'}`}>
                                  {key.isRecommened ? (
                                    <div style={{ right: '-1.4rem' }} className="position-absolute" >
                                      <img src="assets/images/recommended_label_updated.svg" />
                                    </div>
                                  ) : null}

                                  <div className="d-flex flex-row mt-5">
                                    <div>
                                      <p className="cursor">
                                        {key.logo ? (<img
                                          style={{ maxWidth: '120px', maxHeight: '40px' }}
                                          className="mx-2" src={avatarUrl(key.logo)} alt="" />) : null}
                                        <span
                                          style={{ marginLeft: '2rem' }}
                                          className="cursor"
                                        >
                                          <img
                                            onClick={
                                              () => {
                                                if (key.isAvailable || environment === "dev") {
                                                  handleFinSelection(index)
                                                } else {
                                                  toastDisplay("Action not availble", "info")
                                                }
                                              }
                                            }
                                            className='cursor mr-3' src={`assets/images/${finList?.[index]["isChecked"] ? 'checked-green' : 'empty-check'}.png`} />
                                        </span>
                                      </p>

                                    </div>
                                    {/* <div className="d-flex flex-row justify-content-between w-100">
                                      {!key.isAvailable &&
                                        <img src="/assets/images/info-circle-outlined_remark.png" alt="" height={20} width={20} onClick={() => {
                                          setTooltip(true)
                                          setTooltipdata({
                                            lenderName: key.name,
                                            remarks: key.info_text
                                          })
                                        }} />
                                      }
                                    </div> */}
                                  </div>
                                  <div className="d-flex px-2">
                                    <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{key.avgRatings.toFixed(2)}</p>
                                      <span>
                                        <img className={`${key.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      </span>
                                    </div>
                                    <div
                                      onClick={async () => {
                                        // Get getUserTradeInfoWithFinancier
                                        let temp = key
                                        temp["show"] = true
                                        toggleReviewScrollIndex(0)
                                        setshowLoader(true)
                                        temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: key.id, sellerId: userId }) }
                                        setshowLoader(false)
                                        setSeeReview(temp)
                                      }}
                                      className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{key.reviewsCount}</p><p className="font10 font-size-14 font-wt-500">See reviews</p></div>
                                  </div>
                                  <p
                                    onClick={() => {
                                      setSelectedChat({
                                        receiverName: key.name,
                                        receiverId: key.id,
                                        userId: userId,
                                        isChatOpen: true
                                      })
                                    }} className="cursor"
                                  ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 font-wt-500 text-dark ms-2">Chat with financer</span></p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                ) : null}

                {tab === 5 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {reviewBusiness.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>BG Details</u></label>
                      <div className='row'>
                        <div className="col-md-6 pe-5">
                          <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">BG type</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{data.domesticBG ? "Domestic" : 'Foreign'}</span> </p>
                        </div>
                        {reviewBG.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Other Details</u></label>
                      <div className='row'>
                        {reviewOther.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='w-100 mt-5'>
                      <NewTablev2 columns={[
                        {
                          subColumns: "Document Name", subColumnStyle: { width: '30%' }
                        },
                        {
                          subColumns: "Attach Document", subColumnStyle: { width: '40%' }
                        },
                        {
                          subColumns: "Sign", subColumnStyle: { width: '15%' }
                        },
                        {
                          subColumns: "Action", subColumnStyle: { width: '15%' }
                        }
                      ]}>
                        {data.bgTextDocument?.name ?
                          <tr>
                            <td>BG Text</td>
                            <td><FileInput isEditable={false} value={data.bgTextDocument} /></td>
                            <td>
                              {!data[`bgTextDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`bgTextDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.contractCopyDocument?.name ?
                          <tr>
                            <td>Contract Copy</td>
                            <td><FileInput isEditable={false} value={data.contractCopyDocument} /></td>
                            <td>
                              {!data[`contractCopyDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`contractCopyDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.declarationDocument?.name ?
                          <tr>
                            <td>Declaration</td>
                            <td><FileInput isEditable={false} value={data.declarationDocument} /></td>
                            <td>
                              {!data[`declarationDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`declarationDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.boardResolutionDocument?.name ?
                          <tr>
                            <td>Board Resolution</td>
                            <td><FileInput isEditable={false} value={data.boardResolutionDocument} /></td>
                            <td>
                              {!data[`boardResolutionDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`boardResolutionDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                      </NewTablev2>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {finList.map((key) => {
                          if (key.isChecked) {
                            return (
                              <label key={key.name}
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {key.name}
                              </label>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className='mt-3'>
                      <img
                        onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                        className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                      <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                    </div>
                  </div>
                ) : null}

                <div
                  className={"d-flex finActionTabContainer mx-4 my-4 "} >
                  {tab / 1 != 5 ? <div
                    onClick={() => {
                      handleValidation(undefined)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                  </div> : <div
                    onClick={() => {
                      setFinancierActionTab(0)
                      handleValidation(undefined)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                  </div>}
                  <div
                    onClick={() => {
                      if (tab > 0) {
                        setTab(tab - 1)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor ${tab === 0 ? ' disable-bg ' : " "}`} >
                    <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                    <label
                      onClick={() => { toastDisplay('Application saved as draft', "success"); }}
                      className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 "} >Save as Draft</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                    }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor`} >
                    {finActionTab == 2 && draftData.length ? <div
                      style={{
                        width: '14rem',
                        top: `${draftData.length * -2.5}rem`,
                        borderRadius: "10px"
                      }}
                      className="position-absolute " >
                      {draftData.map((i, j) => {
                        return (
                          <div
                            onClick={() => { window.location = `/bgGetConfirmation?formContinue=${i.id}` }}
                            style={{ height: '2.5rem' }} className="d-flex bg-white border-bottom-solid-dee2e6 align-items-center" >
                            <label
                              className="font-size-14 font-wt-500 col-8 cursor truncate" >{`Application ${j + 1}`}</label>
                            <div className="col-4 d-flex align-items-center" >
                              <label
                                style={{
                                  background: "#5CB8D3",
                                  color: "#fff",
                                  borderRadius: "100%"
                                }}
                                className="ml-3 px-1 py-2 m-0" >{i.details.percentageComplete + " %"}</label>
                            </div>
                          </div>
                        )
                      })}
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Draft
                      <span
                        style={{
                          background: "#5CB8D3",
                          color: "#fff",
                          borderRadius: "100%"
                        }}
                        className="ml-3 px-2 py-1" >{draftData.length}</span>
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </main>

        </div>
      </div>
    </div>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(BGGetConfirmation)