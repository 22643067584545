import { useEffect, useState } from "react";
import call from "../../service";
import TimelineCard from "./cardForTimeline";
export default function TransactionTimeline({ userId, setShowTimeline, setAddNewDocument, setSelectedDocument, setHideButtons, handleGoBack, editDocument, }) {
  console.log("aaa timeline", editDocument, editDocument.docId)
  // important: add all the doc types id to get details with name as in useefect
  const [allDocs, setAllDocs] = useState([])
  const options = {
    "Request Quotation": ["Sales Quotation"],
    "Purchase Order": ["Purchase Tax Invoice", "Inward Document"],
    "Purchase Tax Invoice": ["Inward Document", "Delivery Challan"],
    "Sales Enquiry": ["Sales Quotation"],
    "Sales Quotation": ["Order Confirmation"],
    "Order Confirmation": ["Invoice"],
    "Invoice": ["Delivery Challan"]
  };
  function getDocs(docIds) {
    let objectAPI = {
      "userId": userId,
      search: docIds
    }
    call('POST', 'getSalesPurchaseQuotation', objectAPI).then(async (result) => {
      console.log('running getSalesPurchaseQuotation api-->', result);
      let drafts = []
      drafts = drafts.concat(result.data || [])
      setAllDocs(drafts)
    }).catch((e) => {
      console.log('error in getContracts', e);
    })
  }
  useEffect(() => {
    const docs = []
    if (editDocument) {
      // Check for each key and push the corresponding value into the docs array if it exists
      if (editDocument.docNumber) {
        docs.push(editDocument.docNumber);
      }
      if (editDocument.SEdocNumber) {
        docs.push(editDocument.SEdocNumber);
      }
      if (editDocument.OCNum) {
        docs.push(editDocument.OCNum);
      }
      if (editDocument.taxInvoiceNum) {
        docs.push(editDocument.taxInvoiceNum);
      }
    }
    if (docs) getDocs(docs)
  }, [])


  const [assignedValues, setAssignedValues] = useState([])
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    switch (editDocument.docType) {
      case "Purchase Order":
        setAssignedValues(options["Purchase Order"])
        break;
      case "Purchase Tax Invoice":
        setAssignedValues(options["Purchase Tax Invoice"])
        break;
      case "Sales Enquiry":
        setAssignedValues(options["Sales Enquiry"])
        break;
      case "Sales Quotation":
        setAssignedValues(options["Sales Quotation"])
        break;
      case "Order Confirmation":
        setAssignedValues(options["Order Confirmation"])
        break;
      case "Request Quotation":
        setAssignedValues(options["Request Quotation"])
        break;
      case "Invoice":
        setAssignedValues(options["Invoice"])
        break;

      default:
        setAssignedValues([])
        break;
    }
  }, [])

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSelectOption = (option) => {
    switch (option) {
      case "Sales Quotation":
        editDocument.itemStatus = 1
        editDocument.status = 1
        editDocument.SEdocNumber = editDocument.docNumber;
        editDocument.SEinvoiceDate = editDocument.invoiceDate;
        editDocument.addingSQ = true
        delete editDocument.docNumber;
        delete editDocument.invoiceDate
        break
      case "Order Confirmation":
        editDocument.addingOC = true
        editDocument.itemStatus = 1
        editDocument.status = 1
        break

      case "Purchase Tax Invoice":
        editDocument.itemStatus = 1
        editDocument.status = 1
        editDocument.poNumber = editDocument.docNumber;
        editDocument.poDate = editDocument.invoiceDate;
        editDocument.addingPInv = true
        delete editDocument.docNumber;
        delete editDocument.invoiceDate
        break

      case "Invoice":
        editDocument.addingOC = true
        editDocument.OCDate = editDocument.OCInvoiceDate
        editDocument.OCNumber = editDocument.OCNum
        break

      case "Delivery Challan":
        break
      case "Inward Document":
        editDocument.itemStatus = 1
        editDocument.status = 1
        editDocument.poNumber = editDocument.docNumber;
        editDocument.poDate = editDocument.invoiceDate;
        editDocument.addingPInv = true
        delete editDocument.docNumber;
        delete editDocument.invoiceDate
        break


    }
    // setSelectedOption(option); 
    setShowPopup(false);
    if (setSelectedDocument) setSelectedDocument(option)
    if (setAddNewDocument) setAddNewDocument(true)
    setShowTimeline(false)
    console.log("Selected Option: ", option);
  };
  function sortKeysByCreationDate(obj) {
    const entries = Object.entries(obj);
    const sortedEntries = entries?.sort((a, b) => {
      const dateA = new Date(a[1]);
      const dateB = new Date(b[1]);
      return dateA - dateB;
    });
    return Object.fromEntries(sortedEntries);
  }

  // Sort the transaction timeline
  //  default timeline is added to rectify unknown or undefined
  const sortedTimeline = sortKeysByCreationDate(editDocument?.transaction_timeline || {
    "Time line": "10/21/2024, 11:46:44 PM"
  });

  return (
    <div className="card p-3 ">
      <div className="mt-1 d-flex justify-content-between  align-items-center">
        <div className=" d-flex justify-content-between  align-items-center">
          <img onClick={() => { handleGoBack(false); if (setHideButtons) setHideButtons(true) }} class="bg-transparent d-block cursor-pointer me-5" src="assets/images/back.png" alt="Back" height="15" width="20"></img>
          <label className="font-size-18 m-0 p-0 font-wt-600 text-center" >{editDocument.docType} - {editDocument.docId}</label>
        </div>
        {
          editDocument.itemStatus === 1 &&
          <div>
            <button
              className="btn border rounded btn-secoundary"
              style={{ backgroundColor: "grey", color: "white", width: "200px" }}
              onClick={() => handleSelectOption(editDocument.docType)}
            >
              Edit Document
            </button>
          </div>
        }
        {editDocument.itemStatus !== 1 && assignedValues.length > 0 && (
          <div style={{ position: "relative" }}>
            <button
              className="btn border rounded btn-secoundary"
              style={{ backgroundColor: "grey", color: "white", width: "200px" }}  // Set width for the button
              onClick={togglePopup}  // Show popup when clicked
            >
              + Create Document
            </button>

            {/* Popup for dropdown */}
            {showPopup && (
              <div style={{
                position: "absolute",
                top: "100%",  // Position the popup below the button
                left: "0",
                width: "100%",  // Make the popup same width as the button
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1,  // Make sure it's above other elements
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
              }}>
                <ul style={{ listStyleType: "none", padding: "10px", margin: "0" }}>
                  {assignedValues.map((option, index) => (
                    <li
                      key={index}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderBottom: index !== assignedValues.length - 1 ? "1px solid #ccc" : "none",
                        transition: "background-color 0.2s"
                      }}
                      onClick={() => handleSelectOption(option)}  // Select option on click
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#f0f0f0"}  // Hover effect
                      onMouseLeave={(e) => e.target.style.backgroundColor = "white"}  // Remove hover effect
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-2 pt-2 border-top d-flex justify-content-between  align-items-center">
        <label className="font-size-14 m-0 p-0 font-wt-500 text-center" >Buyer : {editDocument.companyName}</label>
        <label className="font-size-14 m-0 p-0 font-wt-500 text-center" >Supplier: {editDocument.clientCompanyName}</label>
        <label className="font-size-14 m-0 p-0 font-wt-500 text-center" >
          Start Date: {editDocument["transaction_timeline"] && Object.keys(editDocument["transaction_timeline"]).length > 0
            ? editDocument["transaction_timeline"][Object.keys(editDocument["transaction_timeline"])[0]]
            : "Not available"}
        </label>


      </div>
      <div className="mt-5 w-50">
        <label className="font-size-16 m-0 p-0 font-wt-600 " >Transaction Timeline</label>
        <div className="d-flex justify-content-start align-items-center mt-3 ms-5">
          {editDocument.transaction_timeline &&
            Object.entries(sortedTimeline).map(([key, value], index) => (
              <div className="d-flex justify-content-start align-items-top" key={index}>
                <div className="text-center">
                  <div className="timeline-icon">
                    <img
                      src="assets/images/formProgressCircleActive.png"
                      alt="Completed"
                    />
                  </div>
                  <label className="d-block font-size-15 m-0 p-0 font-wt-400">{key}</label>
                  <label className="d-block font-size-13 m-0 p-0 font-wt-200">
                    {new Date(value).toLocaleDateString('en-GB')}
                  </label>
                </div>
                {index < Object.keys(sortedTimeline).length - 1 && (
                  <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(213, 213, 213)", borderRadius: "100px" }} />
                )}
              </div>
            ))}
        </div>

      </div>
      <div className="mt-3">
        {allDocs.length > 0 &&
          allDocs.map((item, index) => (
            <TimelineCard key={index} editDocument={item} />
          ))
        }
      </div>

    </div>
  )
}