import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import NewTablev2 from "../../../utils/newTablev2";
import call from "../../../service";
import moment from "moment";
import { encryptData } from "../../../utils/myFunctions";
import { most_used_currencies } from "../../../utils/myFunctions";
import { financertable, tablefinancerData } from "../TableJson"
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
//getFinTasks
const FinancerDv2Taskupdate = ({ userTokenDetails }) => {

  const [dbData, setDbData] = useState([])
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const getTaskdata = () => {
    let reqObj = {
      userId,
      resultPerPage: 10,
      currentPage: page
    }
    call('POST', 'getFinTasks', reqObj).then(result => {
      console.log('success in getTasks', result)
      setDbData(result.data)
      setCount(result.countdata)

    }).catch(e => {
      console.log('error in getTasks', e)
    })
  }
  useEffect(() => {
    getTaskdata()
  }, [page])

  return (
    <>

      <p className='text-color-0C0C0C font-size-20 font-wt-600 lineheight19' style={{ marginTop: "3rem", marginBottom: "1rem" }}>Task Update</p>
      <div className="card border-0 card-layout borderRadius taskupdatediv">
        <NewTablev2 columns={financertable}>

          {dbData.map((key, index) => {
            let createdAt = moment(key.createdAt)
            let todaysDate = moment()

            let diff = createdAt.diff(todaysDate, 'days') + 5
            console.log('difff in dayasssss', diff)
            let Action = ''
            let TranscationType = ''
            if (key.selectedFinancier === null && key.buyers_credit !== null) {
              Action = 'Send Quotes'
            } else if (key.selectedFinancier !== null && key.termSheetSignedByExporter === 0) {
              Action = 'Sign Contract'
            }

            if (key.type === 'sblc') {
              TranscationType = 'SBLC'
            } else if (key.type === 'lc_discounting') {
              TranscationType = 'LC Discounting'
            } else if (key.type === 'lc_confirmation') {
              TranscationType = 'LC Confirmation'
            } else {
              if (key.selectedFinancier === null && key.buyers_credit !== null) {
                Action = 'See Quotes'
              } else if (key.selectedFinancier !== null && key.termSheetSignedByExporter === 0) {
                Action = 'Sign Contract'
              }
              TranscationType = 'Invoice Discounting'
            }

            return (
              <tr>

                <td>{TranscationType}</td>
                <td>{key.supplierName}</td>
                <td>{key.finance_type == 'invoice_discounting' ? key.invRefNo : key.id}</td>
                <td>
                  {diff < 0 ?
                    (<p
                      class={`text-color-0C0C0C mb-0 font-size-13`}> -
                    </p>)
                    :
                    (<p
                      class={`colorFE4141 mb-0 font-size-13`}> {diff + " days"}
                    </p>)
                  }
                </td>
                <td><p href="" className="text-decoration-none cursor text-color1 font-size-14 font-wt-500 textunderline" onClick={() => {
                  window.location = `/viewLCFinApplication`;
                  // localStorage.setItem("applicationDetails", JSON.stringify({
                  //   ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                  //   lcPurpose:key.type,
                  //   lcType: LCTypesObject[item.lcType]
                  // }))
                }}> {"View Application"} </p></td>
              </tr>
            );
          })
          }
        </NewTablev2>
      </div>
      <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={10} />

    </>
  );
}
export default FinancerDv2Taskupdate;