import { connect } from "react-redux"
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal"
import { InputWithSelect, NewInput, NewSelect } from "../../../utils/newInput"
import MultipleSelect from "../../../utils/MultipleSelect"
import CommodityAdd from "../../registration/commodityAdd"
import React from "react"
import { multiSelectEventHandler } from "../../../utils/myFunctions"


const GlobalSignUp = ({

  logoSrc = "assets/images/logo_1.png",
  stepperProgress,
  formTitles,
  FieldTypes,
  handleFieldChange,
  handleStepperProgress,
  updateStepperProgress,
  outsideIndiaOrg,
  setoutSideIndiaOrg,
  newData,
  setNewData,
  errors,
  countryData,
  industryData,
  additionalCommodity,
  addMorecommodity,
  handleAddMore,
  onPlus,
  onCancel,
  selectSupplierPopup,
  setselectSupplierPopup,
  handleChange,
  companyTypes,
  searchedCompanys,
  handleFilterOptions,
  showMoreKYC,
  panArr,
  commodityDropDown,
  isEmailVerified,
  handleNext,
  boolsignup,
  setboolsignup,
  setSupplierName,
  setsuppliersdata,
  setsuppliersdataoverall
}) => {


  return (
    <>
      {boolsignup && <FinanceInvoiceModal modalSize={"md"} isCentered={true} limitinvoice={boolsignup} closeSuccess={() => {
        setboolsignup(false)
        setNewData({
          ...newData,
          supplierName: "",
          contactPerson: "",
          contact_number: "",
          designation: "",
          email_id: "",
          user_address: "",
          organization_type: ''
        })
        setSupplierName('')
        setsuppliersdata([])
        setsuppliersdataoverall([])

      }}>


        <div className="d-flex flex-column col-md-12 align-items-center right-class" style={{ paddingBottom: '1rem' }}>

          <h5 className="pb-4 font-size-18 font-wt-500"><span><i
            onClick={() => {
              if (stepperProgress / 1 == 2) {
                updateStepperProgress(0)
              }
              else {
                if (stepperProgress / 1 == 3 && outsideIndiaOrg === "yes") {
                  updateStepperProgress(0)
                }
                else {
                  handleStepperProgress('dec')
                }
              }
            }}
            class={`fa fa-arrow-left mx-4 ${stepperProgress === 0 && 'd-none'}`} aria-hidden="true"></i></span>{formTitles[stepperProgress]["name"]}</h5>
          {
            stepperProgress == 0 && (
              <>
                {FieldTypes.map((item, index) => {
                  if (!item.disabled) {
                    return (
                      <div
                        onClick={() => {
                          handleFieldChange(index, "workField");
                          // handleStepperProgress("inc")
                          if (outsideIndiaOrg === "yes") {
                            updateStepperProgress(3)
                          }
                          else {
                            updateStepperProgress(2)
                          }
                        }} className={`new-btn py-2 w-50 mb-4 ${newData.workField === index ? "text-white" : 'text-dark bg-white'}`}>
                        {item.name}
                      </div>
                    )
                  }
                })}

                <div className='d-flex flex-row align-items-center'>
                  <div className='d-flex flex-row px-2'>
                    <label className="form-check-label mt-1 fs-15 font-wt-600" >
                      Is your organization outside of India?
                    </label>
                  </div>
                </div>

                <div
                  className="row d-flex my-2 w-mob-45"
                  style={{ width: '25%' }}
                >
                  <div className='d-flex flex-row px-2 col-6' onClick={() => {
                    setoutSideIndiaOrg("no");
                    setNewData({ ...newData, organizationType: null })
                  }}>
                    <input className="form-check-input" type="radio" value={"no"} checked={outsideIndiaOrg === 'no'} />
                    <label className="form-check-label p-0 m-0" >
                      No
                    </label>
                  </div>
                  <div className='d-flex flex-row px-2 col-6' onClick={() => {
                    setoutSideIndiaOrg("yes");
                    setNewData({ ...newData, organizationType: 5 })
                  }}>
                    <input className="form-check-input" type="radio" value={"yes"} checked={outsideIndiaOrg === 'yes'} />
                    <label className="form-check-label p-0 m-0" >
                      Yes
                    </label>
                  </div>
                </div>
              </>
            )
          }
          {
            stepperProgress == 1 && (
              <>
                {companyTypes.map((item, index) => {
                  if (FieldTypes[newData.workField][item.alt]) {
                    return (
                      <div
                        onClick={() => { handleFieldChange(index, "organizationType"); handleStepperProgress("inc") }}
                        className={`new-btn py-2 w-200 mb-4 ${newData.organizationType === index ? "text-white" : 'text-dark bg-white'}`}>
                        {item.name}
                      </div>
                    )
                  }
                })}</>
            )
          }

          {
            stepperProgress == 2 && (
              <>
                {FieldTypes[newData.workField]?.["typeId"] / 1 == 20 ? (
                  <div className="form-floating mb-2 w-200 position-relative">
                    <NewInput type="text" id={"panDocument"} name={"panDocument"} value={newData["panDocument"]} onChange={handleChange}
                      error={errors["panDocument"]} label={"Enter PAN Number"}
                    />
                  </div>
                ) : (
                  <>
                    <div className="form-floating mb-2 w-200 position-relative col-md-12">
                      <MultipleSelect
                        Label={'Enter Company Name'}
                        Id={`selectedCompanyName`}
                        optiondata={searchedCompanys}
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "selectedCompanyName")
                        }}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `selectedCompanyName`, "entityId"))
                        }}
                        value={newData[`selectedCompanyName`] ? [newData[`selectedCompanyName`]] : []}
                        name={`selectedCompanyName`}
                        labelKey={"primaryName"}
                        valKey={"entityId"}
                        error={errors[`selectedCompanyName`]}
                      />
                    </div>
                    <label className="font-size-16 font-wt-500 mb-4">OR</label>
                    <div className="form-floating mb-2 w-200 position-relative col-md-12">
                      <NewInput type="text" id={"gstDocument"} name={"gstDocument"} value={newData["gstDocument"]} onChange={handleChange}
                        error={errors["gstDocument"]} label={"Enter GST Number"}
                      />
                    </div></>)}
                {showMoreKYC ? (
                  <>
                    <div className="form-floating mb-2 w-200 position-relative col-md-12">
                      <NewSelect
                        selectData={companyTypes.filter((item) => {
                          if (FieldTypes[newData.workField][item.alt]) {
                            return item
                          }
                        })} name={"organizationType"} value={newData["organizationType"]} onChange={handleChange}
                        error={errors["organizationType"]} label={"Organization Type"} optionLabel={"name"} optionValue={"index"}
                      />
                    </div>
                    <div className="form-floating mb-2 w-200 position-relative col-md-12">
                      {/* <NewInput isDisabled={true} type="text" id={"iecDocument"} name={"iecDocument"} value={data["iecDocument"]} onChange={handleChange}
                          error={errors["iecDocument"]} label={"Enter IEC Number"}
                        /> */}
                      <NewSelect selectData={newData.iecArr || []} name={"iecDocument"} value={newData["iecDocument"]} onChange={handleChange}
                        error={errors["iecDocument"]} label={"Enter IEC Number"} optionLabel={"iec"} optionValue={"iec"}
                      />
                    </div>
                    <div className="form-floating mb-2 w-200 position-relative col-md-12">
                      <NewInput isDisabled={true} type="text" id={"cinDocument"} name={"cinDocument"} value={newData["cinDocument"]} onChange={handleChange}
                        error={errors["cinDocument"]} label={"Enter CIN Number"}
                      />
                    </div>
                    {FieldTypes[newData.workField]["typeId"] / 1 == 20 ? (
                      <div className="form-floating mb-2 w-200 position-relative col-md-12">
                        <NewInput type="text" id={"gstDocument"} name={"gstDocument"} value={newData["gstDocument"]} onChange={handleChange}
                          error={errors["gstDocument"]} label={"Enter GST Number"}
                        />
                      </div>
                    ) : (
                      <div className="form-floating mb-2 w-200 position-relative col-md-12">
                        <NewSelect selectData={panArr} name={"panDocument"} value={newData["panDocument"]} onChange={handleChange}
                          error={errors["panDocument"]} label={"Select PAN Number"} optionLabel={"name"} optionValue={"name"}
                        />
                        {/* <NewInput isDisabled={true} type="text" id={"panDocument"} name={"panDocument"} value={data["panDocument"]} onChange={handleChange}
                            error={errors["panDocument"]} label={"Enter PAN Numberss"}
                          /> */}
                      </div>
                    )}
                    <div className="form-floating mb-2 w-200 position-relative col-md-12">
                      <NewInput type="text" id={"aadharDocument"} name={"aadharDocument"} value={newData["aadharDocument"]} onChange={handleChange}
                        error={errors["aadharDocument"]} label={"Enter Aadhar Number"}
                      />
                    </div>
                  </>
                ) : null}
                {/* {kycDocTypes.map((item, index) => (
                  <div className="form-floating mb-2 w-50 position-relative">
                    <NewInput type="text" id={item.alt} name={item.alt} value={data[item.alt]} onChange={handleChange}
                      error={errors[item.alt]}
                      label={item.name + (companyTypes[data.organizationType][item.alt]
                        && !companyTypes[data.organizationType][item.alt + "Ignore" + data.workField] ? ' *' : "")}
                    />
                  </div>
                ))} */}
              </>
            )
          }
          {stepperProgress == 3 && (
            <>
              <div className="form-floating mb-2 w-200 position-relative col-md-12">
                <NewInput type="text" name={"companyName"} value={newData["companyName"]} onChange={handleChange}
                  label={"Company Name *"} error={errors["companyName"]} />
                {/* <input
                  type="text"
                  className={`form-control ${errors["companyName"] ? "border-danger mb-0" : ""}`}
                  name={"companyName"}
                  value={data["companyName"]}
                  onChange={handleChange}
                  placeholder={"Company Name *"}
                /> */}
                {/* <label htmlFor="floatingInput">{"Company Name"}
                  {astrix}
                </label> */}
                {/* {errors["companyName"] ? (
                  <span className="text-danger fontValid">{errors["companyName"]}</span>
                ) : (
                  ""
                )} */}
                {/* {errors["companyName"] && (
                  toolTip(errors["companyName"])
                )} */}
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                {/* <SelectField
                  Label={`Industry Type`}
                  AriaLabel="industryType"
                  Id="industryType"
                  Name="industryType"
                  Value={data.industryType}
                  OnChange={handleChange}
                  Astrix={astrix}
                  ValidError={errors.industryType}
                >
                  <option value="">Select Industry Type</option>
                  {industryData.length
                    ? industryData.map((item) => (
                      <option value={item.value} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </SelectField> */}
                {/* <NewSelect
                    label={"Select Industry Type"}
                    selectData={industryData} name="industryType" value={data.industryType} onChange={handleChange}
                    optionLabel={"name"} optionValue={"value"} error={errors["industryType"]} /> */}
                {newData.categoryOfExporters ?
                  <NewInput isDisabled label={'Business Category'} name={'categoryOfExporters'} value={newData.categoryOfExporters} onChange={handleChange} /> : null}
                {/* <select
                  className={" form-control" + (!errors.industryType ? '' : ' border-danger')} name="industryType" value={data.industryType} onChange={handleChange} >
                  <option value="">Select Industry Type</option>
                  {industryData.length
                    ? industryData.map((item) => (
                      <option value={item.value} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </select> */}
                {/* {errors["industryType"] ? (
                  <span className="text-danger fontValid">{errors["industryType"]}</span>
                ) : (
                  ""
                )} */}
                {/* {errors["industryType"] && (
                  toolTip(errors["industryType"])
                )} */}
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                {/* <SelectField
                  Label="Country "
                  AriaLabel="country"
                  Id="country"
                  Name="country"
                  Value={data.country}
                  OnChange={handleChange}
                  Astrix={astrix}
                  ValidError={errors.country}
                >
                  <option value="">Select Country</option>
                  {countryData.length
                    ? countryData.map((item) => (
                      <option value={item.sortname} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </SelectField> */}
                <NewSelect
                  label={"Select Country *"}
                  selectData={countryData} name="country" value={newData.country} onChange={handleChange}
                  optionLabel={"name"} optionValue={"sortname"} error={errors["country"]} />
                {/* <select
                  className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange} >
                  <option value="">Select Country</option>
                  {countryData.length
                    ? countryData.map((item) => (
                      <option value={item.sortname} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </select> */}
                {/* {errors["country"] ? (
                  <span className="text-danger fontValid">{errors["country"]}</span>
                ) : (
                  ""
                )} */}
                {/* {errors["country"] && (
                  toolTip(errors["country"])
                )} */}
              </div>
              <div className="form-floating mb-2 w-200 position-relative col-md-12">
                <NewInput type="text" name={"companyAddress"} value={newData["companyAddress"]} onChange={handleChange}
                  label={"Company Address *"} error={errors["companyAddress"]} />
                {/* <input
                  type="text"
                  className={`form-control ${errors["companyAddress"] ? "border-danger" : ""}`}
                  name={"companyAddress"}
                  value={data["companyAddress"]}
                  onChange={handleChange}
                  placeholder={"Company Address *"}
                /> */}
                {/* <label htmlFor="floatingInput">{"Company Address"}
                  {astrix}
                </label> */}
                {/* {errors["companyAddress"] ? (
                  <span className="text-danger fontValid">{errors["companyAddress"]}</span>
                ) : (
                  ""
                )} */}
                {/* {errors["companyAddress"] && (
                  toolTip(errors["companyAddress"])
                )} */}
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <NewInput type="text" name={"companyCity"} value={newData["companyCity"]} onChange={handleChange}
                  label={"Company City *"} error={errors["companyCity"]} />
                {/* <input
                  type="text"
                  className={`form-control ${errors["companyCity"] ? "border-danger" : ""}`}
                  name={"companyCity"}
                  value={data["companyCity"]}
                  onChange={handleChange}
                  placeholder={"Company City *"}
                /> */}
                {/* {errors["companyCity"] ? (
                  <span className="text-danger fontValid">{errors["companyCity"]}</span>
                ) : (
                  ""
                )} */}
                {/* {errors["companyCity"] && (
                  toolTip(errors["companyCity"])
                )} */}
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <NewInput type="text" name={"companyPostalCode"} value={newData["companyPostalCode"]} onChange={handleChange}
                  label={"Company Postal Code *"} error={errors["companyPostalCode"]} />
                {/* <input
                  type="text"
                  className={`form-control ${errors["companyPostalCode"] ? "border-danger" : ""}`}
                  name={"companyPostalCode"}
                  value={data["companyPostalCode"]}
                  onChange={handleChange}
                  placeholder={"Company Postal Code *"}
                /> */}
                {/* {errors["companyPostalCode"] ? (
                  <span className="text-danger fontValid">{errors["companyPostalCode"]}</span>
                ) : (
                  ""
                )} */}
                {/* {errors["companyPostalCode"] && (
                  toolTip(errors["companyPostalCode"])
                )} */}
              </div>
              {/* #3 */}
              {/* <div className="w-50">
                  <div className="form-floating d-flex justify-content-between mb-2 w-100 position-relative">
                    <NewSelect onChange={handleChange}
                      label={"Select Commodity"} value={data.category} optionLabel={"category"} optionValue={"id"}
                      selectData={commodityDropDown} name="commodity"
                    // onChange={(e) => { setSelectedCommodity(e.target.value) }}
                    />
                    <NewInput className="mr-1" type="text" name={""} value={commodityName} onChange={(e) => setCommodityName(e.target.value)}
                      label={"Commodity Name"} optionLabel={"category"} optionValue={"id"} isAstrix={"false"} />
                    <button height={"30px"} type="button" class="btn btn-primary">+</button>

                  </div>
                  <button height={"30px"} type="button" class="btn btn-secondary">Add More</button>
                </div> */}
              <div className='w-200 col-md-12'>
                <CommodityAdd className="w-100" commodityDropDown={commodityDropDown} onPlus={onPlus} onCancel={onCancel} indexOf={1} />
                {additionalCommodity.map((component, index) => (
                  <React.Fragment key={index} >
                    {component}
                  </React.Fragment>
                ))}

                {addMorecommodity ? (<button onClick={handleAddMore} height={"20px"} type="button" class="mb-2 btn btn-secondary ml-20">Add More</button>) : <></>}
              </div>

            </>
          )}

          {stepperProgress == 4 && (
            <>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative row">
                <div className="w-50 p-0 m-0 col-md-6">
                  <NewSelect selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                    name="nameTitle" value={newData.nameTitle} optionLabel={'name'} optionValue={'name'}
                    onChange={handleChange} error={errors.nameTitle} />
                </div>
                <div className="w-50 p-0 m-0 col-md-6">
                  <NewSelect selectData={newData.directorArr || []} name={"contactPersonDropDown"} value={newData.contactPersonDropDown}
                    optionLabel={'name'} optionValue={'name'} onChange={handleChange}
                    error={errors.contactPersonDropDown} label={"Select Name*"}
                  />
                </div>
              </div>
              <div className="form-floating mb-2 w-200 position-relative col-md-12">
                {newData.contactPersonDropDown === "Other" ? (
                  <NewInput name={'contactPerson'} value={newData.contactPerson} onChange={handleChange}
                    error={errors.contactPerson} label={"Full Name*"}
                  />
                ) : null}
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <NewInput type={"text"} name={"designation"}
                  value={newData["designation"]}
                  onChange={handleChange} label={"Designation *"} error={errors["designation"]} />
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <NewInput type={"email"} name={"email"}
                  value={newData["email"]}
                  onChange={handleChange} label={"Email Id *"} error={errors["email"]} />
                {newData.email ? (
                  <i
                    style={{
                      "left": "90%",
                      "top": "0.8rem"
                    }}
                    // onClick={() => sendCombinedOTP()}
                    className={`position-absolute cursor fa font-size-18 ${isEmailVerified ? "fa-check" : ""} ${isEmailVerified ? "text-success" : ""}`}></i>
                ) : null}
              </div>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <InputWithSelect
                  selectData={countryData} selectName={"phoneCode"} selectValue={newData.phoneCode} optionLabel={"phonecode"}
                  optionValue={'phonecode'}
                  type="number" name={"contactNo"} value={newData["contactNo"]}
                  onChange={handleChange}
                  label={"Mobile Number *"} error={errors["contactNo"]} />
                {/* <select
                  disabled={isMobVerified} className={"form-control w-25" + (errors.phoneCode ? " border-danger" : "")}
                  name="phoneCode" value={data.phoneCode} onChange={handleChange}>
                  {countryData.length ? countryData.map((item, index) => (
                    <option value={item.phonecode}>{`+${item.phonecode}`}</option>
                  ))
                    : ""}
                </select> */}
                {/* <input
                  disabled={isMobVerified}
                  type="number"
                  className={`form-control w-75 ${errors["contactNo"] ? "border-danger" : ""}`}
                  name={"contactNo"}
                  value={data["contactNo"]}
                  onChange={handleChange}
                  placeholder={"Mobile Number *"}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      sendOtp("mob")
                    }
                  }}
                /> */}
                {/* {errors["contactNo"] && (
                  toolTip(errors["contactNo"])
                )} */}
                {/* {data.contactNo ? (
                  <i
                    style={{
                      "left": "90%",
                      "top": "0.8rem"
                    }}
                    onClick={() => sendCombinedOTP()}
                    className={`position-absolute cursor fa font-size-18 ${isMobVerified ? "fa-check" : ""} ${isMobVerified ? "text-success" : ""}`}></i>
                ) : null} */}
              </div>
              {/* <div className="form-floating mb-4 w-50">
                <input
                  type="text"
                  className={`form-control ${errors["referalCode"] ? "border-danger" : ""}`}
                  name={"referalCode"}
                  value={data["referalCode"]}
                  onChange={handleChange}
                  placeholder={"User Referral Code (If Available)"}
                />
                {errors["referalCode"] ? (
                  <span className="text-danger fontValid">{errors["referalCode"]}</span>
                ) : (
                  ""
                )}
              </div> */}
            </>
          )}

          {/* {stepperProgress == 5 && (
            <>
              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <NewInput type="password" name={"password"}
                  value={newData["password"]}
                  onChange={handleChange}
                  label={"Enter password *"} error={errors["password"]} />
               
              </div>

              <div className="form-floating mb-2 w-200 col-md-12 position-relative">
                <NewInput type="password" name={"repassword"}
                  value={newData["repassword"]}
                  onChange={handleChange}
                  label={"Re-enter password *"} error={errors["repassword"]} />
               
              </div>
            </>
          )} */}

          {stepperProgress === 4 ? (
            <>
              <div class="form-check mb-2 w-100">
                <label className={`font-wt-400 mb-4 cursor ${newData.referalCode ? 'text-color1' : ""}`}
                // onClick={() => toggleReferralPopup(true)}
                ><b>{newData.referalCode ? "Refferal Code Applied" : "I have a referral code"}</b>
                  {!newData.referalCode ? (
                    <i class="fas fa fa-arrow-right mx-1"></i>) : " !"}
                </label>
                <label class="form-check-label text-center" for="tcAccept">
                  <input class="form-check-input new-check-box" type="checkbox" name="tcAccept" id="tcAccept"
                    onChange={handleChange}
                    checked={newData.tcAccept}
                  />
                  I Agree to the
                  <span
                    onClick={() => { window.open("https://www.tradereboot.com/privacy-policy", "_blank") }}
                    className="text-primary cursor"> Terms & Conditions</span> and <span
                      onClick={() => { window.open("https://www.tradereboot.com/privacy-policy", "_blank") }}
                      className="text-primary cursor">Privacy policy</span> of Trade reboot fin</label>
              </div>
              <button type="button"
                disabled={!newData.tcAccept}
                onClick={handleNext}
                class={`new-btn py-2 w-50 mb-4 text-white`}>
                {"Register"}
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              className={`new-btn py-2 w-50 mb-4 text-white`}>{"Continue"}</button>
          )}


        </div>

      </FinanceInvoiceModal>}
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(GlobalSignUp)