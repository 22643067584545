import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import call from '../../service'
import { FileInput } from '../../utils/FileInput'
import { convertImageToPdf, dataURItoBlob, getFiscalYearsDropdown, most_used_currencies } from '../../utils/myFunctions'
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect } from '../../utils/newInput'
import toastDisplay from '../../utils/toastNotification'
import SignDocument from '../InvoiceDiscounting/components/SignDocument'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import avatarUrl from '../../utils/makeAvatarUrl'
import { environment, platformURL } from '../../urlConstants'
import moment from 'moment'
import MultipleSelect from '../../utils/MultipleSelect'
import NewTablev2 from '../../utils/newTablev2'
import { documentTable3 } from '../Dashboard/TableJson'
import { DocumentViewer } from '../../utils/documentViewer'
import { DocumentDownloader } from '../../utils/DocumentDownloader'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import { FormProgressBar } from '../CommonComponent/FormProgressBar'
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication'
const fiscalyears = getFiscalYearsDropdown()

let InsuaranceTabs = [
  { name: "Exporter" },
  { name: "Financial" },
  { name: "Bank" },
  { name: "Buyer" },
  { name: "Documents" },
  { name: "Select Insurer" },
  { name: "Review" }
]


const exorterDetailsForm = [
  { "name": "Exporter Name", val: "companyName" },
  { "name": "Contact Number", val: "contact_number", unit: "phonecode" },
  { "name": "Email ID", val: "email_id" },
  { "name": "Website", val: "website" },
  { "name": "Address", val: ["companyAdd1", "companyAdd2", "city", "state", "country", "postal_code"], isMultipleKeys: true },
  { "name": "Registration No.", val: "regNo" },
  { "name": "Pan No.", val: "pan_no" },
  { "name": "Organization Type", val: "orgType" },

]

const contactPersonDetails = [
  { "name": "Contact Person", val: "contact_person", unit: "name_title" },
  { "name": "Contact Number", val: "contact_number", unit: "phonecode" },
  { "name": "designation", val: "designation" },
]

const InsurerDetails = [
  { "name": "Current Credit Insurer (if any)", val: "currentInsurer" },
  { "name": "Reason for Shift", val: "shiftReason" },
]

const businessDetails = [
  { "name": "Nature Of Business", val: "natureBusiness" },
  { "name": "Terms Of Payment", val: "termsOfPayment" },
  { "name": "Product details", val: "productDetails" }
]

const expBankDetails = [
  { "name": "Bank Name", val: "expBankName" },
  { "name": "Branch", val: "expBankBranch" },
  { "name": "Bank Address", val: ["expbankaddLine1", "expbankaddLine2", "expbankcity", "expbankstate", "expbankcountry", "expbankpostal_code"], isMultipleKeys: true },
  { "name": "Account Holder Name", val: "expbankAccName" },
  { "name": "Account No.", val: "expbankAccNo" },
  { "name": "IFSC Code", val: "expbankIFSC_code" },
  { "name": "MICR Code", val: "expbankMICR_code" },
  { "name": "Payment Type", val: "expbankAcc_Type" }
]


const TCIKeyAccounts = ({ userTokenDetails, navToggleState }) => {
  let quotesDetailTemp = localStorage.getItem("quotesDetail");
  const quoteDetails = JSON.parse(quotesDetailTemp)
  const queryParams = new URLSearchParams(window.location.search)
  const buyerId = queryParams.get("buyerid")
  const exporterId = queryParams.get("exporterId")

  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ isChecked: false, buyer_name: quoteDetails?.buyerName })
  const [errors, setErrors] = useState({})
  const [countrydata, setCountrydata] = useState([])
  const [signdoc, setSigndoc] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [InsurerList, setInsurerList] = useState([])
  const [userBanks, setUserBanks] = useState([])
  const [multInvPo, setMultInvPo] = useState([{ purchase_orders: [null], invoices: [null] }])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [tableDocsData, setTableDocsData] = useState([])
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })
  const [top10Countries, setTop10Countries] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top5Debts, settop5Debts] = useState([null, null, null, null, null])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])

  const getallCountry = () => {
    setShowLoader(true)
    call('GET', 'getallCountry').then((result) => {
      console.log('running getallCountry api-->', result);
      setCountrydata(result)
      setShowLoader(false)
    }).catch((e) => {
      setShowLoader(false)
      // console.log('error in getBuyersDetail', e);
    });
  }
  useEffect(() => {
    getallCountry()
  }, [])
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const handleValidations = () => {
    let err = {}
    if (tab == 6 && !data.tcAccepted) {
      err["tcAccepted"] = "Mandatory"
      toastDisplay("Kindly accept terms & conditions, to continue", "info")
      return
    } else if (tab == 6 && data.tcAccepted) {
      let formData = new FormData()
      formData.append("financierId", userId)
      formData.append("buyerId", buyerId)
      formData.append("exporterId", exporterId)
      formData.append("service_name", "Other")
      formData.append("type", "Key Accounts")
      formData.append("selectedInsuarerLists", JSON.stringify(InsurerList))

      Object.keys(data).forEach(key => {
        if (!key.includes("Document")) {
          formData.append(key, data[key])
        }
      })
      for (let index = 0; index < multInvPo.length; index++) {
        const element = multInvPo[index];
        for (let j = 0; j < element.invoices.length; j++) {
          if (data[`invoiceDocument:${index}:${j}`]?.name) {
            formData.append("invoiceDocument" + index + ":" + j, data[`invoiceDocument:${index}:${j}`])
            if (data[`invoiceDocument:${index}:${j}`]?.signatureId) {
              formData.append("invoiceRefrenceNo" + index + ":" + j + "SignId", data[`invoiceDocument:${index}:${j}`].signatureId)
            }
            formData.append("invoiceRefrenceNo" + index + ":" + j, data["invoiceRefrenceNo" + ":" + index + ":" + j])
            formData.append("currency" + index + ":" + j, data["currency" + ":" + index + ":" + j])
            formData.append("contractAmount" + index + ":" + j, data["contractAmount" + ":" + index + ":" + j])
          }
        }
        for (let j = 0; j < element.purchase_orders.length; j++) {
          if (data[`poDocument:${index}:${j}`]?.name) {
            formData.append("poDocument" + index + ":" + j, data[`poDocument:${index}:${j}`])
            if (data[`poDocument:${index}:${j}`]?.signatureId) {
              formData.append("poDocument" + index + ":" + j + "SignId", data[`poDocument:${index}:${j}`].signatureId)
            }
            formData.append("poRefrenceNo" + index + ":" + j, data["poRefrenceNo" + ":" + index + ":" + j])
            formData.append("poAmount" + index + ":" + j, data["poAmount" + ":" + index + ":" + j])
          }
        }
      }
      setShowLoader(true)
      call('POST', 'applyInsuarance', formData).then(res => {
        setShowLoader(false);
        toggleApplicationSubmitPopup({ show: true, data: {} })
      }).catch(err => { setShowLoader(false); toastDisplay("Something went wrong") })
      return
    }

    setTab(tab + 1)
  }
  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  useEffect(() => {
    fetchUserDetails()

  }, [])
  function handleFinSelection(j) {
    let temp = InsurerList
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setInsurerList([...temp])
  }
  async function fetchUserDetails() {
    let resp = exporterId ? await call("POST", "getUserDetailsById", { contactUserId: exporterId }) : []
    let userResp = resp?.[0] || {}
    // console.log("userIECResppppppppppppp", userIECResp);
    let buuyerp = buyerId ? await call("POST", "getBuyerDetailsById", { buyerId: buyerId }) : []
    let BuyerResp = buuyerp?.[0] || {}
    // console.log("userIECResppppppppppppp", userIECResp);
    let addressComponents = {}
    if (BuyerResp?.buyerAddress?.length) {
      addressComponents = await call('POST', 'getAddressComponents', { address: BuyerResp.buyerAddress })
    }

    let finApiResp = await call('POST', "getFinanciersForInsurance", {})
    let finIds = []
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
      element["ratings"] = element.avgRatings || 0
      finIds.push(element.id)
    }

    setInsurerList(finApiResp)

    setData({
      ...data,
      selectedInsuarer: finApiResp,
      companyName: userResp.company_name,
      contact_person: userResp.contact_person,
      contact_number: userResp.contact_number,
      phonecode: userResp.phone_code,
      email_id: userResp.email_id,
      companyAdd1: userResp.user_address,
      pan_no: userResp.pan_no,
      orgType: userResp.organization_type,
      city: userResp.company_city,
      state: userResp.company_state,
      country: userResp.country_code,
      postal_code: userResp.company_postal_code,
      user_address: userResp.user_address,
      designation: userResp.designation,
      name_title: userResp.name_title,
      buyer_contact_number: BuyerResp.buyerPhone,
      buyer_phonecode: BuyerResp.buyerPhoneCode,
      buyer_email_id: BuyerResp.buyerEmail,
      buyeraddLine1: BuyerResp.buyerAddress,
      buyercity: addressComponents.city,
      buyerstate: addressComponents.state,
      buyercountry: BuyerResp.buyerCountry,
      buyerpostal_code: addressComponents.postalCode
    })
    call("POST", "getUserBanks", { "email": userResp.email_id, "inProfile": true })
      .then((result) => {
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        // console.log("Error while querying getUserBanks:", e);
      });
  }
  async function formatInvPoDocsForReview() {
    let row = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      row.push(["Invoice Details " + (index + 1)])
      for (let j = 0; j < element.invoices.length; j++) {
        if (data?.["invoiceDocument" + ":" + j]?.name) {
          row.push([`Invoice - ${data["invoiceRefrenceNo" + ":" + j]}`, `${data["currency" + ":" + j]} ${data["contractAmount" + ":" + j]}`,
          <FileInput
            isEditable={false}
            value={data["invoiceDocument" + ":" + j]}
          />,
          <>
            {
              !data?.["invoiceDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["invoiceDocument" + ":" + j], docSignName: ["invoiceDocument" + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["invoiceDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      for (let j = 0; j < element.purchase_orders.length; j++) {
        if (data?.["poDocument" + ":" + j]?.name) {
          row.push([`PO - ${data["poRefrenceNo" + ":" + j]}`, `-`,
          <FileInput
            isEditable={false}
            value={data["poDocument" + ":" + j]}
          />,
          <>
            {
              !data?.["poDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["poDocument" + ":" + j], docSignName: ["poDocument" + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["poDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      if (row.length) {
        let lastElementInArr = row[row.length - 1]
        lastElementInArr[100] = "To Add Line"
      }
    }
    setTableDocsData([...row])
  }
  useEffect(() => {
    formatInvPoDocsForReview()
  }, [data])
  const handleMultiSelect = async (e, name, val, index) => {
    console.log("handleMultiSelect", e, name, val);
    if (e?.[0]?.id.toString().includes("addNewBank")) {
      return window.open(`${platformURL}/viewprofile?action=addNewBank`, "_blank")
    }

    if (name?.includes("expBankName")) {
      let selectedEntry = e?.[0]?.id && e.reverse()?.[0]?.id ? e.reverse()?.[0] : {}
      setData({
        ...data,
        [name]: selectedEntry.id || null,
        [`expBankBranch`]: selectedEntry.beneficiaryBranch || "",
        [`expbankcity`]: selectedEntry.beneficiaryCity || "",
        [`expbankstate`]: selectedEntry.beneficiaryState || "",
        [`expbankcountry`]: selectedEntry.beneficiaryCountry || "",
        [`expbankpostal_code`]: selectedEntry.beneficiaryPostalCode || "",
        [`expbankaddLine1`]: selectedEntry.beneficiaryBranchAddress || ""

      });
    }
    else {
      setData({
        ...data,
        [name]: e?.[0]?.id ? e.reverse()?.[0]?.id : null
      })
    }

  };
  async function reloadUserBankDetails() {
    setShowLoader(true)
    call("POST", "getUserBanks", { "email": data.email_id, "inProfile": true })
      .then((result) => {
        setShowLoader(false)
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        setShowLoader(false)
        // console.log("Error while querying getUserBanks:", e);
      });
  }
  const handleFileWithAsyncAwait = async (event, poNo, poSubNo, prevData, tempMultInvPoLength) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setShowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setShowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          console.log("insidehandleFileeeeeeeeeeeeeeee", tempData);
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <SideBarV2 state="other_services_credit_insurance" userTokenDetails={userTokenDetails} />
        <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
        <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        {applicationSubmitPopup.show ? (
          <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
            toggleApplicationSubmitPopup(false)
            window.history.back()
          }}>
            <div className='text-center'>
              <img src='/assets/images/successful_icon.svg' />
              <div className="my-4">
                <img style={{}} src="assets/images/greetings_congratulation.svg" />
              </div>
              <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >Key Accounts Insurance </span> has been submitted successfully</p>
            </div>
          </FinanceInvoiceModal>
        ) : null}
        <main role="main" className={"container-bg ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={`Insuarance > Trade Credit Insuarance > Key Accounts`}
            userTokenDetails={userTokenDetails} />
          <div className='d-flex flex-row align-items-center'>
            <a className="cursor"
              onClick={() => window.history.back()}
            ><img
                src={"assets/images/back.png"} alt="Back" height={25} width={25} /></a>
          </div>
          <div className='mt-4'>
            {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
              {InsuaranceTabs.map((item, index) => {
                return (
                  <li>
                    <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      onClick={() => { setTab(index) }}
                    >
                      {item.name}</a>
                  </li>
                )
              })}
            </ul> */}

            <div className="card mt-1">

              <div className="d-flex justify-content-center mt-5 mb-3 ml-5" >
                <FormProgressBar
                  tabs={InsuaranceTabs} activeTab={tab} label={"name"}
                  separationWidth={'5rem'} handleClick={(i, index) => { setTab(index) }}
                />
              </div>

              {tab === 0 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Exporter details</label>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Company Name"}
                            name={"companyName"} value={data.companyName} error={errors.companyName}
                            onChange={handleChange} isDisabled={true} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Contact Number"}
                            selectData={countrydata}
                            selectName={"phonecode"} selectValue={data.phonecode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Email ID"}
                            name={"email_id"} value={data.email_id} error={errors.email_id}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Website"}
                            name={"website"} value={data.website} error={errors.website}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 1"}
                            name={"addLine1"} value={data.addLine1} error={errors.addLine1}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 2"}
                            name={"addLine2"} value={data.addLine2} error={errors.addLine2}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"City"}
                            name={"city"} value={data.city} error={errors.city}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"State"}
                            name={"state"} value={data.state} error={errors.state}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewSelect isAstrix={false} label={"Country"}
                            selectData={countrydata} name={"country"}
                            value={data.country} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.country} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Postal Code"}
                            name={"postal_code"} value={data.postal_code} error={errors.postal_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"registration Number"}
                            name={"regNo"} value={data.regNo} error={errors.regNo}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Pan No."}
                            name={"pan_no"} value={data.pan_no} error={errors.pan_no}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Organization Type"}
                            name={"orgType"} value={data.orgType} error={errors.orgType}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Contact Person Details</label>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Contact Person"}
                            selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                            selectName={"nameTitle"} selectValue={data.name_title}
                            optionLabel={"name"} optionValue={'name'}
                            name={'contact_person'} value={data.contact_person} error={errors.contact_person}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Contact Number"}
                            selectData={countrydata}
                            selectName={"phonecode"} selectValue={data.phonecode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Designation"}
                            name={"designation"} value={data.designation} error={errors.designation}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Insurer details</label>
                    <div className='row'>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Current Credit Insurer (if any)"}
                            name={"currentInsurer"} value={data.currentInsurer} error={errors.currentInsurer}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Reason for Shift"}
                            name={"shiftReason"} value={data.shiftReason} error={errors.shiftReason}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Business details</label>
                    <div className='row'>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Nature Of Business"}
                            name={"natureBusiness"} value={data.natureBusiness} error={errors.natureBusiness}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Terms Of Payment"}
                            name={"termsOfPayment"} value={data.termsOfPayment} error={errors.termsOfPayment}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Product details"}
                            name={"productDetails"} value={data.productDetails} error={errors.productDetails}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              ) : null}
              {tab === 1 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-12 py-4 my-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Turonver</label>
                    <NewTablev2 showSideBorders={true}
                      columns={[{ subColumns: "", subColumnStyle: { width: '25%' } }, { subColumns: '2nd Last Year', subColumnStyle: { width: '25%' } },
                      { subColumns: 'Last Year', subColumnStyle: { width: '25%' } }, { subColumns: 'Current Year', subColumnStyle: { width: '25%' } }]}>
                      {[{ "name": "Turn Over Amount" }].map((i, j) => {
                        return (
                          <tr>
                            <td><label className="font-size-13 font-wt-500 px-2" >{i.name}</label></td>
                            <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                              selectData={currencyData}
                              selectName={`salesCurrency`} selectValue={data[`salesCurrency`]}
                              optionLabel={"code"} optionValue={'code'}
                              name={`${i.name}:Year2`} value={data[`${i.name}:Year2`]}
                              onChange={handleChange} /></td>
                            <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                              selectData={currencyData}
                              selectName={`salesCurrency`} selectValue={data[`salesCurrency`]}
                              optionLabel={"code"} optionValue={'code'}
                              name={`${i.name}:Year1`} value={data[`${i.name}:Year1`]}
                              onChange={handleChange} /></td>
                            <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                              selectData={currencyData}
                              selectName={`salesCurrency`} selectValue={data[`salesCurrency`]}
                              optionLabel={"code"} optionValue={'code'}
                              name={`${i.name}:Year0`} value={data[`${i.name}:Year0`]}
                              onChange={handleChange} /></td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <div className='col-md-12 py-2 my-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Expected Turonver</label>
                    <div className="my-4" >
                      <NewTablev2 showSideBorders={true} columns={[
                        { subColumns: 'Countries( top 10 )', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Estimated annual turnover (next 12 months)', subColumnStyle: { width: '30%' } },
                        { subColumns: 'No. Of Active Accounts', subColumnStyle: { width: '25%' } },
                        { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '25%' } }]} >
                        {top10Countries.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`countryName${j}`} value={data[`countryName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`estAnnualTO${j}`} value={data[`estAnnualTO${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`noOfActiveAccounts${j}`} value={data[`noOfActiveAccounts${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`TermsOfPayment${j}`} value={data[`TermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                  <div className='col-md-12 py-2 my-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Bad debts</label>
                    <div className="my-4" >
                      <NewTablev2 showSideBorders={true} columns={[
                        { subColumns: 'Year', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Company', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Gross Loss ($)', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Recoveries Loss ($)', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Net Loss ($)', subColumnStyle: { width: '20%' } }]} >
                        {top5Debts.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`debtYear${j}`} value={data[`debtYear${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`debtCompany${j}`} value={data[`debtCompany${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`debtGrossLoss${j}`} value={data[`debtGrossLoss${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`debtRecoveriesLoss${j}`} value={data[`debtRecoveriesLoss${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`debtNetLoss${j}`} value={data[`debtNetLoss${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 2 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-4'>Exporter bank details</label>
                    <div className='row'>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">

                          <MultipleSelect
                            onClick={reloadUserBankDetails}
                            Id="Beneficiary bank name"
                            Label="Beneficiary bank name"
                            optiondata={userBanks}
                            onChange={(e) => handleMultiSelect(e, `expBankName`, "id")}
                            value={data[`expBankName`] ? [data[`expBankName`]] : []}
                            name={`expBankName`}
                            labelKey={"beneficiaryBankName"}
                            valKey={"id"}
                            error={errors[`expBankName`]}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Branch"}
                            name={"expBankBranch"} value={data.expBankBranch} error={errors.expBankBranch}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 1"}
                            name={"expbankaddLine1"} value={data.expbankaddLine1} error={errors.expbankaddLine1}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 2"}
                            name={"expbankaddLine2"} value={data.expbankaddLine2} error={errors.expbankaddLine2}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"City"}
                            name={"expbankcity"} value={data.expbankcity} error={errors.expbankcity}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"State"}
                            name={"expbankstate"} value={data.expbankstate} error={errors.expbankstate}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewSelect isAstrix={false} label={"country"}
                            selectData={countrydata} name={"expbankcountry"}
                            value={data.expbankcountry} optionLabel={"name"} optionValue={'value'}
                            onChange={handleChange} error={errors.expbankcountry} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Postal Code"}
                            name={"expbankpostal_code"} value={data.expbankpostal_code} error={errors.expbankpostal_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Account Holder Name"}
                            name={"expbankAccName"} value={data.expbankAccName} error={errors.expbankAccName}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Account Number"}
                            name={"expbankAccNo"} value={data.expbankAccNo} error={errors.expbankAccNo}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"IFSC Code"}
                            name={"expbankIFSC_code"} value={data.expbankIFSC_code} error={errors.expbankIFSC_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"MICR Code"}
                            name={"expbankMICR_code"} value={data.expbankMICR_code} error={errors.expbankMICR_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Account Type"}
                            name={"expbankAcc_Type"} value={data.expbankAcc_Type} error={errors.expbankAcc_Type}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 3 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-12 py-4 my-2'>
                    <label className="font-size-15 font-wt-500 mb-1" ><u>Top 10 Buyer Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Buyers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 4 ? (
                <div className='py-4 px-3'>
                  <ExtraDetailsForLimitApplication userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                    hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                    showContinueButton={true} afterDetailsSavedOn={() => setTab(tab + 1)}
                    loadDataInWriteModeFromCacheIfPresent={true} continueBtnCSS={{ opacity: 0, height: 0 }} />
                </div>
                // <div className='px-5 py-4 pt-0'>
                //   <div className='col-md-12 py-4 mt-2'>
                //     <div>
                //       <label className="font-size-15 font-wt-500 mb-1" >Audited accounts (last 3 year)</label>
                //       <div className='d-flex gap-4 align-items-center'>
                //         <div className='col-md-7'>
                //           <FileInput hideViewDowdBtns={true} name={`audAccountPrev3Years`} value={data[`audAccountPrev3Years`]} error={errors[`audAccountPrev3Years`]}
                //             onChange={handleFile} isEditable={true}
                //             onUploadCancel={() => setData({ ...data, [`audAccountPrev3Years`]: null })}
                //           />
                //         </div>

                //         {!data[`audAccountPrev3Years`]?.signatureId ?
                //           <img className="cursor"
                //             onClick={() => { setSigndoc(data[`audAccountPrev3Years`]?.name); setData({ ...data, docToSign: data[`audAccountPrev3Years`], docSignName: `audAccountPrev3Years` }) }}
                //             src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                //           :
                //           <img style={{ width: '20px' }} className=""
                //             src={"assets/images/doc_sign_filled.svg"} alt="" />
                //         }
                //       </div>

                //     </div>
                //     <div>
                //       <label className="font-size-15 font-wt-500 mb-1" >Specimen Invoice</label>
                //       <div className='d-flex gap-4 align-items-center'>
                //         <div className='col-md-7'>

                //           <FileInput hideViewDowdBtns={true} name={`specimenInvoice`} value={data[`specimenInvoice`]} error={errors[`specimenInvoice`]}
                //             onChange={handleFile} isEditable={true}
                //             onUploadCancel={() => setData({ ...data, [`specimenInvoice`]: null })}
                //           />
                //         </div>
                //         {!data[`specimenInvoice`]?.signatureId ?
                //           <img className="cursor"
                //             onClick={() => { setSigndoc(data[`specimenInvoice`]?.name); setData({ ...data, docToSign: data[`specimenInvoice`], docSignName: `specimenInvoice` }) }}
                //             src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                //           :
                //           <img style={{ width: '20px' }} className=""
                //             src={"assets/images/doc_sign_filled.svg"} alt="" />
                //         }
                //       </div>

                //     </div>
                //     <div>
                //       <label className="font-size-15 font-wt-500 mb-1" >Credit Managment Manual</label>
                //       <div className='d-flex gap-4 align-items-center'>
                //         <div className='col-md-7'>
                //           <FileInput hideViewDowdBtns={true} name={`credManagementManual`} value={data[`credManagementManual`]} error={errors[`credManagementManual`]}
                //             onChange={handleFile} isEditable={true}
                //             onUploadCancel={() => setData({ ...data, [`credManagementManual`]: null })}
                //           />
                //         </div>
                //         {!data[`credManagementManual`]?.signatureId ?
                //           <img className="cursor"
                //             onClick={() => { setSigndoc(data[`credManagementManual`]?.name); setData({ ...data, docToSign: data[`credManagementManual`], docSignName: `credManagementManual` }) }}
                //             src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                //           :
                //           <img style={{ width: '20px' }} className=""
                //             src={"assets/images/doc_sign_filled.svg"} alt="" />
                //         }
                //       </div>

                //     </div>
                //   </div>
                // </div>
              ) : null}
              {tab === 5 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-12 py-4 my-2'>
                    <label className='font-size-14 font-wt-600 my-2'>Select insurer (any 5)</label>
                    <div className='row'>
                      {InsurerList.map((item, index) => {
                        return <div className='col-md-4 mt-4 text-center'>
                          <div className='col-md-12'>
                            <div className="card p-4 cursor rounded-3 chatlist h-100">
                              <div className="d-flex gap-3 pb-3 align-items-center">
                                <div>
                                  <img
                                    onClick={() => {
                                      if (item.isAvailable || environment === "dev") {
                                        handleFinSelection(index)
                                      } else {
                                        toastDisplay("Action not availble", "info")
                                      }
                                    }}
                                    className='cursor mr-3' src={`assets/images/${InsurerList?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                                </div>

                                <img
                                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                                  src={avatarUrl(item.logo)} alt="" />
                                <div className='d-flex gap-2 align-items-center'>
                                  <label className='font-size-14 font-wt-500 text-color1 m-0'>View More</label>
                                  <img src='/assets/images/ArrowBack.png' height={20} width={20} style={{ filter: "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)" }} />
                                </div>
                              </div>
                              <div className="d-flex gap-3 py-3 align-items-center justify-content-between">
                                <div className="d-flex align-items-center gap-2 px-0 cursor">
                                  <div>
                                    <p className="mb-0 font-size-14 text-right">{item.avgRatings.toFixed(2)}
                                    </p>
                                  </div>
                                  <div>
                                    <span>
                                      <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  <p className="font-size-14 mb-1 text-right">{item.reviewsCount}</p>
                                  <p className="font-size-14 mb-1 text-right">{"See reviews"}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 6 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-12 py-4 my-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Exporter details</label>
                    <div className="row">
                      {exorterDetailsForm.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Contact Person Details</label>
                    <div className="row">
                      {contactPersonDetails.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Insurer Details</label>
                    <div className="row">
                      {InsurerDetails.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Business Details</label>
                    <div className="row">
                      {businessDetails.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Exporter Bank Details</label>
                    <div className="row">
                      {expBankDetails.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Turonver</label>
                    <NewTablev2 showSideBorders={true}
                      columns={[{ subColumns: "", subColumnStyle: { width: '25%' } }, { subColumns: '2nd Last Year', subColumnStyle: { width: '25%' } },
                      { subColumns: 'Last Year', subColumnStyle: { width: '25%' } }, { subColumns: 'Current Year', subColumnStyle: { width: '25%' } }]}>
                      {[{ "name": "Turn Over Amount" }].map((i, j) => {
                        return (
                          <tr>
                            <td><label className="font-size-13 font-wt-500 px-2" >{i.name}</label></td>
                            <td>{`${data["salesCurrency"] ? data["salesCurrency"] : ''} ${data[`${i.name}:Year2`] ? data[`${i.name}:Year2`] : 'NA'}`}</td>
                            <td>{`${data["salesCurrency"] ? data["salesCurrency"] : ''} ${data[`${i.name}:Year1`] ? data[`${i.name}:Year1`] : 'NA'}`}</td>
                            <td>{`${data["salesCurrency"] ? data["salesCurrency"] : ''} ${data[`${i.name}:Year0`] ? data[`${i.name}:Year0`] : 'NA'}`}</td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Expected Turonver</label>
                    <div className="my-4" >
                      <NewTablev2 showSideBorders={true} columns={[
                        { subColumns: 'Countries( top 10 )', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Estimated annual turnover (next 12 months)', subColumnStyle: { width: '30%' } },
                        { subColumns: 'No. Of Active Accounts', subColumnStyle: { width: '25%' } },
                        { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '25%' } }]} >
                        {top10Countries.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                {data[`countryName${j}`] || "-"}
                              </td>
                              <td>
                                {data[`estAnnualTO${j}`] || "-"}
                              </td>
                              <td>
                                {data[`noOfActiveAccounts${j}`] || "-"}
                              </td>
                              <td>
                                {data[`TermsOfPayment${j}`] || "-"}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Bad debts</label>
                    <div className="my-4" >
                      <NewTablev2 showSideBorders={true} columns={[
                        { subColumns: 'Year', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Company', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Gross Loss ($)', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Recoveries Loss ($)', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Net Loss ($)', subColumnStyle: { width: '20%' } }]} >
                        {top5Debts.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                {data[`debtYear${j}`] || '-'}
                              </td>
                              <td>
                                {data[`debtCompany${j}`] || "-"}
                              </td>
                              <td>
                                {data[`debtGrossLoss${j}`] || "-"}
                              </td>
                              <td>
                                {data[`debtRecoveriesLoss${j}`] || "-"}
                              </td>
                              <td>
                                {data[`debtNetLoss${j}`] || "-"}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                    <label className="font-size-15 font-wt-500 mb-1" ><u>Top 10 Buyer Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Buyers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                {data[`buyerName${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerAddress${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerCreditLimitRequired${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerTermsOfPayment${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerAnnualSale${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerYearsOfRelation${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerRelationWithClient${j}`] || "-"}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                    <div className='w-100 mt-5'>
                      <label className='font-wt-500 font-size-16'><u>Document</u></label>
                      <div className=''>
                        <ExtraDetailsForLimitApplication userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                          hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                          showContinueButton={false} mainTagMarginPadding0={true} />
                      </div>
                      {/* <div className='col-md-12 py-2 row'>
                        <div className='col-md-6'>
                          <label className="font-size-15 font-wt-500 mb-1" >Audited accounts (last 3 year)</label>
                          <div >
                            <FileInput hideViewDowdBtns={true} name={`audAccountPrev3Years`} value={data[`audAccountPrev3Years`]} error={errors[`audAccountPrev3Years`]}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, [`audAccountPrev3Years`]: null })}
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <label className="font-size-15 font-wt-500 mb-1" >Specimen Invoice</label>
                          <div >
                            <FileInput hideViewDowdBtns={true} name={`specimenInvoice`} value={data[`specimenInvoice`]} error={errors[`specimenInvoice`]}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, [`specimenInvoice`]: null })}
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <label className="font-size-15 font-wt-500 mb-1" >Credit Managment Manual</label>
                          <div >
                            <FileInput hideViewDowdBtns={true} name={`credManagementManual`} value={data[`credManagementManual`]} error={errors[`credManagementManual`]}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, [`credManagementManual`]: null })}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Insurer selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {InsurerList.map((key) => {
                          if (key.isChecked) {
                            return (
                              <label key={key.name}
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {key.name}
                              </label>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className='mt-5'>
                      <div className='d-flex flex-row align-items-center'>
                        <img className='mr-4 cursor' src={data.tcAccepted ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })} />
                        <p className="declarationText mb-0" >{"I hereby declare that the above given information by me is true to my knowledge."}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                className="d-flex finActionTabContainer mx-5 mb-5 p-0" >
                <div
                  onClick={() => {
                    if (tab === 4) {
                      const buttonElement = document.getElementById('extraDetailsForLimitContinueBtn');
                      if (buttonElement) {
                        buttonElement.click();
                      }
                    }
                    else {
                      handleValidations()
                    }
                  }}
                  style={{ borderRight: '2px solid #5CB8D3' }}
                  className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                  <label className={"font-size-16 font-wt-700 text-white cursor"} >{tab === 6 ? "Submit" : "Next"}</label>
                </div>
                <div
                  onClick={() => {
                    if (tab > 0) {
                      setTab(tab - 1)
                    }
                  }}
                  style={{ borderRight: '2px solid #5CB8D3' }} className={`w-25 text-center py-2 ${tab === 0 ? ' disable-bg ' : " "}`}>

                  <label className={`font-size-14 font-wt-500 text-dark cursor `} >Previous</label>
                </div>
                <div
                  onClick={() => { }}
                  style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-2">

                  <label className={"font-size-14 font-wt-500 text-dark cursor"} >Save as draft</label>
                </div>
                <div className="w-25 text-center py-2">
                  <label className={"font-size-14 font-wt-500 text-dark cursor"} >Draft</label>

                </div>
              </div>
            </div>
          </div>
        </main >
        {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />
        }
      </div >

    </>
  )
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}
export default connect(mapStateToProps, null)(TCIKeyAccounts)

