import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import call from "../../service";
import { FileInput } from "../../utils/FileInput";
import avatarUrl from "../../utils/makeAvatarUrl";
import MultipleSelect from "../../utils/MultipleSelect";
import {
  astrix,
  ClearCache,
  convertImageToPdf,
  GetCache,
  getDocDetails,
  handleFileCommonFunction,
  most_used_currencies,
  SetCache,
  toolTip,
} from "../../utils/myFunctions";
import {
  InputForTable,
  InputWithSelect,
  NewInput,
  NewSelect,
} from "../../utils/newInput";
import { NewTable } from "../../utils/newTable";
import { StarRating } from "../../utils/starRating";
import toastDisplay from "../../utils/toastNotification";
import Header from "../partial/header";
import HeaderV2 from "../partial/headerV2";
import Sidebar, { traderTypeIds } from "../partial/sidebar";
import SideBarV2 from "../partial/sideBarV2";
import { PopupMessage } from "../popupMessage";
import config from "../../config.json";
import { Action } from "./action";
import ViewBuyerDetails from "./viewBuyerDetails";
import CheckBuyerHealth from "./checkBuyerHealth";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import TutorialPopup, { TutorialVideoPopup } from "../tutorialPopup";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import Filter from "../InvoiceDiscounting/components/Filter";
import AddBuyerHealth from "./AddBuyerHealth";
import FindBuyers from "./FindBuyers";
import { useHistory } from "react-router";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import NewTablev2 from "../../utils/newTablev2";
import Papa from "papaparse";
import ColumnSelector from "../lcV2/qoutes/columnselector";

const tutorialPopupCSS = {
  0: {
    stageName: "Buyer Management",
    showNext: true,
    showBack: false,
    popup: {
      top: "2rem",
      right: "-20rem",
    },
    arrow: {
      top: "11rem",
      zIndex: 10,
      transform: "rotate(236deg)",
      left: "48.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>
          View all your buyer & their details by clicking on "Buyer Management".
        </span>
      </label>
    ),
  },
  1: {
    stageName: "Buyer Management",
    showNext: false,
    showBack: true,
    popup: {
      top: "14rem",
      right: "-4rem",
    },
    arrow: {
      top: "9rem",
      zIndex: 10,
      transform: "rotate(373deg)",
      left: "54.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>Click here to add new buyer</span>
      </label>
    ),
  },
  2: {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: "-1rem",
      right: "-8rem",
    },
    arrow: {
      top: "8rem",
      zIndex: 100001,
      transform: "rotate(262deg)",
      left: "32.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>Enter buyer name and country & click on continue button.</span>
      </label>
    ),
  },
  3: {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: "-1rem",
      right: "-8rem",
    },
    arrow: {
      top: "8rem",
      zIndex: 100001,
      transform: "rotate(262deg)",
      left: "32.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>Select a DUNS No. & click on continue button.</span>
      </label>
    ),
  },
  4: {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: "-1rem",
      right: "-8rem",
    },
    arrow: {
      top: "8rem",
      zIndex: 100001,
      transform: "rotate(262deg)",
      left: "32.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>Fill buyer details & click on continue button.</span>
      </label>
    ),
  },
  5: {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: "-1rem",
      right: "-8rem",
    },
    arrow: {
      top: "8rem",
      zIndex: 100001,
      transform: "rotate(262deg)",
      left: "32.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>Attach all documents & click on continue button.</span>
      </label>
    ),
  },
  6: {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: "-1rem",
      right: "-8rem",
    },
    arrow: {
      top: "8rem",
      zIndex: 100001,
      transform: "rotate(262deg)",
      left: "32.5%",
    },
    children: (
      <label className="font-size-14 font-wt-600 text-color-value">
        <span>
          Review all form details, click on check box & click on Add Buyer
          button.
        </span>
      </label>
    ),
  },
};

const addBuyerTabs = [
  { name: "Select Buyer" },
  { name: "DUNS No." },
  { name: "Buyer details" },
  // { name: "Documents" },
  // { name: "Select Financier" },
  { name: "Review" },
];

const reviewForm = [
  { name: "Buyer Name", val: "buyerName" },
  {
    name: "Previous year annual sales",
    val: "buyerPrevSale",
    unit: "buyerCurrency",
  },
  { name: "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  {
    name: "Expected current year’s annual sale",
    val: "buyerExpectedSale",
    unit: "buyerCurrency",
  },

  { name: "Email Id", val: "buyerEmail" },
  { name: "Inco Terms", val: "buyerIncoTerms" },
  { name: "Website", val: "buyerWebsite" },
  { name: "Terms of Payment", val: "buyerTermsPayment" },

  { name: "Address", val: "buyerAddress" },
  { name: "Product Details", val: "buyerProductDetails" },

  { name: "Country", val: "buyerCountry" },
  { name: "Postal Code", val: "buyerPostalCode" },
  { name: "DUNS No", val: "buyerDunsNo" },
  { name: "HSN Code", val: "buyerHsnCode" },
];

// const financierData = [
//   { id: 100, name: "Stenn", icon: "stenn.png", rating: 4.6, reviews: 126 },
//   { id: 200, name: "Modifi", icon: "modifi.png", rating: 1, reviews: 130 },
// ]

export const IncoTerms = [
  { name: "EXW" },
  { name: "FCA" },
  { name: "FAS" },
  { name: "FOB" },
  { name: "CFR" },
  { name: "CIF" },
  { name: "CPT" },
  { name: "CIP" },
  { name: "DAP" },
  { name: "DPU" },
  { name: "DDP" },
];

const BuyerManagement = ({ userTokenDetails, navToggleState, cpView }) => {
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const editBuyer = queryParams.get("editBuyer");
  let serarchParam = queryParams.get("search");
  const buyerAction = queryParams.get("action");
  const viewBuyerId = queryParams.get("viewBuyerId");
  const viewUserId = queryParams.get("viewUserId");
  const viewUserTypeId = queryParams.get("viewUserTypeId");

  let cache_data = GetCache("add_buyer_form_data");

  const [dbData, setDbData] = useState([]);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(
    buyerAction === "addNewBuyer"
  );
  const [checkBuyerHealth, setCheckBuyerHealth] = useState({
    show: false,
    data: {},
  });
  const [viewBuyerDetails, setViewBuyerDetails] = useState({
    show: false,
    data: {},
  });
  const [tab, setTab] = useState(0);
  const [data, setData] = useState(
    cache_data["data"] || {
      selectedFinanciers: [],
      buyerNameTitle: "Mr",
      buyerPhoneCode: "91",
      buyerCurrency: "USD",
    }
  );
  const [errors, setErrors] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [showMsgPopup, toggleMsgPopup] = useState(false);
  const [applyLimitPopup, setapplyLimitPopup] = useState(false);

  const [dunsData, setDunsData] = useState([]);
  const [hsnCodes, setHsnCodes] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [filter, setFilter] = useState({
    resultPerPage: 10,
    search: serarchParam ? serarchParam : "",
  });
  const [signdoc, setSigndoc] = useState(false);
  const [totalShipments, setTotalShipments] = useState(0);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({});

  const [lcTutorial, toggleLCTutorial] = useState(
    localStorage.getItem("lcTutorialStep") == 2
  );
  const [tutorialStage, setTutorialStage] = useState(0);
  const [showTutorialVideo, toggleTutorialVideo] = useState({
    show: false,
    link: null,
  });
  const [newBuyerId, setnewBuyerId] = useState("");
  const [annualTurnover, setAnnualTurnover] = useState("Less than 250 Cr");
  const [addBuyerhealth, setaddbuyerhealth] = useState({
    show: false,
    data: {},
  });
  const [findMoreBuyers, setfindMoreBuyers] = useState(false);
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [showPopup, togglePopup] = useState(false);
  const [csvTemplateData, setCSVTemplateData] = useState([]);

  const userData = localStorage.getItem("UserDetails");
  let userToken = userTokenDetails;
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails;
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null;
  const userEmail = userToken.email ? userToken.email : null;
  const userId = userToken.user_id ? userToken.user_id : null;
  const userName = userToken.userName ? userToken.userName : null;
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const ttvExporterCode = userToken.ttvExporterCode
    ? userToken.ttvExporterCode
    : "";
  const subUserName = userToken?.sub_user_name;
  const mainUserName = userToken?.main_user_name;
  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];
    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("buyerManagement");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  useEffect(() => {
    call("POST", "getBuyerListFilters", { userId })
      .then((res) => {
        console.log("getBuyerListFilters then", res);
        setFilterData(res);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (editBuyer && dbData.length) {
      onEdit(0);
    }
    if (viewBuyerId && dbData.length) {
      onView(0);
    }
  }, [editBuyer, dbData, viewBuyerId]);

  const getBuyerDetails = () => {
    //Get Buyer list start
    let objectAPI = {
      userId: viewUserId || userId,
      userEmail: userEmail,
      userTypeId: viewUserTypeId || userTypeId,
      type: "all",
      currentPage: page,
      exactMatchId: editBuyer || viewBuyerId || undefined,
      ...filter,
      ttvExporterCode: ttvExporterCode,
    };
    setshowLoader(true);
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index];
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = [];
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(
                element.accordianId === "hsCodes"
                  ? i[element["labelName"]]
                  : `'${i[element["labelName"]]}'`
              );
            }
          });
        } else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"];
        }
      }
    }
    call("POST", "getBuyersDetail", objectAPI)
      .then((result) => {
        setshowLoader(false);
        console.log(
          "running getBuyersDetail api-->",
          result,
          formatDataForTable(result.buyerData)
        );
        setDbData(result.buyerData);
        setTableData(formatDataForTable(result.buyerData));
        setCount(result.countData);
      })
      .catch((e) => {
        // console.log('error in getBuyersDetail', e);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    getBuyerDetails();
  }, [page, refresh, filterData]);

  useEffect(() => {
    call("POST", "getTotalShipmentsCount", { userId, ttvExporterCode })
      .then((result) => {
        console.log("running getTotalShipmentsCount api-->", result);
        setTotalShipments(result);
      })
      .catch((e) => {
        // console.log('error in getTotalShipmentsCount', e);
      });

    // Get master data start
    call("get", "getallCountry")
      .then((result) => {
        setCountrys(result);
      })
      .catch((e) => {
        console.log("error in getallCountry", e);
      });
    call("get", "getHsnCodes")
      .then((result) => {
        setHsnCodes(result);
      })
      .catch((e) => {
        console.log("error in getHsnCodes", e);
      });
    call("POST", "getLenderDetails")
      .then((result) => {
        setFinancierData(result);
      })
      .catch((e) => {
        console.log("error in getLenderDetails", e);
      });
    // Get master data end
  }, [refresh]);

  async function onDelete(index) {
    setshowLoader(true);
    call("POST", "deleteBuyerDetails", { finDtId: dbData[index]["id"] })
      .then((result) => {
        toastDisplay(result, "success");
        setRefresh(refresh + 1);
        setshowLoader(false);
      })
      .catch((e) => {
        // console.log('error in deleteBuyerDetails', e);
        toastDisplay(e, "error");
        setshowLoader(false);
      });
  }

  async function onEdit(index) {
    setViewBuyerDetails({ show: false, data: {} });
    toggleAddBuyerForm(true);
    setshowLoader(true);
    let selectedBuyer = dbData[index];
    let invoiceDocument = await getDocDetails(
      selectedBuyer.buyersDocId.split(":")[0]
    );
    let poDocument = await getDocDetails(
      selectedBuyer.buyersDocId.split(":")[1]
    );
    setshowLoader(false);
    setData({
      buyerPostalCode: selectedBuyer.buyerPostalCode,
      buyerAddress: selectedBuyer.buyerAddress,
      buyerId: selectedBuyer.id,
      buyerName: selectedBuyer.buyerName,
      buyerCountry: selectedBuyer.buyerCountry,
      buyerEmail: selectedBuyer.buyerEmail,
      buyerContact: selectedBuyer.buyerPhone,
      buyerPhoneCode: selectedBuyer.buyerPhoneCode,
      buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
      buyerWebsite: selectedBuyer.buyerWebsite,
      buyerPrevSale: selectedBuyer.previousAnnualSale,
      buyerExpectedSale: selectedBuyer.currentAnnualSale,
      buyerIncoTerms: selectedBuyer.incoTerms,
      buyerTermsPayment: selectedBuyer.termsOfPayment,
      buyerProductDetails: selectedBuyer.productDetails,
      buyerHsnCode: selectedBuyer.buyerHsnCode
        ? selectedBuyer.buyerHsnCode.split(",")
        : null,
      buyersDocId: selectedBuyer.buyersDocId,
      invoiceDocument: { ...invoiceDocument, noChange: true },
      poDocument: { ...poDocument, noChange: true },
      isEditing: true,
      tcAccepted: true,
    });
  }

  async function onView(index) {
    let selectedBuyer = dbData[index];
    setshowLoader(true);
    setshowLoader(false);
    setViewBuyerDetails({ show: true, data: { ...selectedBuyer, index } });
  }

  function onCheckBuyerHealthClick(duns, index) {
    setshowLoader(true);
    call("POST", "getBuyerHealth", { duns })
      .then((result) => {
        setshowLoader(false);
        setCheckBuyerHealth({ show: true, data: result });
      })
      .catch((e) => {
        // console.log('error in getBuyersDetail', e);
        setshowLoader(false);
        //setaddbuyerhealth({ show: true, data: dbData[index] })
        toastDisplay(e, "error");
      });
  }

  function formatDataForTable(data) {
    let tableData = [];
    let row = [];
    data.forEach((item, index) => {
      let hsnCodes = item.buyerHsnCode ? item.buyerHsnCode.split(",") : [];
      row[0] = item.buyerName;
      row[1] = item.totalShipments || "NA";
      row[2] = (
        <div className="flex-row">
          {hsnCodes.length
            ? hsnCodes.map((item, index) => {
              return (
                <label className="bg-color1 p-1 mx-1 border-radius-5">
                  {item}
                </label>
              );
            })
            : "NA"}
        </div>
      );
      row[3] = item.buyerAddress;
      row[4] = item.countryName;
      row[5] = (
        <div className="">
          <button
            type="button"
            onClick={() =>
              (window.location = `/applyForLimit?buyer=${item.id}`)
            }
            className={`new-btn py-2 px-3 mb-2 text-white`}
          >
            {"Get Finance Limit"}
          </button>
          {item.buyerDUNSNo ? (
            <label
              onClick={() => {
                onCheckBuyerHealthClick(item.buyerDUNSNo, index);
              }}
              className="text-color1 font-wt-500 cursor"
            >
              Check Buyer Health <i class="fas fa fa-arrow-right mx-1"></i>{" "}
            </label>
          ) : null}
        </div>
      );
      tableData.push(row);
      row = [];
    });
    return tableData;
  }

  function resetDataAndHideForm() {
    setData({
      selectedFinanciers: [],
      buyerNameTitle: "Mr",
      buyerPhoneCode: "91",
      buyerCurrency: "USD",
    });
    toggleAddBuyerForm(false);
    setTab(0);
    ClearCache("add_buyer_form_data");
  }

  useEffect(() => {
    if (!data.isEditing) {
      SetCache("add_buyer_form_data", {
        data: {
          ...data,
          invoiceDocument: null,
          poDocument: null,
          docToSign: {},
        },
      });
    }
  }, [data]);

  const handleChange = async (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" });
  };

  const saveCSVData = async () => {
    let tempErrObj = {};
    let nullCheckFor = [
      `Buyer Name*`,
      `Country Code(2 Digit)*`,
      "Address*",
      "Postal Code*",
      "Currency Code(3 Digit)*",
      "Previous Year Annual Sale*",
      "Expected Current Year Annual Sale*",
      "Inco Terms*",
      "Terms of Payment*",
      "Product Details*",
    ];
    for (let index = 0; index < csvTemplateData.length; index++) {
      const element = csvTemplateData[index];
      for (let j = 0; j < nullCheckFor.length; j++) {
        if (element[`Buyer Name*`]) {
          if (!element[nullCheckFor[j]]) {
            tempErrObj[`${nullCheckFor[j]}${index}`] = `Mandatory Field`;
          } else {
            if (element[`Country Code(2 Digit)*`]?.length != 2) {
              tempErrObj[`Country Code(2 Digit)*${index}`] =
                "Country code should be 2 digit only";
            }
            if (element[`Currency Code(3 Digit)*`]?.length != 3) {
              tempErrObj[`Currency Code(3 Digit)*${index}`] =
                "Currency code should be 3 digit only";
            }
          }
        }
      }
    }
    if (!Object.keys(tempErrObj)?.length) {
      setshowLoader(true);
      for (let index = 0; index < csvTemplateData.length; index++) {
        const element = csvTemplateData[index];
        if (element[`Buyer Name*`]) {
          let formData = new FormData();
          formData.append("buyerName", element[`Buyer Name*`]);
          formData.append("buyerCountry", element[`Country Code(2 Digit)*`]);
          formData.append("buyerDUNSNo", element[`Duns No`]);
          formData.append("buyerAddress", element[`Address*`]);
          formData.append("buyerPostalcode", element[`Postal Code*`]);
          if (element[`Website`]) {
            formData.append("buyerWebsite", element[`Website`]);
          }
          if (element[`Email Id`]) {
            formData.append("buyerEmail", element[`Email Id`]);
          }
          if (element[`Contact No`]) {
            formData.append("buyerContact", element[`Contact No`]);
          }
          formData.append("annualSale", element[`Previous Year Annual Sale*`]);
          formData.append(
            "expectedAnnualSale",
            element[`Expected Current Year Annual Sale*`]
          );
          formData.append("annualTurnOver", annualTurnover);
          let buyerCurrency = most_used_currencies.filter(
            (item) => item.code === element[`Currency Code(3 Digit)*`]
          )?.[0];
          formData.append(
            "buyerCurrency",
            buyerCurrency?.["id"]
              ? buyerCurrency?.["id"] + ":" + buyerCurrency["code"]
              : "2:USD"
          );
          formData.append("incoTerms", element[`Inco Terms*`]);
          formData.append("payment", element[`Terms of Payment*`]);
          formData.append("productDetail", element[`Product Details*`]);
          formData.append("userId", userId);
          formData.append("userEmail", userEmail);
          formData.append("userName", userName);
          formData.append("baseUrl", config.baseUrl);
          formData.append("ActionBy", subUserName ? subUserName : mainUserName);
          //Additional fields
          formData.append("buyerHsnCode", element[`HSN Code`] || "");
          formData.append("buyerNameTitle", "Mr");
          formData.append("buyerPhoneCode", element[`Phone Code`]);
          try {
            await call("POST", "insertBuyersDetail", formData);
            toastDisplay("Buyer created successfully", "success")
          } catch (error) {
            console.log("errorIninsertBuyersDetailBulk", error);
          }
        }
      }
      setshowLoader(false);
      toastDisplay("Details saved", "success", () => {
        if (cpView) {
          resetDataAndHideForm();
        } else {
          window.location.reload();
        }
      });
    }
    setErrors({ ...tempErrObj });
  };

  console.log("eeeeeeeeeeeeeeeeeeee", errors);

  const handleCSVData = async (event, index) => {
    event.persist();
    let tempCSVData = csvTemplateData;
    tempCSVData[index][event.target.name] = event.target.value;
    setCSVTemplateData([...tempCSVData]);
    setErrors({ ...errors, [`${event.target.name}${index}`]: "" });
  };

  function getDunsList() {
    setData({
      ...data,
      selectedDuns: null,
      // buyerAddress: "",
      // buyerPostalCode: "",
      buyerDunsNo: "",
    });
    setshowLoader(true);
    call("POST", "getDUNSNo", {
      companyName: data.buyerName,
      countryCode: data.buyerCountry,
      userId,
      userEmail,
    }).then((result) => {
      setshowLoader(false);
      if (result.length > 0) {
        setDunsData(result);
        setTab(1);
      } else {
        // toastDisplay("No Related Buyer Details Found", "error");
        setTab(2);
        setDunsData([]);
        setTutorialStage(4);
      }
      // if (tab == 3) {
      //   setTutorialStage(5)
      // }
      if (tab == 3) {
        setTutorialStage(6);
      }
    }
    )
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex);
      } else if (tabIndex == tab) {
        return null;
      } else {
        return toastDisplay(
          "Click on continue button to go to next form",
          "info"
        );
      }
    }

    let validateFields = [];
    let err = {};
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"];
    }

    if (tab === 2) {
      validateFields = [
        "buyerName",
        "buyerCountry",
        "buyerAddress",
        "buyerPostalCode",
        "buyerPrevSale",
        "buyerExpectedSale",
        "buyerIncoTerms",
        "buyerTermsPayment",
        "buyerProductDetails",
      ];
    }

    // if (tab === 3) {
    //   validateFields = []
    // }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field";
      }
    });

    if (!Object.keys(err).length) {
      let redirectBackTo = localStorage.getItem("redirectBackTo");
      if (redirectBackTo && tab == 2) {
        submitBuyerDetails();
        return null;
      }
      if (tab != 3) {
        if (tab === 0) {
          return getDunsList();
        }
        setTab(tab + 1);
      } else {
        submitBuyerDetails();
      }
    }
    setErrors(err);
  }

  async function submitBuyerDetails() {
    setshowLoader(true);
    let formData = new FormData();
    formData.append("buyerName", data.buyerName);
    formData.append("buyerCountry", data.buyerCountry);
    formData.append("buyerDUNSNo", data.buyerDunsNo);
    formData.append("buyerAddress", data.buyerAddress);
    formData.append("buyerPostalcode", data.buyerPostalCode);
    if (data.buyerWebsite) {
      formData.append("buyerWebsite", data.buyerWebsite);
    }
    if (data.buyerEmail) {
      formData.append("buyerEmail", data.buyerEmail);
    }
    if (data.buyerContact) {
      formData.append("buyerContact", data.buyerContact);
    }
    formData.append("annualSale", data.buyerPrevSale);
    formData.append("expectedAnnualSale", data.buyerExpectedSale);
    formData.append("annualTurnOver", annualTurnover);
    let buyerCurrency = most_used_currencies.filter(
      (item) => item.code === data.buyerCurrency
    )[0];
    formData.append(
      "buyerCurrency",
      buyerCurrency["id"] + ":" + buyerCurrency["code"]
    );
    formData.append("incoTerms", data.buyerIncoTerms);
    formData.append("payment", data.buyerTermsPayment);
    formData.append("productDetail", data.buyerProductDetails);
    formData.append("userId", userId);
    formData.append("userEmail", userEmail);
    formData.append("userName", userName);
    formData.append("baseUrl", config.baseUrl);
    formData.append("ActionBy", subUserName ? subUserName : mainUserName);
    if (!data.invoiceDocument?.noChange) {
      formData.append("previousInvoiceDocument", data.invoiceDocument);
    }
    if (!data.poDocument?.noChange) {
      formData.append("previousPODocument", data.poDocument);
    }
    //Additional fields
    formData.append(
      "buyerHsnCode",
      data.buyerHsnCode && data.buyerHsnCode.length
        ? data.buyerHsnCode.join(",")
        : ""
    );
    formData.append("buyerNameTitle", data.buyerNameTitle);
    formData.append("buyerPhoneCode", data.buyerPhoneCode);
    if (Object.keys(data.buyersAPIDetail || {}).length) {
      formData.append("buyersAPIDetail", JSON.stringify(data.buyersAPIDetail));
    }
    if (data.invoiceDocument?.signatureId) {
      formData.append("invoiceDocSignId", data.invoiceDocument.signatureId);
    }
    if (data.poDocument?.signatureId) {
      formData.append("poDocSignId", data.poDocument.signatureId);
    }
    if (data.isEditing) {
      formData.append("buyerId", data.buyerId);
      formData.append("buyersDocId", data.buyersDocId);
    }
    call(
      "POST",
      `${data.isEditing ? "updateBuyersDetails" : "insertBuyersDetail"}`,
      formData
    )
      .then((result) => {
        setshowLoader(false);
        if (buyerAction === "addNewBuyer") {
          window.close();
        }
        if (data.isEditing) {
          toastDisplay("Buyer details updated successfully", "success")
        } else {
          toastDisplay("Buyer created successfully", "success")
          window.location = '/buyerManagement'
        }
        setnewBuyerId(result.buyer_id);

        toggleMsgPopup(true);
        ClearCache("add_buyer_form_data");
        let redirectBackTo = localStorage.getItem("redirectBackTo");
        if (redirectBackTo) {
          localStorage.removeItem("redirectBackTo");
          window.location = redirectBackTo;
        }
        let afterUpdateCloseTab = localStorage.getItem("afterUpdateCloseTab");
        if (afterUpdateCloseTab) {
          localStorage.removeItem("afterUpdateCloseTab");
          window.close();
        }
        if (lcTutorial) {
          toggleLCTutorial(false);
          localStorage.setItem("lcTutorialStep", 7);
        }
      })
      .catch((err) => {
        // console.log("insertBuyersDetail error:", err)
        setshowLoader(false);
        toastDisplay("Something went wrong", "error");
      });
  }

  const handleFile = (event) => {
    event.persist();
    if (!event.target.files.length) {
      return null;
    } else {
      let file_type = event.target.files[0]["type"].toLowerCase();
      if (
        !(
          file_type.includes("pdf") ||
          file_type.includes("png") ||
          file_type.includes("jpeg")
        )
      ) {
        setErrors({
          ...errors,
          [event.target.name]:
            "Files with pdf, png & jpeg extension are allowed",
        });
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0];
        let fileDataUrl = e.target.result;
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(
            fileDataUrl,
            event.target.files[0]["name"]
          );
          console.log("pdfconversionresp", response);
          fileObj = response["file"];
          fileDataUrl = response["dataUrl"];
          toastDisplay("File converted into pdf format", "success");
        }
        fileObj["filebase64"] = fileDataUrl;
        setData({ ...data, [event.target.name]: fileObj });
        setErrors({ ...errors, [event.target.name]: "" });
      };
    }
  };

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers;
    if (temp.includes(id)) {
      temp = temp.filter((e) => e !== id);
    } else {
      temp.push(id);
    }
    setData({ ...data, selectedFinanciers: temp });
  };

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
      });
    } else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : [],
      });
    }
  };

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1);
    }
  };

  const handleCSV = (event) => {
    event.persist();
    if (!event.target.files.length) {
      return null;
    }
    setErrors({ ...errors, [event.target.name]: "" });
    if (!event.target.files[0]["name"].includes("csv")) {
      setErrors({ ...errors, [event.target.name]: "Please select csv file" });
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      Papa.parse(event.target.files[0], {
        complete: (result) => {
          console.log("csvParseComplete=======>", result);
          if (!result?.data?.length) {
            setErrors({
              ...errors,
              [event.target.name]: "Uploaded CSV is empty",
            });
          } else {
            setCSVTemplateData([...result.data]);
          }
          setData({ ...data, [event.target.name]: event.target.files[0] });
          setErrors({ ...errors, [event.target.name]: "" });
          togglePopup(false);
        },
        header: true,
      });
    };
    reader.readAsText(event.target.files[0]);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (lcTutorial) {
      setTutorialStage(2);
    }
    console.log(booladd, "this in handleClick before toggleAddBuyerForm");
    if (booladd || userPermissions == null) {
      toggleAddBuyerForm(true);
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  };

  const ColumnSelect = ({ filterData, setFilterData }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([]);
    const [draggedItemIndex, setDraggedItemIndex] = useState(null);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    useEffect(() => {
      if (filterData["Grid Columns"]?.data) {
        setUpdatedData([...filterData["Grid Columns"].data]);
      }
    }, [filterData]);

    const handleCheck = (index) => {
      const newData = [...updatedData];
      newData[index].isChecked = !newData[index].isChecked;
      setUpdatedData(newData);
    };

    const handleSave = () => {
      setFilterData({
        ...filterData,
        "Grid Columns": {
          ...filterData["Grid Columns"],
          data: updatedData,
        },
      });
      setShowModal(false);
    };

    const handleDragStart = (index) => {
      setDraggedItemIndex(index);
    };

    const handleDragOver = (index) => {
      const draggedItem = updatedData[draggedItemIndex];
      const items = [...updatedData];
      items.splice(draggedItemIndex, 1);
      items.splice(index, 0, draggedItem);
      setUpdatedData(items);
      setDraggedItemIndex(index);
    };

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-400"
          onClick={handleToggleModal}
          style={{ border: "1px solid lightgrey", backgroundColor: "white" }}
        >
          Columns
        </button>
        {showModal && (
          <div
            className="modal fade show"
            id="itemListModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="itemListModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="itemListModalLabel">
                    Select Columns
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  {updatedData.map((ele, index) => (
                    <div
                      key={index}
                      onDragOver={() => handleDragOver(index)}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      className="mb-1"
                    >
                      <img
                        onClick={() => handleCheck(index)}
                        className="cursor ml-8"
                        src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"}.png`}
                      />
                      <label style={{ marginLeft: "0.5rem" }}>{ele.name}</label>
                    </div>
                  ))}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className={`new-btn w-15 py-2 px-4 text-white cursor mr-1`}
                    style={{ backgroundColor: "#13bef1" }}
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };


  return (
    <>
      <TutorialPopup
        show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]}
        positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"],
        }}
        showSkip={true}
        userId={userId}
        showNext={tutorialPopupCSS[tutorialStage]["showNext"]}
        onNext={() => {
          setTutorialStage(tutorialStage + 1);
        }}
        onBack={() => {
          if (tutorialStage > 0) {
            setTutorialStage(tutorialStage - 1);
          } else {
            localStorage.setItem("lcTutorialStep", 1);
            window.location.reload();
          }
        }}
        showCustomButtonLabel={
          tutorialStage == 1 && count > 0 ? "No new buyer to add" : null
        }
        onCustomButtonLabel={() => {
          toggleLCTutorial(false);
          localStorage.setItem("lcTutorialStep", 7);
          window.location.reload();
        }}
        showBack={tutorialPopupCSS[tutorialStage]["showBack"]}
        videoLinkLabel={"Watch detailed video about Buyer Management"}
        onVideoLinkClick={() =>
          toggleTutorialVideo({
            show: true,
            link: "https://www.youtube.com/embed/tgbNymZ7vqY",
          })
        }
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"],
        }}
      />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      {/* {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      /> */}
      {/* {showMsgPopup ? (
        <PopupMessage
          onClose={() => {
            toggleMsgPopup(false);
            if (data.isEditing) {
              window.location.reload();
            } else {
              if (cpView) {
                resetDataAndHideForm();
              } else {
                setapplyLimitPopup(true);
              }
            }
          }}
          title={
            data.isEditing
              ? "Buyer details updated successfully"
              : "New buyer details are successfully saved!"
          }
          msg={`Buyer details can be viewed & edited any time in "Buyer Management" section.`}
        />
      ) : null}
      {applyLimitPopup ? (
        <FinanceInvoiceModal
          limitinvoice={applyLimitPopup}
          closeSuccess={() => {
            setapplyLimitPopup(false);
            window.location.reload();
          }}
        >
          <div className="text-center">
            <img src="/assets/images/Questionmark.png" />
            <p className="font-size-14 font-wt-400 mt-2">Apply Limit Finance</p>
            <button
              className={`my-4 new-btn w-30 py-2 px-1 text-white cursor`}
              onClick={() => {
                window.location = `applyForLimit?buyer=${newBuyerId}`;
              }}
            >
              Continue
            </button>
            <p
              className="font-size-14 font-wt-400 cursor"
              onClick={() => {
                window.location = "/buyerManagement";
              }}
            >
              <u>Back to buyer management</u>
            </p>
          </div>
        </FinanceInvoiceModal>
      ) : null} */}
      <div className="container-fluid">
        <div className="row">
          {cpView ? null : (
            <SideBarV2 state="nwManagementBuyer" userTokenDetails={userToken} />
          )}
          <main
            role="main"
            className={
              `container-bg ml-sm-auto ${cpView ? " col-lg-12 " : " col-lg-10 "
              } ` + (navToggleState.status ? " expanded-right" : "")
            }
            id="app-main-div"
          >
            {cpView ? null : (
              <HeaderV2
                // title={`Buyer Management ${showAddBuyerForm ? ` > ${data.isEditing ? 'Edit Buyer' : 'Add Buyer'}` : ""}`}
                title={`Buyers`}
                userTokenDetails={userToken}
              />
            )}

            {checkBuyerHealth.show ? (
              <CheckBuyerHealth data={checkBuyerHealth.data} />
            ) : null}

            {addBuyerhealth.show ? (
              <AddBuyerHealth
                data={addBuyerhealth.data}
                countrydata={countrys}
              />
            ) : null}

            {viewBuyerDetails.show ? (
              <ViewBuyerDetails
                data={viewBuyerDetails.data}
                userTokenDetails={userTokenDetails}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ) : null}

            {findMoreBuyers ? (
              <FindBuyers userTokenDetails={userTokenDetails} />
            ) : null}

            {showPopup && (
              <FinanceInvoiceModal
                limitinvoice={showPopup}
                setLimitinvoice={togglePopup}
                closeSuccess={() => togglePopup(false)}
              >
                <div className="col-md-10 mb-2 ml-5">
                  <label className="text-center font-wt-600 text-color1 font-size-14 mb-2">
                    Upload In Bulk
                  </label>
                  <FileInput
                    onUploadCancel={() => {
                      setData({ ...data, buyerAddTemplate: {} });
                    }}
                    name={"buyerAddTemplate"}
                    value={data["buyerAddTemplate"]}
                    onChange={handleCSV}
                    error={errors.buyerAddTemplate}
                    isEditable={true}
                  />
                  {errors.buyerAddTemplate ? (
                    <div class="text-danger mt-2 font-size-12">
                      <i
                        class="fa fas fa-exclamation-circle mr-1"
                        aria-hidden="true"
                      ></i>
                      <b>{errors.buyerAddTemplate}</b>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </FinanceInvoiceModal>
            )}

            {!showAddBuyerForm ? (
              <>
                {!viewBuyerDetails.show &&
                  !checkBuyerHealth.show &&
                  !addBuyerhealth.show &&
                  !findMoreBuyers ? (
                  <div className="row">
                    <div className="w-12">
                      <div className="card h-100 dashboard-card shadow-sm">
                        <label className="value font-wt-600">{count}</label>
                        <label className="font-size-14 font-wt-400">
                          Buyers
                        </label>
                        {/* <div className='d-flex justify-content-between'>
                          <div className=''>
                            <label className='heading'>Total No. of Buyers</label>
                            <label
                              onClick={() => {
                                if (lcTutorial) {
                                  setTutorialStage(2)
                                }
                                toggleAddBuyerForm(true)
                              }}
                              className='subheading cursor'>Add New <img className='ml-2' src={"assets/images/add_icon.png"} /></label>
                          </div>
                          <label className='value ml-4'>{count}</label>
                        </div> */}
                      </div>
                    </div>
                    <div className="w-15">
                      <div className="card h-100 dashboard-card shadow-sm">
                        <label className="value font-wt-600">
                          {totalShipments}
                        </label>
                        <label className="font-size-14 font-wt-400">
                          Shipments
                        </label>
                        {/* <div className='d-flex justify-content-between'>
                          <div className=''>
                            <label className='heading'>Total No. of Shipments</label>
                          </div>
                          <label className='value ml-4'>{totalShipments}</label>
                        </div> */}
                      </div>
                    </div>
                    <div className='w-20' >
                      <div className='' style={tutorialStage == 1 ? {
                        "background": "white",
                        "position": "relative",
                        "zIndex": 10001,
                        "padding": "1rem 0rem 1rem 0rem",
                        "borderRadius": "1rem",
                      } : {}}>
                        <div className='card h-100 addBuyerDiv shadow-sm '
                        //  onClick={() => {
                        //   if (lcTutorial) {
                        //     setTutorialStage(2)
                        //   }
                        //   toggleAddBuyerForm(true)
                        // }}
                        >
                          <div className='d-flex flex-row align-items-center'
                          // onClick={() => {
                          //   if (lcTutorial) {
                          //     setTutorialStage(2)
                          //   } else {
                          //     toggleAddBuyerForm(true)
                          //   }
                          // }
                          // }
                          >
                            <div
                              className="card h-100 addBuyerDiv shadow-sm"
                            // onClick={() => {
                            //   if (lcTutorial) {
                            //     setTutorialStage(2);
                            //   }
                            //   if (booladd || userTokenDetails.UserAccessPermission == null) {
                            //     toggleAddBuyerForm(true)
                            //   } else {
                            //     toastDisplay("Permission to create new user denied. Contact Admin", "info")
                            //   }

                            // }}
                            >
                              <div
                                className="d-flex flex-row align-items-center"
                                onClick={handleClick}
                              >
                                <img
                                  src="/assets/images/add_buyer_icon.svg"
                                  height={30}
                                  width={30}
                                />
                                <label className="font-size-14 font-wt-500 text-color-1 ml-3 mb-0 ">
                                  Add New Buyer
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="mt-2">
                            <div
                              className="card h-100 addBuyerDiv shadow-sm"
                              onClick={() => setfindMoreBuyers(true)}
                            >
                              <div className="d-flex flex-row align-items-center">
                                <img
                                  src="/assets/images/internet_icon.svg"
                                  height={30}
                                  width={30}
                                />
                                <label className="font-size-14 font-wt-500 text-color-1 ml-3 mb-0 ">
                                  Trade Discovery
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {!viewBuyerDetails.show &&
                  !checkBuyerHealth.show &&
                  !addBuyerhealth.show &&
                  !findMoreBuyers ? (
                  <div className="my-4">
                    <div className="filter-div ml-4">
                      <Filter
                        filteredSearch={filteredSearch}
                        setFilteredSearch={setFilteredSearch}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        showFilterBtn={true}
                        showResultPerPage={true}
                        count={count}
                        filter={filter}
                        setFilter={setFilter}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                      <ColumnSelect
                        filterData={filterData}
                        setFilterData={setFilterData}
                      />
                      {/* <div className='row align-items-center'
                       >
                        <label className='col-auto pt-2'>Show</label>
                        <input className='col-auto form-control pagination-input  my-4' type={"number"} name={"resultPerPage"} value={filter.resultPerPage}
                          onChange={(event) => setFilter({ ...filter, resultPerPage: event.target.value })}
                          onKeyDown={handleKeyDown}
                        />
                        <label className='col-auto pt-2'>{`of ${count}`}</label>
                      </div>
                      <div className="input-group mb-3 col-md-3 currency mt-3 pe-1">
                        <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                        <input type="text" name='search' value={filter.search}
                          onKeyDown={handleKeyDown} onChange={(event) => {
                            setFilter({ ...filter, search: event.target.value })
                          }}
                          className="form-control border-start-0" placeholder="Search" />
                      </div> */}
                      {/* <ul class="range-slider">
                        <li>
                          <button type="button" onClick={() => {
                            setRefresh(refresh + 1)
                          }}>
                            <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span></button>
                          <input className='form-control' name="search" id="searchInput" placeholder="Search" value={filter.search}
                            onChange={(event) => {
                              setFilter({ ...filter, search: event.target.value })
                            }} onKeyDown={handleKeyDown} />
                        </li>
                      </ul> */}
                    </div>
                    <div
                      style={
                        lcTutorial && tutorialStage == 0
                          ? {
                            background: "white",
                            position: "relative",
                            zIndex: 10001,
                            padding: "1rem 0rem 1rem 0rem",
                            borderRadius: "1rem",
                          }
                          : {}
                      }
                    >
                      <NewTable
                        filteredSearch={filteredSearch}
                        setFilteredSearch={setFilteredSearch}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        expand={[]}
                        tableExpand={[]}
                        expandKey={"id"}
                        columns={[
                          {
                            name: "Buyer Name",
                            filter: true,
                            filterDataKey: "Buyer Name",
                            sort: [
                              {
                                name: "Sort A-Z",
                                selected: filter.sortBuyerName === "ASC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortBuyerName: "ASC",
                                    sortShipments: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                              {
                                name: "Sort Z-A",
                                selected: filter.sortBuyerName === "DESC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortBuyerName: "DESC",
                                    sortShipments: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                            ],
                          },
                          {
                            name: "Shipments",
                            filter: true,
                            filterDataKey: "Shipment",
                            sort: [
                              {
                                name: "Lowest to Highest",
                                selected: filter.sortShipments === "ASC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortShipments: "ASC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                              {
                                name: "Highest to Lowest",
                                selected: filter.sortShipments === "DESC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortShipments: "DESC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                            ],
                          },
                          {
                            name: "HSN Code",
                            filter: true,
                            filterDataKey: "HSN Code",
                            sort: [
                              {
                                name: "Lowest to Highest",
                                selected: filter.sorthsncode === "ASC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sorthsncode: "ASC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                              {
                                name: "Highest to Lowest",
                                selected: filter.sorthsncode === "DESC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sorthsncode: "DESC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                            ],
                          },
                          {
                            name: "Address",
                            filter: true,
                            filterDataKey: "Address",
                            sort: [
                              {
                                name: "Lowest to Highest",
                                selected: filter.sortAddress === "ASC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortAddress: "ASC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                              {
                                name: "Highest to Lowest",
                                selected: filter.sortAddress === "DESC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortAddress: "DESC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                            ],
                          },
                          {
                            name: "Country",
                            filter: true,
                            filterDataKey: "Country",
                            sort: [
                              {
                                name: "Lowest to Highest",
                                selected: filter.sortCountry === "ASC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortCountry: "ASC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                              {
                                name: "Highest to Lowest",
                                selected: filter.sortCountry === "DESC",
                                onActionClick: () => {
                                  setFilter({
                                    ...filter,
                                    sortCountry: "DESC",
                                    sortBuyerName: false,
                                  });
                                  setRefresh(refresh + 1);
                                },
                              },
                            ],
                          },
                          { name: "Action", filter: false },
                          { name: "", filter: false },
                        ]}
                        data={tableData}
                        options={[
                          { name: "Edit", icon: "edit.png", onClick: onEdit },
                          {
                            name: "Delete",
                            icon: "delete.png",
                            onClick: onDelete,
                          },
                          {
                            name: "View Details",
                            icon: "eye.png",
                            onClick: onView,
                          },
                        ]}
                      />
                      <Pagination
                        perPage={filter.resultPerPage || 10}
                        page={page}
                        totalCount={count}
                        onPageChange={(p) => setPage(p)}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div>
                <i
                  onClick={() => {
                    if (cpView) {
                      toggleAddBuyerForm(false);
                    } else {
                      window.location = "/buyerManagement";
                    }
                  }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                <div className='row position-absolute d-flex align-items-center w-40'
                  style={{ right: '-3rem', top: cpView ? '-1rem' : '5rem' }}>
                  <div className='col-6'>
                    <label className='font-size-15 font-wt-500'>
                      <a className="text-dark cursor-pointer" href="./assets/docs/UploadBuyerTemplate.csv"
                        target="_blank" download>
                        <img className='mr-2' src="assets/images/csv_file_icon.svg" alt="" width="45" height="45"></img>
                        {"Download Template"}</a>
                    </label>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      onClick={() => {
                        togglePopup(true);
                      }}
                      className={`new-btn py-2 px-3 mb-2 text-white`}
                    >
                      {"Upload In Bulk"}
                    </button>
                  </div>
                </div>
                {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                  {addBuyerTabs.map((item, index) => {
                    return (
                      <li>
                        <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")} onClick={() => {
                          handleValidation(index)
                        }}>{item.name}</a>
                      </li>
                    )
                  })}
                </ul> */}

                <div
                  className="bg-white pb-5"
                  style={
                    lcTutorial
                      ? {
                        background: "white",
                        position: "relative",
                        zIndex: 10001,
                        padding: "1rem 0rem 1rem 0rem",
                        borderRadius: "1rem",
                        height: "auto",
                        maxHeight: "40rem",
                        overflow: "auto",
                      }
                      : {
                        height: "40rem",
                        overflow: "auto",
                      }
                  }
                >
                  {csvTemplateData?.length ? (
                    <div style={{ overflowX: "auto" }} className="m-4">
                      <label className="font-size-14 font-wt-600 mb-2">
                        Kindly validate below details before clicking on save
                      </label>
                      <NewTablev2
                        columns={[
                          {
                            subColumns: "Buyer Name*",
                            subColumnStyle: { width: "20%" },
                          },
                          {
                            subColumns: "Country Code(2 Digit)*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Email Id",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Phone Code",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Contact No",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Address*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Postal Code*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Website",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Duns No",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Currency Code(3 Digit)*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Previous Year Annual Sale*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Expected Current Year Annual Sale*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Inco Terms*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Terms of Payment*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "Product Details*",
                            subColumnStyle: { width: "10%" },
                          },
                          {
                            subColumns: "HSN Code",
                            subColumnStyle: { width: "10%" },
                          },
                        ]}
                      >
                        {csvTemplateData.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Buyer Name*`}
                                  value={i[`Buyer Name*`]}
                                  error={errors[`Buyer Name*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Country Code(2 Digit)*`}
                                  value={i[`Country Code(2 Digit)*`]}
                                  error={errors[`Country Code(2 Digit)*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Email Id`}
                                  value={i[`Email Id`]}
                                  error={errors[`Email Id${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  type={"number"}
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Phone Code`}
                                  value={i[`Phone Code`]}
                                  error={errors[`Phone Code${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Contact No`}
                                  value={i[`Contact No`]}
                                  error={errors[`Contact No${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Address*`}
                                  value={i[`Address*`]}
                                  error={errors[`Address*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Postal Code*`}
                                  value={i[`Postal Code*`]}
                                  error={errors[`Postal Code*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Website`}
                                  value={i[`Website`]}
                                  error={errors[`Website${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  type={"number"}
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Duns No`}
                                  value={i[`Duns No`]}
                                  error={errors[`Duns No${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Currency Code(3 Digit)*`}
                                  value={i[`Currency Code(3 Digit)*`]}
                                  error={errors[`Currency Code(3 Digit)*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  type={"number"}
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Previous Year Annual Sale*`}
                                  value={i[`Previous Year Annual Sale*`]}
                                  error={
                                    errors[`Previous Year Annual Sale*${j}`]
                                  }
                                />
                              </td>
                              <td>
                                <InputForTable
                                  type={"number"}
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Expected Current Year Annual Sale*`}
                                  value={
                                    i[`Expected Current Year Annual Sale*`]
                                  }
                                  error={
                                    errors[
                                    `Expected Current Year Annual Sale*${j}`
                                    ]
                                  }
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={"Inco Terms*"}
                                  value={i[`Inco Terms*`]}
                                  error={errors[`Inco Terms*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Terms of Payment*`}
                                  value={i[`Terms of Payment*`]}
                                  error={errors[`Terms of Payment*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`Product Details*`}
                                  value={i[`Product Details*`]}
                                  error={errors[`Product Details*${j}`]}
                                />
                              </td>
                              <td>
                                <InputForTable
                                  onChange={(e) => handleCSVData(e, j)}
                                  name={`HSN Code`}
                                  value={i[`HSN Code`]}
                                  error={errors[`HSN Code${j}`]}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </NewTablev2>
                      <div>
                        <button
                          className={`my-4 new-btn w-25 py-2 px-1 text-white cursor`}
                          onClick={saveCSVData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex justify-content-center mt-5 mb-3">
                        <FormProgressBar
                          tabs={addBuyerTabs}
                          activeTab={tab}
                          label={"name"}
                          separationWidth={"7rem"}
                          handleClick={(i, index) => {
                            handleValidation(index);
                          }}
                        />
                      </div>
                      {tab === 0 ? (
                        <div className="px-4 py-5">
                          <div className="row">
                            <div className="col-md-3">
                              {/* <InputWithSelect isAstrix={true} type={"text"} label={"Buyer's Name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"buyerNameTitle"} selectValue={data.buyerNameTitle}
                            name={'buyerName'} value={data.buyerName} optionLabel={"name"} optionValue={'name'}
                            onChange={handleChange} error={errors.buyerName} /> */}
                              <NewInput
                                isAstrix={true}
                                type={"text"}
                                label={"Buyer's Name"}
                                name={"buyerName"}
                                value={data.buyerName}
                                error={errors.buyerName}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-3">
                              {/* <NewSelect isAstrix={true} label={"Select Country"}
                            selectData={countrys} name={"buyerCountry"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} /> */}
                              <MultipleSelect
                                Id="Select Country"
                                Label="Select Country"
                                selectedvalue="Select Country"
                                optiondata={countrys}
                                onChange={(e) =>
                                  handleMultiSelectchange(
                                    e,
                                    "buyerCountry",
                                    "sortname",
                                    true
                                  )
                                }
                                value={
                                  data.buyerCountry ? [data.buyerCountry] : []
                                }
                                name="buyerCountry"
                                labelKey={"name"}
                                valKey={"sortname"}
                              />
                            </div>
                            <div className="col-md-3">
                              <NewInput
                                type={"text"}
                                label={"Postal Code"}
                                name={"buyerPostalCode"}
                                value={data.buyerPostalCode}
                                onChange={handleChange}
                                error={errors.buyerPostalCode}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}

                  {tab === 1 ? (
                    <div className="px-4 py-5">
                      <label className="mb-4">Select DUNS No.</label>
                      <div className="row dunsCardView px-3">
                        {dunsData.length ? (
                          dunsData.map((item, index) => {
                            return (
                              <div
                                onClick={() => {
                                  setData({
                                    ...data,
                                    selectedDuns: index,
                                    buyerAddress: item.address.street,
                                    buyerPostalCode: item.address.postalCode,
                                    buyerDunsNo: item.duns,
                                    buyersAPIDetail: item,
                                  });
                                }}
                                className={`dunsCard cursor mb-5 mr-5 p-4 ${data.selectedDuns === index
                                  ? "selectedDunsCard"
                                  : ""
                                  } `}
                              >
                                <h6 className="text-color1">{item.duns}</h6>
                                <label className="text-color-label">
                                  Company name
                                </label>
                                <br />
                                <label className="text-color-value">
                                  {item.companyName}
                                </label>
                                <br />
                                <label className="text-color-label">
                                  Address
                                </label>
                                <br />
                                <label className="text-color-value">{`${item.address.street}, ${item.address.city}, ${item.address.countryCode}, ${item.address.postalCode}`}</label>
                              </div>
                            );
                          })
                        ) : (
                          <h6>No Related Buyer Details Found</h6>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {tab === 2 ? (
                    <div className="px-4 py-5">
                      <div className="row">
                        <div className="col-md-4">
                          {/* <InputWithSelect isAstrix={true} type={"text"} label={"Buyer's Name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"buyerNameTitle"} selectValue={data.buyerNameTitle}
                            name={'buyerName'} value={data.buyerName} optionLabel={"name"} optionValue={'name'}
                            onChange={handleChange} error={errors.buyerName} /> */}
                          <NewInput
                            isAstrix={true}
                            type={"text"}
                            label={"Buyer's Name"}
                            name={"buyerName"}
                            value={data.buyerName}
                            error={errors.buyerName}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-4">
                          {/* <NewSelect isAstrix={true} label={"Select Country"}
                            selectData={countrys} name={"buyerCountry"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} /> */}
                          <MultipleSelect
                            Id="Select Country"
                            Label="Select Country"
                            selectedvalue="Select Country"
                            optiondata={countrys}
                            onChange={(e) =>
                              handleMultiSelectchange(
                                e,
                                "buyerCountry",
                                "sortname",
                                true
                              )
                            }
                            value={data.buyerCountry ? [data.buyerCountry] : []}
                            name="buyerCountry"
                            labelKey={"name"}
                            valKey={"sortname"}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            type={"text"}
                            label={"Email Id"}
                            name={"buyerEmail"}
                            value={data.buyerEmail}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect
                            type={"number"}
                            label={"Contact Number"}
                            selectData={countrys}
                            selectName={"buyerPhoneCode"}
                            selectValue={data.buyerPhoneCode}
                            optionLabel={"phonecode"}
                            optionValue={"phonecode"}
                            name={"buyerContact"}
                            value={data.buyerContact}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            isAstrix={true}
                            type={"text"}
                            label={"Address"}
                            name={"buyerAddress"}
                            value={data.buyerAddress}
                            onChange={handleChange}
                            error={errors.buyerAddress}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            isAstrix={true}
                            type={"text"}
                            label={"Postal Code"}
                            name={"buyerPostalCode"}
                            value={data.buyerPostalCode}
                            onChange={handleChange}
                            error={errors.buyerPostalCode}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            type={"text"}
                            label={"Website"}
                            name={"buyerWebsite"}
                            value={data.buyerWebsite}
                            onChange={handleChange}
                            error={errors.buyerWebsite}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            isDisabled={data.selectedDuns != null}
                            type={"text"}
                            label={"DUNS No"}
                            name={"buyerDunsNo"}
                            value={data.buyerDunsNo}
                            onChange={handleChange}
                            error={errors.buyerDunsNo}
                          />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect
                            isAstrix={true}
                            type={"number"}
                            label={"Previous year annual sales"}
                            selectData={currencyData}
                            selectName={"buyerCurrency"}
                            selectValue={data.buyerCurrency}
                            optionLabel={"code"}
                            optionValue={"code"}
                            name={"buyerPrevSale"}
                            value={data.buyerPrevSale}
                            error={errors.buyerPrevSale}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect
                            isAstrix={true}
                            type={"number"}
                            label={"Expected current year’s annual sale"}
                            selectData={currencyData}
                            selectName={"buyerCurrency"}
                            selectValue={data.buyerCurrency}
                            optionLabel={"code"}
                            optionValue={"code"}
                            name={"buyerExpectedSale"}
                            value={data.buyerExpectedSale}
                            error={errors.buyerExpectedSale}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewSelect
                            isAstrix={true}
                            label={"Select Inco Terms"}
                            selectData={IncoTerms}
                            name={"buyerIncoTerms"}
                            value={data.buyerIncoTerms}
                            optionLabel={"name"}
                            optionValue={"name"}
                            onChange={handleChange}
                            error={errors.buyerIncoTerms}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            isAstrix={true}
                            type={"text"}
                            label={"Terms of Payment"}
                            name={"buyerTermsPayment"}
                            value={data.buyerTermsPayment}
                            onChange={handleChange}
                            error={errors.buyerTermsPayment}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput
                            isAstrix={true}
                            type={"text"}
                            label={"Product Details"}
                            name={"buyerProductDetails"}
                            value={data.buyerProductDetails}
                            onChange={handleChange}
                            error={errors.buyerProductDetails}
                          />
                        </div>

                        <div className="col-md-4">
                          <MultipleSelect
                            Id="HSN Code"
                            Label="HSN Code"
                            selectedvalue="HSN Code"
                            optiondata={hsnCodes}
                            onChange={(e) =>
                              handleMultiSelectchange(e, "buyerHsnCode", "code")
                            }
                            value={data.buyerHsnCode || []}
                            name="buyerHsnCode"
                            labelKey={"code"}
                            valKey={"code"}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-row px-2">
                          <label className="form-check-label mt-1 font-size-15 font-wt-500">
                            Buyer Annual Turnover
                          </label>
                        </div>
                        <div
                          className="d-flex flex-row px-2"
                          onClick={() => setAnnualTurnover("Less than 250 Cr")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"Less than 250 Cr"}
                            checked={annualTurnover === "Less than 250 Cr"}
                          />
                          <label className="form-check-label p-0 m-0">
                            Less than 250 Cr
                          </label>
                        </div>
                        <div
                          className="d-flex flex-row px-2"
                          onClick={() => setAnnualTurnover("More than 250 Cr")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"More than 250 Cr"}
                            checked={annualTurnover === "More than 250 Cr"}
                          />
                          <label className="form-check-label p-0 m-0">
                            More than 250 Cr
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* {tab === 3 ? (
                    <div className='px-4 py-5'>
                      <div className="row">

                        <div className="col-md-12">
                          <label className="">Invoice Document</label>
                          <div className="row form-group">
                            <div className="col-md-5">
                              <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                              />
                              {errors.invoiceDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.invoiceDocument}</b></div> : ''}
                            </div>
                            {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label className="">Purchase Order Document</label>
                          <div className="row form-group">
                            <div className="col-md-5">
                              <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "poDocument": null })}
                              />
                              {errors.poDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.poDocument}</b></div> : ''}
                            </div>
                            {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.poDocument && data.poDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}

                  {/* {tab === 4 ? (
                    <div className='px-4 py-5'>
                      <div className="row">
                        {financierData.length && financierData.map((item) => {
                          return (
                            <div className='d-flex mb-5 w-30'>
                              <div className='align-items-center d-flex mr-3'
                                onClick={() => handleSelectedFinanciers(item.id)}
                              >
                                {data.selectedFinanciers.length && data.selectedFinanciers.includes(item.id) ? (
                                  <img className='cursor' src={"assets/images/checked-green.png"} />
                                ) : (
                                  <img className='cursor' src={"assets/images/empty-check.png"} />)}
                              </div>
                              <div
                                className='financierCard px-5 py-4'>
                                <div className='d-flex'
                                  style={{
                                    "height": "2rem",
                                    "overflow": "hidden"
                                  }}
                                >
                                  <img
                                    src={avatarUrl(item.user_avatar)}
                                    className={""}
                                    style={{
                                      width: "30px",
                                      height: "30px"
                                    }}
                                  />
                                  <label
                                    className={"ml-1 overflow-hidden"}
                                  ><b>{item.company_name}</b></label>
                                </div>
                                <div className='row justify-content-between py-3'>
                                  <div>
                                    <label
                                      className={"ml-2"}
                                    >{item.rating}</label>
                                    <StarRating rating={item.rating} />
                                  </div>
                                  <div>
                                    <label
                                    >{item.reviews}</label><br />
                                    <label
                                    >{"See Reviews"}</label>
                                  </div>
                                </div>
                                <div>
                                  <img src={"assets/images/chat.png"} />
                                  <label className='ml-2'>Chat with financier</label>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : null} */}

                  {tab === 3 ? (
                    <div className="p-5">
                      <div className="row">
                        <div className="w-100">
                          <label className="font-wt-500 font-size-16">
                            <u>Buyer Details</u>
                          </label>
                          <div className="row">
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2">
                                    <span className="col-md-5 px-0 BuyerdetailsLabel">
                                      {item.name}
                                    </span>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc">
                                      {" "}
                                      {data[item.val]
                                        ? (item.unit
                                          ? `${data[item.unit]} `
                                          : "") + data[item.val]
                                        : "NA"}
                                    </span>{" "}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                          <div className='row mt-2'>
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className='w-100 my-5'>
                          <label className='font-wt-500'><u>Financier Selected</u></label>
                          <div className='d-flex'>
                            {data.selectedFinanciers.map((item) => {
                              return (
                                <div className='bg-white d-flex text-center align-items-center py-2 mr-3'
                                  style={{
                                    "borderRadius": "10px",
                                    "border": "1px solid #D5D5D5",
                                    "width": "10rem",
                                    "display": "inline-block"
                                  }}
                                >
                                  <label className='w-100 lh-18 m-0 text-color-label'>{financierData.filter(i => i.id == item)[0]["company_name"]}</label>
                                </div>
                              )
                            })}
                          </div>
                        </div> */}
                        {data.isEditing ? null : (
                          <div className="mt-3">
                            <img
                              onClick={() =>
                                setData({
                                  ...data,
                                  tcAccepted: !data.tcAccepted,
                                })
                              }
                              className="cursor mr-3"
                              src={`assets/images/${data.tcAccepted
                                ? "checked-green"
                                : "empty-check"
                                }.png`}
                            />
                            <label>
                              I hereby declare that the above given information
                              by me is true to my knowledge.
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {tab === 3 &&
                    !data.tcAccepted ? null : csvTemplateData?.length ? null : (
                      <div
                        className={
                          tab === 1 ? "buyerContinuBtn row" : "row mx-0 px-3"
                        }
                      >
                        {/* <button
                          type="button"
                          onClick={() => {
                            if (tab === 3) {
                              setIsClicked(true); // Disable button after first click
                            }
                            handleValidation(undefined);
                          }}
                          className={`mx-2 new-btn ${tab === 1 ? "" : "w-25"
                            } py-2 px-2 text-white`}
                        >
                          {tab === 3
                            ? `${data.isEditing ? "Update Buyer" : "Add Buyer"}`
                            : "Continue"}
                        </button> */}
                        <button
                          type="button"
                          onClick={() => {
                            if (tab === 3) {
                              setIsClicked(true); // Disable button after first click
                            }
                            handleValidation(undefined);
                          }}
                          disabled={isClicked && tab === 3} // Disable only for "Add Buyer" or "Update Buyer"
                          className={`mx-2 new-btn ${tab === 1 ? "" : "w-25"} py-2 px-2 text-white ${isClicked && tab === 3 ? "opacity-80 cursor-not-allowed" : ""
                            }`}
                        >
                          {tab === 3
                            ? `${data.isEditing ? "Update Buyer" : "Add Buyer"}`
                            : "Continue"}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            )}


          </main>
        </div>
      </div>

      {/* {signdoc && (
        <SignDocument
          signdoc={signdoc}
          setSigndoc={setSigndoc}
          docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) =>
            setData({ ...data, [data.docSignName]: updatedDoc })
          }
        />
      )} */}
    </>
  );
}
  ;

const mapStateToProps = (state) => {
  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  };
};

export default connect(mapStateToProps, null)(BuyerManagement)
