import { ListItem } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { NewInput, NewSelect } from "../../../utils/newInput";
import SliderComponent from "../../viewProfile/components/SliderComponent";
import AddNewBuyerPopUp from "../../contractManagement/addnewBuyerPopUp";
import DatePicker from "react-datepicker";
import DropdownSearch from "../../tallyReports/Cards/dropdownWithSearch";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FilterNew = ({ filter, setFilter, refresh, setRefresh, count, showResultPerPage, showFilterBtn, filterData, setFilterData,
  showDownloadIcon, onDownloadClick, isAdditionalButton, children, filteredSearch, setFilteredSearch, selectedIndex, setSelectedIndex, resetPage, showSelectOption, setAssignType, setToggle, toggleLabel1, toggleLabel2, showToggleButton,
  singleMultApplication, showDraftCount, onShowDraftClick, draftModeActive, showApplyBtn, activeFilter, handleDateRangeChange, handleStatusFilterChange, handleTagFilterChange, startDate, endDate, uniqueTags, userTokenDetails, handleClearFilter, tablecol, handleColumnVisibilityChange, setTablecol }) => {
  console.log(filterData, "fdddddddddd", filter, "this is filter----", setFilter, "setfilter it is",)
  const [showFilterPopup, toggleFilterPopup] = useState(false)
  const modalRef = useRef(null)
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      if (event.target.name != 'randomSelected') {
        setRefresh(refresh + 1)
        if (resetPage) {
          resetPage()
        }
      }
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, so close it
        toggleFilterPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === 'randomSelected') {
      if (event.target.value <= filter.resultPerPage) {
        setFilter({ ...filter, [event.target.name]: event.target.value })
        return
      } else {
        return
      }
    }
    setFilter({ ...filter, [event.target.name]: event.target.value })
    if (event.target.name === 'search' && resetPage) {
      resetPage()
    }

  }


  const removeFilter = (filterName) => {
    let temp = JSON.parse(JSON.stringify(filterData))
    // console.log("insideRemoveFilterrrrrrrrrrrrr", filterName, temp);
    if (filteredSearch?.length) {
      let temp2 = JSON.parse(JSON.stringify(filteredSearch))
      temp2[filterName]["isFilterActive"] = false
      temp2[filterName]["isAllChecked"] = false
      for (let index = 0; index < temp2[filterName]?.["data"]?.length; index++) {
        const element = temp2[filterName]?.["data"][index];
        element["isChecked"] = false
      }
      setFilteredSearch({ ...temp2 })
    }

    if (temp[filterName]["type"] === "minMaxDate") {
      temp[filterName]["value"] = ["", ""]
    }

    temp[filterName]["isFilterActive"] = false
    temp[filterName]["isAllChecked"] = false
    for (let index = 0; index < temp[filterName]?.["data"]?.length; index++) {
      const element = temp[filterName]?.["data"][index];
      element["isChecked"] = false
    }
    setFilterData({ ...temp })

  }



  const handleCheck = (index) => {
    setTablecol(prevCols =>
      prevCols.map((col, i) =>
        i === index
          ? { ...col, isChecked: !col.isChecked }
          : col
      )
    );
  };

  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    useEffect(() => {
      // Sync the updatedData state with tablecol when modal opens
      setUpdatedData([...tablecol]);
    }, [tablecol]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      const column = updatedData[index];
      if (index < 2) {
        return; // Skip handling checkboxes for the first two fixed columns
      }
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };


    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData.slice(2)); // Only reorder columns starting from the 3rd
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Keep the first two columns fixed, and reorder the rest
      setUpdatedData([...updatedData.slice(0, 2), ...items]);
    };

    const handleSave = () => {
      // Preserve the first two columns (Action and Quotation No) and update only the rest
      const updatedTableCols = [
        ...updatedData.slice(0, 2), // Keep the first two columns unchanged
        ...updatedData.slice(2), // Apply updates to the remaining columns
      ];

      // Update the actual table columns in the parent component
      setTablecol(updatedTableCols); // Ensure tablecol gets updated in the parent

      setShowModal(false); // Close the modal after saving
    };


    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
          }}
        >
          Customize Columns
        </button>
        {showModal && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {updatedData
                            .slice(2) // Exclude the first two columns in the modal
                            .map((ele, index) => (
                              <Draggable
                                key={ele.subColumns}
                                draggableId={ele.subColumns}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className="mb-1"
                                      style={{
                                        border: "2px solid #84d4ef",
                                        padding: "8px",
                                        borderRadius: "0.5rem",
                                        backgroundColor: snapshot.isDragging ? "#d25de9" : "white",
                                        color: snapshot.isDragging ? "white" : "black",
                                        display: "flex", // Use flexbox layout
                                        justifyContent: "space-between", // Push children to opposite ends
                                        alignItems: "center", // Vertically align items
                                        transition: "background-color 0.3s ease",
                                      }}
                                    >
                                      {/* Left side: checkbox and label */}
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                          onClick={() => handleCheck(index + 2)} // Adjust index for checking
                                          className="cursor ml-8"
                                          src={`assets/images/${ele.isFixed
                                            ? "checked-checkbox-24" // Show orange tick if the column is fixed
                                            : ele.isChecked
                                              ? "checked-green" // Show green check if the column is not fixed and checked
                                              : "empty-check" // Show empty check if the column is not fixed and unchecked
                                            }.png`}
                                          style={{
                                            backgroundColor: "white",
                                            cursor: ele.isFixed ? "not-allowed" : "pointer", // Disable cursor for fixed columns
                                            marginRight: "0.5rem",
                                          }}
                                        />

                                        <label>{ele.subColumns}</label>
                                      </div>


                                      <img
                                        src="assets/images/drag-and-drop.png"
                                        alt="Drag Icon"
                                        className="drag-icon"
                                        style={{
                                          cursor: "move",
                                          height: "15px",
                                          marginRight: "1rem",
                                        }}
                                      />
                                    </div>

                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };


  return (
    <>
      <div className={`modal fade ${showFilterPopup && "show"}`} style={showFilterPopup ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0" ref={modalRef}>
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Filters</label>
                <div className="modal-header border-0">
                  {showApplyBtn ?
                    <button type="button"
                      onClick={() => {
                        setRefresh(refresh + 1)
                        toggleFilterPopup(false)
                      }}
                      style={{ right: '5rem', top: '1.5rem' }}
                      className={` border-0 mb-2 text-white enableQuotebtn position-absolute`}>
                      {"Apply"}
                    </button> : null}
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleFilterPopup(false)}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4 filterbody">
              <div>
                {userTokenDetails.main_user_name !== "Admin FOB" && (
                  <div className="d-flex flex-column gap-4">
                    {/* Active Filter Display */}


                    {/* Filters */}
                    <div className="filter-row mb-3">
                      <label style={{ marginRight: "10px", fontWeight: "bold" }}>Tags</label>
                      <NewSelect
                        label="Tags"
                        optionValue="value"
                        optionLabel="label"
                        selectData={uniqueTags.map((tag) => ({
                          value: tag,
                          label: tag,
                        }))}
                        onChange={handleTagFilterChange}
                        className="filter-select"
                      />
                    </div>

                    <div className="filter-row mb-3">
                      <label style={{ marginRight: "10px", fontWeight: "bold" }}>Status</label>
                      <NewSelect
                        label="Status"
                        optionValue="statusValue"
                        optionLabel="statusLabel"
                        selectData={[
                          { statusValue: "0", statusLabel: "Pending" },
                          { statusValue: "1", statusLabel: "Draft" },
                          { statusValue: "2", statusLabel: "Lost" },
                          { statusValue: "3", statusLabel: "Won" },
                          { statusValue: "5", statusLabel: "Cancelled" },
                        ]}
                        onChange={handleStatusFilterChange}
                        className="filter-select"
                      />
                    </div>

                    <div className="filter-row mb-3">
                      <label style={{ marginRight: "10px", }}>Date Range</label>
                      <div
                        className="date-filter p-2"
                        style={{ border: "1px solid lightgrey", borderRadius: "6px" }}
                      >
                        <DatePicker
                          selected={startDate}
                          onChange={handleDateRangeChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          isClearable
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select a date range"
                          className="date-picker-input"
                        />
                      </div>
                    </div>


                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="d-flex align-items-center ml-1 w-100">
        {showResultPerPage ? (
          <div className='row align-items-center'
          >
            <label className='col-auto pt-2'>Show</label>
            <input className='col-auto form-control pagination-input  my-4' type={"number"} name={"resultPerPage"} value={filter.resultPerPage}
              // onChange={(event) => setFilter({ ...filter, resultPerPage: event.target.value })}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <label className='col-auto pt-2'>{`of ${count || 0}`}</label>
          </div>
        ) : null}
        {showFilterBtn ? (
          <div className={showResultPerPage ? "ml-4" : ""}>
            <button
              onClick={() => toggleFilterPopup(!showFilterPopup)}
              type="button" className="btn btn-light bg-white px-4 border border-radius-12 text-start"><img src={"assets/images/filter.png"} alt="" /><span className="px-2">Filter</span></button>
          </div>
        ) : null}
        <div style={{ marginLeft: "1rem" }}>
          <ColumnSelector tablecol={tablecol} setTablecol={setTablecol} />
        </div>

        {activeFilter && (
          <div
            className="active-filters d-flex align-items-center btn btn-light bg-white px-2 border border-radius-12 text-start ml-4"
            style={{

              fontSize: "14px",
            }}
          >
            <span className="active-filter-text">{activeFilter}</span>
            <span
              className="clear-filter-icon"
              onClick={handleClearFilter}
              style={{ cursor: "pointer", fontSize: "1.5rem", marginLeft: "2px" }}
            >
              &times;
            </span>
          </div>
        )}

        {Object.keys(filterData || {}).length ? Object.keys(filterData).map((l, m) => {
          if (l === "Grid Columns") {
            return null
          }
          if (filterData[l]["isFilterActive"]) {
            return (
              <div className={"ml-4"}>
                <button
                  onClick={() => {
                    removeFilter(l)
                    if (showApplyBtn) {
                      setRefresh(refresh + 1)
                    }
                  }}
                  type="button" className="btn btn-light px-4 border-radius-12 text-start bg-color1 border-color1-2px">
                  <span className="px-2 text-color1 font-wt-600 font-size-12 text-center">{l}</span>
                  <button type="button" className="btn-close text-color1 font-size-12" aria-label="Close" onClick={() => {
                    removeFilter(l)
                    if (showApplyBtn) {
                      setRefresh(refresh + 1)
                    }
                  }}></button>
                </button>
              </div>
            )
          }
        }) : null}

        {selectedIndex?.length > 0 &&
          <div className={"ml-4"}>
            <button
              onClick={() => setSelectedIndex([])}
              type="button" className="btn btn-light px-4 border-radius-12 text-start bg-color1 border-color1-2px">
              <span className="px-2 text-color1 font-wt-600 font-size-12 text-center">{`${selectedIndex.length} Selected`}</span>
              <button type="button" className="btn-close text-color1 font-size-12" aria-label="Close" onClick={() => setSelectedIndex([])}></button>
            </button>
          </div>
        }
        {showSelectOption &&
          <div >
            <ul className="ml-3 w-90 nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
              <li className="nav-item p-0 w-50" role="presentation">
                <button onClick={() => {
                  //setPriceKey("plan_amount_usd")
                  setAssignType("Random")
                }} className="nav-link active  roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">
                  <label className="font-size-13 mb-0">Random</label>
                  <input type="number" name='randomSelected' value={filter?.randomSelected}
                    onKeyDown={handleKeyDown} onChange={handleChange} maxLength={filter.resultPerPage}
                    className="w-25 ml-2 assignTaskInput" placeholder="" />
                </button>
              </li>
              <li className="nav-item p-0 w-50" role="presentation">
                <button onClick={(e) => {
                  setAssignType("Start To End")
                }} className="nav-link h-100 w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">

                  <label className="font-size-13 mb-0">Start To End</label>

                </button>
              </li>
            </ul>
          </div>
        }

        {showToggleButton &&
          <div >
            <ul className="ml-3 w-90 nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
              <li className="nav-item p-0 w-40" role="presentation">
                <button onClick={() => {
                  setToggle(toggleLabel1)
                }} className="nav-link active  roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">
                  <label className="font-size-13 mb-0">{toggleLabel1}</label>
                </button>
              </li>
              <li className="nav-item p-0 w-60" role="presentation">
                <button onClick={(e) => {
                  setToggle(toggleLabel2)
                }} className="nav-link h-100 w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">

                  <label className="font-size-13 mb-0">{toggleLabel2}</label>

                </button>
              </li>
            </ul>
          </div>
        }
      </div>

      <div className="d-flex align-items-center w-100 justify-content-end gap-2">
        {singleMultApplication ?
          <div
            onClick={() => { setFilter({ ...filter, singleApplication: !filter.singleApplication }) }}
            className="cursor ml-auto" >
            <img className="cursor" src={filter.singleApplication ? "/assets/images/single_application.png" : "/assets/images/multiple_application.png"} />
          </div> : null}
        <div className={`input-group mb-3 col-md-4 currency mt-3 pe-1 ${showDraftCount ? ' ml-auto ' : ' '}`}>
          <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
          <input type="text" name='search' value={filter?.search}
            onKeyDown={handleKeyDown} onChange={handleChange}
            className="form-control border-start-0" placeholder="Search" />
        </div>
        {showDraftCount ? (
          <div
            style={{ background: '#fff', border: draftModeActive ? "2px solid #AED8FF " : '1px solid #ced4da', borderRadius: '10px' }}
            onClick={onShowDraftClick}
            className="cursor ml-3 px-3 py-1" >
            <label className="cursor" ><span className="font-size-14 font-wt-500 mr-1" >Draft</span><span className="font-size-14 font-wt-600">{showDraftCount}</span></label>
          </div>
        ) : null}
        {showDownloadIcon ? (
          <div
            onClick={onDownloadClick}
            className="cursor" >
            <img className="cursor" src={"/assets/images/download_text_icon.svg"} />
          </div>
        ) : null}
        {isAdditionalButton ? (
          <div>
            {children}
          </div>
        ) : null}
      </div>

    </>
  );
}
export default FilterNew;