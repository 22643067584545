import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const ColumnSelector = ({ filterData, setFilterData }) => {
  const [showModal, setShowModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  console.log(filterData, "filterrrrrrr")
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };


  useEffect(() => {
    if (filterData["Grid Columns"]?.data) {
      setUpdatedData([...filterData["Grid Columns"].data]);
    }
  }, [filterData]);

  const handleCheck = (index) => {
    const newData = [...updatedData];
    newData[index].isChecked = !newData[index].isChecked;
    setUpdatedData(newData);
  };

  const handleSave = () => {
    setFilterData({
      ...filterData,
      "Grid Columns": {
        ...filterData["Grid Columns"],
        data: updatedData,
      },
    })
    setShowModal(false);
  };


  const handleDragStart = (index) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (index) => {
    const draggedItem = updatedData[draggedItemIndex];
    const items = [...updatedData];
    items.splice(draggedItemIndex, 1);
    items.splice(index, 0, draggedItem);
    setUpdatedData(items);
    setDraggedItemIndex(index);
  };

  return (
    <>
      <button
        type="button"
        className="btn column-btn font-size-14 font-wt-400"
        onClick={handleToggleModal}
        style={{ border: "1px solid lightgrey", backgroundColor: "white" }}
      >
        Columns
      </button>
      {showModal && (
        <div
          className="modal fade show"
          id="itemListModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="itemListModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="itemListModalLabel">
                  Select Columns
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleToggleModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>




              <div className="modal-body">
                {updatedData.map((ele, index) => (
                  <div
                    key={index}
                    onDragOver={() => handleDragOver(index)}
                    draggable
                    onDragStart={() => handleDragStart(index)}
                    className="mb-1"
                  >
                    <img
                      onClick={() => handleCheck(index)}
                      className="cursor ml-8"
                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                        }.png`}
                    />
                    <label style={{ marginLeft: '0.5rem' }}>{ele.name}</label>
                  </div>
                ))}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className={`new-btn w-15 py-2 px-4 text-white cursor mr-1`}
                  style={{ backgroundColor: "#13bef1" }}
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleToggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ColumnSelector;
