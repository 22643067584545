import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, decryptData, extractNoFromTxt, GetCache, getDocDetails, getInvoiceFinalChargesObj, mergeMultiplePdfs, most_used_currencies, SetCache, toolTip } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import config from "../../../../config.json"
import SignDocument from "../../components/SignDocument";
import FinanceInvoiceModal from "../../contract/components/financeinvoiceModal";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect } from "../../../../utils/newInput";
import { ToastContainer } from "react-toastify";
import swal from 'sweetalert';
import moment from "moment";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import { NewTable } from "../../../../utils/newTable";
import NewTablev2 from "../../../../utils/newTablev2";
import { documentTable, documentTable2, documentTable4 } from "../../../Dashboard/TableJson";
import { DocumentViewer } from "../../../../utils/documentViewer";
import { DocumentDownloader } from "../../../../utils/DocumentDownloader";
import { aiServerBackendUrl } from "../../../../urlConstants";
import axios from "axios";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const addBuyerTabs = [
  { name: "Documents" },
  { name: "Application" },
  { name: "Review" },
]
const reviewForm = [
  { "name": "Applicant Name", val: "applicantName", unit: "nameTitle" },
  // { "name": "Request amount", val: "reqAmount", unit: "currency" },
  // { "name": "Invoice amount:", val: "contractAmount", unit: "currency" },
  { "name": "Finance credit days", val: "creditDays" },
  // { "name": "Invoice No.", val: "invoiceRefrenceNo" },
  { "name": "Bank", val: "beneficiaryBankTitle" },
  { "name": "BL Number", val: "blNo" },
  { "name": "BL issue date", val: "invoiceIssueDate" },
  { "name": "BL due date", val: "invoiceDueDate" },
  { "name": "Lender", val: "lenderNameTitle" },
  { "name": "Buyer", val: "buyerName" },
  // { "name": "Purchase order no.", val: "poRefrenceNo" }
]

const reviewForm2 = [
  { "name": "Shipment Origin Country", val: "shipmentOriginCountry" },
  { "name": "Shipment Origin Address", val: "shipmentOriginAddress" },
  { "name": "Shipment Origin City", val: "shipmentOriginCity" },
  { "name": "Shipment Origin Postal Code", val: "shipmentOriginPostalCode" },
  { "name": "Shipment Destination Country", val: "shipmentDestinationCountry" },
  { "name": "Shipment Destination Address", val: "shipmentDestinationAddress" },
  { "name": "Shipment Destination City", val: "shipmentDestinationCity" },
  { "name": "Shipment Destination Postal Code", val: "shipmentDestinationPostalCode" },
]


const ApplyMultFinanceBtn = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const view = queryParams.get("view") ? decryptData(queryParams.get("view")) : null

  const [tab, setTab] = useState(0)
  const [signdoc, setSigndoc] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [replaceDoc, setReplaceDoc] = useState({})
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [moreDocsArray2, setMoreDocsArray2] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [userBank, setUserBank] = useState([])
  const [bankMaster, setbankMasters] = useState([]);
  const [finalCharges, setFinalCharges] = useState([{}])
  const [showFees, toggleShowFees] = useState(false);
  const [feesData, setFeesData] = useState([{}]);
  const [multInvPo, setMultInvPo] = useState([{ purchase_orders: [null] }])
  const [tableDocsData, setTableDocsData] = useState([])
  const [invPoComparisonData, setInvPoComparisonData] = useState([])
  const [tempOcrData, setTempOcrData] = useState([])
  const [showInvPoAnalysis, setShowInvPoAnalysis] = useState({})
  const [buyerComparisonTableData, setBuyerComparisonTableData] = useState([])
  // const [itemDetailsComparisonData, setItemDetailsComparisonData] = useState([])
  const [matchedItemDetails, setmatchedItemDetails] = useState([])
  const [mismatchedItemDetails, setmismatchedItemDetails] = useState([])

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null

  let selectedQuote = JSON.parse(localStorage.getItem("selectedQuote"))
  let isLenderModifi = selectedQuote.lender_name.toLowerCase().includes("modifi") ? true : false
  // let isLenderModifi = true
  let limitPendingFrom = localStorage.getItem('limitPendingFrom') != "null"
  let documentStatus = localStorage.getItem('documentStatus')

  const [boolmodifywidth, setboolwidth] = useState(true)
  useEffect(() => {
    let tempArr = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      if (element.isFilled) {
        tempArr.push([`Invoice - ${data["invoiceRefrenceNo" + index]}`, `${data["currency" + index]} ${data["contractAmount" + index]}`,
        <FileInput
          isEditable={false}
          value={data["invoiceDocument" + index]}
        />,
        <>
          {
            !data?.["invoiceDocument" + index]?.["signatureId"] ? (
              <div className="">
                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["invoiceDocument" + index], docSignName: ["invoiceDocument" + index] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
              </div>
            ) : null
          }
          {
            data?.["invoiceDocument" + index]?.["signatureId"] ? (
              <div className="">
                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
              </div>
            ) : null
          }
        </>
        ])
        tempArr.push([`PO - ${data["poRefrenceNo" + index]}`, "-",
        <FileInput
          isEditable={false}
          value={data["poDocument" + index]}
        />,
        <>
          {
            !data?.["poDocument" + index]?.["signatureId"] ? (
              <div className="">
                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["poDocument" + index], docSignName: ["poDocument" + index] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
              </div>
            ) : null
          }
          {
            data?.["poDocument" + index]?.["signatureId"] ? (
              <div className="">
                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
              </div>
            ) : null
          }
        </>])
      }
    }
    setTableDocsData(tempArr)
  }, [tab, data, multInvPo])

  useEffect(() => {
    if (data.logisticDocument?.name) {
      analyzeBLByOCR()
    }
  }, [data.logisticDocument])

  async function analyzeBLByOCR() {
    let formData = new FormData()
    formData.append("doc", data.logisticDocument)
    setshowLoader(true)
    let aiResp = await axios.post(aiServerBackendUrl + "/bl-predict", formData)
    setshowLoader(false)
    setData({
      ...data,
      blNo: aiResp?.data?.bl_no || "",
      invoiceIssueDate: moment(aiResp?.data?.bl_issue_date).isValid() ? moment(aiResp?.data?.bl_issue_date).format("YYYY-MM-DD") : ""
    })
  }

  useEffect(() => {
    if (multInvPo?.length) {
      // console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm", multInvPo);
      analyzeInvoiceDocument()
    }
  }, [multInvPo, data])

  useEffect(() => {
    syncOcrData()
  }, [tempOcrData, data])

  useEffect(() => {
    if (showInvPoAnalysis.show) {
      formatDataForTable()
    }
  }, [showInvPoAnalysis])

  async function formatDataForTable() {
    let temp = []
    temp.push(["Name", showInvPoAnalysis.data?.buyerDetails?.inv?.name || "-", showInvPoAnalysis.data?.buyerDetails?.po?.name || "-", showInvPoAnalysis.data?.buyerDetails?.dnb?.name || "-",
      showInvPoAnalysis.data?.buyerDetails?.comparisoniInvPo?.name || "-", showInvPoAnalysis.data?.buyerDetails?.comparisoniInvDnb?.name || "-"])
    temp.push(["Address", showInvPoAnalysis.data?.buyerDetails?.inv?.address || "-", showInvPoAnalysis.data?.buyerDetails?.po?.address || "-", showInvPoAnalysis.data?.buyerDetails?.dnb?.address || "-",
      showInvPoAnalysis.data?.buyerDetails?.comparisoniInvPo?.address || "-", showInvPoAnalysis.data?.buyerDetails?.comparisoniInvDnb?.address || "-"])
    setBuyerComparisonTableData(temp)
    temp = []
    for (let index = 0; index < showInvPoAnalysis.data?.itemDetails?.matched?.comparison?.length; index++) {
      const element = showInvPoAnalysis.data?.itemDetails?.matched?.comparison[index]
      let invArr = showInvPoAnalysis.data?.itemDetails?.matched?.inv
      let poArr = showInvPoAnalysis.data?.itemDetails?.matched?.po
      temp.push([index + 1, "Invoice", invArr?.[index]?.product_code || "-", invArr?.[index]?.product_description || "-",
      invArr?.[index]?.product_quantity || "-", invArr?.[index]?.product_per_unit_price || "-", invArr?.[index]?.product_total_amount || "-"])

      temp.push([index + 1, "PO", poArr?.[index]?.product_code || "-", poArr?.[index]?.product_description || "-",
      poArr?.[index]?.product_quantity || "-", poArr?.[index]?.product_per_unit_price || "-", poArr?.[index]?.product_total_amount || "-"])

      temp.push([index + 1, "Match (%)", element?.product_code || "-", element?.product_description || "-",
      element?.product_quantity || "-", element?.product_per_unit_price || "-", element?.product_total_amount || "-"])
    }
    setmatchedItemDetails(temp)
    temp = []
    for (let index = 0; index < showInvPoAnalysis.data?.itemDetails?.mismatched?.comparison?.length; index++) {
      const element = showInvPoAnalysis.data?.itemDetails?.mismatched?.comparison[index]
      let invArr = showInvPoAnalysis.data?.itemDetails?.mismatched?.inv
      let poArr = showInvPoAnalysis.data?.itemDetails?.mismatched?.po
      temp.push([index + 1, "Invoice", invArr?.[index]?.product_code || "-", invArr?.[index]?.product_description || "-",
      invArr?.[index]?.product_quantity || "-", invArr?.[index]?.product_per_unit_price || "-", invArr?.[index]?.product_total_amount || "-"])
      temp.push([index + 1, "PO", poArr?.[index]?.product_code || "-", poArr?.[index]?.product_description || "-",
      poArr?.[index]?.product_quantity || "-", poArr?.[index]?.product_per_unit_price || "-", poArr?.[index]?.product_total_amount || "-"])
      temp.push([index + 1, "Match (%)", element?.product_code || "-", element?.product_description || "-",
      element?.product_quantity || "-", element?.product_per_unit_price || "-", element?.product_total_amount || "-"])
    }
    setmismatchedItemDetails(temp)
  }

  async function syncOcrData() {
    let temp = invPoComparisonData
    for (let index = 0; index < tempOcrData.length; index++) {
      const element = tempOcrData[index];
      if (data["invoiceRefrenceNo" + index] && element.invOcr) {
        await call('POST', 'syncInvPoOcrData', { invNo: data["invoiceRefrenceNo" + index], documentType: "inv", invOcr: element.invOcr })
      }
      if (data["invoiceRefrenceNo" + index] && element.poOcr) {
        await call('POST', 'syncInvPoOcrData', { invNo: data["invoiceRefrenceNo" + index], documentType: "po", poOcr: element.poOcr })
      }
      temp[index] = await call('POST', 'analyzeInvoicePoOcr', { invNo: data["invoiceRefrenceNo" + index], buyerId: buyer })
    }
    setInvPoComparisonData([...temp])
  }

  async function analyzeInvoiceDocument() {
    setshowLoader(true)
    let tempData = data
    let tempMultInvPo = multInvPo
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      let invOcr = null
      let poOcr = null
      for (let j = 0; j < element.purchase_orders.length; j++) {
        const item = element.purchase_orders[j];
        if (data["poDocument" + index + ":" + j]?.name && !item) {
          let formData = new FormData()
          formData.append("document", data["poDocument" + index + ":" + j])
          let resp = await call('POST', 'analyzeInvoiceByOCR', formData)
          poOcr = resp?.complete_response || {}
          tempMultInvPo[index]["purchase_orders"][j] = true
          tempData["poRefrenceNo" + index + ":" + j] = resp?.invoice_number || ""
        }
      }
      if (data["invoiceDocument" + index]?.name && !element?.ocrDone) {
        let formData = new FormData()
        formData.append("document", data["invoiceDocument" + index])
        toastDisplay("Analyzing document kindly wait", "info")
        let resp = await call('POST', 'analyzeInvoiceByOCR', formData)
        invOcr = resp?.complete_response || {}
        // console.log("analyzeInvoiceByOCRapirespppppppppppp", resp);
        tempData["invoiceRefrenceNo" + index] = resp?.invoice_number || ""
        tempData["contractAmount" + index] = extractNoFromTxt(resp?.invoice_amount)
        tempData["currency" + index] = resp?.invoice_currency_code || ""
        tempData["applicantName"] = resp?.supplier_name || userName
        tempData["shipmentOriginAddress"] = resp?.supplier_address || ""
        tempData["shipmentOriginCity"] = resp?.supplier_address_components?.city || ""
        tempData["shipmentOriginPostalCode"] = resp?.supplier_address_components?.postalCode || ""
        tempData["shipmentOriginCountry"] = resp?.supplier_address_components?.countrySortName || ""

        tempData["shipmentDestinationAddress"] = resp?.customer_address || ""
        tempData["shipmentDestinationCity"] = resp?.customer_address_components?.city || ""
        tempData["shipmentDestinationPostalCode"] = resp?.customer_address_components?.postalCode || ""
        tempData["shipmentDestinationCountry"] = resp?.customer_address_components?.countrySortName || ""
        tempMultInvPo[index]["ocrDone"] = true
      }
      // console.log("ocrrrrrrrrrrrrrrrrrrrrrrrrr", invOcr, poOcr);
      if (invOcr) {
        let temp = tempOcrData
        temp[index] = temp[index] || {}
        temp[index]["invOcr"] = invOcr
        setTempOcrData([...temp])
      }
      if (poOcr) {
        let temp = tempOcrData
        temp[index] = temp[index] || {}
        if (temp[index]["poOcr"]?.["items"] && poOcr.items?.length) {
          temp[index]["poOcr"]["items"].push(poOcr.items)
        }
        else {
          temp[index]["poOcr"] = poOcr
        }
        setTempOcrData([...temp])
      }
    }
    setData(tempData)
    setshowLoader(false)
  }

  // console.log("dattttttttttttttttttttttttttttttttttttttttt", data);
  console.log("errorssssssssssssssssssssssssssssssssssssssssssss", errors);

  useEffect(() => {
    let tempFinalCharges = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      if (data["contractAmount" + index] && data.creditDays) {
        tempFinalCharges.push(getInvoiceFinalChargesObj(data["contractAmount" + index], data.creditDays, selectedQuote))
      }
    }
    setFinalCharges([...tempFinalCharges])
  }, [data, data.creditDays, multInvPo])


  useEffect(() => {
    let viewOnlyData = {}
    call('POST', 'getBuyersDetailWithDoc', {
      buyersId: buyer,
      applicationId: localStorage.getItem('applicationId')
    }).then(async (result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setshowLoader(true)
      let termSheetDocument = await getDocDetails(localStorage.getItem("termSheet"))
      // If documentStatus is currentDocuments patch it start
      let tempCurrentPostShipmentDocuments = {}
      if (documentStatus === "currentDocuments") {
        let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: localStorage.getItem("applicationId") })
        for (let index = 0; index < limitDocs.data.length; index++) {
          const element = limitDocs.data[index];
          let tempFileObj = await getDocDetails(element.docId)
          let convertedFileObj = tempFileObj.filebase64 ? dataURItoBlob(tempFileObj.filebase64) : null
          if (convertedFileObj) {
            convertedFileObj = new File([convertedFileObj], tempFileObj.name, { type: "application/pdf" });
            convertedFileObj["filebase64"] = tempFileObj.filebase64
            convertedFileObj["signatureId"] = tempFileObj["signatureId"]
          }
          tempCurrentPostShipmentDocuments[element.label?.includes("invoiceDocument") ? "invoiceDocument0" : element.label] = convertedFileObj
          if (element.label?.includes("poDocument") && convertedFileObj) {
            tempCurrentPostShipmentDocuments["poDocument0"] = convertedFileObj
          }
        }
      }
      // If documentStatus is currentDocuments patch it end
      if (view) {
        viewOnlyData = await getInvoice()
      }
      setshowLoader(false)
      if (result.length) {
        // console.log("viewOnlyData=================>", viewOnlyData);
        setData({
          ...data, nameTitle: "Mr", currency0: "USD", applicantName: userName, buyerName: result[0]["buyerName"],
          buyerPosition: result[0]["buyerPosition"], nameAuthorizedSignatory: result[0]["nameAuthorizedSignatory"],
          buyerEmail: result[0]["buyerEmail"], buyerPhoneCode: result[0]["buyerPhoneCode"], buyerContact: result[0]["buyerPhone"],
          buyerIdentifier: result[0]["identifier"], incoTerms: result[0]["incoTerms"],
          lenderId: selectedQuote.lender_id, termSheetDocument, lenderNameTitle: selectedQuote.lender_name, ...viewOnlyData,
          ...tempCurrentPostShipmentDocuments
        })
      }
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      // console.log('running getUserBanks api -->', result);
      setUserBank(result.message);
    }).catch((e) => {
      // console.log('error in getUserBanks', e);
    })

    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    // Get bank master list that are involved in trade finance.
    call("GET", "getBanksListMaster")
      .then((result) => {
        setbankMasters(result);
      })
      .catch((e) => {
        console.log("Error while querying getBanksListMaster:", e);
      });
  }, [])

  let docIndex = {
    invoiceDocument: 0,
    poDocument: 1,
    logisticDocument: 2,
    invReportDocument: 3,
    grnDocument: 4
  }

  async function replaceDocument() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("replaceOnlyDoc", true)
    Object.keys(replaceDoc).map(key => {
      formData.append(key, data[key])
      if (data[key]["signatureId"]) {
        formData.append(key + "SignId", data[key]["signatureId"])
      }
    })
    formData.append("previousDocIds", data.docsId)
    formData.append("userId", userId)
    formData.append("invRefNo", view)
    if (data.modifi_deal_id) {
      formData.append("modifi_deal_id", data.modifi_deal_id)
    }
    call("POST", "updateInvoiceDiscounting", formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay('Updated successfully', "success", () => setTimeout(() => {
          window.location = '/apply-finance'
        }, 1000))
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        // console.log("error in updateInvoiceDiscounting", e);
      });
  }

  async function getInvoice() {
    return new Promise(resolve => {
      let viewOnlyData = {}
      call('POST', 'getInvDiscoutingDetails', { invNo: view }).then(async (result) => {
        // console.log('Invoice Get Discounting API  =====>', result);
        if (result && result.length) {
          let temp = result[0]
          let docsId = temp["tbl_doc_id"].split(",")

          viewOnlyData["modifi_deal_id"] = temp["modifi_deal_id"]
          viewOnlyData["tcAccepted"] = true
          viewOnlyData["docsId"] = docsId
          viewOnlyData["invoiceDocument"] = await getDocDetails(docsId[0])
          viewOnlyData["poDocument"] = await getDocDetails(docsId[1])
          viewOnlyData["logisticDocument"] = await getDocDetails(docsId[2])
          viewOnlyData["invReportDocument"] = await getDocDetails(docsId[3])
          viewOnlyData["grnDocument"] = await getDocDetails(docsId[4])
          viewOnlyData["packingListDocument"] = await getDocDetails(docsId[5])
          viewOnlyData["shippingBillDocument"] = await getDocDetails(docsId[6])

          viewOnlyData["poRefrenceNo"] = temp["po_no"]
          viewOnlyData["invoiceRefrenceNo"] = temp["reference_no"]
          viewOnlyData["beneficiaryBankName"] = temp["bankList"].split(":")[0] / 1
          viewOnlyData["currency"] = currencyData.filter(i => {
            if (i.id / 1 == temp["currency"] / 1) {
              return i
            }
          })[0]["code"]
          viewOnlyData["contractAmount"] = temp["contract_amount"]
          viewOnlyData["invoiceIssueDate"] = temp["issue_date"]
          viewOnlyData["invoiceDueDate"] = temp["due_date"]
          viewOnlyData["reqAmount"] = temp["amount"]
          viewOnlyData["lenderId"] = temp["lender_id"] / 1
          viewOnlyData["creditDays"] = temp["credit_days"]


          viewOnlyData["shipmentOriginCountry"] = temp["shipment_origin_country"]
          viewOnlyData["shipmentOriginAddress"] = temp["shipment_origin_address"]
          viewOnlyData["shipmentOriginCity"] = temp["shipment_origin_city"]
          viewOnlyData["shipmentOriginPostalCode"] = temp["shipment_origin_postal_code"]

          viewOnlyData["shipmentDestinationCountry"] = temp["shipment_destination_country"]
          viewOnlyData["shipmentDestinationAddress"] = temp["shipment_destination_address"]
          viewOnlyData["shipmentDestinationCity"] = temp["shipment_destination_city"]
          viewOnlyData["shipmentDestinationPostalCode"] = temp["shipment_destination_postal_code"]

          resolve(viewOnlyData)
        }
      }).catch((e) => {
        resolve({})
        // console.log('error in Invoice Get Discounting API  =====>', e);
      })
    })
  }

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === "beneficiaryBankName") {
      let beneficiaryBankTitle = userBank.filter(i => {
        if (i.id == event.target.value) {
          return i
        }
      })[0]["beneficiaryBankName"]
      setData({ ...data, [event.target.name]: event.target.value, beneficiaryBankTitle })
      setErrors({ ...errors, [event.target.name]: "" })
      return null
    }
    else if (event.target.name === "contractAmount") {
      setData({ ...data, [event.target.name]: event.target.value, reqAmount: event.target.value })
      setErrors({ ...errors, [event.target.name]: "", reqAmount: "" })
      return null
    }
    else if (event.target.name === "invoiceIssueDate" && event.target.value && data.creditDays) {
      let invoiceDueDate = moment(event.target.value, "YYYY-MM-DD").add(data.creditDays / 1, "days").format("YYYY-MM-DD")
      setData({ ...data, [event.target.name]: event.target.value, invoiceDueDate })
      setErrors({ ...errors, [event.target.name]: "", creditDays: "" })
      return null
    }
    else if (event.target.name === "creditDays" && event.target.value && data.invoiceIssueDate) {
      let invoiceDueDate = moment(data.invoiceIssueDate, "YYYY-MM-DD").add(event.target.value / 1, "days").format("YYYY-MM-DD")
      setData({ ...data, [event.target.name]: event.target.value, invoiceDueDate })
      setErrors({ ...errors, [event.target.name]: "", creditDays: "" })
      return null
    }
    let tempData = { ...data, [event.target.name]: event.target.value }
    // merge all po number start
    for (let index = 0; index < multInvPo.length; index++) {
      let item = multInvPo[index]
      let tempPoNo = ""
      for (let j = 0; j < item.purchase_orders.length; j++) {
        if (tempData["poRefrenceNo" + index + ":" + j]) {
          tempPoNo = tempPoNo ? tempPoNo + ":" + tempData["poRefrenceNo" + index + ":" + j] : tempData["poRefrenceNo" + index + ":" + j]
        }
      }
      if (tempPoNo) {
        tempData["poRefrenceNo" + index] = tempPoNo
      }
    }
    // merge all po number end
    setData(tempData)
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
  }



  const handleFileWithAsyncAwait = async (event, poNo, poSubNo, prevData, tempMultInvPoLength) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setshowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setshowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          if (poNo != undefined && poSubNo != undefined) {
            let allPosPdfArray = []
            for (let index = 0; index < tempMultInvPoLength; index++) {
              if (poSubNo / 1 == index / 1) {
                allPosPdfArray[index] = fileObj
              }
              else if (prevData["poDocument" + poNo + ":" + index]?.name) {
                allPosPdfArray[index] = prevData["poDocument" + poNo + ":" + index]
              }
            }
            if (allPosPdfArray?.length) {
              tempData["poDocument" + poNo] = await mergeMultiplePdfs(allPosPdfArray, "combinedPos")
            }
          }
          // merge current po with previous data end
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }



  const handleFile = async (event) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      // if (event.target.name.includes("otherDoc")) {
      //   setAddMoreDoc(addMoreDoc.concat(null))
      // }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        if (view) {
          setReplaceDoc({ ...replaceDoc, [event.target.name]: "" })
        }
        let tempData = { ...data, [event.target.name]: fileObj }
        setData(tempData)
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  // console.log("dataaaaaaaaaaaaaaaaaaaaaaaassssssss", feesData);

  useEffect(() => {
    if (tab / 1 == 1 && userBank.length / 1 == 1) {
      setData({ ...data, beneficiaryBankName: userBank[0]['id'], beneficiaryBankTitle: userBank[0]["beneficiaryBankName"] })
    }
  }, [tab])

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        toggleShowFees(false)
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}

    if (tab === 0 && !view) {
      // if (data.contractAmount && selectedQuote.financeLimit && data.contractAmount > selectedQuote.financeLimit) {
      //   toastDisplay(`Invoice amount can't be more than pending finance limit of ${selectedQuote.financeLimit} `, "info")
      //   return null
      // }
    }

    if (tab === 1) {
      validateFields = ["applicantName", "buyerName", "buyerEmail", "buyerPosition", "buyerContact", "nameAuthorizedSignatory", "beneficiaryBankName",
        "invoiceIssueDate", "invoiceDueDate", "lenderId", "creditDays"]
      if (isLenderModifi) {
        validateFields = validateFields.concat("shipmentOriginCountry", "shipmentOriginAddress", "shipmentOriginCity", "shipmentOriginPostalCode",
          "shipmentDestinationCountry", "shipmentDestinationAddress", "shipmentDestinationCity", "shipmentDestinationPostalCode")
      }
    }

    if (tab === 0) {
      // Validating multiple invoice & po start
      let tempMultInvPo = multInvPo
      let tempTotalInvoiceAmount = 0
      for (let index = 0; index < multInvPo.length; index++) {
        let tempErr = {}
        if (!data["invoiceRefrenceNo" + index]) {
          tempErr["invoiceRefrenceNo" + index] = "Mandatory Field"
        }
        if (!data["contractAmount" + index]) {
          tempErr["contractAmount" + index] = "Mandatory Field"
        }
        if (data["contractAmount" + index]) {
          tempTotalInvoiceAmount += data["contractAmount" + index] / 1
          if (tempTotalInvoiceAmount / 1 > selectedQuote?.financeLimit / 1) {
            tempErr["contractAmount" + index] = `Total invoice amount can't be greater than available limit of '${selectedQuote?.financeLimit / 1}' `
          }
        }
        if (!data["currency" + index]) {
          tempErr["currency" + index] = "Mandatory Field"
        }
        if (!data?.["invoiceDocument" + index]?.name) {
          tempErr["invoiceDocument" + index] = "Mandatory Field"
        }
        for (let j = 0; j < multInvPo[index].purchase_orders.length; j++) {
          // if (!data?.["poRefrenceNo" + index + ":" + j]) {
          //   tempErr["poRefrenceNo" + index + ":" + j] = "Mandatory Field"
          // }
          if (!data?.["poDocument" + index + ":" + j]?.name) {
            tempErr["poDocument" + index + ":" + j] = "Mandatory Field"
          }
        }
        if (!Object.keys(tempErr).length) {
          tempMultInvPo[index] = { ...tempMultInvPo[index], isFilled: true }
        }
        err = { ...err, ...tempErr }
      }
      setMultInvPo([...tempMultInvPo])
      // Validating multiple invoice & po end
    }

    if (tab === 0) {
      if (!(data.logisticDocument && data.logisticDocument.name)) {
        err["logisticDocument"] = "Mandatory field"
      }
    }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab === 2 || (tab === 1 && isLenderModifi && !view)) {
        if (view) {
          replaceDocument()
          return null
        }

        for (let index = 0; index < multInvPo.length; index++) {
          const element = multInvPo[index];
          let isLastInvoice = (index / 1 + 1) == multInvPo.length / 1

          setshowLoader(true)
          let formData = new FormData()

          formData.append("blNo", data.blNo || data["invoiceRefrenceNo" + index])

          if (isLenderModifi) {
            formData.append("showFees", showFees)
            formData.append("quoteId", feesData?.[index]?.quoteId)
            formData.append("modifiCompleteResp", JSON.stringify(feesData?.[index]?.completeResp || {}))
          }
          if (index == 0) {
            formData.append("applicationId", localStorage.getItem('applicationId'))
          }
          else {
            let nextApplicationId = await call('POST', 'getNextApplicationIdForInvoice', { previousInvoiceNo: data["invoiceRefrenceNo" + (index - 1)] })
            formData.append("applicationId", nextApplicationId)
          }

          formData.append("userId", userId)
          formData.append("applicantEmail", userEmail)

          formData.append("selectedBuyerLimit", JSON.stringify({ limit: selectedQuote.assignValue }))
          formData.append("contractAmount", data["contractAmount" + index])
          formData.append("invoiceRefrenceNo", data["invoiceRefrenceNo" + index])
          formData.append("poRefrenceNo", data["poRefrenceNo" + index] || "NA")

          formData.append("invoiceIssueDate", data.invoiceIssueDate)
          formData.append("invoiceDueDate", data.invoiceDueDate)
          formData.append("reqAmount", data["contractAmount" + index])
          formData.append("creditDays", data.creditDays)

          formData.append("currency", currencyData.filter(i => {
            if (i.code === data["currency" + index]) {
              return i
            }
          })[0]["id"])

          formData.append("currencyName", data["currency" + index])
          formData.append("lenderId", selectedQuote.lender_id)
          formData.append("lenderName", selectedQuote.lender_name)
          formData.append("lenderEmail", bankMaster.filter(i => {
            if (i.id / 1 == data.lenderId / 1) {
              return i
            }
          })[0]["email_id"])

          formData.append("buyerId", buyer)
          formData.append("buyername", data.buyerName)

          formData.append("buyerEmail", data.buyerEmail)
          formData.append("bankListId", data.beneficiaryBankName)
          formData.append("bankListName", data.beneficiaryBankTitle)
          formData.append("logisticId", 0)

          formData.append("logisticName", "Others")
          formData.append("nameAuthorizedSignatory", data.nameAuthorizedSignatory)
          formData.append("buyerPosition", data.buyerPosition)
          formData.append("buyerContact", data.buyerContact)

          formData.append("invoiceDocument", data["invoiceDocument" + index])
          formData.append("poDocument", data["poDocument" + index])
          formData.append("logisticDocument", data.logisticDocument)

          if (data["invoiceDocument" + index]?.signatureId) {
            formData.append("invoiceDocumentSignId", data["invoiceDocument" + index]?.signatureId)
          }
          if (data["poDocument" + index]?.signatureId) {
            formData.append("poDocumentSignId", data["poDocument" + index]?.signatureId)
          }
          if (data.logisticDocument.signatureId) {
            formData.append("logisticDocumentSignId", data.logisticDocument.signatureId)
          }

          if (data.invReportDocument && data.invReportDocument.name) {
            formData.append("invReportDocument", data.invReportDocument)
            if (data.invReportDocument.signatureId) {
              formData.append("invReportDocumentSignId", data.invReportDocument.signatureId)
            }
          }
          if (data.grnDocument && data.grnDocument.name) {
            formData.append("grnDocument", data.grnDocument)
            if (data.grnDocument.signatureId) {
              formData.append("grnDocumentSignId", data.grnDocument.signatureId)
            }
          }
          if (data.packingListDocument && data.packingListDocument.name) {
            formData.append("packingListDocument", data.packingListDocument)
            if (data.packingListDocument.signatureId) {
              formData.append("packingListDocumentSignId", data.packingListDocument.signatureId)
            }
            try {
              if (selectedQuote?.lender_id / 1 == 51) {
                let tempFormData = new FormData()
                tempFormData.append("file", data.packingListDocument)
                tempFormData.append("userEmail", userEmail)
                await call("POST", "uploadAdditionalStennDocument", tempFormData)
              }
            } catch (error) {
              console.log("error occured while sending packingListDocument to stenn", error);
            }
          }
          if (data.shippingBillDocument && data.shippingBillDocument.name) {
            formData.append("shippingBillDocument", data.shippingBillDocument)
            if (data.shippingBillDocument.signatureId) {
              formData.append("shippingBillDocumentSignId", data.shippingBillDocument.signatureId)
            }
            try {
              if (selectedQuote?.lender_id / 1 == 51) {
                let tempFormData = new FormData()
                tempFormData.append("file", data.shippingBillDocument)
                tempFormData.append("userEmail", userEmail)
                await call("POST", "uploadAdditionalStennDocument", tempFormData)
              }
            } catch (error) {
              console.log("error occured while sending shippingBillDocument to stenn", error);
            }
          }

          formData.append("sellerId", userId)
          formData.append("dnc", "on")
          formData.append("otherDocMetaData", null)
          formData.append("baseUrl", config.baseUrl)
          formData.append("applicantName", data.applicantName)
          formData.append("userEmail", userEmail)
          formData.append("userIdentifier", userIdentifier)
          formData.append("buyerIdentifier", data.buyerIdentifier)
          formData.append("incoTerms", data.incoTerms)
          formData.append("bankAccountNo", userBank.filter(i => {
            if (i.id / 1 == data.beneficiaryBankName / 1) {
              return i
            }
          })[0]["beneficiaryAccountNo"])


          formData.append("shipmentOriginCountry", data.shipmentOriginCountry)
          formData.append("shipmentOriginAddress", data.shipmentOriginAddress)
          formData.append("shipmentOriginCity", data.shipmentOriginCity)
          formData.append("shipmentOriginPostalCode", data.shipmentOriginPostalCode)

          formData.append("shipmentDestinationCountry", data.shipmentDestinationCountry)
          formData.append("shipmentDestinationAddress", data.shipmentDestinationAddress)
          formData.append("shipmentDestinationCity", data.shipmentDestinationCity)
          formData.append("shipmentDestinationPostalCode", data.shipmentDestinationPostalCode)


          // Display the key/value pairs
          // for (var pair of formData.entries()) {
          //   console.log(pair[0] + ', ' + pair[1]);
          // }
          console.log(formData, "this is formData----->>>>>/////")
          try {
            let result = await call("POST", "applyInvoiceDiscounting", formData)
            // console.log("apiresultapplyInvoiceDiscounting===>", result);
            setshowLoader(false)
            if (isLenderModifi && !showFees) {
              let tempFeesData = []
              tempFeesData.push(result)
              setFeesData([...tempFeesData])
              if (isLastInvoice) {
                toastDisplay("Kindly check fees structure to continue", "info");
                toggleShowFees(true)
                setTab(2)
              }
            }
            else {
              if (isLastInvoice) {
                // toastDisplay('Applied for finance successfully', "success")
                toastDisplay('Applied for finance successfully', "success", () => setTimeout(() => {
                  window.location = '/apply-finance'
                }, 1000))
              }
            }
          } catch (error) {
            console.log("error in applyInvoiceDiscounting", error);
            setshowLoader(false)
            toastDisplay(error, "error");
          }
        }
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors(err)
  }

  const base64ToBlob = (base64, mime) => {
    try {
      // Remove the data URI prefix if present
      const base64WithoutPrefix = base64.includes(',') ? base64.split(',')[1] : base64;

      const byteCharacters = atob(base64WithoutPrefix);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    } catch (error) {
      console.error("Error decoding base64:", error);
      return null;
    }
  };

  const openPdfInNewTab = (file) => {
    if (file?.filebase64) {
      const pdfBlob = base64ToBlob(file.filebase64, "application/pdf");
      if (pdfBlob) {
        const blobUrl = URL.createObjectURL(pdfBlob);

        // Open the Blob URL in a new tab
        window.open(blobUrl, '_blank');
      } else {
        console.error("Failed to create Blob from base64");
      }
    } else if (file?.url) {
      // Open the file URL directly
      window.open(file.url, '_blank');
    }
  };
  useEffect(() => {
    if (viewDoc.show && viewDoc.doc) {
      openPdfInNewTab(viewDoc.doc);
    }
  }, [viewDoc.show, viewDoc.doc]);

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {/* <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} /> */}
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_apply-finance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for Finance"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/apply-finance"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className="px-5 pt-5" >
                    <label
                      className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                    ><u>{`Invoice & Purchase Order`}</u></label>

                    <NewTablev2 columns={documentTable4}>
                      {multInvPo.map((i, j) => {
                        return <>
                          <tr>
                            <td>
                              <div className="d-flex pt-3" >
                                <label
                                  className="font-size-14 text-color-0C0C0C font-wt-500"
                                >{`Invoice`}</label>
                                {Object.keys(invPoComparisonData[j] || {})?.length ? (<img
                                  src={'assets/images/details_icon.png'}
                                  style={{
                                    width: 20,
                                    height: 20
                                  }}
                                  className="mx-3 cursor"
                                  onClick={() => {
                                    setShowInvPoAnalysis({ show: true, data: invPoComparisonData[j] })
                                  }}
                                />) : null}
                              </div>
                              {i.purchase_orders.map((o, p) =>
                                <div className="mt-3" >
                                  <label
                                    className="font-size-14 text-color-0C0C0C font-wt-500" >Purchase Order</label>
                                </div>
                              )}
                            </td>
                            <td>
                              <div>
                                <FileInput hideViewDowdBtns={true} name={"invoiceDocument" + j} value={data["invoiceDocument" + j]} error={errors["invoiceDocument" + j]}
                                  isEditable={true} onChange={handleFile} boolmodifywidth={boolmodifywidth}
                                  onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j]: null })}
                                />
                                {errors["invoiceDocument" + j] ? <div class="text-danger mt-2 font-size-12">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors["invoiceDocument" + j]}</b></div> : ''}
                              </div>
                              {i.purchase_orders.map((o, p) => {
                                return (
                                  <div className="mt-3">
                                    <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                      onMultipleDragDrop={async (e) => {
                                        let tempErr = errors
                                        let tempData = data
                                        for (let index = 0; index < e.target.files.length; index++) {
                                          let element = e.target.files[index];
                                          let customEvent = {
                                            target: { name: "poDocument" + j + ":" + (p + index), files: [element] },
                                            persist: () => console.log("...")
                                          }
                                          let resp = await handleFileWithAsyncAwait(customEvent, j, p + index, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                          if (resp.status / 1 == 0) {
                                            tempErr = { ...tempErr, ...resp.msg }
                                          }
                                          if (resp.status / 1 == 1) {
                                            tempData = { ...tempData, ...resp.msg }
                                          }
                                          // console.log("customEventtttttttttttttttt", customEvent, resp);
                                        }
                                        setData(tempData)
                                        setErrors(tempErr)

                                        let temp = multInvPo
                                        for (let index = 0; index < e.target.files.length - 1; index++) {
                                          temp[j].purchase_orders.push(null)
                                        }
                                        setMultInvPo([...temp])
                                      }}
                                      boolmodifywidth={boolmodifywidth}
                                      name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                      isEditable={true}
                                      // onChange={(e) => handleFile(e, j, p)}
                                      onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                    />
                                    {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                      <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                  </div>
                                )

                              })}
                            </td>
                            <td>
                              <div>
                                <InputForTable isDisabled={view} type={"text"} label={""}
                                  name={"invoiceRefrenceNo" + j} value={data["invoiceRefrenceNo" + j]} error={errors["invoiceRefrenceNo" + j]}
                                  onChange={handleChange} />
                              </div>
                              {i.purchase_orders.map((o, p) => {
                                return (
                                  <div className="mt-5">
                                    <InputForTable isDisabled={view} type={"text"} label={""}
                                      name={"poRefrenceNo" + j + ":" + p} value={data["poRefrenceNo" + j + ":" + p]} error={errors["poRefrenceNo" + j + ":" + p]}
                                      onChange={(e) => handleChange(e, j, p)} />
                                  </div>
                                )
                              })}
                            </td>
                            <td>
                              <div>
                                <InputWithSelectForTable isDisabled={view} type={"number"} label={""}
                                  selectData={currencyData}
                                  selectName={"currency" + j} selectValue={data["currency" + j]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={'contractAmount' + j} value={data["contractAmount" + j]} error={errors["contractAmount" + j] || errors["currency" + j]}
                                  onChange={handleChange} />
                              </div>
                              {i.purchase_orders.map((o, p) => <div className="mt-5">-</div>)}
                            </td>
                            <td>
                              <div>
                                {!data[`invoiceDocument${j}`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`invoiceDocument${j}`]?.name); setData({ ...data, docToSign: data[`invoiceDocument${j}`], docSignName: `invoiceDocument${j}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </div>
                              {i.purchase_orders.map((o, p) => {
                                return (
                                  <div className="mt-5">
                                    {!data[`poDocument${j}:${p}`]?.signatureId ?
                                      <img className="cursor"
                                        onClick={() => { setSigndoc(data[`poDocument${j}:${p}`]?.name); setData({ ...data, docToSign: data[`poDocument${j}:${p}`], docSignName: `poDocument${j}:${p}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                      :
                                      <img style={{ width: '20px' }} className=""
                                        src={"assets/images/doc_sign_filled.svg"} alt="" />
                                    }
                                  </div>
                                )
                              })}
                            </td>
                            <td>
                              <div
                                className="d-flex"
                              >
                                {data[`invoiceDocument${j}`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invoiceDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invoiceDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}
                                <img
                                  onClick={() => {
                                    let temp = multInvPo
                                    if (temp.length > 1) {
                                      temp = temp.filter((l, m) => {
                                        if (m / 1 != j / 1) {
                                          return l
                                        }
                                      })
                                      setMultInvPo(temp)
                                    }
                                    else {
                                      toastDisplay("Atleast 1 invoice & po details are required", "info")
                                    }
                                  }}
                                  className={`cursor `}
                                  src={"assets/images/cross.png"}
                                />
                              </div>
                              {i.purchase_orders.map((o, p) => {
                                return (
                                  <div
                                    className="d-flex mt-5"
                                  >
                                    {data[`poDocument${j}:${p}`]?.name ? (
                                      <>
                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`poDocument${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`poDocument${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                      </>
                                    ) : null}
                                    {i.purchase_orders.length - 1 > p ?
                                      <div
                                        className="col-1">
                                        <img
                                          onClick={() => {
                                            let temp = multInvPo
                                            if (temp[j].purchase_orders.length > 1) {
                                              let tempUpdatedPo = []
                                              for (let m = 0; m < temp[j].purchase_orders.length; m++) {
                                                if (m != p) {
                                                  tempUpdatedPo.push(null)
                                                }
                                              }
                                              temp[j].purchase_orders = tempUpdatedPo
                                              setMultInvPo([...temp])
                                            }
                                            else {
                                              toastDisplay("Atleast 1 po details are required", "info")
                                            }
                                          }}
                                          className="cursor"
                                          src={"assets/images/cross.png"}
                                        />
                                      </div> : (<div
                                        className="col-1">
                                        <img
                                          onClick={() => {
                                            let temp = multInvPo
                                            temp[j].purchase_orders.push(null)
                                            setMultInvPo([...temp])
                                          }}
                                          className="cursor"
                                          src={"assets/images/vector_add_blue.svg"}
                                        />
                                      </div>)}
                                  </div>
                                )
                              })}
                            </td>
                          </tr>
                          {/* {i.purchase_orders.map((o, p) => {
                            return (
                              <tr  >
                                <td>Purchse Order</td>
                                <td>
                                  <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "poDocument" + j + ":" + (p + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent, j, p + index, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                        }
                                        // console.log("customEventtttttttttttttttt", customEvent, resp);
                                      }
                                      setData(tempData)
                                      setErrors(tempErr)
                                      let temp = multInvPo
                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                        temp[j].purchase_orders.push(null)
                                      }
                                      setMultInvPo([...temp])
                                    }}
                                    name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                    isEditable={true}
                                    // onChange={(e) => handleFile(e, j, p)}
                                    onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                  />
                                  {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                </td>
                                <td>
                                  <div className="position-relative">
                                    <InputForTable isDisabled={view} type={"text"} label={""}
                                      name={"poRefrenceNo" + j + ":" + p} value={data["poRefrenceNo" + j + ":" + p]} error={errors["poRefrenceNo" + j + ":" + p]}
                                      onChange={(e) => handleChange(e, j, p)} />
                                  </div>
                                </td>
                                <td>-</td>
                                <td>
                                  {!data[`poDocument${j}:${p}`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`poDocument${j}:${p}`]?.name); setData({ ...data, docToSign: data[`poDocument${j}:${p}`], docSignName: `poDocument${j}:${p}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td>
                                  <div
                                    className="d-flex"
                                  >
                                    {data[`poDocument${j}:${p}`]?.name ? (
                                      <>
                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`poDocument${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`poDocument${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                      </>
                                    ) : null}
                                    {i.purchase_orders.length - 1 > p ?
                                      <div
                                        className="col-1">
                                        <img
                                          onClick={() => {
                                            let temp = multInvPo
                                            if (temp[j].purchase_orders.length > 1) {
                                              let tempUpdatedPo = []
                                              for (let m = 0; m < temp[j].purchase_orders.length; m++) {
                                                if (m != p) {
                                                  tempUpdatedPo.push(null)
                                                }
                                              }
                                              temp[j].purchase_orders = tempUpdatedPo
                                              setMultInvPo([...temp])
                                            }
                                            else {
                                              toastDisplay("Atleast 1 po details are required", "info")
                                            }
                                          }}
                                          className="cursor"
                                          src={"assets/images/cross.png"}
                                        />
                                      </div> : (<div
                                        className="col-1">
                                        <img
                                          onClick={() => {
                                            let temp = multInvPo
                                            temp[j].purchase_orders.push(null)
                                            setMultInvPo([...temp])
                                          }}
                                          className="cursor"
                                          src={"assets/images/vector_add_blue.svg"}
                                        />
                                      </div>)}
                                  </div>
                                </td>
                              </tr>
                            )
                          })} */}
                        </>
                      })}
                    </NewTablev2>
                    <div>
                      <label
                        onClick={() => {
                          let temp = multInvPo
                          temp = temp.concat([{ purchase_orders: [null] }])
                          setMultInvPo(temp)
                          setData({ ...data, ["currency" + (temp.length - 1)]: "USD" })
                        }}
                        className="font-size-14 text-color1 font-wt-800 cursor px-2"
                      >Add More</label>
                    </div>

                    <div className='w-100 mt-3'>
                      <label className='font-wt-500 font-size-16'>
                        <u>Past Documents</u>
                      </label>
                      <div className='row mt-2'>
                        <div className="">
                          {/* Accordion setup */}
                          <div className="accordion" id="otherDocumentsAccordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOtherDocuments">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOtherDocuments" aria-expanded="false" aria-controls="collapseOtherDocuments">
                                  View Documents
                                </button>
                              </h2>
                              <div id="collapseOtherDocuments" className="accordion-collapse collapse" aria-labelledby="headingOtherDocuments" data-bs-parent="#otherDocumentsAccordion">
                                <div className="accordion-body">
                                  <NewTablev2 columns={documentTable2}>
                                    <tr>
                                      <td>Bill of Lading / Airway Bill *</td>
                                      <td>
                                        <FileInput hideViewDowdBtns={true} name={`logisticDocument`} value={data[`logisticDocument`]} error={errors[`logisticDocument`]}
                                          onChange={handleFile} isEditable={true} boolmodifywidth={boolmodifywidth}
                                          onUploadCancel={() => setData({ ...data, [`logisticDocument`]: null })}
                                        />
                                      </td>
                                      <td>
                                        {!data[`logisticDocument`]?.signatureId ?
                                          <img className="cursor"
                                            onClick={() => { setSigndoc(data[`logisticDocument`]?.name); setData({ ...data, docToSign: data[`logisticDocument`], docSignName: `logisticDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                          :
                                          <img style={{ width: '20px' }} className=""
                                            src={"assets/images/doc_sign_filled.svg"} alt="" />
                                        }
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {data[`logisticDocument`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`logisticDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`logisticDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                    {/* Repeat other document rows similarly */}
                                    <tr>
                                      <td>Investigation Report</td>
                                      <td>
                                        <FileInput hideViewDowdBtns={true} name={`invReportDocument`} value={data[`invReportDocument`]} error={errors[`invReportDocument`]}
                                          onChange={handleFile} isEditable={true} boolmodifywidth={boolmodifywidth}
                                          onUploadCancel={() => setData({ ...data, [`invReportDocument`]: null })}
                                        />
                                      </td>
                                      <td>
                                        {!data[`invReportDocument`]?.signatureId ?
                                          <img className="cursor"
                                            onClick={() => { setSigndoc(data[`invReportDocument`]?.name); setData({ ...data, docToSign: data[`invReportDocument`], docSignName: `invReportDocument` }) }}
                                            src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                          :
                                          <img style={{ width: '20px' }} className=""
                                            src={"assets/images/doc_sign_filled.svg"} alt="" />
                                        }
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {data[`invReportDocument`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invReportDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invReportDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                    {/* Add more rows as needed */}
                                  </NewTablev2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <InputWithSelect isDisabled={view} isAstrix={true} type={"text"} label={"Applicant name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"nameTitle"} selectValue={data.nameTitle}
                            optionLabel={"name"} optionValue={'name'}
                            name={'applicantName'} value={data.applicantName} error={errors.applicantName}
                            onChange={handleChange} />
                        </div>
                      </div>

                      {/* <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Purchase order number"}
                            name={"poRefrenceNo"} value={data.poRefrenceNo} error={errors.poRefrenceNo}
                            onChange={handleChange} />
                        </div>
                      </div> */}

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Buyer Email"}
                              name={"buyerEmail"} value={data.buyerEmail} error={errors.buyerEmail}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Buyer Position"}
                              name={"buyerPosition"} value={data.buyerPosition} error={errors.buyerPosition}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-6 ps-0">
                            <NewInput isAstrix={true} isDisabled={true} type={"text"} label={"Buyer Name"}
                              name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                              onChange={handleChange} />
                          </div>

                          <div className="col-md-6 pe-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Buyer Contact"}
                              selectData={countrys}
                              selectName={"buyerPhoneCode"} selectValue={data.buyerPhoneCode}
                              optionLabel={"phonecode"} optionValue={'phonecode'}
                              name={'buyerContact'} value={data.buyerContact} error={errors.buyerContact}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Name of Authorized Signatory (Buyer)"}
                            name={"nameAuthorizedSignatory"} value={data.nameAuthorizedSignatory} error={errors.nameAuthorizedSignatory}
                            onChange={handleChange} />
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewSelect isDisabled={view} isAstrix={true} label={"Select bank"}
                            selectData={userBank} name={"beneficiaryBankName"}
                            value={data.beneficiaryBankName} optionLabel={"beneficiaryBankName"} optionValue={'id'}
                            onChange={handleChange} error={errors.beneficiaryBankName} />
                        </div>
                      </div>

                      {/* <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-7 ps-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Invoice Amount"}
                              selectData={currencyData}
                              selectName={"currency"} selectValue={data.currency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'contractAmount'} value={data.contractAmount} error={errors.contractAmount}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-5 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Invoice Number"}
                              name={"invoiceRefrenceNo"} value={data.invoiceRefrenceNo} error={errors.invoiceRefrenceNo}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-7 ps-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Invoice Amount"}
                              selectData={currencyData}
                              selectName={"currency"} selectValue={data.currency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'contractAmount'} value={data.contractAmount} error={errors.contractAmount}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-5 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Invoice Number"}
                              name={"invoiceRefrenceNo"} value={data.invoiceRefrenceNo} error={errors.invoiceRefrenceNo}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={view} isAstrix={false} type={"text"} label={"BL Number"}
                              name={"blNo"} value={data.blNo} error={errors.blNo}
                              onChange={handleChange} />
                          </div>

                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"date"} label={"BL Issue Date"}
                              name={"invoiceIssueDate"} value={data.invoiceIssueDate} error={errors.invoiceIssueDate}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"number"} label={"Finance Credit Days"}
                              name={"creditDays"} value={data.creditDays} error={errors.creditDays}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewSelect isDisabled={true} isAstrix={true} label={"Select lender"}
                              selectData={bankMaster} name={"lenderId"}
                              value={data.lenderId} optionLabel={"company_name"} optionValue={'id'}
                              onChange={handleChange} error={errors.buyerCountry} />
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <NewSelect isAstrix={true} label={"Shipper / broker"}
                          selectData={countrys} name={"Shipperbroker"}
                          value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.buyerCountry} />
                      </div> */}
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className={limitPendingFrom ? "col-md-6 ps-0" : "col-md-4 ps-0"}>
                            <NewInput isDisabled={true} type={"text"} label={"Factoring Fee"}
                              name={"factoringFees"} value={selectedQuote.factoringFees + " " + selectedQuote.factoringFeesCurrency} error={errors.interestRate}
                              onChange={handleChange} />
                          </div>
                          <div className={limitPendingFrom ? "col-md-6 ps-0" : "col-md-4 ps-0"}>
                            <NewInput isDisabled={true} type={"text"} label={"Interest Rate"}
                              name={"interestRate"} value={selectedQuote.interestRate + " %"} error={errors.interestRate}
                              onChange={handleChange} />
                          </div>
                          {limitPendingFrom ? null : (
                            <div className="col-md-4 px-0">
                              <NewInput isDisabled={true} type={"text"} label={"Setup fee"}
                                name={"setUpFees"} value={selectedQuote.setupFees + " " + selectedQuote.setupFeesCurrency} error={errors.setUpFees}
                                onChange={handleChange} />
                            </div>
                          )}
                        </div>
                      </div>
                      {isLenderModifi ? (
                        <>
                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewSelect isDisabled={view} isAstrix={true} label={"Shipment Origin Country"}
                                selectData={countrys} name={"shipmentOriginCountry"}
                                value={data.shipmentOriginCountry} optionLabel={"name"} optionValue={'sortname'}
                                onChange={handleChange} error={errors.shipmentOriginCountry} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Origin Address"}
                                name={"shipmentOriginAddress"} value={data.shipmentOriginAddress} error={errors.shipmentOriginAddress}
                                onChange={handleChange} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Origin City"}
                                name={"shipmentOriginCity"} value={data.shipmentOriginCity} error={errors.shipmentOriginCity}
                                onChange={handleChange} />
                            </div>
                          </div>


                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Origin Postal Code"}
                                name={"shipmentOriginPostalCode"} value={data.shipmentOriginPostalCode} error={errors.shipmentOriginPostalCode}
                                onChange={handleChange} />
                            </div>
                          </div>


                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewSelect isDisabled={view} isAstrix={true} label={"Shipment Destination Country"}
                                selectData={countrys} name={"shipmentDestinationCountry"}
                                value={data.shipmentDestinationCountry} optionLabel={"name"} optionValue={'sortname'}
                                onChange={handleChange} error={errors.shipmentDestinationCountry} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Destination Address"}
                                name={"shipmentDestinationAddress"} value={data.shipmentDestinationAddress} error={errors.shipmentDestinationAddress}
                                onChange={handleChange} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Destination City"}
                                name={"shipmentDestinationCity"} value={data.shipmentDestinationCity} error={errors.shipmentDestinationCity}
                                onChange={handleChange} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Destination Postal Code"}
                                name={"shipmentDestinationPostalCode"} value={data.shipmentDestinationPostalCode} error={errors.shipmentDestinationPostalCode}
                                onChange={handleChange} />
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/* <div className="col-md-3 ">
                        <NewInput isAstrix={true} type={"text"} label={"Finance credit date"}
                          name={"Finance credit date"} value={data.Setupfees} error={errors.Setupfees}
                          onChange={handleChange} />
                      </div> */}
                    </div>
                  </div>
                ) : null}

                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Applicant Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                          {isLenderModifi ? reviewForm2.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          }) : null}
                        </div>
                      </div>

                      {isLenderModifi ? (
                        <div className='w-100 mt-4'>
                          <label className='font-wt-500 font-size-16'><u>Total Charges</u></label>
                          {feesData?.length ? feesData.map((l, m) => {
                            return (
                              <div>
                                <label className='font-wt-500 font-size-16 mt-3'><u>{`For Invoice : ${data["invoiceRefrenceNo" + m]}`}</u></label>
                                {l?.fees && Object.keys(l.fees).length ? Object.keys(l.fees).map((item, index) => (
                                  <div className='col-5 p-0'>
                                    <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{item.toUpperCase().split("FEE")[0]}</label>
                                    <label className='lh-18 font-size-14'>:</label>
                                    <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                      {(l.fees[item]["amount"] / 100).toFixed(2) + " " + l.fees[item]["currency"]}
                                    </label>
                                  </div>
                                )) : null}
                              </div>
                            )
                          }) : null}
                        </div>
                      ) : (
                        <div className='w-100 mt-4'>
                          <label className='font-wt-500 font-size-16'><u>Total Charges</u></label>
                          {finalCharges?.length ? finalCharges.map((o, p) => {
                            return (
                              <div>
                                <label className='font-wt-500 font-size-16 mt-3'><u>{`For Invoice : ${data["invoiceRefrenceNo" + p]}`}</u></label>
                                <div className='col-5 p-0'>
                                  <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Factoring Rate"}</label>
                                  <label className='lh-18 font-size-14'>:</label>
                                  <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                    {o["factoring"] || "NA"}
                                  </label>
                                </div>
                                <div className='col-5 p-0'>
                                  <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Interest Rate"}</label>
                                  <label className='lh-18 font-size-14'>:</label>
                                  <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                    {o["interest"] || "NA"}
                                  </label>
                                </div>
                                <div className='col-5 p-0'>
                                  <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Setup Fees (One Time Only)"}</label>
                                  <label className='lh-18 font-size-14'>:</label>
                                  <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                    {o["oneTime"] || "NA"}
                                  </label>
                                </div>
                                {o?.otherCharges?.length ? o.otherCharges.map((item, index) => {
                                  return (
                                    <div className='col-5 p-0'>
                                      <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{selectedQuote.otherCharges[index]["name"]}</label>
                                      <label className='lh-18 font-size-14'>:</label>
                                      <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                        {item["OtherCharge" + (index + 1)] || "NA"}
                                      </label>
                                    </div>
                                  )
                                }) : null}
                                <div className='col-5 p-0'>
                                  <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Total Payable"}</label>
                                  <label className='lh-18 font-size-14'>:</label>
                                  <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                    {o["total"] || "NA"}
                                  </label>
                                </div>
                              </div>
                            )
                          }) : null}
                        </div>
                      )}


                      <div className="mt-4">
                        <label className='font-wt-500 font-size-16'><u>Invoice Details</u></label>
                        <NewTable
                          disableAction={true}
                          columns={[
                            { name: "Document Name" },
                            { name: "Invoice Amount" },
                            { name: "Attach Document" },
                            { name: "" }
                          ]}
                          data={tableDocsData}
                        />
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>

                        <div className='row mt-2'>

                          {data.logisticDocument && data.logisticDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Bill of Lading/Airway Bill</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"logisticDocument"} value={data.logisticDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.invReportDocument && data.invReportDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Investigation Report</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invReportDocument"} value={data.invReportDocument}
                                    isEditable={false} boolmodifywidth={boolmodifywidth}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.grnDocument && data.grnDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Certificate of Origin</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"grnDocument"} value={data.grnDocument}
                                    isEditable={false} boolmodifywidth={boolmodifywidth}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.packingListDocument && data.packingListDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Packing List</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"packingListDocument"} value={data.packingListDocument}
                                    isEditable={false} boolmodifywidth={boolmodifywidth}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.shippingBillDocument && data.shippingBillDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Shipping Bill</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument}
                                    isEditable={false} boolmodifywidth={boolmodifywidth}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {moreDocsArray?.length ? moreDocsArray.map((item, index) => {
                            if (item.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item}
                                        isEditable={false} boolmodifywidth={boolmodifywidth}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                          {data.termSheetDocument && data.termSheetDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Term Sheet</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"termSheetDocument"} value={data.termSheetDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          {moreDocsArray2?.length ? moreDocsArray2.map((item, index) => {
                            if (item.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                          <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}
                {tab === 2 && !data.tcAccepted ? null : (
                  <div className="px-5 pb-5">
                    <button type="button"
                      onClick={() => handleValidation(undefined)}
                      className={`new-btn w-20 py-2 px-2 text-white`} style={{ marginTop: "1rem" }}>
                      {tab === 2 ? `${view ? "Update" : "Submit"}` : "Continue"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for “Invoice discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}
      {showInvPoAnalysis?.show && <FinanceInvoiceModal modalSize={"lg"} limitinvoice={showInvPoAnalysis?.show} setLimitinvoice={() => {
        setShowInvPoAnalysis({ show: false })
      }} closeSuccess={() => {
        setShowInvPoAnalysis({ show: false })
      }}>
        <p
          className="font-size-16 font-wt-600"
        >Invoice - PO Analysis</p>
        <p
          className="font-size-14 font-wt-600 mt-4"
        >Buyer Details</p>
        <div>
          <NewTable
            data={buyerComparisonTableData}
            columns={[
              { name: "Details", },
              { name: "Invoice", },
              { name: "PO" },
              { name: "DNB" },
              { name: "Invoice-PO Match (%)" },
              { name: "Invoice-DNB Match (%)" }]} disableAction={true} />
        </div>
        <p
          className="font-size-14 font-wt-600 mt-4"
        >Item Details</p>
        <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
          <div
            style={{ overflowX: 'hidden' }}
            className="accordion-item financeacc mb-3 ">
            <h2
              className="accordion-header font-size-14 font-wt-600" id={"Heading0"}>
              <button
                className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                data-bs-target={`#Collapse0`} aria-expanded="true" aria-controls={"Collapse0"}>
                Matched Items
              </button>
            </h2>

            <div
              style={{ overflow: 'auto' }}
              id={"Collapse0"} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading0"} data-bs-parent="#accordionFlushExample">
              <div className="accordion-body d-flex"
              >
                <NewTable
                  data={matchedItemDetails}
                  columns={[
                    { name: "Item Number" },
                    { name: "Document" },
                    { name: "Product Code", },
                    { name: "Description" },
                    { name: "Quantity" },
                    { name: "Unit Price" },
                    { name: "Amount" }]} disableAction={true} />
              </div>
            </div>

          </div>

          <div
            style={{ overflowX: 'hidden' }} className="accordion-item financeacc mb-3 ">
            <h2
              className="accordion-header font-size-14 font-wt-600" id={"Heading1"}>
              <button
                className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                data-bs-target={`#Collapse1`} aria-expanded="true" aria-controls={"Collapse0"}>
                Mis-matched Items
              </button>
            </h2>

            <div
              style={{ overflow: 'auto' }}
              id={"Collapse1"} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading1"} data-bs-parent="#accordionFlushExample">
              <div className="accordion-body d-flex"
              >
                <NewTable
                  data={mismatchedItemDetails}
                  columns={[
                    { name: "Item Number" },
                    { name: "Document" },
                    { name: "Product Code", },
                    { name: "Description" },
                    { name: "Quantity" },
                    { name: "Unit Price" },
                    { name: "Amount" }]} disableAction={true} />
              </div>
            </div>

          </div>
        </div>
      </FinanceInvoiceModal>}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyMultFinanceBtn)