
// import React, { useEffect, useState } from 'react'
// import { connect } from 'react-redux'
// import HeaderV2 from '../partial/headerV2'
// import SideBarV2 from '../partial/sideBarV2'
// import Filter from '../InvoiceDiscounting/components/Filter'
// import { ExpandableTable } from '../wallet/components/ExpandableTable'
// import Pagination from '../InvoiceDiscounting/contract/components/pagination'
// import toastDisplay from '../../utils/toastNotification'
// import moment from 'moment'
// import call from '../../service'
// import swal from 'sweetalert'
// import NewTablev2 from '../../utils/newTablev2'
// import { applyFinTblDID } from '../Dashboard/TableJson'
// import { LCPurposeObject, correctFileBase64, getDocDetails, roundOfNumberInDecimal } from '../../utils/myFunctions'
// import { ToastContainer } from 'react-toastify';
// import { useHistory } from "react-router";
// import { FileInput } from '../../utils/FileInput'
// import { DocumentViewer } from '../../utils/documentViewer'
// import { DocumentDownloader, downloadTheFile } from '../../utils/DocumentDownloader'
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';

// const addBuyerTabs = [
//   { name: "Application form" },
//   { name: "Documents" },
//   { name: "Quote" }
// ]

// const DocVaultTransactionDetails = ({ userTokenDetails, navToggleState, viewInPopup, onSelectFile }) => {

//   let docVaultTransactionDetails = JSON.parse(localStorage.getItem('docVaultTransactionDetails'))
//   console.log(docVaultTransactionDetails, "docVaultTransactionDetails--->>>>>>>")
//   let history = useHistory()

//   let forInvoice = !docVaultTransactionDetails?.lcPurpose

//   const [filteredSearch, setFilteredSearch] = useState({})
//   const [filter, setFilter] = useState({
//   })
//   const [refresh, setRefresh] = useState(0)
//   const [filterData, setFilterData] = useState({})
//   const [count, setCount] = useState(1)
//   const [page, setPage] = useState(1)
//   const [dbdata, setDbdata] = useState([])
//   const [showLoader, setshowLoader] = useState(false)
//   const [folderView, toggleFolderView] = useState("grid")

//   const [applyForFinanceDocs, setApplyForFinanceDocs] = useState([])
//   const [contractDocs, setContractDocs] = useState([])
//   const [applyForLimitDocs, setApplyForLimitDocs] = useState([])

//   const [showFolders, toggleShowFolders] = useState({})

//   const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
//   const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

//   const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
//   const userEmail = userTokenDetails.email ? userTokenDetails.email : null
//   const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
//   const userName = userTokenDetails.userName ? userTokenDetails.userName : null
//   const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
//   const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
//   const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")

//   useEffect(() => {
//     loadDocuments()
//   }, [filter.search])



//   async function loadDocuments() {
//     let searchQuery = filter?.search ? filter.search.toLowerCase() : ""

//     let tempApplyForFinanceDocs = []
//     let tempContractDocs = []
//     let tempApplyForLimitDocs = []

//     setshowLoader(true)
//     let postShipmentDocs = forInvoice ? "invPostShipmentDocs" : "lcPostShipmentDocs"
//     let invPostShipmentDocs = docVaultTransactionDetails?.[postShipmentDocs] ? docVaultTransactionDetails?.[postShipmentDocs].split(",") : []
//     for (let index = 0; index < invPostShipmentDocs.length; index++) {
//       let fileIfPresent = await getDocDetails(invPostShipmentDocs[index])
//       if (fileIfPresent.name) {
//         fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
//         fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
//         if (searchQuery) {
//           if (fileIfPresent.doc_name.toLowerCase().includes(searchQuery)) {
//             tempApplyForFinanceDocs.push({
//               file: fileIfPresent
//             })
//           }
//         }
//         else {
//           tempApplyForFinanceDocs.push({
//             file: fileIfPresent
//           })
//         }
//       }
//     }

//     let fileIfPresent = await getDocDetails(docVaultTransactionDetails.termSheet)
//     if (fileIfPresent.name) {
//       fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
//       fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
//       if (searchQuery) {
//         if (fileIfPresent.doc_name.toLowerCase().includes(searchQuery)) {
//           tempContractDocs.push({
//             file: fileIfPresent
//           })
//         }
//       }
//       else {
//         tempContractDocs.push({
//           file: fileIfPresent
//         })
//       }
//     }
//     // let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: docVaultTransactionDetails.applicationId, fetchLCDocs: !forInvoice })

//     // for (let index = 0; index < limitDocs.data.length; index++) {
//     //   const element = limitDocs.data[index];
//     //   let fileIfPresent = await getDocDetails(element.docId)
//     //   if (fileIfPresent.name) {
//     //     fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
//     //     fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
//     //     if (searchQuery) {
//     //       if (fileIfPresent.doc_name.toLowerCase().includes(searchQuery)) {
//     //         tempApplyForLimitDocs.push({
//     //           file: fileIfPresent
//     //         })
//     //       }
//     //     }
//     //     else {
//     //       tempApplyForLimitDocs.push({
//     //         file: fileIfPresent
//     //       })
//     //     }
//     //   }
//     // }


//     //*********************************************************************** */

//     // for (let index = 0; index < limitDocs.data.length; index++) {
//     //   const element = limitDocs.data[index];
//     //   let fileIfPresent = await getDocDetails(element.docId);
//     //   console.log(fileIfPresent, "fileif present --->>>>>")
//     //   if (fileIfPresent.name) {
//     //     try {
//     //       // Ensure the base64 string is trimmed of any extra spaces or newlines
//     //       const fileBase64 = fileIfPresent.filebase64.trim();

//     //       // Decode base64 and calculate file size
//     //       fileIfPresent["size"] = (atob(fileBase64).length / (1024 * 1024)).toFixed(2) + " MB";

//     //       if (searchQuery) {
//     //         if (fileIfPresent.doc_name.toLowerCase().includes(searchQuery.toLowerCase())) {
//     //           tempApplyForLimitDocs.push({ file: fileIfPresent });
//     //         }
//     //       } else {
//     //         tempApplyForLimitDocs.push({ file: fileIfPresent });
//     //       }
//     //     } catch (error) {
//     //       console.error("Failed to decode base64 string for docId:", element.docId, error);
//     //     }
//     //   }
//     // }


//     //******************************************************************** */

//     let tempLimitDocsDetails = {}
//     let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: docVaultTransactionDetails.applicationId })


//     for (let index = 0; index < limitDocs.data.length; index++) {
//       const element = limitDocs.data[index];
//       tempLimitDocsDetails[element.label] = await getDocDetails(element.docId, null, false, null, true)
//       if (element.attributes) {
//         tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
//       }
//     }
//     setshowLoader(false)



//     fileIfPresent = await getDocDetails(docVaultTransactionDetails.draftLC, null, false, null, true)
//     if (fileIfPresent.name) {
//       fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
//       fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
//       if (searchQuery) {
//         if (fileIfPresent.doc_name.toLowerCase().includes(searchQuery)) {
//           tempApplyForLimitDocs.push({
//             file: fileIfPresent
//           })
//         }
//       }
//       else {
//         tempApplyForLimitDocs.push({
//           file: fileIfPresent
//         })
//       }
//     }

//     setApplyForFinanceDocs(tempApplyForFinanceDocs)
//     // setApplyForFinanceDocs(tempLimitDocsDetails)
//     setContractDocs(tempContractDocs)
//     setApplyForLimitDocs(tempApplyForLimitDocs)
//     setshowLoader(false)
//   }

//   // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", applyForFinanceDocs);

//   let folders = []
//   if (applyForFinanceDocs.length) {
//     folders.push({ name: 'Apply For Finance', toggle: 'applyForFinance' })
//   }
//   if (contractDocs.length) {
//     folders.push({ name: 'Contract', toggle: 'contractDocs' })
//   }
//   if (applyForLimitDocs.length) {
//     folders.push({ name: 'Apply For Limit', toggle: 'applyForLimit' })
//   }

//   const downloadFilesAsZip = async () => {
//     let files = []
//     let folderName = ""

//     if (showFolders["applyForFinance"] && applyForFinanceDocs.length) {
//       files = applyForFinanceDocs
//       folderName = "Apply For Finance"
//     }
//     if (showFolders["contractDocs"] && contractDocs.length) {
//       files = contractDocs
//       folderName = "Contract"
//     }
//     if (showFolders["applyForLimit"] && applyForLimitDocs.length) {
//       files = applyForLimitDocs
//       folderName = "Apply For Limit"
//     }

//     if (folderName && files.length) {

//       for (let index = 0; index < files.length; index++) {
//         const element = files[index];
//         downloadTheFile(element.file)
//       }

//       // // Initialize a new instance of JSZip
//       // const zip = new JSZip();

//       // // Loop through the array of Base64-encoded files
//       // for (let i = 0; i < files.length; i++) {
//       //   // Decode the Base64 data
//       //   const fileData = atob(files[i].file.filebase64);

//       //   // Create a Blob from the decoded data
//       //   const blob = new Blob([fileData], { type: "application/pdf" });

//       //   // Add the Blob to the zip file with a specific filename
//       //   zip.file(`${files[i].file.doc_name}(${files[i].file.name}).pdf`, blob);
//       // }

//       // // Generate the zip file
//       // const zipBlob = await zip.generateAsync({ type: 'blob' });

//       // // Save the zip file using FileSaver.js
//       // saveAs(zipBlob, `${folderName}.zip`);

//     }
//     else {
//       toastDisplay('Select folder to download', "info")
//     }
//   };

//   // function handleDocumentView(file) {
//   //   if (viewInPopup) {
//   //     onSelectFile(file);
//   //   } else {
//   //     try {
//   //       // Decode Base64 string and create a Blob object
//   //       const byteCharacters = atob(file.filebase64);
//   //       const byteNumbers = new Array(byteCharacters.length).map((_, idx) =>
//   //         byteCharacters.charCodeAt(idx)
//   //       );
//   //       const byteArray = new Uint8Array(byteNumbers);
//   //       const blob = new Blob([byteArray], { type: "application/pdf" });

//   //       // Create a URL for the Blob and open it in a new tab
//   //       const blobUrl = URL.createObjectURL(blob);
//   //       window.open(blobUrl, "_blank");

//   //       // Revoke the URL after some time to free up memory
//   //       setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
//   //     } catch (error) {
//   //       console.error("Failed to open document:", error);
//   //     }
//   //   }
//   // }
//   function handleDocumentView(file) {
//     if (viewInPopup) {
//       onSelectFile(file);
//     } else {
//       try {
//         // Trim and validate the base64 string
//         let base64Data = file.filebase64.trim();

//         if (!isValidBase64Header(base64Data)) {
//           console.error("Invalid Base64 format");
//           return;
//         }

//         // Decode Base64 string and create a Blob object
//         const byteCharacters = atob(base64Data);
//         const byteNumbers = new Uint8Array(
//           Array.from(byteCharacters).map((char) => char.charCodeAt(0))
//         );
//         const blob = new Blob([byteNumbers], { type: "application/pdf" });

//         // Create a URL for the Blob and open it in a new tab
//         const blobUrl = URL.createObjectURL(blob);
//         window.open(blobUrl, "_blank");

//         // Revoke the URL after some time to free up memory
//         setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
//       } catch (error) {
//         console.error("Failed to open document:", error);
//       }
//     }
//   }

//   // Helper function to validate Base64 header and structure
//   function isValidBase64Header(str) {
//     // Check if it starts with a proper Base64 header (minimal validation)
//     return /^[A-Za-z0-9+/]+={0,2}$/.test(str.substring(0, 100)); // Test only the first 100 characters
//   }



//   return (
//     <>
//       {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
//       <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
//       <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
//       <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
//       <div className="container-fluid">
//         <div className="row">
//           {viewInPopup ? null :
//             <SideBarV2 state="docVaultTransaction" userTokenDetails={userTokenDetails} />}
//           <main role="main" className={`ml-sm-auto ${viewInPopup ? ' col-lg-12 m-0 p-0 ' : ' col-lg-10 '} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
//             {viewInPopup ? null :
//               <HeaderV2
//                 title={"Document Vault > Details"}
//                 userTokenDetails={userTokenDetails} />}
//             <div className='card p-5' >
//               {viewInPopup ? null :
//                 <div className='d-flex align-items-center row'>
//                   <a className="cursor col-3"
//                     onClick={() => { history.go(-1) }}
//                   ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
//                   <label
//                     className='font-size-13 font-wt-500 col-9'
//                   >{`${docVaultTransactionDetails.buyerName || "-"} - ${LCPurposeObject[docVaultTransactionDetails.lcPurpose] || "Invoice Discounting"}`}</label>
//                 </div>}
//               <div className='d-flex row my-5 pl-3' >
//                 <div
//                   onClick={() => { toggleFolderView(folderView === "list" ? "grid" : "list") }}
//                   style={{ width: '8%', height: '2.5rem' }} className='card d-flex flex-row cursor' >
//                   <div className=' d-flex align-items-center cursor' >
//                     <img className='cursor' src={folderView === "list" ? `assets/images/list_icon.png` : `assets/images/grid_icon.png`} />
//                   </div>
//                   <label className='font-size-13 font-wt-600 mt-2 ml-2 cursor'>{folderView === "list" ? "List" : "Grid"}</label>
//                 </div>

//                 <div style={{ width: '92%', marginTop: '-1rem' }} className='d-flex align-items-center justify-content-end' >
//                   <Filter
//                     filteredSearch={filteredSearch} showDownloadIcon={viewInPopup ? false : true}
//                     setFilteredSearch={setFilteredSearch} onDownloadClick={downloadFilesAsZip}
//                     filterData={filterData} setFilterData={setFilterData} showFilterBtn={false}
//                     showResultPerPage={false} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
//                 </div>

//               </div>

//               {folderView === "list" ? <div>
//                 <NewTablev2
//                   columns={[{ subColumns: 'Name', filter: false, subColumnStyle: { width: "40%" } },
//                   { subColumns: 'Last Modified', filter: false, subColumnStyle: { width: "16%" } },
//                   { subColumns: 'Size', filter: false, subColumnStyle: { width: "13%" } },
//                   { subColumns: 'Type', filter: false, subColumnStyle: { width: "10%" } },
//                   { subColumns: '', filter: false, subColumnStyle: { width: "16%" } }]}
//                 >
//                   {applyForFinanceDocs.length ?
//                     <tr>
//                       <td>
//                         <div className="d-flex" >
//                           <div style={{ height: '1.5rem' }}
//                             onClick={() => { toggleShowFolders({ applyForFinance: !showFolders.applyForFinance }) }}
//                             className='d-flex cursor' >
//                             <img style={{ rotate: showFolders.applyForFinance ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
//                             <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
//                             <label className='font-size-12 font-wt-500 cursor pt-1' >{"Apply For Finance"}</label>
//                           </div>
//                         </div>
//                         {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
//                           return (
//                             <div className='mt-3' >
//                               <FileInput showDocName={true} showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{moment(i.modified_at).format('DD/MM/YYYY HH:mm')}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{i.file.size}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{'PDF'}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
//                                 onClick={() => {
//                                   if (viewInPopup) {
//                                     onSelectFile(i.file)
//                                   }
//                                   else {
//                                     toggleViewDoc({ show: true, doc: i.file })
//                                   }
//                                 }}
//                               >{viewInPopup ? `Select Document` : `View Document`}</button>
//                             </div>
//                           )
//                         })}
//                       </td>
//                     </tr> : null}

//                   {contractDocs.length ?
//                     <tr>
//                       <td>
//                         <div className="d-flex" >
//                           <div style={{ height: '1.5rem' }}
//                             onClick={() => { toggleShowFolders({ contractDocs: !showFolders.contractDocs }) }}
//                             className='d-flex cursor' >
//                             <img style={{ rotate: showFolders.contractDocs ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
//                             <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
//                             <label className='font-size-12 font-wt-500 cursor pt-1' >{"Contract"}</label>
//                           </div>
//                         </div>
//                         {showFolders["contractDocs"] && contractDocs.map((i, j) => {
//                           return (
//                             <div className='mt-3' >
//                               <FileInput showDocName={true} showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["contractDocs"] && contractDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{moment(i.modified_at).format('DD/MM/YYYY HH:mm')}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["contractDocs"] && contractDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{i.file.size}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["contractDocs"] && contractDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{'PDF'}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["contractDocs"] && contractDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
//                                 onClick={() => {
//                                   if (viewInPopup) {
//                                     onSelectFile(i.file)
//                                   }
//                                   else {
//                                     toggleViewDoc({ show: true, doc: i.file })
//                                   }
//                                 }}
//                               >{viewInPopup ? `Select Document` : `View Document`}</button>
//                             </div>
//                           )
//                         })}
//                       </td>
//                     </tr> : null}

//                   {applyForLimitDocs.length ?
//                     <tr>
//                       <td>
//                         <div className="d-flex" >
//                           <div style={{ height: '1.5rem' }}
//                             onClick={() => { toggleShowFolders({ applyForLimit: !showFolders.applyForLimit }) }}
//                             className='d-flex cursor' >
//                             <img style={{ rotate: showFolders.applyForLimit ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
//                             <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
//                             <label className='font-size-12 font-wt-500 cursor pt-1' >{"Apply For Limit"}</label>
//                           </div>
//                         </div>
//                         {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
//                           return (
//                             <div className='mt-3' >
//                               <FileInput showDocName={true} showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{moment(i.modified_at).format('DD/MM/YYYY HH:mm')}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{i.file.size}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <label className='font-size-12 font-wt-500' >{'PDF'}</label>
//                             </div>
//                           )
//                         })}
//                       </td>
//                       <td>
//                         <div style={{ height: '1.5rem' }} className="d-flex" >
//                         </div>
//                         {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
//                           return (
//                             <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
//                               <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
//                                 onClick={() => {
//                                   if (viewInPopup) {
//                                     onSelectFile(i.file)
//                                   }
//                                   else {
//                                     handleDocumentView(i.file)
//                                     // toggleViewDoc({ show: true, doc: i.file })
//                                   }
//                                 }}
//                               >{viewInPopup ? `Select Document` : `View Document`}</button>
//                             </div>
//                           )
//                         })}
//                       </td>
//                     </tr> : null}
//                 </NewTablev2>
//               </div> :
//                 <div>
//                   <div className='d-flex row' >
//                     {folders.map((i, j) => {
//                       return (
//                         <div
//                           onClick={() => toggleShowFolders({ [i.toggle]: true })}
//                           style={showFolders[i.toggle] ? { backgroundColor: '#EEE', borderRadius: 10 } : {}}
//                           className='w-15 cursor' >
//                           <div className='text-center w-100' >
//                             <img src='assets/images/folder_big_icon.png' />
//                           </div>
//                           <div>
//                             <label className="font-size-12 font-wt-500 text-center w-100" >{i.name}</label>
//                           </div>
//                         </div>
//                       )
//                     })}
//                   </div>

//                   <div className='d-flex row' >
//                     {applyForFinanceDocs.length && showFolders["applyForFinance"] ?
//                       applyForFinanceDocs.map((i, j) => {
//                         return (
//                           <div
//                             onClick={() => {
//                               if (viewInPopup) {
//                                 onSelectFile(i.file)
//                               }
//                               else {
//                                 toggleViewDoc({
//                                   show: true, doc: i.file
//                                 })
//                               }
//                             }}
//                             style={{ height: '10rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
//                             <div style={{ height: '8rem' }} className='pt-3 px-3'>
//                               <object
//                                 style={{ overflowX: 'hidden' }}
//                                 data={correctFileBase64(i.file.filebase64)}
//                                 scrolling="no"
//                                 type="application/pdf" width="100%" height="100%">
//                                 <param name="navpanes" value="0"></param>
//                               </object>
//                             </div>
//                             <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
//                               <label className='font-size-12 font-wt-500 w-100' >{i.file.doc_name}</label>
//                             </div>
//                           </div>
//                         )
//                       })
//                       : null}
//                   </div>

//                   <div className='d-flex row' >
//                     {contractDocs.length && showFolders["contractDocs"] ?
//                       contractDocs.map((i, j) => {
//                         return (
//                           <div
//                             onClick={() => {
//                               if (viewInPopup) {
//                                 onSelectFile(i.file)
//                               }
//                               else {
//                                 toggleViewDoc({
//                                   show: true, doc: i.file
//                                 })
//                               }
//                             }}
//                             style={{ height: '10rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
//                             <div style={{ height: '8rem' }} className='pt-3 px-3'>
//                               <object
//                                 style={{ overflowX: 'hidden' }}
//                                 data={correctFileBase64(i.file.filebase64)}
//                                 scrolling="no"
//                                 type="application/pdf" width="100%" height="100%">
//                                 <param name="navpanes" value="0"></param>
//                               </object>
//                             </div>
//                             <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
//                               <label className='font-size-12 font-wt-500 w-100' >{i.file.doc_name}</label>
//                             </div>
//                           </div>
//                         )
//                       })
//                       : null}
//                   </div>

//                   <div className='d-flex row' >
//                     {applyForLimitDocs.length && showFolders["applyForLimit"] ?
//                       applyForLimitDocs.map((i, j) => {
//                         return (
//                           <div
//                             onClick={() => {
//                               if (viewInPopup) {
//                                 onSelectFile(i.file)
//                               }
//                               else {
//                                 toggleViewDoc({
//                                   show: true, doc: i.file
//                                 })
//                               }
//                             }}
//                             style={{ height: '10rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
//                             <div style={{ height: '8rem' }} className='pt-3 px-3'>
//                               <object
//                                 style={{ overflowX: 'hidden' }}
//                                 data={correctFileBase64(i.file.filebase64)}
//                                 scrolling="no"
//                                 type="application/pdf" width="100%" height="100%">
//                                 <param name="navpanes" value="0"></param>
//                               </object>
//                             </div>
//                             <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
//                               <label className='font-size-12 font-wt-500 w-100' >{i.file.doc_name}</label>
//                             </div>
//                           </div>
//                         )
//                       })
//                       : null}
//                   </div>

//                 </div>
//               }

//             </div>
//           </main>
//         </div>
//       </div>
//     </>
//   )
// }

// const mapStateToProps = state => {
//   return {
//     clientType: state.clientType,
//     navToggleState: state.navToggleState,
//     notifications: state.IncomingNotifications.notification_data
//   }
// }

// export default connect(mapStateToProps)(DocVaultTransactionDetails)




import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import Filter from '../InvoiceDiscounting/components/Filter'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import toastDisplay from '../../utils/toastNotification'
import moment from 'moment'
import call from '../../service'
import swal from 'sweetalert'
import NewTablev2 from '../../utils/newTablev2'
import { applyFinTblDID } from '../Dashboard/TableJson'
import { LCPurposeObject, correctFileBase64, getDocDetails, roundOfNumberInDecimal } from '../../utils/myFunctions'
import { ToastContainer } from 'react-toastify';
import { useHistory } from "react-router";
import { FileInput } from '../../utils/FileInput'
import { DocumentViewer } from '../../utils/documentViewer'
import { DocumentDownloader, downloadTheFile } from '../../utils/DocumentDownloader'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { invoiceTemplateTypes } from '../Edocs/Einvoice'
import { poTemplateTypes } from '../Edocs/EPO'

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" },
  { name: "Quote" }
]

const DocVaultTransactionDetails = ({ title, userTokenDetails, navToggleState, viewInPopup, onSelectFile, localStorageKeyName, search, folderView2, hideDefaultFilter }) => {

  let docVaultTransactionDetails = JSON.parse(localStorage.getItem(localStorageKeyName || 'docVaultTransactionDetails') || "{}")
  let history = useHistory()

  let forInvoice = !docVaultTransactionDetails?.lcPurpose

  const [filteredSearch, setFilteredSearch] = useState({})
  const [filter, setFilter] = useState({
    search
  })
  const [refresh, setRefresh] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const [dbdata, setDbdata] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [folderView, toggleFolderView] = useState("grid")

  const [applyForFinanceDocs, setApplyForFinanceDocs] = useState([])
  const [contractDocs, setContractDocs] = useState([])
  const [applyForLimitDocs, setApplyForLimitDocs] = useState([])
  const [edocs, setEdocs] = useState({})

  const [showFolders, toggleShowFolders] = useState({})

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")

  useEffect(() => {
    if (!docVaultTransactionDetails.applicationId) {
      loadDocumentsFromGlobalSearch()
    }
    else {
      if (isNaN(docVaultTransactionDetails.applicationId)) {
        loadEdocs()
      }
      else {
        loadDocuments()
      }
    }
  }, [filter.search])

  useEffect(() => {
    setFilter({ ...filter, search })
    toggleFolderView(folderView2)
  }, [search, folderView2])

  async function loadDocumentsFromGlobalSearch() {
    if (!search) {
      return setEdocs({})
    }
    setshowLoader(true)
    let tempEdocs = {}
    let apiResp = await call('POST', 'searchDocGloballyInDocumentVault', {
      userId, search, invoiceTemplateTypes, poTemplateTypes
    })
    let folderNames = Object.keys(apiResp)
    for (let index = 0; index < folderNames.length; index++) {
      tempEdocs[folderNames[index]] = []
      let folderData = apiResp[folderNames[index]]
      for (let j = 0; j < folderData.length; j++) {
        let fileIfPresent = await getDocDetails(folderData[j]["docId"], null, null, null, true)
        if (fileIfPresent.name) {
          fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
          fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
          tempEdocs[folderNames[index]].push({
            file: fileIfPresent
          })
        }
      }
    }
    setEdocs({ ...tempEdocs })
    setshowLoader(false)
  }

  async function loadEdocs() {
    setshowLoader(true)
    let searchQuery = filter?.search ? filter.search.toLowerCase() : ""
    if (docVaultTransactionDetails.applicationId === "invoice") {
      let tempEdocs = {}
      for (let index = 0; index < invoiceTemplateTypes.length; index++) {
        let apiResp = await call('POST', 'getEdocs', {
          userId, userEmail, userTypeId, "currentPage": 1, "resultPerPage": 1000, "search": "",
          "invoiceType": [`'${invoiceTemplateTypes[index]["name"]}'`]
        })
        apiResp = apiResp["data"] || []
        tempEdocs[invoiceTemplateTypes[index]["name"]] = []
        for (let j = 0; j < apiResp.length; j++) {
          let fileIfPresent = await getDocDetails(apiResp[j]["docId"], null, null, null, true)
          if (fileIfPresent.name) {
            fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
            fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
            if (searchQuery) {
              if (fileIfPresent.name.toLowerCase().includes(searchQuery)) {
                tempEdocs[invoiceTemplateTypes[index]["name"]].push({
                  file: fileIfPresent
                })
              }
            }
            else {
              tempEdocs[invoiceTemplateTypes[index]["name"]].push({
                file: fileIfPresent
              })
            }
          }
        }
      }
      setEdocs({ ...tempEdocs })
    }
    else if (docVaultTransactionDetails.applicationId === "po") {
      let tempEdocs = {}
      for (let index = 0; index < poTemplateTypes.length; index++) {
        let apiResp = await call('POST', 'getEdocs', {
          userId, userEmail, userTypeId, "currentPage": 1, "resultPerPage": 1000, "search": "",
          "invoiceType": [`'${poTemplateTypes[index]["name"]}'`], onlyPO: true
        })
        apiResp = apiResp["data"] || []
        tempEdocs[poTemplateTypes[index]["name"]] = []
        for (let j = 0; j < apiResp.length; j++) {
          let fileIfPresent = await getDocDetails(apiResp[j]["docId"], null, null, null, true)
          if (fileIfPresent.name) {
            fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
            fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
            if (searchQuery) {
              if (fileIfPresent.name.toLowerCase().includes(searchQuery)) {
                tempEdocs[poTemplateTypes[index]["name"]].push({
                  file: fileIfPresent
                })
              }
            }
            else {
              tempEdocs[poTemplateTypes[index]["name"]].push({
                file: fileIfPresent
              })
            }
          }
        }
      }
      setEdocs({ ...tempEdocs })
    }
    setshowLoader(false)
  }

  console.log("edocssssssssssssssssss", edocs);

  async function loadDocuments() {
    let searchQuery = filter?.search ? filter.search.toLowerCase() : ""

    let tempApplyForFinanceDocs = []
    let tempContractDocs = []
    let tempApplyForLimitDocs = []

    setshowLoader(true)
    let postShipmentDocs = forInvoice ? "invPostShipmentDocs" : "lcPostShipmentDocs"
    let invPostShipmentDocs = docVaultTransactionDetails?.[postShipmentDocs] ? docVaultTransactionDetails?.[postShipmentDocs].split(",") : []
    for (let index = 0; index < invPostShipmentDocs.length; index++) {
      let fileIfPresent = await getDocDetails(invPostShipmentDocs[index], null, null, null, true)
      if (fileIfPresent.name) {
        fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
        fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
        if (searchQuery) {
          if (fileIfPresent.name.toLowerCase().includes(searchQuery)) {
            tempApplyForFinanceDocs.push({
              file: fileIfPresent
            })
          }
        }
        else {
          tempApplyForFinanceDocs.push({
            file: fileIfPresent
          })
        }
      }
    }

    let fileIfPresent = await getDocDetails(docVaultTransactionDetails.termSheet, null, null, null, true)
    if (fileIfPresent.name) {
      fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
      fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
      if (searchQuery) {
        if (fileIfPresent.name.toLowerCase().includes(searchQuery)) {
          tempContractDocs.push({
            file: fileIfPresent
          })
        }
      }
      else {
        tempContractDocs.push({
          file: fileIfPresent
        })
      }
    }

    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: docVaultTransactionDetails.applicationId, fetchLCDocs: !forInvoice })
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      let fileIfPresent = await getDocDetails(element.docId, null, null, null, true)
      if (fileIfPresent.name) {
        fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
        fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
        if (searchQuery) {
          if (fileIfPresent.name.toLowerCase().includes(searchQuery)) {
            tempApplyForLimitDocs.push({
              file: fileIfPresent
            })
          }
        }
        else {
          tempApplyForLimitDocs.push({
            file: fileIfPresent
          })
        }
      }
    }
    fileIfPresent = await getDocDetails(docVaultTransactionDetails.draftLC, null, null, null, true)
    if (fileIfPresent.name) {
      fileIfPresent["size"] = (atob(fileIfPresent.filebase64).length / (1024 * 1024))
      fileIfPresent["size"] = fileIfPresent["size"].toFixed(2) + " MB"
      if (searchQuery) {
        if (fileIfPresent.name.toLowerCase().includes(searchQuery)) {
          tempApplyForLimitDocs.push({
            file: fileIfPresent
          })
        }
      }
      else {
        tempApplyForLimitDocs.push({
          file: fileIfPresent
        })
      }
    }

    setApplyForFinanceDocs(tempApplyForFinanceDocs)
    setContractDocs(tempContractDocs)
    setApplyForLimitDocs(tempApplyForLimitDocs)
    setshowLoader(false)
  }

  // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", applyForFinanceDocs);

  let folders = []
  if (applyForFinanceDocs.length) {
    folders.push({ name: 'Apply For Finance', toggle: 'applyForFinance' })
  }
  if (contractDocs.length) {
    folders.push({ name: 'Contract', toggle: 'contractDocs' })
  }
  if (applyForLimitDocs.length) {
    folders.push({ name: 'Apply For Limit', toggle: 'applyForLimit' })
  }
  console.log(folders, "folders here-->>>>")

  const downloadFilesAsZip = async () => {
    let files = []
    let folderName = ""

    if (showFolders["applyForFinance"] && applyForFinanceDocs.length) {
      files = applyForFinanceDocs
      folderName = "Apply For Finance"
    }
    if (showFolders["contractDocs"] && contractDocs.length) {
      files = contractDocs
      folderName = "Contract"
    }
    if (showFolders["applyForLimit"] && applyForLimitDocs.length) {
      files = applyForLimitDocs
      folderName = "Apply For Limit"
    }

    if (folderName && files.length) {

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        downloadTheFile(element.file)
      }

      // // Initialize a new instance of JSZip
      // const zip = new JSZip();

      // // Loop through the array of Base64-encoded files
      // for (let i = 0; i < files.length; i++) {
      //   // Decode the Base64 data
      //   const fileData = atob(files[i].file.filebase64);

      //   // Create a Blob from the decoded data
      //   const blob = new Blob([fileData], { type: "application/pdf" });

      //   // Add the Blob to the zip file with a specific filename
      //   zip.file(`${files[i].file.name}(${files[i].file.name}).pdf`, blob);
      // }

      // // Generate the zip file
      // const zipBlob = await zip.generateAsync({ type: 'blob' });

      // // Save the zip file using FileSaver.js
      // saveAs(zipBlob, `${folderName}.zip`);

    }
    else {
      toastDisplay('Select folder to download', "info")
    }
  };

  function handleDocumentView(file) {
    if (viewInPopup) {
      onSelectFile(file);
    } else {
      try {
        // Trim and validate the base64 string
        let base64Data = file.filebase64.trim();

        if (!isValidBase64Header(base64Data)) {
          console.error("Invalid Base64 format");
          return;
        }

        // Decode Base64 string and create a Blob object
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(
          Array.from(byteCharacters).map((char) => char.charCodeAt(0))
        );
        const blob = new Blob([byteNumbers], { type: "application/pdf" });

        // Create a URL for the Blob and open it in a new tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");

        // Revoke the URL after some time to free up memory
        setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
      } catch (error) {
        console.error("Failed to open document:", error);
      }
    }
  }

  //   // Helper function to validate Base64 header and structure
  function isValidBase64Header(str) {
    // Check if it starts with a proper Base64 header (minimal validation)
    return /^[A-Za-z0-9+/]+={0,2}$/.test(str.substring(0, 100)); // Test only the first 100 characters
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          {viewInPopup ? null :
            <SideBarV2 state="docVaultTransaction" userTokenDetails={userTokenDetails} />}
          <main role="main" className={`ml-sm-auto ${viewInPopup ? ' col-lg-12 m-0 p-0 ' : ' col-lg-10 '} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {viewInPopup ? null :
              <HeaderV2
                title={"Document Vault > Details"}
                userTokenDetails={userTokenDetails} />}
            <div className='card p-5' >
              {viewInPopup ? null :
                <div className='d-flex align-items-center row'>
                  <a className="cursor col-3"
                    onClick={() => { history.go(-1) }}
                  ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                  <label
                    className='font-size-13 font-wt-500 col-9'
                  >{`${docVaultTransactionDetails.buyerName || "-"} - ${LCPurposeObject[docVaultTransactionDetails.lcPurpose] || "Invoice Discounting"}`}</label>
                </div>}
              {hideDefaultFilter ? null :
                <div className='d-flex row my-5 pl-3' >
                  <div
                    onClick={() => { toggleFolderView(folderView === "list" ? "grid" : "list") }}
                    style={{ width: '8%', height: '2.5rem' }} className='card d-flex flex-row cursor' >
                    <div className=' d-flex align-items-center cursor' >
                      <img className='cursor' src={folderView === "list" ? `assets/images/list_icon.png` : `assets/images/grid_icon.png`} />
                    </div>
                    <label className='font-size-13 font-wt-600 mt-2 ml-2 cursor'>{folderView === "list" ? "List" : "Grid"}</label>
                  </div>
                  <div style={{ width: '92%', marginTop: '-1rem' }} className='d-flex align-items-center justify-content-end' >
                    <Filter
                      filteredSearch={filteredSearch} showDownloadIcon={viewInPopup ? false : true}
                      setFilteredSearch={setFilteredSearch} onDownloadClick={downloadFilesAsZip}
                      filterData={filterData} setFilterData={setFilterData} showFilterBtn={false}
                      showResultPerPage={false} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                  </div>
                </div>}
              {title ?
                <label className='font-size-16 font-wt-500 w-100 text-center mb-3' ><u>{title}</u></label>
                : null}
              {folderView === "list" ? <div>
                <NewTablev2
                  columns={[{ subColumns: 'Name', filter: false, subColumnStyle: { width: "40%" } },
                  { subColumns: 'Last Modified', filter: false, subColumnStyle: { width: "16%" } },
                  { subColumns: 'Size', filter: false, subColumnStyle: { width: "13%" } },
                  { subColumns: 'Type', filter: false, subColumnStyle: { width: "10%" } },
                  { subColumns: '', filter: false, subColumnStyle: { width: "16%" } }]}
                >
                  {Object.keys(edocs).length ? Object.keys(edocs).map((i) => {
                    return <tr>
                      <td>
                        <div className="d-flex" >
                          <div style={{ height: '1.5rem' }}
                            onClick={() => { toggleShowFolders({ [i]: !showFolders[i] }) }}
                            className='d-flex cursor' >
                            <img style={{ rotate: showFolders[i] ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
                            <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
                            <label className='font-size-12 font-wt-500 cursor pt-1' >{i}</label>
                          </div>
                        </div>
                        {showFolders[i] && edocs[i].map((i, j) => {
                          return (
                            <div className='mt-3' >
                              <FileInput showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        <div style={{ height: '1.5rem' }} className="d-flex" >
                        </div>
                        {showFolders[i] && edocs[i].map((i, j) => {
                          return (
                            <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                              <label className='font-size-12 font-wt-500' >{moment(i.file.modified_at).format('DD/MM/YYYY HH:mm')}</label>
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        <div style={{ height: '1.5rem' }} className="d-flex" >
                        </div>
                        {showFolders[i] && edocs[i].map((i, j) => {
                          return (
                            <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                              <label className='font-size-12 font-wt-500' >{i.file.size}</label>
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        <div style={{ height: '1.5rem' }} className="d-flex" >
                        </div>
                        {showFolders[i] && edocs[i].map((i, j) => {
                          return (
                            <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                              <label className='font-size-12 font-wt-500' >{'PDF'}</label>
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        <div style={{ height: '1.5rem' }} className="d-flex" >
                        </div>
                        {showFolders[i] && edocs[i].map((i, j) => {
                          return (
                            <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                              <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
                                onClick={() => {
                                  if (viewInPopup) {
                                    onSelectFile(i.file)
                                  }
                                  else {
                                    handleDocumentView(i.file)
                                    // toggleViewDoc({ show: true, doc: i.file })
                                  }
                                }}
                              >{viewInPopup ? `Select Document` : `View Document`}</button>
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  }) :
                    <>
                      {applyForFinanceDocs.length ?
                        <tr>
                          <td>
                            <div className="d-flex" >
                              <div style={{ height: '1.5rem' }}
                                onClick={() => { toggleShowFolders({ applyForFinance: !showFolders.applyForFinance }) }}
                                className='d-flex cursor' >
                                <img style={{ rotate: showFolders.applyForFinance ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
                                <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
                                <label className='font-size-12 font-wt-500 cursor pt-1' >{"Apply For Finance"}</label>
                              </div>
                            </div>
                            {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
                              return (
                                <div className='mt-3' >
                                  <FileInput showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{moment(i.file.modified_at).format('DD/MM/YYYY HH:mm')}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{i.file.size}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{'PDF'}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForFinance"] && applyForFinanceDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
                                    onClick={() => {
                                      if (viewInPopup) {
                                        onSelectFile(i.file)
                                      }
                                      else {
                                        handleDocumentView(i.file)
                                        // toggleViewDoc({ show: true, doc: i.file })
                                      }
                                    }}
                                  >{viewInPopup ? `Select Document` : `View Document`}</button>
                                </div>
                              )
                            })}
                          </td>
                        </tr> : null}

                      {contractDocs.length ?
                        <tr>
                          <td>
                            <div className="d-flex" >
                              <div style={{ height: '1.5rem' }}
                                onClick={() => { toggleShowFolders({ contractDocs: !showFolders.contractDocs }) }}
                                className='d-flex cursor' >
                                <img style={{ rotate: showFolders.contractDocs ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
                                <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
                                <label className='font-size-12 font-wt-500 cursor pt-1' >{"Contract"}</label>
                              </div>
                            </div>
                            {showFolders["contractDocs"] && contractDocs.map((i, j) => {
                              return (
                                <div className='mt-3' >
                                  <FileInput showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["contractDocs"] && contractDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{moment(i.file.modified_at).format('DD/MM/YYYY HH:mm')}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["contractDocs"] && contractDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{i.file.size}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["contractDocs"] && contractDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{'PDF'}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["contractDocs"] && contractDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
                                    onClick={() => {
                                      if (viewInPopup) {
                                        onSelectFile(i.file)
                                      }
                                      else {
                                        handleDocumentView(i.file)
                                        // toggleViewDoc({ show: true, doc: i.file })
                                      }
                                    }}
                                  >{viewInPopup ? `Select Document` : `View Document`}</button>
                                </div>
                              )
                            })}
                          </td>
                        </tr> : null}

                      {applyForLimitDocs.length ?
                        <tr>
                          <td>
                            <div className="d-flex" >
                              <div style={{ height: '1.5rem' }}
                                onClick={() => { toggleShowFolders({ applyForLimit: !showFolders.applyForLimit }) }}
                                className='d-flex cursor' >
                                <img style={{ rotate: showFolders.applyForLimit ? "180deg" : "" }} className='cursor' src='assets/images/arrow.png' />
                                <img className='mx-3 cursor' src='assets/images/folder_icon.png' />
                                <label className='font-size-12 font-wt-500 cursor pt-1' >{"Apply For Limit"}</label>
                              </div>
                            </div>
                            {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
                              return (
                                <div className='mt-3' >
                                  <FileInput showFileNameW100={true} value={i.file} hideViewDowdBtns={true} />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{moment(i.file.modified_at).format('DD/MM/YYYY HH:mm')}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{i.file.size}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <label className='font-size-12 font-wt-500' >{'PDF'}</label>
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div style={{ height: '1.5rem' }} className="d-flex" >
                            </div>
                            {showFolders["applyForLimit"] && applyForLimitDocs.map((i, j) => {
                              return (
                                <div style={{ height: '3rem' }} className='mt-3 d-flex align-items-center'>
                                  <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
                                    onClick={() => {
                                      if (viewInPopup) {
                                        onSelectFile(i.file)
                                      }
                                      else {
                                        handleDocumentView(i.file)
                                        // toggleViewDoc({ show: true, doc: i.file })
                                      }
                                    }}
                                  >{viewInPopup ? `Select Document` : `View Document`}</button>
                                </div>
                              )
                            })}
                          </td>
                        </tr> : null} </>}
                </NewTablev2>
              </div> :
                <div>
                  <div className='d-flex row' >
                    {Object.keys(edocs).map((i, j) => {
                      return (
                        <div
                          onClick={() => toggleShowFolders({ [i]: true })}
                          style={showFolders[i] ? { backgroundColor: '#EEE', borderRadius: 10 } : {}}
                          className='w-15 cursor' >
                          <div className='text-center w-100' >
                            <img src='assets/images/folder_big_icon.png' />
                          </div>
                          <div>
                            <label className="font-size-12 font-wt-500 text-center w-100" >{i}</label>
                          </div>
                        </div>
                      )
                    })}
                    {folders.map((i, j) => {
                      return (
                        <div
                          onClick={() => toggleShowFolders({ [i.toggle]: true })}
                          style={showFolders[i.toggle] ? { backgroundColor: '#EEE', borderRadius: 10 } : {}}
                          className='w-15 cursor' >
                          <div className='text-center w-100' >
                            <img src='assets/images/folder_big_icon.png' />
                          </div>
                          <div>
                            <label className="font-size-12 font-wt-500 text-center w-100" >{i.name}</label>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className='d-flex row' >
                    {Object.keys(edocs).map((i, j) => {
                      if (showFolders[i] && edocs[i]?.length) {
                        console.log("eeeeeeeeeeeeeeeeeeeeeeeeee", edocs[i]);
                        return <>
                          {edocs[i].map((k, index) => {
                            return (
                              <div
                                onClick={() => {
                                  if (viewInPopup) {
                                    onSelectFile(edocs[i][index].file)
                                  }
                                  else {
                                    toggleViewDoc({
                                      show: true, doc: edocs[i][index].file
                                    })
                                  }
                                }}
                                style={{ height: '11rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
                                <div style={{ height: '8rem' }} className='pt-3 px-3'>
                                  <object
                                    style={{ overflowX: 'hidden' }}
                                    data={correctFileBase64(edocs[i][index].file.filebase64)}
                                    scrolling="no"
                                    type="application/pdf" width="100%" height="100%">
                                    <param name="navpanes" value="0"></param>
                                  </object>
                                </div>
                                <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
                                  <label className='font-size-12 font-wt-500 w-100' >{edocs[i][index].file.name}</label>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      }
                    })}
                    {applyForFinanceDocs.length && showFolders["applyForFinance"] ?
                      applyForFinanceDocs.map((i, j) => {
                        return (
                          <div
                            onClick={() => {
                              if (viewInPopup) {
                                onSelectFile(i.file)
                              }
                              else {
                                toggleViewDoc({
                                  show: true, doc: i.file
                                })
                              }
                            }}
                            style={{ height: '11rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
                            <div style={{ height: '8rem' }} className='pt-3 px-3'>
                              <object
                                style={{ overflowX: 'hidden' }}
                                data={correctFileBase64(i.file.filebase64)}
                                scrolling="no"
                                type="application/pdf" width="100%" height="100%">
                                <param name="navpanes" value="0"></param>
                              </object>
                            </div>
                            <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
                              <label className='font-size-12 font-wt-500 w-100' >{i.file.name}</label>
                            </div>
                          </div>
                        )
                      })
                      : null}
                  </div>

                  <div className='d-flex row' >
                    {contractDocs.length && showFolders["contractDocs"] ?
                      contractDocs.map((i, j) => {
                        return (
                          <div
                            onClick={() => {
                              if (viewInPopup) {
                                onSelectFile(i.file)
                              }
                              else {
                                toggleViewDoc({
                                  show: true, doc: i.file
                                })
                              }
                            }}
                            style={{ height: '11rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
                            <div style={{ height: '8rem' }} className='pt-3 px-3'>
                              <object
                                style={{ overflowX: 'hidden' }}
                                data={correctFileBase64(i.file.filebase64)}
                                scrolling="no"
                                type="application/pdf" width="100%" height="100%">
                                <param name="navpanes" value="0"></param>
                              </object>
                            </div>
                            <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
                              <label className='font-size-12 font-wt-500 w-100' >{i.file.name}</label>
                            </div>
                          </div>
                        )
                      })
                      : null}
                  </div>

                  <div className='d-flex row' >
                    {applyForLimitDocs.length && showFolders["applyForLimit"] ?
                      applyForLimitDocs.map((i, j) => {
                        return (
                          <div
                            onClick={() => {
                              if (viewInPopup) {
                                onSelectFile(i.file)
                              }
                              else {
                                toggleViewDoc({
                                  show: true, doc: i.file
                                })
                              }
                            }}
                            style={{ height: '11rem', width: '22%' }} className='card mr-4 mt-4 d-flex align-items-center p-0 cursor' >
                            <div style={{ height: '8rem' }} className='pt-3 px-3'>
                              <object
                                style={{ overflowX: 'hidden' }}
                                data={correctFileBase64(i.file.filebase64)}
                                scrolling="no"
                                type="application/pdf" width="100%" height="100%">
                                <param name="navpanes" value="0"></param>
                              </object>
                            </div>
                            <div className='w-100 px-3 text-center' style={{ borderTop: '1px solid #ced4da' }} >
                              <label className='font-size-12 font-wt-500 w-100' >{i.file.name}</label>
                            </div>
                          </div>
                        )
                      })
                      : null}
                  </div>

                </div>
              }

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data
  }
}

export default connect(mapStateToProps)(DocVaultTransactionDetails)