import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import { InputForTable, InputWithSelectForTable, NewInput, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import { handleFileCommonFunction, most_used_currencies, printDiv } from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import avatarUrl from "../../utils/makeAvatarUrl";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FileInput } from "../../utils/FileInput";
import SignDocument, { signFonts } from "../InvoiceDiscounting/components/SignDocument";
import DropdownSearch from "../tallyReports/Cards/dropdownWithSearch";


let invItemsTable = [{ subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
{ subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '20%' } },
{ subColumns: "HSN NO.", subColumnStyle: { width: '10%' } },
{ subColumns: "QUANTITY", subColumnStyle: { width: '10%' } },
{ subColumns: "UNIT PRICE", subColumnStyle: { width: '12%' } },
{ subColumns: "TAX %", subColumnStyle: { width: '12%' } },
{ subColumns: "TAX AMOUNT", subColumnStyle: { width: '12%' } },
{ subColumns: "TOTAL PRICE", subColumnStyle: { width: '15%' } }
]


let chargesTable = [{ subColumns: "CHARGE TITLE", subColumnStyle: { width: '45%' } }, { subColumns: "AMOUNT", subColumnStyle: { width: '45%' } }]

const ChallanOrder = ({ fromSP, docType, setSelectedDocument = {}, setrefresh, editDocument = {}, userTokenDetails, navToggleState, selectedInvoiceData, buyerDetails, sellerDetails, handleGoBack, setSelectedBuyer }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  console.log("in challan", editDocument)

  const userTypeId = userTokenDetails?.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails?.email ? userTokenDetails.email : null
  const userId = userTokenDetails?.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails?.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails?.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails?.parent_data ? userTokenDetails.parent_data : null;
  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  // const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState(editDocument ? {
    invCurrency: 'USD', invChargeTitle0: "SUBTOTAL", invChargeTitle1: 'HANDLING', nviChargeTitle2: 'FREIGHT',
    invChargeTitle3: 'MISC.', ...editDocument, userSelected: editDocument.companyName || ""
  } : {
    invCurrency: 'USD', invChargeTitle0: "SUBTOTAL", invChargeTitle1: 'HANDLING', nviChargeTitle2: 'FREIGHT',
    invChargeTitle3: 'MISC.'
  })
  // invCurrency: 'USD', invChargeTitle0: "SUBTOTAL", invChargeTitle1: 'HANDLING', invChargeTitle2: 'FREIGHT',
  //   invChargeTitle3: 'MISC.'
  const [error, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [invOther, setInvOther] = useState([null, null, null, null])
  const [preview, setPreview] = useState({})
  const [showPopup, togglePopup] = useState(false)
  const [signdoc, setSigndoc] = useState(false);
  const [usersOnboard, setusersOnBoard] = useState([])
  const [mstCommList, setMstCommList] = useState([])
  const [termsConditions, setTermsConditions] = useState([{ id: Date.now(), value: '' }]);
  const [removeDeliveredBy, setRemoveDeliveredBy] = useState(true)
  const [removeReceivedBy, setRemoveReceivedBy] = useState(true)
  let jj = -1
  let kk = -1

  useEffect(() => {
    if (selectedInvoiceData?.docData) {
      setData(selectedInvoiceData.docData?.data)
      setInvItems(selectedInvoiceData.docData?.invItems)
      setInvOther(selectedInvoiceData.docData.invOther)
    }
  }, [])

  // useEffect(() => {
  //   if (buyerDetails && sellerDetails) {
  //     setData(prevData => ({
  //       ...prevData,
  //       companyContactNo: buyerDetails?.companyContactNo,
  //       companyEmailId: buyerDetails?.companyEmailId,
  //       companyWebsite: buyerDetails?.companyWebsite,
  //       originAddress: buyerDetails?.address,
  //       companyName: buyerDetails?.companyName,
  //       finalDestination: sellerDetails?.address,
  //       shipToContactNo: buyerDetails?.companyContactNo,
  //       shipToEmailId: buyerDetails?.companyEmailId,
  //       shipToCompanyName: buyerDetails?.companyName,
  //       shipToContactName: buyerDetails?.contactName,
  //       clientContactName: sellerDetails?.contactName,
  //       clientCompanyName: sellerDetails?.companyName,
  //       clientEmailId: sellerDetails?.companyEmailId,
  //       clientContactNo: sellerDetails?.companyContactNo,
  //       clientAddress: sellerDetails?.address,

  //     }))
  //   };
  // }, [buyerDetails, sellerDetails]);
  useEffect(() => { console.log("data in challan", data) }, [data])
  const handleCommodityChange = (selectedCommodity, j) => {
    // console.log(selectedCommodity, j)
    const selectedItem = mstCommList.find(
      (comm) => comm.commodity_pretty_name === selectedCommodity
    );
    // console.log("selectedItem:", selectedItem)
    if (selectedItem) {
      setData((prevData) => ({
        ...prevData,
        [`itemDesc${j}`]: selectedItem.commodity_pretty_name,
        [`itemHSN${j}`]: selectedItem.hsn,
        [`itemTax%${j}`]: parseFloat(selectedItem.gstRate) || 0,
        [`itemQuantityUnits${j}`]: selectedItem.unit,
        [`itemUnitPrice${j}`]: selectedItem.procured.length ? selectedItem.procured[0]["rate"] : 0

      }));
    }

  };
  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountrys(result.data.message);
      }
    });
    call('POST', 'getusercommoditylist', { userId }).then((result) => {
      console.log("result in getCommodityList-->", result)
      setMstCommList(result || [])
    }).catch((e) => {
      console.log('error in getCommodityList', e);
    })

  }, []);
  function generateChallanNumber() {
    const now = new Date();

    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    // ${year}
    const ChallanNumber = `CL${month}${day}${hours}${minutes}${seconds}`;

    return ChallanNumber;
  }
  useEffect(() => {
    call('POST', "getallBuyersandExporters", { userId }).then((res) => setusersOnBoard(res))
    if (!data.challan) {
      const num = generateChallanNumber()
      setData(prev => ({ ...prev, "challan": num }))
    }
    if (!data.clnInvoiceDate) {
      setData(prev => ({ ...prev, "clnInvoiceDate": new Date().toISOString().split('T')[0] }))
    }

  }, [])



  console.log(usersOnboard, "this is users on board---.....", data['userSelected'], "this is data selected------")



  async function handleChange(e, i) {
    e.persist()
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    const updatedTermsConditions = [...termsConditions];
    // if (e.target.name.includes(`invSubTotalAmount`)) {
    //   console.log("looged")
    //   let invTotalAmount1 = 0
    //   for (let index = 0; index < invOther.length; index++) {
    //     let amountToAdd = data[`invSubTotalAmount${index}`] || 0
    //     if (invOther[index] === null) {
    //       invTotalAmount1 += amountToAdd / 1
    //     }
    //   }
    //   console.log("invTotalAmount1 || invTotalAmount", invTotalAmount1)
    //   updatedData['invTotalAmount'] = invTotalAmount1

    // }

    if (name === 'itemAddOn') {
      updatedData[`itemAddOn${i}`] = value;
    }
    if (e.target.name.includes('itemTotalAmount')) {
      let invSubTotalAmount = 0
      for (let index = 0; index < invItems.length; index++) {
        let amountToAdd = e.target.name === `itemTotalAmount${index}` ? e.target.value :
          (data[`itemTotalAmount${index}`] || 0)
        if (invItems[index] === null) {
          invSubTotalAmount += amountToAdd / 1
        }
      }
      setData({ ...data, [e.target.name]: e.target.value, [`invSubTotalAmount0`]: invSubTotalAmount })
      setErrors({ ...error, [e.target.name]: "" })
    }
    if (name.includes('itemQuantity') || name.includes('itemUnitPrice') || name.includes('itemTax%') || name.includes('invSubTotalAmount')) {
      console.log("called sub")
      const index = name.match(/\d+/)[0];
      const quantity = parseFloat(updatedData[`itemQuantity${index}`]) || 0;
      const unitPrice = parseFloat(updatedData[`itemUnitPrice${index}`]) || 0;
      const taxPercentage = parseFloat(updatedData[`itemTax%${index}`]) || 0;

      const taxAmount = quantity * unitPrice * (taxPercentage / 100);
      const totalAmount = (quantity * unitPrice) + taxAmount;

      updatedData[`itemTotalAmount${index}`] = totalAmount.toFixed(2);
      updatedData[`itemTax${index}`] = taxAmount.toFixed(2);

      let invTotalTax = 0;
      let invTotalAmount = 0;
      invItems.forEach((item, idx) => {
        const totalAmt = parseFloat(updatedData[`itemTotalAmount${idx}`]) || 0;
        const taxAmt = parseFloat(updatedData[`itemTax${idx}`]) || 0;
        if (item === null) {
          // invTotalAmountBeforeTax += totalAmt - taxAmt;
          invTotalTax += taxAmt;
          invTotalAmount += totalAmt;
        }
      });
      let invtotalwithextras = invTotalAmount
      for (let index = 0; index < invOther.length; index++) {
        // console.log(invItems.length)
        if (index > 0 && updatedData[`invSubTotalAmount${index}`]) {
          // console.log("invtotalwithextras1", invtotalwithextras)
          invtotalwithextras += parseFloat(updatedData[`invSubTotalAmount${index}`]) || 0
        }
      };
      console.log("invtotalwithextras2", invtotalwithextras)

      updatedData['invTotalAmount'] = invtotalwithextras.toFixed(2)

      updatedData[`invTotalTax`] = invTotalTax.toFixed(2);

      updatedData['invSubTotalAmount0'] = invTotalAmount.toFixed(2);

      updatedData['invTotalAmountBeforeTax'] = (invtotalwithextras - invTotalTax).toFixed(2)

    }
    if (name.includes('invTotalAmount') || name.includes('invAdvToPay')) {
      const totalamt = parseFloat(updatedData['invTotalAmount']) || 0;
      const adv = parseFloat(updatedData['invAdvToPay']) || 0;
      updatedData['invBalanceToPay'] = (totalamt - adv).toFixed(2)


    }



    // Update the state with the new values
    setData(updatedData);
    setErrors({ ...error, [name]: "" });
  }



  const saveToDb = async (type) => {
    console.log("here savetodb", type, editDocument)
    const objectAPI = {
      userId: userId,
      docType: docType || "",
      status: editDocument.itemStatus || type === "new" ? 0 : 1,
      data: data,
      docId: data.challan,
      sellerId: editDocument ? editDocument["buyerId"] : userTokenDetails.sub_user_id,
      buyerId: editDocument ? editDocument["sellerId"] : buyerDetails?.companyId,
      appLink: "",
      appId: editDocument ? editDocument["idFromDB"] : ""
    }
    console.log("in save to db", objectAPI)
    if (editDocument) {
      if (type === "draft" && editDocument.itemStatus === 1) {
        objectAPI.status = 1
      }
      if (type === "new" && editDocument.itemStatus === 1) {
        objectAPI.status = 0
      }
      if (editDocument.addingOC) {
        // this condition for only if doc is coming from sq and saved as draft
        if (type === "draft") objectAPI.status = 1
        objectAPI.transaction_timeline = { ...editDocument.transaction_timeline, "Delivery Challan": new Date().toLocaleString() }
        await call('POST', 'createSalesPurchaseQuotation', objectAPI)
          .then((result) => {
            console.log(result)
          })
          .catch((e) => console.log("error in saving into db", e))
      }
      else {
        objectAPI.transaction_timeline = { ...editDocument.transaction_timeline, "Delivery Challan": new Date().toLocaleString() }
        await call('POST', 'updateSalesPurchaseQuotation', objectAPI)
          .then((result) => {
            console.log(result)
          })
          .catch((e) => console.log("error in saving into db", e))
      }
    } else {
      console.log("new entry")
      objectAPI.transaction_timeline = { "Delivery Challan": new Date().toLocaleString() }
      await call('POST', 'createSalesPurchaseQuotation', objectAPI)
        .then((result) => {
          console.log(result)
        })
        .catch((e) => console.log("error in saving into db", e))
    }

    setrefresh(prev => prev + 1)
    handleGoBack()

  }

  useEffect(() => {
    if (data?.commInvoice?.name) {
      saveCommInvoice()
    }
  }, [data.commInvoice])


  async function saveCommInvoice() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("userId", userId)
    formData.append("docType", "dc")
    formData.append("docTemplate", "Delivery Challan")
    formData.append("docName", `Delivery Challan - ${data.challan}`)

    formData.append("doc", data.commInvoice)
    if (selectedInvoiceData?.id) {
      formData.append("updateDocId", selectedInvoiceData.id)
    }
    let tempData = data
    delete tempData["commInvoice"]
    formData.append('docData', JSON.stringify({ data: tempData, invItems, invOther }))
    await call('POST', 'saveEdoc', formData)
    setshowLoader(false)
    toastDisplay("Document downloaded & saved", "success")
    window.location = '/deliveryChallan'
  }

  const deleteDiv = (type) => {


    if (type === "receivedBy") {
      setRemoveReceivedBy(!removeReceivedBy)

    } else if (type === "deliveredBy") {
      setRemoveDeliveredBy(!removeDeliveredBy)
    }
  }
  // async function saveCommInvoice() {
  //   setshowLoader(true)

  //   let formData = new FormData()
  //   formData.append("userId", userId)
  //   formData.append("docType", "dc")
  //   formData.append("docTemplate", "Delivery Challan")
  //   formData.append("docName", `Delivery Challan - ${data.challan}`)
  //   formData.append("doc", data.commInvoice)


  //   if (selectedInvoiceData?.id) {
  //     formData.append("updateDocId", selectedInvoiceData.id)
  //   }
  //   let tempData = data
  //   delete tempData["commInvoice"]
  //   formData.append('docData', JSON.stringify({ data: tempData, invItems, invOther }))
  //   await call('POST', 'saveEdoc', formData)
  //   setshowLoader(false)
  //   toastDisplay("Document downloaded & saved", "success")
  // }




  return (
    <>
      {signdoc && <SignDocument onlyReturnSign={true} setSigndoc={setSigndoc}
        setUpdatedDoc={(signDetails) => {
          console.log("signDetailsssssssssssssssss", signDetails);
          setData({ ...data, invSign: signDetails })
        }} />
      }



      {showPopup && <FinanceInvoiceModal limitinvoice={showPopup} setLimitinvoice={togglePopup} closeSuccess={() => togglePopup(false)} >
        <div className="col-md-10 mb-2 ml-5">
          <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>Upload Company Logo</label>
          <FileInput
            onUploadCancel={() => { setData({ ...data, companyLogo: {} }) }} name={"companyLogo"} value={data["companyLogo"]}
            onChange={(event) => {
              let file_type = event.target.files?.[0]?.["type"]?.toLowerCase()
              if (file_type && (file_type.includes("png") || file_type.includes("jpeg"))) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = async (e) => {
                  let fileObj = event.target.files[0]
                  let fileDataUrl = e.target.result
                  fileObj["filebase64"] = fileDataUrl
                  setData({ ...data, [event.target.name]: fileObj })
                  togglePopup(false)
                }
              }
              else {
                setErrors({ ...error, companyLogo: 'Only png & jpeg images are supported' })
              }
            }}
            error={error.companyLogo} isEditable={true} />
          {error.companyLogo ? <div class="text-danger mt-2 font-size-12">
            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
            <b>{error.companyLogo}</b></div> : ''}
        </div>
      </FinanceInvoiceModal>}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='card mt-1 pt-4' >
        <div className=" row m-0 p-0 justify-content-end"     >
          {fromSP && <div >
            <img onClick={handleGoBack} class="bg-transparent d-block cursor-pointer me-2" src="assets/images/back.png" alt="Back" height="15" width="20"></img>
          </div>}
          <div className={`${!preview.show ? ' w-20 ' : ' w-30 '} d-flex justify-content-between`} >
            <button type="button"
              onClick={() => { setPreview({ ...preview, show: !preview.show }) }}
              className={` new-btn ${!preview.show ? ' w-100 ' : ' w-45 '} py-2 px-2 text-white`}>
              {!preview.show ? "Preview" : "Back to Editing"}
            </button>
            <button type="button"
              onClick={() => {
                data.status = 5
                saveToDb("draft")
                // handleGoBack()
              }}
              className={` new-btn4 py-2 px-2 me-2 text-white`} style={{ width: "300px" }}>
              Save As Draft
            </button>
            {preview.show ?
              <button type="button"
                onClick={() => {
                  printDiv("invoiceDiv", `Delivery Challan - ${data.challan}`, [], data, setData, "commInvoice")
                  // saveToDb("new")
                }}
                className={` new-btn w-45 py-2 px-2  text-white`}>
                {"Save & Download"}
              </button> : null}
          </div>
        </div>
        <div id={"invoiceDiv"} className="p-4" >
          <div style={{ borderRadius: "5px" }} >
            <div className="p-3 mb-2" style={{ border: '2px solid #000' }}>
              <div
                className="d-flex flex-column justify-content-end align-items-end py-1" >
                <label className=" font-wt-500" style={{ color: "#16c1e0", fontSize: "33px " }} >DELIVERY CHALLAN</label>

              </div>
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="font-size-20 font-wt-400">
                  <span>Date - </span>
                  <span >{data.clnInvoiceDate}</span>
                </div>
                <div className="font-size-20 font-wt-400">
                  <span>Challan Number - </span>
                  <span >{data.challan}</span>
                </div>

              </div>
            </div>
            <div className="row m-0 d-flex row" >
              <div className="col-12 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderLeft: '2px solid #000' }}>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      fontClass={" font-size-22 font-wt-600 "}
                      isDisabled={preview.show} placeholder={(!userTokenDetails.userName && !preview.show) ? "<COMPANY NAME>" : ""} name={"inputcompanyName"} value={userTokenDetails.userName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.clientAddress && !preview.show) ? "Enter Address" : ""} name={"clientAddress"} value={data.clientAddress} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.clientWebsite && !preview.show) ? "Enter Website" : ""} name={"clientWebsite"} value={data.clientWebsite} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.clientEmailId && !preview.show) ? "Enter Email Id" : ""} name={"clientEmailId"} value={data.clientEmailId} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.clientContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"clientContactNo"} value={data.clientContactNo} onChange={handleChange} />
                  </div>
                </div>
              </div>




            </div>




            <div className="row m-0 d-flex row mt-2" >
              <div className="col-12 p-0" >
                <div className=""
                >
                  {/* <label
                    style={{ border: '2px solid #000', borderBottom: 0 }} className="font-size-15 font-wt-500 p-3 w-100 text-center m-0 p-0" ><u>#SHIPMENT INFORMATION</u></label> */}
                  <div className="col-12 p-0 m-0">
                    <div style={{}} className="d-flex flex-wrap" >
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000' }}
                        >
                          <label className="font-size-15 font-wt-500" ><u>DELIVERY CHALLAN</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} placeholder={(!data.challan && !preview.show) ? "Enter Delivery Challan" : ""} name={"challan"} value={data.challan} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000', borderLeft: 0 }}>
                          <label className="font-size-15 font-wt-500" ><u>ORDER DATE</u></label>
                          <div className="position-relative" >
                            <InputForTable type="date" isDisabled={preview.show} placeholder={(!data.orderDate && !preview.show) ? "Enter Order Date" : ""} name={"orderDate"} value={data.orderDate} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000', borderLeft: 0 }}>
                          <label className="font-size-15 font-wt-500" ><u>DISPATCH DATE</u></label>
                          <div className="position-relative" >
                            <InputForTable type="date" isDisabled={preview.show} placeholder={(!data.dispatchDate && !preview.show) ? "Enter Dispatch Date" : ""} name={"dispatchDate"} value={data.dispatchDate} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000', borderLeft: 0 }}>
                          <label className="font-size-15 font-wt-500" ><u>SHIPPING TERMS</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} placeholder={(!data.shippingTerms && !preview.show) ? "Enter Shipping Terms" : ""} name={"shippingTerms"} value={data.shippingTerms} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div className="row m-0 d-flex row mt-8" >
              <div className="col-12 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderLeft: '2px solid #000' }}>
                  <label className="font-size-15 font-wt-500">Bill To:</label>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    {/* <InputForTable
                      fontClass={" font-size-22 font-wt-600 "}
                      isDisabled={preview.show} placeholder={(!data.companyName && !preview.show) ? "<BILL TO>" : ""} name={"companyName"} value={data.companyName} onChange={handleChange} /> */}

                    <InputWithSelectForTable type={"text"} isDisabled={preview.show}
                      defaultValue={editDocument.companyName || ""} selectData={usersOnboard} selectName={"userSelected"} selectValue={data[`userSelected`]}
                      optionLabel={"company_name"} optionValue={"company_name"}
                      name={`userSelected`} value={data[`userSelected`]} onChange={handleChange} />

                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.originAddress && !preview.show) ? "Enter Address" : ""} name={"originAddress"} value={data.originAddress} onChange={handleChange} />
                  </div>

                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.companyWebsite && !preview.show) ? "Enter Email Id" : ""} name={"companyWebsite"} value={data.companyWebsite} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.companyContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"companyContactNo"} value={data.companyContactNo} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.challanType && !preview.show) ? "Enter Challan Type" : ""} name={"challanType"} value={data.challanType} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.gst && !preview.show) ? "Enter GST Number" : ""} name={"gst"} value={data.gst} onChange={handleChange} />
                  </div>
                </div>
              </div>




            </div>




            <div className="p-3"
              style={{ border: '2px solid #000' }}>
              <NewTablev2 addTableClass={`m-0 invBorder`}
                columns={preview.show ? invItemsTable : invItemsTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '5%' } }])}
              >
                {invItems.map((i, j) => {
                  if (i === null) {
                    jj = jj + 1
                    if (preview.show && !data[`itemDesc${j}`]) {
                      return null
                    }
                    return (
                      <tr>
                        <td><label className="font-size-16 font-wt-500" >{jj + 1}</label></td>
                        <td>

                          <div className="position-relative w-100">
                            <DropdownSearch placeholder={"Select Commodity*"} isDisabled={preview.show} defaultItem={editDocument && data[`itemDesc${j}`]} items={mstCommList.map(item => item.commodity_pretty_name)} onSelect={handleCommodityChange} customStyles={{ "index": j }} />
                            <div className="mt-3 mb-2 ms-2">
                              <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data[`itemAddOn${j}`] && !preview.show) ? "Enter Item Description" : ""} name={"itemAddOn"} value={data[`itemAddOn${j}`]} onChange={(e) => handleChange(e, j)} />
                            </div>

                            {/*{inputValue && filteredOptions.length > 0 && (
                              <select
                                className="w-100"
                                value={inputValue}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                  handleCommodityChange(e.target.value, j);
                                }}
                              >
                                <option value="" disabled>Select Commodity</option>
                                {filteredOptions.map((comm, i) => (
                                  <option key={i} value={comm.commodity_pretty_name}>
                                    {comm.commodity_pretty_name}
                                  </option>
                                ))}
                              </select>
                            )}*/}
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemHSN${j}`] && !preview.show) ? "Enter HSN" : ""} name={`itemHSN${j}`} value={data[`itemHSN${j}`]} onChange={handleChange} />


                          </div>
                        </td>
                        <td>
                          <div className="position-relative d-flex " style={{ gap: "5px" }} >

                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemQuantity${j}`] && !preview.show) ? "Quantity" : ""} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]} onChange={handleChange} />
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemQuantityUnits${j}`] && !preview.show) ? "Units" : ""} name={`itemQuantityUnits${j}`} value={data[`itemQuantityUnits${j}`]} onChange={handleChange} />

                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemUnitPrice${j}`} value={data[`itemUnitPrice${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemTax%${j}`] && !preview.show) ? "Enter Tax %" : ""} name={`itemTax%${j}`} value={data[`itemTax%${j}`]} onChange={handleChange} />


                          </div>
                          {/* <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemTax%${j}`] && !preview.show) ? "Enter Tax %" : ""} name={`itemTax%${j}`} value={`${data[`itemTax%${j}`]}` || "1"} onChange={handleChange} />
                          </div> */}
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemTax${j}`} value={data[`itemTax${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemTotalAmount${j}`} value={data[`itemTotalAmount${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        {!preview.show ?
                          <td>
                            <div className="d-flex w-100 justify-content-between" >
                              <div className="" >
                                {(invItems.length - invItems.filter(i => {
                                  if (i === undefined) {
                                    return true
                                  }
                                }).length) - 1 === jj ?
                                  <img
                                    onClick={async () => {
                                      let temp = invItems
                                      temp.push(null)
                                      setInvItems([...temp])
                                    }}
                                    className="cursor" src="assets/images/add_black_icon.png" /> :
                                  <img
                                    onClick={() => {
                                      let temp = invItems
                                      temp[j] = undefined
                                      setInvItems([...temp])
                                    }}
                                    className="cursor" src="assets/images/delete.png" />}
                              </div>
                            </div>
                          </td> : null}
                      </tr>
                    )
                  }
                })}
              </NewTablev2>

              <div className="d-flex justify-content-end"
              >
                <div className="w-33 mt-2" >
                  <NewTablev2 hideHeading={preview.show} addTableClass={"m-0 invBorder"}
                    columns={preview.show ? chargesTable : chargesTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '10%' } }])}>
                    {invOther.map((i, j) => {
                      if (i === null) {
                        kk = kk + 1
                        if (preview.show && !data[`invChargeTitle${j}`]) {
                          return null
                        }
                        return (
                          <tr>
                            <td>
                              <label className="font-size-16 font-wt-500" >
                                <InputForTable isDisabled={j === 0 || preview.show} placeholder={(!data[`invChargeTitle${j}`] && !preview.show) ? "Enter Title" : ""} name={`invChargeTitle${j}`}
                                  value={data[`invChargeTitle${j}`]} onChange={handleChange} />
                              </label>
                            </td>
                            <td>
                              <div className="position-relative " >
                                <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                                  selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                  optionLabel={"code"} optionValue={"code"}
                                  name={`invSubTotalAmount${j}`} value={data[`invSubTotalAmount${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            {!preview.show ?
                              <td>
                                {j === 0 ? null :
                                  <div className="d-flex w-100 justify-content-between" >
                                    <div className="" >
                                      {(invOther.length - invOther.filter(i => {
                                        if (i === undefined) {
                                          return true
                                        }
                                      }).length) - 1 === kk ?
                                        <img
                                          onClick={async () => {
                                            let temp = invOther
                                            temp.push(null)
                                            setInvOther([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = invOther
                                            temp[j] = undefined
                                            setInvOther([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div>
                                  </div>}
                              </td> : null}
                          </tr>
                        )
                      }
                    })}
                  </NewTablev2>
                </div>
              </div>
              <div className="d-flex  justify-content-end">
                <div style={{ border: '1px solid #000', width: "33%", borderTop: "0px" }} >
                  <div className="d-flex row align-items-center m-0 p-0 mt-2"
                  >
                    <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL (before)</label>
                    <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                      <InputWithSelectForTable isDisabled={preview.show} type={"number"}
                        selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                        optionLabel={"code"} optionValue={"code"}
                        name={`invTotalAmountBeforeTax`} value={data[`invTotalAmountBeforeTax`]} onChange={handleChange} />

                    </div>

                  </div>
                  <div className="d-flex row align-items-center m-0 p-0 "
                  >
                    <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL TAX</label>
                    <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                      <InputWithSelectForTable isDisabled={preview.show} type={"number"}
                        selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                        optionLabel={"code"} optionValue={"code"}
                        name={`invTotalTax`} value={data[`invTotalTax`]} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="d-flex row align-items-center m-0 p-0"
                  >
                    <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL  (After tax + Extra Charges)</label>
                    <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                      <InputWithSelectForTable isDisabled={preview.show} type={"number"}
                        selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                        optionLabel={"code"} optionValue={"code"}
                        name={`invTotalAmount`} value={data[`invTotalAmount`]} onChange={handleChange} />
                    </div>
                  </div>


                </div>
              </div>
              {/* <div className="d-flex justify-content-end m-0 mt-2 p-0">
                <div className="w-33 d-flex row align-items-center m-0 p-0 py-2 "
                  style={{ border: '1px solid #000' }} >
                  <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >ADVANCE TO PAY</label>
                  <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                    <InputWithSelectForTable isDisabled={preview.show} type={"number"}
                      selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                      optionLabel={"code"} optionValue={"code"}
                      name={`invAdvToPay`} value={data[`invAdvToPay`]} onChange={handleChange} />
                  </div>
                </div>

              </div>
              <div className="d-flex justify-content-end m-0 mt-2 p-0">
                <div className="w-33 d-flex row align-items-center m-0 p-0 py-2 "
                  style={{ border: '1px solid #000' }} >
                  <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >BALANCE TO PAY</label>
                  <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                    <InputWithSelectForTable isDisabled={true} type={"number"}
                      selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                      optionLabel={"code"} optionValue={"code"}
                      name={`invBalanceToPay`} value={data[`invBalanceToPay`]} onChange={handleChange} />
                  </div>
                </div>

              </div> */}
            </div>
            {!removeReceivedBy && <div className="w-100 p-0 mt-2 position-relative" style={{ border: '1.7px solid #000' }}>
              <div className="p-3"
                style={{ border: '1px solid #000' }}>
                <label className="font-size-15 font-wt-500" ><u>Received By</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.receivedBy && !preview.show) ? "Enter Received By" : ""} name={"receivedBy"} value={data.receivedBy} onChange={handleChange} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !removeReceivedBy ? "#c7d5d6" : "" }} src="assets/images/edit.png" onClick={() => enableEditingDiv("receivedBy")} /> */}
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={() => deleteDiv("receivedBy")} />
                </div>
              </div>
            </div>}
            {!removeDeliveredBy && <div className="w-100 p-0 mt-2 position-relative" style={{ border: '1.7px solid #000' }}>
              <div className="p-3"
                style={{ border: '1px solid #000' }}>
                <label className="font-size-15 font-wt-500" ><u>Delivered By</u></label>
                <div className="position-relative" >
                  <InputForTable isDisabled={preview.show} type={"text"} placeholder={(!data.deliveredBy && !preview.show) ? "Enter Received By" : ""} name={"deliveredBy"} value={data.deliveredBy} onChange={handleChange} />
                </div>
              </div>
              <div className="position-absolute w-20" style={{ top: 2, right: 2, display: preview.show ? "none" : "block" }}>
                <div className="d-flex justify-content-end">
                  {/* <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" style={{ backgroundColor: !removeReceivedBy ? "#c7d5d6" : "" }} src="assets/images/edit.png" onClick={() => enableEditingDiv("receivedBy")} /> */}
                  <img className="cursor p-1 mt-1 me-1 border border-secondary rounded" src="assets/images/delete.png" onClick={() => deleteDiv("deliveredBy")} />
                </div>
              </div>
            </div>}

            <div className="px-3 py-5 mt-2"
              style={{ border: '2px solid #000' }}>
              <div className="d-flex m-0 p-0 align-items-center" >
                <label className="font-size-14 font-wt-500 w-45" >"WE HEREBY CERTIFY THIS INVOICE TO BE TRUE AND CORRECT."</label>
                <div className="w-55  d-flex" >
                  <div className="position-relative w-50" >
                    <label className="font-size-14 font-wt-500" ><u>Name of Authorized Signatory</u></label>
                    <InputForTable isDisabled={preview.show}
                      placeholder={(!data.authorizedSignatoryName && !preview.show) ? "Enter Name" : ""} name={"authorizedSignatoryName"} value={data.authorizedSignatoryName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-50" >
                    <label
                      onClick={() => {
                        setSigndoc(true);
                      }}
                      className="font-size-14 font-wt-500 cursor" ><u>Signature</u>
                      {preview.show ? null :
                        <img src="assets/images/edit.png" className="cursor" />}</label>
                    <div>
                      {data?.invSign?.typedSign ?
                        <label style={{
                          fontFamily: signFonts.find((i, j) => {
                            if (j === data.invSign.typedSignStyle) {
                              return true
                            }
                          })['font']
                        }} className="font-size-16" >{data.invSign.typedSign}</label>
                        : data?.invSign?.signBase64 ?
                          <img src={data?.invSign?.signBase64} />
                          : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mt-3 border-secondary border-top pt-3" style={{
            display: preview.show ? "none" : "block"
          }}>
            <h6>ADD ON'S:</h6>
            <div className=" d-flex justify-content-start ">
              {removeReceivedBy && <button className="me-2 btn border border-secondary rounded" onClick={() => { setRemoveReceivedBy(false) }} style={{ backgroundColor: !removeReceivedBy ? "#5CB8D3" : "" }}> Add Received By</button>}
              {removeDeliveredBy && <button className="me-2 btn  border border-secondary rounded" onClick={() => { setRemoveDeliveredBy(false) }} style={{ backgroundColor: !removeDeliveredBy ? "#5CB8D3" : "" }}>Add Delivered By</button>}

            </div>
          </div>
        </div>
      </div>
    </>
  )

}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ChallanOrder)

