import { useState, useEffect } from "react";
import DropdownSearch from "../tallyReports/Cards/dropdownWithSearch";
import call from "../../service";


export default function AddNewBuyerPopUp({ setAddingNewDocument, handleCloseModal, setHideButtons, typeOf, setSelectedCompany, setAddNewDocument }) {
  const [dbSupplier, setDbSupplier] = useState([])
  const handleSelection = (selectedCompanyName) => {
    const selectedCompany = dbSupplier.find(company => company.company_name === selectedCompanyName);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (setAddNewDocument) setAddNewDocument(true);
      handleCloseModal();
      if (setAddingNewDocument) setAddingNewDocument(true)
      if (setHideButtons) setHideButtons(false)
    }
  };

  const handleAddNewCompany = () => {
    if (typeOf === "Buyer") {
      window.open(`/buyerManagement?action=addNewBuyer`, "_blank");
    } else if (typeOf === "Seller") {
      window.open(`/nwManagementExporter?action=addNewSeller`, "_blank");
    }
    localStorage.setItem('afterUpdateCloseTab', true);
  };
  useEffect(() => {
    call('POST', 'getUsersListing', { "searchUserType": 19, "network": "innetwork", "userAvatars": true, includeBuyersToo: true })
      .then((result) => {
        console.log("result of api in getUsersListing-->", result)
        setDbSupplier(result)
      }).catch((e) => {
        console.log('error in getUsersListing', e);
      })

  }, []);


  return (
    <div>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100000,
      }}>
        <div style={{
          background: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          position: 'relative',
          minWidth: '600px',
          width: '40%',
          height: "300px",
          maxHeight: "80%",
          // overflowY: "scroll"
        }}>
          <div onClick={handleCloseModal} className='cursor-pointer border rounded-circle shadow d-flex justify-content-center align-items-center' style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            width: '40px',
            backgroundColor: '#858c8c',
            height: '40px',
          }}>
            <p style={{ fontSize: '20px', fontFamily: 'sans-serif', color: 'white', backgroundColor: '#858c8c' }} className='p-0 m-0 fw-bold'>X</p>
          </div>
          <h4>{typeOf === "Buyer" ? "Please Select / Add Buyer" : "Please Select / Add Seller"}</h4>
          <div className="d-flex justify-content-between align-items-center m-3 mt-5 ms-0">
            <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>
              {typeOf === "Buyer" ? "Select Buyer" : "Select Seller"}
            </label>
            <DropdownSearch items={dbSupplier.map(company => company.company_name)} onSelect={handleSelection} customStyles={{ widthOfInput: '80%' }} />
          </div>

          <div className="cursor mt-5">
            <label
              onClick={handleAddNewCompany}
              className="font-size-14 text-color1 font-wt-600 mx-1 cursor"
            >
              {typeOf === "Buyer" ? "Add New Buyer" : "Add New Seller"}
            </label>
            <img
              onClick={handleAddNewCompany}
              className="cursor text-color1"
              src={'assets/images/right-arrow.png'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
