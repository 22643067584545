import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone'
import { Line, Circle } from 'rc-progress';
import DocViewer from "react-doc-viewer";
import { getDocDetails, isFilePreviewAvailable } from "./myFunctions";

function downloadTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  if (result.name.split(".").pop() === "png" || result.name.split(".").pop() === "PNG") {
    console.log(result.name);
    let link = document.createElement("a");
    console.log(link);
    link.download = result.name.split(".")[0] + ".png";
    link.href = 'data:application/png;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "jpg" || result.name.split(".").pop() === "JPG") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".jpeg";
    link.href = 'data:application/jpeg;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "pdf" || result.name.split(".").pop() === "PDF") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".PDF";
    link.href = 'data:application/pdf;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function getSourceType(mime) {
  return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
    (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," :
      (mime === "pdf" || mime === "PDF") ? "data:application/pdf;base64," : "")
}

function viewTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  let mime = result.name.split(".").pop()
  let sourceType = getSourceType(mime)
  let filePrevAvailable = isFilePreviewAvailable(result["filebase64"])
  if (filePrevAvailable) {
    return (
      <>
        {
          (mime === "pdf" || mime === "PDF") ?
            // <iframe title="Document Preview" frameborder="0" height="100%" className="col-md-12 m-0 p-0"
            //   src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")}></iframe>
            <object
              style={{ overflowX: 'hidden' }}
              data={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")}
              scrolling="no"
              type="application/pdf" width="100%" height="100%">
              <param name="navpanes" value="0"></param>
            </object>
            :
            <img src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")} alt={result.name} />
        }
      </>
    )
  }
  else {
    return (<div className="bg-white d-flex justify-content-center align-items-center h-100">
      <div>
        <label className="font-size-20 font-wt-600 text-color1 w-100 text-center">{`No Preview Available`}</label>
        <label className="font-size-16 font-wt-600 text-dark w-100 my-2 text-center">Download File</label>
        <div className="d-flex justify-content-center">
          <img onClick={() => {
            downloadTheFile(result)
          }} className="cursor" src="assets/images/new_dowd_icon.svg" />
        </div>
      </div>
    </div>)
  }
}


export const DocumentViewer = ({
  hideVault,
  name,
  value,
  onChange,
  error,
  onUploadCancel,
  onView,
  isEditable,
  extra_class,
  show,
  dismiss,
}) => {
  const [uploadProgress, toggleUplaodProgress] = useState(!isEditable ? 100 : 0);
  const [value2, setValue2] = useState(value);
  const [showLoader, setshowLoader] = useState(false);

  let isFileSelected = value && value.name ? true : false;

  useEffect(() => {
    if (!value?.filebase64 && value?.doc_id) {
      loadValue2();
    }
  }, [value]);

  async function loadValue2() {
    setshowLoader(true);
    let tempVal = await getDocDetails(value.doc_id, null, null, null, true);
    setValue2({ ...tempVal });
    setshowLoader(false);
  }

  useEffect(() => {
    if (isFileSelected && uploadProgress < 100) {
      setTimeout(() => {
        toggleUplaodProgress(uploadProgress + 35);
      }, 1000);
    }
  }, [value, uploadProgress]);

  const onDrop = (acceptedFiles, fileRejections, event) => {
    if (acceptedFiles.length) {
      let event = {
        target: { name: name, files: acceptedFiles },
        persist: () => console.log("onDrop called"),
      };
      onChange(event);
      toggleUplaodProgress(0);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img className="" src="assets/images/loader.gif" alt="description" />
          </span>
        </div>
      )}
      {show && (value2.filebase64 || value?.filebase64) ? (
        <div
          className="modal d-block show"
          id="docPreview"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)" }}
          onClick={dismiss} // This will dismiss the modal when the background is clicked
        >
          <div
            className="modal-dialog modal-fullscreen"
            style={{ pointerEvents: "none" }} // Disable clicks on the entire dialog initially
          >
            <div
              className="modal-content bg-transparent"
              style={{ pointerEvents: "auto" }} // Re-enable clicks inside the modal content
              onClick={(e) => e.stopPropagation()} // Prevent dismissal when clicking inside the content
            >
              <div
                className="modal-header border-0 px-4 pe-5 d-flex justify-content-between"
                style={{ background: "rgba(0,0,0,0.5)" }}
              >
                <div>
                  <a className="cursor close" onClick={dismiss}>
                    <img
                      style={{
                        filter:
                          "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)",
                      }}
                      src={"assets/images/pdf-back.png"}
                      alt="Back"
                      height={25}
                      width={25}
                    />
                  </a>
                </div>
                <div>
                  <h6 className="modal-title text-capitalize" style={{ color: "#08c8ff" }}>
                    {value.name}
                  </h6>
                </div>
                <div className="d-flex gap-3">
                  <a className="cursor">
                    <img
                      style={{
                        filter:
                          "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)",
                      }}
                      src={"assets/images/download-pdf.png"}
                      alt="Download"
                      height={25}
                      width={25}
                    />
                  </a>
                  <a className="cursor">
                    <img
                      style={{
                        filter:
                          "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)",
                      }}
                      src={"assets/images/printer.png"}
                      alt="Print"
                      height={25}
                      width={25}
                    />
                  </a>
                  <a className="cursor">
                    <img
                      style={{
                        filter:
                          "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)",
                      }}
                      src={"assets/images/share.png"}
                      alt="Share"
                      height={25}
                      width={25}
                    />
                  </a>
                </div>
              </div>
              <div className="modal-body p-0">
                <div className="d-flex" style={{ height: "670px" }}>
                  <div className="col-md-2" style={{ background: "rgba(0,0,0,0.5)" }}></div>
                  <div className="col-md-8 mx-auto pt-3">
                    <div className="d-flex m-0 p-0 bg-transparent">
                      <div className={"tab-pane active show col-md-12"} style={{ height: "650px" }}>
                        {viewTheFile(value?.filebase64 ? value : value2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
