import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, GetCache, most_used_currencies, SetCache, toolTip, getDocDetails, getInvoiceFinalChargesObj } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, NewTextArea } from '../../../../utils/newInput';
import { PopupMessage } from '../../../popupMessage';
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import { NewTable } from "../../../../utils/newTable";
import { ToastContainer } from "react-toastify";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import { useHistory } from "react-router";
import { InvoicePOComparisonModal } from "./InvoicePOComparisonModal";
import moment from "moment";
import NewTablev2 from "../../../../utils/newTablev2";

import { DocumentViewer } from "../../../../utils/documentViewer";
import { DocumentDownloader } from "../../../../utils/DocumentDownloader";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

let supplierDetails = [
  { name: 'Supplier Name', val: "company_name" },
  { name: 'Active Application', val: 'activeApplication' },
  { name: 'Contact Number', val: 'contact_number', unit: 'phone_code' },
  { name: 'Approved Application', val: 'totalApproved' },
  { name: 'Email Id', val: 'email_id' },
  { name: 'Rejected Application', val: 'totalRejected' },
  { name: 'Website', val: 'website' },
  { name: 'Pending Application', val: 'totalPending' },
  { name: 'Address', val: 'user_address' },
  { name: '' },
  { name: 'Industry Type', val: 'industry_type' }
]

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" }
]

const serialLabels = ["1st", "2nd", "3rd"]

const ViewFinanceApplication = ({ userTokenDetails, navToggleState }) => {

  const [tab, setTab] = useState(1);
  const [signdoc, setSigndoc] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({ modal: false, type: '' });
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [lcMoreDocuments, setLCMoreDocuments] = useState({ modal: false, page: 0 });
  const [lcRequestMoreDetails, setLCRequestMoreDetails] = useState({ modal: false, page: 0 });
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [addMore, setAddMore] = useState(false);
  const [addMoreDetails, setAddMoreDetails] = useState(false);
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [moreDetailsSent, setMoreDetailsSent] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [docSignPopup, setDocSignPopup] = useState({});
  const [addRemark, setAddRemark] = useState(false);
  const [showComment, setShowComment] = useState(null);
  const [showDocComment, setShowDocComment] = useState(null);
  const [agreementConfiguration, setAgreementConfiguration] = useState({})
  const [agreementDocNames, setAgreementDocNames] = useState({ frameworkDocument: "Framework Agreement", exhibitDocument: "Exhibit Agreement", noaDocument: "Notice of Agreement" })
  const [docNamePicker, toggleDocNamePicker] = useState({ show: false, id: null })
  const [tempValue, setTempValue] = useState("")
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [moreDocsArray2, setMoreDocsArray2] = useState([])
  const [disbursement, setDisbursement] = useState({ modal: false, page: 0 });
  const [disbursementArr, setDisbursementArr] = useState([{ unit: "USD" }, { unit: 'USD' }])
  const [invPoComparisonData, setInvPoComparisonData] = useState(null)
  const [showInvPoAnalysis, setShowInvPoAnalysis] = useState({})
  const [userData, setUserData] = useState({})
  const [finalCharges, setFinalCharges] = useState([{}])
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [bankDocs3, setBankDocs3] = useState([null])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const infoType = [
    { "name": "$ Currency", "unit": "currency" },
    { "name": "% Percentage", "unit": "percentage" },
    { "name": "Text", "unit": "text" },
    { "name": "Date", "unit": "date" }
  ]

  let finApplicationDetail = localStorage.getItem("finApplicationDetail");
  finApplicationDetail = finApplicationDetail ? JSON.parse(finApplicationDetail) : 'NA';

  let disbursementDetail = localStorage.getItem("disbursementDetail");
  disbursementDetail = disbursementDetail ? JSON.parse(disbursementDetail) : 'NA';
  let history = useHistory()

  const documentUploaded = localStorage.getItem("documentUploaded");

  const reviewForm = [
    { "name": "Applicant Name", val: finApplicationDetail.supplierName },
    { "name": "Shipment Origin Country", val: finApplicationDetail.shipment_origin_country },
    { "name": "Buyer Name", val: finApplicationDetail.buyerName },
    { "name": "Shipment Origin City", val: finApplicationDetail.shipment_origin_city },
    { "name": "Buyer Signatory Name", val: finApplicationDetail.nameAuthorizedSignatory },
    { "name": "Shipment Origin Address", val: finApplicationDetail.shipment_origin_address },
    { "name": "Buyer Designation", val: finApplicationDetail.buyerPosition },
    { "name": "Shipment Origin Postal Code", val: finApplicationDetail.shipment_origin_postal_code },
    { "name": "Buyer Contact Number", val: finApplicationDetail.buyerPhone, unit: finApplicationDetail.buyerPhoneCode },
    { "name": "Shipment Destination Country", val: finApplicationDetail.shipment_destination_country },
    { "name": "Bank", val: finApplicationDetail.bankList.split(':')[1] },
    { "name": "Shipment Destination City", val: finApplicationDetail.shipment_destination_city },
    { "name": "Lender", val: finApplicationDetail.lenderName },
    { "name": "Shipment Destination Address", val: finApplicationDetail.shipment_destination_address },
    { "name": "Finance Credit Days", val: finApplicationDetail.credit_days, unit: ' days' },
    { "name": "Shipment Destination Postal Code", val: finApplicationDetail.shipment_destination_postal_code },
    { "name": "Invoice amount:", val: finApplicationDetail.symbol + ' ' + finApplicationDetail.contract_amount },
    { "name": "Invoice No.", val: finApplicationDetail.reference_no },
    { "name": "Invoice issue date", val: finApplicationDetail.issue_date },
    { "name": "Invoice due date", val: finApplicationDetail.due_date },
    { "name": "Purchase order no.", val: finApplicationDetail.po_no },
    { "name": "Bill of lading number", val: finApplicationDetail.blNo },
  ]

  async function asyncUseEffectFunction() {
    // Load agreement configuration start
    call('POST', 'getAgreementDocConfigurationByInvoiceNo', { "invRefNo": finApplicationDetail.reference_no }).then((result) => {
      if (result.configuration) {
        setAgreementConfiguration(result.configuration)
      }
    }).catch((e) => {
    })
    // Load agreement configuration end
    setshowLoader(true);
    let investigationReport, certificateOfOrigin, noaDocument, frameworkDocument, exhibitDocument = "";
    let docId = finApplicationDetail.tbl_doc_id.split(",");
    let invoiceDocument = await getDocDetails(docId[0]);
    let poDocument = await getDocDetails(docId[1]);
    let shipment = await getDocDetails(docId[2]);
    if (docId.length > 3) {
      investigationReport = await getDocDetails(docId[3]);
    }
    if (docId.length > 4) {
      certificateOfOrigin = await getDocDetails(docId[4]);
    }
    if (finApplicationDetail.frameworkDoc) {
      frameworkDocument = await getDocDetails(finApplicationDetail.frameworkDoc);
    }
    if (finApplicationDetail.exhibitDoc) {
      exhibitDocument = await getDocDetails(finApplicationDetail.exhibitDoc);
    }
    if (finApplicationDetail.noaDoc) {
      noaDocument = await getDocDetails(finApplicationDetail.noaDoc);
    }
    let termSheet = await getDocDetails(finApplicationDetail.termSheet);

    call('POST', 'getAdditional', { "reference_no": finApplicationDetail.reference_no }).then((result) => {
      setAdditionalDetails(result.additionalDetails);
      setAdditionalDocuments(result.additionalDocuments);
      setshowLoader(false);
    }).catch((e) => {
      setshowLoader(false);
      toastDisplay("Something went wrong", "error")
    })

    setData({
      ...data, invoiceDocument, poDocument, shipment, investigationReport, certificateOfOrigin,
      termSheet, frameworkDocument, exhibitDocument, noaDocument, "documentUploaded": documentUploaded,
      addRemark: finApplicationDetail?.buyersRemark?.[userId]?.["remark"]
    })

    let docTitles = {}
    if (frameworkDocument?.doc_name) {
      docTitles["frameworkDocument"] = frameworkDocument?.doc_name
    }
    if (exhibitDocument?.doc_name) {
      docTitles["exhibitDocument"] = exhibitDocument?.doc_name
    }
    if (noaDocument?.doc_name) {
      docTitles["noaDocument"] = noaDocument?.doc_name
    }
    setAgreementDocNames({ ...agreementDocNames, ...docTitles })
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [])

  useEffect(() => {
    let tempFinalCharges = []
    tempFinalCharges.push(getInvoiceFinalChargesObj(finApplicationDetail.contract_amount, finApplicationDetail.credit_days, finApplicationDetail.selectedQuote))
    setFinalCharges([...tempFinalCharges])
    console.log("tempFinalChargesssssssssssssssssssssssssssssssssss", tempFinalCharges);
  }, [])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting.modal && isSubmitting.type == 'addMoreDoc') {
      addMoreDocFunc();
    } else if (Object.keys(errors).length === 0 && isSubmitting.modal && isSubmitting.type == 'sendDocReq') {
      addMoreDocFunc();
    } else if (Object.keys(errors).length === 0 && isSubmitting.modal && isSubmitting.type == 'sendDetailsReq') {
      addMoreDetailsFunc();
    } else if (Object.keys(errors).length === 0 && isSubmitting.modal && isSubmitting.type == 'addMoreDetails') {
      addMoreDetailsFunc();
    } else if (Object.keys(errors).length === 0 && isSubmitting.modal && isSubmitting.type == 'forwardDocument') {
      forwardDocument();
    }
    else if (isSubmitting.modal) {
      setIsSubmitting({ modal: false, type: '' })
    }
  }, [errors]);

  async function asyncUseEffectFunction2() {
    if (Object.keys(agreementConfiguration).length) {
      // console.log("aaaaaaaaaaaaaaaaaaaaaa", agreementDocNames);
      let docSignStatus = await call("POST", 'invoiceAgreementSignStatus', { invRefNo: finApplicationDetail.reference_no })
      let frameworkDocument = data.frameworkDocument;
      let exhibitDocument = data.exhibitDocument;
      let noaDocument = data.noaDocument;
      setTableData(formatDataForTable([{ frameworkDocument, key: "frameworkDocument", title: agreementDocNames.frameworkDocument, configurationKey: "framework" },
      { exhibitDocument, key: "exhibitDocument", title: agreementDocNames.exhibitDocument, configurationKey: "exhibit" },
      { noaDocument, key: "noaDocument", title: agreementDocNames.noaDocument, configurationKey: "noa" }], docSignStatus))
      setData({ ...data, docSignStatus })
    }
    call('POST', 'getGeneralDetailsOfExporter', {
      "userId": finApplicationDetail.seller_id,
      "invLenderId": userId
    }).then((result) => {
      setshowLoader(false)
      setUserData(result)
    }).catch((e) => {
      setshowLoader(false)
    });
  }

  useEffect(() => {
    asyncUseEffectFunction2()
  }, [data.documentUploaded, agreementConfiguration, agreementDocNames])



  function formatDataForTable(param, docSignStatus) {
    let tableData = []
    let row = []
    for (let index = 0; index < param.length; index++) {
      const item = param[index];
      if (item[item.key]) {
        row[0] = item[item.key]?.doc_name
        row[1] = <div className="w-75">
          <FileInput name={item.key} value={item[item.key]}
            isEditable={false}
          />
        </div>
        row[2] = <div className="signStatusDiv">
          <div className="w-15">
            {agreementConfiguration?.[item.configurationKey + "ToExporter"] ? (
              <>
                {docSignStatus?.[item.key]?.["signatureId"] ? <img src={"assets/images/green_tick.png"} /> : null}
              </>
            ) :
              <label className="font-wt-600 font-size-12 text-color1">Not Sent</label>}
          </div>
          <div className="w-15">
            {agreementConfiguration?.[item.configurationKey + "ToBuyer"] ? (
              <>
                {docSignStatus?.[item.key]?.["buyerSignatureId"] ? <img src={"assets/images/green_tick.png"} /> : null}
              </>
            ) :
              <label className="font-wt-600 font-size-12 text-color1">Not Sent</label>}
          </div>
          <div className="w-15">
            {docSignStatus?.[item.key]?.["financierSignatureId"] ? <img src={"assets/images/green_tick.png"} />
              : <img
                onClick={() => handleSignPopup(item.key, item[item.key])}
                src={"assets/images/supplier-images/fluent_signature-20-regular.png"} className="cursor" />}
          </div>
        </div>
        tableData.push(row)
        row = []
      }
    }
    console.log("tttttttttttttttttttt", tableData);
    return tableData
  }

  function handleSignPopup(documentKey, documentFile) {
    setSigndoc(true)
    setDocSignPopup({ docToSign: documentFile, docSignName: documentKey })
  }

  async function uploadSignedDocumentAndReload(updatedDoc) {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("fileToSign", docSignPopup.docSignName)
    formData.append(docSignPopup.docSignName, updatedDoc)
    formData.append("signatureId", updatedDoc.signatureId)
    formData.append("docId", data.docSignStatus[docSignPopup.docSignName]["id"])
    formData.append("invRefNo", finApplicationDetail.reference_no)
    formData.append("isFinancier", true);
    await call("POST", 'signInvoiceDiscountingAgreement', formData)
    setshowLoader(false)
    window.location.reload()
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }
  }

  async function preHandleSubmit(type) {
    let err = {};
    let validateFields = [];
    if (type == 'addMoreDoc') {
      validateFields = ["documentName", "reason"];
    }
    else if (type == 'sendDocReq') {
      validateFields = ["documentName", "reason"];
    }
    else if (type == 'addMoreDetails') {
      validateFields = ["infoTypeDetail", "title", "detailReason"];
    }
    else if (type == 'forwardDocument') {
      if (data?.frameworkDocument?.name || data?.exhibitDocument?.name || data?.noaDocument?.name) {
        if (data?.frameworkDocument?.name && !(data.frameworkToExporter || data.frameworkToBuyer)) {
          err["frameworkDocument"] = "You have to select atleast 1 option for send to"
        }
        if (data?.exhibitDocument?.name && !(data.exhibitToExporter || data.exhibitToBuyer)) {
          err["exhibitDocument"] = "You have to select atleast 1 option for send to"
        }
        if (data?.noaDocument?.name && !(data.noaToExporter || data.noaToBuyer)) {
          err["noaDocument"] = "You have to select atleast 1 option for send to"
        }
      }
      else {
        err["toHold"] = true
        toastDisplay('Upload atleast 1 document to proceed', "info")
      }
      if (!(data.signTypeDigital || data.signTypeWet) || (data?.frameworkDocument?.name && !(data.frameworkToExporter || data.frameworkToBuyer))) {
        err["signType"] = "Mandatory"
        toastDisplay('Kindly select sign type and send to for agreement documents', "info")
      }
    }
    else if (type == 'sendDetailsReq') {
      validateFields = ["infoTypeDetail", "title", "detailReason"];
    }
    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })
    setErrors(err)
    setIsSubmitting({ modal: true, type: type });
  }

  const onDelete = async (datakey) => {
    const objWithIdIndex = data.otherDoc.findIndex((obj, key) => key === datakey);
    data.otherDoc.splice(objWithIdIndex, 1);
    if (data.otherDoc.length == 0) {
      delete data.otherDoc;
    }
    setData({ ...data })
  }

  const onEdit = async (item, datakey) => {
    setData({
      ...data,
      documentName: item.name,
      reason: item.reason
    })
    const objWithIdIndex = data.otherDoc.findIndex((obj, key) => key === datakey);
    data.otherDoc.splice(objWithIdIndex, 1);
    if (data.otherDoc.length == 0) {
      delete data.otherDoc;
    }
  }

  const onDeleteDetails = async (datakey) => {
    const objWithIdIndex = data.otherDetails.findIndex((obj, key) => key === datakey);
    data.otherDetails.splice(objWithIdIndex, 1);
    if (data.otherDetails.length == 0) {
      delete data.otherDetails;
    }
    setData({ ...data })
  }

  const onEditDetails = async (item, datakey) => {
    setData({
      ...data,
      infoTypeDetail: item.type,
      title: item.title,
      detailReason: item.detailReason
    })
    const objWithIdIndex = data.otherDetails.findIndex((obj, key) => key === datakey);
    data.otherDetails.splice(objWithIdIndex, 1);
    if (data.otherDetails.length == 0) {
      delete data.otherDetails;
    }
  }

  function addMoreDocFunc() {
    let otherDoc = data.otherDoc || []
    let temp = {
      "name": data.documentName,
      "reason": data.reason
    }
    otherDoc.push(temp)
    setData({
      ...data, documentName: "", reason: "", otherDoc
    })
    setAddMore(true);
    if (isSubmitting.type == 'sendDocReq' && !data.otherDoc) {
      sendRequest(otherDoc);
    }
    else if (isSubmitting.type == 'sendDocReq') {
      sendRequest(null);
    }
  }

  function addMoreDetailsFunc() {
    let otherDetails = data.otherDetails || []
    let temp = {
      "type": data.infoTypeDetail,
      "title": data.title,
      "detailReason": data.detailReason
    }
    otherDetails.push(temp)
    setData({
      ...data, infoTypeDetail: "", title: "", detailReason: "", otherDetails
    })
    setAddMoreDetails(true);
    if (isSubmitting.type == 'sendDetailsReq' && !data.otherDetails) {
      sendRequest(otherDetails);
    }
    else if (isSubmitting.type == 'sendDetailsReq') {
      sendRequest(null);
    }
  }

  function sendRequest(additionalDetail) {
    setshowLoader(true);
    let details = additionalDetail ? JSON.stringify(additionalDetail) : JSON.stringify(data.otherDetails);
    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('reference_no', finApplicationDetail.reference_no);
    formData.append('details', details);
    if (isSubmitting.modal && isSubmitting.type == 'sendDetailsReq') {
      call('POST', 'sendAdditionalDetails', formData).then((result) => {
        setshowLoader(false);
        setLCRequestMoreDetails({ modal: false, page: 0 })
        setMoreDetailsSent(true);
        toastDisplay(result, "success")
      }).catch((e) => {
        setshowLoader(false);
        toastDisplay("Something went wrong", "error")
      })
    }
    else if (isSubmitting.modal && isSubmitting.type == 'sendDocReq') {
      call('POST', 'sendAdditionalDoc', formData).then((result) => {
        setshowLoader(false);
        setLCMoreDocuments({ modal: true, page: 2 })
        toastDisplay(result, "success")
      }).catch((e) => {
        setshowLoader(false);
        toastDisplay("Something went wrong", "error")
      })
    }
  }

  function forwardDocument() {
    setshowLoader(true);
    let docId = finApplicationDetail.tbl_doc_id.split(",");
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append('userId', userId);
    formData.append('frameworkAgreement', data.frameworkDocument);
    formData.append('exhibitAgreement', data.exhibitDocument);
    formData.append('noaAgreement', data.noaDocument);
    formData.append('limit_id', finApplicationDetail.limit_id);
    formData.append('invRefNo', finApplicationDetail.reference_no);
    formData.append("frameworkAgreementTitle", agreementDocNames.frameworkDocument)
    formData.append("exhibitAgreementTitle", agreementDocNames.exhibitDocument)
    formData.append("noaAgreementTitle", agreementDocNames.noaDocument)
    if (data?.frameworkDocument?.signatureId) {
      formData.append('frameworkSignId', data.frameworkDocument.signatureId);
    }
    if (data?.exhibitDocument?.signatureId) {
      formData.append('exhibitSignId', data.exhibitDocument.signatureId);
    }
    if (data?.noaDocument?.signatureId) {
      formData.append('noaSignId', data.noaDocument.signatureId);
    }
    formData.append("agreementConfiguration", JSON.stringify({
      exhibitToExporter: data.exhibitToExporter,
      exhibitToBuyer: data.exhibitToBuyer, frameworkToBuyer: data.frameworkToBuyer, frameworkToExporter: data.frameworkToExporter,
      noaToExporter: data.noaToExporter, noaToBuyer: data.noaToBuyer
    }))
    if (data.invoiceDocument && data.invoiceDocument.signatureId) {
      formData.append('invoiceDocSignId', data.invoiceDocument.signatureId);
      formData.append('invoiceDocID', docId[0]);
    }
    if (data.poDocument && data.poDocument.signatureId) {
      formData.append('poDocSignId', data.poDocument.signatureId);
      formData.append('poDocID', docId[1]);
    }
    if (data.shipment && data.shipment.signatureId) {
      formData.append('shipmentDocSignId', data.shipment.signatureId);
      formData.append('shipmentDocId', docId[2]);
    }
    if (data.investigationReport && data.investigationReport.signatureId) {
      formData.append('investigationDocSignId', data.investigationReport.signatureId);
      formData.append('investigationDocId', docId[3]);
    }
    if (data.certificateOfOrigin && data.certificateOfOrigin.signatureId) {
      formData.append('certificateDocSignId', data.certificateOfOrigin.signatureId);
      formData.append('certificateDocId', docId[4]);
    }
    formData.append("agreementSignType", data.signTypeDigital ? "Digital" : "Wet")
    call('POST', 'forwardDocument', formData).then((result) => {
      setshowLoader(false);
      toggleMsgPopup(true);
      toastDisplay(result, "success")
    }).catch((e) => {
      setshowLoader(false);
      toastDisplay("Something went wrong", "error")
    })
  }

  useEffect(() => {
    preCalculateDisbursementSchedule()
  }, [finalCharges])

  async function preCalculateDisbursementSchedule() {
    let totalAmountToDisburse = finApplicationDetail.contract_amount / 1 - (finalCharges?.[0]?.total?.split(" ")?.[0] || 0)
    let firstDate = moment(finApplicationDetail.created_at).add('5', "days").format("YYYY-MM-DD")
    let secondDate = finApplicationDetail.due_date
    let firstDisbursedUpto = finApplicationDetail.selectedQuote.discountingPercentage || 90
    let amtUnit = finalCharges?.[0]?.total?.split(" ")?.[1]
    let firstAmt = (totalAmountToDisburse * (firstDisbursedUpto / 100)).toFixed(2)
    let secondAmt = (totalAmountToDisburse - firstAmt).toFixed(2)
    setDisbursementArr([{ amount: firstAmt, unit: amtUnit, date: firstDate }, { amount: secondAmt, unit: amtUnit, date: secondDate }])
  }

  const handleDisbursementArr = (e, index) => {
    if (e.persist) {
      e.persist()
    }
    let temp = disbursementArr
    temp[index][e.target.name] = e.target.value
    setDisbursementArr([...temp])
  }

  async function validateDisbursementArr() {
    let err = {}
    disbursementArr.forEach((element, index) => {
      if (!element.unit) {
        err["amount" + index] = "Mandatory field"
      }
      if (!element.amount) {
        err["amount" + index] = "Mandatory field"
      }
      if (!element.date) {
        err["date" + index] = "Mandatory field"
      }
    });
    if (!Object.keys(err).length) {
      setshowLoader(true)
      if (data.disbursementRemark) {
        await call('POST', 'addRemarkOnQuote', {
          applicationId: finApplicationDetail.limit_id,
          remark: data.disbursementRemark, userId, userName, buyerId: finApplicationDetail.buyer_id,
          buyerName: finApplicationDetail.buyerName, exporterUserId: finApplicationDetail.seller_id,
          purpose: " invoice application (" + finApplicationDetail.reference_no + ") ", stage: "DisbursementSchedule" + finApplicationDetail.limit_id
        })
      }
      let objectAPI = {
        'quoteId': finApplicationDetail.reference_no,
        'disbursementArr': disbursementArr,
        'userId': userId,
        'isInvoice': true,
        "lenderEmail": userEmail,
        "remark": data.disbursementRemark
      }
      call('POST', 'scheduleDisbursementInvoice', objectAPI).then((result) => {
        setshowLoader(false)
        toastDisplay(result, "success", () => {
          history.go(-1)
        })
      }).catch((e) => {
        setshowLoader(false)
        toastDisplay("Something went wrong", "error")
      })
    }
    setErrors(err)
  }

  const [boolmodifywidth, setboolwidth] = useState(true)


  const base64ToBlob = (base64, mime) => {
    try {
      // Remove the data URI prefix if present
      const base64WithoutPrefix = base64.includes(',') ? base64.split(',')[1] : base64;

      const byteCharacters = atob(base64WithoutPrefix);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    } catch (error) {
      console.error("Error decoding base64:", error);
      return null;
    }
  };

  const openPdfInNewTab = (file) => {
    if (file?.filebase64) {
      const pdfBlob = base64ToBlob(file.filebase64, "application/pdf");
      if (pdfBlob) {
        const blobUrl = URL.createObjectURL(pdfBlob);

        // Open the Blob URL in a new tab
        window.open(blobUrl, '_blank');
      } else {
        console.error("Failed to create Blob from base64");
      }
    } else if (file?.url) {
      // Open the file URL directly
      window.open(file.url, '_blank');
    }
  };
  useEffect(() => {
    if (viewDoc.show && viewDoc.doc) {
      openPdfInNewTab(viewDoc.doc);
    }
  }, [viewDoc.show, viewDoc.doc]);


  return (
    <>
      <InvoicePOComparisonModal invNo={finApplicationDetail.reference_no} buyerId={finApplicationDetail.buyer_id} showInvPoAnalysis={showInvPoAnalysis} setShowInvPoAnalysis={setShowInvPoAnalysis}
        invPoComparisonData={invPoComparisonData} setInvPoComparisonData={setInvPoComparisonData} />
      {(disbursementArr && disbursement.modal && disbursement.page === 1) ?
        <div className={`modal fade ${disbursement.modal && "show"}`} style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content submitmodal pb-4">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbursement({ modal: false, page: 0 })}></button>
              </div>
              <div className="modal-body text-center">
                <p className="mb-2 font-size-14 font-wt-600">{`Schedule Disbursement for ${userData["company_name"]}`}</p>
                <p className="mb-4 font-size-14 font-wt-600">{`Total Invoice Amount - `}
                  <label className="color3DB16F font-wt-600 font-size-16" >{most_used_currencies.filter(i => {
                    if (i.id === finApplicationDetail.currency) {
                      return i
                    }
                  })?.[0]?.code} {finApplicationDetail.contract_amount} </label></p>

                <div className=" d-flex row justify-content-center align-items-center" >
                  <div className="w-85" >
                    <NewTablev2 columns={[{ subColumns: 'No. of disbursement', subColumnStyle: { width: '16%' } },
                    { subColumns: 'Disbursement Date', subColumnStyle: { width: '16%' } },
                    { subColumns: 'Disbursement Amount', subColumnStyle: { width: '16%' } },
                    { subColumns: 'Remark', subColumnStyle: { width: '22%' } },
                    { subColumns: 'Disbursement Percentage', subColumnStyle: { width: '16%' } },
                    { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                      {disbursementArr.map((item, index) => {
                        return (
                          <tr>
                            <td><label className="font-size-12 font-wt-500" >{serialLabels[index] || (index + 1) + "th"}</label></td>
                            <td><InputForTable key={index}
                              type={"date"} name={`date`} defaultValue={item["date"]} value={item["date"]}
                              onChange={(e) => handleDisbursementArr(e, index)} error={errors["date" + index]}
                            /></td>
                            <td><InputWithSelectForTable key={index}
                              selectData={most_used_currencies} selectName={"unit"}
                              defaultSelectValue={item["unit"]} name={"amount"} defaultValue={item["amount"]} value={item["amount"]}
                              optionLabel={'code'} optionValue={'code'} error={errors["amount" + index]}
                              label={"Amount"} type={"number"} onChange={(e) => handleDisbursementArr(e, index)} /></td>
                            <td><InputForTable key={index}
                              type={"text"} name={`remark`} defaultValue={item["remark"]} value={item["remark"]}
                              onChange={(e) => handleDisbursementArr(e, index)} error={errors["remark" + index]}
                            /></td>
                            <td><label className="color3DB16F font-wt-600 font-size-16">
                              {`${((item["amount"] / finApplicationDetail.contract_amount) * 100).toFixed(0)}%`}</label></td>
                            <td>{index > 0 ? (
                              <div className="">
                                <img onClick={() => {
                                  let temp = disbursementArr
                                  temp = temp.filter((i, j) => {
                                    if (j != index) {
                                      return i
                                    }
                                  })
                                  setDisbursementArr(temp)
                                }} className="cursor" src="assets/images/delete.png" />
                              </div>
                            ) : null}</td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                </div>
                <a
                  onClick={() => {
                    let temp = disbursementArr
                    temp = temp.concat([{ unit: "USD" }])
                    setDisbursementArr(temp)
                  }}
                >
                  <p className="text-color1 font-wt-600 font-size-14 cursor text-left ml-5 pl-3"
                  >Add more dates</p>
                </a>
                {/* <div className="d-flex justify-content-center">
                  <textarea rows="4" cols="50" className={"form-control w-50"} placeholder="Add Remark." name="disbursementRemark" value={data.disbursementRemark}
                    onChange={handleChange}
                  />
                </div> */}
                <button type="button" className={`mx-2 new-btn w-25 py-2 px-2 mt-3 text-white`}
                  onClick={() => validateDisbursementArr()}>Save Schedule</button>
              </div>
            </div>
          </div>
        </div> :
        (disbursement.modal && disbursement.page === 2) &&
        <div className={`modal fade ${disbursement.modal && "show"}`} style={{ display: "block" }}>
          <div className="modal-dialog modal-sm">
            <div className="modal-content submitmodal pb-4">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbursement({ modal: false, page: 0 })}></button>
              </div>
              <div className="modal-body text-center">
                <p>Disbursement Schedule</p>
                <NewTable disableAction={false}
                  columns={[{ name: "Date", filter: false }, { name: "Amount", filter: false }]}
                  data={tableData}
                />
                <button type="button" className={`mx-2 new-btn py-2 px-2 mt-3 text-white`}
                  onClick={() => { setDisbursement({ modal: false, page: 0 }); window.location = "/lcFinApplication" }}>Confirm</button>
                <p className="mt-3 text-blue" onClick={() => setDisbursement({ modal: true, page: 1 })}>Change Schedule</p>
              </div>
            </div>
          </div>
        </div>
      }
      <AddMoreDocumentHandler hide={true}
        purpose={"ApplyForInvoiceFinance"} purposeId={finApplicationDetail.limit_id}
        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
      />
      <AddMoreDocumentHandler hide={true}
        purpose={"SendTermSheet"} purposeId={finApplicationDetail.limit_id}
        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray2(docs)}
      />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {/* <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} /> */}
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_financeApplication" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Export Factoring > Finance Application Details"}
              userTokenDetails={userTokenDetails} />
            {showMsgPopup ? (
              <PopupMessage
                onClose={() => { toggleMsgPopup(false); window.location = "/financeApplication" }}
                title={''}
                msg={`Documents sent successfully !`}
              />
            ) : null}
            <a className="cursor" href="/financeApplication"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5 '>
                    {Object.keys(invPoComparisonData || {})?.length ? (<img
                      src={'assets/images/details_icon.png'}
                      style={{
                        width: 30,
                        top: "2rem",
                        right: "2rem"
                      }}
                      className="mx-3 cursor position-absolute"
                      onClick={() => {
                        setShowInvPoAnalysis({ show: true, data: invPoComparisonData })
                      }}
                    />) : null}
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Supplier Details</u></label>
                        <div className='row'>
                          {supplierDetails.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2">
                                  <span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span>
                                  {item.name ? <>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc" > {!item.unit ? (userData[item.val] || "-") : `${userData[item.unit]} ${userData[item.val] || "-"}`}</span>
                                  </> : null}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-3'>
                        <label className='font-wt-500 font-size-16'><u>Application Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {(item.unit || "") + " " + (item.val || "-")}
                                </label>
                              </div>
                            )
                          })}
                          {/* <p className="mt-3 text-blue"
                            onClick={() => setLCRequestMoreDetails({ modal: true, page: 1 })}
                          >Request more details  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p> */}
                        </div>
                        {additionalDetails && additionalDetails.length > 0 ?
                          <div className='row'>
                            <label className='font-wt-500 font-size-16'><u>Additional Details Requested</u></label>
                            {additionalDetails && additionalDetails.map((item, key) => {
                              return (<>
                                <div className='col-md-3 mt-3'>
                                  <NewInput type={item.type} isDisabled={true} label={item.title}
                                    value={item.type}
                                  />
                                  <div
                                    onClick={() => setShowComment(key)}
                                    className="cursor ammendCommentIcon2">
                                    <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                                  </div>
                                  {showComment === key ? (
                                    <div className="ammendPopup2">
                                      <button
                                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => setShowComment(null)}></button>
                                      <div className="p-3">
                                        <div className="mr-5 pr-2">
                                          <NewInput isDisabled={true}
                                            value={item.detailReason}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>)
                            })}
                          </div> : null}
                      </div>
                      <div className='w-100 mt-4'>
                        <label className='font-wt-500 font-size-16'><u>Total Charges</u></label>
                        {finalCharges?.length ? finalCharges.map((o, p) => {
                          return (
                            <div>
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Factoring Rate"}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {o["factoring"] || "NA"}
                                </label>
                              </div>
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Interest Rate"}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {o["interest"] || "NA"}
                                </label>
                              </div>
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Setup Fees (One Time Only)"}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {o["oneTime"] || "NA"}
                                </label>
                              </div>
                              {o?.otherCharges?.length ? o.otherCharges.map((item, index) => {
                                return (
                                  <div className='col-5 p-0'>
                                    <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{finApplicationDetail.selectedQuote?.otherCharges?.[index]?.["name"]}</label>
                                    <label className='lh-18 font-size-14'>:</label>
                                    <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                      {item["OtherCharge" + (index + 1)] || "NA"}
                                    </label>
                                  </div>
                                )
                              }) : null}
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Total Payable"}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {o["total"] || "NA"}
                                </label>
                              </div>
                            </div>
                          )
                        }) : null}
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        {data.invoiceDocument?.name ?
                          <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                        <div className='row mt-2'>
                          {data.poDocument?.name ? <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {data.shipment?.name ? <div className="col-md-4">
                            <label className="font-size-14">Bill of Lading/Airway Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"shipment"} value={data.shipment} error={errors.shipment}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {data.investigationReport?.name ? <div className="col-md-4">
                            <label className="font-size-14">Investigation Report</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"investigationReport"} value={data.investigationReport} error={errors.investigationReport}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {data.certificateOfOrigin?.name ? <div className="col-md-4">
                            <label className="font-size-14">Certificate of Origin</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"certificateOfOrigin"} value={data.certificateOfOrigin} error={errors.certificateOfOrigin}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {data.packingListDocument?.name ? <div className="col-md-4">
                            <label className="font-size-14">Packing List</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {data.shippingBillDocument?.name ? <div className="col-md-4">
                            <label className="font-size-14">Shipping Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {moreDocsArray?.length ? moreDocsArray.map((item, index) => {
                            if (item?.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                          {data.termSheet?.name ? <div className="col-md-4">
                            <label className="font-size-14">Term Sheet</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"termSheet"} value={data.termSheet} error={errors.termSheet}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div> : null}
                          {moreDocsArray2?.length ? moreDocsArray2.map((item, index) => {
                            if (item?.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                          {/* <p className="mt-3 text-blue"
                            onClick={() => setLCMoreDocuments({ modal: true, page: 1 })}
                          >Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p> */}
                        </div>
                        {additionalDocuments && additionalDocuments.length > 0 ? <div className='row'>
                          <label className='font-wt-500 font-size-16'><u>Additional Documents Requested</u></label>
                          {additionalDocuments && additionalDocuments.map((item, key) => {
                            return (<>
                              <div className='col-md-3 mt-3'>
                                <NewInput isDisabled={true} label={item.name}
                                />
                                <div
                                  onClick={() => setShowDocComment(key)}
                                  className="cursor ammendCommentIcon2">
                                  <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                                </div>
                                {showDocComment === key ? (
                                  <div className="ammendPopup2">
                                    <button
                                      type="button" className="btn-close ammendCommentCrossIcon" onClick={() => setShowDocComment(null)}></button>
                                    <div className="p-3">
                                      <div className="mr-5 pr-2">
                                        <NewInput isDisabled={true}
                                          value={item.reason}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </>)
                          })}
                        </div> : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab == 1 && data.documentUploaded == 'yes' &&
                  <div className='px-5 py-4 pt-5'>
                    <NewTable
                      disableAction={true}
                      columns={[{ name: "Document Name" }, { name: "Documents Attached" },
                      { name: "Signed By", subColumns: ["Supplier", "Buyer", "Financier"] }]}
                      data={tableData}
                    />
                    <div className="mt-4">
                      <button type="button"
                        onClick={() => setDisbursement({ modal: true, page: 1 })}
                        className={`mx-4 new-btn py-2 px-3 text-white`}>
                        {"Schedule Disbursement"}
                      </button>
                    </div>
                  </div>}

                {(tab === 1 && data.documentUploaded == 'no') ? (
                  <div className='px-5 py-4 pt-5'>

                    <div className='mb-3 d-flex pl-2 pt-2'>
                      <label className="font-wt-500 font-size-16 pt-1 mr-3" >Stamp & Sign Type</label>
                      <input type="radio" id="signTypeDigital" name="signTypeDigital"
                        checked={data.signTypeDigital ? true : false}
                        onClick={() => {
                          setData({ ...data, ["signTypeDigital"]: !data.signTypeDigital, signTypeWet: false })
                        }}
                      />
                      <label className="font-wt-500 font-size-14 mr-4" for="signTypeDigital">Digital</label>
                      <input className="mr-3" type="radio" id="signTypeWet" name="signTypeWet"
                        checked={data.signTypeWet ? true : false}
                        onClick={() => {
                          setData({ ...data, ["signTypeWet"]: !data.signTypeWet, signTypeDigital: false })
                        }} />
                      <label className="font-wt-500 font-size-14 mr-4" for="signTypeWet">Wet</label>
                    </div>

                    <div>
                      <NewTablev2 columns={[{ subColumns: 'Document Name', subColumnStyle: { width: '20%' } }, { subColumns: 'Attach Document', subColumnStyle: { width: '45%' } },
                      { subColumns: 'Send to', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                        <tr>
                          <td className='font-size-12 font-wt-600' >
                            <InputForTable name={"frameworkDocument"} value={agreementDocNames.frameworkDocument}
                              onChange={(e) =>
                                setAgreementDocNames({ ...agreementDocNames, ["frameworkDocument"]: e.target.value })} />
                          </td>
                          <td>
                            <FileInput hideViewDowdBtns name={"frameworkDocument"} value={data.frameworkDocument} error={errors.frameworkDocument}
                              onChange={handleFile} isEditable={true} boolmodifywidth={boolmodifywidth}
                              onUploadCancel={() => setData({ ...data, "frameworkDocument": null })}
                            />
                            {errors.frameworkDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.frameworkDocument}</b></div> : ''}
                          </td>
                          <td>
                            <div>
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    setData({ ...data, "frameworkToBuyer": !data.frameworkToBuyer })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data.frameworkToBuyer ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Buyer"}</label>
                              </div>
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    setData({ ...data, "frameworkToExporter": !data.frameworkToExporter })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data.frameworkToExporter ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Supplier"}</label>
                              </div>
                            </div>
                          </td>
                          <td>
                            {!data[`frameworkDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`frameworkDocument`]?.name); setData({ ...data, docToSign: data[`frameworkDocument`], docSignName: `frameworkDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`frameworkDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`frameworkDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`frameworkDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >
                            <InputForTable hideViewDowdBtns name={"exhibitDocument"} value={agreementDocNames.exhibitDocument}
                              onChange={(e) =>
                                setAgreementDocNames({ ...agreementDocNames, ["exhibitDocument"]: e.target.value })} />
                          </td>
                          <td>
                            <FileInput name={"exhibitDocument"} value={data.exhibitDocument} error={errors.exhibitDocument}
                              onChange={handleFile} isEditable={true} boolmodifywidth={boolmodifywidth}
                              onUploadCancel={() => setData({ ...data, "exhibitDocument": null })}
                            />
                            {errors.exhibitDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.exhibitDocument}</b></div> : ''}
                          </td>
                          <td>
                            <div>
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    setData({ ...data, "exhibitToBuyer": !data.exhibitToBuyer })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data.exhibitToBuyer ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Buyer"}</label>
                              </div>
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    setData({ ...data, "exhibitToExporter": !data.exhibitToExporter })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data.exhibitToExporter ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Supplier"}</label>
                              </div>
                            </div>
                          </td>
                          <td>
                            {!data[`exhibitDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`exhibitDocument`]?.name); setData({ ...data, docToSign: data[`exhibitDocument`], docSignName: `exhibitDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`exhibitDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`exhibitDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`exhibitDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >
                            <InputForTable hideViewDowdBtns name={"noaDocument"} value={agreementDocNames.noaDocument}
                              onChange={(e) =>
                                setAgreementDocNames({ ...agreementDocNames, ["noaDocument"]: e.target.value })} />
                          </td>
                          <td>
                            <FileInput name={"noaDocument"} value={data.noaDocument} error={errors.noaDocument}
                              onChange={handleFile} isEditable={true} boolmodifywidth={boolmodifywidth}
                              onUploadCancel={() => setData({ ...data, "noaDocument": null })}
                            />
                            {errors.noaDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.noaDocument}</b></div> : ''}
                          </td>
                          <td>
                            <div>
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    setData({ ...data, "noaToBuyer": !data.noaToBuyer })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data.noaToBuyer ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Buyer"}</label>
                              </div>
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    setData({ ...data, "noaToExporter": !data.noaToExporter })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data.noaToExporter ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Supplier"}</label>
                              </div>
                            </div>
                          </td>
                          <td>
                            {!data[`noaDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`noaDocument`]?.name); setData({ ...data, docToSign: data[`noaDocument`], docSignName: `noaDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`noaDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`noaDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`noaDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        {bankDocs3.map((i, j) => {
                          return (
                            <tr>
                              <td className='font-size-12 font-wt-600' >
                                <InputForTable name={`addMoreDoc${j}`} value={agreementDocNames[`addMoreDoc${j}`]}
                                  onChange={(e) =>
                                    setAgreementDocNames({ ...agreementDocNames, [`addMoreDoc${j}`]: e.target.value })} />
                              </td>
                              <td>
                                <FileInput hideViewDowdBtns name={`addMoreDoc${j}`} value={data[`addMoreDoc${j}`]} error={errors[`addMoreDoc${j}`]}
                                  onChange={handleFile} isEditable={true} boolmodifywidth={boolmodifywidth}
                                  onUploadCancel={() => setData({ ...data, [`addMoreDoc${j}`]: null })}
                                />
                                {errors[`addMoreDoc${j}`] ? <div class="text-danger mt-2">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors[`addMoreDoc${j}`]}</b></div> : ''}
                              </td>
                              <td>
                                <div>
                                  <div className="align-self-center">
                                    <img
                                      onClick={() => {
                                        setData({ ...data, [`addMoreDocToBuyer${j}`]: !data[`addMoreDocToBuyer${j}`] })
                                      }}
                                      className='cursor mr-3' src={`assets/images/${data[`addMoreDocToBuyer${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                                    <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Buyer"}</label>
                                  </div>
                                  <div className="align-self-center">
                                    <img
                                      onClick={() => {
                                        setData({ ...data, [`addMoreDocToExporter${j}`]: !data[`addMoreDocToExporter${j}`] })
                                      }}
                                      className='cursor mr-3' src={`assets/images/${data[`addMoreDocToExporter${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                                    <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{"Supplier"}</label>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {!data[`addMoreDoc${j}`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`addMoreDoc${j}`]?.name); setData({ ...data, docToSign: data[`addMoreDoc${j}`], docSignName: `addMoreDoc${j}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {<>
                                  {(bankDocs3.length - 1 > j) ?
                                    <img
                                      onClick={() => {
                                        let temp = bankDocs3
                                        temp = temp.filter((m, n) => {
                                          if (n != j) {
                                            return true
                                          }
                                        })
                                        setBankDocs3([...temp])
                                      }}
                                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                    /> : <img
                                      onClick={() => {
                                        let temp = bankDocs3
                                        temp.push(null)
                                        setBankDocs3([...temp])
                                      }}
                                      style={{ width: '22px', height: '22px' }}
                                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                </>}
                                {data[`addMoreDoc${j}`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`addMoreDoc${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`addMoreDoc${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}
                              </div></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    {/* <div className="col-md-8 mb-3">
                      {docNamePicker.id === "frameworkDocument" ? (
                        <div className="w-25 d-inline-block">
                          <NewInput name={docNamePicker.id} value={tempValue}
                            onChange={(e) => setTempValue(e.target.value)} />
                        </div>
                      )
                        : (
                          <label className="font-size-14 cursor ml-2"
                          >{agreementDocNames.frameworkDocument}</label>
                        )}
                      <span>
                        {docNamePicker.id === "frameworkDocument" ? (
                          <>
                            <img
                              onClick={() => {
                                if (tempValue) {
                                  setAgreementDocNames({ ...agreementDocNames, [docNamePicker.id]: tempValue })
                                  toggleDocNamePicker({ show: false, id: null })
                                }
                                else {
                                  toastDisplay('Please enter document name', "info")
                                }
                              }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                            <img
                              onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                          </>
                        ) : (
                          <img
                            onClick={() => { setTempValue(agreementDocNames.frameworkDocument); toggleDocNamePicker({ show: true, id: "frameworkDocument" }) }}
                            style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}
                      </span>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"frameworkDocument"} value={data.frameworkDocument} error={errors.frameworkDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "frameworkDocument": null })}
                          />
                          <div className='mb-3 d-flex pl-2 pt-2'>
                            <label className="font-wt-600 font-size-14 pt-1 mr-4" >Send to</label>
                            <input className="mr-3" type="radio" id="frameworkToExporter" name="frameworkToExporter"
                              checked={data.frameworkToExporter ? true : false}
                              onClick={() => {
                                setData({ ...data, ["frameworkToExporter"]: !data.frameworkToExporter })
                              }} />
                            <label className="font-wt-500 font-size-14 mr-4" for="frameworkToExporter">Exporter</label>
                            <input type="radio" id="frameworkToBuyer" name="frameworkToBuyer"
                              checked={data.frameworkToBuyer ? true : false}
                              onClick={() => {
                                setData({ ...data, ["frameworkToBuyer"]: !data.frameworkToBuyer })
                              }}
                            />
                            <label className="font-wt-500 font-size-14 mr-4" for="frameworkToBuyer">Buyer</label>
                          </div>
                          {errors.frameworkDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.frameworkDocument}</b></div> : ''}
                        </div>
                        {data.frameworkDocument && data.frameworkDocument.name && !data.frameworkDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.frameworkDocument, docSignName: "frameworkDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.frameworkDocument && data.frameworkDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      {docNamePicker.id === "exhibitDocument" ? (
                        <div className="w-25 d-inline-block">
                          <NewInput name={docNamePicker.id} value={tempValue}
                            onChange={(e) => setTempValue(e.target.value)} />
                        </div>
                      )
                        : (
                          <label className="font-size-14 cursor ml-2"
                          >{agreementDocNames.exhibitDocument}</label>
                        )}
                      <span>
                        {docNamePicker.id === "exhibitDocument" ? (
                          <>
                            <img
                              onClick={() => {
                                if (tempValue) {
                                  setAgreementDocNames({ ...agreementDocNames, [docNamePicker.id]: tempValue })
                                  toggleDocNamePicker({ show: false, id: null })
                                }
                                else {
                                  toastDisplay('Please enter document name', "info")
                                }
                              }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                            <img
                              onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                          </>
                        ) : (
                          <img
                            onClick={() => { setTempValue(agreementDocNames.exhibitDocument); toggleDocNamePicker({ show: true, id: "exhibitDocument" }) }}
                            style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}
                      </span>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"exhibitDocument"} value={data.exhibitDocument} error={errors.exhibitDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "exhibitDocument": null })}
                          />
                          <div className='mb-3 d-flex pl-2 pt-2'>
                            <label className="font-wt-600 font-size-14 pt-1 mr-4" >Send to</label>
                            <input className="mr-3" type="radio" id="exhibitToExporter" name="exhibitToExporter"
                              checked={data.exhibitToExporter ? true : false}
                              onClick={() => {
                                setData({ ...data, ["exhibitToExporter"]: !data.exhibitToExporter })
                              }} />
                            <label className="font-wt-500 font-size-14 mr-4" for="exhibitToExporter">Exporter</label>
                            <input type="radio" id="exhibitToBuyer" name="exhibitToBuyer"
                              checked={data.exhibitToBuyer ? true : false}
                              onClick={() => {
                                setData({ ...data, ["exhibitToBuyer"]: !data.exhibitToBuyer })
                              }}
                            />
                            <label className="font-wt-500 font-size-14 mr-4" for="exhibitToBuyer">Buyer</label>
                          </div>
                          {errors.exhibitDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.exhibitDocument}</b></div> : ''}
                        </div>
                        {data.exhibitDocument && data.exhibitDocument.name && !data.exhibitDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.exhibitDocument, docSignName: "exhibitDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.exhibitDocument && data.exhibitDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      {docNamePicker.id === "noaDocument" ? (
                        <div className="w-25 d-inline-block">
                          <NewInput name={docNamePicker.id} value={tempValue}
                            onChange={(e) => setTempValue(e.target.value)} />
                        </div>
                      )
                        : (
                          <label className="font-size-14 cursor ml-2"
                          >{agreementDocNames.noaDocument}</label>
                        )}
                      <span>
                        {docNamePicker.id === "noaDocument" ? (
                          <>
                            <img
                              onClick={() => {
                                if (tempValue) {
                                  setAgreementDocNames({ ...agreementDocNames, [docNamePicker.id]: tempValue })
                                  toggleDocNamePicker({ show: false, id: null })
                                }
                                else {
                                  toastDisplay('Please enter document name', "info")
                                }
                              }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                            <img
                              onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                          </>
                        ) : (
                          <img
                            onClick={() => { setTempValue(agreementDocNames.noaDocument); toggleDocNamePicker({ show: true, id: "noaDocument" }) }}
                            style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}
                      </span>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"noaDocument"} value={data.noaDocument} error={errors.noaDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "noaDocument": null })}
                          />
                          <div className='mb-3 d-flex pl-2 pt-2'>
                            <label className="font-wt-600 font-size-14 pt-1 mr-4" >Send to</label>
                            <input className="mr-3" type="radio" id="noaToExporter" name="noaToExporter"
                              checked={data.noaToExporter ? true : false}
                              onClick={() => {
                                setData({ ...data, ["noaToExporter"]: !data.noaToExporter })
                              }} />
                            <label className="font-wt-500 font-size-14 mr-4" for="noaToExporter">Exporter</label>
                            <input type="radio" id="noaToBuyer" name="noaToBuyer"
                              checked={data.noaToBuyer ? true : false}
                              onClick={() => {
                                setData({ ...data, ["noaToBuyer"]: !data.noaToBuyer })
                              }}
                            />
                            <label className="font-wt-500 font-size-14 mr-4" for="noaToBuyer">Buyer</label>
                          </div>
                          {errors.noaDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.noaDocument}</b></div> : ''}
                        </div>
                        {data.noaDocument && data.noaDocument.name && !data.noaDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.noaDocument, docSignName: "noaDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.noaDocument && data.noaDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div> */}

                    {/* <div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                          {errors.invoiceDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invoiceDocument}</b></div> : ''}
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Bill of Lading/Airway Bill</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"shipment"} value={data.shipment} error={errors.shipment}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "shipment": null })}
                          />
                          {errors.shipment ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.shipment}</b></div> : ''}
                        </div>
                        {data.shipment && data.shipment.name && !data.shipment.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.shipment, docSignName: "shipment" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.shipment && data.shipment.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Investigation Report</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"investigationReport"} value={data.investigationReport} error={errors.investigationReport}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "investigationReport": null })}
                          />
                          {errors.investigationReport ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.investigationReport}</b></div> : ''}
                        </div>
                        {data.investigationReport && data.investigationReport.name && !data.investigationReport.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.investigationReport, docSignName: "investigationReport" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.investigationReport && data.investigationReport.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Certificate of Origin</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"certificateOfOrigin"} value={data.certificateOfOrigin} error={errors.certificateOfOrigin}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "certificateOfOrigin": null })}
                          />
                          {errors.certificateOfOrigin ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.certificateOfOrigin}</b></div> : ''}
                        </div>
                        {data.certificateOfOrigin && data.certificateOfOrigin.name && !data.certificateOfOrigin.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.certificateOfOrigin, docSignName: "certificateOfOrigin" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.certificateOfOrigin && data.certificateOfOrigin.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Packing List</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "packingListDocument": null })}
                          />
                          {errors.packingListDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.packingListDocument}</b></div> : ''}
                        </div>
                        {data.packingListDocument && data.packingListDocument.name && !data.packingListDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.packingListDocument, docSignName: "packingListDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.packingListDocument && data.packingListDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Shipping Bill</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "shippingBillDocument": null })}
                          />
                          {errors.shippingBillDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.shippingBillDocument}</b></div> : ''}
                        </div>
                        {data.shippingBillDocument && data.shippingBillDocument.name && !data.shippingBillDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.shippingBillDocument, docSignName: "shippingBillDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.shippingBillDocument && data.shippingBillDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div> */}
                    {/* <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler notEditable={true} titleNotEditable={true} hideAddMoreOption={true}
                        purpose={"ApplyForInvoiceFinance"} purposeId={finApplicationDetail.limit_id}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
                      />
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Term Sheet</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"termSheet"} value={data.termSheet} error={errors.termSheet}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "termSheet": null })}
                          />
                          {errors.termSheet ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.termSheet}</b></div> : ''}
                        </div>
                        {data.termSheet && data.termSheet.name && !data.termSheet.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.termSheet, docSignName: "termSheet" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.termSheet && data.termSheet.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler notEditable={true} titleNotEditable={true} hideAddMoreOption={true}
                        purpose={"SendTermSheet"} purposeId={finApplicationDetail.limit_id}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray2(docs)}
                      />
                    </div> */}


                  </div>
                ) : null}

                {tab === 0 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => setTab(1)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Continue"}
                    </button>
                    {/* <button type="button"
                      onClick={() => setAddRemark(true)}
                      className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                      {"Add remark"}
                    </button> */}
                  </div>
                )}

                {((tab === 1 && data.documentUploaded == 'no') &&
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => preHandleSubmit('forwardDocument')}
                      className={`mx-4 new-btn w-15 py-2 px-1 text-white`}>
                      {'Send Documents'}
                    </button>
                  </div>
                )}

                {(tab === 0 && lcMoreDocuments.modal && lcMoreDocuments.page === 1) ?
                  <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCMoreDocuments({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p>Request more documents</p>
                          <div className="row">
                            <div className='w-100'>
                              <div className='row'>
                                {addMore && data.otherDoc && data.otherDoc.map((item, key) => {
                                  return (
                                    <>
                                      <div className='col-md-7 move-l'>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <input disabled={true} className={"form-control bg-white text-al"} placeholder={item.name} />
                                            <div className="folderCommentIcon">
                                              <img src={"/assets/images/folder.png"} alt="" className="mb-3" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-2 move-l'>
                                        <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2" onClick={() => onEdit(item, key)} />
                                        <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2" onClick={() => onDelete(key)} />
                                      </div></>
                                  )
                                })
                                }
                                <div className='col-md-7 move-l'>
                                  <NewInput isAstrix={true} type={"text"} label={"Document Name"}
                                    name={"documentName"} value={data.documentName} error={errors.documentName} onChange={handleChange}
                                  />
                                </div>
                                <div className='col-md-7 move-l'>
                                  <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason`}
                                    name={"reason"} value={data.reason} error={errors.reason} onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-11 move-md text-blue">
                                  <a onClick={() => preHandleSubmit('addMoreDoc')}><p>Add more</p></a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => preHandleSubmit('sendDocReq')} >Send Request</button>
                        </div>
                      </div>
                    </div>
                  </div> :
                  (tab === 0 && lcMoreDocuments.modal && lcMoreDocuments.page === 2) &&
                  <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-m">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => { setLCMoreDocuments({ modal: false, page: 0 }); window.location = '/viewFinanceApplication' }}></button>
                        </div>
                        <div className="modal-body text-center">
                          <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                          <p>Request for additional documents sent successfully !</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {lcRequestMoreDetails.modal && lcRequestMoreDetails.page === 1 &&
                  <div className={`modal fade ${lcRequestMoreDetails.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCRequestMoreDetails({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p>Request more details</p>
                          <div className="row">
                            <div className='w-100'>
                              <div className='row'>
                                {addMoreDetails && data.otherDetails && data.otherDetails.map((item, key) => {
                                  return (
                                    <>
                                      <div className='col-md-6 move-m'>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <input disabled={true} className={"form-control bg-white"} placeholder={item.title} />
                                            {item.type == 'percentage' ? <p className="input-date-icon">%</p> :
                                              item.type == 'currency' ? <p className="input-date-icon">$</p> :
                                                item.type == 'text' ? <p className="input-date-icon">T</p> :
                                                  <img src={"/assets/images/calender.png"} alt="" className="input-date-icon" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-2 move-m'>
                                        <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2" onClick={() => onEditDetails(item, key)} />
                                        <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2" onClick={() => onDeleteDetails(key)} />
                                      </div></>
                                  )
                                })
                                }
                              </div>
                              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" >
                                <div class="col-md-6 move-m">
                                  <div class="col-md-6">
                                    <p style={{ "fontSize": "12px" }}>Select field type</p>
                                  </div>
                                  <div className="row justify-evenly">
                                    {infoType.map((item) => {
                                      return (
                                        <div
                                          onClick={() => setData({ ...data, infoTypeDetail: item.unit })}
                                          style={data.infoTypeDetail == item.unit ? {
                                            border: "2px solid #B9EFFF"
                                          } : null}
                                          className={`mb-3 col-md-6 form-control w-45 cursor `}>
                                          <label>{item.name}</label>
                                        </div>
                                      )
                                    })}
                                    {errors.infoTypeDetail ? <div class="text-danger mt-2 font-size-12">
                                      <b>{errors.infoTypeDetail}</b></div> : ''}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-6 move-m">
                                  <NewInput isAstrix={true} type={"text"} label={"Title"}
                                    name={"title"} value={data.title} error={errors.title} onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-6 move-m">
                                  <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason.`}
                                    name={"detailReason"} value={data.detailReason} error={errors.detailReason} onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 move-left text-blue">
                            <a onClick={() => preHandleSubmit('addMoreDetails')}><p>Add more</p></a>
                          </div>
                          <button type="button" className={`mx-2 new-btn w-20 py-2 px-2 text-white`} onClick={() => {
                            preHandleSubmit('sendDetailsReq')
                          }}>Send Request</button>
                        </div>
                      </div>
                    </div>
                  </div>}

                {moreDetailsSent && <div className={`modal fade ${moreDetailsSent && "show"}`} style={{ display: "block" }}>
                  <div className="modal-dialog modal-md">
                    <div className="modal-content submitmodal pb-4">
                      <div className="modal-header border-0">
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => { setMoreDetailsSent(false); window.location = '/viewFinanceApplication' }}></button>
                      </div>
                      <div className="modal-body text-center">
                        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                        <p>Request for additional details sent successfully !</p>
                      </div>
                    </div>
                  </div>
                </div>}

                {addRemark && <div className={`modal fade ${addRemark && "show"}`} style={{ display: "block" }}>
                  <div className="modal-dialog modal-md">
                    <div className="modal-content submitmodal pb-4">
                      <div className="modal-header border-0">
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setAddRemark(false)}></button>
                      </div>
                      <div className="modal-body text-center">
                        <p>Add Remark</p>
                        <div className="col-md-10 move-p">
                          <textarea rows="4" cols="50" className={"form-control"} placeholder="Write a remark for supplier." name="addRemark" value={data.addRemark}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="button"
                          onClick={() => {
                            if (!data.addRemark) {
                              return toastDisplay('Kindly add remark to continue', "info")
                            }
                            setshowLoader(true)
                            call('POST', 'addRemarkOnQuote', {
                              remark: data.addRemark, userId, userName, buyerId: finApplicationDetail.buyer_id,
                              buyerName: finApplicationDetail.buyerName, exporterUserId: finApplicationDetail.seller_id,
                              applicationId: finApplicationDetail.limit_id,
                              purpose: " invoice application (" + finApplicationDetail.reference_no + ") ", stage: data.documentUploaded === 'yes' ? "SignAgreement" + finApplicationDetail.limit_id : "InvoiceApplication" + finApplicationDetail.limit_id
                            }).then(async (result) => {
                              toastDisplay("Remark sent", "success")
                              setshowLoader(false)
                              setAddRemark(false)
                            }).catch((error) => {
                              toastDisplay(error, "error")
                              setshowLoader(false)
                              setAddRemark(false)
                            })
                          }}
                          className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                          {"Send Remark"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>}

                {/* {docNamePicker.show && <div className={`modal fade ${docNamePicker.show && "show"}`} style={{ display: "block" }}>
                  <div className="modal-dialog modal-md">
                    <div className="modal-content submitmodal pb-4">
                      <div className="modal-header border-0">
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleDocNamePicker({ show: false, id: null })}></button>
                      </div>
                      <div className="modal-body text-center">
                        <NewInput name={docNamePicker.id} value={tempValue}
                          onChange={(e) => { setTempValue(e.target.value) }}
                        />
                        <FileInput name={docNamePicker.id} value={data[docNamePicker.id]} isEditable={true}
                          onChange={handleFile} />
                        <button type="button"
                          onClick={() => {
                            if (tempValue) {
                              setAgreementDocNames({ ...agreementDocNames, [docNamePicker.id]: tempValue })
                              toggleDocNamePicker({ show: false, id: null })
                            }
                            else {
                              toastDisplay('Please enter document name', "info")
                            }
                          }}
                          className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                          {"SAVE"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>} */}

              </div>
            </div>
          </main>
        </div>
      </div >
      {
        data.documentUploaded == 'no' && signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />
      }
      {
        data.documentUploaded == 'yes' && signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={docSignPopup.docToSign}
          setUpdatedDoc={(updatedDoc) => uploadSignedDocumentAndReload(updatedDoc)} />
      }
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewFinanceApplication)