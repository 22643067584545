import React, { useEffect, useState } from "react";
import moment from "moment";
import TableFilter from "../../wallet/components/tableFilter";
import { printDiv, ExportExcel, isOutStandingDatePassed } from "../../../utils/myFunctions";
import NewTablev2 from "../../../utils/newTablev2";
import call from "../../../service";
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
import History from "./history";
import EmailModal from "./emailModal";
import { columns } from "../TableJson";
import Action from "../../lcV2/qoutes/actions";
import DisbursementChart from "./DisbursementChart";
import { NewTable } from "../../../utils/newTable";


const FinPayment = ({ userTokenDetails, navToggleState, }) => {

  const [dbData, setDbData] = useState([])

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [data, setData] = useState({ pageCount: 3 })


  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 3 })
  const [showLoader, setshowLoader] = useState(false)
  const [selectedDisbursement, setSelectedDisbursement] = useState({})

  const [history, setHistory] = useState(false)
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [display, setDisplay] = useState(false);


  const [action, setAction] = useState({ show: false, id: null });

  const [alldisdata, setalldisdata] = useState({})
  const [dishistory, setdishistory] = useState({})
  const [disgraphdata, setdisgraphdata] = useState([])

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Name": data.createdByName,
        "Service Name": data.serviceName,
        "Transaction ID": data.transactionId,
        "Type of Transaction": data.type,
        "Charges": data.charges,
        "Mode Of Payment": data.modeOfPayment,
        "Date And Time": moment(data.createdAt).format('DD-MM-YYYY hh:ss:mm A'),
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }

  const getreportsdata = () => {
    setshowLoader(true)
    call('POST', 'getreportsdata', { userId, userTypeId }).then(result => {
      setalldisdata(result)
      setshowLoader(false)

    }).catch(e => {
      console.log('error in getreportsdata', e)
      setshowLoader(false)

    })
  }

  const gethistorydisbursement = () => {
    setshowLoader(true)

    call('POST', 'gethistorydisbursement', { userId, userTypeId }).then(result => {
      setdishistory(result)
      setshowLoader(false)

    }).catch(e => {
      console.log('error in gethistorydisbursement', e)
      setshowLoader(false)

    })
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      let allDeniedCount = 0
      let allApprovedCount = 0

      if (item.buyers_credit) {
        for (let index = 0; index < JSON.parse(item.buyers_credit).length; index++) {
          const j = JSON.parse(item.buyers_credit)[index];
          if (j.financierAction === "deny") {
            allDeniedCount += 1
          }
          else {
            allApprovedCount += 1
          }
        }
      }

      row[0] = item.reference_no
      row[1] = item.supplierName
      row[2] = item.buyerName
      row[3] = item.lenderName

      let disbScheduledOn = item.disbScheduledOn?.split(",") || []
      let disbCurrency = item.disbCurrency?.split(",") || []
      let disbAmount = item.disbAmount?.split(",") || []
      let disbActualAmount = item.disbActualAmount?.split(",") || []
      let disbActualDate = item.disbActualDate?.split(",") || []
      let disbAttachment = item.disbAttachment?.split(",") || []
      let disbStatus = item.disbStatus?.split(",") || []

      row[4] = <div
        className=''
      >
        {disbScheduledOn.length ? disbScheduledOn.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className='font-size-14 text-color-label font-wt-600' >{`${moment(key).format("DD/MM/YYYY")}`}</label>
            </div>
          )
        }) : "NA"}
      </div>
      row[5] = <div
        className=''
      >
        {disbAmount.length ? disbAmount.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className='font-size-14 text-color-label font-wt-600' >{`${key} ${disbCurrency[index]}`}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[6] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className={`font-size-14 
              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-600`} >
                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? `${disbAmount[index]} ${disbCurrency[index]}` : '-'}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[7] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className={`font-size-14 
              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-600`} >
                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? (moment().diff(moment(disbScheduledOn[index]), "days") + " Days") : '-'}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[8] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative mb-2' >
              {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ?
                <img src={"/assets/images/overdue_icon.png"} alt="" height={24} width={25} /> : disbStatus / 1 == 0 ?
                  <img src={"/assets/images/processing.png"} alt="" height={24} width={25} /> :
                  <img src={"/assets/images/upload_done.png"} alt="" height={24} width={25} />}
            </div>
          )
        }) : "NA"}
      </div>

      tableData.push(row)
      row = []
    }
    return tableData
  }

  const getdisbursementTable = () => {
    setshowLoader(true)

    let reqObj = {
      userId,
      search: filter.search,
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      userTypeId
    }
    call('POST', 'getInvoiceDiscountingListForFinancer', reqObj).then(result => {
      setDbData(formatDataForTable(result.listData))
      setCount(result.countData);
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  const getdisbursementchartdata = () => {
    setshowLoader(true)

    let reqObj = {
      "userId": userId,
      "startMonth": new Date().getMonth() - 1,
      "endMonth": new Date().getMonth() + 1,
      "currentYear": new Date().getFullYear(),
      userTypeId

    }
    call('POST', 'getdisbursementchartdata', reqObj).then(result => {
      setdisgraphdata(result)
      setshowLoader(false)

    }).catch(e => {
      setshowLoader(false)

    })
  }
  useEffect(() => {
    getreportsdata()
    gethistorydisbursement()
    getdisbursementchartdata()
  }, [])
  useEffect(() => {
    getdisbursementTable()
  }, [page, refresh])


  const handleDropDown = (index) => {
    if (!action.show)
      setAction({ show: true, id: index });
    else
      setAction({ show: false, id: index })
  }

  const HistoryFn = () => {
    setHistory(true);
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
  }

  const closeExpFn = () => {
    setExperience(false);

  }

  const closeFn = () => {
    setExperience(true);
    setLimitinvoice(false);
  }

  const ContinueFn = () => {
    setExperience(false);
    setLimitinvoice(true);
  }


  function openApplication(indx, tabIndx) {
    let item = dbData[indx]
    window.location = `/sign-agreement`
    localStorage.setItem("item", JSON.stringify(item))
    localStorage.setItem("isAdminUser", true)
    localStorage.setItem("defaultSetTab", tabIndx)

    // setting manual user id & email for user
    localStorage.setItem("manualUserId", item.user_id)
    localStorage.setItem("manualUserEmail", item.supplierEmailId)
    localStorage.setItem("headerBreadCum", "Invoice Discounting > Approved Finance > Application Details")
  }

  return (
    <>
      <div className="row justify-content-between mt-4">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="col-md-3 ">
          <div className="card p-4 dashboard-card border-0 borderRadius h-100 justify-content-center me-3">
            <p className='dasboard-count color3DB16F mb-1 font-size-22'>{"$ " + Intl.NumberFormat('en-US').format(alldisdata.receievables)}</p>
            <label className='dashboard-name cursor'> <img className='me-2' src={"assets/images/Receivable.png"} alt="" /> Paid  </label>
          </div>
        </div>
        <div className="col-md-3 ">
          <div className="card p-4 dashboard-card border-0 borderRadius h-100 justify-content-center me-3">
            <p className='dasboard-count colorFF7B6D mb-1 font-size-22'>{"$ " + Intl.NumberFormat('en-US').format(alldisdata.overdue)}</p>
            <label className='dashboard-name cursor'> <img className='me-2' src={"assets/images/OverDue.png"} alt="" /> Over due  </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="card p-4 dashboard-card border-0 borderRadius h-100 mx-0">
            <label className="text-secondary font-size-12 font-wt-500 mb-0">Total 12 weeks payment summary</label>
            <div className="d-flex justify-content-between">
              <div>
                <p className='dasboard-count color3DB16F font-size-22 mb-1'>{"$ " + Intl.NumberFormat('en-US').format(dishistory.receieved)}</p>
                <label className='dashboard-name cursor'> Paid </label>
              </div>
              <div>
                <p className='dasboard-count text-custom2 font-size-22 mb-1'>{"$ " + Intl.NumberFormat('en-US').format(dishistory.due)}</p>
                <label className='dashboard-name cursor'> Due </label>
              </div>
              <div>
                <p className='dasboard-count colorFF7B6D font-size-22 mb-1'>{"$ " + Intl.NumberFormat('en-US').format(dishistory.overdue)}</p>
                <label className='dashboard-name cursor'> Overdue </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card p-4 dashboard-card border-0 borderRadius mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center">
            <p className="font-wt-500 letter-spacing05 text-color-0C0C0C font-size-14 lineheight19 mb-0">Receivables</p>
            <select class="form-select border-0 monthsingraph" aria-label="Default select example">
              <option selected>October - December</option>
              {/* <option value="1">October - December</option> */}
            </select>
          </div>
          <div className="d-flex gap-2">
            <label className="paymentsdots"><img className='' src={"assets/images/Due-dot.png"} alt="" /> Due </label>
            <label className="paymentsdots"><img className='' src={"assets/images/received-dot.png"} alt="" /> Received </label>
            <label className="paymentsdots"><img className='' src={"assets/images/overdue-dot.png"} alt="" /> Overdue </label>
          </div>
        </div>
        <div className="py-5 my-5 text-center">
          <DisbursementChart data={disgraphdata} />

        </div>

      </div >
      <div className="mt-3">

        {/* <div className="text-end">
          <button class="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">History</button>
        </div> */}
        <div className="mt-4">
          <TableFilter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} onExcelExport={() => exportToexcel(dbData, "Excel_Disbursements")} onPDFExport={() => printDiv("disbursementstablediv", "Excel_Disbursements", [])} />

        </div>

        <div className="mb-4" id="disbursementstablediv">
          <NewTable disableAction={false}
            columns={[{
              name: "Application No", filter: false
            },
            {
              name: "Supplier", filter: true,
              filterDataKey: "Supplier Name",
              sort: [
                { name: "Sort A-Z", selected: filter.sortExpName === "ASC", onActionClick: () => { setFilter({ ...filter, sortExpName: 'ASC', sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } },
                { name: "Sort Z-A", selected: filter.sortExpName === "DESC", onActionClick: () => { setFilter({ ...filter, sortExpName: "DESC", sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } }]
            },
            {
              name: "Buyer", filter: true,
              filterDataKey: "Buyer Name",
              sort: [
                { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } },
                { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } }]
            },
            { name: "Financer" },
            { name: "Disbursement Date" },
            { name: "Disbursement Amount" },
            {
              name: "Outstanding Amount"
            },
            {
              name: "Outstanding Days"
            },
            { name: "Status" }
            ]}
            data={dbData}
            options={[
              { name: "Application", onClick: (indx) => openApplication(indx, 0) },
              { name: "Documents", onClick: (indx) => openApplication(indx, 1) },
              { name: "Quote", onClick: (indx) => openApplication(indx, 2) },
              { name: "Disbursement", onClick: (indx) => openApplication(indx, 3) },
              { name: "Chat with Supplier", onClick: () => null },
              { name: "Chat with Buyer", onClick: () => null }
            ]}
          />
        </div>
        <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage} />

      </div>

      {history && <History setHistory={setHistory} history={history} />}

      {
        limitinvoice && <EmailModal limitinvoice={limitinvoice} closeSuccess={closeSuccess} >
          <div className="mx-4 text-start">
            <div className="mx-3 text-start d-flex gap-3 align-items-center text-color-0C0C0C font-size-13 letter-spacing04 lh-18">Send mail to:
              <div className=''>
                <img
                  alt=""
                  onClick={() => setData({ ...data, isFinancerSelected: !data.isFinancerSelected })}
                  className='cursor mr-3' src={`assets/images/${data.isFinancerSelected ? 'checked-green' : 'empty-check'}.png`} />
                <label className="mb-0">Financer</label>
              </div>
              <div className=''>
                <img
                  alt=""
                  onClick={() => setData({ ...data, isBuyerSelected: !data.isBuyerSelected })}
                  className='cursor mr-3' src={`assets/images/${data.isBuyerSelected ? 'checked-green' : 'empty-check'}.png`} />
                <label className="mb-0">Buyer</label>
              </div>

            </div>
            <div className="card p-3 my-3 borderRadius">
              <p className="color434343 font-size-14 letter-spacing05">{`Below are the details of overdue payment related to 1 invoice with due date ${selectedDisbursement.overduedate ? moment(selectedDisbursement.overduedate).format('DD-MM-YYYY') : '-'}. Request you to share updates on this matter.`}</p>
              <div className="col-md-10 px-0">
                <div className="d-flex"> <p className="col-6 px-0 BuyerdetailsLabel mb-0">Transaction ID</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc mb-0" >{selectedDisbursement.transactionId ? selectedDisbursement.transactionId : '-'} </p></div>
                <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Invoice no</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >{selectedDisbursement.invRefNo ? selectedDisbursement.invRefNo : '-'} </p></div>
                <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Supplier</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >{userName}</p></div>
                <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Buyer name</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >{selectedDisbursement.buyerName}</p></div>
                <div className="d-flex"> <p className="col-6 px-0 BuyerdetailsLabel  mb-0" >Outstanding amount</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >{"$ " + Intl.NumberFormat('en-US').format(selectedDisbursement.totalOverdueAmt)}</p></div>
                <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Due date</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >{selectedDisbursement.overduedate ? moment(selectedDisbursement.overduedate).format('DD-MM-YYYY') : '-'}</p></div>
              </div>
            </div>
            <div className="mx-3">
              <p onClick={() => closeFn()} className="cursor text-custom2 font-wt-500 font-size-13 lh-18">Add from templates</p>
              <button type="button"
                className={` new-btn w-auto py-2 px-4 text-white cursor`}>
                Send
              </button>
              <button type="button"
                className={`mx-3 viewfinancersbtn w-auto py-2 px-3 text-custom2 cursor`}>
                Save as template
              </button>
            </div>
          </div>

        </EmailModal>
      }
      {
        experience && <EmailModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
          <div className="mx-4 text-start">
            <p className="text-secondary font-wt-600 letter-spacing04 lineheight19">Saved templates</p>
            <div className="d-flex justify-content-between border-bottom pb-2">
              <div className="form-check cursor ps-0  ">
                <input onClick={() => setDisplay(true)} className="form-check-input" type="radio" name="LCconfirmation" id="LCconfirmation" />
                <label className="form-check-label" for="LCconfirmation">
                  Outstanding over due
                </label>
              </div>
              <div>
                <img className='' src={"assets/images/arrowdown.png"} />
              </div>
            </div>
            <div className="d-flex justify-content-between  pb-2 mt-2">
              <div className="form-check cursor ps-0  ">
                <input onClick={() => setDisplay(true)} className="form-check-input" type="radio" name="LCconfirmation" id="LCconfirmation" />
                <label className="form-check-label" for="LCconfirmation">
                  Disbursement date
                </label>
              </div>
              <div>
                <img className='' src={"assets/images/arrowdown.png"} />
              </div>
            </div>
            <button type="button" onClick={() => ContinueFn()}
              className={` new-btn w-auto py-2 px-5 mt-4 text-white cursor`}>
              Continue
            </button>
          </div>


        </EmailModal >
      }
    </>
  );
}
export default FinPayment;