import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { financialYearMonths, handleFileCommonFunction, handleMultipleFile, mergeMultiplePdfs, monthsNoMapping, most_used_currencies, replaceFileBase64WithFileObj } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import Papa from 'papaparse';
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

export let wcyesnoqsts = {
  "qst1": {
    label: "Women Entrepreneur"
  },
  "qst2": {
    label: "ISO certified"
  },
  "qst3": {
    label: "Business continuity indemnity policy"
  },
  "qst4": {
    label: "The agreement between the client and the debtor does not prohibit assignment of receivables by the client to a third party including a Factoring Company."
  },
  "qst5": {
    label: "Any irregular and slow payments in the past by the proposed debtor."
  },
  "qst6": {
    label: "Are there any overdue invoices outstanding on account of the debtor in the client’s books."
  },
  "qst7": {
    label: "Whether there have been any disputes with the debtor in recent past with respect to the goods supplied by the client to the debtor or otherwise."
  },
  "qst8": {
    label: "Inform whether direct contact allowed"
  }
}

const tabsArr = [
  { name: "Documents" },
  { name: "Company Details" },
  { name: "Owner Details" },
  { name: "Facility" },
  { name: "Vendor Details" },
  { name: "Select Financier" },
  { name: "Review" }
]

export const reviewBusiness = [
  { "name": "Business Name", val: "businessName" },
  { "name": "Aadhar No", val: "aadharNo" },
  { "name": "Contact Person", val: "contactPerson" },
  { "name": "PAN No", val: "panNo" },
  { "name": "Contact Number", val: "contactNo", unit: "phoneCode" },
  { "name": "Constitution", val: "constitution" },
  { "name": "Email Id", val: "emailId" },
  { "name": "Factory Premises", val: "factoryPremises" },
  { "name": "Address", val: "addressLine1", after: "addressLine2" },
  { "name": "Start Date of Business", val: "startDateOfBusiness" },
  { "name": "Industry Type", val: "industry" },
  { "name": "Competition", val: "competition" },
  { "name": "Sector", val: "sector" },
  { "name": "Product Details", val: "productDescription" },
  { "name": "Sub-Sector", val: "subSector" }
]

export const ownerReview = [
  { "name": "Owner Name", val: "ownerName" },
  { "name": "Category", val: "ownerCategory" },
  { "name": "Contact No.", val: "ownerContactNo", unit: "ownerPhoneCode" },
  { "name": "PAN No", val: "ownerPanNo" },
  { "name": "Email Id", val: "ownerEmailId" },
  { "name": "Aadhar No.", val: "ownerAadharNo" },
  { "name": "Address", val: "ownerAddressLine", after: "ownerAddressLine" },
  { "name": "Date of Birth", val: "ownerDOB" },
  { "name": "Net Worth", val: "ownerNetWorth" }
]

const briefFinancials = [
  { name: 'Sales' },
  { name: 'COGS' },
  { name: 'EBITDA' },
  { name: 'EBITDA %' },
  { name: 'PAT' },
  { name: 'PAT %' },
  { name: 'Trade Receivables' },
  { name: 'Trade Payables' },
  { name: 'Debtor Days' },
  { name: 'Creditors Day' },
  { name: 'Bank' }
]

export const facilityNames1 = [
  { name: 'CC (Cash Credit)' },
  { name: 'OD (Overdraft)' },
  { name: 'LC' },
  { name: 'BG' },
  { name: 'TL' },
]
export const facilityNames2 = [
  { name: 'ODFD' },
  { name: 'MEOD' },
  { name: 'WCDL' },
  { name: 'DRUL' },
  { name: 'FPC/FBD' },
]

const WCApplyLimit = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const formContinue = queryParams.get("formContinue")
  console.log(userTokenDetails, "usertokennnnnn");
  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }

  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const user_avatar = userToken.userAvatar ? userToken.userAvatar : null

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
    salesCurrency: 'INR', [`faciltiyOutstandingCurrency0`]: 'USD', [`faciltiyLimitCurrency0`]: 'USD',
    facilityCurrency: 'USD'
  })
  const [errors, setErrors] = useState({})
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [signdoc, setSigndoc] = useState(false);

  const [countryData, setCountryData] = useState([])
  const [currencyData, setCurrencyData] = useState(most_used_currencies)
  const [finList, setFinList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false
  })
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')
  const [states, setstatedata] = useState([])
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [owners, setOwners] = useState([null, null])
  const [exFacilties, setExFacilities] = useState([null])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])

  const [finActionTab, setFinancierActionTab] = useState(0)
  const [draftData, setDraftData] = useState([])
  const [shouldSaveCache, toggleShouldSaveCache] = useState(formContinue ? false : true)
  const [cacheId, setCacheId] = useState(formContinue || null)
  const [ownerdetails, setownerdetails] = useState([])
  useEffect(() => {
    if (shouldSaveCache) {
      saveCache()
    }
  }, [data, tab, owners, exFacilties, top10Buyers, top10Suppliers])

  async function saveCache() {
    let reqBody = { userId, forWC: true }
    reqBody["details"] = { data, tab, owners, exFacilties, top10Buyers, top10Suppliers, percentageComplete: (tab) * 20 }
    if (cacheId) {
      reqBody["cacheId"] = cacheId
    }
    let tempCacheId = await call('POST', 'saveInvoiceLimitCache', reqBody)
    if (tempCacheId) {
      setCacheId(tempCacheId)
    }
  }


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("wcApplyLimit");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  useEffect(() => {
    if (cacheId) {
      getCache()
    }
  }, [])

  async function getCache() {
    let cacheDetails = await call('POST', 'getInvoiceLimitCacheById', { cacheId, forWC: true })
    if (cacheDetails?.details) {
      setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
      setTab(cacheDetails.details.tab)
      setOwners(cacheDetails.details.owners)
      setExFacilities(cacheDetails.details.exFacilties)
      setTop10Buyers(cacheDetails.details.top10Buyers)
      setTop10Suppliers(cacheDetails.details.top10Suppliers)
      toggleShouldSaveCache(true)
    }
  }

  useEffect(() => {
    call('POST', 'getAllInvoiceLimitDrafts', { userId, forWC: true }).then((res) => setDraftData(res))
  }, [])




  useEffect(() => {
    getFinancers()
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountryData(result.data.message);
      }
    });
    getStateByCountry(101)
  }, [])

  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }

  async function fetchUserDetails(ownerIndex, action, clearForm) {
    let resp = await call("POST", "getUserDetailsById", { contactUserId: userId })
    let userResp = resp?.[0]
    // let userIECResp = await call("POST", "getUserDetailsFromIEC", { userId })
    let objectToSet = {
      [`ownerName${ownerIndex}`]: "",
      [`ownerNameTitle${ownerIndex}`]: "",
      [`ownerContactNo${ownerIndex}`]: "",
      [`ownerPhoneCode${ownerIndex}`]: "",
      [`ownerEmailId${ownerIndex}`]: "",
      [`ownerAddressLine1${ownerIndex}`]: "",
      [`ownerAddressLine2${ownerIndex}`]: "",
      [`ownerCity${ownerIndex}`]: "",
      [`ownerState${ownerIndex}`]: "",
      [`ownerCountry${ownerIndex}`]: "",
      [`ownerPostalCode${ownerIndex}`]: "",
      [`ownerPanNo${ownerIndex}`]: "",
      [`ownerAadharNo${ownerIndex}`]: ""
    }
    let clearFormObj = {
      [`ownerName${clearForm}`]: "",
      [`ownerNameTitle${clearForm}`]: "",
      [`ownerContactNo${clearForm}`]: "",
      [`ownerPhoneCode${clearForm}`]: "",
      [`ownerEmailId${clearForm}`]: "",
      [`ownerAddressLine1${clearForm}`]: "",
      [`ownerAddressLine2${clearForm}`]: "",
      [`ownerCity${clearForm}`]: "",
      [`ownerState${clearForm}`]: "",
      [`ownerCountry${clearForm}`]: "",
      [`ownerPostalCode${clearForm}`]: "",
      [`ownerPanNo${clearForm}`]: "",
      [`ownerAadharNo${clearForm}`]: ""
    }
    if (action) {
      setData({ ...data, ...objectToSet, [`sameAsUserDetailsOwner${ownerIndex}`]: false, [`sameAsUserDetailsOwner${clearForm}`]: false, ...clearFormObj })
    }
    else {
      objectToSet = {
        [`ownerName${ownerIndex}`]: userResp.contact_person,
        [`ownerNameTitle${ownerIndex}`]: userResp.name_title,
        [`ownerContactNo${ownerIndex}`]: userResp.contact_number,
        [`ownerPhoneCode${ownerIndex}`]: userResp.phone_code,
        [`ownerEmailId${ownerIndex}`]: userResp.email_id,
        [`ownerAddressLine1${ownerIndex}`]: userResp.user_address,
        [`ownerAddressLine2${ownerIndex}`]: "",
        [`ownerCity${ownerIndex}`]: userResp.company_city,
        [`ownerState${ownerIndex}`]: userResp.company_state,
        [`ownerCountry${ownerIndex}`]: userResp.country_code,
        [`ownerPostalCode${ownerIndex}`]: userResp.company_postal_code,
        [`ownerPanNo${ownerIndex}`]: userResp.pan_no,
        [`ownerAadharNo${ownerIndex}`]: userResp.aadhar_no
      }
      setData({ ...data, ...objectToSet, [`sameAsUserDetailsOwner${ownerIndex}`]: true, [`sameAsUserDetailsOwner${clearForm}`]: false, ...clearFormObj })
    }
  }

  function handleFinSelection(j) {
    let temp = finList
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setFinList([...temp])
  }

  const getFinancers = async () => {
    setshowLoader(true)
    let finApiResp = await call('POST', "getFinanciersForWC", {})
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
    }
    setshowLoader(false)
    setFinList(finApiResp)
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleCSV = (event, userTypee) => {
    event.persist();
    if (!event.target.files.length) {
      return null
    }
    setErrors({ ...errors, [event.target.name]: "" });
    if (!event.target.files[0]['name'].includes("csv")) {
      setErrors({ ...errors, [event.target.name]: "Please select csv file" });
      return
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      Papa.parse(event.target.files[0], {
        complete: (result) => {
          // console.log("csvParseComplete=======>", result);
          let tempObj = {}
          for (let index = 0; index < result?.data?.length; index++) {
            if (index <= 10) {
              const element = result.data[index];
              if (userTypee == "buyer") {
                tempObj[`buyerName${index}`] = element["Buyer Name"] || ""
                tempObj[`buyerAddress${index}`] = element["Address"] || ""
                tempObj[`buyerCreditLimitRequired${index}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
                tempObj[`buyerTermsOfPayment${index}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
                tempObj[`buyerAnnualSale${index}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
                tempObj[`buyerYearsOfRelation${index}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
                tempObj[`buyerRelationWithClient${index}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
              }
              else if (userTypee === "supplier") {
                tempObj[`supplierName${index}`] = element["Supplier Name"] || ""
                tempObj[`supplierAddress${index}`] = element["Address"] || ""
                tempObj[`supplierCreditLimitRequired${index}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
                tempObj[`supplierTermsOfPayment${index}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
                tempObj[`supplierAnnualSale${index}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
                tempObj[`supplierYearsOfRelation${index}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
                tempObj[`supplierRelationWithClient${index}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
              }
            }
            else {
              break
            }
          }
          // console.log("tempObjjjjjjjjjjjjjjjjjjjjj", tempObj);
          setData({ ...data, ...tempObj, [event.target.name]: event.target.files[0] })
          setErrors({ ...errors, [event.target.name]: "" });
        },
        header: true
      });
    }
    reader.readAsText(event.target.files[0]);
  }


  useEffect(() => {

    call('POST', 'getownerDetails', { iec: userTokenDetails.iecNo }).then((res) => setownerdetails(res))

  }, [])


  async function handleValidation() {
    let err = {}
    if (tab === 4) {
      if (!data["buyerName0"]) {
        err["buyerName"] = "Kindly enter atleast 1 buyer details"
        toastDisplay("Kindly enter atleast 1 buyer details", "info")
      }
      else {
        setTab(5)
      }
    }
    else if (tab === 5) {
      let isFinSelected = false
      for (let index = 0; index < finList.length; index++) {
        const element = finList[index];
        if (element.isChecked) {
          isFinSelected = true
        }
      }
      if (!isFinSelected) {
        toastDisplay("Kindly select atleast 1 financer", "info")
      }
      else {
        setTab(tab + 1)
      }
    }
    else if (tab === 6) {
      if (!data.tcAccepted) {
        toastDisplay('kindly accept declaration, to continue', "info")
        err["tcAccepted"] = 'kindly accept declaration, to continue'
      }
    }
    else {
      setTab(tab + 1)
    }
    setErrors(err)
    if (!Object.keys(err).length && tab === 6) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("userId", userId)
      formData.append("userName", userName)
      Object.keys(data).forEach(key => {
        if (!key.includes("Document")) {
          formData.append(key, data[key])
        }
      })
      if (data.securityDocument?.name) {
        formData.append("securityDocument", data.securityDocument)
        if (data[`securityDocument`].signatureId) {
          formData.append("securityDocumentSignId", data[`securityDocument`].signatureId)
        }
      }
      formData.append("finList", JSON.stringify(finList))
      // formData.append('to', "+917310004469");
      // formData.append('templateName', "working_capital_limit");
      // formData.append('mediaUrl', "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
      // formData.append('filename', "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
      // formData.append('placeholders', JSON.stringify(["nikita", "6676778"]));
      call('POST', 'applyForWCLimit', formData).then(async res => {
        setshowLoader(false);
        toggleApplicationSubmitPopup({ show: true, data: {} })
        if (cacheId) {
          await call("POST", 'clearInvoiceLimitCacheById', { cacheId, forWC: true })
        }
        if (userTokenDetails.type_id / 1 == 1) {
          localStorage.clear("UserDetails")
          if (window.opener) {
            window.opener.postMessage({ type: 'reload', data: 'New application created' }, '*');
          }

          window.close()
        }
      })
        .catch(err => { setshowLoader(false); toastDisplay("Something went wrong") })
    }
  }

  // console.log("dattaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(result => {
      setChatList(result)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              {/* <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div> */}

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {selectedChat.isChatOpen &&
        <div className="chatboxDiv">
          <ChatBoxPopUp
            chatList={chatList}
            user_avatar={user_avatar}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            onChatSend={() => sendChatMsg()}
            message={message}
            setTextMsg={setTextMsg}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false
              })
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup(false)
          window.location = 'wcQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >Working Capital Limit </span> has been submitted successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="otherFinWCApplyForLimit" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Working Capital > Apply For Limit"}
              userTokenDetails={userToken} />
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabsArr.map((item, index) => {
                  return (
                    <li
                      onClick={() => {
                        if (index < tab) {
                          setTab(index)
                        }
                      }}
                    >
                      <a
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className="card mt-1"
              >

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabsArr} activeTab={tab} label={"name"}
                    separationWidth={'1rem'} handleClick={(i, index) => {
                      if (index < tab) {
                        setTab(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='py-4 px-3'>
                    <ExtraDetailsForLimitApplication userTokenDetails={userToken} hideHeader={true} hideSideBar={true}
                      hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                      showContinueButton={true} afterDetailsSavedOn={() => setTab(1)}
                      loadDataInWriteModeFromCacheIfPresent={true} continueBtnCSS={{ opacity: 0, height: 0 }} />
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className="d-flex row px-5 pb-4 pt-5" >
                    <div className="col-md-4" >
                      <NewInput
                        label={"Business Name"} value={data.businessName} error={errors.businessName}
                        onChange={handleChange} name={"businessName"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"PAN No"} value={data.panNo} error={errors.panNo}
                        onChange={handleChange} name={"panNo"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Aadhar No"} value={data.aadharNo} error={errors.aadharNo}
                        onChange={handleChange} name={"aadharNo"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Contact Person"} value={data.contactPerson} error={errors.contactPerson}
                        onChange={handleChange} name={"contactPerson"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <InputWithSelect type={"number"} label={"Contact No"}
                        selectData={countryData}
                        selectName={`phoneCode`} selectValue={data[`phoneCode`]}
                        optionLabel={"phonecode"} optionValue={'phonecode'}
                        name={`contactNo`} value={data[`contactNo`]} error={errors[`phoneCode`] || errors[`contactNo`]}
                        onChange={handleChange} />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Email Id"} value={data.emailId} error={errors.emailId}
                        onChange={handleChange} name={"emailId"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Address Line 1"} value={data.addressLine1} error={errors.addressLine1}
                        onChange={handleChange} name={"addressLine1"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Address Line 2"} value={data.addressLine2} error={errors.addressLine2}
                        onChange={handleChange} name={"addressLine2"}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"City"} value={data.city} error={errors.city} name={"city"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"State"} value={data.state} error={errors.state} name={"state"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewSelect
                        selectData={countryData} optionLabel={"name"} optionValue={"sortname"} onChange={handleChange}
                        label={"Country"} name={"country"} value={data.country} error={errors.country}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Postal Code"} value={data.postalCode} error={errors.postalCode} name={"postalCode"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Constitution"} value={data.constitution} error={errors.constitution} name={"constitution"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Industry"} value={data.industry} error={errors.postalCode} name={"industry"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput type={"date"}
                        label={"Start Date of Business"} value={data.startDateOfBusiness} error={errors.startDateOfBusiness} name={"startDateOfBusiness"}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4" >
                      <NewInput
                        label={"Sector"} value={data.sector} error={errors.sector} name={"sector"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Sub Sector"} value={data.subSector} error={errors.subSector} name={"subSector"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Competition"} value={data.competition} error={errors.competition} name={"competition"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Factory Premises"} value={data.factoryPremises} error={errors.factoryPremises} name={"factoryPremises"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        label={"Product Description"} value={data.productDescription} error={errors.productDescription} name={"productDescription"}
                        onChange={handleChange}
                      />
                    </div>

                    {/* <div className="mt-4">
                      <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } }, { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                        {Object.keys(wcyesnoqsts).map((i, j) => {
                          return (
                            <tr>
                              <td>{wcyesnoqsts[i]["label"]}</td>
                              <td>
                                <img
                                  onClick={() => {
                                    setData({ ...data, [i]: data[i] === true ? undefined : true })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                              <td>
                                <img
                                  onClick={() => {
                                    setData({ ...data, [i]: data[i] === false ? undefined : false })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div> */}

                    {/* <div className="mt-3">
                      <div className="d-flex row justify-content-between" >
                        <label className="font-size-13 font-wt-600 mb-3 w-50" ><u>Month on month sales (in Lacs)</u></label>
                        <button type="button"
                          onClick={async () => {
                            setshowLoader(true)
                            let apiResp = await call('POST', 'getLast3YearsGstMonthOnMonthSales', { userId })
                            setshowLoader(false)
                            if (apiResp?.length) {
                              let tempData = data
                              let Year0 = moment().format("YYYY")
                              let Year1 = Year0 - 1
                              for (let index = 0; index < apiResp.length; index++) {
                                const element = apiResp[index];
                                let selectedYear = element.financialYear.split("-")[1]
                                tempData[`${monthsNoMapping[element.month]}:${Year0 === selectedYear ? 'Year0' :
                                  Year1 === selectedYear ? 'Year1' : "Year2"}`] = element.sales || 0
                              }
                              setData({ ...tempData })
                            }
                            else {
                              toastDisplay('No GST details found, make sure you upload your GST documents in order to use this feature', "info")
                            }
                          }}
                          className={` new-btn w-17 py-2 px-2  text-white mb-4 mr-2`}>
                          {"Fetch From OCR"}
                        </button>
                      </div>
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Months", subColumnStyle: { width: '25%' } }, { subColumns: '2nd Last Year', subColumnStyle: { width: '25%' } },
                        { subColumns: 'Last Year', subColumnStyle: { width: '25%' } }, { subColumns: 'Current Year', subColumnStyle: { width: '25%' } }]}>
                        {financialYearMonths.map((i, j) => {
                          return (
                            <tr>
                              <td><label className="font-size-13 font-wt-500 px-2" >{i.name}</label></td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`salesCurrency`} selectValue={data[`salesCurrency`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`${i.name}:Year2`} value={data[`${i.name}:Year2`]}
                                onChange={handleChange} /></td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`salesCurrency`} selectValue={data[`salesCurrency`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`${i.name}:Year1`} value={data[`${i.name}:Year1`]}
                                onChange={handleChange} /></td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`salesCurrency`} selectValue={data[`salesCurrency`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`${i.name}:Year0`} value={data[`${i.name}:Year0`]}
                                onChange={handleChange} /></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div> */}

                    <div className="mt-3">
                      <label className="font-size-13 font-wt-600 mb-3" ><u>Brief Financials</u></label>
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "", subColumnStyle: { width: '20%' } }, { subColumns: '2021 (Audited)', subColumnStyle: { width: '20%' } },
                        { subColumns: '2022 (Audited)', subColumnStyle: { width: '20%' } }, { subColumns: '2023 Prov B/s if ABS NA', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Jan to May 23 numbers', subColumnStyle: { width: '20%' } }]}>
                        {briefFinancials.map((i, j) => {
                          return (
                            <tr>
                              <td><label className="font-size-13 font-wt-500 px-2" >{i.name}</label></td>
                              <td><InputForTable isAstrix={false} label={""}
                                name={`${i.name}:0`} value={data[`${i.name}:0`]}
                                onChange={handleChange} /></td>
                              <td><InputForTable isAstrix={false} label={""}
                                name={`${i.name}:1`} value={data[`${i.name}:1`]}
                                onChange={handleChange} /></td>
                              <td><InputForTable isAstrix={false} label={""}
                                name={`${i.name}:2`} value={data[`${i.name}:2`]}
                                onChange={handleChange} /></td>
                              <td><InputForTable isAstrix={false} label={""}
                                name={`${i.name}:3`} value={data[`${i.name}:3`]}
                                onChange={handleChange} /></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 2 ? (
                  <div className="d-flex row px-5 pb-4 pt-5" >
                    {ownerdetails.map((i, j) => {
                      return (
                        <div className="mb-3" >
                          <div className='mb-3 d-flex m-2'>
                            <label className="font-wt-600 font-size-16 pt-1 mr-4" ><u>{`Owner ${j + 1} details`}</u></label>
                            {owners.length > 1 ?
                              <div className='col-3 d-flex justify-content-center'>
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                                  let tempTxns = owners
                                  tempTxns = tempTxns.filter((l, m) => {
                                    if (m != j) {
                                      return true
                                    }
                                  })
                                  setOwners([...tempTxns])
                                }}>Remove</button>
                              </div> : null}
                            <div className='col-3 d-flex justify-content-center '>
                              <button type="button" className="btn btn-success btn-sm" onClick={() => {
                                setOwners(owners.concat([null]))
                              }}>Add more</button>

                            </div>
                            <div className="mr-4">
                              <img
                                onClick={() => {
                                  fetchUserDetails(j, data[`sameAsUserDetailsOwner${j}`], j / 1 == 0 ? 1 : 0)
                                }}
                                className='cursor mr-3' src={`assets/images/${data[`sameAsUserDetailsOwner${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                              <label className="font-wt-600 font-size-14">Same as user details</label>
                            </div>
                            <div>
                              <img
                                onClick={() => setData({ ...data, [`mainUserOwner${j}`]: !data[`mainUserOwner${j}`] })}
                                className='cursor mr-3' src={`assets/images/${data[`mainUserOwner${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                              <label className="font-wt-600 font-size-14">is main user</label>
                            </div>
                          </div>

                          <div className="d-flex row" >
                            <div className="col-md-4" >
                              <InputWithSelect label={"Owner Name"}
                                selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                                selectName={`ownerNameTitle${j}`} selectValue={i.name}
                                optionLabel={"name"} optionValue={'name'}
                                name={`ownerName${j}`} value={i.name}
                                onChange={handleChange} />
                            </div>
                            {/* <div className="col-md-4" >
                              <NewInput type={"date"}
                                label={"Date of Birth"} value={data[`ownerDOB${j}`]} name={`ownerDOB${j}`}
                                onChange={handleChange}
                              />
                            </div> */}
                            <div className="col-md-4" >
                              <NewInput
                                label={"Gender"} value={i.gender ? i.gender : "-"} name={`ownerGender${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            {/* <div className="col-md-4" >
                              <NewInput
                                label={"Category"} value={data[`ownerCategory${j}`]} name={`ownerCategory${j}`}
                                onChange={handleChange}
                              />
                            </div> */}
                            <div className="col-md-4" >
                              <InputWithSelect type={"number"} label={"Contact No"}
                                selectData={countryData}
                                selectName={`ownerPhoneCode${j}`} selectValue={i.contact ? i.contact : "-"}
                                optionLabel={"phonecode"} optionValue={'phonecode'}
                                name={`ownerContactNo${j}`} value={i.contact ? i.contact : '-'}
                                onChange={handleChange} />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"Email ID"} value={ownerdetails.email} name={`ownerEmailId${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"Address Line 1"} value={i.address} name={`ownerAddressLine1${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            {/* <div className="col-md-4" >
                              <NewInput
                                label={"Address Line 2"} value={data[`ownerAddressLine2${j}`]} name={`ownerAddressLine2${j}`}
                                onChange={handleChange}
                              />
                            </div> */}
                            <div className="col-md-4" >
                              <NewInput
                                label={"City"} value={i.city ? i.city : '-'} name={`ownerCity${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"State"} value={i.state ? i.state : '-'} name={`ownerState${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewSelect
                                selectData={countryData} optionLabel={"name"} optionValue={"sortname"} onChange={handleChange}
                                label={"Country"} name={`ownerCountry${j}`} value={i.country ? i.country : '-'}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"Postal Code"} value={i.postalcode ? i.postacode : '-'} name={`ownerPostalCode${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"PAN No"} value={i.pan} name={`ownerPanNo${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"Aadhar No"} value={i.aadhar ? i.aadhar : '-'} name={`ownerAadharNo${j}`}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4" >
                              <NewInput
                                label={"New Worth"} value={i.networth ? i.networth : '-'} name={`ownerNetWorth${j}`}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>





                      )
                    })}
                  </div>) : null}

                {tab === 3 ? (
                  <div className="d-flex row px-5 pt-5 pb-4">
                    <div className="mb-4" >
                      <label className="font-size-15 font-wt-500" ><u>Existing Facility Details</u></label>
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Lender", subColumnStyle: { width: '15%' } },
                        { subColumns: "Facility", subColumnStyle: { width: '15%' } },
                        { subColumns: "Limits", subColumnStyle: { width: '15%' } },
                        { subColumns: "Outstanding (Lacs)", subColumnStyle: { width: '15%' } },
                        { subColumns: "ROI/Commission (%)", subColumnStyle: { width: '15%' } },
                        { subColumns: "Tendor (months)", subColumnStyle: { width: '15%' } },
                        { subColumns: "Action", subColumnStyle: { width: '8%' } }]}
                      >
                        {exFacilties.map((i, j) => {
                          return (
                            <tr>
                              <td><InputForTable name={`lenderName${j}`} value={data[`lenderName${j}`]} onChange={handleChange} /></td>
                              <td><SelectForTable label={""}
                                selectData={[{ name: 'CC (Cash Credit)' }, { name: 'OD (Overdraft)' }, { name: 'LC' }, { name: 'BG' }, { name: 'TL' }]}
                                name={`facility${j}`} value={data[`facility${j}`]} optionLabel={"name"} optionValue={'name'}
                                onChange={handleChange} /> </td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`faciltiyLimitCurrency${j}`} selectValue={data[`faciltiyLimitCurrency${j}`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`faciltiyLimit${j}`} value={data[`faciltiyLimit${j}`]} onChange={handleChange} /></td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`faciltiyOutstandingCurrency${j}`} selectValue={data[`faciltiyOutstandingCurrency${j}`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`faciltiyOutstanding${j}`} value={data[`faciltiyOutstanding${j}`]} onChange={handleChange} /></td>
                              <td>
                                <InputForTable type={"number"} name={`facilityROI${j}`} value={data[`facilityROI${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`facilityTenor${j}`} value={data[`facilityTenor${j}`]} onChange={handleChange} /></td>
                              <td>
                                <div className="d-flex w-100 justify-content-between" >
                                  <div className="" >
                                    {exFacilties.length - 1 === j ?
                                      <img
                                        onClick={async () => {
                                          let temp = exFacilties
                                          temp.push(null)
                                          setExFacilities([...temp])
                                          setData({ ...data, [`faciltiyLimitCurrency${j + 1}`]: 'USD', [`faciltiyOutstandingCurrency${j + 1}`]: 'USD' })
                                        }}
                                        className="cursor" src="assets/images/add_black_icon.png" /> :
                                      <img
                                        onClick={() => {
                                          let temp = exFacilties
                                          temp = temp.filter((l, m) => {
                                            if (m != j) {
                                              return true
                                            }
                                          })
                                          setExFacilities([...temp])
                                        }}
                                        className="cursor" src="assets/images/delete.png" />}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="mb-4" >
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Security Type", subColumnStyle: { width: '20%' } },
                        { subColumns: "Brief Property Address", subColumnStyle: { width: '40%' } },
                        { subColumns: "Upload Document", subColumnStyle: { width: '40%' } }]}
                      >
                        <tr>
                          <td>
                            <SelectForTable label={""}
                              selectData={[{ name: 'Residential' }, { name: 'Commercial' }, { name: 'Industrial' }, { name: 'Land/Others' },
                              { name: 'FD/Liquid Security' }]}
                              name={`securityType`} value={data[`securityType`]} optionLabel={"name"} optionValue={'name'}
                              onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable name={"briefPropertyAddress"} value={data.briefPropertyAddress} onChange={handleChange} />
                          </td>
                          <td>
                            <FileInput isEditable={true} value={data.securityDocument} name={"securityDocument"}
                              onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)} />
                          </td>
                        </tr>
                      </NewTablev2>
                    </div>

                    <div className="mb-4 d-flex row m-0 p-0" >
                      <label className="font-size-15 font-wt-500" ><u>Requested Facility Details</u></label>
                      <div className="d-flex row justify-content-between m-0 p-0" >
                        <div style={{ width: '49%' }} className="" >
                          <NewTablev2 showSideBorders={true}
                            columns={[{ subColumns: "Facility", subColumnStyle: { width: '50%' } },
                            { subColumns: "Limits", subColumnStyle: { width: '50%' } }]}
                          >
                            {facilityNames1.map((i, j) => {
                              return (
                                <tr>
                                  <td><label className="font-size-14 font-wt-500" >{i.name}</label></td>
                                  <td>
                                    <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                      selectData={currencyData}
                                      selectName={`facilityCurrency`} selectValue={data[`facilityCurrency`]}
                                      optionLabel={"code"} optionValue={'code'}
                                      name={`${i.name}:facilityLimit`} value={data[`${i.name}:facilityLimit`]}
                                      onChange={handleChange} />
                                  </td>
                                </tr>
                              )
                            })}
                          </NewTablev2>
                        </div>
                        <div style={{ width: '49%' }} className="" >
                          <NewTablev2 showSideBorders={true}
                            columns={[{ subColumns: "Facility", subColumnStyle: { width: '50%' } },
                            { subColumns: "Limits", subColumnStyle: { width: '50%' } }]}
                          >
                            {facilityNames2.map((i, j) => {
                              return (
                                <tr>
                                  <td><label className="font-size-14 font-wt-500" >{i.name}</label></td>
                                  <td>
                                    <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                      selectData={currencyData}
                                      selectName={`facilityCurrency`} selectValue={data[`facilityCurrency`]}
                                      optionLabel={"code"} optionValue={'code'}
                                      name={`${i.name}:facilityLimit`} value={data[`${i.name}:facilityLimit`]}
                                      onChange={handleChange} />
                                  </td>
                                </tr>
                              )
                            })}</NewTablev2>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 4 ? (
                  <div className="d-flex row px-5 pt-5 pb-4">
                    <label className="font-size-15 font-wt-500 mb-4" ><u>Top 10 buyer details</u> (Upload buyer details in excel format or fill the below table manually)</label>
                    <div className="col-md-6" >
                      <FileInput isEditable={true} value={data["top10BuyersDocument"]} name={'top10BuyersDocument'} onChange={e => handleCSV(e, "buyer")}
                        onUploadCancel={() => { setData({ ...data, top10BuyersDocument: null }) }} />
                      {errors[`top10BuyersDocument`] ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors[`top10BuyersDocument`]}</b></div> : ''}
                    </div>
                    <div className="col-md-4" >
                      <h6 className=''>
                        <a className="text-dark cursor-pointer" href="./assets/docs/Top10Buyers.csv"
                          target="_blank" download>
                          <img className='mr-2' src="assets/images/csv_file_icon.svg" alt="" width="45" height="45"></img>
                          {"Download Template"}</a>
                      </h6>
                    </div>
                    <div className="my-4" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Buyers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <label className="font-size-15 font-wt-500 mb-4" ><u>Top 10 supplier details</u> (Upload supplier details in excel format or fill the below table manually)</label>
                    <div className="col-md-6" >
                      <FileInput isEditable={true} value={data["top10SupplierDocument"]} name={'top10SupplierDocument'} onChange={e => handleCSV(e, "supplier")}
                        onUploadCancel={() => { setData({ ...data, top10SupplierDocument: null }) }} />
                      {errors[`top10SupplierDocument`] ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors[`top10SupplierDocument`]}</b></div> : ''}
                    </div>
                    <div className="col-md-6" >
                      <h6 className=''>
                        <a className="text-dark cursor-pointer" href="./assets/docs/Top10Suppliers.csv"
                          target="_blank" download>
                          <img className='mr-2' src="assets/images/csv_file_icon.svg" alt="" width="45" height="45"></img>
                          {"Download Template"}</a>
                      </h6>
                    </div>
                    <div className="my-4" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Suppliers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 5 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <label className='font-wt-500 font-size-16'>{`Select any ${finList.length} financiers`}</label>
                    <div className="row my-4">
                      {
                        finList.map((key, index) => {
                          return (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-3">
                              <div className="d-flex ">
                                <div class="custom-control ps-0 custom-checkbox form-check">
                                  <label class="form-check-label" for={"flexCheckChecked" + index}></label>
                                </div>
                                <div className={`card p-3 col-10 ${key.isAvailable ? "selectfinancercard" : 'disabledFinCard'}`}>
                                  {key.isRecommened ? (
                                    <div style={{ right: '-1.4rem' }} className="position-absolute" >
                                      <img src="assets/images/recommended_label_updated.svg" />
                                    </div>
                                  ) : null}

                                  <div className="d-flex flex-row mt-5">
                                    <div>
                                      <p className="cursor">
                                        {key.logo ? (<img
                                          style={{ maxWidth: '120px', maxHeight: '40px' }}
                                          className="mx-2" src={avatarUrl(key.logo)} alt="" />) : null}
                                        <span
                                          style={{ marginLeft: '2rem' }}
                                          className="cursor"
                                        >
                                          <img
                                            onClick={
                                              () => {
                                                if (key.isAvailable || environment === "dev") {
                                                  handleFinSelection(index)
                                                } else {
                                                  toastDisplay("Action not availble", "info")
                                                }
                                              }
                                            }
                                            className='cursor mr-3' src={`assets/images/${finList?.[index]["isChecked"] ? 'checked-green' : 'empty-check'}.png`} />
                                        </span>
                                      </p>

                                    </div>
                                    {/* <div className="d-flex flex-row justify-content-between w-100">
                                      {!key.isAvailable &&
                                        <img src="/assets/images/info-circle-outlined_remark.png" alt="" height={20} width={20} onClick={() => {
                                          setTooltip(true)
                                          setTooltipdata({
                                            lenderName: key.name,
                                            remarks: key.info_text
                                          })
                                        }} />
                                      }
                                    </div> */}
                                  </div>
                                  <div className="d-flex px-2">
                                    <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{key.avgRatings.toFixed(2)}</p>
                                      <span>
                                        <img className={`${key.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                        <img className={`${key.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      </span>
                                    </div>
                                    <div
                                      onClick={async () => {
                                        // Get getUserTradeInfoWithFinancier
                                        let temp = key
                                        temp["show"] = true
                                        toggleReviewScrollIndex(0)
                                        setshowLoader(true)
                                        temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: key.id, sellerId: userId }) }
                                        setshowLoader(false)
                                        setSeeReview(temp)
                                      }}
                                      className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{key.reviewsCount}</p><p className="font10 font-size-14 font-wt-500">See reviews</p></div>
                                  </div>
                                  <p
                                    onClick={() => {
                                      setSelectedChat({
                                        receiverName: key.name,
                                        receiverId: key.id,
                                        userId: userId,
                                        isChatOpen: true
                                      })
                                    }} className="cursor"
                                  ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 font-wt-500 text-dark ms-2">Chat with financer</span></p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                ) : null}

                {tab === 6 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mb-3'>
                      <label className='font-wt-500 font-size-16'><u>Company Details</u></label>
                      <div className='row'>
                        {reviewBusiness.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    {owners.map((i, j) => {
                      return (
                        <div className='w-100 mb-3'>
                          <label className='font-wt-500 font-size-16'><u>{`Owner ${j + 1} Details`}</u></label>
                          <div className='row'>
                            {ownerReview.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  {item.name ?
                                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                    : null} </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    })}

                    {/* <div className="mb-3">
                      <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } }, { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                        {Object.keys(wcyesnoqsts).map((i, j) => {
                          return (
                            <tr>
                              <td>{wcyesnoqsts[i]["label"]}</td>
                              <td>
                                <img
                                  onClick={() => {
                                    setData({ ...data, [i]: data[i] === true ? undefined : true })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                              <td>
                                <img
                                  onClick={() => {
                                    setData({ ...data, [i]: data[i] === false ? undefined : false })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div> */}

                    <div className="mb-3" >
                      <label className="font-size-15 font-wt-500" ><u>Existing Facility Details</u></label>
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Lender", subColumnStyle: { width: '15%' } },
                        { subColumns: "Facility", subColumnStyle: { width: '15%' } },
                        { subColumns: "Limits", subColumnStyle: { width: '15%' } },
                        { subColumns: "Outstanding (Lacs)", subColumnStyle: { width: '15%' } },
                        { subColumns: "ROI/Commission (%)", subColumnStyle: { width: '15%' } },
                        { subColumns: "Tendor (months)", subColumnStyle: { width: '15%' } },
                        { subColumns: "Action", subColumnStyle: { width: '8%' } }]}
                      >
                        {exFacilties.map((i, j) => {
                          return (
                            <tr>
                              <td><InputForTable name={`lenderName${j}`} value={data[`lenderName${j}`]} onChange={handleChange} /></td>
                              <td><SelectForTable label={""}
                                selectData={[{ name: 'CC (Cash Credit)' }, { name: 'OD (Overdraft)' }, { name: 'LC' }, { name: 'BG' }, { name: 'TL' }]}
                                name={`facility${j}`} value={data[`facility${j}`]} optionLabel={"name"} optionValue={'name'}
                                onChange={handleChange} /> </td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`faciltiyLimitCurrency${j}`} selectValue={data[`faciltiyLimitCurrency${j}`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`faciltiyLimit${j}`} value={data[`faciltiyLimit${j}`]} onChange={handleChange} /></td>
                              <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`faciltiyOutstandingCurrency${j}`} selectValue={data[`faciltiyOutstandingCurrency${j}`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`faciltiyOutstanding${j}`} value={data[`faciltiyOutstanding${j}`]} onChange={handleChange} /></td>
                              <td>
                                <InputForTable type={"number"} name={`facilityROI${j}`} value={data[`facilityROI${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`facilityTenor${j}`} value={data[`facilityTenor${j}`]} onChange={handleChange} /></td>
                              <td>
                                <div className="d-flex w-100 justify-content-between" >
                                  <div className="" >
                                    {exFacilties.length - 1 === j ?
                                      <img
                                        onClick={async () => {
                                          let temp = exFacilties
                                          temp.push(null)
                                          setExFacilities([...temp])
                                          setData({ ...data, [`faciltiyLimitCurrency${j + 1}`]: 'USD', [`faciltiyOutstandingCurrency${j + 1}`]: 'USD' })
                                        }}
                                        className="cursor" src="assets/images/add_black_icon.png" /> :
                                      <img
                                        onClick={() => {
                                          let temp = exFacilties
                                          temp = temp.filter((l, m) => {
                                            if (m != j) {
                                              return true
                                            }
                                          })
                                          setExFacilities([...temp])
                                        }}
                                        className="cursor" src="assets/images/delete.png" />}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Security Type", subColumnStyle: { width: '20%' } },
                        { subColumns: "Brief Property Address", subColumnStyle: { width: '40%' } },
                        { subColumns: "Upload Document", subColumnStyle: { width: '40%' } }]}
                      >
                        <tr>
                          <td>
                            <SelectForTable label={""}
                              selectData={[{ name: 'Residential' }, { name: 'Commercial' }, { name: 'Industrial' }, { name: 'Land/Others' },
                              { name: 'FD/Liquid Security' }]}
                              name={`securityType`} value={data[`securityType`]} optionLabel={"name"} optionValue={'name'}
                              onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable name={"briefPropertyAddress"} value={data.briefPropertyAddress} onChange={handleChange} />
                          </td>
                          <td>
                            <FileInput isEditable={true} value={data.securityDocument} name={"securityDocument"}
                              onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)} />
                          </td>
                        </tr>
                      </NewTablev2>
                    </div>

                    <div className="mb-4 d-flex row m-0 p-0" >
                      <label className="font-size-15 font-wt-500" ><u>Requested Facility Details</u></label>
                      <div className="d-flex row justify-content-between m-0 p-0" >
                        <div style={{ width: '49%' }} className="" >
                          <NewTablev2 showSideBorders={true}
                            columns={[{ subColumns: "Facility", subColumnStyle: { width: '50%' } },
                            { subColumns: "Limits", subColumnStyle: { width: '50%' } }]}
                          >
                            {facilityNames1.map((i, j) => {
                              return (
                                <tr>
                                  <td><label className="font-size-14 font-wt-500" >{i.name}</label></td>
                                  <td>
                                    <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                      selectData={currencyData}
                                      selectName={`facilityCurrency`} selectValue={data[`facilityCurrency`]}
                                      optionLabel={"code"} optionValue={'code'}
                                      name={`${i.name}:facilityLimit`} value={data[`${i.name}:facilityLimit`]}
                                      onChange={handleChange} />
                                  </td>
                                </tr>
                              )
                            })}
                          </NewTablev2>
                        </div>
                        <div style={{ width: '49%' }} className="" >
                          <NewTablev2 showSideBorders={true}
                            columns={[{ subColumns: "Facility", subColumnStyle: { width: '50%' } },
                            { subColumns: "Limits", subColumnStyle: { width: '50%' } }]}
                          >
                            {facilityNames2.map((i, j) => {
                              return (
                                <tr>
                                  <td><label className="font-size-14 font-wt-500" >{i.name}</label></td>
                                  <td>
                                    <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                      selectData={currencyData}
                                      selectName={`facilityCurrency`} selectValue={data[`facilityCurrency`]}
                                      optionLabel={"code"} optionValue={'code'}
                                      name={`${i.name}:facilityLimit`} value={data[`${i.name}:facilityLimit`]}
                                      onChange={handleChange} />
                                  </td>
                                </tr>
                              )
                            })}</NewTablev2>
                        </div>
                      </div>
                    </div>


                    <label className="font-size-15 font-wt-500 mb-1" ><u>Buyer Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Buyers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <label className="font-size-15 font-wt-500 mb-1" ><u>Supplier Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Suppliers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className=''>
                      <ExtraDetailsForLimitApplication userTokenDetails={userToken} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton={false} mainTagMarginPadding0={true} />
                    </div>

                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {finList.map((key) => {
                          if (key.isChecked) {
                            return (
                              <label key={key.name}
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {key.name}
                              </label>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className='mt-3'>
                      <img
                        onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                        className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                      <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                    </div>
                  </div>
                ) : null}


                <div
                  style={tab === 0 ? { marginTop: '-3rem', marginBottom: '1.5rem' } : { marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  className={"d-flex finActionTabContainer mx-4 "} >
                  {tab / 1 != 6 ? <div
                    onClick={() => {
                      if (tab === 0) {
                        const buttonElement = document.getElementById('extraDetailsForLimitContinueBtn');
                        if (buttonElement) {
                          buttonElement.click();
                        }
                      }
                      else {
                        handleValidation(undefined)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                  </div> : <div
                    onClick={() => {
                      setFinancierActionTab(0)
                      handleValidation(undefined)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                  </div>}
                  <div
                    onClick={() => {
                      if (tab > 0) {
                        setTab(tab - 1)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor ${tab === 0 ? ' disable-bg ' : " "}`} >
                    <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                    <label
                      onClick={() => { toastDisplay('Application saved as draft', "success"); }}
                      className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 "} >Save as Draft</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                    }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor`} >
                    {finActionTab == 2 && draftData.length ? <div
                      style={{
                        width: '14rem',
                        top: `${draftData.length * -2.5}rem`,
                        borderRadius: "10px"
                      }}
                      className="position-absolute " >
                      {draftData.map((i, j) => {
                        return (
                          <div
                            onClick={() => { window.location = `/wcApplyLimit?formContinue=${i.id}` }}
                            style={{ height: '2.5rem' }} className="d-flex bg-white border-bottom-solid-dee2e6 align-items-center" >
                            <label
                              className="font-size-14 font-wt-500 col-8 cursor truncate" >{`Application ${j + 1}`}</label>
                            <div className="col-4 d-flex align-items-center" >
                              <label
                                style={{
                                  background: "#5CB8D3",
                                  color: "#fff",
                                  borderRadius: "100%"
                                }}
                                className="ml-3 px-1 py-2 m-0" >{i.details.percentageComplete + " %"}</label>
                            </div>
                          </div>
                        )
                      })}
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Draft
                      <span
                        style={{
                          background: "#5CB8D3",
                          color: "#fff",
                          borderRadius: "100%"
                        }}
                        className="ml-3 px-2 py-1" >{draftData.length}</span>
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </main>

        </div>
      </div>
    </div>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(WCApplyLimit)