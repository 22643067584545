import { useEffect } from "react";

const styles = {
  cardHeader: {
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #ddd',
    padding: '10px 15px',
  },
  companyName: {
    margin: 0,
    fontSize: '18px',
  },
  docNumber: {
    color: '#6c757d',
    fontSize: "18px"
  },
};
export default function TimelineCard({ editDocument }) {
  useEffect(() => {
    console.log("aaa inside card", editDocument)
  }, [])
  return (
    <div className="card mt-3 mb-3" >
      <div className="card-header" style={styles.cardHeader}>
        {/* <h5 style={styles.companyName}>{editDocument.companyName}</h5> */}
        <h6 style={styles.docNumber}>{editDocument.docType}: <span className="cursor text-primary">{editDocument.docId}</span></h6>
      </div>

      <div className="card-body">
        <div className="row mb-3">
          <div className="col">
            <strong>{editDocument.details.clientCompanyName} - {editDocument.details.shipToCompanyName}</strong><br />
            <strong>Total Amount:</strong>  {editDocument.details.invTotalAmount} {editDocument.details.invCurrency}<br />
            <strong>Total No Of Items:</strong> {Object.keys(editDocument.details).filter(key => key.startsWith('itemDesc')).length} <br />
            {/* <strong>Phone:</strong> {editDocument.clientContactNo} */}
          </div>
          <div className="col">
            <strong>Date:</strong> {editDocument.details.invoiceDate || new Date(editDocument.createdAt).toLocaleDateString()} <br />
            {/* <strong>Contact Name:</strong> {editDocument.shipToContactName} <br />
            <strong>Email:</strong> {editDocument.shipToEmailId} <br />
            <strong>Phone:</strong> {editDocument.shipToContactNo} */}
          </div>
        </div>

        {/* <div className="row">
          <div className="col">
            <strong>Item:</strong> {editDocument.itemDesc0} <br />
            <strong>Quantity:</strong> {editDocument.itemQuantity0} {editDocument.itemQuantityUnits0} <br />
            <strong>Unit Price:</strong> ₹{editDocument.itemUnitPrice0} <br />
            <strong>Total Amount:</strong> ₹{editDocument.itemTotalAmount0}
          </div>
          <div className="col">
            <strong>Tax:</strong> ₹{editDocument.itemTax0} ({editDocument["itemTax%0"]}%) <br />
            <strong>Sub Total:</strong> ₹{editDocument.invSubTotalAmount0} <br />
            <strong>Total Before Tax:</strong> ₹{editDocument.invTotalAmountBeforeTax} <br />
            <strong>Total After Tax:</strong> ₹{editDocument.invTotalAmount}
          </div>
        </div>

        <div className="mt-3">
          <strong>Invoice Date:</strong> {editDocument.invoiceDate} <br />
          <strong>Delivery Date:</strong> {editDocument.deliveryDate}
        </div> */}
      </div>

      {/* <div className="card-footer text-muted">
        <small>Status: {editDocument.status}</small>
      </div> */}
    </div>

  )
}